<template>
    <div v-if="individualMeasure.sessionDetails" style="width: 100%; padding: 15px">
<!--        <table style="width: 100%">-->
<!--            <tr>-->
<!--                <th>-->

<!--                </th>-->
<!--                <th v-for="(header, headerIndex) in resultsTableHeaders" :key="headerIndex" >-->
<!--                    <div style="display: flex">-->
<!--                        <div v-if="!editMode" :style="{opacity: isTestExcluded(headerIndex) ? 0.5 : null}">-->
<!--                            {{header.label}}-->
<!--                        </div>-->
<!--                        <div v-else >-->
<!--                            <img v-if="isTestExcluded(headerIndex)" :src="greenPlus" alt="green-plus" @click="excludeIncludeBaselineSessionResult(false, headerIndex)" />-->
<!--                            <img v-else :src="redX" alt="remove-icon" @click="excludeIncludeBaselineSessionResult(true, headerIndex)" />-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </th>-->
<!--            </tr>-->
<!--            <tr v-for="(row, rowIndex) in individualMeasure.sessionDetails.resultsTable" :key="rowIndex">-->
<!--                <td>-->
<!--                    {{ row.label }}-->
<!--                </td>-->
<!--                <td-->
<!--                    v-for="(number, index) in resultsTableHeaders.length" :key="index"-->
<!--                    :style="{opacity: isTestExcluded(index) ? 0.5 : null}"-->
<!--                >-->
<!--                    <div-->
<!--                        style="height: 100%; width: 100%"-->
<!--                        :style="{color: row.tests[index] && row.tests[index].isFlagged ? 'var(&#45;&#45;main-red-base)' : null}" >-->
<!--                        {{row.tests[index] ? row.tests[index].value : '-'}}-->
<!--                    </div>-->


<!--                </td>-->
<!--            </tr>-->
<!--        </table>-->
	
	
	    <table class="pdf-table" style="width: 100%; border: none">
		    <tr>
			    <th style="background-color: white;border: none">
			
			    </th>
			    <th v-for="(header, headerIndex) in resultsTableHeaders" :key="headerIndex" style="background-color: white;border: none">
				    <div style="display: flex; border: none;padding: 5px 10px; color: black!important; background-color: white" >
					    <div v-if="!editMode" :style="{opacity: isTestExcluded(headerIndex) ? 0.5 : null}" >
						    {{header.label}}
					    </div>
					    <div v-else >
						    <img class="pointer" v-if="isTestExcluded(headerIndex)" :src="greenPlus" alt="green-plus" @click="excludeIncludeBaselineSessionResult(false, headerIndex)" />
						    <img v-else class="pointer" :src="redX" alt="remove-icon" @click="excludeIncludeBaselineSessionResult(true, headerIndex)" />
					    </div>
				    </div>
			
			    </th>
		    </tr>
            <GenericTestResultsTable v-if="individualMeasure.sessionDetails.resultsTable2"
                    :individualMeasure="individualMeasure"
            />
            <CobaltTestCardResults v-else-if="isCobaltMeasureType(individualMeasure.measureId)"
                    :individualMeasure="individualMeasure"
                    @openCobaltGraphs="openCobaltGraphs"
            />
	    </table>
    </div>
    
</template>

<script>
import redX from '@/assets/svgs/red-x-circle.svg'
import greenPlus from '@/assets/svgs/green-plus-circle.svg'
import CobaltTestCardResults from "@/components/Profile/TestsViewer/CobaltTestResultsTable.vue";
import GenericTestResultsTable from "@/components/Profile/TestsViewer/GenericTestResultsTable.vue";
import {isCobaltMeasureType} from "@/js/shared/helper";

export default {
    name: "NewIndividualTestCardSessionResults",
    components: {GenericTestResultsTable, CobaltTestCardResults},
    props: {
        individualMeasure: {
            required: true
        },
        editMode: {
            default: false
        },
	    profileTest: {
			
	    }
    },
    data() {
        return {
            redX,
            greenPlus
        }
    },
    computed: {
        resultsTableHeaders() {
            if (!this.individualMeasure || !this.individualMeasure.sessionDetails)
                return [];
            if (!this.individualMeasure.hasDuplicateTests)
                return [{ label: '' }];
            
            let headers = [];
            
            for (let i = 0; i < this.individualMeasure.sessionDetails.columnCount; i++){
                headers.push({label: 'Test ' + (i + 1)})
            }

            return headers
        },
        sessionDetails() {
            return this.individualMeasure.sessionDetails
        }
    },
    methods: {
        excludeIncludeBaselineSessionResult(shouldExclude, index) {
            if (!this.sessionDetails) return
	        
	        const {profileTest} = this;

            let listOfResultIds = this.sessionDetails.testsArray[index].resultIds

            let payload = {
	            profileTestResultIds: listOfResultIds,
                shouldExclude: shouldExclude,
	            profileTestId: profileTest.id,
	            profileId: profileTest.profileId
            };

            if (shouldExclude) {
                console.log('remove ids ', listOfResultIds)
            } else {
                console.log('add ids ', listOfResultIds)
            }
	        
            this.$emit('excludeIncludeTestResults', payload)
        },
        isTestExcluded(index) {
            // if (index === 1) return true
	        // let self = this;
			// debugger;
            return this.individualMeasure.sessionDetails.testsArray[index]?.isExcluded
        },
        isCobaltMeasureType(measureId){
            return isCobaltMeasureType(measureId);
        },
        openCobaltGraphs(resultId){
            this.$emit('openCobaltGraphs', resultId)
        }
    }
}
</script>

<style scoped>

.excluded {
	opacity: .5
}
.flagged {
	color: #ff6b6b;
}

td, th {
    /*border: 1px solid #dddddd;*/
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #eeeeee;
}
</style>