<template>
    <v-btn v-if="isEditFab" icon @click="onClick">
        <v-icon class="edit-icon">mdi-pencil</v-icon>
    </v-btn>

    <v-btn v-else
           :color="disabled ? '#000' : color"
           @click="onClick"
           :x-small="xSmall"
           :small="small"
           :outlined="outlined"
           :disabled="disabled"
           :fab="fab"
           :dark="!disabled"
           :rounded="rounded"
           :loading="loading"
           :type="type"
           :depressed="depressed"
           :elevation="0"
           :style="{'color': color === 'white' ? '#000' : null}"
           
           

    >
	    <v-icon v-if="icon" style="font-size: 1rem" >{{icon}}</v-icon>
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: 'primary'
        },
        small: {
            type: Boolean,
      
        },
        xSmall: {
            type: Boolean
        },
        outlined: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        fab: {
            type: Boolean
        },
        dark: {
            type: Boolean
        },
        rounded: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean
        },
        type: {
            type: String
        },
        isEditFab: {
            type: Boolean
        },
        depressed: {
            type: Boolean
        },
		icon: {
			type: String
		}

    },
    name: "AppButton",
    methods: {
        onClick(event) {
            this.$emit('click', event)
        }
    },

}
</script>

<style scoped>
</style>