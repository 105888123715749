<template>
    <div style="height: 100vh; overflow-y: scroll">
        <div class="pa-4">
            <div style="display: flex; align-items: center">
                <div class="filter-title">
                    Version
                </div>
                <div 
                    v-for="version in versions" :key="version.value"
                    class="option mr-2"
                    :class="{'option-active': chartFilters.version === version.value}"
                    @click="selectVersion(version.value)"
                >
                    {{ version.label }}
                </div>
            </div>
            <div style="display: flex; align-items: center" v-if="chartFilters.version">
                <div class="filter-title">
                    Stages
                </div>
                <div
                    v-for="stage in stages" :key="stage.value"
                    class="option mr-2"
                    :class="{'option-active': stage.active}"
                    @click="stage.active = !stage.active"
                >
                    {{ stage.label }}
                </div>
            </div>
                <highcharts
                    v-if="chartOptions1"
                    :options="chartOptions1"
                    :style="{height: `${this.chartHeight}px`}"
                    style="width: 100%; background-color: transparent"
                    ref="chart"
                ></highcharts>
            
        </div>
        
    </div>
</template>

<script>
import {allMeasures, measuresCategoriesById} from "@/js/shared/constants";
import {getTestTypesByCategories} from "@/js/shared/helper";

export default {
    name: "SwayTestsByDomain",
    data() {
        return {
            chartOptions: {
                title: {
                    text: 'Sway Tests by Domain'
                },
                chart: {
                    style: {
                        fontFamily: 'Roboto'
                    }
                },
                accessibility: {
                    point: {
                        valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
                    }
                },
                exporting: {
                    sourceWidth: 1000,
                    sourceHeight: 1000
                },
                series: [{
                    keys: ['from', 'to', 'weight', 'color'],
                    data: [
                        {from: 'Test Types', to: 'Balance', weight: 19, color: 'red'},


                        ['Test Types', 'Functional', 10],
                        ['Test Types', 'Processing', 3],
                        ['Test Types', 'Executive Function', 3],
                        ['Test Types', 'Memory', 1],
                        ['Test Types', 'Nueromotor', 0],
                        ['Test Types', 'Oculomotor', 1],
                        ['Test Types', 'Survey', 2],
                        ['Test Types', 'COVID', 1],
                        ['Test Types', 'Symptoms', 1],

                        ['Balance', 'Feet Together Eyes Closed', 1],
                        ['Balance', 'Feet Together Eyes Closed - Foam', 1],
                        ['Balance', 'Feet Together Eyes Open', 1],
                        ['Balance', 'Feet Together Eyes Open - Foam', 1],
                        ['Balance', 'Single Leg - Left - Eyes Closed', 1],
                        ['Balance', 'Single Leg - Right - Eyes Closed', 1],
                        ['Balance', 'Single Leg - Left - Eyes Open', 1],
                        ['Balance', 'Single Leg - Right - Eyes Open', 1],
                        ['Balance', 'Single Leg - Eyes Open', 1],
                        ['Balance', 'Tandem - Right - Eyes Closed', 1],
                        ['Balance', 'Tandem - Left - Eyes Closed', 1],
                        ['Balance', 'Tandem - Right - Eyes Open', 1],
                        ['Balance', 'Tandem - Left - Eyes Open', 1],
                        ['Balance', 'Tandem - Eyes Open', 1],
                        ['Balance', 'Semi-Tandem - Eyes Open', 1],
                        ['Balance', 'Shoulder Width - Eyes Open', 1],
                        ['Balance', 'Shoulder Width - Eyes Closed', 1],
                        ['Balance', 'Shoulder Width - Eyes Open - Foam', 1],
                        ['Balance', 'Shoulder Width - Eyes Closed - Foam', 1],


                        // ['Balance', 'Feet Together', 4],
                        // ['Balance', 'Single Leg', 5],
                        // ['Balance', 'Tandem', 5],
                        // ['Balance', 'Semi Tandem', 1],
                        // ['Balance', 'Shoulder Width', 4],


                        ['Functional', 'Single Leg Squat (L)', 1],
                        ['Functional', 'Single Leg Squat (R)', 1],
                        ['Functional', 'Shoulder Width Squat', 1],
                        ['Functional', 'Timed Up & Go', 1],
                        ['Functional', 'Tandem Gait', 1],
                        ['Functional', 'Sit to Stand', 1],
                        ['Functional', 'Active Head Rotation', 1],
                        ['Functional', 'Active Head Rotation (Foam)', 1],
                        ['Functional', 'Active Rotation - Arms Extended', 1],
                        ['Functional', 'Active Rotation - Arms Extended (Foam)', 1],

                        ['Processing', 'Inspection Time', 1],
                        ['Processing', 'Reaction Time', 1],
                        ['Processing', 'Impulse Control', 1],

                        ['Executive Function', 'Reverse Number Counting', 1],
                        ['Executive Function', 'Cued Stroop', 1],
                        ['Executive Function', 'Cued Stroop - Impairment', 1],

                        ['Memory', 'Memory ', 1],

                        ['Oculomotor', 'Multiple Object Tracking', 1],

                        ['Survey', 'VOMS', 1],
                        ['Survey', 'On Field Assessment', 1],

                        ['COVID', 'COVID ', 1],

                        ['Symptoms', 'Symptoms ', 1],


                    ],
                    type: 'sankey',
                    name: 'Sankey demo series'
                }]
            },
            // chartOptions1: null,
            chartHeight: null,
            chartFilters: {
                version: 501,
            },
            versions: [
                {
                    label: '5.0.0',
                    value: 500
                },
                {
                    label: '5.0.1',
                    value: 501
                },
                
            ],
            stages: [
                {
                    label: 'Alpha',
                    name: 'alpha',
                    value: 1,
                    color: '#42b983',
                    active: false
                },
                {
                    label: 'Beta',
                    name: 'beta',
                    value: 2,
                    color: '#fcad16',
                    active: true
                },
                {
                    label: 'Charlie',
                    name: 'charlie',
                    value: 3,
                    color: '#333',
                    active: true
                },
                {
                    label: 'Delta',
                    name: 'delta',
                    value: 4,
                    color: '#d1d4d8',
                    active: true
                },
                
            ]
        }
    },
    mounted() {
        // this.init()
    },
    methods: {
        selectVersion(value) {
            this.chartFilters.version = this.chartFilters.version === value ? null : value
        },
    },
    watch: {
    },
    computed: {
        chartOptions1() {

            let stages = [];
            this.stages.forEach(stage => {
                if (stage.active) {
                    stages.push(stage.value)
                }
            })
            
            
            let {testTypesByCategories, totalTestTypes} = getTestTypesByCategories({stages, version: this.chartFilters.version});

            let chartHeight = totalTestTypes * 35;
            this.chartHeight = chartHeight

            
            let dataLabels = {
                style: {
                    fontSize: 20
                }
            }
            
            let testTypeDataLabels = {
                style: {
                    fontSize: 14
                }
            }
            
            let data = [];
            let nodes = [{
                id: 'Sway System',
                color: '#34d3fe',
                dataLabels
            }]

            for (let categoryId in testTypesByCategories) {
                let category = testTypesByCategories[categoryId]
                data.push({
                    from: 'Sway System',
                    to: category.label,
                    weight: category.testTypes.length
                })

                nodes.push({
                    id: category.label,
                    color: category.color,
                    dataLabels
                })

                category.testTypes.forEach(testType => {
                    const grey = '#d1d4d8';

                    let stateColors = {
                        1: '#7e57c2',
                        2: '#ffca28',
                        3: '#34d3fe',
                        4: '#d1d4d8'
                    }

                    if (this.chartFilters.version) {
                        if (testType.versions) {
                            let version = testType.versions.find(x => x.number === this.chartFilters.version);
                            
                            
                            
                            let label = testType.label + ' '

                            let color = version?.stage ? stateColors[version.stage] : stateColors[4]

                            if (!!version && !testType.isProtocol && this.stages[version.stage - 1].active) {

                                data.push({
                                    from: category.label,
                                    to: label,
                                    weight: 1,
                                    color: color
                                })

                                nodes.push({
                                    id: label,
                                    color: color,
                                    dataLabels: testTypeDataLabels
                                })
                            }
                        } else if (this.stages[3].active) {
                            data.push({
                                from: category.label,
                                to: testType.label + ' ',
                                weight: 1,
                                color: stateColors[4]
                            })

                            nodes.push({
                                id: testType.label + ' ',
                                color: stateColors[4],
                                dataLabels: testTypeDataLabels
                            })
                        }
                    } else {
                        if (!testType.isProtocol) {
                            let label = testType.label + ' '
                            data.push({
                                from: category.label,
                                to: label,
                                weight: 1,
                                color: category.color,
                            })

                            nodes.push({
                                id: label,
                                color: category.color,
                                dataLabels: testTypeDataLabels
                            })
                        }
                    }



                    // if (!testType.isProtocol) {
                    //     let label = testType.label + ' '
                    //     data.push({
                    //         from: category.label,
                    //         to: label,
                    //         weight: 1,
                    //         color: category.color
                    //     })
                    //
                    //     nodes.push({
                    //         id: label,
                    //         color: category.color
                    //     })
                    // }



                })

            }
            

            return {
                title: {
                    text: 'Sway Test Types by Domain'
                },
                chart: {
                    style: {
                        fontFamily: 'Roboto',
                        height: chartHeight,
                        margin: 0,
                    }
                },
                accessibility: {
                    point: {
                        valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
                    }
                },
                exporting: {
                    // sourceWidth: 1000,
                    // sourceHeight: 1000
                },
                series: [{
                    keys: ['from', 'to', 'weight', 'color'],
                    data,
                    nodes,
                    type: 'sankey',
                    name: 'Sankey demo series'
                }],
                credits: false
            }


        }
    }
}
</script>

<style scoped>
.option {
    padding: 5px; 
    border: 1px solid var(--v-border-base); 
    border-radius: 5px
}

.option-active {
    background-color: rgba(0,255,0,.2);
}

.option:hover {
    cursor: pointer
}

.filter-title {
    font-weight: bold;
    margin-right: 10px
}
</style>