<template>
    <div class="app-input">
        <validation-provider
            v-slot="{ errors, valid }"
            :name="errorLabel ? errorLabel : name"
            :rules="rules"
        >
        <div v-if="row" style="display: flex; align-items: center">
            <div style="display: flex;  flex-direction: column;margin-right: 10px;min-width: 100px; max-width: 50%">
                <div style="color: #757575;  ; ">{{label}}</div> 
                <div v-if="errors" class="error--text text-caption">{{errors[0]}}</div>
            </div>
                

            <slot :errors="errors" :valid="valid"></slot>
            <HelperMessage
                v-if="helperMessage"
                :message="helperMessage"
            />
        </div>
        <div v-else>
           
                <div style="display: flex">
                    <div style="color: #757575">{{label}}</div>
	                <HelperMessage
		                v-if="helperMessage"
		                :message="helperMessage"
	                />
	                <div v-if="errors" class="error--text text-caption">{{errors[0]}}</div>
                </div>
	        
                <slot :errors="errors" :valid="valid"></slot>
	        
            
        </div>
        </validation-provider>
    </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {digits, email, length, max, regex, required} from "vee-validate/dist/rules";
import {TheMask} from "vue-the-mask";
import HelperMessage from "@/components/HelperMessage";

setInteractionMode('eager')

extend('digits', {
    ...digits,
    message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
    ...required,
    // message: '{_field_} can not be empty bruh',
    message: ' required ',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
    ...regex,
    message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

extend('length', {
    ...length,
    message: 'needs to be at least {length} characters.',
})

extend('digits', {
    ...digits,
    message: 'needs to be {length} digits.',
})

// extend('profileId', {
//     ...length,
//     message: 'needs to be at least {length} characters.',
// })

// extend('profileId', value => {
//     return value.length === 6;
// });

// extend('positive', value => {
//     return value >= 0;
// });

extend('positive', {
    validate: value => {
        return value >= 0;
    },
    message: ' must be a positive number'
});

extend('inches', {
    validate: value => {
        return value < 12 && value >= 0;
    },
    message: ' invalid '
});

extend('feet', {
    validate: value => {
        return value < 11 && value >= 0;
    },
    message: ' invalid '
});

extend('centimeters', {
    validate: value => {
        return value < 300;
    },
    message: 'must be less than 300'
});

extend('decimal', {
    validate: value => {
        return isNaN(value) === false;
    },
    message: 'must be a number or decimal'
});

extend('password', {
    validate: value => {
        return value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{6,}$/);
    },
    message: 'must contain at least one uppercase letter, one lowercase letter, and one number'
});

export default {
    name: "AppInput",
    props: {
        errorLabel: {
            required: false
        },
        name: {
            required: true
        },
        rules: {
            
        },
        label: {
            
        },
        row: {
            type: Boolean,
            default: false
        },
        helperMessage: {
            required: false
        }
    },
    components: {
        HelperMessage,
        ValidationObserver,
        ValidationProvider,
        TheMask
    },
}
</script>

<style scoped>

</style>