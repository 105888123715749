import { render, staticRenderFns } from "./GroupNotifications.vue?vue&type=template&id=73fb912c&scoped=true"
import script from "./GroupNotifications.vue?vue&type=script&lang=js"
export * from "./GroupNotifications.vue?vue&type=script&lang=js"
import style0 from "./GroupNotifications.vue?vue&type=style&index=0&id=73fb912c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73fb912c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCheckbox,VSimpleTable})
