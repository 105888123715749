<template>
    <div style="max-width: 500px">
        <table class="pdf-table" style="margin-top: 10px">
            <thead class="profile-test-history-table-header">
                <tr style="">
                    <th style="padding: 0 10px; background-color: #fafafa; color: black!important">Total Score</th>
                    <th style="padding: 0 10px; background-color: #fafafa; color: black!important">{{table.surveySubject}} Severity</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, index) in tableRows"
                    :key="index"
                    :class="{activeRow: row.isActive}"
    
                >
                    <td class="pdf-table-cell">{{ row.minValue }} - {{row.maxValue}}</td>
                    <td class="pdf-table-cell" >{{ row.description }}</td>
                </tr>
            </tbody>
            

        </table>
    </div>
</template>

<script>

export default {
    name: "SurveyResultsTable",
    props: {
        table: {
            required: true
        },
        score: {
            required: true
        }
    },
    data() {
        return {

           
        }
    },
    computed: {
        tableRows() {
            return this.table.rows.map(x => {
                x.isActive = this.score >= x.minValue && this.score <= x.maxValue;
                return x
            })
        }
    }
}
</script>

<style scoped>
.activeRow {
    background-color: rgba(255,0,0,.1);
}
</style>