<template>
    <div v-if="!profileIsInactive">
        <div v-if="!profile.data.eventStartedOn" style="display: flex; justify-content: flex-end" class="mb-2">
            <app-btn @click="$emit('createEvent')">Create Event</app-btn>
        </div>
	
	
	    <app-card  hide-header>
		    <app-data-table
			    :headers="headers"
			    :items="profile.events"
			    :loading="loading"
		    >
<!--			    <template v-slot:item.isActive="{ item }">-->
<!--				    <div style="display: flex; align-items: center">-->
<!--					    <div :style="{'background-color': item.isActive ? 'limegreen' : 'red'} " class="dot mr-2"></div>-->
<!--					    <div>-->
<!--						    {{item.isActive ? 'Active' : 'Expired'}}-->
<!--					    </div>-->
<!--				-->
<!--				    </div>-->
<!--			    </template>-->
<!--			    <template v-slot:item.name="{ item }">-->
<!--				    <div style="display: flex; align-items: center">-->
<!--					    <TestTypeDot class="mr-2" :is-non-baseline="item.isNonBaseline" :has-duplicate-tests="!item.isTest" :is-excluded="false" />-->
<!--					    <div class="mr-2">-->
<!--						    {{$store.state.currentAccount.viewingAsSuper && item.isProfileSession && item.baselineSessionTypeId !== 5 ? `Profile ID: ${item.profileId}` : item.displayName}}-->
<!--					    </div>-->
<!--					    <div >-->
<!--						    <app-chip small label :color="item.isProfileSession ? 'blue' : 'green'" >{{ item.isProfileSession ? 'Profile' : 'Group'}}</app-chip>-->
<!--					    </div>-->
<!--				-->
<!--				    </div>-->
<!--			    </template>-->
			    <template v-slot:item.name="{ item }">
				
				    {{ item.name }}
				
				    <app-chip
					    color="orange"
					    dark
					    v-if="!item.endedOn"
					    class="ml-1"
				    >
					    Open
				    </app-chip>
			
			    </template>
			    <template v-slot:item.startedOn="{ item }">

					    {{ formatDate(item.startedOn) }}
				 
			    </template>
			    <template v-slot:item.endedOn="{ item }">
				
					<div v-if="item.endedOn" >
						{{ formatDate(item.endedOn) }}
					</div>
			
			    </template>
			    <template v-slot:item.eventTypeId="{ item }">
				
				    <div v-if="item.eventTypeId" >
					    {{ getEventTypeIdDisplay(item.eventTypeId) }}
				    </div>
			
			    </template>
                <template v-slot:item.deleteButton="{ item }">
                    <div style="display: flex; align-items: center">
                        <app-fab-btn type="edit" color="grey" x-small @click="$emit('editEvent', item)"/>
                        <app-fab-btn type="delete" color="red" small @click="deleteProfileEvent(item)"></app-fab-btn>
                    </div>
                </template>
			    <template #no-data>
				    <slot name="no-data"></slot>
			    </template>
                
		    </app-data-table>
	
	    </app-card>
        
    </div>
    <div v-else>
        <EmptyState title="Events Unavailable" description="You cannot create an event for an inactive profile. To reactivate this profile, please add this profile to an active group." />
    </div>
</template>

<script>
import {getSessions} from "@/services/session.service";
import {getSessionDetails} from "@/js/shared/helper";
import EmptyState from "@/components/EmptyState";
import moment from "moment";
import {eventTypes} from "@/js/shared/constants";
import {deleteProfileEvent} from "@/services/profile.service";

export default {
    name: "ProfileEvents",
    components: {EmptyState},
    props: {
        simpleProfile: {
            required: true
        },
        profileIsInactive: {
            type: Boolean,
            
        },
	    profile: {}
    },
    data() {
        return {
            events: [],
            eventDialogOpen: false,
	        loading: false,
        }
    },
    mounted() {

    },
	computed: {
		headers() {
			// if (!) {
			return [
				{
					text: 'Name',
					value: 'name',
				},
				// {
				//     text: 'Type',
				//     value: 'type',
				// },
				{
					text: 'Start Date',
					value: 'startedOn',
				},
				// {
				//     text: 'Begins',
				//     value: 'startsOnFormatted',
				// },
				{
					text: 'End Date',
					value: 'endedOn',
				},
				{
					text: 'Event Type',
					value: 'eventTypeId',
				},
				
				{
					text: 'Notes',
					value: 'note',
				},
                {
                    text: 'Actions',
                    value: 'deleteButton'
                },
			]
			//}
		},
	},
    methods: {
	    formatDate(date) {
			return moment(date).format('YYYY-MM-DD')
	    },
	    getEventTypeIdDisplay(id) {
			return eventTypes.find(x => x.id === id).name
	    },
        async deleteProfileEvent(event){
            try {
                
                let confirmation = confirm("Are you sure you want to delete this event? This action cannot be undone.")
                
                if (confirmation === false) {
                    return;
                }
                
                await deleteProfileEvent({ProfileEvent: event});
                location.reload();
            } catch (e) {
                console.log(e);
            }
        },
    }
}
</script>

<style scoped>

</style>