<template>
    <v-container>
        <v-row>
            <div v-if="!showPassword && showAccountTypes" style="display: flex; align-items: center">
                <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>
                <span style="color: rgba(255,0,0,.5); font-weight: 800; margin-left: 5px">IMPORTANT NOTE: </span>
            </div>
            <div v-if="!showPassword && showAccountTypes" style="margin-bottom: 10px">
                <strong>User: </strong>Users are required to be granted access to individual groups within the selected organization. Users do not have access to configure settings or add/update other users on the organization.
            </div>
            <div v-if="!showPassword && showAccountTypes" style="margin-bottom: 10px">
                <strong>Admin: </strong>Admins have access to all groups and profiles within the selected organization. Admins can configure settings and add/update other users on the organization.
            </div>
            <v-col v-if="showAccountTypes" cols="12">
                <app-select
                    v-model="newUserForm.organizationAccessTypeId"
                    label="Access Type*"
                    :items="accountTypes"
                    item-text="text"
                    item-value="value"
                />
            </v-col>
            <v-col cols="6">
                <app-text-field
                    v-model="newUserForm.firstName"
                    label="First Name*"
                    error-label="First Name"
                    rules="required|max:50"
                    autocomplete="given-name"
                />
            </v-col>
            <v-col cols="6">
                <app-text-field
                    v-model="newUserForm.lastName"
                    label="Last Name*"
                    error-label="Last Name"
                    required rules="required|max:50"
                    autocomplete="family-name"
                />
            </v-col>
            <v-col cols="12">
                <app-text-field v-model="newUserForm.email" label="Email*" rules="email|required" />
            </v-col>
            <v-col v-if="showPassword" cols="12">
                <app-text-field v-model="newUserForm.password"
                                @focus="showPasswordCriteria = true"
                                type="password" 
                                label="Password*" 
                                autocomplete="new-password"
                                rules="password|required" />
                <div class="pa-2" v-if="showPasswordCriteria">
                    <div :class="[getMessageClass(newPasswordsLength)]">
                        <span v-if="newPasswordsLength">&#10003;</span><span v-else>&#10007;</span> contains 8+ characters
                    </div>
                    <div :class="[getMessageClass(newPasswordHasUppercase)]">
                        <span v-if="newPasswordHasUppercase">&#10003;</span><span v-else>&#10007;</span> contains 1 uppercase letter
                    </div>
                    <div :class="[getMessageClass(newPasswordHasLowercase)]">
                        <span v-if="newPasswordHasLowercase">&#10003;</span><span v-else>&#10007;</span> contains 1 lowercase letter
                    </div>
                    <div :class="[getMessageClass(newPasswordHasDigit)]">
                        <span v-if="newPasswordHasDigit">&#10003;</span><span v-else>&#10007;</span> contains 1 digit
                    </div>
                    <div :class="[getMessageClass(newPasswordHasSpecialCharacter)]">
                        <span v-if="newPasswordHasSpecialCharacter">&#10003;</span><span v-else>&#10007;</span> contains 1 special character
                    </div>
                </div>
            </v-col>
            <v-col v-if="showPassword" cols="12">
                <app-text-field
                    label="Confirm New Password"
                    v-model="newUserForm.confirmNewPassword"
                    type="password"
                    name="confirm-password"
                    autocomplete="new-password"
                    @focus="showConfirmPasswordIsSameMessage = true"
                />
                <div class="pa-2" v-if="showConfirmPasswordIsSameMessage">
                    <div :class="[confirmPasswordMatches ? 'password-item-good' : 'password-item-bad']">
                        <span v-if="confirmPasswordMatches">&#10003;</span><span v-else>&#10007;</span> passwords match
                    </div>
                </div>
            </v-col>
            <v-col cols="6">
                <app-text-field
                    v-model="newUserForm.phoneNumber"
                    label="Phone Number"
                    :mask="'###-###-####'"
                    autocomplete="tel"
                />
            </v-col>
            <v-col v-if="showProfessionalType" cols="6">
                <app-text-field
                    v-model="newUserForm.jobTitle"
                    label="Professional Designation"
                    autocomplete="organization-title"
                />
            </v-col>
            <v-col v-if="showProfessionalType" cols="6">
                <app-select v-model="newUserForm.professionalTypeId" label="Professional Type" :items="professionalTypes" item-text="text" item-value="value" />
            </v-col>
            <v-col cols="6" v-if="showSafetyCoach">
                <app-form-switch v-model="newUserForm.isSafetyCoach" label="Is Safety Coach" />
            </v-col>
            <v-col cols="6" v-if="showBillingContact">
                <app-form-switch v-model="newUserForm.isBillingContact" label="Is Billing Contact" />
            </v-col>
            <v-col cols="6" v-if="isSuperAdmin">
                <app-form-switch v-model="newUserForm.isOptimizedSyncEnabled" label="Is Optimized Sync Enabled" />
            </v-col>
            <v-col cols="6" v-if="isSuperAdmin">
                <app-text-field
                    v-model="newUserForm.optimizedSyncTimePeriodDays"
                    label="Optimize Sync Lookback Days"
                    rules="numeric"
                />
            </v-col>
            
        </v-row>
        
        
        <div v-if="showGroups">
            <v-divider class="mt-4 mb-4"></v-divider>
            <app-form-multiple-select
                v-model="newUserForm.groupIds"
                :items="groups"
                rules="required"
                item-text="name"
                item-value="id"
                label="Groups*"
                sublabel="(minimum of 1)"
            ></app-form-multiple-select>
        </div>
        <div v-if="showOrganizations">
            <v-divider class="mt-4 mb-4"></v-divider>
            <multiselect v-model="newUserForm.organizations"
                         :options="organizations"
                         :searchable="true"
                         label="name"
                         :multiple="true"
                         track-by="id"
                         selectLabel=""
                         deselectLabel=""
                         :max-height="600"
                         :close-on-select="false"
            ></multiselect>
        </div>
       
<!--        <app-autocomplete-->
<!--            v-model="newUserForm.groups"-->
<!--            label="Groups"-->
<!--            :items="groups"-->
<!--            item-text="name"-->
<!--            item-value="id"-->
<!--        />-->
    </v-container>
</template>

<script>

import {getGroups} from "@/services/groups.service";
import {professionalTypes} from "@/js/shared/constants"
import {getEnterpriseOrganizations} from "@/services/enterprise.service";
import Multiselect from "vue-multiselect";

export default {
    name: "AddUserForm",
    components: {Multiselect},
    props: {
        newUserForm: {
            required: true,
            type: Object
        },
        showGroups: {
            type: Boolean,
            default: true
        },
        showOrganizations: {
            type: Boolean,
            default: false
        },
        showBillingContact: {
            type: Boolean,
            default: true
        },
        showSafetyCoach: {
            type: Boolean,
            default: true
        },
        showAccountTypes: {
            type: Boolean,
            default: true
        },
        showPassword: {
            type: Boolean,
            default: false
        },
        showProfessionalType: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.init()

    },
    data() {
        return {
            groups: [],
            organizations: [],
            professionalTypes,
            showPasswordCriteria: false,
            showConfirmPasswordIsSameMessage: false,
            isSuperAdmin: false,
            accountTypes: [
                {
                    text: 'User',
                    value: 1
                },
                {
                    text: 'System Admin',
                    value: 2
                }
            ],
            accessTypeString: "Access Type*"
        }
    },
    computed:{
        newPasswordsLength() {
            const {password} = this.newUserForm
            return password.length > 7
        },
        newPasswordHasUppercase() {
            const {password} = this.newUserForm
            return password.match(/(?=.*[A-Z])/)
        },
        newPasswordHasLowercase() {
            const {password} = this.newUserForm
            return password.match(/(?=.*[a-z])/)
        },
        newPasswordHasDigit() {
            const {password} = this.newUserForm;
            let temp = password.match(/(?=.*\d)/);
            return password.match(/(?=.*\d)/)

        },
        newPasswordHasSpecialCharacter() {
            const {password} = this.newUserForm;
            let temp = password.match(/(?=.*\W)/);
            return password.match(/(?=.*\W)/)

        },
        confirmPasswordMatches() {
            const {password, confirmNewPassword} = this.newUserForm;
            return confirmNewPassword.length > 0 && password === confirmNewPassword

        },
        newPasswordIsValid() {
            return this.newPasswordsLength && this.newPasswordHasUppercase && this.newPasswordHasLowercase && this.newPasswordHasDigit && this.newPasswordHasSpecialCharacter
        },
        formIsValid() {
            return this.newPasswordIsValid && this.confirmPasswordMatches && this.changePasswordForm.oldPassword.length > 0
        },
        changePasswordButtonEnabled() {
            const {
                password,
                confirmNewPassword
            } = this.newUserForm;

            return !!password && !!confirmNewPassword

        },  
    },
    created() {
        
        this.setGroupsAndOrgs();
        this.setAccountTypes();
    },
    watch:{
        $props: {
            handler() {
                this.setAccountTypes();
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        getMessageClass(valid) {
            if (valid) {
                return 'password-item-good'
            }
            else if (!valid && !this.submitClicked) {
                return 'password-item-bad'
            } else {
                return 'password-item-bad-error'
            }
        },
        async getGroups() {
            const response = await getGroups();
            if (!response.isSuccess) {
                return
            }
            this.groups = response.groups;            
        },
        async getOrganizations() {

            if (!this.$store.state.currentAccount.isEnterpriseAdminOrg)
                return;
            
            const response = await getEnterpriseOrganizations(true);
            if (!response.isSuccess) {
                return
            }
            this.organizations = response.organizations;
        },
        async init(){
            this.setAccountTypes();
        },
        setAccountTypes() {
            if (!this.$store.state.currentAccount) {
                this.newUserForm.organizationAccessTypeId = 5
            }
            else if (this.newUserForm.isSafetyCoach){
                this.accountTypes = [
                    {
                        text: 'User',
                        value: 1
                    }
                ];
                this.accessTypeString = "Sway Account Type*"
            }else{
                this.accountTypes = [
                    {
                        text: 'User',
                        value: 1
                    },
                    {
                        text: 'System Admin',
                        value: 2
                    }
                ];
                this.accessTypeString = "Access Type*"
            }
        },
        setGroupsAndOrgs(){
            //if parent/individual sign up (unauthenticated)
            if (!this.$store.state.currentAccount){
                this.showOrganizations = false;
                this.showGroups = false;
                this.showSafetyCoach = false;
                this.showBillingContact = false;
                this.showPassword = true;
                this.showProfessionalType = false;
                this.showAccountTypes = false;
            }
            else if (this.$store.state.currentAccount.isEnterpriseAdminOrg){
                this.getOrganizations();
                this.showOrganizations = true;
                this.showGroups = false;
                this.showPassword = false;
                this.showSafetyCoach = true;
                this.showBillingContact = true;
                this.showProfessionalType = true;
                this.showAccountTypes = true;
            }else{
                this.showOrganizations = false;
                this.showGroups = true;
                this.showPassword = false;
                this.showSafetyCoach = true;
                this.showBillingContact = true;
                this.showProfessionalType = true;
                this.getGroups();
                this.showAccountTypes = true;
            }
            
            if (this.$store.state.currentAccount && this.$store.state.currentAccount.isSuperAdmin) {
                this.isSuperAdmin = true;
            }else{
                this.isSuperAdmin = false;
            }
        }
    }
   
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__tag{
    background: var(--v-primary-base);
}

.multiselect__option--highlight{
    background: var(--v-primary-base);
}

.multiselect__content-wrapper{
    max-height: 600px!important;
}

</style>

<style scoped>

</style>