<template>
	<div>
		

		<div id="clinical-subscriptions" v-if="form" >
			<div class="inline-block-field">
				<app-form-date-picker v-model="form.subscriptionStartDate" label="Subscription Start Date"></app-form-date-picker>
			</div>
			<div class="inline-block-field">
				<app-form-date-picker v-model="form.subscriptionEndDate" label="Subscription End Date"></app-form-date-picker>
			</div>
			<div class="inline-block-field">
				<app-select v-model="form.billingCycle" label="Subscription Cycle" :items="billingCycleTypes" item-text="text" item-value="value" />
			</div>
			<div class="inline-block-field" v-if="!form.isManualBilling && !form.isDemo && !form.isFree">
				<app-text-field v-model="form.profilePrice" label="Per Profile Price" rules="decimal"></app-text-field>
			</div>
			<div class="inline-block-field" v-if="!form.isManualBilling && !form.isDemo && !form.isFree">
				<app-select v-model="form.packageTier" label="Profile Type" :items="clinicalProfileTypes" item-text="text" item-value="value" />
			</div>
			<div class="inline-block-field" v-if="!form.isManualBilling && !form.isDemo && !form.isFree">
				<app-text-field v-model="form.systemFee" label="System Fee" rules="decimal"></app-text-field>
			</div>
			<div class="inline-block-field" v-if="!form.isDemo && !form.isFree">
				<app-form-checkbox v-model="form.isManualBilling" label="Enable Manual Billing (reminder email only)" checkboxLeftSide></app-form-checkbox>
			</div>
            <div class="inline-block-field" v-if="form.isManualBilling">
                <app-text-field v-model="form.manualEstimatedTotal" label="Estimated Total (manual billing only)"></app-text-field>
            </div>
			<div class="inline-block-field" v-if="!form.isManualBilling && !form.isFree">
				<app-form-checkbox v-model="form.isDemo" label="Enable Trial Status (free trial)" checkboxLeftSide></app-form-checkbox>
			</div>
			<div class="inline-block-field" v-if="!form.isManualBilling && !form.isDemo">
				<app-form-checkbox v-model="form.isFree" label="Is Free (not trial)" checkboxLeftSide></app-form-checkbox>
			</div>
			<div class="inline-block-field">
				<app-form-checkbox v-model="form.doNotRenew" label="Do Not Renew" checkboxLeftSide></app-form-checkbox>
			</div>
            <div class="inline-block-field">
                <app-form-checkbox v-model="form.updateFutureSubscriptions" label="Create Future Subscriptions (note this will replace any other future subscriptions for this subscription type)" checkboxLeftSide></app-form-checkbox>
            </div>
		</div>
		
	
	</div>
</template>

<script>
import {packageTiers, billingCycleTypes, clinicalPackageTiers} from "@/js/shared/constants";

export default {
	name: "ClinicalSubscriptionCreateUpdateForm",
	props: {
		form: {
			required: true
		}
	},
	data() {
		return {
			clinicalProfileTypes: clinicalPackageTiers,
			billingCycleTypes,
			sportsProfileTypes: packageTiers,
		}
	}
}
</script>

<style scoped>

</style>