<template>
  <div class="pdf-page-header-container" id="pdf-page-header">
    <div class="pdf-header-row">
      <div style="width: 33%; text-align: left; display: inline-block; padding-left: 10px">Sway</div>
      <div style="width: 34%; text-align: center; display: inline-block;">CLINICAL REPORT</div>
      <div style="width: 33%; text-align: right; display: inline-block;; padding-right: 10px; font-size: 8px; background-color: red">DATE: {{ displayDate }}</div>
    </div>
   
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "PageHeader",
  computed: {
    displayDate() {
      return moment().format('M/DD/YYYY')
    }
  }
}
</script>

<style scoped>


</style>