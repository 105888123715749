import ProfileTest from "@/js/shared/classes/ProfileTest/ProfileTest";



export default class SwayProfileTest extends ProfileTest {
    constructor(inputData) {
        super(inputData);

            
    }

    
}