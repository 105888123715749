<template>
    <div> 
        <highcharts :options="chartOptions" />
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";

export default {
    components: {
        highcharts: Chart,
    },
    props: {
        organizations: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: "column",
                    style: {
                        fontFamily: "Arial",
                        fontSize: "12px",
                    },
                },
                title: {
                    text: null,
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: "#2E2E2E",
                            fontSize: "12px",
                            fontWeight: "bold",
                        },
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        style: {
                            color: "#2E2E2E",
                            fontSize: "12px",
                            fontWeight: "bold",
                        },
                    },
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        dataLabels: {
                            enabled: true,
                            color: "#2E2E2E",
                            style: {
                                textOutline: "none",
                                fontSize: "12px",
                                fontWeight: "bold",
                            },
                        },
                    },
                },
                series: [
                    {
                        name: "Total Test Count",
                        data: [],
                        color: "#E64B35",
                    },
                    {
                        name: "Total Profile Count",
                        data: [],
                        color: "#FAC051",
                    },
                    {
                        name: "mBESS Count",
                        data: [],
                        color: "#3690C0",
                    },
                    {
                        name: "mCTSIB Count",
                        data: [],
                        color: "#669966",
                    },
                    {
                        name: "CDC4 Count",
                        data: [],
                        color: "#A8866F",
                    },
                ],
            },
        };
    },
    mounted() {
        
        //if this.organizations is empty, then return and do nothing
        if (this.organizations.length === 0) {
            return;
        }
        
        // Sort the data by the totalTestCount property in descending order
        this.organizations.sort((a, b) =>
            b.totalTestCount - a.totalTestCount
        );

        // Set the x-axis categories
        this.chartOptions.xAxis.categories = this.organizations.map(
            (org) => org.organizationName
        );

        // Set the y-axis data for each series
        this.chartOptions.series[0].data = this.organizations.map(
            (org) => org.totalTestCount
        );
        this.chartOptions.series[1].data = this.organizations.map(
            (org) => org.totalProfileCount
        );
        this.chartOptions.series[2].data = this.organizations.map(
            (org) => org.mBESSCount
        );
        this.chartOptions.series[3].data = this.organizations.map(
            (org) => org.mCTSIBCount
        );
        this.chartOptions.series[4].data = this.organizations.map(
            (org) => org.cdC4Count
        );
      this.chartOptions.series[5].data = this.organizations.map(
          (org) => org.eventCount
      );
    },
    methods: {
        
    },
};

</script>