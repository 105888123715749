<template>
    <div style="margin: 0px 0px"  >
        <!--    <div class="pdf-section-title">-->
        <!--      {{ title }}-->
        <!--    </div>-->
        <div style="background-color: #F4F4F4; display: flex; padding: 5px 10px" 
             :style="{
            borderLeft: headerBorder ? '1px solid var(--main-border-color)' : null,
            borderRight: headerBorder ? '1px solid var(--main-border-color)' : null,
            borderTop: headerBorder ? '1px solid var(--main-border-color)' : null,
            
        }">
            <div style="font-weight: 800; text-transform: uppercase; color: #898C90; flex: 2">
                {{title}}
            </div>
            
            <div v-if="!titleOnly" style="font-weight: 800; text-transform: uppercase;  color: #898C90; flex: 3; text-align: center">
                Interpretation
            </div>
            <div v-if="!titleOnly" style="font-weight: 800; text-transform: uppercase; color: #898C90; flex: 1; text-align: right; display: flex">
                <div style="flex: 1; text-align: center">
                    Percentile
                </div>

                 
            </div>

        </div>
        <div class="pdf-content-container" :style="{padding: noPadding ? '0' : null}">
            <slot ></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "SectionWrapper",
    props: {
        title: {
            required: true,
            type: String
        },
        titleOnly: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
        },
        headerBorder: {
            type: Boolean,
        }
    }
}
</script>

<style scoped>

</style>