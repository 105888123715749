<template>
	<div>
		
		<div style="padding: 2rem" class="sway-visa-card-container">

			
			<div class="sway-visa-content" style="z-index: 1000">
				<img :src="swayVisaLogo" class="visa-logo elevate">
				<div class="cardholder-info elevate">
					
				</div>
				<div>
					Visa Holder 
				</div>
				<div class="cardholder-name">
					{{profile.firstName}} {{profile.lastName}}
				</div>
				
				<div v-if="profileAccessLinkUrl" class="qr-code-container elevate">
					<qrcode-vue :value="profileAccessLinkUrl" :size="qrCodeSize" foreground="#fff" background="transparent" level="H" />
				</div>

                <div style="text-align: center">
                    <span>(Click to copy)</span><br>
                    <span
                        @click="copyProfileAccessLink(profileAccessLinkUrl)"
                        class="mt-2"
                        style="text-decoration: underline"
                    >{{profileAccessLinkUrl}}</span>
                </div>
                
			</div>
			<img :src="swayVisaBackgroundLogo" class="background-logo-container" >
			
			
			
		</div>
		
	</div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import swayVisaLogo from "@/assets/images/swayvisa-logo.svg";
import swayVisaBackgroundLogo from "@/assets/images/sway-visa-background-logo.svg";
import {SET_SNACKBAR} from "@/store/mutation-types";

export default {
	name: "SwayVisaCard",
	components: {
		QrcodeVue,
	},
	props: {
		profile: {
			required: true
		},
		profileAccessLinkUrl: {
			required: true
		}
	},
	data() {
		return {
			qrCodeSize: 250,
			swayVisaLogo,
			swayVisaBackgroundLogo
		}
	},
    methods: {
        async copyProfileAccessLink() {
            try {
                await navigator.clipboard.writeText(this.profileAccessLinkUrl)
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Link Copied to Clipboard',
                    color: 'success',
                    open: true
                });
            } catch ($e) {
            }
        },
    }
}
</script>

<style scoped>
.elevate {
	z-index: 3;
}

.sway-visa-card-container {
	background: linear-gradient(45deg, rgba(0,0,0,1), rgba(51,51,51,1));
	margin: 1rem;
	padding: 1rem;
	color: white;
	border-radius: 1rem;
	
	overflow: hidden;
	position: relative;
	box-shadow: 0px 0px 20px rgba(51,51,51,.5);
}

.sway-visa-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 3;
	color: #f2f2f2
}

.visa-logo {
	max-width: 50%
}

.qr-code-container {
	/*background-color: white;*/
	margin-top: 2rem;
	align-self: center;
}

.background-logo-container {
	position: absolute;
	top: -10%;
	right: -10%;
	height: 70%;
	transform: rotate(-45deg);
	z-index: 0
}

.cardholder-info {
	padding-top: 1.5rem
}

.cardholder-name {
	font-size: 1.2rem;
	font-weight: 600;
	
}
</style>