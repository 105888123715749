<template>
<!--    <div v-if="measureResults.hasDuplicateTests">-->
<!--        <table class="pdf-table" style="border: none">-->
<!--            <thead>-->
<!--            <tr>-->
<!--                <th style="background-color: white;border: none"></th>-->
<!--                <th-->
<!--                    v-for="(item, itemIndex) in sessionDetailsHeaderLength"-->
<!--                    style="border: none;padding: 5px 10px; color: black!important; background-color: white"-->
<!--                    :style="{-->
<!--                                        opacity: measureResults.sessionDetails.testsArray[itemIndex].isExcluded ? 0.5 : 1,-->
<!--                                        textDecoration: measureResults.sessionDetails.testsArray[itemIndex].isExcluded ? 'line-through' : null-->
<!--                                    }"-->
<!--                >-->
<!--                    Test {{itemIndex + 1}}-->
<!--                </th>-->
<!--            </tr>-->
<!--            </thead>-->

<!--            <tr v-for="(row, rowIndex) in measureResults.sessionDetails.resultsTable" :key="rowIndex">-->
<!--                <td class="pdf-table-cell" style="padding: 5px 10px">-->
<!--                    {{row.label}}-->
<!--                </td>-->
<!--                <td-->
<!--                    class="pdf-table-cell"-->
<!--                    style="padding: 5px 10px"-->
<!--                    v-for="(test, testIndex) in row.tests"-->
<!--                    :key="testIndex"-->
<!--                    :style="{-->
<!--                                        color: test.isFlagged  ? 'red' : null,-->
<!--                                        opacity: measureResults.sessionDetails.testsArray[testIndex].isExcluded ? 0.5 : 1,-->
<!--                                        textDecoration: measureResults.sessionDetails.testsArray[testIndex].isExcluded ? 'line-through' : null-->
<!--                                    }"-->


<!--                >-->
<!--                    {{test.value}}-->
<!--                </td>-->
<!--            </tr>-->
<!--	-->
<!--	        -->
<!--        </table>-->
<!--    </div>-->
	
	
	
	
	<div >
		<table class="pdf-table" style="border: none">
			<thead>
			<tr>
				<th style="background-color: white;border: none"></th>
				<th
					v-for="(item, itemIndex) in sessionDetailsHeaderLength"
					style="border: none;padding: 5px 10px; color: black!important; background-color: white"
					:style="{
                                        opacity: measureResults.sessionDetails.testsArray[itemIndex].isExcluded ? 0.5 : 1,
                                        textDecoration: measureResults.sessionDetails.testsArray[itemIndex].isExcluded ? 'line-through' : null
                                    }"
				>
					Test {{itemIndex + 1}}
				</th>
			</tr>
			</thead>
			
			<tr v-for="(row, rowIndex) in measureResults.sessionDetails.resultsTable2" :key="rowIndex">
				<td
					class="pdf-table-cell"
					style="padding: 5px 10px"
					v-for="(item, itemIndex) in row"
					:key="itemIndex"
                    :class="[(item.isFlagged ? 'flagged' : '')]"
                    :style="{
                                        color: item.isFlagged  ? 'red' : null,
                                        opacity: item.isExcluded ? 0.5 : 1,
                                        textDecoration: item.isExcluded ? 'line-through' : null
                                    }"
				
				
				>
					<div v-html="item.text"></div>
				</td>
			</tr>
		
		
		</table>
	</div>
	
	
<!--    <div v-else-if="allDetailRows.length > 1">-->
<!--        <div >-->
<!--            <table class="pdf-table">-->
<!--                <tr v-for="(result, resultIndex) in allDetailRows" :key="resultIndex" :style="{color: result.isFlagged ? 'red' : null}">-->
<!--                    <td class="pdf-table-cell" style="padding: 5px 10px" >-->
<!--                        {{result.key}} -->
<!--                    </td>-->
<!--                    <td class="pdf-table-cell" style="padding: 5px 10px">-->
<!--                        {{result.value }}-->
<!--                    </td>-->
<!--                </tr>-->
<!--            </table>-->

<!--        </div>-->
<!--    </div>-->
</template>

<script>
export default {
    name: "TestDetailTable",
    props: {
        measureResults: {
            required: true
        },
        allDetailRows: {
            required: true
        },
	    editMode: {
		    type: Boolean
	    }
    },
    computed: {
        sessionDetailsHeaderLength() {
            return this.measureResults.sessionDetails.columnCount;
        },
    }
}
</script>

<style scoped>

.excluded {
    opacity: .5
}
.flagged {
    color: #ff6b6b;
}

</style>