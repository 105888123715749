<template>
	<div style="padding: 1rem; width: 100%">
		<div v-if="invoice.voidReason">
			<AppChip>VOID Reason: {{invoice.voidReason}}</AppChip>
		</div>
		<table>
			<tbody>
				<tr v-for="(item, index) in items" :key="index">
					<td style="padding: 0.5rem">{{item.text}}</td>
					<td style="padding: 0.5rem">{{item.value}}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "UnsentInvoicesDetails",
	props: {
		invoice: {
			required: true
		}
	},
	data() {
		return {
			
		}
	},
	computed: {
		items() {
			const {invoice} = this;
			return [
				{
					text: "Description",
					value: invoice.description
				},
				{
					"text": "Invoice Date",
					"value": invoice.invoiceDate
				},
				{
					text: "Created On",
					value: invoice.createdOn
				},
				{
					text: "Amortization Start Date",
					value: invoice.amortizationStartDate
				},
				{
					text: "Amortization End Date",
					value: invoice.amortizationEndDate
				},
				{
					text: "End Period Date",
					value: invoice.endPeriodOn
				},
				
			]
		}
	}
}
</script>

<style scoped>

</style>