import moment from "moment";
import {billingCycleTypes, subscriptionColors} from "@/js/shared/constants";
import SportsSubscription from "@/js/shared/classes/Subscription/SportsSubscription";
import ClinicalSubscription from "@/js/shared/classes/Subscription/ClinicalSubscription";

const _formatSubscription = Symbol()

export default class Subscriptions {
    constructor(data) {
        let formattedData = data.map(x => {
            return x.isPrePaid ? new SportsSubscription(x) : new ClinicalSubscription(x)
        })

        
        
        
        
        // Sort by start date
        this.data =  formattedData.sort((a, b) => {
            if (moment(a.startDate.value).isBefore(b.startDate.value)) return 1
            return -1
        });
        this.findIssues();

        this.subscriptionChart = this.getSubscriptionTimelineChart()

    }
    
    findIssues() {
        
        this.data.forEach(x => {
            
            let doesOverlap = false;
            
            // Check if overlaps with other subscriptions
            doesOverlap = this.data.filter(y => {
                if (x.productSubscriptionId !== y.productSubscriptionId) {
                    let startDateBeforeEndDate = moment(x.startDate.value).isBefore(y.endDate.value)
                    let endDateBeforeStartDate = moment(x.endDate.value).isAfter(y.startDate.value)
                    return startDateBeforeEndDate  && endDateBeforeStartDate
                }
            }).length > 0
            
            x.issues = {
                doesOverlap
            }
        })
    }

    getSubscriptionTimelineChart() {
        let seriesData = [];
        this.data.forEach(sub => {

            let startDate = moment(sub.startDate.value);
            let endDate = moment(sub.endDate.value)

            seriesData.push({
                x: Date.UTC(startDate.year(), startDate.month(), startDate.date()),
                x2: Date.UTC(endDate.year(), endDate.month(), endDate.date()),
                y: 0,
                partialFill: null,
                color: `rgba(${sub.color.r},${sub.color.g},${sub.color.b},.5)`,
                borderColor: `rgba(${sub.color.r},${sub.color.g},${sub.color.b},1)`,
                subscription: sub
            })
        })

        return {
            subscriptionChart: {
                type: 'xrange',
                height: 300

            },
            title: {
                text: ' '
            },
            accessibility: {
                point: {
                    descriptionFormat: '{add index 1}. {yCategory}, {x:%A %e %B %Y} to {x2:%A %e %B %Y}.'
                }
            },
            xAxis: {
                type: 'datetime',
                plotLines: [{
                    color: '#E0E0E0',
                    width: 2,
                    value: Date.now(),
                    label: {
                        text: 'Today'
                    }
                }]
            },
            yAxis: {
                title: {
                    text: ''
                },
                // categories: ['Prototyping', 'Development', 'Testing'],
                reversed: true
            },
            tooltip: {
                useHTML: true,
                formatter() {
                    const {subscription} = this.point;
                    return `<div>${subscription.startDate.display} to ${subscription.endDate.display}</div><br/>
                            <div><h3>${subscription.displayName}</h3></div>
                           
                           <div>Profile Count: ${subscription.profileCount.display}</div>
                           <div>Profile Price: ${subscription.profilePrice.display}</div>
                            <div>System Fee: ${subscription.systemFee.display}</div>
                             <div>Total: <strong>${subscription.total.display}</strong></div>
    `
                },
                outside: true
            },
            series: [{
                name: 'Subscription',
                // pointPadding: 0,
                // groupPadding: 0,
                borderColor: 'gray',
                pointWidth: 40,
                data: seriesData,
                dataLabels: {
                    enabled: true,
                    useHTML: false,
                    formatter() {
                        const {subscription} = this.point;
                        return subscription.displayName
                        return `<div>${subscription.startDate.display} to ${subscription.endDate.display}</div><br/>
                            <div><h3>${subscription.displayName}</h3></div>

                        <div>Profile Count: ${subscription.profileCount.display}</div>
                        <div>Profile Price: ${subscription.profilePrice.display}</div>
                        <div>System Fee: ${subscription.systemFee.display}</div>
                        <div>Total: <strong>${subscription.total.display}</strong></div>
                            `
                    }
                }
            }],
            credits: {
                enabled: false
            }
        }
    }

    static getSubscriptionModel() {
        return {
            startDate: null,
            endDate: null,
            isTrial: null,
            systemFee: null,
            isManualBilling: null,
            profilePrice: null,
            billingCycle: null
        }
    }

    static billingCycleTypes = [
        {
            text: 'Annual',
            value: 1
        },
        {
            text: 'Monthly',
            value: 2
        },
        {
            text: 'Quarterly',
            value: 3
        },
    ]



}