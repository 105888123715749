export default {
    data() {
        return {
            apiSnackbar: {
                open: false,
                text: '',
                color: 'success'
            },
        }
    },
    methods: {
        async callApi(apiFunction, errorMessage) {
            
            const result = await apiFunction();
            if (result.data.isSuccess) {
                // this.apiSnackbar = {
                //     open: true,
                //     text: 'Successful api call!',
                //     color: 'success'
                // }
                return result.data
            } else {
                this.apiSnackbar = true;
                return {
                    errors: [],
                    isSuccess: false,
                    data: null
                }
            }
        }
    }
}