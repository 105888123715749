<template>
    <div class="pa-2">
        <app-card  :title="title" center-title>
            <div style="display: flex; flex-direction: column; align-items: center; color: var(--v-text-lighten2)">
<!--                <div>{{ title }}</div>-->
                <div>
                    <span style="background-color: var(--v-secondary-base); height: 10px; width: 10px; border-radius: 5px"></span>
                    <h1 style="margin: 20px">{{value}}</h1>
                </div>
            </div>
            
        </app-card>
    </div>
</template>

<script>
export default {
    name: "AppStatCard",
    props: {
        title: {
            type: String
        },
        value: {
            required: true
        }
    }
}
</script>

<style scoped>

</style>