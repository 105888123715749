<template>
	<div>
		<div v-for="(item, index) in items" :key="index" >
			{{item.label}}: {{item.value}}
		</div>
	</div>
</template>

<script>
import moment from "moment";
import {getEventTypeById} from "@/js/shared/helper";

export default {
	name: "EventSection",
	props: {
		event: {
			required: true
		}
	},
	computed: {
		items() {
			const {event} = this;
			let dateFormat = 'MMMM D, YYYY'
			return [
				{
					label: 'Name',
					value: event.name ? event.name : 'Event'
				},
				{
					label: 'Type',
					value: event.eventTypeId ? getEventTypeById(event.eventTypeId)?.name : '-'
				},
				{
					label: 'Start Date',
					value: event.startedOn ? moment(event.startedOn).format(dateFormat) : '-'
				},
				{
					label: 'End Date',
					value: event.endedOn ? moment(event.endedOn).format(dateFormat) : '-'
				},
				{
					label: 'Notes',
					value: event.note ? event.note : '-'
				},
				
			]
		}
	}
}
</script>

<style scoped>

</style>