<template>
    <div>
        <div v-if="!loading">
            <page-header :title="account.selectedOrganization.name" subtitle="Billing"></page-header>
            <app-tabs :value="tab">
                <app-tab v-for="tab in tabs" @click="goToRoute(tab.route)" :icon="tab.icon">{{ tab.label }}</app-tab>
            </app-tabs>
            <router-view
                :invoices="invoices"
                v-if="billingInfo && invoices && billingOverview"
                :billingInfo="billingInfo"
                :billingOverview="billingOverview"
                @setBillingInfo="setBillingInfo($event)"
                @getInvoices="getInvoices()"
            ></router-view>
        </div>
        <div v-else>
            <AppPageLoader text="Loading Organization Billing..." />
        </div>
    </div>
</template>

<script>
import {getBillingInfo, getBillingOverview, getInvoices} from "@/services/billing.service";
import moment from "moment";
import {formatMoney, mapInvoiceToDisplay} from "@/js/shared/helper";
import {superAdminSymbol} from "@/js/shared/constants";

export default {
    name: "Billing",
    props: ['account'],
    data() {
        return {
            loading: false,
            billingInfo: null,
            billingOverview: null,
            organizationPreferences: null,
            invoices: null,
            loadingInvoices: false,
        }
    },
    async mounted() {
        this.loading = true
        await Promise.all([ this.getBillingInfo(), this.getInvoices(), this.getBillingOverview()]);

        this.loading = false
    },
    methods: {
        async getInvoices() {
            this.loadingInvoices = true;
            const response = await getInvoices();
            if (!response.isSuccess) {
                return
            }
            const {invoices} = response
            invoices.forEach(invoice => mapInvoiceToDisplay(invoice));
            this.invoices = invoices.sort(function (a,b) {
                return b.id - a.id;
            });
            this.loadingInvoices = false;
        },
        goToRoute(route) {
            this.$router.push(route)
        },
        async getBillingInfo() {
            const response = await getBillingInfo();
            if (!response.isSuccess) {
                return
            }
            const {organization, billToOrgId, billToOrgName} = response;
            this.billingInfo = organization;
            this.billingInfo.billToOrgId = billToOrgId;
            this.billingInfo.billToOrgName = billToOrgName;
        },
        setBillingInfo(billingInfoModel) {
            this.billingInfo = billingInfoModel.organization;
            this.billingInfo.billToOrgId = billingInfoModel.billToOrgId;
            this.billingInfo.billToOrgName = billingInfoModel.billToOrgName;
        },
        async getBillingOverview() {
            const response = await getBillingOverview();
            if (!response.isSuccess) {
                return
            }
            this.billingOverview = response;
        },
    },
    computed: {
        tab() {
            let value = 0;
            let path = this.$route.path.split('/');
            let last = path[2];

            switch (last) {
                case 'billing-overview':
                    value = 0;
                    break;
                case 'billing-settings':
                    value = 1;
                    break;
                case 'billing-history':
                    value = 2;
                    break;
                case 'view-invoices':
                    value = 3;
                    break;
                case 'profiles-tested':
                    value = 4;
                    break;
                case 'create-invoice':
                    value = 5;
                    break;
            }
            
            return value
        },
        tabs() {
            var myTabs = [
                {
                    label: 'Billing Overview',
                    route: '/billing/billing-overview',
                    icon: 'mdi-credit-card-outline'
                },
                {
                    label: 'Billing Settings',
                    route: '/billing/billing-settings',
                    icon: 'mdi-cog-outline'
                },
                {
                    label: 'Billing History',
                    route: '/billing/billing-history',
                    icon: 'mdi-table'
                },
                {
                    label: 'View Invoices',
                    route: '/billing/view-invoices',
                    icon: 'mdi-format-list-bulleted'
                }
            ];
            if (this.$store.state.currentAccount.isSuperAdmin || (this.billingOverview && (this.billingOverview.hasChildSportsOrgs || this.billingOverview.hasChildClinicalOrgs)))
            {
                myTabs.push(
                {
                    label: 'Profiles Tested',
                    route: '/billing/profiles-tested',
                    icon: 'mdi-check-circle-outline'
                });
            }
            if (this.$store.state.currentAccount.isSuperAdmin)
            {
                myTabs.push(
                {
                    label: `${superAdminSymbol} Create/Edit Invoice`,
                    route: '/billing/create-invoice',
                    icon: 'mdi-folder-plus'
                });
            }
            
            return myTabs;
        }
    }
}
</script>

<style scoped>

</style>