<template>
	<div class="pa-4">
		<v-container fluid>
			<v-row>
				<v-col sm="12" md="6" lg="4">
					<v-simple-table>
						<tbody v-for="(item, index) in datesItems" :key="index">
							<tr>
								<td>
									{{item.label}}
								</td>
								<td>
									{{item.value}}
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-col>
				<v-col sm="12" md="6" lg="4">
					<v-simple-table>
						<tbody v-for="(item, index) in pricingItems" :key="index">
						<tr>
							<td style="color: #757575">
								{{item.label}}
							</td>
							<td>
								{{item.value}}
							</td>
						</tr>
						</tbody>
					</v-simple-table>
				</v-col>
			</v-row>
		</v-container>

	</div>
</template>

<script>
import {formatMoney} from "@/js/shared/helper";
import {billingCycleTypes} from "@/js/shared/constants";

export default {
	name: "SportsSubscriptionsTableItemExpandSection",
	props: {
		data: {
			required: true
		}
	},
	data() {
		return {
			
		}
	},
	computed: {
		datesItems() {
			const {data} = this;
			return [
				{
					label: 'Start Date',
					value: data.startDate.display
				},
				{
					label: 'End Date',
					value: data.endDate.display
				},
				{
					label: 'Duration',
					value: data.duration.display
				},
				{
					label: 'Billing Cycle',
					value: billingCycleTypes.find(x => x.value === data.billingCycle).text
				},
			]
		},
		
		pricingItems() {
			const {data} = this;
			
			return [
				{
					label: 'Profile Count',
					value: data.profileCount.display
				},
				{
					label: 'Profile Price',
					value: data.profilePrice.display
				},
				{
					label: 'System Fee',
					value: data.systemFee.display
				},
				{
					label: 'Total',
					value: data.total.display
				},
			]
		}
	}
}
</script>

<style scoped>

</style>