<template>
    <app-card class="custom-card" title="Daily Tests">

<!--        <h2 class="text-center ma-2">Daily Tests</h2>-->

        <div v-if="loading" style="display: flex; justify-content: center; align-items: center; min-height: 200px">
            <app-loader></app-loader>
        </div>
        <div v-else>
            <div class="ml-4 mb-4">
                <v-chip
                    label
                    v-for="item in chips"
                    class="ma-2"
                    :color="item.value === chartDataToDisplay ? 'primary' : 'light'"
                    @click="selectChartDataType(item.value)"
                >{{item.label}}</v-chip>
            </div>

            <highcharts  :options="chartOptions" v-if="chartOptions"></highcharts>
        </div>

    </app-card>
</template>

<script>
import moment from "moment";

export default {
    name: "TestDataSummaryChart",
    props: {
        testsData: {
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chartDataToDisplay: 'totalTestCount',
            chips: [
                {
                    label: 'All',
                    value: 'totalTestCount'
                },
                {
                    label: 'COVID',
                    value: 'covidTestCount'
                },
                {
                    label: 'Balance',
                    value: 'balanceTestCount'
                },
                {
                    label: 'Reaction Time',
                    value: 'reactionTimeTestCount'
                },
                {
                    label: 'Impulse Control',
                    value: 'impulseControlTestCount'
                },
                {
                    label: 'Inspection Time',
                    value: 'inspectionTimeTestCount'
                },
                {
                    label: 'Memory',
                    value: 'memoryTestCount'
                },
                {
                    label: 'Number Counting',
                    value: 'numberCountingTestCount'
                },
            ]
        }
    },
    methods: {
        selectChartDataType(value) {
            this.chartDataToDisplay = value;
            this.setupCharts()
        },

    },
    computed: {
        chartOptions() {
            if (!this.testsData) return null
            
            let categories = [];
            let data = [];

            this.testsData.forEach(day => {
                categories.push(moment(day.date).format('M/D/YY'))
                data.push(day[this.chartDataToDisplay]);
                // covidTestsCategories.push(moment(day.date).format('M/D/YY'))
                // covidTestsData.push(day.covidTestCount);
            })

            return {
                title: {
                    text: '',

                },
                chart: {
                    // type: 'column'
                },
                credits: {
                    enabled: false
                },
                subtitle: {
                    text: ''
                },

                yAxis: {
                    title: {
                        text: 'Tests per day'
                    }
                },

                xAxis: {
                    accessibility: {
                        rangeDescription: 'Range: 2010 to 2017'
                    },
                    categories: categories
                },

                plotOptions: {
                    series: {
                        borderRadius: 0,
                        label: {
                            connectorAllowed: false
                        },
                        marker: {
                            enabled: false
                        }
                        // pointStart: 2010
                    }
                },

                series: [{
                    name: 'Test per day',
                    data: data,
                    color: "#60C1F8"
                },
                ],

            }
        }
    }
}
</script>

<style scoped>

</style>