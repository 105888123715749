<template>
    <div>
        <PageHeader title="Settings" />

        <app-card class="pa-4">
            <div v-if="fields">
                <h3>Mobile Security Preferences</h3>
                <div class="ma-2">
                    <app-form-checkbox
                        checkbox-left-side
                        label="Require PIN"
                        v-model="fields.isPasscodeEnabled.value"
                        :helper-message="fields.isPasscodeEnabled.helperMessage"
                    ></app-form-checkbox>


                </div>
                <h3>COVID-19 Settings</h3>
                <div class="ma-2">
                    <app-form-checkbox
                        checkbox-left-side
                        label="Enable COVID-19 Screening Options"
                        v-model="fields.enableCovid19Screening.value"
                        
                    ></app-form-checkbox>
                    <app-form-checkbox
                        checkbox-left-side
                        label="Send COVID-19 Screening alert if profile skips temperature input"
                        v-model="fields.flagTestOnCovidTemperatureSkip.value"
                        
                    ></app-form-checkbox>
                    <app-form-checkbox
                        checkbox-left-side
                        label="Include Direct Contact questions in covid screening"
                        v-model="fields.enableCovidContactQuestions.value"
                        
                    ></app-form-checkbox>
                </div>
                <h3>Other Mobile App Settings</h3>
                <div class="ma-2">
                    <app-form-checkbox checkbox-left-side label="Use Metric System" v-model="fields.isMetric.value" ></app-form-checkbox>
                </div>
                <div class="ma-2" >
                    <app-btn 
                        @click="onFormSubmit"
                        :loading="savingChanges"
                    >Save</app-btn>
                </div>
            </div>

        </app-card>


    </div>
</template>

<script>

import {
    getOrganizationPreferences, 
    updateOrganizationPreferences,
    organizationPreferencesModel
} from '@/services/organization.service'

export default {
    name: "Settings",
    components: {

    },
    data() {
        return {
            fields: null,
            organizationPreferences: null,
            savingChanges: false
        }
    },
    async mounted() {
        const organizationPreferences = await getOrganizationPreferences();
        this.organizationPreferences = organizationPreferences;
        this.setFields()
        // this.fields = {
        //     isPasscodeEnabled: {
        //         value: organizationPreferences.isPasscodeEnabled,
        //         helperMessage: 'I am a helper message'
        //     },
        //     enableCovid19Screening: {
        //         value: organizationPreferences.enableCovid19Screening,
        //         helperMessage: 'Enabling COVID-19 Screening Options does this that and the other thing...'
        //     },
        //     flagTestOnCovidTemperatureSkip: {
        //         value: organizationPreferences.flagTestOnCovidTemperatureSkip,
        //         helperMessage: 'Enabling COVID-19 Screening Alert does this that and the other thing...'
        //     },
        //     enableCovidContactQuestions: {
        //         value: organizationPreferences.enableCovidContactQuestions,
        //         helperMessage: 'Enabling COVID-19 Direct Contact Questions does this that and the other thing...'
        //     },
        //     isMetric: {
        //         value: false
        //     }
        // }
    },
    methods: {
        async onFormSubmit() {

            const request = {...organizationPreferencesModel}
            const {organizationId, organizationPreferencesId} = this.organizationPreferences;
            
            const {
                isPasscodeEnabled,
                enableCovid19Screening,
                flagTestOnCovidTemperatureSkip,
                enableCovidContactQuestions,
                isMetric
            } = this.fields;
            
            request.organizationId = organizationId;
            request.organizationPreferencesId = organizationPreferencesId;
            
            request.isPasscodeEnabled = isPasscodeEnabled.value;
            request.enableCovid19Screening = enableCovid19Screening.value;
            request.flagTestOnCovidTemperatureSkip = flagTestOnCovidTemperatureSkip.value;
            request.enableCovidContactQuestions = enableCovidContactQuestions.value;
            request.isMetric = isMetric.value;
            
            this.savingChanges = true;
            const {data} = await updateOrganizationPreferences(request);
            const {organizationPreferences} = data;
            this.organizationPreferences = organizationPreferences
            this.savingChanges = false;
        },
        setFields() {
            const {
                isPasscodeEnabled,
                enableCovid19Screening,
                flagTestOnCovidTemperatureSkip,
                enableCovidContactQuestions,
                isMetric
            } = this.organizationPreferences;
            
            this.fields = {
                isPasscodeEnabled: {
                    value: isPasscodeEnabled,
                    helperMessage: 'Allows organization to manage device security by requiring a local passcode on each device.'
                },
                enableCovid19Screening: {
                    value: enableCovid19Screening,
                    // helperMessage: 'Enabling COVID-19 Screening Options does this that and the other thing...'
                },
                flagTestOnCovidTemperatureSkip: {
                    value: flagTestOnCovidTemperatureSkip,
                    // helperMessage: 'Enabling COVID-19 Screening Alert does this that and the other thing...'
                },
                enableCovidContactQuestions: {
                    value: enableCovidContactQuestions,
                    // helperMessage: 'Enabling COVID-19 Direct Contact Questions does this that and the other thing...'
                },
                isMetric: {
                    value: isMetric
                }
            }
        }
    },
    
    
}
</script>

<style scoped>

</style>