<template>
    <div class="mb-2 pa-2 profile-container list-item" 
         :class="{ 'added-profile': profile.hasBeenAdded, disabled: disabled}"
    >
            <div style="flex: 1">{{fullName}} </div>
            <div class="profile-id" style="background-color: transparent">{{profile.id}}</div>
            <v-icon v-if="profile.hasBeenAdded && removable" small class="ml-4" @click="$emit('removeProfile', profile.id)">mdi-close</v-icon>


        
    </div>

</template>

<script>
export default {
    name: "ProfileMoveCard",
    props: {
        profile: {
            required: true
        },
        disabled: {
            default: false
        },
        removable: {
            type: Boolean
        }
    },
    computed: {
        fullName() {
            return this.profile.firstName + ' ' + this.profile.lastName
        },
        profileDisabled() {
            return this.profile.disabled
        },
        
    },
    watch: {
        // disabled() {
        //     this.$forceUpdate()
        // }
    }
}
</script>

<style scoped>
.profile-container {
    border: 1px solid var(--v-border-base);
    border-radius: 5px;
    display: flex;
    align-items: center;
    
}

.added-profile {
    border: 1px solid var(--v-primary-base);;
}

.profile-id {
    color: var(--v-light-darken4)
}

.profile-container:hover {
    background-color: var(--v-light-base);
    cursor: pointer;


}

.list-item {
    overflow: hidden;
}
.list-enter-active, .list-leave-active {
    transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(30px);
    background-color: var(--v-light-base);
}

.disabled {
    background-color: var(--v-light-base);
}

.disabled:hover {
    cursor: default;
}
</style>