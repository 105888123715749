<template>
    <v-chip
        :color="backgroundColor"
        :dark="dark"
        :outlined="outlined"
        :label="label"
        :small="small"
        :x-small="xSmall"
        :x-large="xLarge"
        :style="{color: currentColor}"
        style="font-weight: 500"
    >
        <slot></slot>
    </v-chip>
</template>

<script>
export default {
    name: "AppChip",
    props: {
        color: {
            type: String,
        },
        dark: {
            type: Boolean
        },
        outlined: {
            type: Boolean
        },
        label: {
            type: Boolean
        },
        small: {
            type: Boolean
        },
        xSmall: {
            type: Boolean
        },
        xLarge: {
            type: Boolean
        }
        
    },
    data() {
        return {
            currentColor: null,
            backgroundColor: null
        }
    },
    watch: {
        color() {
            this.init()
        }  
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            switch (this.color) {
                case 'green':
                    this.currentColor = '#4CAF50';
                    this.backgroundColor = '#E8F5E9';
                    break
                case 'blue':
                    this.currentColor = '#03A9F4';
                    this.backgroundColor = '#E1F5FE';
                    break;
                case 'orange':
                    this.currentColor = '#FF9800';
                    this.backgroundColor = '#FFF3E0';
                    break;
                case 'deep-orange':
                    this.currentColor = '#FF5722';
                    this.backgroundColor = '#FBE9E7';
                    break;
                case 'red':
                    this.currentColor = '#F44336';
                    this.backgroundColor = '#FDD9D7';
                    break;
                case 'purple':
                    this.currentColor = '#9C27B0';
                    this.backgroundColor = '#F3E5F5';
                    break;
                case 'deep-cyan':
                    this.currentColor = '#009688';
                    this.backgroundColor = '#CCEAE7';
                    break;
                case 'dark-slate-blue':
                    this.currentColor = '#3F51B5';
                    this.backgroundColor = '#D9DCF0';
                    break;
                case 'crimson':
                    this.currentColor = '#E91E63';
                    this.backgroundColor = '#FBD2E0';
                    break;
                case 'grey':
                    this.currentColor = '#607D8B';
                    this.backgroundColor = '#DFE5E8';
                    break;
	            case 'brown':
		            this.currentColor = '#795548';
		            this.backgroundColor = '#efebe9';
		            break;
	            case 'yellow':
		            this.currentColor = '#f9a825';
		            this.backgroundColor = '#fffde7';
		            break;
	            case 'pink':
		            this.currentColor = '#e91e63';
		            this.backgroundColor = '#fce4ec';
		            break;
	            case 'event':
		            this.currentColor = '#fff';
		            this.backgroundColor = '#FF9800';
		            break;
                case 'warning':
                    this.backgroundColor = 'warning';
                    break;
                case 'primary':
                    this.backgroundColor = 'primary';
                    break;
                case 'secondary':
                    this.backgroundColor = 'secondary';
                    break;
                default:
                    this.currentColor = null;
                    this.backgroundColor = null;

            }
        }  
    },
    computed: {
    }
}
</script>

<style scoped>
.red {
    color: red;
    background-color: pink;
}

.blue {
    color: blue;
}
</style>