<template>
	<div class="pa-4">
		<div  >
			<v-container>
				<v-row>
					<v-col :cols="3">
						<h3>General</h3>
                        <div>Business Type: {{businessType}}</div>
                        <div>Package Type: {{packageType}}</div>
						<div>Type: {{organizationType}}</div>
						<div class="mt-2">Created On: {{createdOn}}</div>
						
					</v-col>
					<v-col :cols="3">
						<div>
							<h3>Billing info</h3>
							<div>{{organization.billingAddress1}}</div>
							<div>{{organization.billingAddress2}}</div>
							<div>{{organization.billingCity}}, {{organization.billingState}} {{organization.billingPostalCode}}</div>
							<div>{{organization.billingCountry}}</div>
						</div>
					</v-col>
					<v-col :cols="3">
						<h3>Address</h3>
						<div>{{organization.address1}}</div>
						<div>{{organization.address2}}</div>
						<div>{{organization.city}}, {{organization.state}} {{organization.postalCode}}</div>
						<div>{{organization.country}}</div>
					</v-col>
					<v-col :cols="3">
						<h3>Attributes</h3>
						<div>Is Internal Org: {{organization.isInternalOrg}}</div>
						<div>Is Demo: {{organization.isDemo}}</div>
                        <div>Is Pilot: {{organization.isPilot}}</div>
						<div>Is Disabled: {{organization.isDisabled}}</div>
						<div>Is Enterprise Admin Org: {{organization.isEnterpriseAdminOrg}}</div>
						<div>Is Research: {{organization.isResearch}}</div>
                        <div>Can Enable Profile Sharing: {{organization.canEnableProfileSharing}}</div>
                        <div>Has Custom Agreement: {{organization.hasCustomAgreement}}</div>
					</v-col>
				</v-row>
			</v-container>
			
			<div>
				
			</div>
			<div>
				
			</div>
		</div>
		<AppButton @click="editingOrg = true" >
			Edit
		
		</AppButton>
		
		<DialogForm 
			title="Edit Organization" 
			:open="editingOrg" 
			@close="editingOrg = false"
			@submit="submit"
		>
			<template #form-content>
				<AppTabs v-model="tabValue">
					<AppTab v-for="(tab, index) in dialogFormTabs" :key="index">{{ tab.label }}</AppTab>
				</AppTabs>
				<div v-if="tabValue === 0">
					<AppTextField label="Name" v-model="editOrganizationForm.name" />
                    <AppSelect label="Business Type" v-model="editOrganizationForm.businessTypeId" :items="organizationBusinessTypeIds" item-text="label" item-value="id" />
                    <AppSelect label="Package Type" v-model="packageTierId" :items="packageTiers" item-text="text" item-value="value" />
                    <AppFormCheckbox v-if="isPackageTierChanged" label="Update Standard Protocols & Measure Access" v-model="updateMeasureAccess" style="padding-left:10px" />
					<AppSelect label="Type" v-model="editOrganizationForm.typeId" :items="organizationTypeIds" item-text="label" item-value="id" />
                    <AppSelect label="Primary Admin" v-model="editOrganizationForm.adminAccountId" :items="adminAccountOptions" item-text="label" item-value="id" />
				</div>
				<div v-if="tabValue === 1">
					<AppTextField label="Address 1" v-model="editOrganizationForm.address1" />
					<AppTextField label="Address 2" v-model="editOrganizationForm.address2" />
					<AppTextField label="City" v-model="editOrganizationForm.city" />
					<AppTextField label="State" v-model="editOrganizationForm.state" />
					<AppTextField label="Postal Code" v-model="editOrganizationForm.postalCode" />
				</div>
				<div v-if="tabValue === 2">
					<AppTextField label="Billing Address 1" v-model="editOrganizationForm.billingAddress1" />
					<AppTextField label="Billing Address 2" v-model="editOrganizationForm.billingAddress2" />
					<AppTextField label="Billing City" v-model="editOrganizationForm.billingCity" />
					<AppTextField label="Billing State" v-model="editOrganizationForm.billingState" />
					<AppTextField label="Billing Postal Code" v-model="editOrganizationForm.billingPostalCode" />
                    <AppSelect label="Billing Method" v-model="editOrganizationForm.billMethod" :items="billMethodIds" item-text="label" item-value="id" />
				</div>
                <div v-if="tabValue === 3">
                    <AppFormCheckbox label="Is Research" v-model="editOrganizationForm.isResearch" />
                    <AppFormCheckbox label="Is Internal Org" v-model="editOrganizationForm.isInternalOrg" />
                    <AppFormCheckbox label="Is Demo" v-model="editOrganizationForm.isDemo" />
                    <AppFormCheckbox label="Is Pilot" v-model="editOrganizationForm.isPilot" />
                    <AppFormCheckbox label="Is Disabled" v-model="editOrganizationForm.isDisabled" />
                    <AppFormCheckbox label="Is Enterprise Admin Org" v-model="editOrganizationForm.isEnterpriseAdminOrg" />
                    <AppFormCheckbox label="Can Enable Profile Sharing" v-model="editOrganizationForm.canEnableProfileSharing" />
                    <AppFormCheckbox label="Has Custom Agreement" v-model="editOrganizationForm.hasCustomAgreement" />
                </div>
			</template>
		</DialogForm>
	</div>
</template>

<script>
import AppButton from "@/components/AppButton";
import {organizationTypeIds, organizationBusinessTypeIds, packageTiers, billMethodIds} from "@/js/shared/constants";
import {getOrganizationRequestModelFromOrganization, updateOrganizationInfo} from "@/services/organization.service";
import {SET_SNACKBAR} from "@/store/mutation-types";
import moment from "moment";
import {getAdminAccounts} from "@/services/superAdmin.service";

export default {
	name: "SuperAdminOrgTableItemExpandSection",
	components: {AppButton},
	props: {
		organization: {
			required: true
		}
	},
	data() {
		return {
            billMethodIds,
			organizationTypeIds,
			organizationBusinessTypeIds,
            packageTiers,
			
            packageTierId: null,
            isPackageTierChanged: false,
            updateMeasureAccess: false,
            
            adminAccountOptions: [],
			
			editingOrg: false,
			tabValue: 0,
			editOrganizationForm: null,
			dialogFormTabs: [
				{
					label: 'General',
					value: 0
				},
				{
					label: 'Address',
					value: 1
				},
				{
					label: 'Billing',
					value: 2
				},
				{
					label: 'Attributes',
					value: 3
				},
				
			]
		}
	},
	async mounted() {
		// let form = getOrganizationRequestModelFromOrganization(this.organization)
        let accountsResponse = await getAdminAccounts(this.organization.id);
        this.adminAccountOptions = accountsResponse.accounts.map(x => ({label: x.firstName + ' ' + x.lastName, id: x.id}));
		this.editOrganizationForm = getOrganizationRequestModelFromOrganization(this.organization);
        this.packageTierId = this.organization.packageTierId;
	},
	computed: {
		organizationType() {
			let x = organizationTypeIds.find(x => x.id === this.organization.typeId);
			return x?.label
		},
		businessType() {
			let x = organizationBusinessTypeIds.find(x => x.id === this.organization.businessTypeId);
			return x?.label
		},
		createdOn() {
			return moment(this.organization.createdOn).format('MMM D, yyyy')
		},
        packageType() {
            let x = packageTiers.find(x => x.value === this.organization.packageTierId);
            return x?.text
        }
		
	},
    watch: {
        // whenever question changes, this function will run
        packageTierId: function (newVal, oldVal) {
            this.editOrganizationForm.packageTierId = newVal;
            if (oldVal && newVal && newVal !== oldVal){
                this.isPackageTierChanged = true;
            }
        }
    },
	methods: {
		async submit() {
			let form = this.editOrganizationForm;
			
			let response = await updateOrganizationInfo({organization: this.editOrganizationForm, updateMeasureAccess: this.updateMeasureAccess});
			
			if (!response.isSuccess) {
                //error should be handled in httpService.js
				/*this.$store.commit(SET_SNACKBAR, {
					text: 'There was an error updating the organization',
					color: 'error',
					open: true
				});*/
			}
			else {
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Successfully Updated Organization',
                    color: 'success',
                    open: true
                });
            }
			
			this.editingOrg = false
			
			this.$emit('updatedOrg')
			
		},
	}
}
</script>

<style scoped>

</style>