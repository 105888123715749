import AppTabs from "@/components/tabs/AppTabs";
import AppTab from "@/components/tabs/AppTab";
import PageHeader from "@/components/PageHeader";
import AppFormCheckbox from "@/components/forms/AppFormCheckbox";
import AppSelect from "@/components/forms/AppSelect";
import AppAutocomplete from "@/components/forms/AppAutocomplete";
import AppTextField from "@/components/forms/AppTextField";
import DialogForm from "@/components/DialogForm";
import AppButton from "@/components/AppButton";
import AppCard from "@/components/AppCard";
import AppDataTable from "@/components/tables/AppDataTable";
import Vue from "vue";
import AppLoader from "@/components/AppLoader";
import AppChip from "@/components/AppChip";
import AppSimpleTable from "@/components/tables/AppSimpleTable";
import AppFabButton from "@/components/AppFabButton";
import AppConfirmDialog from "@/components/AppConfirmDialog";
import AppBreadcrumbs from "@/components/AppBreadcrumbs";
import AppTimePicker from "@/components/AppTimePicker";
import AppFormDatePicker from "@/components/forms/AppFormDatePicker";
import AppDatePicker from "@/components/AppDatePicker";
import AppForm from "@/components/AppForm";
import AppMultipleSelect from "@/components/AppMultipleSelect";
import AppFormTimePicker from "@/components/forms/AppFormTimePicker";
import AppFormMultipleSelect from "@/components/forms/AppFormMultipleSelect";
import AppFormSwitch from "@/components/forms/AppFormSwitch";
import AppPageLoader from "@/components/AppPageLoader";
import SwayLoader from "@/components/SwayLoader";

export default {
    install(Vue, options) {
        Vue.component('AppTabs', AppTabs)
        Vue.component('AppTab', AppTab)
        Vue.component('PageHeader', PageHeader)
        Vue.component('AppFormCheckbox', AppFormCheckbox)
        Vue.component('AppSelect', AppSelect)
        Vue.component('AppAutocomplete', AppAutocomplete)
        Vue.component('AppTextField', AppTextField)
        Vue.component('DialogForm', DialogForm)
        Vue.component('AppBtn', AppButton)
        Vue.component('AppFabBtn', AppFabButton)
        Vue.component('AppCard', AppCard)
        Vue.component('AppDataTable', AppDataTable)
        Vue.component('AppLoader', AppLoader)
        Vue.component('AppChip', AppChip)
        Vue.component('AppSimpleTable', AppSimpleTable)
        Vue.component('AppFormDatePicker', AppFormDatePicker)
        Vue.component('AppConfirmDialog', AppConfirmDialog)
        Vue.component('AppBreadcrumbs', AppBreadcrumbs)
        Vue.component('AppTimePicker', AppTimePicker)
        Vue.component('AppDatePicker', AppDatePicker)
        Vue.component('AppForm', AppForm)
        Vue.component('AppMultipleSelect', AppMultipleSelect)
        Vue.component('AppFormTimePicker', AppFormTimePicker)
        Vue.component('AppFormMultipleSelect', AppFormMultipleSelect)
        Vue.component('AppFormSwitch', AppFormSwitch)
        Vue.component('AppPageLoader', AppPageLoader)
        Vue.component('SwayLoader', SwayLoader)
        
    }
}