<template>
	<div>
		<div>
				<v-container fluid>
					<v-row dense>
						<v-col sm="12" md="6" >
							<AppCard hide-header class="pa-2" style="height: 100%">
								<h3><span style="color: #4caf50">BILLING</span> Organization Connections</h3>
								<v-divider class="mt-2 mb-2"></v-divider>
								<div><strong>{{$store.state.currentOrganization.organization.name}}</strong> is a <strong>{{ organizationBillingStatus.label }}</strong></div>
								<div>{{ organizationBillingStatus.description }}</div>
								<div
									v-if="organizationBillingStatus.type === 1 && billingInfo.billToOrgId"
									class="mt-2"
								>
									Parent Billing Organization: {{billingInfo.billToOrgName}} ({{billingInfo.billToOrgId}})
								</div>
								<div 
									v-if="organizationBillingStatus.type === 2 && billingChildOrgs && billingChildOrgs.organizations.length > 0"
									class="mt-2"
								>
									Child Billing Organizations Count: {{billingChildOrgs.organizations.length}}
									<ul>
										<li v-for="org in billingChildOrgs.organizations">{{org.organizationName}} ({{org.organizationId}})</li>
									</ul>
								</div>
							</AppCard>
						
						</v-col>
						<v-col sm="12" md="6" >
							<AppCard hide-header class="pa-2">
								<h3><span style="color: #2196f3">ACCESS</span> Organization Connections</h3>
								<v-divider class="mt-2 mb-2"></v-divider>
								<h4>Parent Organizations</h4>
								<app-simple-table
									v-if="orgConnects"
									:items="orgConnects.parentOrgConnects"
									:headers="parentOrgConnectionHeaders"
								>
									<template v-slot:no-data>
										<div style="display: flex; justify-content: center; padding: 20px">
											No access parent organizations
										</div>
									</template>
								</app-simple-table>
								<h4 class="mt-4">Child Organizations</h4>
								<app-simple-table
									v-if="orgConnects && orgConnects.childOrgConnects.length > 0"
									:items="orgConnects.childOrgConnects"
									:headers="childOrgConnectionHeaders"
								>
									<template v-slot:no-data>
										<div style="display: flex; justify-content: center; padding: 20px">
											No access child organizations
										</div>
									</template>
								</app-simple-table>
								<div v-else>
									No Access Child Organizations
								</div>
							
							</AppCard>
						
						</v-col>
						<v-col sm="12" lg="6" v-if="formattedCurrentSubscription && usageHistory">
							<AppCard hide-header class="pa-2">
								<h3>Current Subscription</h3>
								<v-divider class="mt-2 mb-2"></v-divider>
								<h2>{{ formattedCurrentSubscription.packageTier }}</h2>
								<AppChip
									v-if="formattedCurrentSubscription.isDemo"
									color="green"
									label
									large
								>
									Demo
								</AppChip>
								<div>
									{{formattedCurrentSubscription.subscriptionStartDate}} to {{formattedCurrentSubscription.subscriptionEndDate}}
								</div>
								
								
								<div v-if="!formattedCurrentSubscription.isDemo">
									<highcharts
										:options="usageChartOptions"
										ref="highchart"
										v-if="formattedCurrentSubscription.isSports"
									/>
									<div v-if="formattedCurrentSubscription.isSports">Profile Count: {{formattedCurrentSubscription.totalProfileCount}}</div>
									<div v-if="formattedCurrentSubscription.isSports">Profile Credits Remaining: {{usageHistory.sportsProfilesRemaining}}</div>
									<div>Profile Price: {{formattedCurrentSubscription.profilePrice}}</div>
									<div>System Fee: {{formattedCurrentSubscription.systemFee}}</div>
								</div>
								<v-alert
									class="mt-2"
									type="warning"
									outlined
								>
									<strong>BETA</strong>: Please confirm these values are correct before sharing externally.
								</v-alert>
								
								
							</AppCard>
						</v-col>
						<v-col sm="12" lg="6">
							<AppCard hide-header class="pa-2">
								<h3>Invoices</h3>
								<v-divider class="mt-2 mb-2"></v-divider>
								<app-simple-table
									:items="invoices"
									:headers="invoicesTableHeaders"
								>
									<template #item.paidTotal="{item}">
										
										<div v-if="item.payment && !item.isRefunded">
											{{ item.paidTotal }}
											<app-chip  color="green" label small class="ml-2">Paid</app-chip>
										</div>
										<div v-else-if="item.isRefunded">
											<app-chip  color="yellow" label small class="ml-2">Payment Refunded</app-chip>
										</div>
										<div v-else-if="item.deletedOn">
											<app-chip  color="gray" label small class="ml-2">VOIDED</app-chip>
										</div>
										<div v-else>
											<app-chip :color="item.isPastDue ? 'red' : 'blue'" label small>{{ item.isPastDue ? 'Past Due' : 'Outstanding' }}</app-chip>
										</div>
									
									</template>
								</app-simple-table>
							</AppCard>
						</v-col>
					</v-row>
				</v-container>
				

			
		</div>
	</div>
</template>

<script>
import {baseUrl} from "@/config";
import httpService from "@/services/httpService";
import {getBillingInfo, getInvoices, getProfileUsageHistory, getBillingChildOrgs} from "@/services/billing.service";
import {getOrgConnects} from "@/services/organization.service";
import moment from "moment";
import Invoice from "@/js/shared/classes/Invoice/Invoice";
import {formatMoney} from "@/js/shared/helper";
import {packageTiers, orgConnectStatusSelectList, orgConnectTypeSelectList} from "@/js/shared/constants";

export default {
	name: "OrgManagementDashboard",
	data() {
		return {
			currentSubscription: null,
			usageHistory: null,
			invoices: [],
			billingInfo: null,
			billingChildOrgs: null,
			orgConnects: null,
			invoicesTableHeaders: [
				{
					text: 'Invoice Number',
					value: 'displayNumber'
				},
				{
					text: 'Invoice Date',
					value: 'issuedOn'
				},
				{
					text: 'Due Date',
					value: 'displayDueDate'
				},
				{
					text: 'Total',
					value: 'total'
				},
				{
					text: 'Payment',
					value: 'paidTotal'
				},
			],
			parentOrgConnectionHeaders: [
				{
					text: 'ID',
					value: 'parentOrganizationId'
				},
				{
					text: 'Name',
					value: 'parentOrganizationName'
				},
				{
					text: 'Connection Type',
					value: 'orgConnectTypeLabel'
				},
				{
					text: 'Connection Status',
					value: 'orgConnectStatusTypeLabel'
				},
			],
			childOrgConnectionHeaders: [
				{
					text: 'ID',
					value: 'childOrganizationId'
				},
				{
					text: 'Name',
					value: 'childOrganizationName'
				},
				{
					text: 'Connection Type',
					value: 'orgConnectTypeLabel'
				},
				{
					text: 'Connection Status',
					value: 'orgConnectStatusTypeLabel'
				},
			],
			
		}
	},
	mounted() {
		this.getCurrentSubscription();
		this.getUsageHistory();
		this.getInvoices()
		this.getBillingInfo();
		this.getBillingChildOrgs()
		this.getOrgConnects();
	},
	methods: {
		async getInvoices() {
			const response = await getInvoices();
			
			let invoices = []
			
			if (response.isSuccess) {
				invoices = response.invoices;
			}
			
			invoices.forEach(invoice => {
				const {id, displayInvoiceDate, displayDueDate, total, issuedOn} = invoice;
				let format = "M/D/YYYY";
				invoice.id = id;
				invoice.displayNumber = id + (invoice.deletedOn ? ' (VOIDED)' : (invoice.isRefunded ? ' (REFUNDED)' : (invoice.isNotSent ? ' (NOT SENT)' : '')));
				invoice.displayInvoiceDate = moment(displayInvoiceDate).format(format);
				invoice.displayDueDate = moment(displayDueDate).format(format);
				invoice.total = formatMoney(total, 2);
				invoice.totalNumeric = total;
				invoice.paidTotal = invoice.payment ? (formatMoney(invoice.payment.total, 2) + (invoice.isRefunded ? ' (REFUNDED)' : '')) : '$0.00';
				invoice.paidTotalNumeric = invoice.payment ? invoice.payment.total : 0.0;
				invoice.isPastDue = !invoice.payment && moment().isAfter(moment(displayDueDate)) && !invoice.deletedOn;
				invoice.issuedOn = moment(issuedOn).format(format);
				
				if (invoice.deletedOn)
					invoice.displayRange = invoice.id + ' (VOID)'
				else if (invoice.isNotSent)
					invoice.displayRange = invoice.id + ' (Visible on ' + invoice.displayInvoiceDate + ')'
				else if (invoice.payment && invoice.payment.total === total)
					invoice.displayRange = invoice.id + ' (Paid on ' + moment(invoice.payment.paymentDate).format(format) + ')'
				else
					invoice.displayRange = invoice.id + ' (Due ' + invoice.displayDueDate + ')'
			});
			invoices.sort(function (a,b) {
				return b.id - a.id;
			});
			
			this.invoices = invoices.slice(0,5)
			
		},
		async getCurrentSubscription() {
			
			this.isDataLoaded = false;
			
			let isUpcomingSubscription = false;
			let isPreviousSubscription = false;
			
			let query = 'isUpcomingSubscription=' + isUpcomingSubscription + '&isPreviousSubscription=' + isPreviousSubscription;
			const endpoint = baseUrl + '/Billing/GetProductSubscription?' + query;
			const response = await httpService.post(endpoint);
			
			if (response.isSuccess) {

				if (response.model.sports)
					this.sportsSubscriptionEnabled = true;
				
				if (response.model.clinical)
					this.clinicalSubscriptionEnabled = true;
				
				this.currentSubscription = response.model
				
				// this.model = response.model;
				// this.organizationName = response.organizationName;
				// this.isUpcomingSubscription = response.isUpcomingSubscription;
				// this.isPreviousSubscription = response.isPreviousSubscription;
			}
			
			this.isDataLoaded = true;
		},
		async getUsageHistory() {
			
			try {
				
				let response = await getProfileUsageHistory();
			
				if (response.isSuccess) {
					this.usageHistory = response
					// this.sportsProfileUsageHistory = response.sportsUsageItems;
					// this.sportsProfilesRemaining = response.sportsProfilesRemaining;
					// this.clinicalProfileUsageHistory = response.clinicalUsageItems;
					// this.clinicalProfilesRemaining = response.clinicalProfilesRemaining;
					// this.hasMultipleOrganizationUsage = response.hasMultipleOrganizationUsage
					
					
					
				}
				
				
			}
			catch (e) {
				
			}
		},
		async getBillingInfo() {
			let response = await getBillingInfo();
			if (response.isSuccess) {
				this.billingInfo = response
			}
			
		},
		async getBillingChildOrgs() {
			let response = await getBillingChildOrgs();
			if (response.isSuccess) {
				this.billingChildOrgs = response
			}
		},
		async getOrgConnects() {
			let response = await getOrgConnects();
			if (response.isSuccess) {
				
				response.childOrgConnects.forEach(x => {
					x.orgConnectStatusTypeLabel = orgConnectStatusSelectList.find(y => y.value === x.orgConnectStatusTypeId).text;
					x.orgConnectTypeLabel = orgConnectTypeSelectList.find(y => y.value === x.orgConnectTypeId).text;
				})
				
				response.parentOrgConnects.forEach(x => {
					x.orgConnectStatusTypeLabel = orgConnectStatusSelectList.find(y => y.value === x.orgConnectStatusTypeId).text;
					x.orgConnectTypeLabel = orgConnectTypeSelectList.find(y => y.value === x.orgConnectTypeId).text;
				})
				
				this.orgConnects = response

			}
		},
		dateFormatter(date) {
			return moment(date).format("MM/DD/YYYY")
		},
		
	},
	computed: {
		usageChartOptions() {
			if (!this.usageHistory || !this.currentSubscription.sports) return null
			
			
			let profileCreditsAvailable = this.currentSubscription.sports.profileCount;
			let profileCreditsRemaining = this.usageHistory.sportsProfilesRemaining;
			let profileCreditsUsed = profileCreditsAvailable - profileCreditsRemaining;
			let percentUsed = Number((profileCreditsUsed / profileCreditsAvailable)).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2});
			
			return {
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: 0,
					plotShadow: false,
					height: 150,
					style: {
						fontFamily: 'SF Pro Display'
					}
				},
				title: {
					text: percentUsed,
					align: 'center',
					verticalAlign: 'middle',
					// y: 60
				},
				tooltip: {
					pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
				},
				accessibility: {
					point: {
						valueSuffix: '%'
					}
				},
				plotOptions: {
					pie: {
						dataLabels: {
							enabled: false,
							// distance: -50,
							style: {
								fontWeight: 'bold',
								color: 'white'
							}
						},
						// startAngle: -90,
						// endAngle: 90,
						// center: ['50%', '75%'],
						// size: '110%'
					}
				},
				series: [{
					type: 'pie',
					name: 'Usage',
					innerSize: '80%',
					data: [
						['Used', profileCreditsUsed],
						{
							name: "Remaining",
							y: profileCreditsRemaining,
							color: '#eeeeee'
						}
						// ['Edge', 11.97],
						// ['Firefox', 5.52],
						// ['Safari', 2.98],
						// ['Internet Explorer', 1.90],
						// {
						// 	name: 'Other',
						// 	y: 3.77,
						// 	dataLabels: {
						// 		enabled: false
						// 	}
						// }
					]
				}],
				credits: {
					enabled: false
				}
			}
		},
		packageTier() {
			if (!this.currentSubscription) return null
			let packageTier = this.currentSubscription.sports?.packageTier ? this.currentSubscription.sports?.packageTier : this.currentSubscription.clinical?.packageTier;
			// let something = packageTiers;
			// debugger;
			return packageTiers.find(x => x.value === packageTier).text
		},
		formattedCurrentSubscription() {
			if (!this.currentSubscription || this.billingInfo.billToOrgId) return null;
			let base = this.currentSubscription.sports ? 'sports' : 'clinical';
			let isSports = !!this.currentSubscription.sports

			let packageTier = this.currentSubscription[base].packageTier ? packageTiers.find(x => x.value === this.currentSubscription[base].packageTier).text : null
			let subscriptionStartDate = this.dateFormatter(this.currentSubscription[base].subscriptionStartDate)
			let subscriptionEndDate = this.dateFormatter(this.currentSubscription[base].subscriptionEndDate)
			let profilePrice = formatMoney(this.currentSubscription[base].profilePrice)
            let profileCount = this.currentSubscription[base].profileCount
            let totalProfileCount = this.currentSubscription[base].profileCount + this.currentSubscription[base].profileAddOnCount
			let systemFee = formatMoney(this.currentSubscription[base].systemFee)
			let isDemo = this.currentSubscription[base].isDemo
			
			
			return {
				isSports,
				packageTier,
				subscriptionStartDate,
				subscriptionEndDate,
				profilePrice,
				profileCount,
                totalProfileCount,
				systemFee,
				isDemo
			}
		},
		organizationBillingStatus() {
			if (!this.billingInfo || !this.billingChildOrgs) return null
			
			if (this.billingInfo.billToOrgId) return {
				type: 1,
				label: "Billing Child",
				description: "A Billing Child Organization's usage counts toward it's Billing Parent Organizations usage or it's invoices are paid for by the Billing Parent Organization."
			}
			else if (this.billingChildOrgs.organizations) return {
				type: 2,
				label: "Billing Parent",
				description: "A Billing Parent Organization is responsible for paying its own invoices as well as the invoices for all child organizations."
			}
			else return {
				type: 3,
				label: "Standard Billing",
				description: "A standard billing organization is responsible for paying its own invoices. There is no parent organization to bill to and it has no child organizations that it is paying for."
			}
		}
	}
}
</script>

<style scoped>

</style>