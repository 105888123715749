<template>
    <div style="display: flex;">
        <span style="margin-right: 5px" class="pdf-stat-label">Device Summary:</span>
         
        <div v-for="(stat, index) in stats" :key="index" >
            <span class="pdf-profile-header-with-additional-data-stat-label" >{{stat.label}} </span><span class="pdf-stat-value">{{ stat.value }}</span>
        </div> 
    </div>
</template>

<script>
export default {
    name: "TestMetaDataHeader",
    props: {
        metadata: {
            required: true
        }
    },
    computed: {
        stats() {
            return [
                {
                    label: 'App Version',
                    value: this.metadata.appVersion ? this.metadata.appVersion : '-'
                },
                {
                    label: 'Platform',
                    value: this.metadata.platform ? this.metadata.platform : '-'
                },
              {
                label: 'Device Manufacturer',
                value: this.metadata?.deviceManufacturer ? this.metadata.deviceManufacturer : '-'
              },
              {
                label: 'Device Model',
                value: this.metadata?.deviceModel ? this.metadata.deviceModel : '-'
              },
              {
                label: 'OS Version',
                value: this.metadata?.osVersion ? this.metadata.osVersion : '-'
              },
              {
                label: 'Device ID',
                value: this.metadata?.deviceId ? this.metadata.deviceId : '-'
              }
            ]
        }
    }
}
</script>

<style scoped>

</style>