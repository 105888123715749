import httpService from "@/services/httpService";
import {baseUrl} from "@/config"


const endpoint = baseUrl + "/Organization"

const organizationPreferencesModel = {
        organizationPreferencesId: 1,
        organizationId: 6127,
        isPasscodeEnabled: true,
        dynamicProtocolsEnabled: true,
        profileFlagDisplayInterval: "string",
        isSkipTestEnabled: true,
        isSymptomTrackingEnabled: true,
        isAtHomeTestingEnabled: true,
        isMetric: true,
        enableNewTestViewVisualizations: true,
        isExpeditedBaselineEnabled: true,
        enableCovid19Screening: true,
        flagTestOnCovidTemperatureSkip: true,
        enableCovidContactQuestions: true
}

const getOrganizationPreferences = async () => {
    try {
        return await httpService.post(endpoint + '/GetOrganizationPreferences', )
    } catch (e) {
    }
}

const masterSearch = async (searchString) => {
    try {
        return await httpService.post(endpoint + '/MasterSearch', {searchString} )
    } catch (e) {
    }
}

const getOrganizationTestDataSummary = async (LookBackDays) => {
    try {
        return await httpService.post(endpoint + '/GetOrganizationTestDataSummary', {LookBackDays} )
    } catch (e) {
    }
}

const getDevices = async () => {
    try {
        return await httpService.post(endpoint + '/GetDevices' )

    } catch (e) {
    }
}

const removeDevice = async (id) => {
    try {
        return await httpService.post(endpoint + '/RemoveDevice', {id} )
    } catch (e) {
    }
}

const getTotalTestCount = async () => {
    try {
        return await httpService.post(endpoint + '/GetTotalTestCount', {} )
    } catch (e) {
    }
}

const getTotalProfileCount = async () => {
    try {
        return await httpService.post(endpoint + '/GetTotalProfileCount', {} )
    } catch (e) {
    }
}

const getTotalGroupsCount = async () => {
    try {
        return await httpService.post(endpoint + '/GetTotalGroupsCount', {} )
    } catch (e) {
    }
}

const getDailyTestCounts = async (request) => {
    try {
        return await httpService.post(endpoint + '/GetDailyTestsCount', request )
    } catch (e) {
    }
}

const updateOrganizationInfo = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateOrganizationInfo', request )
    } catch (e) {
    }
}

const getOrganization = async () => {
    try {
        return await httpService.post(endpoint + '/getOrganization', {} )
    } catch (e) {
    }
}

const getOrganizationById = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetOrganizationById', {Id: id} )
    } catch (e) {
    }
}

const getExternalIntegrations = async (request) => {
    try {
        return await httpService.post(endpoint + '/GetExternalIntegrations', {} )
    } catch (e) {
    }
}

const createExternalIntegration = async (request) => {
    try {
        return await httpService.post(endpoint + '/CreateExternalIntegration', request )
    } catch (e) {
    }
}

const updateExternalIntegration = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateExternalIntegration', request )
    } catch (e) {
    }
}

const getOrgConnects = async () => {
    try {
        return await httpService.post(endpoint + '/getOrgConnects' )
    } catch (e) {
    }
}

const saveOrgConnect = async (request) => {
    try {
        return await httpService.post(endpoint + '/saveOrgConnect', {orgConnect: request} )
    } catch (e) {
    }
}

const masterSearchSuperAdmin = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/MasterSearchSuperAdmin', {searchString: request})
        return response
    } catch (e) {
    }
}



const updateOrganizationPreferences = async (organizationPreferences) => {
    // let x  = organizationPreferencesModel;
    // let y = organizationPreferences;
    // 
    try {
        return await httpService.post(endpoint + '/UpdateOrganizationPreferences', {organizationPreferences} )
    } catch (e) {
    }
}

const updatePasscodeEnabled = async (isPasscodeEnabled, organizationPreferences) => {
    const request = {...organizationPreferencesModel}
    
    let {
        organizationId,
        organizationPreferencesId,
        dynamicProtocolsEnabled,
        profileFlagDisplayInterval,
        isSkipTestEnabled,
        isSymptomTrackingEnabled,
        isAtHomeTestingEnabled,
        isMetric,
        enableNewTestViewVisualizations,
        isExpeditedBaselineEnabled,
        enableCovid19Screening,
        flagTestOnCovidTemperatureSkip,
        enableCovidContactQuestions
    } = organizationPreferences

    request.isPasscodeEnabled = isPasscodeEnabled;

    request.organizationId = organizationId;
    request.organizationPreferencesId = organizationPreferencesId;


    request.dynamicProtocolsEnabled = dynamicProtocolsEnabled;
    request.profileFlagDisplayInterval = profileFlagDisplayInterval;
    request.isSkipTestEnabled = isSkipTestEnabled;
    request.isSymptomTrackingEnabled = isSymptomTrackingEnabled;
    request.isAtHomeTestingEnabled = isAtHomeTestingEnabled;
    request.isMetric = isMetric;
    request.enableNewTestViewVisualizations = enableNewTestViewVisualizations;
    request.isExpeditedBaselineEnabled = isExpeditedBaselineEnabled;
    request.enableCovid19Screening = enableCovid19Screening;
    request.flagTestOnCovidTemperatureSkip = flagTestOnCovidTemperatureSkip;
    request.enableCovidContactQuestions = enableCovidContactQuestions;
    
    try {
        return await httpService.post(endpoint + '/UpdateOrganizationPreferences', {organizationPreferences: request} )
    } catch (e) {
    }
}

const getTestResultsExport = async (request) => {
    // let x  = organizationPreferencesModel;
    // let y = organizationPreferences;
    // 
    
    // try {
    //     return await httpService.post(endpoint + '/GetTestResultsExport', request )
    // } catch (e) {
    // }

    try {
        let response = await httpService.post(endpoint + '/GetTestResultsExport', request, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer'
        })

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        let documentName = process.env.NODE_ENV === 'development' ? `Test_Results.zip` : `Test_Results.xlsx`;

        link.setAttribute('download', documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
        return response


    } catch(error) {
        console.log(error)
    }
}

const getEventsExport = async (request) => {
    // let x  = organizationPreferencesModel;
    // let y = organizationPreferences;
    // 

    // try {
    //     return await httpService.post(endpoint + '/GetTestResultsExport', request )
    // } catch (e) {
    // }

    try {
        let response = await httpService.post(endpoint + '/GetEventsExport', request, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer'
        })

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        let documentName = `Sway_Events.xlsx`

        link.setAttribute('download', documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
        return response


    } catch(error) {
        console.log(error)
    }
}

let updateOrganizationInfoModel = {
        id:0,
        adminAccountId:0,
        name:"string",
        isEnterpriseAdminOrg:true,
        isInternalOrg:true,
        canEnableProfileSharing:true,
        referrer:"string",
        address1:"string",
        address2:"string",
        city:"string",
        state:"string",
        postalCode:"string",
        phone:"string",
        billingAddress1:"string",
        billingAddress2:"string",
        billingCity:"string",
        billingState:"string",
        billingPostalCode:"string",
        billingPhone:"string",
        billMethod:0,
        distributorId:0,
        typeId:0,
        userPrice:0,
        profilePrice:0,
        basePrice:0,
        billingName:"string",
        billingEmail:"string",
        maxProfiles:0,
        isDisabled:true,
        isResearch:true,
        rtUpgradeResponse:0,
        isDemo:true,
        billingCountry:"string",
        stripeId:"string",
        medicalProfessionalName:"string",
        medicalProfessionalEmail:"string",
        minResponseMins:0,
        maxResponseMins:0,
        supportPhone:"string",
        isAMP:true,
        systemTypeId:0,
        country:"string",
        billingCycleTypeId:0,
        packageTierId:0,
        businessTypeId:0,
        lastFourDigits:"string",
        automatedBillingAcceptedDate:"2022-05-02T17:46:36.860Z",
        lastTestDate:"2022-05-02T17:46:36.860Z",
        totalTestCount:0,
        latitude:0,
        longitude:0,
        isPilot:false,
        hasCustomAgreement:false
}

const getOrganizationRequestModelFromOrganization = (org) => {
    
    let response = {...updateOrganizationInfoModel}
    for (let property in response) {
        response[property] = org[property]
    }
    
    return response
    
}


export {
    getOrganizationPreferences,
    masterSearch,
    getOrganizationTestDataSummary,
    updateOrganizationPreferences,
    organizationPreferencesModel,
    getDevices,
    removeDevice,
    getTotalTestCount,
    getTotalProfileCount,
    getTotalGroupsCount,
    getDailyTestCounts,
    getTestResultsExport,
    updatePasscodeEnabled,
    updateOrganizationInfo,
    getExternalIntegrations,
    createExternalIntegration,
    updateExternalIntegration,
    getOrgConnects,
    saveOrgConnect,
    masterSearchSuperAdmin,
    getOrganizationRequestModelFromOrganization,
    getOrganization,
    getOrganizationById,
    getEventsExport
}
