var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-card',{attrs:{"hide-header":""}},[_c('div',{},[_c('v-data-table',{staticClass:"profiles-table",attrs:{"headers":_vm.headers,"items":_vm.profiles,"items-per-page":_vm.tableSettings.rowsPerPage,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"page":_vm.tableSettings.pageNumber,"hide-default-footer":false,"id":"profiles-table","search":_vm.tableSettings.searchString},on:{"update:options":[function($event){_vm.options=$event},_vm.updateOptions],"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","border-bottom":"1px solid lightgrey"}},[_c('div',{staticClass:"pa-4",staticStyle:{"flex":"1"}},[_c('v-icon',{staticClass:"ml-2 mr-2"},[_vm._v("mdi-magnify")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableSettings.searchString),expression:"tableSettings.searchString"}],attrs:{"placeholder":"Search Profiles"},domProps:{"value":(_vm.tableSettings.searchString)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.tableSettings, "searchString", $event.target.value)},_vm.updateSearchString]}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"end","padding-right":"10px"}},[_c('div',{staticClass:"open-events-filter",style:({backgroundColor: _vm.openEventsOnly ? 'orange' : '#e0e0e0', color: _vm.openEventsOnly ? 'white' : '#757575'}),on:{"click":_vm.toggleOpenEventsOnly}},[_c('v-icon',{staticClass:"mr-1 clickable",style:({color: _vm.openEventsOnly ? 'white' : '#757575'}),attrs:{"size":"20"}},[_vm._v("mdi-filter-variant")]),_c('span',[_vm._v("Open Events")])],1)])])]},proxy:true},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_vm._v(" "+_vm._s(item.id)+" ")]),(item.inEvent)?_c('app-chip',{staticClass:"ml-2",attrs:{"color":"warning","label":""}},[_vm._v("Event")]):_vm._e()],1)]}},{key:"item.inActiveGroup",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"dot mr-2",style:({'background-color': item.inActiveGroup ? 'limegreen' : 'red'})}),_c('div',[_vm._v(" "+_vm._s(item.inActiveGroup ? 'Active' : 'Inactive')+" ")])])]}},{key:"item.averages",fn:function(ref){
var item = ref.item;
return [(_vm.loadingAverages)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"#E0E0E0","size":20,"width":2}})],1):_c('div',{staticStyle:{"display":"flex","align-items":"center"}},_vm._l((item.averages),function(average,key){return (item.averages)?_c('div',[(_vm.shouldShowAverage(key))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"height":"10px","width":"10px","border-radius":"10px","margin":"5px"},style:({backgroundColor: _vm.getCiColor(average.variabilityType)})},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTestTypeName(key)))])]):_vm._e()],1):_vm._e()}),0)]}},{key:"no-data",fn:function(){return [_c('div',{staticStyle:{"margin":"100px 0"}},[_c('div',{staticStyle:{"font-size":"1.5em"}},[_vm._v("No profiles match your search.")])])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }