<template>
    <div v-if="chartOptions" :class="{'pdf-excluded-section': measureResult.isExcluded}">
        <div style="display: flex; align-items: center">
            <div class="pdf-test-title" style="flex: 2; width: 250px; display: flex">
                <div style="color: #414D55; flex: 1">
                    {{ name }}
                    <div v-if="measureResult.isExcluded" style="color: red; font-weight: 600">
                        EXCLUDED FROM BASELINE
                    </div>
                    <div v-if="measureResult.fallRiskTypeId === 1">
                        <FallRiskTag />
                        
                    </div>
                </div>
                
                <div
                    v-if="profileTest.data.isEvent"
                    style="font-weight: 600; margin-right: 20px"
                >
	                <MeasureChangeChip :change-data="measureResult.changeData" />
                </div>
            </div>
            <div style="flex: 4" >

                <div style="position: relative; transform: translateY(8px)" >
                    <highcharts :options="chartOptions" ref="highchart" />
                    <ChartIndicator :label="displayScore" :xPosition="indicatorXPosition" />
                    
                </div>
                

            </div>

            <div style="flex: 1; display: flex;  background-color: transparent">

                    <div style="padding: 10px; display: flex; justify-content: center; align-items: center; flex-direction: column; flex: 1" >
                        <div style=" text-align: center; display: flex; justify-content: center;  background-color: transparent; font-size: 16px" >
                            {{results.percentileNumber}}<div style="font-size: 10px;text-transform: uppercase">{{ ordinalSuffix }}</div>
                        </div>
                        <div style=" text-transform: uppercase; font-size: 12px; background-color: transparent; text-align: center" >
                            {{results.percentileDescription ? results.percentileDescription : '-'}}
                        </div>
                    </div>


                
            </div>
        </div>
<!--        <div v-if="measureResult.showDetails" style="display: grid; place-content: center">-->
<!--            <div v-for="(item, index) in measureResult.results" :key="index" style="min-width: 400px">-->
<!--                <div v-for="(detail, detailIndex) in item.detailRows" :key="detailIndex" style="display: flex; width: 100%; font-size: 14px">-->
<!--                    <div style="flex: 1">-->
<!--                        {{detail.key}}-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        {{detail.value}}-->
<!--                    </div>-->
<!--                </div>-->


<!--            </div>-->
<!--        </div>-->
        <div v-if="showDropdown && allDetailRows.length > 1">
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column">
                <div @click="open = !open" ref="expandChevron">
                    <v-icon >mdi-chevron-down</v-icon>
                </div>
                <v-expand-transition>
                    <div v-if="open" >
<!--                        <div v-for="(item, index) in measureResult.results" :key="index" style="min-width: 400px">-->
<!--                            <div v-for="(detail, detailIndex) in item.detailRows" :key="detailIndex" style="display: flex; width: 100%">-->
<!--                                <div style="flex: 1">-->
<!--                                    {{detail.key}}-->
<!--                                </div>-->
<!--                                <div>-->
<!--                                    {{detail.value}}-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            -->

<!--                        </div>-->
                        <TestDetailTable 
	                        :measure-results="measureResult" 
	                        :all-detail-rows="allDetailRows"
	                        :editMode="editMode"
                        />

                    </div>
                    
                </v-expand-transition>
            </div>
        </div>
        

        <!--    <b-container style="margin: 0">-->
        <!--      <b-row style="margin: 0">-->
        <!--        <b-col style="margin: 0">-->
        <!--          <div class="highcharts-wrapper" style="position: relative">-->
        <!--            <Highcharts :options="chartOptions" ref="highchart"/>-->
        <!--            <ChartIndicator :label="displayScore" :xPosition="indicatorXPosition" />-->
        <!--          </div>-->
        <!--        </b-col>-->
        <!--        <b-col>-->
        <!--          <b-row>-->
        <!--            <div v-for="(stat, index) in stats" :key="index">-->
        <!--              <b-col >-->
        <!--                <div >-->
        <!--                  <div style="text-transform: uppercase">-->
        <!--                    {{stat.label}}-->
        <!--                  </div>-->
        <!--                  <div style="border: 1px solid lightgrey; border-radius: 5px; padding: 10px">-->
        <!--                    {{stat.value}}-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--              </b-col>-->
        <!--            </div>-->
        <!--          </b-row>-->
        <!--        </b-col>-->
        <!--      </b-row>-->
        <!--    </b-container>-->
    </div>
</template>

<script>
import ProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
// import Highcharts from "highcharts";
// import Annotations from 'highcharts/modules/annotations'
// import VueHighcharts from 'vue-highcharts'
// import More from 'highcharts/highcharts-more';
// import Vue from "vue";
import ChartIndicator from "../ChartIndicator";
import gsap from "gsap";
// More(Highcharts);
// Annotations(Highcharts)
// Vue.use(VueHighcharts)
import {getTestChangeColor, getOrdinalSuffix} from "@/js/shared/helper";
import TestDetailTable from "@/components/pdf-export/components/TestDetailTable";
import FallRiskTag from "@/components/pdf-export/components/FallRiskTag";
import MeasureChangeChip from "@/components/pdf-export/components/MeasureChangeChip";

export default {
    name: "TestRow",
    components: {
	    MeasureChangeChip,
        FallRiskTag,
        TestDetailTable,
        ChartIndicator,
    },
    props: {
        profileTest: ProfileTest,
        id: String,
        name: String,
        measureResult: {
            
        },
        showDropdown: {
            type: Boolean,
            default: false
        },
	    editMode: {
		    type: Boolean
	    }
    },
    data() {
        return {
            // uniqueChartIdentifier: Date.now() + Math.random(),
            // stats: [],
            // chartOptions: null,
            indicatorXPosition: 40,
            // displayScore: null,
            chartOptions: null,
            open: false
        }
    },
    mounted() {
        let x = typeof this.profileTest;
		if (this.editMode) this.open = true
        this.setChartOptions()

    },
    watch: {
        profileTest() {
            this.setChartOptions()
        },
        open() {
            if (!this.open) {
                gsap.to(this.$refs.expandChevron, {
                    rotationZ: 0,
                    duration: .2
                })
            } else {
                gsap.to(this.$refs.expandChevron, {
                    rotationZ: 180,
                    duration: .2

                })
            }
        },
	    editMode() {
		    if (this.editMode) this.open = true
	    }
    },
    computed: {
        // chartOptions() {
        //     
        //    
        // },
        
        displayScore() {
            if (!this.results) return null
            return this.results.score.toFixed(0);
        },
        stats() {
            let results = this.profileTest.formattedTestsData[this.id];
            // 

            

            return [
                {
                    label: 'Percentile',
                    value: typeof results.percentileNumber === 'number' ? results.percentileNumber + ordinalSuffix : '-'
                },
                {
                    label: 'Rank',
                    value: results.percentileDescription ? results.percentileDescription : '-'
                }
            ]
        },
        
        percentChangeClass() {
            if (typeof this.measureResult.percentChange !== 'number') return null
            return getTestChangeColor({percentChange: this.measureResult.percentChange})
        },
        ordinalSuffix() {
            const ordinalSuffix = getOrdinalSuffix(this.results.percentileNumber);
            return ordinalSuffix
        },
        results() {
            return this.profileTest.formattedTestsData[this.id]
        },
        allDetailRows() {
            let response = [];
            this.measureResult.results.forEach(result => {
                result.detailRows.forEach(row => response.push(row))
            })
            return response
        }
    },
    methods: {
	    excludeIncludeTestResults(request) {
		    this.$emit('excludeIncludeTestResults', request)
	    },
         getAllDetailRows(measureResults) {
            let response = [];
            measureResults.results.forEach(result => {
                result.detailRows.forEach(row => response.push(row))
            })
            return response
        },
        setChartOptions() {
            if (!this.profileTest) return null;
            
            this.chartOptions = this.profileTest.getMeasureColumnRangeChartOptionsById({
                id: this.id,
                disableAnimation: true,
                resizeCallback: (indicatorXPosition) => {
                    this.indicatorXPosition = indicatorXPosition;
                }});
        },
        
    }
}
</script>

<style scoped>



</style>