import { render, staticRenderFns } from "./AppDataTable.vue?vue&type=template&id=724f2fc0&scoped=true"
import script from "./AppDataTable.vue?vue&type=script&lang=js"
export * from "./AppDataTable.vue?vue&type=script&lang=js"
import style0 from "./AppDataTable.vue?vue&type=style&index=0&id=724f2fc0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "724f2fc0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VDataTable,VIcon})
