<template>
    <v-row
        class="d-flex"
        justify="center"
        v-click-outside="closeCard"
        style="position: relative"
        @keydown.down="moveDown"
        @keydown.up="moveUp"
        @keydown.enter="goToItem"
    >
        <v-text-field
            @blur="searchFieldBlur"
            ref="input"
            v-model="search"
            :items="items"
            :loading="isLoading"
            background-color="white"
            auto-select-first
            hide-no-data
            hide-selected
            item-text="title"
            item-value="title"
            placeholder="Search for Profiles, Groups, Protocols and Users"
            prepend-inner-icon="mdi-magnify"
            return-object

            append-icon=""
            class="search-input-area"
            filled
            rounded
            color="text"
            @focus="showCard = true"

            name="master-search"
            
            readonly 
            onfocus="this.removeAttribute('readonly');" 
            onblur="this.setAttribute('readonly','');"

        ></v-text-field>
        <v-expand-transition>
            <app-card
                v-if="showCard"
                class="master-search-menu"
                hide-header
            >
                <div v-if="!search && items.length === 0" class="pa-4">
                    Start typing to search your profiles, groups, protocols, and users
                </div>
                <div v-else-if="isLoading" style="padding: 20px">
                    Loading...
                </div>
                <div v-else  style="overflow: scroll">
                    
                    
                
                <v-list x dense style="max-height: 400px" class="red--text" >
                    <v-list-item-group
                        v-model="selectedItemIndex"
                        color="primary"
                    >
                        <div >
                            
                            
                        
                        <v-list-item  :class="'index-' + i"
                                     @click="goToItem(item)"
                                     v-for="(item, i) in items"
                                     :key="i"
                                      :disabled="item.isHeader"
                                      @mouseover="selectedItemIndex = i"
                        >
                            <v-list-item-content v-if="item.header"  >
                                <div class="pa-2">
                                    <h2 >{{item.header}}</h2>
                                    
                                </div>
                                <v-divider></v-divider>
                            
                        </v-list-item-content>
<!--                            <v-list-item-icon>-->
<!--                                <v-icon v-text="item.icon"></v-icon>-->
<!--                            </v-list-item-icon>-->
                            <v-list-item-content v-else class="text--text">
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                <v-list-item-subtitle v-text="item.id + ' (' + item.organizationName + ')'"></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        </div>
                    </v-list-item-group>
                </v-list>
                </div>
            </app-card>
        </v-expand-transition>
    </v-row>
</template>

<script>
import {masterSearch} from "@/services/organization.service";

export default {
    name: "AppTestSearch2",
    data: () => ({
        search: null,
        showMenu: false,
        showCard: false,
        selectedItemIndex: 1,
        isLoading: false,
        items: [],
        searchTimeout: null,
    }),
    methods: {
        searchFieldBlur(event) {
            // this.search = ''
        },
        closeCard() {
            this.showCard = false
        },
        moveDown() {
            if (this.items[this.selectedItemIndex + 1].isHeader) {
                
                this.selectedItemIndex += 2;
                console.log('isHeader. New index = ', this.selectedItemIndex)
            } else {
                this.selectedItemIndex++;
                console.log('is Not Header. New index = ', this.selectedItemIndex)
            }
            
            
            if (this.selectedItemIndex > 5) {
                this.scrollToElement(this.selectedItemIndex - 5)
            }
            
        },
        moveUp() {
            // this case should never happen but just in case           
            if (this.selectedItemIndex <= 1) return

            if (this.items[this.selectedItemIndex - 1].isHeader && this.selectedItemIndex > 1) {
                this.selectedItemIndex -= 2
            } else {
                this.selectedItemIndex--;
            }
            
            
            
            this.scrollToElement(this.selectedItemIndex)
        },
        scrollToElement(index) {
            const el = this.$el.getElementsByClassName(`index-${index}`)[0];

            if (el) {
                el.scrollIntoView({behavior: 'smooth'});
            }
        },
        goToItem() {
            // 
            // let item = this.items[this.selectedItemIndex]
            // if (item) {
            //     this.$router.push(item.route);
            //     this.showCard = false;
            //     return
            // }

            if (this.items.length === 0) return;
            
            
                this.$refs.input.blur();


                this.$router.push(this.items[this.selectedItemIndex].route)
                this.showCard = false;
                this.search = '';
                this.searchTimeout = null

            
            
            
            
            // return this.$router.push(this.items[this.selectedItem])
        },
        myTest() {
            alert('my test')
        },
        async performSearch(val) {
            
            const response = await masterSearch(val);

            if (!response.isSuccess) {
                return
            }

            const {searchResults: results} = response

            // type 1 = Profile
            // type 2 = Group
            // type 3 = Protocol
            // type 4 = Account

            let profiles = [];
            let groups = [];
            let protocols = [];
            let accounts = [];

            results.forEach(result => {
                switch (result.type) {
                    case 1:
                        profiles.push(result);
                        break;
                    case 2:
                        groups.push(result);
                        break;
                    case 3:
                        protocols.push(result);
                        break;
                    case 4:
                        accounts.push(result)
                }
            })

            let final = [];
            if (profiles.length > 0) {
                let {viewingAsSuper} = this.$store.state.currentAccount;
                final.push({header: 'Profiles', isHeader: true})
                profiles.forEach(profile => {
                    final.push({
                        title: viewingAsSuper ? '' : profile.title,
                        id: profile.id,
                        route: `/profiles/${profile.id}/overview?orgId=${profile.organizationId}`,
                        isHeader: false,
                        organizationName: profile.organizationName
                    })
                })
            }
            if (groups.length > 0) {
                final.push({header: 'Groups', isHeader: true})
                groups.forEach(group => {
                    final.push({
                        title: group.title,
                        id: group.id,
                        route: `/groups/${group.id}/profiles?orgId=${group.organizationId}`,
                        isHeader: false,
                        organizationName: group.organizationName
                    })
                })
            }
            if (protocols.length > 0) {
                final.push({header: 'Protocols', isHeader: true})
                protocols.forEach(protocol => {
                    final.push({
                        title: protocol.title,
                        id: protocol.id,
                        route: `/protocols/${protocol.id}?orgId=${protocol.organizationId}`,
                        isHeader: false,
                        organizationName: protocol.organizationName
                    })
                })
            }
            if (accounts.length > 0) {
                final.push({header: 'Accounts', isHeader: true})
                accounts.forEach(account => {
                    final.push({
                        title: account.title,
                        id: account.id,
                        route: `/users/${account.id}?orgId=${account.organizationId}`,
                        isHeader: false,
                        organizationName: account.organizationName
                    })
                })
            }

            this.items = final;
            this.isLoading = false;
            this.selectedItemIndex = 1;
        }
    },
    watch: {
        
        async search (val) {
            if (!val) return
            this.items = [];
            this.isLoading = true;
           

            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout)
                // this.searchTimeout = null
                
            }
            
            this.searchTimeout = setTimeout(async () => {
        
                await this.performSearch(val)
            }, 250)
            
        },
    },
}
</script>

<style scoped>
.master-search-menu {
    position: absolute;
    max-height: 400px;
    width: 100%;
    z-index: 10;
    top: 60px;
    /*overflow: scroll;*/
}

.search-input-area {
    background-color: var(--v-light-lighten1);
    border-radius: 15px;

}
</style>