<template>
    <div>
        <div style="display: flex; align-items: center; margin-bottom: 10px">
            <div style="display: flex; flex-direction: row; align-items: center">
                <div style="display: flex">
<!--                    <div v-if="subtitle" class="overflow" style="text-transform: uppercase; font-weight: 600; color: grey">{{subtitle}}</div>-->

                    <h1 class="page-header">{{title}}</h1>
                    
                    
                </div>
                <slot name="title-suffix" > </slot>
                
                <div class="ml-2">
                    
                    <span><app-fab-btn v-if="editable" small type="edit" @click="$emit('edit')" ></app-fab-btn></span>
                    <span><app-fab-btn v-if="deletable" small type="delete"  @click="$emit('delete')"></app-fab-btn></span>
                    <span><app-fab-btn v-if="shareable" small type="share"  @click="$emit('share')"></app-fab-btn></span>
                    <span><app-fab-btn v-if="printable" small type="print" @click="$emit('print')" :loading="printing"></app-fab-btn></span>
                    <v-menu offset-y :nudge-bottom="8" v-if="menu">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                fab
                                small
                                icon
                                v-bind="attrs"
                                v-on="on"

                            >
                                <span><v-icon>mdi-dots-vertical</v-icon></span>
                            </v-btn>
                        </template>
                        <slot name="menu-content"></slot>

                    </v-menu>
                    <span><app-fab-btn v-if="addable" small type="add" @click="$emit('add')" ></app-fab-btn></span>
                </div>
                
                <slot name="title-right"></slot>
            </div>
            <v-spacer></v-spacer>
            <slot name="right-content"/>

        </div>
        <slot name="below"></slot>
        <v-divider v-if="divider" class="mt-5 mb-5"></v-divider>
       
<!--        <v-alert-->
<!--            border="left"-->
<!--            color="warning"-->
<!--            dark-->
<!--        >-->
<!--             <span class="mr-3"><v-icon>mdi-alert</v-icon></span>Profile is currently in an event-->
<!--        </v-alert>-->
    </div>


</template>

<script>
export default {
    name: "PageHeader",
    props: {
        title: {
            required: true,
            type: String
        },
        subtitle: {
            type: String
        },
        editable: {
            type: Boolean
        },
        deletable: {
            type: Boolean
        },
        shareable: {
            type: Boolean
        },
        addable: {
            type: Boolean
        },
        printable: {
            type: Boolean
        },
        menu: {
            type: Boolean
        },
        printing: {
            type: Boolean
        },
        divider: {
            type: Boolean
        }
        
    }
}
</script>

<style scoped>
.page-header {
    /*color: var(--v-dark-base);*/
    font-size: 40px
}
</style>