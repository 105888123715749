<template>
    <div id="app" class="primary-bg" v-if="selectedAccountNotifications && selectedAccountNotifications.length > 0">
        <AppCard class="pa-2" :clickable="false" title="Notify Users">
            <div class="section-text">Notify users via email, SMS, or push notification when a test is completed on this code.</div>
            <section class="pa-2">
                <div style="" class="row section-subtitle">
                    <div class="col" style="padding-right:40px!important"></div>
                    <div class="col">Email</div>
                    <div class="col">Sms</div>
                    <div class="col">App</div>
                </div>
                <div style="text-align: center; align-items: center" class="row section-text" v-for="(value, accountId, index) in notificationsGroupedByAccount">
                    <div class="col" style="padding-right:40px!important">
                        {{value['1'].firstName + ' ' + value['1'].lastName}}
                    </div>
                    <div class="col">
                        <app-form-checkbox
                                v-model="value['1'].isEnabled"
                                message=""
                                label=""
                                @input="updateNotifications()"
                                :include-label="false"
                        />
                    </div>
                    <div class="col">
                        <app-form-checkbox
                                v-model="value['2'].isEnabled"
                                message=""
                                label=""
                                @input="updateNotifications()"
                                :include-label="false"
                        />
                    </div>
                    <div class="col">
                        <app-form-checkbox
                                v-model="value['3'].isEnabled"
                                message=""
                                label=""
                                @input="updateNotifications()"
                                :include-label="false"
                        />
                    </div>
                </div>
            </section>
        </AppCard>

    </div>
</template>

<script>
import 'moment-timezone'

export default {
    name: "UserNotificationsForm",
    props: {
        selectedAccountNotifications: {
            required: true,
            type: Array
        }
    },
    components: {
    },
    data() {
        return {
            
        }
    },
    watch: {
    },
    computed: {
        notificationsGroupedByAccount(){
            //return a dictionary of selectedAccountNotifications grouped by accountId
            let response = {}
            if (this.selectedAccountNotifications){
                this.selectedAccountNotifications.forEach((item) => {
                    if (!response[item.accountId])
                        response[item.accountId] = {}

                    if (!response[item.accountId][item.communicationTypeId])
                        response[item.accountId][item.communicationTypeId] = {}

                    response[item.accountId][item.communicationTypeId] = item
                })
            }

            return response;
        }
    },
    methods: {
        updateNotifications() {
            //this.testAndCodeBuilder.baselineSessionAccountNotifications = this.selectedAccountNotifications;
            console.log(JSON.stringify(this.selectedAccountNotifications));
            //this.submit();
        }
    }
}
</script>

<style>
/*
#app {
    padding-bottom: 60px
}

.section-title {
    font-weight: bold;
    color: var(--main-primary-charcoal);
    margin-bottom: 5px
}

.section-title:not(:first-child) {
    margin-top: 10px
}

.section-subtitle {
    font-weight: bold;
    color: var(--main-primary-charcoal);
    font-size: 14px
}

.section-text {
    font-weight: 300;
    font-size: 13px;
    color: var(--main-primary-charcoal)
}

.border-bottom {
    border-bottom: 1px solid var(--main-border-base);
}

header, .row {
    display: flex;  
}

.col {
    flex: 1;       
}*/
</style>