import httpService from "@/services/httpService";
import {baseUrl} from "@/config"


const endpoint = baseUrl + "/Enrollment"

const enrollOrganization = async (request) => {
    try {
        return await httpService.post(endpoint + '/EnrollOrganization', request )
    } catch (e) {
    }
}

export {
    enrollOrganization
}
