import moment from "moment";

import Subscriptions from "@/js/shared/classes/Subscription/Subscriptions";


const _formatSubscription = Symbol()

export default class SportsSubscriptions extends Subscriptions {
    constructor(data) {
        super(data)
    }
    
    static getSubscriptionModel() {
        return {
            startDate: null,
            endDate: null,
            isTrial: null,
            systemFee: null,
            isManualBilling: null,
            profilePrice: null,
            billingCycle: null
        }
    }  
    
    addUsageData(usageData) {
        usageData.forEach(x => {
            let subscription = this.data.find(y => y.productSubscriptionId === x.subscriptionId)
            if (subscription) subscription.addUsageDataItem(x)
        })
    }
    
    getHistoricalUsageTable() {
        let rows = [];
        this.data.forEach((subscription) => {
            if (moment(subscription.startDate.value).isAfter(moment())) return
            rows.push({
                rowspan: subscription.usageData.length + 1, // Add 1 for the row that shows the Profile Credit Balance
                productSubscriptionId: subscription.productSubscriptionId, 
                isSubscription: true,
                subscription,
            });
            
            subscription.usageData.sort((a, b) => {
                // Put purchases first
                if (a.usageType.value === 2 || a.usageType.value === 4) return 1
                if (a.usageCount > b.usageCount) return -1 
                return 1
            })
            
            subscription.usageData.forEach((x, index) => {
                if (index !== 0) rows.push({})
                for (let key in x) {
                    rows[rows.length - 1][key] = x[key]
                }
            })
            
            let profileCreditBalance = subscription.getProfileCreditBalance();
            rows.push({
                isProfileCreditBalance: true,
                profileCreditBalance,
            })
            
        })
        
        return rows
    }
    
}