<template>
    <tbody>
        <tr v-for="(row, rowIndex) in individualMeasure.sessionDetails.resultsTable2" :key="rowIndex">
            <td
                    v-for="item in row"
                    :style="{opacity: item.isExcluded ? 0.5 : null, textDecoration: item.isExcluded ? 'line-through' : null}"
                    :class="[(item.isExcluded ? 'excluded-result' : ''), (item.isFlagged ? 'flagged' : '')]"
                    class="pdf-table-cell"
            >
                <div v-html="item.text"></div>
            </td>
        </tr>
    </tbody>
</template>

<script>

export default {
    name: "GenericTestResultsTable",
    props: {
        individualMeasure: {
            required: true
        },
        editMode: {
            default: false
        },
        profileTest: {

        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    methods: {
        
    }
}
</script>

<style scoped>

.excluded {
    opacity: .5
}
.flagged {
    color: #ff6b6b;
}

td, th {
    /*border: 1px solid #dddddd;*/
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #eeeeee;
}
</style>