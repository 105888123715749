<template>
    <div class="" >
	    <div style="display: flex">
		    <div>
			    <app-btn style="" @click="getInvoiceReport">
				    Get Invoice Report
			    </app-btn>
			    <app-fab-btn type="download" @click="downloadInvoiceReport" />
		    </div>
		    <div >
			    <app-btn style="" @click="getUnpaidInvoiceReport">
				    Get Unpaid Invoice Report
			    </app-btn>
			    <app-fab-btn type="download" @click="downloadUnpaidInvoiceReport" />
		    </div>
		    <div >
			    <app-btn style="" @click="getMissingInvoiceReport">
				    Get Missing Invoice Report
			    </app-btn>
			    <app-fab-btn type="download" @click="downloadMissingInvoiceReport" />
		    </div>
		    <div >
			    <app-btn style="" @click="getUnsentInvoiceReport">
				    Get Unsent Invoice Report
			    </app-btn>
			    <app-fab-btn type="download" @click="downloadUnsentInvoiceReport" />
		    </div>
	    </div>
	    
	    <div v-if="currentReport">
		    <app-card hide-header class="mb-4 mt-4 pa-4">
			    <div v-if="currentReport === 2" >
				    <div>There are currently <strong style="color: #07aaf4">{{reportRows.length}}</strong> Unpaid Invoices</div>
				    
				    <div class="pt-2">
					    <div style="font-weight: bold">Unpaid Invoice Report Description</div>
					    <p>The Unpaid Invoice Report shows all invoices that have been created that have not been paid.</p>
				    </div>
				    
				    <div style="display: flex; gap: .25rem">
					    <div>Due Date: </div>
					
					    <app-chip color="red" label small>days past due</app-chip>
					    <app-chip color="green" label small>today</app-chip>
					    <app-chip color="blue" label small>days out</app-chip>
				    </div>
				    <v-divider class="mt-2"/>
				    <div v-if="unpaidInvoiceReportStats" class="mt-2">
					    <div>Total Invoice Amount: {{unpaidInvoiceReportStats.totalAmount}}</div>
					    <div class="mt-2">Filtered Total Invoice Amount: {{unpaidInvoiceReportStats.filteredTotalAmount}}</div>
					    <div style="color: #757575">
						    The Filtered Total Invoice Amount removes all invoices that meet the following criteria
						    <ul>
							    <li>The Invoice is for a DISABLED Organization</li>
							    <li>The Invoice has been VOIDED</li>
							    <li>The Invoice is not past due</li>
						    </ul>
					    </div>
					    
				    </div>
			    </div>
			
			    <div v-if="currentReport === 3" >
				    <div>There are currently <strong style="color: #07aaf4">{{reportRows.length}}</strong> Missing Invoices</div>
				    
				    <div class="pt-2">
					    <div style="font-weight: bold">Missing Invoice Report Description</div>
					    <p>The Missing Invoice Report shows all organizations that have not had an invoice created in over a year.</p>
				    </div>
				    <div style="display: flex; gap: .25rem">
					    <div>Organization Name: </div>
					
					    <app-chip color="purple" label small>Research</app-chip>
					    <app-chip color="green" label small>Demo</app-chip>
					    <app-chip color="red" label small>Disabled</app-chip>
				    </div>
				    <div style="display: flex; gap: .25rem" class="pt-2">
					    <div>Last Invoice Date: </div>
					    <app-chip color="blue" label small>days ago</app-chip>
				    </div>
			    </div>
			
			    <div v-if="currentReport === 4" >
				    <div>There are currently <strong style="color: #07aaf4">{{reportRows.length}}</strong> Unsent Invoices</div>
				    
				    <div class="pt-2">
					    <div style="font-weight: bold">Unsent Invoice Report Description</div>
					    <p>The Unsent Invoice Report shows all invoices that have been created but not sent to the customer through the portal.</p>
				    </div>
				    <div style="display: flex; gap: .25rem">
					    <div>Invoice Type: </div>
					    <InvoiceTypeChip :invoice-type-id="1" small />
					    <InvoiceTypeChip :invoice-type-id="2" small />
					    <InvoiceTypeChip :invoice-type-id="3" small />
                        <InvoiceTypeChip :invoice-type-id="4" small />
                        <InvoiceTypeChip :invoice-type-id="5" small />
				    </div>
				    <div style="display: flex; gap: .25rem" class="pt-2">
					    <div>Due Date: </div>
					
					    <app-chip color="red" label small>days past due</app-chip>
					    <app-chip color="green" label small>today</app-chip>
					    <app-chip color="blue" label small>days out</app-chip>
				    </div>
				    <div style="display: flex; gap: .25rem" class="pt-2">
					    <div>Display Date: </div>
					
					    <app-chip color="red" label small>days past due</app-chip>
					    <app-chip color="green" label small>today</app-chip>
					    <app-chip color="blue" label small>days out</app-chip>
				    </div>
				    <div style="display: flex; gap: .25rem" class="pt-2">
					    <div>Approval Status: </div>
					    <InvoiceApprovalStatusChip :approval-status-id="0" small/>
					    <InvoiceApprovalStatusChip :approval-status-id="1" small/>
					    <InvoiceApprovalStatusChip :approval-status-id="2" small/>
					    <InvoiceApprovalStatusChip :approval-status-id="3" small/>
				    </div>
			    </div>
			    
		    </app-card>
		    
	    </div>
        

		<app-card hide-header>
			<v-data-table 
				:items="reportRows" 
				:headers="headers" 
				:show-expand="showExpand"
				@click:row="(item, slot) => showExpand ? slot.expand(!slot.isExpanded) : null"
				id="invoices-table"
				ref="invoices-table" 
				single-expand
				:options.sync="options"
				:item-key="currentReport === 4 ? 'id' : 'InvoiceId'"
				:sort-by="sortBy"
				:sort-desc="sortDesc"
				:items-per-page="-1"
			>
				
				<template v-slot:item.id="{ item }">
					{{item.id}} 
					<AppChip small label v-if="item.deletedOn" color="red" >VOID</AppChip>
				</template>
				
				<template v-slot:item.DueDate="{ item }" v-if="currentReport === 2 || currentReport === 4">
					{{ item.DueDate }}
					<InvoiceDaysTillChip v-if="item.daysTillDue" :value="item.daysTillDue" />
				
				</template>
				
				<template v-slot:item.dueDate="{ item }" v-if="currentReport === 2 || currentReport === 4">
					{{ item.dueDate }}
					<InvoiceDaysTillChip v-if="currentReport === 2" :value="item.daysTillDue" />
				
				</template>
				
				<template v-slot:item.organizationId="{item}">
					{{item.organizationInfo.organizationId }}
				</template>
				<template v-slot:item.organizationName="{item}">
					{{item.organizationInfo.organizationName }}
				</template>
				
				<template v-slot:item.approvalStatusId="{ item }">
					<InvoiceApprovalStatusChip :approval-status-id="item.approvalStatusId" />
				</template>
				
				<template v-slot:item.Name="{ item }" v-if="currentReport === 2 || currentReport === 3">
					{{item.Name}}
					<app-chip
						v-if="item.IsResearch"
						color="purple"
						label
						class="ml-2"
						small
					>
						Research
					</app-chip>
					<app-chip
						v-if="item.IsDemo"
						color="green"
						label
						class="ml-2"
						small
					>
						Demo
					</app-chip>
					<app-chip
						v-if="item.IsDisabled"
						color="red"
						label
						class="ml-2"
						small
					>
						Disabled
					</app-chip>
				</template>
				
				<template v-slot:item.LastInvoiceDate="{ item }" v-if="currentReport === 3">
					{{item.LastInvoiceDate}}
					<app-chip
						color="blue"
						label
						class="ml-2"
						small
					>
						{{ item.daysSinceLastInvoice }} days ago
					</app-chip>
					
				</template>
				
				<template v-slot:item.DisplayDate="{ item }" v-if="currentReport === 2 || currentReport === 4">
					{{ item.displayDate }}
					<app-chip
						v-if="item.daysTillDisplayDate"
						:color="item.daysTillDisplayDate > 0 ? 'red' : item.daysTillDisplayDate == 0 ? 'green' : 'blue' "
						label
						class="ml-2"
						small
					>
						{{ item.daysTillDisplayDate !== 0 ? Math.abs(item.daysTillDisplayDate) : 'today' }} {{ item.daysTillDisplayDate > 0 ? 'days past due' : item.daysTillDisplayDate < 0 ? 'days out' : null }}
					</app-chip>
				
				</template>
				
				<template v-slot:item.displayDate="{ item }" v-if="currentReport === 2 || currentReport === 4">
					{{ item.displayDate }}
					<app-chip
						v-if="item.daysTillDisplayDate"
						:color="item.daysTillDisplayDate > 0 ? 'red' : item.daysTillDisplayDate == 0 ? 'green' : 'blue' "
						label
						class="ml-2"
						small
					>
						{{ item.daysTillDisplayDate !== 0 ? Math.abs(item.daysTillDisplayDate) : 'today' }} {{ item.daysTillDisplayDate > 0 ? 'days past due' : item.daysTillDisplayDate < 0 ? 'days out' : null }}
					</app-chip>
				
				</template>
				
				<template v-slot:item.invoiceTypeId="{ item }" v-if="currentReport === 4">
					<InvoiceTypeChip :invoice-type-id="item.invoiceTypeId" />				
				</template>

				
				<template v-slot:item.link="{ item }">
					<div @click="onRowClick(item)">
						<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M15.3089 18.8397H2.35521C1.05396 18.8397 0 17.7858 0 16.4847V3.53244C0 2.23132 1.05396 1.17748 2.35521 1.17748H7.06563V3.53244H2.35521V16.4847H15.3089V11.7748H17.6641V16.4847C17.6641 17.7858 16.6101 18.8397 15.3089 18.8397ZM9.06757 11.4298L7.40718 9.76487L14.8178 2.35496H10.5984V0H18.8417V8.24235H16.4865V4.02106L9.06757 11.4298Z" fill="#CCCCCC"/>
						</svg>
					
					</div>
				
				
				
				</template>
				
				<template v-slot:expanded-item="{ headers, item }" >
					<td :colspan="headers.length" style="background-color: #fafafa">
						<UnsentInvoicesDetails v-if="currentReport === 4" :invoice="item" />
					</td>
				
				</template>
				
				
			</v-data-table>
			
		</app-card>
	    

        
    </div>
</template>

<script>
import {getInvoiceReportExport, getUnpaidInvoiceReportExport, getMissingInvoiceReportExport, getUnsentInvoiceReportExport,
    getInvoiceReport, getUnpaidInvoiceReport, getMissingInvoiceReport, getUnsentInvoiceReport} from "@/services/report.service";

import moment from "moment";
import UnsentInvoicesDetails from "@/components/Reporting/InvoiceReports/UnsentInvoicesDetails";
import {invoiceApprovalStatuses} from "@/js/shared/constants";
import Invoice from "@/js/shared/classes/Invoice/Invoice";
import InvoiceApprovalStatusChip from "@/components/Billing/Chips/InvoiceApprovalStatusChip";
import InvoiceTypeChip from "@/components/Billing/Chips/InvoiceTypeChip";
import InvoiceDaysTillChip from "@/components/Billing/Chips/InvoiceDaysTillChip";
import {formatMoney} from "@/js/shared/helper";

export default {
    name: "InvoiceReports",
    components: {
	    InvoiceDaysTillChip,
	    InvoiceTypeChip,
	    InvoiceApprovalStatusChip,
	    UnsentInvoicesDetails
    },
    data () {
        return {
            headers: [],
	        options: {
				// itemsPerPage: 1000000
	        },
            reportRows: [],
            loading: true,
	        sortBy: [],
	        sortDesc: [],
	        unpaidInvoiceHeaders: [
		        {
			        text: "Invoice ID",
			        value: "InvoiceId"
		        },
		        {
			        text: "Org ID",
			        value: "OrganizationId"
		        },
		        {
			        text: "Org Name",
			        value: "Name"
		        },
		        {
			        text: "Issued On",
			        value: "IssuedOn"
		        },
		        {
			        text: "Due Date",
			        value: "DueDate"
		        },
		        {
			        text: "Deleted On",
			        value: "DeletedOn"
		        },
		        {
			        text: "Invoice Total",
			        value: "InvoiceTotal"
		        },
		        {
			        text: "Total Paid",
			        value: "TotalPaid"
		        },
                
                // {
                //     text: "Is Disabled",
                //     value: "IsDisabled"
                // },
                // {
                //     text: "Is Research",
                //     value: "IsResearch"
                // },
                // {
                //     text: "Is Demo",
                //     value: "IsDemo"
                // },
		        {
			        "text": " ",
			        "value": "link"
		        },
	        ],
	        missingInvoiceHeaders: [
		        {
					text: "Org ID",
			        value: "OrgId"
		        },
		        {
			        text: "Name",
			        value: "Name"
		        },
		        {
			        text: "Last Invoice Date",
			        value: "LastInvoiceDate"
		        },
		        {
			        text: "Org Deleted Date",
			        value: "OrgDeletedDate"
		        },
	        ],
	        unsentInvoiceHeaders: [
		        {
			        "text": "ID",
			        "value": "id"
		        },
		        {
			        "text": "Org Id",
			        "value": "organizationId"
		        },
		        {
			        text: "Invoice Type",
			        value: "invoiceTypeId"
		        },
		        {
			        "text": "Org Name",
			        "value": "organizationName"
		        },
		        {
			        "text": "Amount",
			        "value": "total"
		        },
		        {
			        "text": "Due Date",
			        "value": "dueDate"
		        },
		        {
			        "text": "Display Date",
			        "value": "displayDate"
		        },
		        // {
			    //     "text": "Renewal Date",
			    //     "value": "CurrentRenewalDate"
		        // },
                {
                    "text": "Approval Status",
                    "value": "approvalStatusId"
                },
		        {
			        "text": " ",
			        "value": "link"
		        },
	        ],
			currentReport: 0, // 0 = none, 1 = Invoice Report, 2 = Unpaid Invoice Report, 3 = Missing Invoice Report, 4 = Unsent Invoice Report
	        invoiceApprovalStatuses,
	        unpaidInvoiceReportStats: null
        }
    },

    mounted () {
        //this.getDataFromApi()
    },
    methods: {
        async getInvoiceReport() {
            this.loading = true;

            const response = await getInvoiceReport();
	        this.currentReport = 1
            this.headers = response.headers;
            this.reportRows = response.report;
			
        },
        async getUnpaidInvoiceReport() {
            this.loading = true;

            const response = await getUnpaidInvoiceReport();
	        this.currentReport = 2
            this.headers = this.unpaidInvoiceHeaders;
			
			let unpaidInvoiceReportStats = {
				totalAmount: 0,
				filteredTotalAmount: 0
			}

			
			let filteredRows = []
	
	        response.report.forEach((x, index) => {
				
				// x = new Invoice(x)
				
		        x.daysSinceLastInvoice = moment().diff(x.LastInvoiceDate, 'days')
		        
		        x.daysTillDue = x.DueDate ? moment().diff(x.DueDate, "days") * -1 : null;
				
		        // x.DaysPastDue = moment().isAfter(x.DueDate) ? moment().diff(x.DueDate, "days") : null;
		
		
		        unpaidInvoiceReportStats.totalAmount += x.InvoiceTotal
				
				if (x.daysTillDue < 0 && !x.DeletedOn && !x.IsDisabled) {
					unpaidInvoiceReportStats.filteredTotalAmount += x.InvoiceTotal
					
					filteredRows.push(x)
				}
		
		        x.InvoiceTotal = `$${x.InvoiceTotal}`;
		        x.TotalPaid = `$${x.TotalPaid}`;
		        x.IssuedOn = moment(x.IssuedOn).format("YYYY-MM-DD");
		        x.DueDate = x.DueDate ? moment(x.DueDate).format("YYYY-MM-DD") : null;
		        x.Name = x.OrganizationName;
				
	        })
	        
	        // let rows = response.report
	        
			unpaidInvoiceReportStats.totalAmount = formatMoney(unpaidInvoiceReportStats.totalAmount)
			unpaidInvoiceReportStats.filteredTotalAmount = formatMoney(unpaidInvoiceReportStats.filteredTotalAmount)
			this.unpaidInvoiceReportStats = unpaidInvoiceReportStats;		
	        
            this.reportRows = response.report;
	        
        },
        async getMissingInvoiceReport() {
            this.loading = true;

            const response = await getMissingInvoiceReport();
	        this.currentReport = 3
            this.headers = this.missingInvoiceHeaders;
			
			response.report.forEach(x => {
				
				
				x.daysSinceLastInvoice = moment().diff(x.LastInvoiceDate, 'days')
				x.LastInvoiceDate = moment(x.LastInvoiceDate).format("YYYY-MM-DD")
				x.OrgDeletedDate = x.OrgDeletedDate ? moment(x.OrgDeletedDate).format("YYYY-MM-DD") : null
				
			})
			
            this.reportRows = response.report;
	
	        this.sortBy = ['LastInvoiceDate']
	        this.sortDesc = [true]
	        
        },
        async getUnsentInvoiceReport() {
            this.loading = true;

            const response = await getUnsentInvoiceReport();
            // this.headers = response.headers;
	        this.currentReport = 4
	        let dates = ["OrganizationCreateDate", "InvoiceDate", "DueDate", "DateOfSale", "DisplayDate", "CurrentRenewalDate", "AmortizationStartDate", "AmortizationEndDate"]
	        
	        
            this.headers = this.unsentInvoiceHeaders;
	
			let data = response.report.map(x => new Invoice(x))
	        
	        this.sortBy = ['displayDate']
	        this.sortDesc = [false]
			
	        // response.report.forEach(row => {
	        //
		    //     // dates.forEach(x => row[x] = moment(row[x]).format("YYYY-MM-DD"));
			// 	// row["TotalAmount"] = `$${row["TotalAmount"]}`;
			// 	// row.daysTillDue = moment().diff(row.DueDate, 'days'); 
			// 	// row.daysTillDisplayDate = moment().diff(row.DisplayDate, 'days');
		    //     //
			// 	// // Approval Statuses:
		    //     // // Unknown = 0;
			// 	// // AutomatedUnapproved = 1;
		    //     // // AutomatedApproved = 2;
		    //     // // Manual = 3;
		    //     //
		    //     // row.approvalStatusColor = invoiceApprovalStatuses.find(x => x.value === row.ApprovalStatusId).tagColor;
		    //     // row.approvalStatusText = invoiceApprovalStatuses.find(x => x.value === row.ApprovalStatusId).text;
			//		
			// 	// switch (row.ApprovalStatusId) {
			// 	// 	case 0:
			// 	// 		row.approvalStatusColor = 'orange';
			// 	// 		row.approvalStatusText = 'Unknown';
			// 	// 		break;
			// 	// 	case 1:
			// 	// 		row.approvalStatusColor = 'red';
			// 	// 		row.approvalStatusText = 'Unapproved';
			// 	// 		break;
			// 	// 	case 2:
			// 	// 		row.approvalStatusColor = 'green';
			// 	// 		row.approvalStatusText = 'Approved - Auto';
			// 	// 		break;
			// 	// 	case 3:
			// 	// 		row.approvalStatusColor = 'green';
			// 	// 		row.approvalStatusText = 'Approved - Manual';
			// 	// 		break;
			// 	// }
			//	
	        // })
			
            this.reportRows = data;
			
			
        },
        async downloadInvoiceReport() {
            this.loading = true;

            const response = await getInvoiceReportExport();
        },
        async downloadUnpaidInvoiceReport() {
            this.loading = true;

            const response = await getUnpaidInvoiceReportExport();
        },
        async downloadMissingInvoiceReport() {
            this.loading = true;

            const response = await getMissingInvoiceReportExport();
        },
        async downloadUnsentInvoiceReport() {
            this.loading = true;

            const response = await getUnsentInvoiceReportExport();
        },
	    async onRowClick(item) {
			
			if (this.currentReport === 4) {
				window.open(`${window.location.origin}/billing/create-invoice?orgId=${item.organizationInfo.organizationId}&id=${item.id}`, '_blank')
			} else {
				window.open(`${window.location.origin}/billing/create-invoice?orgId=${item.OrganizationId}&id=${item.InvoiceId}`, '_blank')
			}
		    
	    },
    },
	computed: {
		showExpand() {
			return this.currentReport === 4
		}
	}
}
</script>

<style scoped>

</style>