<template>
    <div>
        <div v-if="!$store.state.currentAccount.isEnterpriseAdminOrg">
            <h3>
                Merge Profiles
            </h3>
            <app-form
                style="width: 350px"
                submit-button-title="Merge Profiles"
                :saving="isMergingProfiles"
                @submit="startMerge"
            >
                <div class="mt-2" >
                    <app-text-field
                        label="Profile ID to Keep"
                        row
                        v-model="mergeProfilesData.profileToKeepId"
                        white-background
                        rules="required|positive"
                    />
                    <br />
                    <app-text-field
                        label="Profile ID to Lose"
                        row
                        v-model="mergeProfilesData.profileToLoseId"
                        white-background
                        rules="required|positive"
                        class="mt-2"
                    />
                </div>
            </app-form>
            <app-confirm-dialog :open="mergeProfilesData.profileToKeepName && mergeProfilesData.profileToLoseName"
                                title="Confirm Merge"
                                @close="cancelMerge"
                                @confirm="confirmMerge"
                                :loading="isMergingProfiles">
                <div>
                    <p>Are you sure you want to merge the following profiles?</p>
                    <p>
                        Profile to Keep: {{mergeProfilesData.profileToKeepName}}<br/>
                        Profile to Lose: {{mergeProfilesData.profileToLoseName}}
                    </p>
                </div>
            </app-confirm-dialog>
        </div>
        <div v-if="$store.state.currentAccount.isEnterpriseAdminOrg && isAdminOrgAccessType($store.state.currentAccount.selectedOrganizationAccessTypeId)">
            <h3>
                Transfer Profile (Enterprise)
            </h3>
            <app-form
                    style="width: 350px"
                    submit-button-title="Transfer Profile"
                    :saving="isTransferringProfiles"
                    @submit="startTransferProfile"
            >
                <div class="mt-2" >
                    <app-text-field
                            label="Profile ID to Transfer"
                            row
                            v-model="transferProfileData.profileId"
                            type="number"
                            white-background
                            rules="required|positive"
                    />
                    <br />
                    <AppSelect label="New Organization"
                               row
                               v-model="transferProfileData.organizationId"
                               :items="organizationList"
                               item-text="label"
                               item-value="id"
                    />
                </div>
            </app-form>
            <app-confirm-dialog :open="transferProfileData.profileName"
                                title="Confirm Transfer"
                                @close="cancelTransferProfile"
                                @confirm="confirmTransferProfile"
                                :loading="isTransferringProfiles">
                <div>
                    <p>Are you sure you want to transfer the following profile?</p>
                    <p>
                        Profile: {{transferProfileData.profileName}}<br/>
                        New Organization: {{transferProfileData.organizationName}}
                    </p>
                </div>
            </app-confirm-dialog>
        </div>
        <div v-if="account.isSuperAdmin && !$store.state.currentAccount.isEnterpriseAdminOrg">
            <h3>
                Transfer Profile (Super Admin)
            </h3>
            <app-form
                    style="width: 350px"
                    submit-button-title="Transfer Profile"
                    :saving="isTransferringProfiles"
                    @submit="startTransferProfile"
            >
                <div class="mt-2" >
                    <app-text-field
                            label="Profile ID to Transfer"
                            row
                            v-model="transferProfileData.profileId"
                            type="number"
                            white-background
                            rules="required|positive"
                    />
                    <br />
                    <app-text-field
                               label="New Organization Id"
                               row
                               v-model="transferProfileData.organizationId"
                               type="number"
                               white-background
                               rules="required|positive"
                    />
                </div>
            </app-form>
            <app-confirm-dialog :open="transferProfileData.profileName"
                                title="Confirm Transfer"
                                @close="cancelTransferProfile"
                                @confirm="confirmTransferProfile"
                                :loading="isTransferringProfiles">
                <div>
                    <p>Are you sure you want to transfer the following profile?</p>
                    <p>
                        Profile: {{transferProfileData.profileName}}<br/>
                        New Organization: {{transferProfileData.organizationName}}
                    </p>
                </div>
            </app-confirm-dialog>
        </div>
        <div v-if="account.isSuperAdmin">
            <h3>
                Transfer Test
            </h3>
            <app-form
                    style="width: 350px"
                    submit-button-title="Transfer Test"
                    :saving="isTransferringTest"
                    @submit="onTransferTest"
            >
                <div  class="mt-2" >
    
                    <app-text-field
                            label="Test ID"
                            row
                            v-model="transferTestData.profileTestId"
                            white-background
                            rules="required|positive"
                    />
                    <app-text-field
                            label="Current Profile ID"
                            row
                            v-model="transferTestData.currentProfileId"
                            white-background
                            rules="required|positive"
                            class="mt-2"
                    />
                    <app-text-field
                            label="New Profile ID"
                            row
                            v-model="transferTestData.newProfileId"
                            white-background
                            rules="required|positive"
                            class="mt-2"
                    />
                </div>
            </app-form>

            <h3>
                Transfer Group of Profiles
            </h3>
            <app-form
                style="width: 350px"
                submit-button-title="Transfer Group of Profiles"
                :saving="isTransferringGroupOfProfiles"
                @submit="onTransferGroupOfProfiles"
            >
                <div style="display: flex; align-items: center">
                    <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>
                    <span style="color: rgba(255,0,0,.5); font-weight: 800; margin-left: 5px">IMPORTANT NOTE: </span>
                </div>
                <div style="margin-bottom: 10px">
                    <strong>All profiles in specified group will be transferred to the new organization and will be DELETED from the current organization.</strong>
                </div>
                <div style="margin-bottom: 10px">
                    Put a 0 for "New Group Id" to duplicate the group in the new organization.
                </div>
                <div  class="mt-2" >

                    <app-text-field
                        label="Current Org Id"
                        row
                        v-model="transferGroupOfProfiles.fromOrgId"
                        white-background
                        rules="required|positive"
                    />
                    <app-text-field
                        label="New Org Id"
                        row
                        v-model="transferGroupOfProfiles.toOrgId"
                        white-background
                        rules="required|positive"
                        class="mt-2"
                    />
                    <app-text-field
                        label="Current Group Id"
                        row
                        v-model="transferGroupOfProfiles.fromGroupIds"
                        white-background
                        rules="required"
                        class="mt-2"
                    />
                    <app-text-field
                        label="New Group Id"
                        row
                        v-model="transferGroupOfProfiles.toGroupId"
                        white-background
                        rules="required|positive"
                        class="mt-2"
                    />
                </div>
            </app-form>
        </div>
    </div>
</template>

<script>
import {startMergeProfiles, confirmMergeProfiles, startTransferProfile, confirmTransferProfile,
    transferTest, transferGroupOfProfiles} from "@/services/tools.service";
import {SET_SNACKBAR} from "@/store/mutation-types";
import {isAdminOrgAccessType} from "@/js/shared/helper";

export default {
    name: "Tools",
    data() {
        return {
            mergeProfilesData: {
                profileToKeepId: null,
                profileToKeepName: null,
                profileToLoseId: null,
                profileToLoseName: null,
            },
            isMergingProfiles: false,

            transferProfileData: {
                profileId: null,
                organizationId: null
            },
            isTransferringProfiles: false,
            
            transferTestData: {
                profileTestId: null,
                currentProfileId: null,
                newProfileId: null,
            },
            isTransferringTest: false,

            transferGroupOfProfiles: {
                fromOrgId: null,
                toOrgId: null,
                fromGroupIds: null,
                toGroupId: null,
            },
            isTransferringGroupOfProfiles: false
        }
    },
    props: {
        account: {}
    },
    computed: {
        viewingAsSuper() {
            let {viewingAsSuper} = this.$store.state.currentAccount;
            return viewingAsSuper;
        },
        organizationList() {
            return this.$store.state.currentAccount.availableOrganizations
                    .filter(x => !x.isDisabled && !x.isEnterpriseAdminOrg)
                    .map(x => ({label: x.name, id: x.id}));
        },
    },
    methods: {
        isAdminOrgAccessType,
        async startMerge() {
            this.isMergingProfiles = true
            let request = {...this.mergeProfilesData};
            
            let response = await startMergeProfiles(request);
            
            if (!response.isSuccess) {
                this.$store.commit(SET_SNACKBAR, {
                    text: response.errors[0].message,
                    color: 'error',
                    open: true
                });
            }
            else{
                this.mergeProfilesData.profileToLoseName = response.profileToLoseName;
                this.mergeProfilesData.profileToKeepName = response.profileToKeepName;
            }
            
            this.isMergingProfiles = false
        },
        async confirmMerge() {
            let request = {...this.mergeProfilesData};

            let response = await confirmMergeProfiles(request);

            if (!response.isSuccess) {

                this.$store.commit(SET_SNACKBAR, {
                    text: response.errors[0].message,
                    color: 'error',
                    open: true
                });
            } else {
                this.$store.commit(SET_SNACKBAR, {
                    text: `Successfully merged profiles.`,
                    color: 'success',
                    open: true
                });

                this.mergeProfilesData.profileToKeepId = null
                this.mergeProfilesData.profileToKeepName = null
                
                this.mergeProfilesData.profileToLoseId = null
                this.mergeProfilesData.profileToLoseName = null
            }

            this.isMergingProfiles = false
        },
        async cancelMerge(){
            this.mergeProfilesData.profileToLoseName = null;
            this.mergeProfilesData.profileToKeepName = null;

            this.isMergingProfiles = false;
        },
        async startTransferProfile() {
            this.isTransferringProfiles = true
            let request = {...this.transferProfileData};

            let response = await startTransferProfile(request);

            if (!response.isSuccess) {
                this.$store.commit(SET_SNACKBAR, {
                    text: response.errors[0].message,
                    color: 'error',
                    open: true
                });
            }
            else{
                this.transferProfileData.profileName = response.profileName;
                this.transferProfileData.organizationName = response.organizationName;
            }

            this.isTransferringProfiles = false
        },
        async confirmTransferProfile() {
            let request = {...this.transferProfileData};

            let response = await confirmTransferProfile(request);

            if (!response.isSuccess) {

                this.$store.commit(SET_SNACKBAR, {
                    text: response.errors[0].message,
                    color: 'error',
                    open: true
                });
            } else {
                this.$store.commit(SET_SNACKBAR, {
                    text: `Successfully transferred profiles.`,
                    color: 'success',
                    open: true
                });

                this.transferProfileData.profileId = null
                this.transferProfileData.organizationId = null
                this.transferProfileData.profileName = null
            }

            this.isTransferringProfiles = false
        },
        async cancelTransferProfile(){
            this.transferProfileData.profileId = null
            this.transferProfileData.organizationId = null
            this.transferProfileData.profileName = null

            this.isTransferringProfiles = false
        },
        async onTransferTest() {

            this.isTransferringTest = true
            let request = {...this.transferTestData};
            let response = await transferTest(request);

            if (!response.isSuccess) {

                this.$store.commit(SET_SNACKBAR, {
                    text: response.errors[0].message,
                    color: 'error',
                    open: true
                });
            } else {
                this.$store.commit(SET_SNACKBAR, {
                    text: `Successfully transferred test.`,
                    color: 'success',
                    open: true
                });

                this.transferTestData.newProfileId = null
                this.transferTestData.currentProfileId = null
                this.transferTestData.profileTestId = null
            }

            this.isTransferringTest = false
        },
        async onTransferGroupOfProfiles() {
            
            let confirmation = confirm("Are you sure? All profiles in Group #" + this.transferGroupOfProfiles.fromGroupIds + 
                " will be transferred to Org #" + this.transferGroupOfProfiles.toOrgId + 
                ", and will be deleted from Org #" + this.transferGroupOfProfiles.fromOrgId);
            
            if (!confirmation)
                return;

            this.isTransferringGroupOfProfiles = true
            let request = {...this.transferGroupOfProfiles};
            let response = await transferGroupOfProfiles(request);

            if (!response.isSuccess) {

                this.$store.commit(SET_SNACKBAR, {
                    text: response.errors[0].message,
                    color: 'error',
                    open: true
                });
            } else {
                this.$store.commit(SET_SNACKBAR, {
                    text: `Successfully transferred group of profiles.`,
                    color: 'success',
                    open: true
                });

                this.transferGroupOfProfiles.fromOrgId = null
                this.transferGroupOfProfiles.toOrgId = null
                this.transferGroupOfProfiles.fromGroupIds = null
                this.transferGroupOfProfiles.toGroupId = null
            }

            this.isTransferringGroupOfProfiles = false
        }
        
    }
}
</script>

<style scoped>

</style>