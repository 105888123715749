import httpService from "@/services/httpService";
import {baseUrl} from "@/config"


const endpoint = baseUrl + "/Protocol"

const protocolModel = {
    name: "",
    description: "",
    symptomsEnabled: true,
    eventsEnabled: true,
    legacyProtocolId: 0,
    isDisabled: true,
    protocolItems: [
    {
        regulationMeasureId: 0,
        regulationMeasureOrder: 0
    }
]
}

const getProtocols = async () => {
    try {
        return await httpService.post(endpoint + '/GetProtocols', )
    } catch (e) {
    }
}

const getProtocol = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetProtocol', {id})
 
    } catch (e) {
    }
}

const getMeasuresForProtocol = async (organizationProtocolId) => {
    try {
        return await httpService.post(endpoint + '/GetMeasuresForProtocol', {organizationProtocolId})
    } catch (e) {
    }
}

const getMeasuresForOrganization = async () => {
    try {
        return await httpService.post(endpoint + '/GetMeasuresForOrganization', )
    } catch (e) {
    }
}

const getAllMeasures = async () => {
    try {
        return await httpService.post(endpoint + '/GetAllMeasures', )
    } catch (e) {
    }
}

const addMeasureToOrganization = async (id) => {
    try {
        return await httpService.post(endpoint + '/AddMeasureToOrganization',  {id})
    } catch (e) {
    }
}

const removeMeasureFromOrganization = async (id) => {
    try {
        return await httpService.post(endpoint + '/RemoveMeasureFromOrganization',  {id})
    } catch (e) {
    }
}

const createProtocol = async (request) => {
    try {
        return await httpService.post(endpoint + '/CreateProtocol', request)
    } catch (e) {
    }
}

const updateProtocol = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateProtocol', request)
    } catch (e) {
    }
}

const deleteProtocol = async (id) => {
    try {
        return await httpService.post(endpoint + '/DeleteProtocol', {id})

    } catch (e) {
    }
}

const getStandardProtocols = async () => {
    try {
        return await httpService.post(endpoint + '/GetStandardProtocols')
    } catch (e) {
    }
}

const addStandardProtocol = async (id) => {
    try {
        return await httpService.post(endpoint + '/AddStandardProtocol', {id} )
    } catch (e) {
    }
}



export {
    getProtocols,
    getProtocol,
    getMeasuresForProtocol,
    getMeasuresForOrganization,
    protocolModel,
    createProtocol,
    deleteProtocol,
    updateProtocol,
    getAllMeasures,
    addMeasureToOrganization,
    removeMeasureFromOrganization,
    getStandardProtocols,
    addStandardProtocol
}
