<template>
    <div class="">
        <div>
            <app-btn style="" @click="getReportAll">
                Get All Orgs Report
            </app-btn>
            <app-fab-btn type="download" @click="downloadReportAll" />
        </div>
        <div style="margin-top:10px; margin-bottom:10px">
            <app-btn style="" @click="getReportActive">
                Get Active Orgs Report
            </app-btn>
            <app-fab-btn type="download" @click="downloadReportActive" />
        </div>

        <v-data-table :items="reportRows" :headers="headers">
            
        </v-data-table>
    </div>
</template>

<script>
import moment from 'moment'
import {getOrganizationReportExport, getOrganizationReport} from "@/services/report.service";

export default {
    name: "OrganizationReport",
    components: {
    },
    data () {
        return {
            headers: [],
            reportRows: [],
            loading: true
        }
    },

    mounted () {
        //this.getDataFromApi()
    },
    computed: {
    },
    methods: {
        async getReportAll() {
            this.loading = true;

            const response = await getOrganizationReport({ActiveOnly: false});
            this.headers = response.headers;
            this.reportRows = response.report;
        },
        async getReportActive() {
            this.loading = true;

            const response = await getOrganizationReport({ActiveOnly: true});
            this.headers = response.headers;
            this.reportRows = response.report;
        },
        async downloadReportAll() {
            this.loading = true;
            
            const response = await getOrganizationReportExport({ActiveOnly: false});
        },
        async downloadReportActive() {
            this.loading = true;

            const response = await getOrganizationReportExport({ActiveOnly: true});
        }
    },
}
</script>

<style scoped>

</style>