<template>
    <tbody>
        <tr v-for="item in mappedResults" :class="item.cssClass">
            <td v-if="item.hasBreakdown">
                <div>
                    <span style="">
                        {{item.title}}
                        <app-fab-btn
                                x-small
                                type="chevrondown"
                                @click="showTestBreakdown(item)"
                                v-if="testBreakdownTestId != item.testId"
                        />
                        <app-fab-btn
                                x-small
                                type="chevronup"
                                @click="testBreakdownTestId = null"
                                v-else
                        />
                    </span>
                </div>
                <div v-if="testBreakdownTestId == item.testId" style="padding-left:15px; font-style:italic">
                    <div v-if="algorithmVersion == 1">Displacement Score</div>
                    <div v-if="algorithmVersion == 1">Center Consistency Score</div>
                    <div v-if="algorithmVersion == 1">Deviation Angle Consistency Score</div>
                    <div v-if="algorithmVersion == 1">Rep Time Consistency Score</div>
                    <div v-if="algorithmVersion == 3">Motion Quality Score</div>
                    <div v-if="algorithmVersion == 3">Tempo Score</div>
                </div>
            </td>
            <td v-else>
                <div>{{item.title}}</div>
            </td>
            <td>
                <div>
                    <span style="">{{item.cell1}}</span>
                    <span v-if="testBreakdownTestId == item.testId" style="float: right">
                        <app-fab-btn
                                x-small
                                type="chart"
                                @click="openCobaltGraphs(item.resultId1)"
                        />
                    </span>
                </div>
                <div v-if="testBreakdownTestId === item.testId" style="padding-left:5px; font-style:italic">
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.displacementRow.cell1}}</div>
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.centerRow.cell1}}</div>
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.deviationAngleRow.cell1}}</div>
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.repTimeRow.cell1}}</div>
                    <div v-if="algorithmVersion == 3">{{item.scoreBreakdown.motionQualityScore2Row.cell1}}</div>
                    <div v-if="algorithmVersion == 3">{{item.scoreBreakdown.tempoScoreRow.cell1}}</div>
                </div>
            </td>
            <td v-if="hasAnyCell2">
                <div>
                    <span style="">{{item.cell2}}</span>
                    <span v-if="testBreakdownTestId == item.testId" style="float: right">
                        <app-fab-btn
                                x-small
                                type="chart"
                                @click="openCobaltGraphs(item.resultId2)"
                        />
                    </span>
                </div>
                <div v-if="testBreakdownTestId == item.testId" style="padding-left:5px; font-style:italic">
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.displacementRow.cell2}}</div>
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.centerRow.cell2}}</div>
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.deviationAngleRow.cell2}}</div>
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.repTimeRow.cell2}}</div>
                    <div v-if="algorithmVersion == 3">{{item.scoreBreakdown.motionQualityScore2Row.cell2}}</div>
                    <div v-if="algorithmVersion == 3">{{item.scoreBreakdown.tempoScoreRow.cell2}}</div>
                </div>
            </td>
            <td v-if="hasAnyCell3">
                <div>
                    <span style="">{{item.cell3}}</span>
                    <span v-if="testBreakdownTestId == item.testId" style="float: right">
                        <app-fab-btn
                                x-small
                                type="chart"
                                @click="openCobaltGraphs(item.resultId3)"
                        />
                    </span>
                </div>
                <div v-if="testBreakdownTestId == item.testId" style="padding-left:5px; font-style:italic">
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.displacementRow.cell3}}</div>
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.centerRow.cell3}}</div>
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.deviationAngleRow.cell3}}</div>
                    <div v-if="algorithmVersion == 1">{{item.scoreBreakdown.repTimeRow.cell3}}</div>
                    <div v-if="algorithmVersion == 3">{{item.scoreBreakdown.motionQualityScore2Row.cell3}}</div>
                    <div v-if="algorithmVersion == 3">{{item.scoreBreakdown.tempoScoreRow.cell3}}</div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script>

export default {
    name: "CobaltTestResultsTable",
    props: {
        individualMeasure: {
            required: true
        },
        editMode: {
            default: false
        },
        profileTest: {

        }
    },
    data() {
        return {
            mappedResults: [],
            hasAnyCell2: false,
            hasAnyCell3: false,

            testBreakdownTestId: null,
            testBreakdownRows: [],
            
            algorithmVersion: null
        }
    },
    computed: {

    },
    mounted() {
        this.buildTable();
    },
    methods: {
        buildTable() {
            
            let mappedResults = [];
            this.hasAnyCell2 = false;
            this.hasAnyCell3 = false;
            const resultHolder = this.individualMeasure; // WebProfileTestResults.cs

            let sum1 = 0.0;
            let sum2 = 0.0;
            let sum3 = 0.0;

            let count1 = 0;
            let count2 = 0;
            let count3 = 0;
            
            for (let i in resultHolder.results) {
                let result = resultHolder.results[i];
                let mappedResult = mappedResults.find(x => x.testId == result.test);
                if (!mappedResult){
                    mappedResult = {title: result.name, testId: result.test};
                    mappedResults.push(mappedResult);
                }
                
                // special handling of VMS tests
                if (result.skipped){

                    if (result.testNumber == 3){
                        mappedResult.cell3 = "Skipped";
                    }
                    else if (result.testNumber == 2){
                        mappedResult.cell2 = "Skipped";
                    }
                    else {
                        mappedResult.cell1 = "Skipped";
                    }
                }
                else if (result.test == 78 || result.test == 80) {

                    let json = JSON.parse(result.displayDataJson);
                    if (json)
                    {
                        this.algorithmVersion = json.algorithmVersion;

                        mappedResult.hasBreakdown = true;
                        if (!mappedResult.scoreBreakdown)
                            mappedResult.scoreBreakdown = {};
                    }

                    if (this.algorithmVersion == 1){
                        
                        if (!json.vmsAverageScore)
                            continue;

                        const displacementScore = json.displacementScore.toFixed(2);
                        const centerScore = json.centerConsistencyScore.toFixed(2);
                        const deviationAngleScore = json.deviationAngleConsistencyScore.toFixed(2);
                        const repTimeScore = json.repTimeConsistencyScore.toFixed(2);
                        const vmsAverageScore = json.vmsAverageScore.toFixed(2);

                        let displacementRow = mappedResult.scoreBreakdown.displacementRow;
                        if (!displacementRow)
                            displacementRow = mappedResult.scoreBreakdown.displacementRow = {};

                        let centerRow = mappedResult.scoreBreakdown.centerRow;
                        if (!centerRow)
                            centerRow = mappedResult.scoreBreakdown.centerRow = {};

                        let deviationAngleRow = mappedResult.scoreBreakdown.deviationAngleRow;
                        if (!deviationAngleRow)
                            deviationAngleRow = mappedResult.scoreBreakdown.deviationAngleRow = {};

                        let repTimeRow = mappedResult.scoreBreakdown.repTimeRow;
                        if (!repTimeRow)
                            repTimeRow = mappedResult.scoreBreakdown.repTimeRow = {};

                        let vmsAverageRow = mappedResult.scoreBreakdown.vmsAverageRow;
                        if (!vmsAverageRow)
                            vmsAverageRow = mappedResult.scoreBreakdown.vmsAverageRow = {};

                        if (result.testNumber == 3){
                            displacementRow.cell3 = displacementScore;
                            centerRow.cell3 = centerScore;
                            deviationAngleRow.cell3 = deviationAngleScore;
                            repTimeRow.cell3 = repTimeScore;
                            mappedResult.cell3 = vmsAverageScore;
                        }
                        else if (result.testNumber == 2){
                            displacementRow.cell2 = displacementScore;
                            centerRow.cell2 = centerScore;
                            deviationAngleRow.cell2 = deviationAngleScore;
                            repTimeRow.cell2 = repTimeScore;
                            mappedResult.cell2 = vmsAverageScore;
                        }
                        else {
                            displacementRow.cell1 = displacementScore;
                            centerRow.cell1 = centerScore;
                            deviationAngleRow.cell1 = deviationAngleScore;
                            repTimeRow.cell1 = repTimeScore;
                            mappedResult.cell1 = vmsAverageScore;
                        }
                    }
                    else if (this.algorithmVersion == 3){

                        let motionQualityScore2Row = mappedResult.scoreBreakdown.motionQualityScore2Row;
                        if (!motionQualityScore2Row)
                            motionQualityScore2Row = mappedResult.scoreBreakdown.motionQualityScore2Row = {};

                        let tempoScoreRow = mappedResult.scoreBreakdown.tempoScoreRow;
                        if (!tempoScoreRow)
                            tempoScoreRow = mappedResult.scoreBreakdown.tempoScoreRow = {};

                        if (result.testNumber == 3){
                            motionQualityScore2Row.cell3 = json.motionQualityScore2.toFixed(2);
                            tempoScoreRow.cell3 = json.tempoScore.toFixed(2);
                            mappedResult.cell3 = json.vmsAverageScore.toFixed(2);
                        }
                        else if (result.testNumber == 2){
                            motionQualityScore2Row.cell2 = json.motionQualityScore2.toFixed(2);
                            tempoScoreRow.cell2 = json.tempoScore.toFixed(2);
                            mappedResult.cell2 = json.vmsAverageScore.toFixed(2);
                        }
                        else {
                            motionQualityScore2Row.cell1 = json.motionQualityScore2.toFixed(2);
                            tempoScoreRow.cell1 = json.tempoScore.toFixed(2);
                            mappedResult.cell1 = json.vmsAverageScore.toFixed(2);
                        }
                    }
                }

                if (result.testNumber == 3){
                    mappedResult.cell3 = mappedResult.cell3 ?? result.display;
                    mappedResult.resultId3 = result.profileTestResultId;
                    this.hasAnyCell3 = true;
                    count3++;
                    if (!isNaN(mappedResult.cell3))
                        sum3 += Number(mappedResult.cell3);
                }
                else if (result.testNumber == 2){
                    mappedResult.cell2 = mappedResult.cell2 ?? result.display;
                    mappedResult.resultId2 = result.profileTestResultId;
                    this.hasAnyCell2 = true;
                    count2++;
                    if (!isNaN(mappedResult.cell2))
                        sum2 += Number(mappedResult.cell2);
                }
                else {
                    mappedResult.cell1 = mappedResult.cell1 ?? result.display;
                    mappedResult.resultId1 = result.profileTestResultId;
                    count1++;
                    if (!isNaN(mappedResult.cell1))
                        sum1 += Number(mappedResult.cell1);
                }
            }

            if (mappedResults.length > 1){
                var composite = {title: "Composite Score", cell1: (sum1/count1).toFixed(2), cssClass: "average-row", testId: -1};
                if (this.hasAnyCell2)
                    composite.cell2 = (sum2/count2).toFixed(2);
                if (this.hasAnyCell3)
                    composite.cell3 = (sum3/count3).toFixed(2);

                mappedResults.unshift(composite);
            }
            
            if (this.hasAnyCell2 || this.hasAnyCell3){
                var titleRow = {title: "", cell1: "Test 1", cssClass: "average-row", testId: -1};
                if (this.hasAnyCell2)
                    titleRow.cell2 = "Test 2";
                if (this.hasAnyCell3)
                    titleRow.cell3 = "Test 3";

                mappedResults.unshift(titleRow);
            }
            
            this.mappedResults = mappedResults;
        },
        showTestBreakdown(item){
            this.testBreakdownTestId = item.testId;
        },
        openCobaltGraphs(resultId){
            this.$emit('openCobaltGraphs', resultId)
        }
    }
}
</script>

<style scoped>

.excluded {
    opacity: .5
}
.flagged {
    color: #ff6b6b;
}

td, th {
    /*border: 1px solid #dddddd;*/
    text-align: left;
    padding: 8px;
}

tr:nth-child(even) {
    background-color: #eeeeee;
}

.average-row :first-child {
    font-weight: bold;
}

.right-align-row :first-child {
    text-align: right;
}
</style>