<template>
    <div>
        <div class="mb-2 text-right">
            <app-btn @click="addUsersDialogOpen = true">Add User to Group</app-btn>
        </div>
        
        <app-card hide-header>
            <v-simple-table>
                <template>
                    <thead>
                        <tr >
                            <th >
                                First Name
                            </th>
                            <th>
                                Last Name
                            </th>
                            <th>
                                Email
                            </th>
                            <th >
                                Phone Number
                            </th>
                            <th >
                                Actions
                            </th>
                        </tr>
                        
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in formattedUsers" :key="index">
                            <td>
                                {{user.firstName}}
                            </td>
                            <td>
                                {{user.lastName}}
                            </td>
                            <td>
                                {{user.email}}
                            </td>
                            <td>
                                {{user.phoneNumber}}
                            </td>
<!--                            <td>-->
<!--                                <v-checkbox v-model="temp" color="secondary"></v-checkbox>-->
<!--                            </td>-->
<!--                            <td>-->
<!--                                <v-checkbox v-model="temp" color="secondary"></v-checkbox>-->
<!--                            </td>-->
                            <td>
                                <v-tooltip bottom :open-delay="1000">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div >
                                            <v-icon v-bind="attrs"
                                                    v-if="!user.isAdmin"
                                                    v-on="on"
                                                    color="red"
                                                    @click="openRemoveUserDialog(user)"
                                            >mdi-close-circle</v-icon>
                                            <app-chip v-else label color="blue">Admin</app-chip>
                                        </div>
                                        
                                    </template>
                                    <span>Remove User</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </app-card>
        <dialog-form 
            title="Add Users to Group" 
            :open="addUsersDialogOpen"
            @close="addUsersDialogOpen = false"
            :width="700"
        >
            <template #form-content>
                <div style="border: 1px solid lightgrey; border-radius: 5px; overflow: hidden">
                    <v-data-table
                        :headers="headers"
                        :items="formattedAccounts"
                    >
                        <template #item.isAdmin="{item}">
                            <div >
                                <app-chip v-if="item.isAdmin" label color="blue">Admin</app-chip>
                                <app-chip v-else-if="item.isInGroup" label color="green">User</app-chip>
                                <div v-else>
                                    <v-icon color="green" @click="addUserToGroup(item)">mdi-plus-circle</v-icon>
                                </div>
                            </div>
                        </template>
                    </v-data-table>
                </div>
                
            </template>
            
        </dialog-form>
        <app-confirm-dialog
            title="Remove User from Group"
            submitButtonTitle="Remove User"
            :open="removeUserDialogOpen"
            @close="removeUserDialogOpen = false"
            confirm-delete
            @confirm="removeUser"
            :loading="removingUser"
            
        >
            Are you sure you want to remove {{userToRemove && userToRemove.firstName}} {{userToRemove && userToRemove.lastName}} from the group? 
        </app-confirm-dialog>
    </div>
</template>

<script>
import {getAccounts} from '@/services/userService'
import {updateGroupAccounts} from "@/services/groups.service";
import {isAdminOrgAccessType} from "@/js/shared/helper"

export default {
    name: "GroupUsers",
    props: {
        users: {
            type: Array,
            required: true
        },
        accounts: {
            type: Array,
            required: true
        },
        group: {
            required: true
        }
    },
    data() {
        return {
            selected: [14446],
            temp: false,
            // users: null,
            addUsersDialogOpen: false,
            removeUserDialogOpen: false,
            userToRemove: null,
            removingUser: false,
            headers: [
                {
                    text: 'First Name',
                    value: 'firstName'
                },
                {
                    text: 'Last Name',
                    value: 'lastName'
                },
                {
                    text: 'Action',
                    value: 'isAdmin'
                },
            ]
        }
    },
    mounted() {
      // this.getAccounts()
        if (this.$route.query.addUser === '1') {
            this.addUsersDialogOpen = true
        }
    },
    computed: {
        formattedUsers() {
            if (!this.formattedAccounts || this.formattedAccounts.length === 0) return []
            return this.users.map(user => {
                let isAdmin = false;
                this.formattedAccounts.forEach(account => {
                    if (account.id === user.id) {
                        if (account.isAdmin) isAdmin = true
                    }
                })
                user.isAdmin = isAdmin
                return user
            })
        },
        formattedAccounts() {
            
            let formattedAccounts = this.accounts.map(account => {
                account.isInGroup = false;
                account.isAdmin = isAdminOrgAccessType(account.selectedOrganizationAccessTypeId);
                let user = this.users.find(user => user.id === account.id)
                if (user) {
                    account.isInGroup = true;
                }
                
                return account
            });
            
            return formattedAccounts
        }  
    },
    methods: {
        // async getAccounts() {
        //     let response = await getAccounts();
        //     if (!response.isSuccess) {
        //         return
        //     }
        //     this.users = response.accounts;
        // },
        openRemoveUserDialog(userToRemove) {
            this.userToRemove = userToRemove;
            this.removeUserDialogOpen = true;
            
            
        },
        async addUserToGroup(user) {
            // let something = user
            let accountIds = [user.id];
            this.users.forEach(x => {
                accountIds.push(x.id)
            })
            
            let request = {
                groupId: this.group.id,
                accountIds,
            };
            this.$emit('updateGroupAccounts', request);
            this.addUsersDialogOpen = false
            
            // this.users.forEach(user => request.accountIds.push(user.id));
            // // 
            //
            // const response = updateGroupAccounts(request)
            
        },
        async removeUser() {
            this.removingUser = true;
            const {userToRemove} = this;
            let groupId = this.group.id;
            
            let accountIds = []
            this.users.forEach(user => {
                if (user.id !== userToRemove.id) accountIds.push(user.id)
            })
            
            
            
            let request = {
                groupId,
                accountIds
            }

            this.$emit('updateGroupAccounts', request)

            this.removingUser = false;
            this.removeUserDialogOpen = false
        },
    }
}
</script>

<style scoped>
th {
    /*border: 1px solid lightgrey*/
}
</style>