<template>
    <app-card hide-header>
        <div class="">
            <v-data-table
                :headers="headers"
                :items="organizations"
                :items-per-page="tableSettings.rowsPerPage"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="loading"
                :page="tableSettings.pageNumber"
                :hide-default-footer="true"
                class="profiles-table"
                @click:row="(item, slot) => onRowClick(item)"
                show-expand
            >
                <template v-slot:top>
                    <table-options-row>
                        <template #search>
                            <div class="pa-3" style="width: 100%">
                                <v-icon class="ml-2 mr-2">mdi-magnify</v-icon>
                                <input
                                    v-model="tableSettings.searchString"
                                    placeholder="Search"
                                    @input="updateSearchString"
                                    style="width: 80%"
                                >
                            </div>
                            
                        </template>
                    </table-options-row>
                </template>
                <template v-slot:no-data="{items}">
                    <div style="height: 200px; width: 200px; background-color: red">There are no profiles that match your search</div>
                </template>
                <!--                <template-->
                <!--                    v-slot:body="{ items, headers }"-->
                <!--                >-->
                <!--                    <table-body -->
                <!--                        :items="items"-->
                <!--                        :headers="headers"-->
                <!--                        @onRowClick="onRowClick($event)"-->
                <!--                    ></table-body>-->
                <!--                </template>-->

                <template v-slot:item.name="{ item }">
          
                        
                        <div style="display: flex; align-items: center">
                            <div>
                                {{item.name}}
                            </div>
                            <app-chip 
                                v-if="item.isResearch"
                                color="purple"
                                label
                                class="ml-2"
                                small
                            >
                                Research
                            </app-chip>
                            <app-chip
                                v-if="item.isDemo"
                                color="green"
                                label
                                class="ml-2"
                                small
                            >
                                Demo
                            </app-chip>
                            <app-chip
                                v-if="item.isPilot"
                                color="deep-cyan"
                                label
                                class="ml-2"
                                small
                            >
                                Pilot
                            </app-chip>
                            <app-chip
                                v-if="item.isDisabled"
                                color="red"
                                label
                                class="ml-2"
                                small
                            >
                                Disabled
                            </app-chip>
	                        <app-chip
		                        v-if="item.isInternalOrg"
		                        color="blue"
		                        label
		                        class="ml-2"
		                        small
	                        >
		                        Internal
	                        </app-chip>
	                        <app-chip
		                        v-if="item.isEnterpriseAdminOrg"
		                        color="orange"
		                        label
		                        class="ml-2"
		                        small
	                        >
		                        Enterprise
	                        </app-chip>
                        </div>
                    
                    
                    


                </template>
                <template v-slot:item.isInactive="{ item }">
                    <app-chip
                        :color="item.isInactive ? 'red' : 'green' "
                        dark
                    >
                        {{ item.isInactive ? 'Inactive' : 'Active' }}
                    </app-chip>


                </template>
                <template v-slot:item.createdOn="{ item }">
                    {{getDisplayDate(item.createdOn)}}


                </template>
<!--	            <template v-slot:item.link="{ item }">-->
<!--		            <div @click="onRowClick(item)">-->
<!--			            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--				            <path d="M15.3089 18.8397H2.35521C1.05396 18.8397 0 17.7858 0 16.4847V3.53244C0 2.23132 1.05396 1.17748 2.35521 1.17748H7.06563V3.53244H2.35521V16.4847H15.3089V11.7748H17.6641V16.4847C17.6641 17.7858 16.6101 18.8397 15.3089 18.8397ZM9.06757 11.4298L7.40718 9.76487L14.8178 2.35496H10.5984V0H18.8417V8.24235H16.4865V4.02106L9.06757 11.4298Z" fill="#CCCCCC"/>-->
<!--			            </svg>-->
<!--			            -->
<!--		            </div>-->
<!--		            -->
<!--	-->
<!--	-->
<!--	            </template>-->
	            <template v-slot:expanded-item="{ headers, item }">
		            <td :colspan="headers.length" style="background-color: #fafafa">
			            <SuperAdminOrgTableItemExpandSection 
				            :organization="item"
				            @updatedOrg="init"
			            />
		            </td>
	            </template>

                <template v-slot:footer="{ props }" >
                    <table-footer
                        :pagination="props.pagination"
                        @previousPage="previousPage(props)"
                        @nextPage="nextPage(props)"
                        @setPage="setPage($event)"
                        @setItemsPerPage="setItemsPerPage($event)"

                    ></table-footer>
                </template>
            </v-data-table>
            <div v-if="organizations.length === 0 && !loading" class="no-data">
                No profiles match your search criteria
            </div>
        </div>



    </app-card>
</template>

<script>
import {getOrganizationsPaged} from "@/services/superAdmin.service";
import TableFooter from "@/components/tables/TableFooter";
import TableOptionsRow from "@/components/tables/TableOptionsRow";
import TableHeader from "@/components/tables/TableHeader";
import TableBody from "@/components/tables/TableBody";
import moment from 'moment'
import SuperAdminOrgTableItemExpandSection from "@/components/SuperAdmin/SuperAdminOrgTableItemExpandSection";

export default {
    name: "SuperAdminOrganizationsTable",
    components: {
	    SuperAdminOrgTableItemExpandSection,
        TableFooter,
        TableOptionsRow,
        TableHeader,
        TableBody
    },
    data () {
        return {
            totalItems: 200,
            tableSettings: {
                pageNumber: 1,
                rowsPerPage: 10,
                sortByColumn: '',
                sortAscending: false,
                searchString: ''
            },
            organizations: [],
            loading: true,
            options: {},
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                    class: 'th-border'
                },
                { text: 'Name', value: 'name' },
                { text: 'Created On', value: 'createdOn' },
                { text: 'State', value: 'state' },
                // { text: 'Is Demo', value: 'isDemo'},
                // { text: 'Link', value: 'link'},
                
                // { text: 'Last Test Date', value: 'lastTestDate', class: 'text--text' },
                // { text: 'Active/Inactive', value: 'isInactive', class: 'text--text' },
            ],
            typingTimer: null,
            correlationId: self.crypto.randomUUID()
        }
    },

    mounted () {
        //set from query string or from defaults
        this.init()
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.totalProfiles / 10)
        },
    },
    methods: {
		init() {
			this.options.page = parseInt(this.$route.query.PageNumber ?? '') || 1;
			this.tableSettings.pageNumber = parseInt(this.$route.query.PageNumber ?? '') || 1;
			this.tableSettings.rowsPerPage = parseInt(this.$route.query.RowsPerPage ?? '') || 10;
			this.options.sortBy[0] = this.$route.query.SortByColumn ?? '';
			this.options.sortDesc[0] = (this.$route.query.SortAscending === 'true') ?? false;
			this.tableSettings.searchString = this.$route.query.SearchString ?? '';
			this.getDataFromApi()
		},
        getDisplayDate(value) {
            return moment(value).format('MM-DD-YYYY')
        },
        async onRowClick(org) {
            let x = org;
            //let result = await switchSelectedOrganization(org.id);
            
            window.open(`${window.location.origin}/dashboard?orgId=${org.id}`, '_blank')
            
            //await this.$router.push('/dashboard');
            
            //location.reload();
            
            // this.$router.push(`profiles/${profile.id}`)
        },
        previousPage(props) {
            const {pagination} = props;
            if (pagination.page === 1) return;

            this.tableSettings.pageNumber--;
            this.updateOptions()
        },
        nextPage(props) {
            const {pagination} = props;
            if (pagination.page === pagination.pageCount) return;
            this.tableSettings.pageNumber++;
            this.updateOptions()
        },
        setPage(page) {
            this.tableSettings.pageNumber = page;
            this.updateOptions()
        },
        setItemsPerPage(value) {
            this.tableSettings.rowsPerPage = value;
            this.updateOptions()
        },

        updateSearchString(event) {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                this.tableSettings.pageNumber = 1;
                this.updateOptions()
                // alert('stopped typing')
            }, 300)
        },
        // updatePageNumber(pageNumber) {
        //     this.updateOptions()
        // },
        async updateOptions() {
            // 
            const {page, itemsPerPage, sortBy, sortDesc, groupBy, groupDesc} = this.options;
            // this.tableSettings.pageNumber = page

            let pageNumber = this.tableSettings.pageNumber;
            if (!pageNumber || pageNumber == 0)
                pageNumber = 1;

            // let rowsPerPage = itemsPerPage;
            // if (!rowsPerPage || rowsPerPage == 0)
            let rowsPerPage = this.tableSettings.rowsPerPage;

            this.correlationId = self.crypto.randomUUID();
            let request = {
                PageNumber: pageNumber,
                RowsPerPage: this.tableSettings.rowsPerPage,
                SortByColumn: sortBy[0],
                SortAscending: sortDesc[0],
                SearchString: this.tableSettings.searchString,
                CorrelationId: this.correlationId
            }

            this.$router.push({path: this.$route.path, query: {...request}})
            
            console.log('REQUEST: ', request);
            this.loading = true;
            const response = await getOrganizationsPaged(request);
            if (response.correlationId !== this.correlationId) {
                debugger;
                return;
            }
            // 
            // const {data} = response;
            const {items, totalRowCount} = response
            // if (!profiles || !totalRowCount) return;
            this.organizations = items;
            this.totalItems = parseInt(totalRowCount);
            this.loading = false
        },
        async getDataFromApi() {
            console.log('Called getDataFromAPI')
            this.loading = true;
            
            //
            this.correlationId = self.crypto.randomUUID();
            let request = {
                PageNumber: this.tableSettings.pageNumber,
                RowsPerPage: this.tableSettings.rowsPerPage,
                SortByColumn: this.options.sortBy[0],
                SortAscending: this.options.sortDesc[0],
                SearchString: this.tableSettings.searchString,
                CorrelationId: this.correlationId
            }
            const response = await getOrganizationsPaged(request);
            if (response.correlationId !== this.correlationId) {
                debugger;
                return;
            }

            const {items, totalRowCount} = response;
            // if (!profiles || !totalRowCount) return;
            this.organizations = items;
            this.totalItems = parseInt(totalRowCount);
            this.loading = false

        },
    },
}
</script>

<style scoped>
.profiles-table {
    /*color: var(--v-text-base)*/
}

/*.profiles-table >>> tbody tr :hover {*/
/*  cursor: pointer;*/
/*  background-color: var(--v-accent-base);*/
/*}*/

/*.th-border {*/
/*    border: 10px solid red!important*/
/*}*/
/*.sortable {*/
/*    border: 10px solid red!important*/
/*}*/
/*.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {*/
/*    border: 10px solid red!important*/
/*}*/

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input {
    /*height: 40px*/
    width: 50%;
    /*height: 100%*/
}

.table-row:hover {
    background-color: var(--v-accent-base)!important;
    cursor: pointer
}
.table-row {
    background-color: white;
}

.search-input-area {
    border-radius: 15px;
    /*background-color: grey;*/
    height: 0px

}

.no-data {
    color: var(--v-text-base);
    margin: 20px
}

/*thead {*/
/*  background-color: red!important;*/
/*}*/

/*#profiles-table table thead tr th:nth-child(1) {*/
/*  background: green;*/
/*}*/
/*#profiles-table table thead tr th:nth-child(2) {*/
/*  background: red;*/
/*}*/

/*.v-data-table >>> thead {*/
/*    background-color: var(--v-text-base)!important;*/
/*    border: 10px solid red!important*/
/*}*/

.v-data-table >>> thead >>> tr >>> th {
    color: white;
    border: 1px solid red!important
}



path {
    color: red!important
}
</style>