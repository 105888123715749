<template>
    <app-card  hide-header>
        <app-data-table
            :headers="headers"
            :items="formattedSessions"
            @onRowClick="$emit('onRowClick', $event)"
            :loading="loading"
        >
            <template v-slot:item.isActive="{ item }">
                <div style="display: flex; align-items: center">
                    <div :style="{'background-color': item.isActive ? 'limegreen' : 'red'} " class="dot mr-2"></div>
                    <div>
                        {{item.isActive ? 'Active' : 'Expired'}}
                    </div>

                </div>
            </template>
            <template v-slot:item.name="{ item }">
                <div style="display: flex; align-items: center">
                    <TestTypeDot class="mr-2" :is-non-baseline="item.isNonBaseline" :has-duplicate-tests="!item.isTest" :is-dot-excluded="false" />
                    <div class="mr-2">
                        {{$store.state.currentAccount.viewingAsSuper && item.isProfileSession && item.baselineSessionTypeId !== 5 ? `Profile ID: ${item.profileId}` : item.displayName}}
                    </div>
                    <div >
                        <app-chip small label :color="item.isProfileSession ? 'blue' : 'green'" >{{ item.isProfileSession ? 'Profile' : 'Group'}}</app-chip>
                    </div>

                </div>
            </template>
            <template v-slot:item.code="{ item }">
                <app-chip
                    :color="item.isProfileSession ? 'blue' : 'green'"
                    dark
                >
                    {{ item.code }}
                </app-chip>
            </template>
            <template #no-data>
                <slot name="no-data"></slot>
            </template>
        </app-data-table>
        
    </app-card>
</template>

<script>
import {getSessionDetails} from "@/js/shared/helper";
import TestTypeDot from "@/components/TestTypeDot";

export default {
    name: "SessionsTable",
    components: {TestTypeDot},
    props: {
        sessions: {
            required: true
        },
        loading: {
            default: false
        }
    },
    data() {
        return {

        }
    },
    computed: {
        isViewingAsSuper() {
            let {viewingAsSuper} = this.$store.state.currentAccount;
            return viewingAsSuper;
        },
        headers() {
            // if (!) {
                return [
                    {
                        text: 'Name',
                        value: 'name',
                    },
                    // {
                    //     text: 'Type',
                    //     value: 'type',
                    // },
                    {
                        text: 'Protocol',
                        value: 'protocolName',
                    },
                    // {
                    //     text: 'Begins',
                    //     value: 'startsOnFormatted',
                    // },
	                {
		                text: 'Created On',
		                value: 'createdOnFormatted',
	                },
                    {
                        text: 'Expires',
                        value: 'endsOnFormatted',
                    },
	                
                    {
                        text: 'Code',
                        value: 'code',
                    },
                    {
                        text: 'Status',
                        value: 'isActive',
                    }
                ]
            //}
        },
        formattedSessions() {
            return this.sessions.map(session => {
                let formattedSession = getSessionDetails(session)
                
                //variable for fullname that uses ID if viewing as super, otherwise uses first and last name

                // if (formattedSession.isProfileSession) {
                //     console.log('formattedSession', formattedSession);
                //     debugger;
                //     formattedSession.name = this.isViewingAsSuper && formattedSession.profileId ? `Profile ID: ${formattedSession.profileId}` : formattedSession.displayName;
                // }
                // else {
                //     formattedSession.displayName = formattedSession.groupName;
                // }

                return formattedSession;
            });
        }
    }
}
</script>

<style scoped>
.dot {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: red;
}
</style>