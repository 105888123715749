<template>
  <div hide-header>
<!--    <v-card-title>-->
<!--	    -->
<!--      <v-text-field-->
<!--          v-model="search"-->
<!--          append-icon="mdi-magnify"-->
<!--          label="Search"-->
<!--          single-line-->
<!--          hide-details-->
<!--      ></v-text-field>-->
<!--    </v-card-title>-->

    <v-data-table
        :headers="headers"
        :items="items"
        :page="page"
        :hide-default-footer="hideDefaultFooter"
        class="text--text"
        color="text"
        :items-per-page="itemsPerPage"
        @click:row="onRowClick"
        :loading="loading"
        :loading-text="loadingText"
        :show-expand="showExpand"
        :search="search"
        :item-key="itemKey"

    >
	    <template v-slot:top>
		    <table-options-row>
			    <template #search>
				    <div class="pa-3" style="width: 100%">
					    <v-icon class="ml-2 mr-2">mdi-magnify</v-icon>
					    <input
						    v-model="search"
						    placeholder="Search"
						    @input="updateSearchString"
						    style="width: 80%"
					    >
				    </div>
			
			    </template>
		    </table-options-row>
	    </template>
	    
        <template v-slot:body.prepend="{props}"></template>
        
<!--        <template v-slot:top>-->
<!--            <table-options-row></table-options-row>-->
<!--        </template>-->
        
<!--        <template-->
<!--            v-slot:body="{ items, headers }"-->
<!--        >-->
<!--            <table-body-->
<!--                :items="items"-->
<!--                :headers="headers"-->
<!--                @onRowClick="onRowClick($event)"-->
<!--            ></table-body>-->
<!--        </template>-->

        <!--https://stackoverflow.com/questions/53430731/vuejs-nested-slots-how-to-pass-slot-to-grandchild-->
        <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="{item}">
            <slot :name="slot" :item="item"></slot>
        </template>
        <template #no-data>
            <slot name="no-data"></slot>
        </template>

        
<!--        <template v-slot:footer="{ props }" >-->
<!--            <table-footer-->
<!--                :pagination="props.pagination"-->
<!--                @previousPage="previousPage(props)"-->
<!--                @nextPage="nextPage(props)"-->
<!--                @setPage="setPage($event)"-->
<!--                @setItemsPerPage="setItemsPerPage($event)"-->

<!--            ></table-footer>-->
<!--        </template>-->
    </v-data-table>
  </div>
</template>

<script>
import { mdiContentCopy, mdiDownload, mdiDeleteOutline } from '@mdi/js'
import TableFooter from "@/components/tables/TableFooter";
import TableOptionsRow from "@/components/tables/TableOptionsRow";
import TableBody from "@/components/tables/TableBody";

export default {
    components: {
        TableFooter,
        TableOptionsRow,
        TableBody
    },
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean
        },
        loadingText: {
            type: String
        },
	    showExpand: {
		    type: Boolean,
		    default: false
	    },
	    itemKey: {
			
	    },
	    showAllItems: {
			type: Boolean,
		    default: false
	    },
	    hideDefaultFooter: {
			type: Boolean,
		    default: false
	    }
    },
    name: "AppDataTable",
    data () {
        return {
            page: 1,
            itemsPerPage: 10,
            search: ''
        }
    },
	mounted() {
		if (this.showAllItems) {
			this.showAllItemsFunc()
		}
	},
	watch: {
		showAllItems: function(newVal, oldVal) {
			if (newVal) {
				this.showAllItemsFunc()
			} else {
				this.itemsPerPage = 10
			}
		}
	},
    methods: {
		showAllItemsFunc() {
			this.itemsPerPage = -1
		},
        onRowClick(item) {
            this.$emit('onRowClick', item)
        },
	    updateSearchString(event) {
		    clearTimeout(this.typingTimer);
		    this.typingTimer = setTimeout(() => {
			    this.tableSettings.pageNumber = 1;
			    this.updateOptions()
			    // alert('stopped typing')
		    }, 300)
	    },
        previousPage(props) {
            const {pagination} = props;
            if (pagination.page === 1) return;

            this.page--
        },
        nextPage(props) {
            const {pagination} = props;
            if (pagination.page === pagination.pageCount) return;
            this.page++
        },
        setPage(page) {
            this.page = page
        },
        setItemsPerPage(value) {
            this.itemsPerPage = value
        }
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.items.length / 10)
        }
    }
}
</script>

<style scoped>
.table-row:hover {
    background-color: var(--v-accent-base)!important;
    cursor: pointer
}
.table-row {
    background-color: white;
    color: var(--v-text-base)
}

td {
    background-color: red!important;
}


.search-input-area {
	border-radius: 15px;
}
/*.v-data-table >>> thead {*/
/*  background-color: var(--v-text-base)!important;*/
/*  color: white!important;*/
/*}*/

.v-data-footer {
    background-color: white!important;
    color: var(--v-text-base)!important
}
.data-table-footer {
    background-color: white!important;
    color: var(--v-text-base);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 1px solid lightgrey
}

button {
    box-shadow: none!important;
    color: red
}



.pointer:hover {
    cursor: pointer
}
</style>