<template>
	<app-chip 
		:color="color" 
		
		:small="small"
		:label="label"
	>
		{{ text }}
	</app-chip>
</template>

<script>
import Invoice from "@/js/shared/classes/Invoice/Invoice";

export default {
	name: "InvoiceTypeChip",
	props: {
		invoiceTypeId: {
			required: true
		},
		small: {
			type: Boolean
		},
		label: {
			type: Boolean,
			default: true
		}
	},
	watch: {
		invoiceTypeId() {
			this.init()
		}
	},
	data() {
		return {
			color: null,
			text: null,
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			let invoiceTypesObject = Invoice.invoiceTypes.find(x => x.value === this.invoiceTypeId);
			this.color = invoiceTypesObject.chipColor
			this.text = invoiceTypesObject.text
		}
	}
}
</script>

<style scoped>

</style>