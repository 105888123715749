<template>
    <div >
        <component
            :is="currentComponent"
            :profile="profile"
            :profileTest="profileTest"
            :completedOnDate="completedOnDate"
            :viewingAsSuper="false"

        >
            
        </component>
    </div>
</template>

<script>
import moment from "moment";
import ProfileHeaderWithoutAdditionalData
    from "@/components/pdf-export/ProfileHeader/ProfileHeaderWithoutAdditionalData";
import ProfileHeaderWithAdditionalData from "@/components/pdf-export/ProfileHeader/ProfileHeaderWithAdditionalData";

export default {
    name: "ProfileHeader",
    components: {ProfileHeaderWithoutAdditionalData, ProfileHeaderWithAdditionalData},
    props: {
        profile: {
            type: Object,
            required: true
        },
        profileTest: {
            required: true
        },
        enableSportsProfileProperties: {
            default: true
        }
    },
    computed: {
        storeAccount() {
            return this.$store.state.currentAccount
        },
        currentComponent() {
            return this.profile.profileInfo.additionalData && this.enableSportsProfileProperties ? ProfileHeaderWithAdditionalData :  ProfileHeaderWithoutAdditionalData
        },
        
        completedOnDate() {
            let temp = moment(this.profileTest.data.completedOn)
            let date = temp.format('MM/DD/YYYY')
            let time = temp.format('h:mm A')
            return `${date} at ${time}`
        }
    },
}
</script>

<style scoped>

</style>