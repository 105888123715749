import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Settings from "@/views/Settings";
import Dashboard from "@/views/Dashboard";
import DashboardSwitcher from "@/views/DashboardSwitcher";
import ProfileTest from "@/views/ProfileTest";
import Profile from "@/views/Profile";
import Profiles from "@/views/Profiles";
import Groups from "@/views/Groups";
import Sessions from "@/components/Sessions/Sessions";
import Users from "@/views/Users";
import DashboardLayout from '@/components/DashboardLayout'
import {getUser, switchSelectedOrganization, refreshToken} from '@/services/userService'
import Account from "@/views/Account";
import Group from "@/views/Group";
import Protocols from "@/views/Protocols";
import Protocol from "@/views/Protocol";
import Session from "@/components/Sessions/Session";
import User from "@/views/User";
import Organization from "@/views/Organization";
import Billing from "@/components/Billing/Billing";
import BillingSettings from "@/components/Billing/BillingSettings";
import BillingOverview from "@/components/Billing/BillingOverview";
import ProfilesTested from "@/components/Billing/ProfilesTested";
import OrganizationApi from "@/components/OrganizationPages/OrganizationApi";
import OrganizationSettings from "@/components/OrganizationPages/OrganizationSettings";
import OrganizationSecurity from "@/components/OrganizationPages/OrganizationSecurity";
import SelectOrganization from "@/views/SelectOrganization";
import BillingHistory from "@/components/Billing/BillingHistory";
import SuperAdmin from "@/components/SuperAdmin/SuperAdmin";
import OrganizationInvoice from "@/components/Billing/OrganizationInvoice";
import GroupOverview from "@/components/GroupPages/GroupOverview";
import GroupProfiles from "@/components/GroupPages/GroupProfiles";
import GroupUsers from "@/components/GroupPages/GroupUsers";
import GroupSchedule from "@/components/GroupPages/GroupSchedule";
import GroupNotifications from "@/components/GroupPages/GroupNotifications";
import Surveys from "@/views/Surveys";
import CreateSurvey from "@/components/SurveyPages/CreateSurvey";
import SwayReporting from "@/components/Reporting/SwayReporting";
import SwayDashboard from "@/components/Reporting/SwayDashboard";
import OrganizationReport from "@/components/Reporting/OrganizationReport";
import FullpageInvoice from "@/components/Billing/FullpageInvoice";
import ProfileOverview from "@/components/Profile/ProfileOverview";
import ProfileGroups from "@/components/Profile/ProfileGroups";
import ProfileCalendar from "@/components/Profile/ProfileCalendar";
import ProfilesTable from "@/components/ProfilesTable";
import BulkMove2 from "@/views/BulkMove2";
import FullpageProfileTest from "@/views/FullpageProfileTest";
import RedeemProfileAccessLink from "@/views/RedeemProfileAccessLink";
import AccountPersonalInformation from "@/components/Account/AccountPersonalInformation";
import ChangePassword from "@/components/Account/ChangePassword";
import AccountGroups from "@/components/Account/AccountGroups";
import GroupSessions from "@/components/GroupPages/GroupSessions";
import ProfileSessions from "@/components/Profile/ProfileSessions";
import ProfileBaselines from "@/components/Profile/ProfileBaselines";
import ProfileTrends from "@/components/Profile/ProfileTrends";
import ProfileTestHistory from "@/components/Profile/ProfileTestHistory";


import OrgManagementDashboard from "@/components/OrgManagement/OrgManagementDashboard";
import SubscriptionGeneral from "@/components/OrgManagement/SubscriptionGeneral";
import SubscriptionMeasures from "@/components/OrgManagement/SubscriptionMeasures";
import ProfileCountTables from "@/components/OrgManagement/ProfileCountTables";
import OrgManagementReports from "@/components/OrgManagement/Reports";
import ProfilesTestedReport from "@/components/OrgManagement/ProfilesTestedReport";
import EmailReport from "@/components/OrgManagement/EmailReport";
import OrgConnectManagement from "@/components/OrgManagement/OrgConnectManagement";

import NewLogin from "@/components/Login/NewLogin";
import OrganizationViewBills from "@/components/Billing/OrganizationViewBills";
import ForgotPassword from "@/components/Login/ForgotPassword";
import ResetPassword from "@/components/Login/ResetPassword";
import GetMobileToken from "@/components/Login/GetMobileToken";
import PageNotFound from "@/views/PageNotFound";
import Tools from "@/views/Tools";
import SwayTestsByDomain from "@/components/Reporting/SwayTestsByDomain";
import OrganizationStatistics from "@/components/OrganizationPages/OrganizationStatistics";
import SuperAdminOrganizationsTable from "@/components/SuperAdmin/SuperAdminOrganizationsTable";
import SuperAdminProfileUserTable from "@/components/SuperAdmin/SuperAdminProfileUserTable";
import SuperAdminInvoicesTable from "@/components/SuperAdmin/SuperAdminInvoicesTable";
import SubscriptionReport from "@/components/Reporting/SubscriptionReport";
import RawDataExport from "@/components/Reporting/RawDataExport";
import NcesIdTool from "@/components/SuperAdmin/NcesIdTool";
import CreateInvoice from "@/components/Billing/CreateInvoice";
import InvoiceReports from "@/components/Reporting/InvoiceReports";
import EmailReportAllOrgs from "@/components/Reporting/EmailReport";
import ProfileEvents from "@/components/Profile/ProfileEvents";
import Reporting from "@/views/Reporting";
import NewOrgEnrollment from "@/components/SuperAdmin/NewOrgEnrollment";
import EnterpriseEnrollment from "@/views/EnterpriseEnrollment.vue";

import OrgManagement from "@/views/OrgManagement";
import AccessConnections from "@/components/OrgManagement/Connections/AccessConnections";
import BillingConnections from "@/components/OrgManagement/Connections/BillingConnections";
import OverviewConnections from "@/components/OrgManagement/Connections/OverviewConnections";





Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Dashboard Layout',
        component: DashboardLayout,
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: DashboardSwitcher
            },
            {
                path: 'profiles',
                name: 'Profiles',
                component: Profiles,
                children: [
                    {
                        path: '',
                        name: 'Profiles Table',
                        component: ProfilesTable,
                    },
                    // {
                    //   path: 'bulk-move',
                    //   name: 'Profiles Table',
                    //   component: BulkMove2,
                    // },
                ]
            },
            {
                path: 'profiles/bulk-move/:groupId?',
                name: 'Bulk Move',
                component: BulkMove2
            },
            {
                path: 'profiles/:profileId',
                name: 'Profile',
                component: Profile,
                children: [
                    {
                        path: 'overview',
                        name: 'Profile Overview',
                        component: ProfileOverview,
                    },
                    {
                        path: 'groups',
                        name: 'Profile Groups',
                        component: ProfileGroups,
                    },
                    {
                        path: 'codes',
                        name: 'Profile Codes',
                        component: ProfileSessions,
                    },
                    {
                        path: 'events',
                        name: 'Profile Events',
                        component: ProfileEvents,
                    },
                    {
                        path: 'calendar',
                        name: 'Profile Calendar',
                        component: ProfileCalendar,
                    },
                    {
                        path: 'baselines',
                        name: 'Profile Baselines',
                        component: ProfileBaselines,
                    },
                    {
                        path: 'trends',
                        name: 'Profile Trends',
                        component: ProfileTrends,
                    },
                    {
                        path: 'test-history',
                        name: 'Profile Test History',
                        component: ProfileTestHistory,
                    },
                    {
                        path: '',
                        redirect: 'overview',
                    },

                ]
            },
            {
                path: 'profiles/:profileId/test/:testId',
                name: 'ProfileTest',
                component: ProfileTest,
            },
            {
                path: 'groups/:id',
                name: 'Group',
                component: Group,
                children: [
                    {
                        path: 'overview',
                        name: 'Group Overview',
                        component: GroupOverview,
                    },
                    {
                        path: 'profiles',
                        name: 'Group Profiles',
                        component: GroupProfiles,
                    },
                    {
                        path: 'users',
                        name: 'Groups Users',
                        component: GroupUsers,
                    },
                    {
                        path: 'schedule',
                        name: 'Group Schedule',
                        component: GroupSchedule,
                    },
                    {
                        path: 'codes',
                        name: 'Group Codes',
                        component: GroupSessions,
                    },
                    {
                        path: 'notifications',
                        name: 'Group Notifications',
                        component: GroupNotifications,
                    },

                ]
            },
            {
                path: 'groups',
                name: 'Groups',
                component: Groups,

            },

            {
                path: 'protocols/:id',
                name: 'Protocol',
                component: Protocol
            },
            {
                path: 'protocols',
                name: 'Protocols',
                component: Protocols
            },

            {
                path: 'tools',
                name: 'Tools',
                component: Tools
            },
            {
                path: 'codes/:id',
                name: 'Code',
                component: Session
            },
            {
                path: 'codes',
                name: 'Codes',
                component: Sessions
            },
            {
                path: 'settings',
                name: 'Settings',
                component: Settings
            },
            {
                path: 'surveys',
                name: 'Surveys',
                component: Surveys,
                children: [
                    {
                        path: 'create',
                        name: 'Create Survey',
                        component: CreateSurvey
                    }
                ]
            },
            {
                path: 'organization',
                name: 'Organization',
                component: Organization,
                children: [

                    {
                        path: 'api',
                        name: 'API',
                        component: OrganizationApi,
                    },
                    {
                        path: 'settings',
                        name: 'Organization Settings',
                        component: OrganizationSettings,
                    },
                    {
                        path: 'security',
                        name: 'Organization Security',
                        component: OrganizationSecurity,
                    },
                    {
                        path: 'statistics',
                        name: 'Organization Statistics',
                        component: OrganizationStatistics,
                    },
                    {
                        path: '',
                        redirect: '/organization/settings'
                    },


                    // {
                    //   path: 'overview',
                    //   name: 'Organization Overview',
                    //   component: OrganizationOverview,
                    // },
                    //
                    // {
                    //   path: '',
                    //   name: 'Organization Overview',
                    //   component: OrganizationOverview,
                    // },
                ]
            },
            {
                path: 'billing',
                name: 'Billing',
                component: Billing,
                children: [
                    {
                        path: 'billing-overview',
                        name: 'Billing Overview',
                        component: BillingOverview,
                    },
                    {
                        path: 'billing-settings',
                        name: 'Billing Settings',
                        component: BillingSettings,
                    },
                    {
                        path: 'billing-history',
                        name: 'Billing History',
                        component: BillingHistory,
                        children: [ // I think this is unused....
                            {
                                path: ':invoiceId',
                                name: 'Organization Invoice',
                                component: OrganizationInvoice
                            }
                        ]
                    },
                    {
                        path: 'view-invoices/:id?',
                        name: 'Organization View Invoices',
                        component: OrganizationViewBills,
                    },
                    {
                        path: 'create-invoice/:id?',
                        name: 'Create Invoice',
                        component: CreateInvoice,
                    },
                    {
                        path: 'profiles-tested',
                        name: 'Profiles Tested',
                        component: ProfilesTested,
                    },
                    {
                        path: '',
                        redirect: '/billing/billing-overview'
                    }
                ]
            },
            {
                path: 'users/:id',
                name: 'User',
                component: User
            },
            {
                path: 'users',
                name: 'Users',
                component: Users
            },
            {
                path: 'reporting',
                name: 'Reporting',
                component: Reporting
            },
            {
                path: 'enterprise-enrollment',
                name: 'Enterprise Enrollment',
                component: EnterpriseEnrollment
            },
            {
                path: 'account',
                name: 'Account',
                component: Account,
                children: [
                    {
                        path: 'personal-information',
                        name: 'Personal Information',
                        component: AccountPersonalInformation,
                    },
                    {
                        path: 'password',
                        name: 'Account Password',
                        component: ChangePassword,
                    },
                    {
                        path: 'groups',
                        name: 'Account Groups',
                        component: AccountGroups,
                    },
                    {
                        path: '',
                        redirect: '/account/personal-information'
                        // name: 'Personal Information',
                        // component: AccountPersonalInformation,
                    },
                ]
            },
            {
                path: 'super-admin',
                name: 'Internal Sway Admin',
                component: SuperAdmin,
                children: [

                    {
                        path: 'organizations',
                        name: 'Organizations',
                        component: SuperAdminOrganizationsTable,
                    },
                    {
                        path: 'profiles-and-users',
                        name: 'Profiles and Users',
                        component: SuperAdminProfileUserTable,
                    },
                    {
                        path: 'all-invoices',
                        name: 'All Invoices',
                        component: SuperAdminInvoicesTable,
                    },
                    {
                        path: 'nces-tool',
                        name: 'NCES Tool',
                        component: NcesIdTool,
                    },
                    {
                        path: 'new-org-enrollment',
                        name: 'New Org Enrollment',
                        component: NewOrgEnrollment,
                    },
                    
                    {
                        path: 'reporting',
                        name: 'Reporting',
                        component: SwayReporting,
                        children: [
                            {
                                path: 'organization-report',
                                name: 'Organization Report',
                                component: OrganizationReport,
                            },
                            {
                                path: 'subscription-report',
                                name: 'Subscription Report',
                                component: SubscriptionReport,
                            },
                            {
                                path: 'sway-dashboard',
                                name: 'Sway Dashboard',
                                component: SwayDashboard,
                            },
                            {
                                path: 'tests-by-domain',
                                name: 'Sway Tests By Domain',
                                component: SwayTestsByDomain,
                            },
                            // {
                            //     path: 'profiles-tested-report',
                            //     name: 'Profiles Tested Report',
                            //     component: ProfilesTestedReport,
                            // },
                            {
                                path: 'raw-data-export',
                                name: 'Raw Data Export',
                                component: RawDataExport,
                            },
                            {
                                path: 'invoice-reports',
                                name: 'Invoice Reports',
                                component: InvoiceReports,
                            },
                            {
                                path: 'email-report',
                                name: 'Email Report',
                                component: EmailReportAllOrgs,
                            },
                            {
                                path: '',
                                redirect: '/super-admin/reporting/organization-report'
                            },
                        ],
                        
                    },
                    {
                        path: '',
                        redirect: '/super-admin/organizations'
                    },
                ]
            },
            {
                path: 'org-management',
                name: 'Org Management',
                component: OrgManagement,
                children: [
                    {
                        path: 'overview',
                        name: 'Overview',
                        component: OrgManagementDashboard,
                    },
                    {
                        path: 'usage',
                        name: 'Usage',
                        component: ProfileCountTables,
                    },
                    {
                        path: 'subscription',
                        name: 'Subscription General',
                        component: SubscriptionGeneral,
                    },
                    {
                        path: 'measures',
                        name: 'Subscription Measures',
                        component: SubscriptionMeasures,
                    },
                    {
                        path: 'connections',
                        name: 'Org Connect Management',
                        component: OrgConnectManagement,
                        children: [
                            {
                                path: '',
                                name: 'Overview',
                                component: OverviewConnections,
                            },
                            {
                                path: 'access',
                                name: 'Access',
                                component: AccessConnections,
                            },
                            {
                                path: 'billing',
                                name: 'Billing',
                                component: BillingConnections,
                            },
                            {
                                path: '',
                                redirect: ''
                            },
                        ]
                    },
                    {
                        path: 'reporting',
                        name: 'Reports',
                        component: OrgManagementReports,
                        children: [
                            {
                                path: 'profiles-tested-report',
                                name: 'Profiles Tested Report',
                                component: ProfilesTestedReport,
                            },
                            {
                                path: 'billing-email-log',
                                name: 'Billing Email Log',
                                component: EmailReport,
                            },
                            {
                                path: '',
                                redirect: 'profiles-tested-report'
                            },
                        ],
                    },
                    
                    {
                        path: '',
                        redirect: 'overview'
                    }

                ]
            },
            // {
            
            //     path: 'sway-reporting',
            //     name: 'Sway Reporting',
            //     component: SwayReporting,
            //     children: [
            //         {
            //             path: 'organization-report',
            //             name: 'Organization Report',
            //             component: OrganizationReport,
            //         },
            //         {
            //             path: 'subscription-report',
            //             name: 'Subscription Report',
            //             component: SubscriptionReport,
            //         },
            //         {
            //             path: 'sway-dashboard',
            //             name: 'Sway Dashboard',
            //             component: SwayDashboard,
            //         },
            //         {
            //             path: 'tests-by-domain',
            //             name: 'Sway Tests By Domain',
            //             component: SwayTestsByDomain,
            //         },
            //         {
            //             path: 'profiles-tested-report',
            //             name: 'Profiles Tested Report',
            //             component: ProfilesTestedReport,
            //         },
            //         {
            //             path: 'raw-data-export',
            //             name: 'Raw Data Export',
            //             component: RawDataExport,
            //         },
            //         {
            //             path: 'invoice-reports',
            //             name: 'Invoice Reports',
            //             component: InvoiceReports,
            //         },
            //         {
            //             path: 'email-report',
            //             name: 'Email Report',
            //             component: EmailReport,
            //         }
            //     ]
            // },
            {
                path: '',
                redirect: '/dashboard'
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: NewLogin
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: ForgotPassword
    },
    {
        path: '/reset-password',
        name: 'Reset Password',
        component: ResetPassword
    },
    {
        path: '/get-mobile-token',
        name: 'Get Mobile Token',
        component: GetMobileToken
    },
    
    {
        path: '/select-organization',
        name: 'SelectOrganization',
        component: SelectOrganization
    },
    {
        path: '/sway-dashboard',
        name: 'Sway Dashboard',
        component: SwayDashboard
    },
    {
        path: '/sway-tools',
        name: 'Sway Tools',
        component: SwayTestsByDomain
    },
    {
        path: '/invoice-fullpage',
        name: 'Fullpage Invoice',
        component: FullpageInvoice
    },
    {
        path: '/profile-test-fullpage',
        name: 'Fullpage Profile Test',
        component: FullpageProfileTest
    },
    {
        path: '/redeem-profile-access-link',
        name: 'Redeem Profile Access Link',
        component: RedeemProfileAccessLink
    },

    {
        path: '/',
        redirect: '/dashboard'
    },
    {
        path: '',
        redirect: '/dashboard'
    },

    {
        path: "*",
        component: PageNotFound,
        name: '404'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

const noAuthRoutes = ['Login', 'Fullpage Invoice', 'Fullpage Profile Test'];

router.beforeEach(async (to, from, next) => {
    let user = getUser()
    
    if (
        to.name === 'Login' ||
        to.name === 'Login2' ||
        to.name === 'Forgot Password' ||
        to.name === 'Reset Password' ||
        to.name === 'Fullpage Invoice' ||
        to.name === '404' ||
        to.name === '5.0 Release Notes' ||
        to.name === 'Fullpage Profile Test'

    ) {

        //remove user item from local storage
        localStorage.removeItem('user');

        next()
    } else if (
        to.name === 'Redeem Profile Access Link'
    ) {
        next()
    } else if (
        to.name !== 'Login' &&
        to.name !== 'Login2' &&
        to.name !== 'Forgot Password' &&
        to.name !== 'Reset Password' &&
        to.name !== 'Fullpage Invoice' &&
        to.name !== '404' &&
        to.name !== '5.0 Release Notes' &&
        to.name !== 'Fullpage Profile Test' &&
        to.name !== 'Redeem Profile Access Link' &&
        to.name !== 'Get Mobile Token' &&
        !user
    ) {
        //remove user item from local storage
        localStorage.removeItem('user');

        //javascript for params, stackoverflow: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript 
        //get orgId from query string
        const fromParams = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let query = {};
        if (fromParams && fromParams.orgId) {
            query.orgId = fromParams.orgId;
        }

        query.redirect= to.fullPath

        next({ name: 'Login', query: query })
    } else if (user && user.accountTypeId === 4 && (to.name !== 'Personal Information')) {
        next({ path: '/account/personal-information' })
    } else if (user) {
        
        //refresh user
        user = getUser();

        //if new route doesn't have orgId, try to get from old route and switch if needed
        //this ensures that the correct orgId matches the cookie orgId

        let frontendOrgId = to.query.orgId ? to.query.orgId : from.query.orgId;
        if (!frontendOrgId) {
            frontendOrgId = user.selectedOrganization.id;
        }

        //frontend matches current user cookie, good to go
        //THIS IS WHERE 99% OF THE AUTHENTICATED ROUTES WILL GO
        if (frontendOrgId == user.selectedOrganization.id) {
            
            //if query doesn't have orgId, add it
            if (!to.query.orgId){
                let query = {
                    ...to.query,
                }

                query.orgId = frontendOrgId;

                next({path: to.path, query})
            }else{

                //try catch refresh auth token on page change to extend timeout
                try {
                    await refreshToken();
                } catch (e) {
                    console.log(e);
                }
                
                next()
            }

        } else if (frontendOrgId != user.selectedOrganization.id) {
            
            if (to.name !== 'SelectOrganization') {
                await switchSelectedOrganization(frontendOrgId);
            }

            user = getUser();

            let queryParams = to.query;
            queryParams.orgId = user.selectedOrganization.id;

            next({ path: to.path, query: {...queryParams} })
        }
        else {
            
            //try catch refresh auth token on page change to extend timeout
            try {
                await refreshToken();
            } catch (e) {
                console.log(e);
            }
            
            next()
        }
    }
        // else if (user && user.selectedOrganization && (!to.query.orgId || to.query.orgId !== user.selectedOrganization.id)) {
        //
        //   if (to.query.orgId != user.selectedOrganization.id) {
        //     // Switch Organizations
        //     // 
        //     await switchSelectedOrganization(to.query.orgId);
        //     user = getUser();
        //     next({ name: to.name, query: {orgId: user.selectedOrganization.id} })
        //   }
        //
        //   const {id} = user.selectedOrganization;
        //     next({ name: to.name, query: {orgId: id} })
        //
        //   // next()
        //
    // }

    else next()
})

export default router
