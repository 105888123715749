<template>
    <div>
        
        <div v-if="charts">
            <div v-for="(chart, index) in charts" :key="index">
                <highcharts :options="chart"></highcharts>
            </div>
            
        </div>
        <div v-else>
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; padding: 50px">
                <SwayLoader />
                <div style="margin-top: 10px">
                    Loading Data...
                </div>
            </div>
           
            
        </div>
    </div>
</template>

<script>
import {getTestRawData} from "@/services/profile.service";
import moment from "moment";
import {allMeasuresFromConstants} from "@/js/shared/constants";
import {getMotionOrGyroChart} from "@/js/shared/helper";


export default {
    name: "TestAccelerometerData",
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            charts: null
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            const {id} = this;
            let response = await getTestRawData(id);
            // 
            const { testResultsRawData} = response.testRawData;
            let charts = []
            testResultsRawData.forEach(result => {
                
                const measureName = allMeasuresFromConstants[result.testId].label
                let testResultsData = JSON.parse(result.rawData)
                let {gyroData, gyro_data, motionData, motion_data, quaternionData, quaternion_data, testStartedTime, beep_time_stamps} = testResultsData;
                
                let gyroRxSeries = {
                    name: 'Gyro RX Data',
                    data: []
                };
                let gyroRySeries = {
                    name: 'Gyro RY Data',
                    data: []
                };
                let gyroRzSeries = {
                    name: 'Gyro RZ Data',
                    data: []
                };

                let motionXSeries = {
                    name: 'Motion X Data',
                    data: []
                };

                let motionYSeries = {
                    name: 'Motion Y Data',
                    data: []
                };

                let motionZSeries = {
                    name: 'Motion Z Data',
                    data: []
                };

                let quaternionWSeries = {
                    name: 'Quaternion W Data',
                    data: []
                };

                let quaternionXSeries = {
                    name: 'Quaternion X Data',
                    data: []
                };

                let quaternionYSeries = {
                    name: 'Quaternion Y Data',
                    data: []
                };

                let quaternionZSeries = {
                    name: 'Quaternion Z Data',
                    data: []
                };
                
                gyroData = gyro_data ? gyro_data : gyroData;
                motionData = motion_data ? motion_data : motionData
                quaternionData = quaternion_data ? quaternion_data : quaternionData
                
                if (gyroData) {
                    gyroData.forEach(point => {
                        let x = moment(point.ts).valueOf()
                        gyroRxSeries.data.push([x, point.rx])
                        gyroRySeries.data.push([x, point.ry])
                        gyroRzSeries.data.push([x, point.rz])

                    });
                }
                
                if (motionData) {
                    motionData.forEach(point => {
                        let x = moment(point.ts).valueOf()
                        motionXSeries.data.push([x, point.x])
                        motionYSeries.data.push([x, point.y])
                        motionZSeries.data.push([x, point.z])

                    });
                }

                if (quaternionData) {
                    quaternionData.forEach(point => {
                        let x = moment(point.ts).valueOf()
                        quaternionWSeries.data.push([x, point.qw])
                        quaternionXSeries.data.push([x, point.qx])
                        quaternionYSeries.data.push([x, point.qy])
                        quaternionZSeries.data.push([x, point.qz])
                    });
                }
                
                let xAxisPlotLines = []
                
                if (beep_time_stamps) {
                    beep_time_stamps.forEach(timeStamp => {
                        xAxisPlotLines.push({
                            value: moment(timeStamp).valueOf(),
                            width: 2,
                            color: '#34d3fd',
                            label: {
                                text: 'Metronome',
                                style: {
                                    color: '#757575'
                                }
                            }
                        })
                    })
                }
                
                let officialTestStartTime = moment(testStartedTime ? testStartedTime : motionData[0].ts).valueOf()
                
                if (testStartedTime) {
                    xAxisPlotLines.push({
                        value: moment(testStartedTime).valueOf(),
                        width: 2,
                        color: '#7200ca',
                        label: {
                            text: 'Start of Test',
                            style: {
                                color: '#757575'
                            }
                        }
                    })
                }

                let gyroSeries = [
                    gyroRxSeries,
                    gyroRySeries,
                    gyroRzSeries,
                ]
                
                let gyroTitle = `${measureName} Gyro Data`
                
                let motionSeries = [
                    motionXSeries,
                    motionYSeries,
                    motionZSeries,
                ]
                
                
                let motionTitle = `${measureName} Motion Data`

                let quaternionSeries = [
                    quaternionWSeries,
                    quaternionXSeries,
                    quaternionYSeries,
                    quaternionZSeries
                ]


                let quaternionTitle = `${measureName} Quaternion Data`
                
                let gyroChartOptions = getMotionOrGyroChart({series: gyroSeries, xAxisPlotLines, testStartTime: officialTestStartTime, title: gyroTitle})
                let motionChartOptions = getMotionOrGyroChart({series: motionSeries, xAxisPlotLines, testStartTime: officialTestStartTime, title: motionTitle})
                let quaternionChartOptions = getMotionOrGyroChart({series: quaternionSeries, xAxisPlotLines, testStartTime: officialTestStartTime, title: quaternionTitle})
                
                // const gyroChartOptions = {
                //     chart: {
                //         zoomType: 'x',
                //         style: {
                //             fontFamily: 'inherit'
                //         }
                //     },
                //
                //     title: {
                //         text: 
                //     },
                //
                //     subtitle: {
                //         text: ' '
                //     },
                //
                //     tooltip: {
                //         valueDecimals: 2,
                //         shared: true,
                //         useHTML: true,
                //         backgroundColor: '#fff',
                //         formatter() {
                //             const self = this;
                //             let elapsedTime = moment(self.x - officialTestStartTime)
                //             let timeInTest = `${elapsedTime.format('s')}.${parseInt(elapsedTime.format('SSS'))} seconds`;
                //             // 
                //             let points = self.points.map(point => (
                //                 `<div style="display: flex; align-items: center"><div style="background-color: ${point.color}; height: 10px; width: 10px; border-radius: 10px; margin-right: 3px"></div> <div style="; margin-right: 3px">${point.series.name}:</div><span style="font-weight: bold">${point.y}</span></div>`
                //             ))
                //             let timeTitle = `<div>Time Elapsed</div>`
                //             let timeElapsed = `<div style="font-size: 18px; margin-bottom: 10px; font-weight: bold">${timeInTest}</div>`
                //             let response = `<div >`;
                //             response += timeTitle
                //             response += timeElapsed
                //             points.forEach(point => response += point) 
                //             response += `</div>`
                //             return response
                //         }
                //     },
                //
                //     xAxis: {
                //         type: 'datetime',
                //         plotLines: xAxisPlotLines,
                //         labels: {
                //             formatter() {
                //                 let self = this;
                //                 return moment(self.value - officialTestStartTime).format('s')
                //             }
                //         },
                //         // tickmarkPlacement: 'on',
                //         // startOnTick: true,
                //     },
                //
                //     series: 
                // }

                // const motionChartOptions = {
                //     chart: {
                //         zoomType: 'x',
                //         style: {
                //             fontFamily: 'inherit'
                //         }
                //     },
                //
                //     title: {
                //         text: `${measureName} Motion Data`
                //     },
                //
                //     subtitle: {
                //         text: ' '
                //     },
                //
                //     tooltip: {
                //         valueDecimals: 2,
                //         shared: true,
                //         useHTML: true,
                //         backgroundColor: '#fff',
                //         formatter() {
                //             const self = this;
                //             let elapsedTime = moment(self.x - officialTestStartTime)
                //             let timeInTest = `${elapsedTime.format('s')}.${parseInt(elapsedTime.format('SSS'))} seconds`;
                //             // 
                //             let points = self.points.map(point => (
                //                 `<div style="display: flex; align-items: center"><div style="background-color: ${point.color}; height: 10px; width: 10px; border-radius: 10px; margin-right: 3px"></div> <div style="; margin-right: 3px">${point.series.name}:</div><span style="font-weight: bold">${point.y}</span></div>`
                //             ))
                //             let timeTitle = `<div>Time Elapsed</div>`
                //             let timeElapsed = `<div style="font-size: 18px; margin-bottom: 10px; font-weight: bold">${timeInTest}</div>`
                //             let response = `<div >`;
                //             response += timeTitle
                //             response += timeElapsed
                //             points.forEach(point => response += point)
                //             response += `</div>`
                //             return response
                //         }
                //     },
                //
                //     xAxis: {
                //         type: 'datetime',
                //         plotLines: xAxisPlotLines,
                //         labels: {
                //             formatter() {
                //                 let self = this;
                //                 return moment(self.value - officialTestStartTime).format('s')
                //             }
                //         }
                //     },
                //
                //     series: [
                //         motionXSeries,
                //         motionYSeries,
                //         motionZSeries,
                //     ]
                // }
                
                charts.push(gyroChartOptions, motionChartOptions, quaternionChartOptions)
                this.charts = charts
                
                
            })
            
        }
    }
}
</script>

<style scoped>

</style>