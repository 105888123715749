<template>
    <v-menu
        :close-on-content-click="false"
        offset-y
        :nudge-bottom="5"
    >
        <template v-slot:activator="{ on: menu, attrs }">

            <div

                v-bind="attrs"
                v-on="{ ...menu }"
            >
                {{ value }}
            </div>


        </template>
        <v-date-picker 
            :value="value"
            @input="onChange"
            :allowed-dates="allowedDates"
        ></v-date-picker>

    </v-menu>
</template>

<script>

export default {
    name: "AppDatePicker",
    props: {
        value: {
            required: true
        },
        allowedDates: {
            type: Function
        }
    },
    methods: {
        onChange(e) {
            this.$emit('input', e)
        }
    }
}
</script>

<style scoped>

</style>