<template>
	<div v-if="$store.state.currentAccount.isSuperAdmin">
<!--		<AppPageLoader v-if="loadingMessage" :text="loadingMessage"/>-->
		<!--        <highcharts :options="chartOptions" />-->

		
		<v-container fluid>
			<v-row>
				<v-col sm="12" lg="9" xl="6">
					<AppCard title="Parent Access Organizations" class="mb-4">
						<template #header-right>
							<AppBtn icon="mdi-plus" @click="addParentOrgDialogOpen = true">Add Parent Organization</AppBtn>
						</template>
						<app-data-table :items="accessParentOrgs" :headers="accessParentTableHeaders" :loading="loadingOrgConnects" >
							<template #item.parentOrganizationName="{item}">
								<div>
									{{item.parentOrganizationName}}
									<AppChip :color="item.connectionStatus.chipColor" small label class="ml-2">{{item.connectionStatus.text}}</AppChip>
								</div>
							</template>
							<template #item.connectionType="{item}">
								<div>
									{{item.connectionType.emoji}} {{item.connectionType.text}}
								
								</div>
							</template>
							<template v-slot:item.actions="{ item }">
								
								<v-icon
									small
									@click="showRemoveConnectPopup('Parent', item)"
								>
									mdi-delete
								</v-icon>
							</template>
						</app-data-table>
					
					</AppCard>
				</v-col>
				<v-col sm="12" lg="9" xl="6">
					<AppCard title="Child Access Organizations" class="mb-4">
						<template #header-right>
							<AppBtn icon="mdi-plus" @click="addChildOrgDialogOpen = true">Add Child Organization</AppBtn>
						</template>
						<app-data-table :items="accessChildOrgs" :headers="accessChildTableHeaders" :loading="loadingOrgConnects" >
							<template #item.childOrganizationName="{item}">
								<div>
									{{item.childOrganizationName}}
									<AppChip :color="item.connectionStatus.chipColor" small label class="ml-2">{{item.connectionStatus.text}}</AppChip>
								</div>
							</template>
							<template #item.connectionType="{item}">
								<div>
									{{item.connectionType.emoji}} {{item.connectionType.text}}
									
								</div>
							</template>
							<template v-slot:item.actions="{ item }">
								
								<v-icon
									small
									@click="showRemoveConnectPopup('Parent', item)"
								>
									mdi-delete
								</v-icon>
							</template>
						</app-data-table>
					</AppCard>
				</v-col>
			</v-row>
		</v-container>

			
		
		<DialogForm 
			title="Add Access Parent Org" 
			:open="addParentOrgDialogOpen"
			@close="addParentOrgDialogOpen = false"
			@submit="() => addOrgConnect('parent')"
			submit-button-title="Save"
			:saving="savingOrgConnect"
		>
			<template #form-content>
				<app-text-field 
					label="Org Id" 
					v-model="orgIdToAdd"
					@input="updateSearchString"
					rules="required|positive"
				></app-text-field>
				<div 
					v-if="orgToAdd" 
				     class="pa-2 mt-2 mb-2" 
					style="border-radius: 5px"
					:style="{backgroundColor: orgToAdd.id ? '#e8f5e9' : '#ffebee'}"
				>
					✅ <strong>{{orgToAdd.name}}</strong> ({{ orgToAdd.id}})
				</div>
				<div
					v-else-if="orgIdToAdd"
					class="pa-2 mt-2 mb-2"
					style="border-radius: 5px; background-color: #ffebee"
				>
					❌ Invalid Org ID
				</div>
				
				
				<app-select 
					:items="orgConnectTypeSelectList" 
					label="Type" 
					v-model="connectTypeToAdd"
					rules="required"
				></app-select>
				<app-select 
					:items="orgConnectStatusSelectList" 
					label="Status" v-model="statusTypeToAdd"
					rules="required"
				></app-select>
			</template>
		</DialogForm>
		
		
		
		
		<DialogForm
			title="Add Access Child Org"
			:open="addChildOrgDialogOpen"
			@close="addChildOrgDialogOpen = false"
			@submit="addOrgConnect"
			submit-button-title="Confirm Organization"
		>
			<template #form-content>
				<app-text-field
					label="Org Id"
					v-model="orgIdToAdd"
					@input="updateSearchString"
					rules="required|positive"
				></app-text-field>
				<div
					v-if="orgToAdd"
					class="pa-2 mt-2 mb-2"
					style="border-radius: 5px"
					:style="{backgroundColor: orgToAdd.id ? '#e8f5e9' : '#ffebee'}"
				>
					✅ <strong>{{orgToAdd.name}}</strong> ({{ orgToAdd.id}})
				</div>
				<div
					v-else-if="orgIdToAdd"
					class="pa-2 mt-2 mb-2"
					style="border-radius: 5px; background-color: #ffebee"
				>
					❌ Invalid Org ID
				</div>
				
				
				<app-select
					:items="orgConnectTypeSelectList"
					label="Type"
					v-model="connectTypeToAdd"
					rules="required"
				></app-select>
				<app-select
					:items="orgConnectStatusSelectList"
					label="Status" v-model="statusTypeToAdd"
					rules="required"
				></app-select>
			</template>
			
		</DialogForm>
		
<!--		<div class="pr-4 pl-4">-->
<!--			<h3>To view/edit Billing parent/child orgs, go to <a @click="goToBillingSettings">Billing Settings</a> page</h3>-->
<!--		</div>-->
		
		<app-confirm-dialog
			:title="'Add '+parentOrChild+' Org'"
			submitButtonTitle="Add"
			:open="!!orgNameToAdd"
			@close="orgNameToAdd = null"
			@confirm="addOrgConnect"
		>
			<span>Are you sure you want to connect {{orgNameToAdd}} as a {{parentOrChild}} Org?</span>
		</app-confirm-dialog>
		
		<app-confirm-dialog
			:title="'Remove '+parentOrChild+' Org'"
			submitButtonTitle="Remove"
			:open="!!orgConnectToRemove"
			@close="orgConnectToRemove = null"
			@confirm="removeOrgConnect"
		>
			<span v-if="orgConnectToRemove">Are you sure you want to remove {{orgConnectToRemoveName}} from {{parentOrChild}} Orgs?</span>
		</app-confirm-dialog>
	</div>
</template>

<script>
import {getOrgConnects, saveOrgConnect, getOrganizationById} from "@/services/organization.service";
import {getBillingChildOrgs} from "@/services/billing.service";
import {orgConnectStatusSelectList, orgConnectTypeSelectList} from "@/js/shared/constants";

export default {
	name: "AccessConnections",
	props: {
		accessChildOrgs: {
			required: true
		},
		accessParentOrgs: {
			required: true
		},
	},
	components: {
		
	},
	watch: {
		
	},
	data() {
		return {
			
			loadingOrgConnects: false,
			
			// currentParentOrgs: [],
			// currentChildOrgs: [],
			
			accessParentTableHeaders: [
				
				{
					text: 'ID',
					value: 'parentOrganizationId'
				},
				{
					text: 'Name',
					value: 'parentOrganizationName'
				},
				{
					text: 'Connection Type',
					value: 'connectionType',
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				},
			],
			accessChildTableHeaders: [
				
				{
					text: 'ID',
					value: 'childOrganizationId'
				},
				{
					text: 'Name',
					value: 'childOrganizationName'
				},
				{
					text: 'Connection Type',
					value: 'connectionType',
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				},
			],
			
			orgIdToAdd: null,
			orgToAdd: null,
			
			connectTypeToAdd: null,
			statusTypeToAdd: null,
			parentOrChild: null,
			orgNameToAdd: null,
			
			orgConnectToRemove: null,
			orgConnectToRemoveName: null,
			
			addParentOrgDialogOpen: false,
			addChildOrgDialogOpen: false,
			
			savingOrgConnect: false,
			
			orgConnectStatusSelectList,
			orgConnectTypeSelectList,
		}
	},
	mounted() {
	},
	computed: {
		
	},
	methods: {
		updateSearchString(event) {
			// const x = event;
			// debugger;
			clearTimeout(this.typingTimer);
			this.typingTimer =  setTimeout(async () => {
				let response = await getOrganizationById(parseInt(event));
				if (response.isSuccess) {
					this.orgToAdd = response.organization;
				}
			}, 300)
		},
		async getBillingChildOrgs(){
			const result = await getBillingChildOrgs();
			this.billingChildOrgs = result.organizations;
		},
		goToBillingSettings() {
			this.$router.push("/billing/billing-settings");
		},
		editParentOrganizations() {
			this.isEditingParentOrgs = true;
			this.isEditingChildOrgs = false;
			this.isShowingAddParentOrg = false;
			this.isShowingAddChildOrg = false;
			this.orgIdToAdd = null;
			this.connectTypeToAdd = null;
			this.statusTypeToAdd = null;
		},
		editChildOrganizations() {
			this.isEditingParentOrgs = false;
			this.isEditingChildOrgs = true;
			this.isShowingAddParentOrg = false;
			this.isShowingAddChildOrg = false;
			this.orgIdToAdd = null;
			this.connectTypeToAdd = null;
			this.statusTypeToAdd = null;
		},
		async confirmParentOrgConnect() {
			
		},
		async showAddConnectPopup(parentOrChild){
			this.loadingMessage = "Loading Org Info";
			
			this.parentOrChild = parentOrChild;
			var orgToAdd = await getOrganizationById(this.orgIdToAdd);
			if (!orgToAdd || !orgToAdd.organization) {
				alert("Organization not found");
			}
			else {
				this.orgNameToAdd = orgToAdd.organization.name;
			}
			
			this.loadingMessage = null;
		},
		async showRemoveConnectPopup(parentOrChild, orgConnect) {
			this.parentOrChild = parentOrChild;
			
			this.orgConnectToRemove = orgConnect;
			this.orgConnectToRemoveName = this.parentOrChild === "Parent" ? this.orgConnectToRemove.parentOrganizationName : this.orgConnectToRemove.childOrganizationName;
		},
		async addOrgConnect(type) {
			this.savingOrgConnect = true
			
			var request = {
				orgConnectTypeId: this.connectTypeToAdd,
				orgConnectStatusTypeId: this.statusTypeToAdd,
				isEnabled: true
			}
			if (type === "parent"){
				request.parentOrganizationId = this.orgIdToAdd;
				request.childOrganizationId = this.$route.query.orgId;
			}
			else {
				request.parentOrganizationId = this.$route.query.orgId;
				request.childOrganizationId = this.orgIdToAdd;
			}
			await saveOrgConnect(request);
			
			this.orgIdToAdd = null;
			this.connectTypeToAdd = null;
			this.statusTypeToAdd = null;
			this.parentOrChild = null;
			this.orgNameToAdd = null;
			this.isShowingAddParentOrg = false;
			this.isShowingAddChildOrg = false;
			
			this.addParentOrgDialogOpen = false;
			this.addChildOrgDialogOpen = false
			this.savingOrgConnect = false;
			await this.getOrgConnects();
		},
		async removeOrgConnect() {
			this.loadingMessage = "Removing Org Connect";
			
			this.orgConnectToRemove.orgConnectStatusTypeId = 5;
			this.orgConnectToRemove.isEnabled = false;
			await saveOrgConnect(this.orgConnectToRemove);
			
			this.orgConnectToRemove = null;
			
			this.loadingMessage = null;
			await this.getOrgConnects();
		},
	},
	
}
</script>

<style scoped>


thead tr th {
	padding: 0 16px;
	font-size: 16px;
	height: 48px;
}

</style>