<template>
  <sway-loader />
</template>

<script>
import SwayLoader from "@/components/SwayLoader";
export default {
  name: "AppLoader",
    components: {SwayLoader}
}
</script>

<style scoped>

</style>