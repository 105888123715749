<template>
	<div style="padding: 1rem; width: 100%">
		<table>
			<tbody>
			<tr v-for="(item, index) in items" :key="index">
				<td style="padding: 0.5rem">{{item.text}}</td>
				<td style="padding: 0.5rem">{{subscription[item.value]}}</td>
			</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "SubscriptionReportsDetails",
	props: {
		subscription: {
			required: true
		}
	},
	data() {
		return {
			items: [
				{
					"text": "ErrorMessage",
					"value": "errorMessage"
				},
				{
					"text": "ClinicalProfilePrice",
					"value": "clinicalProfilePrice"
				},
				{
					"text": "ClinicalSystemFee",
					"value": "clinicalSystemFee"
				},
				{
					"text": "ClinicalBillingCycle",
					"value": "clinicalBillingCycle"
				},
				{
					"text": "ClinicalSubscriptionStartDate",
					"value": "clinicalSubscriptionStartDate"
				},
				{
					"text": "ClinicalSubscriptionEndDate",
					"value": "clinicalSubscriptionEndDate"
				},
				{
					"text": "ClinicalPackageTier",
					"value": "clinicalPackageTier"
				},
				{
					"text": "SportsProfilePrice",
					"value": "sportsProfilePrice"
				},
				{
					"text": "SportsSystemFee",
					"value": "sportsSystemFee"
				},
				{
					"text": "SportsProfileCount",
					"value": "sportsProfileCount"
				},
				{
					"text": "SportsSubscriptionStartDate",
					"value": "sportsSubscriptionStartDate"
				},
				{
					"text": "SportsSubscriptionEndDate",
					"value": "sportsSubscriptionEndDate"
				},
				{
					"text": "SportsPackageTier",
					"value": "sportsPackageTier"
				},
			]
		}
	}
}
</script>

<style scoped>

</style>