<template>
    <div v-if="!profileIsInactive">
        <div style="display: flex; justify-content: flex-end" class="mb-2">
            <app-btn @click="$emit('createSession')">Create Code</app-btn>
        </div>
        <SessionsTable
            :sessions="sessions"
            :loading="loadingSessions"
            @onRowClick="onRowClick($event)"
        >
            <template #no-data>
                <div>
                    There are no codes for this profile
                </div>
            </template>
        </SessionsTable>
<!--        <SessionCreateForm-->
<!--            :open="createSessionDialogOpen"-->
<!--            @close="createSessionDialogOpen = false"-->
<!--            :profile="simpleProfile"-->
<!--            :profiles="[simpleProfile]"-->
<!--        />-->
    </div>
    <div v-else>
        <EmptyState title="Codes Unavailable" description="You cannot create a code for an inactive profile. To reactivate this profile, please add this profile to an active group." />
    </div>
</template>

<script>
import {getSessions} from "@/services/session.service";
import {getSessionDetails} from "@/js/shared/helper";
import SessionsTable from "@/components/Sessions/SessionsTable";
import SessionCreateForm from "@/components/Sessions/SessionCreateForm";
import EmptyState from "@/components/EmptyState";

export default {
    name: "ProfileSessions",
    components: {EmptyState, SessionCreateForm, SessionsTable},
    props: {
        simpleProfile: {
            required: true
        },
        profileIsInactive: {
            type: Boolean,
            
        }
    },
    data() {
        return {
            loadingSessions: false,
            sessions: [],
            createSessionDialogOpen: false
        }
    },
    mounted() {
        this.setSessions()
    },
    methods: {
        async setSessions() {
            this.loadingSessions = true;
            const data = await getSessions();
            if (!data.isSuccess) {
                this.loadingSessions = false;
            }
            let {sessions} = data;

            let filteredSessions = [];
            sessions.forEach(session => {
                if (session.profileId === this.simpleProfile.id) filteredSessions.push(session)
            });



            this.sessions = filteredSessions
            this.loadingSessions = false;
        },
        onRowClick(value) {
            this.$router.push(`/codes/${value.id}`)
        }
    }
}
</script>

<style scoped>

</style>