<template>
    <div >
        
        <div v-for="(category, index) in measureResultsByCategory"
             :key="index" 
             :class="index === 0 ? 'pdf-avoid-page-break' : null"
             v-if="category.categoryId !== 9"
        >

            <div class="pdf-section-title" v-if="index === 0" >Test Details</div>
            
            


            

                <div
                    v-for="(measure, measureId, measureIndex) in category.measureResults"
                    :key="measureId"
                    :style="{marginTop: measureIndex > 0 ? '10px' : null}"
                    :class="index !== 0 ? 'pdf-avoid-page-break' : null"
                    v-if="!measuresCategoriesById[category.categoryId].hideDetails "
                >
                    <SectionWrapper
                        :title="category.fullLabel ? category.fullLabel : category.label"
                        v-if="measureIndex === 0"
                        title-only
                        no-padding
                        header-border
                        :style="{marginTop: index > 0 ? '10px' : null}"
                        class="pdf-avoid-page-break"
                    >
                        <TestDetail :profileTest="profileTest" :measureId="parseInt(measureId)" :measure="measure" :categoryId="category.categoryId" />
                    </SectionWrapper>
                    <div v-else class="pdf-avoid-page-break">
                        <TestDetail :profileTest="profileTest" :measureId="parseInt(measureId)" :measure="measure" :categoryId="category.categoryId" />
                    </div>
                </div>
            
        </div>
        
    </div>
    <!--    </div>-->
</template>

<script>

import SectionWrapper from "@/components/pdf-export/SectionWrapper";
import {measuresCategoriesById} from "@/js/shared/constants";
import CategoryTestsSummary from "@/components/pdf-export/CategoryTestsSummary";
import TestDetail from "@/components/pdf-export/components/TestDetail";

export default {
    name: "TestDetailsSection",
    components: {TestDetail, CategoryTestsSummary, SectionWrapper},
    props: {
        profile: {
            // required: true
        },
        profileTest: {
            required: true
        },
        measureResultsByCategory: {
            required: true
        }
    },
    data() {
        return {
            measuresCategoriesById
        }
    },
    mounted() {

    },
    computed: {

    },
    methods: {

    }
}
</script>

<style scoped>

</style>