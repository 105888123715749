<template>
    <app-card title="Change your password"  style="width: 400px">

<!--    <div style="font-size: 1.2em; font-weight: bold">Change your password</div>-->
        <div style="max-width: 600px" class="mt-2 pa-4 text--text" >
<!--            <app-form submit-button-title="Change Password" v-slot="{invalid}" :use-default-btn="false">-->
                <!--                        <div>is valid: {{ !invalid }}</div>-->
                <app-text-field 
                    label="Old Password" 
                    v-model="changePasswordForm.oldPassword" 
                    type="password"
                    name="old-password"
                    :error="submitClicked && changePasswordForm.oldPassword.length === 0"
                ></app-text-field>
                <app-text-field 
                    label="New Password" 
                    v-model="changePasswordForm.newPassword" 
                    type="password"
                    name="new-password"
                    @focus="showPasswordCriteria = true"
                    :success="newPasswordIsValid"
                    :error="!newPasswordIsValid && submitClicked"
                />
                <div class="pa-2" v-if="showPasswordCriteria">
                    <div :class="[getMessageClass(newPasswordsLength)]">
                        <span v-if="newPasswordsLength">&#10003;</span><span v-else>&#10007;</span> contains 8+ characters
                    </div>
                    <div :class="[getMessageClass(newPasswordHasUppercase)]">
                        <span v-if="newPasswordHasUppercase">&#10003;</span><span v-else>&#10007;</span> contains 1 uppercase letter
                    </div>
                    <div :class="[getMessageClass(newPasswordHasLowercase)]">
                        <span v-if="newPasswordHasLowercase">&#10003;</span><span v-else>&#10007;</span> contains 1 lowercase letter
                    </div>
                    <div :class="[getMessageClass(newPasswordHasDigit)]">
                        <span v-if="newPasswordHasDigit">&#10003;</span><span v-else>&#10007;</span> contains 1 digit
                    </div>
                    <div :class="[getMessageClass(newPasswordHasSpecialCharacter)]">
                        <span v-if="newPasswordHasSpecialCharacter">&#10003;</span><span v-else>&#10007;</span> contains 1 special character
                    </div>
                </div>

                <app-text-field 
                    label="Confirm New Password" 
                    v-model="changePasswordForm.confirmNewPassword" 
                    type="password"
                    name="confirm-password"
                    @focus="showConfirmPasswordIsSameMessage = true"
                    :success="confirmPasswordMatches"
                    :error="!confirmPasswordMatches && submitClicked"
                />
                <div class="pa-2" v-if="showConfirmPasswordIsSameMessage">
                    <div :class="[confirmPasswordMatches ? 'password-item-good' : 'password-item-bad']">
                        <span v-if="confirmPasswordMatches">&#10003;</span><span v-else>&#10007;</span> passwords match
                    </div>
                </div>
                <app-btn 
                    class="mt-4" 
                    @click="changePassword" 
                    :loading="savingNewPassword"
                >
                    Submit Change
                </app-btn>
<!--            </app-form>-->

        </div>


    </app-card>
</template>

<script>
import {updateAccountPasswordModel, updatePassword} from "@/services/userService"
import {SET_SNACKBAR} from "@/store/mutation-types";


export default {
    name: "ChangePassword",
    props: {
        account: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showPasswordCriteria: false,
            showConfirmPasswordIsSameMessage: false,
            submitClicked: false,
            changePasswordForm: {
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            },
            
            savingNewPassword: false
        }
    },
    computed: {
        newPasswordsLength() {
            const {newPassword} = this.changePasswordForm
            return newPassword.length > 7
        },
        newPasswordHasUppercase() {
            const {newPassword} = this.changePasswordForm
            return newPassword.match(/(?=.*[A-Z])/)
        },
        newPasswordHasLowercase() {
            const {newPassword} = this.changePasswordForm
            return newPassword.match(/(?=.*[a-z])/)
        },
        newPasswordHasDigit() {
            const {newPassword} = this.changePasswordForm;
            let temp = newPassword.match(/(?=.*\d)/);
            return newPassword.match(/(?=.*\d)/)

        },
        newPasswordHasSpecialCharacter() {
            const {newPassword} = this.changePasswordForm;
            let temp = newPassword.match(/(?=.*\W)/);
            return newPassword.match(/(?=.*\W)/)

        },
        confirmPasswordMatches() {
            const {newPassword, confirmNewPassword} = this.changePasswordForm;
            return confirmNewPassword.length > 0 && newPassword === confirmNewPassword

        },
        newPasswordIsValid() {
          return this.newPasswordsLength && this.newPasswordHasUppercase && this.newPasswordHasLowercase && this.newPasswordHasDigit && this.newPasswordHasSpecialCharacter
        },
        formIsValid() {
            return this.newPasswordIsValid && this.confirmPasswordMatches && this.changePasswordForm.oldPassword.length > 0
        },
        changePasswordButtonEnabled() {
            const {
                oldPassword,
                newPassword,
                confirmNewPassword
            } = this.changePasswordForm;

            return !!oldPassword && !!newPassword

        },
    },
    methods: {
        clearData() {
            this.changePasswordForm = {
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            }
            this.showPasswordCriteria = false;
            this.showConfirmPasswordIsSameMessage = false;
            this.submitClicked = false;
        },
        getMessageClass(valid) {
            if (valid) {
                return 'password-item-good'
            }
            else if (!valid && !this.submitClicked) {
                return 'password-item-bad'
            } else {
                return 'password-item-bad-error'
            }
        },
        async changePassword() {
            
            
            const {
                oldPassword,
                newPassword,
                confirmNewPassword
            } = this.changePasswordForm;
            
            this.submitClicked = true;
            
            if (!this.formIsValid) return
            this.savingNewPassword = true;
            const request = {...updateAccountPasswordModel}
            
            const { 
                id,
                email,
                firstName,
                lastName,
                phoneNumber,
                jobTitle,
                professionalTypeId,
                isDisabled,
                isSafetyCoach,
                accountTypeId,
                organizationAccessTypeId,
            } = this.account;
            
            request.id = id;
            request.email = email;
            request.firstName = firstName;
            request.lastName = lastName;
            request.phoneNumber = phoneNumber;
            request.jobTitle = jobTitle;
            request.professionalTypeId = professionalTypeId;
            request.isDisabled = isDisabled;
            request.isSafetyCoach = isSafetyCoach;
            request.accountTypeId = accountTypeId;
            request.organizationAccessTypeId = organizationAccessTypeId;
            request.groupsIds = [0];
            request.updateGroups = false;
            
            request.oldPassword = oldPassword;
            request.newPassword = newPassword;
            
            const response = await updatePassword(request)

            
            if (!response.isSuccess) {
                
            } else {
                this.clearData()

                this.$store.commit(SET_SNACKBAR, {
                    text: 'Password Successfully Updated',
                    color: 'success',
                    open: true
                });
            }
            
            

            this.savingNewPassword = false;
            
            
            
            

        },
        isChangePasswordFormValid(formIsInvalid) {
            const {
                oldPassword,
                newPassword,
                confirmNewPassword
            } = this.changePasswordForm;

            // passwords are blank 
            const passwordsAreBlank = oldPassword.length === 0 || newPassword.length === 0 || confirmNewPassword.length === 0

            const newPasswordsMatch = (newPassword === confirmNewPassword)

            return formIsInvalid || !newPasswordsMatch || passwordsAreBlank
        },
    }
}
</script>

<style scoped>
.password-item-good {
    color: #4caf50;
    font-weight: bold
}

.password-item-bad {
    color: var(--v-text-lighten3)
}

.password-item-bad-error {
    color: var(--v-error-base)
}
</style>