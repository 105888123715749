/* Utilities */
const funcs ={
    isIos() {
        return window.webkit && window.webkit.messageHandlers && typeof _nativeBridge === 'undefined';//eslint-disable-line 
    },

    /* WebView Communication */

    sendNativeCommand(command, payload) {//eslint-disable-line 
        var message = { command: command, payload: payload };
        //debugger;//eslint-disable-line 
        if(this.isIos()) {
            window.webkit.messageHandlers.bridge.postMessage(message);
        }
        else {
            try{
                _nativeBridge.send(JSON.stringify(message));//eslint-disable-line 
            }catch{
                console.log('sendNativeCommand in core.js not available');
            }
        }
    },

    sendNativeCommandMaui(command, payload) {//eslint-disable-line 
       //debugger;//eslint-disable-line 

        let methodName = command;
        let paramValues = payload;

        if (typeof paramValues !== 'undefined') {
            if (!Array.isArray(paramValues)) {
                paramValues = [paramValues];
            }
            for (var i = 0; i < paramValues.length; i++) {
                paramValues[i] = JSON.stringify(paramValues[i]);
            }
        }

        let messageType = 1;
        let messageContent = JSON.stringify({ "MethodName": methodName, "ParamValues": paramValues });
        var message = JSON.stringify({ "MessageType": messageType, "MessageContent": messageContent });

        if (window.chrome && window.chrome.webview) {
            // Windows WebView2
            window.chrome.webview.postMessage(message);
        }
        else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.webwindowinterop) {
            // iOS and MacCatalyst WKWebView
            window.webkit.messageHandlers.webwindowinterop.postMessage(message);
        }
        else {
            try{
                // Android WebView
                hybridWebViewHost.sendMessage(message);
            }catch{
                console.log('sendNativeCommandMaui in core.js not available');
            }
        }
    },

    isChrome(){
        let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        return isChrome;
    }
}




export default funcs