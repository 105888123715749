<template>
    <div class="">
        <div class="sway-form">
            <form>
                <h3>Profiles Tested Report</h3>
                <br />
                <div class="inline-block-field">
                    <app-form-date-picker v-model="startDate" label="Testing Start Date"></app-form-date-picker>
                </div>
                <br />
                <div class="inline-block-field">
                    <app-form-date-picker v-model="endDate" label="Testing End Date"></app-form-date-picker>
                </div>
                <br />
                <div class="inline-block-field">
                    Test Type Filter:<br/>
                    <input type="radio" v-model="testFilterType" value="0" style="margin: 5px">All Tests<br/>
                    <input type="radio" v-model="testFilterType" value="1" style="margin: 5px">Covid Tests Only<br/>
                    <input type="radio" v-model="testFilterType" value="2" style="margin: 5px">Non-Covid Tests Only<br/>
                </div>
                <br />
                <app-btn style="" @click="getReport">
                    Get Profiles Tested Report For {{$route.query.orgId}}
                </app-btn>
                <app-fab-btn type="download" @click="downloadReport" />
            </form>
        </div>

        <v-data-table :items="reportRows" :headers="headers">

        </v-data-table>
    </div>
</template>

<script>
import TableFooter from "@/components/tables/TableFooter";
import TableOptionsRow from "@/components/tables/TableOptionsRow";
import TableHeader from "@/components/tables/TableHeader";
import TableBody from "@/components/tables/TableBody";
import moment from 'moment'
import {getProfilesTestedReportExport, getProfilesTestedReport} from "@/services/report.service";

export default {
    name: "ProfilesTestedReport",
    components: {
        TableFooter,
        TableOptionsRow,
        TableHeader,
        TableBody
    },
    data () {
        return {
            reportRows: [],
            headers: [],
            loading: true,
            
            startDate: moment().add(-1, "months").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            testFilterType: 0
        }
    },

    mounted () {
        //this.getDataFromApi()
    },
    computed: {
        covidOnly() {
            return this.testFilterType == 1;
        },
        nonCovidOnly() {
            return this.testFilterType == 2;
        }
    },
    methods: {
        async getReport() {
            this.loading = true;

            const request = {
                startDate: this.startDate,
                endDate: this.endDate,
                covidOnly: this.covidOnly,
                nonCovidOnly: this.nonCovidOnly
            };

            const response = await getProfilesTestedReport(request);
            
            this.reportRows = response.report;
            this.headers = response.headers;

            this.loading = false;
        },
        async downloadReport() {
            this.loading = true;

            const request = {
                startDate: this.startDate,
                endDate: this.endDate,
                covidOnly: this.covidOnly,
                nonCovidOnly: this.nonCovidOnly
            };
            
            const response = await getProfilesTestedReportExport(request);
            
            this.loading = false;
        }
    },
}
</script>

<style scoped>

</style>