import httpService from "@/services/httpService";
import {baseUrl} from "@/config"
import {handleUnexpectedApiError, handleExpectedApiError} from "@/services/serviceHelper";


const endpoint = baseUrl + "/Session"

const sessionModel = {
    groupId: 0,
    code: "",
    startsOn: "2021-01-12T18:53:09.829Z",
    endsOn: "2021-01-12T18:53:09.830Z",
    organizationId: 0,
    organizationProtocolId: 0,
    profileId: 0,
    baselineSessionTypeId: 0,
    isNonBaseline: null
}

const getSessions = async () => {
    try {
        return await httpService.post(endpoint + '/GetSessions')
        // else handleExpectedApiError(response.data)
        
    } catch (e) {
        // return handleUnexpectedApiError(e.response)
    }
}

const getSession = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetSession', {id})
    } catch (e) {
        return handleUnexpectedApiError(e.response)
    }
}

const getAccountNotificationPrefs = async (request) => {
    try {
        return await httpService.post(endpoint + '/GetAccountNotificationPrefs', request)
    } catch (e) {
        return handleUnexpectedApiError(e.response)
    }
}

const createSession = async (request) => {
    try {
        return await httpService.post(endpoint + '/CreateSession', request);
    } catch (e) {
        return handleUnexpectedApiError(e.response)
    }
}

const updateSessionToExpedited = async (id) => {
    try {
        return await httpService.post(endpoint + '/UpdateSessionToExpedited', {id});
    } catch (e) {
        return handleUnexpectedApiError(e.response)
    }
}

const updateSession = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateSession', request);
    } catch (e) {
        return handleUnexpectedApiError(e.response)
    }
}

const deleteSession = async (id) => {
    try {
        return await httpService.post(endpoint + '/DeleteSession', {id});
    } catch (e) {
        return handleUnexpectedApiError(e.response)
    }
}

const getTestedProfilesBySessionId = async (id) => {
    try {
        return await httpService.post(endpoint + '/getTestedProfilesBySessionId', {id});
    } catch (e) {
        return handleUnexpectedApiError(e.response)
    }
}



export {
    getSessions,
    getSession,
    getAccountNotificationPrefs,
    sessionModel,
    createSession,
    updateSession,
    deleteSession,
    getTestedProfilesBySessionId,
    updateSessionToExpedited
}
