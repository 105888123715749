<template>
    <div>
        <v-breadcrumbs
            :items="items"
            divider="/"

        >
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                    color="secondary"
                    @click="$router.push(item.route)"
                    link
                    :style="{color: !item.disabled ? 'var(--v-primary-darken1)' : null}"
                    :class="{'active-link': !item.disabled}"

                >
                    {{ item.text }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
    </div>
</template>

<script>
export default {
    name: "AppBreadcrumbs",
    props: {
        path: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            // items: [
            //     {
            //         text: 'Dashboard',
            //         disabled: false,
            //         href: 'breadcrumbs_dashboard',
            //     },
            //     {
            //         text: 'Link 1',
            //         disabled: false,
            //         href: 'breadcrumbs_link_1',
            //     },
            //     {
            //         text: 'Link 2',
            //         disabled: true,
            //         href: 'breadcrumbs_link_2',
            //     },
            // ],
        }
    },
    computed: {
        items() {
            let temp = [];
            let items = this.path.split('/');

            let first;
            let second;
            let third;
            let fourth;

            switch (items[1]) {
                case 'profiles':
                    first = {
                        text: 'Profiles',
                        route: '/profiles',
                        disabled: !items[2]
                    };
                    if (items[2]) {
                        if (items[2] === 'bulk-move') {
                            second = {
                                text: 'Bulk Move',
                                route: '',
                                disabled: true
                            }
                        }
                        if (!this.$store.state.currentProfile) break;
                        const {firstName, lastName, id} = this.$store.state.currentProfile.profileInfo;
                        let text = this.$store.state.currentAccount.viewingAsSuper ? `Profile ID: ${id}` : firstName + ' ' + lastName
                        second = {
                            text: text,
                            route: `/profiles/${id}`,
                            disabled: !items[3]
                        }

                        // if (items[3]) {
                        //     third = {
                        //         text: 'Test',
                        //         route: '/profiles',
                        //         disabled: true
                        //     }
                        // }
                    }
                    break;
                case 'users':
                    first = {
                        text: 'Users',
                        route: '/users',
                        disabled: !items[2]
                    };
                    if (items[2]) {
                        if (!this.$store.state.currentUser) break;
                        const {firstName, lastName} = this.$store.state.currentUser
                        second = {
                            text: firstName + ' ' +  lastName,
                            route: '/user',
                            disabled: !items[3]
                        }
                    }
                    break;
                case 'groups':
                    first = {
                        text: 'Groups',
                        route: '/groups',
                        disabled: !items[2]
                    };
                    if (items[2]) {

                        if (!this.$store.state.currentGroup) break;
                        second = {
                            text: this.$store.state.currentGroup.name,
                            route: '',
                            disabled: true
                        }
                    }

                    break;
                case 'protocols':
                    first = {
                        text: 'Protocols',
                        route: '/protocols',
                        disabled: !items[2]
                    };
                    if (items[2]) {
                        if (!this.$store.state.currentProtocol) break;
                        second = {
                            text: this.$store.state.currentProtocol.name,
                            route: '/protocols',
                            disabled: !items[3]
                        }
                    }
                    break;
                case 'sessions':
                    first = {
                        text: 'Sessions',
                        route: '/sessions',
                        disabled: !items[2]
                    };
                    if (items[2]) {
                        if (!this.$store.state.currentSession) break;
                        second = {
                            text: 'Session',
                            route: '/sessions',
                            disabled: !items[3]
                        }
                    }
                    break;
                default:
                    first = 'Something went wrong'

            }

            // if (first) {
            //     first = {
            //         text: 'Dashboard',
            //         disabled: false,
            //         route: '/dashboard'
            //     }
            // }

            let final = [];
            if (second) {
                final.push(first)
                final.push(second)
            }
            if (third) final.push(third)
            return final

        },
        split() {
            return this.path.split('/');
        }


    }

}
</script>

<style scoped>
.active-link {
    color: var(--v-primary-darken4)
}

.active-link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.v-application ul, .v-application ol {
    padding-left: 0px;
}
</style>