<template>
    <div >
        <div v-if="sessions.length > 0">
            <div style="display: flex; justify-content: flex-end" class="mb-2">
                <app-btn @click="createSessionDialogOpen = true">Create Code</app-btn>
            </div>

            <SessionsTable
                :sessions="sessions"
                :loading="loadingSessions"
                @onRowClick="onRowClick($event)"
            />

           
        </div>
        <div v-else>
            <EmptyState
                :svg="emptyStateSvg"
                button
                buttonText="Create Code"
                description="This group does not have any codes"
                @buttonClick="createSessionDialogOpen = true"
            />
            
        </div>
        <SessionCreateForm
            :open="createSessionDialogOpen"
            @close="createSessionDialogOpen = false"
            :group="group"
            :groups="[group]"
            :organizationPreferences="organizationPreferences"
        />
    </div>
</template>

<script>
import {getSessions} from "@/services/session.service";
import {getSessionDetails} from "@/js/shared/helper";
import SessionsTable from "@/components/Sessions/SessionsTable";
import SessionCreateForm from "@/components/Sessions/SessionCreateForm";
import EmptyState from "@/components/EmptyState";
import emptyStateSvg from "@/assets/svgs/empty-state/empty-code.svg"

export default {
    name: "GroupSessions",
    components: {EmptyState, SessionCreateForm, SessionsTable},
    props: {
        group: {
            required: true
        },
        sessions: {
            required: true
        },
        organizationPreferences: {
            required: true
        }
    },
    data() {
        return {
            emptyStateSvg,
            createSessionDialogOpen: false
        }
    },
    watch: {
        $route() {
            if (this.$route.query.createCode === 'true') {
                this.createSessionDialogOpen = true
            } 
        }  
    },
    mounted() {
        if (this.$route.query.createCode === 'true') {
            this.createSessionDialogOpen = true
        }
    },
    methods: {
        
        onRowClick(value) {
            this.$router.push(`/codes/${value.id}`)
        }
    }
}
</script>

<style scoped>

</style>