var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppInput',{attrs:{"name":_vm.errorLabel ? _vm.errorLabel : _vm.label,"rules":_vm.rules,"label":_vm.label,"row":_vm.row},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"value":_vm.value,"items":_vm.items,"required":"","outlined":_vm.outlined,"dense":"","color":"text","item-text":_vm.itemText,"item-value":_vm.itemValue,"menu-props":{ offsetY: true, dense: false },"error-messages":errors.length > 0 ? [''] : [],"disabled":_vm.disabled,"placeholder":_vm.placeholder,"color":_vm.color},on:{"change":_vm.updateSelect},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
var parent = ref.parent;
return [_c('v-list-item-content',{staticStyle:{"max-width":"500px"}},[_c('div',{style:({marginTop: item.description && _vm.showDescription ? '5px' : null, marginBottom: item.description && _vm.showDescription ? '5px' : null})},[_c('v-list-item-title',{style:({fontWeight: item.description && _vm.showDescription ? 'bold' : null})},[_vm._v(_vm._s(item[_vm.itemText] ? item[_vm.itemText] : item.label)+" "+_vm._s(item.parent))]),(item.description && _vm.showDescription)?_c('div',{staticStyle:{"margin-top":"5px","font-size":".8rem","color":"#757575"},domProps:{"innerHTML":_vm._s(item.description)}}):_vm._e()],1)])]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }