<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="12">

                <app-card title="Profiles Tested">
                    <div class="pa-2">
                        <div v-if="billingOverview.hasChildSportsOrgs" class="pr-4 pl-4">
                            <div class="mb-4" >
                                <div style="display: flex; align-items: center">
                                    <h4 class="mr-2">Sports Profiles Tested By Organizations ({{sportsDateRange}})</h4>
                                </div>
                                <div>
                                    <h4>Note: Distinct profiles tested during period. Does not matter if deleted, inactive, or active. Only tested profiles.</h4>

                                    <table id="profile-monthly-count-table">
                                        <thead>
                                        <tr>
                                            <td>
                                                Organization Name
                                            </td>
                                            <td>
                                                Organization Id
                                            </td>
                                            <td>
                                                Distinct Tested Profiles
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(item, index) in billingOverview.sportsChildOrgProfiles" :key="index">
                                            <td>
                                                <span>{{item.orgName}}</span>
                                            </td>
                                            <td>
                                                <span>{{item.orgId}}</span>
                                            </td>
                                            <td>
                                                <span>{{item.distinctProfileCount}}</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <v-divider class="ma-4" v-if="billingOverview.hasChildSportsOrgs && billingOverview.hasChildClinicalOrgs"></v-divider>
                        <div v-if="billingOverview.hasChildClinicalOrgs" class="pr-4 pl-4">
                            <div class="mb-4" >
                                <div style="display: flex; align-items: center">
                                    <h4 class="mr-2">Clinical Profiles Tested By Organizations ({{clinicalDateRange}})</h4>
                                </div>
                                <div>
                                    <h4>Note: Distinct profiles tested during period. Does not matter if deleted, inactive, or active. Only tested profiles.</h4>

                                    <table>
                                        <thead>
                                        <tr>
                                            <td>
                                                Organization Name
                                            </td>
                                            <td>
                                                Organization Id
                                            </td>
                                            <td>
                                                Distinct Tested Profiles
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(item, index) in billingOverview.clinicalChildOrgProfiles" :key="index">
                                            <td>
                                                <span>{{item.orgName}}</span>
                                            </td>
                                            <td>
                                                <span>{{item.orgId}}</span>
                                            </td>
                                            <td>
                                                <span>{{item.distinctProfileCount}}</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>



                </app-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment'
import {formatMoney} from '@/js/shared/helper'

// find info for stripe API here
// https://stripe.com/docs/js/element/mount

export default {
    name: "ProfilesTested",
    props: {
        billingOverview: {
            required: true
        }
    },
    components: {
    },
    data() {
        return {
            isBalancePastDue: false
        }
    },
    mounted() {
    },
    computed: {
        clinicalDateRange() {
            return this.formatDate(this.billingOverview.clinicalPeriodStart) + " - " + this.formatDate(this.billingOverview.clinicalPeriodEnd)
        },
        sportsDateRange() {
            return this.formatDate(this.billingOverview.sportsPeriodStart) + " - " + this.formatDate(this.billingOverview.sportsPeriodEnd)
        }
    },
    methods: {
        formatMoney(money) {
            return formatMoney(money, 2);
        },
        formatDate(date) {
            return moment(date).format("M/D/YYYY");
        }
    },

}
</script>

<style scoped>

</style>