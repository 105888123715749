<template>
    <div>
        <app-card v-if="profile">
            <calendar :events="events"></calendar>
        </app-card>
    </div>
</template>

<script>
import Calendar from "@/components/Calendar";
import moment from 'moment'
export default {
    name: "ProfileCalendar",
    components: {
        Calendar
    },
    props: {
        profile: {
            required: true
        }
    },
    computed: {
        events() {
            return this.profile.tests.map(test => {
                if (!test.completedOn) return
                return {
                    end: moment(test.completedOn).valueOf(),
                    start: moment(test.completedOn).valueOf(),
                    color: test.isEvent ? 'orange' : 'blue',
                    name: 'Event',
                    timed: true
                }
            })
        }
    }
}
</script>

<style scoped>

</style>