var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"pdf-table",staticStyle:{"border":"none"}},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"background-color":"white","border":"none"}}),_vm._l((_vm.sessionDetailsHeaderLength),function(item,itemIndex){return _c('th',{staticStyle:{"border":"none","padding":"5px 10px","color":"black!important","background-color":"white"},style:({
                                        opacity: _vm.measureResults.sessionDetails.testsArray[itemIndex].isExcluded ? 0.5 : 1,
                                        textDecoration: _vm.measureResults.sessionDetails.testsArray[itemIndex].isExcluded ? 'line-through' : null
                                    })},[_vm._v(" Test "+_vm._s(itemIndex + 1)+" ")])})],2)]),_vm._l((_vm.measureResults.sessionDetails.resultsTable2),function(row,rowIndex){return _c('tr',{key:rowIndex},_vm._l((row),function(item,itemIndex){return _c('td',{key:itemIndex,staticClass:"pdf-table-cell",class:[(item.isFlagged ? 'flagged' : '')],staticStyle:{"padding":"5px 10px"},style:({
                                        color: item.isFlagged  ? 'red' : null,
                                        opacity: item.isExcluded ? 0.5 : 1,
                                        textDecoration: item.isExcluded ? 'line-through' : null
                                    })},[_c('div',{domProps:{"innerHTML":_vm._s(item.text)}})])}),0)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }