<template>
    <div>
        <div v-if="charts || scores || errorMessage">
            <div style="margin-bottom: 20px;border-color: black;border-bottom-style: solid;">
                <div style="margin: auto;width: 80%;text-align: center;font-weight: bold;">{{testTitle}}</div>
            </div>
            <div v-if="errorMessage" style="color: red; font-size: 16px; font-weight:bold; text-align: center; padding:10px">{{errorMessage}}</div>
            <div v-if="scores">
                <div style="text-align: center; font-size: 18px">Scores</div>
                <table class="scores-table">
                    <tr v-for="(score, index) in scores" :key="index">
                        <td>{{score.name}}</td>
                        <td style="font-weight: bold">{{score.score}}</td>
                    </tr>
                </table>
            </div>
            <div v-if="topDownChart" style="margin-left: auto; margin-right: auto;">
                <highcharts :options="topDownChart"></highcharts>
            </div>
            <div v-if="psiBeepChart" style="margin-left: auto; margin-right: auto;">
                <highcharts :options="psiBeepChart"></highcharts>
            </div>
        </div>
        <div v-else>
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; padding: 50px">
                <SwayLoader />
                <div style="margin-top: 10px">
                    Loading Data...
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {getPublicTestCalculationGraphs} from "@/services/profile.service";
import {buildLineChart} from "@/js/shared/helper";
import Highcharts from "highcharts";


export default {
    name: "CobaltGraphPopup",
    components:{
        
    },
    props: {
        profileTestId: {
            required: true
        },
        profileTestResultId: {
            required: false
        }
    },
    data() {
        return {
            errorMessage: null,
            scores: null,
            charts: null,
            topDownChart: null,
            psiBeepChart: null,
            currentResultId: null,
            resultIds: [],
            resultLength: 0,
            resultIteration: 0,
            testTitle: ""
        }
    },
    mounted() {
        this.loadGraphs()
    },
    methods: {
        async loadGraphs() {

            this.scores = null;
            this.topDownChart = null;
            this.psiBeepChart = null;
            this.errorMessage = null;
            
            let response = await getPublicTestCalculationGraphs(this.profileTestId, this.profileTestResultId);

            this.currentResultId = response.selectedTestResultId;
            this.resultIds = response.availableTestResultIds;
            this.resultLength = this.resultIds.length;
            this.resultIteration = this.resultIds.findIndex(o => o === response.selectedTestResultId);
            this.testTitle = response.testType + ' #' + response.selectedTestResultId;

            if (response.Errors) {
                this.errorMessage = response.Errors[0].Message;
                return;
            }
            
            let charts = []
            const scatterGraphs = response.data.scatterGraphs;
            if (scatterGraphs){
                scatterGraphs.forEach(result => {
                    if (result.series && result.name == "CobaltTopDownView"){
                        const options = buildLineChart(result);
                        options.chart.width = 800;
                        options.chart.height = 800;
                        let minX = 0.0, maxX = 0.0, minY = 0.0, maxY = 0.0;
                        result.series.map(s => {
                            s.points.map(value => {
                                if (value[0] && value[0] < minX)
                                    minX = value[0];
                                if (value[0] && value[0] > maxX)
                                    maxX = value[0];
                                if (value[1] && value[1] < minY)
                                    minY = value[1];
                                if (value[1] && value[1] > maxY)
                                    maxY = value[1];
                            })
                        });
                        
                        options.xAxis.min = minX > minY ? minY : minX;
                        options.xAxis.max = maxX < maxY ? maxY : maxX;
                        options.yAxis.min = minX > minY ? minY : minX;
                        options.yAxis.max = maxX < maxY ? maxY : maxX;
                        this.topDownChart = options;
                    }
                    if (result.series && result.name == "PsiAngleWithBeeps"){
                        this.psiBeepChart = buildLineChart(result);
                    }
                });
            }
            this.charts = charts

            this.errorMessage = response.data.errorMessage;
            this.scores = response.data.namedScores;
        }
    }
}
</script>

<style scoped>
    .scores-table
    {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 40px;
        font-size: 12px;
    }
</style>