<template>
    <v-dialog
        v-model="open"
        :width="width"
        @click:outside="closeDialog"
    >

                <v-card>
                    <v-card-title>
                        <h2 style="font-weight: bold">{{ title }}</h2>
                        <v-spacer></v-spacer>
                        <v-icon  @click="closeDialog" color="accent">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text  class="text--text">
                        <!--        <slot name="form-content"></slot>-->
                        <v-container >

                            <slot></slot>


                        </v-container>
                        <!--          <small>*indicates required field</small>-->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <app-btn
                            :color="confirmDelete ? 'error' : 'primary'"
                            type="submit"
                            class="mb-4"
                            :loading="loading"
                            @click="handleDelete"
                        >
                            {{ buttonTitle }}
                        </app-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>

    </v-dialog>
</template>

<script>
export default {
    name: "AppConfirmDialog",
    props: {
        open: {
            required: true,
            type: Boolean
        },
        title: {
            required: true,
            type: String
        },
        width: {
            required: false,
            type: Number,
            default: 500
        },
        onSubmit: {
            type: Function,
        },
        submitButtonTitle: {
            type: String,
        },
        loading: {
            type: Boolean
        },
        confirmDelete: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        
        handleDelete() {
            this.$emit('confirm');
            // this.closeDialog()
        },
        closeDialog() {
            this.$emit('close')
        }
    },
    computed: {
        buttonTitle() {
            if (this.submitButtonTitle) return this.submitButtonTitle
            if (this.confirmDelete) return 'Delete'
            else return 'Confirm'
        }
    }
}
</script>

<style scoped>

</style>