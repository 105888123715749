<template>
    <div>
        <div v-if="!loadingProtocols">

            <page-header title="Protocols">
                <div slot="right-content">
                    <app-btn @click="addProtocolDialogOpen = true" icon="mdi-plus">Create Protocol</app-btn>
                    <app-btn
                        @click="editProtocolSettingsDialogOpen = true"
                        class="ml-2"
                        v-if="isAdmin"
                        icon="mdi-pencil"
                    >Edit Settings</app-btn>
                    <app-btn v-if="isSuperAdmin" @click="addStandardProtocolDialogOpen = true" class="ml-2">🧑‍🚀 Add Standard Protocol</app-btn>
                </div>
            </page-header>
           
            <app-card  hide-header>
                <app-data-table
                    :headers="headers"
                    :items="protocols"
                    @onRowClick="onRowClick($event)"
                    :loading="loadingProtocols"
                    loading-text="Loading Protocols..."
                >
                    <template #item.legacyProtocolId="{item}">
                        <div >
                            <app-chip label v-if="item.legacyProtocolId" color="green">Standard</app-chip>
                            <app-chip v-else label color="blue">Custom</app-chip>
                        </div>
                    </template>
                    <template #item.symptomsEnabled="{item}">
                        <div >
                            {{item.symptomsEnabled ? 'Yes' : 'No'}}
                            
                        </div>
                    </template>
                </app-data-table>
            </app-card>


           

        </div>
        
        <div v-else>
            <AppPageLoader text="Loading Protocols..." />
        </div>

        <dialog-form
            :open="editProtocolSettingsDialogOpen"
            @close="editProtocolSettingsDialogOpen = false"
            title="Edit Protocol Settings"
            :width="600"
            @submit="submitEditProtocolSettingsForm"
            :saving="savingEditProtocolSettings"
        >
            <template v-slot:form-content>
                <app-form-checkbox
                    v-model="organizationPreferences.isSkipTestEnabled"
                    checkboxLeftSide label="Allow App Users to skip trials during test or session"
                />

            </template>

        </dialog-form>
        
        <dialog-form
            :open="addProtocolDialogOpen"
            @close="addProtocolDialogOpen = false"
            title="Add A New Protocol"
            :width="600"
            @submit="submitAddProtocolForm"
            :saving="savingNewProtocol"
        >
            <template v-slot:form-content>
                <app-text-field
                    v-model="addProtocolForm.name"
                    label="Name*"
                    rules="required"
                />
                <app-text-field
                    v-model="addProtocolForm.description"
                    label="Description*"
                    rules="required"
                />
                <app-form-switch label="Symptoms Tracking" v-model="addProtocolForm.symptomsEnabled" />
<!--                <app-form-switch label="Events Enabled" v-model="addProtocolForm.eventsEnabled" />-->

                <v-divider></v-divider>

                <v-row class="mt-3">
                    <app-form-multiple-select
                        draggable
                        :items="organizationMeasures"
                        item-title="name"
                        item-subtitle="description"
                        item-value="regulationMeasureId"
                        v-model="addProtocolForm.measures"
                        addButtonText="Add New Test Type"
                        label="Test Sequence*"
                        addButtonTest="Add New Test Type"
                        style="width: 100%"
                    >

                    </app-form-multiple-select>
                </v-row>

            </template>

        </dialog-form>

        <dialog-form
                :open="addStandardProtocolDialogOpen"
                @close="addStandardProtocolDialogOpen = false"
                title="Add Standard Protocol"
                :width="600"
                @submit="submitAddStandardProtocolForm"
                :saving="savingNewProtocol"
        >
            <template v-slot:form-content>
                <app-select
                        :items="standardProtocols"
                        label="Standard Protocol"
                        v-model="addStandardProtocolLegacyId"
                        item-text="name"
                        item-value="legacyProtocolId"
                ></app-select>
            </template>

        </dialog-form>
    </div>
</template>

<script>
import {
    getProtocols,
    getMeasuresForOrganization,
    protocolModel,
    createProtocol,
    addStandardProtocol, getStandardProtocols
} from '@/services/protocol.service'
import {SET_SNACKBAR} from '@/store/mutation-types'
import draggable from 'vuedraggable'
import AppMultipleSelect from "@/components/AppMultipleSelect";
import {getOrganizationPreferences, updateOrganizationPreferences} from "@/services/organization.service";
import {isAdminOrgAccessType} from "@/js/shared/helper";
import {allMeasuresFromConstants, measuresCategoriesById} from "@/js/shared/constants";

export default {
    name: "Protocols",
    props: {
        account: {}
    },
    components: {
        draggable,
        AppMultipleSelect
    },
    data() {
        return {
            protocols: [],
            organizationMeasures: [],
            organizationPreferences: null,
            loadingProtocols: true,
            addProtocolDialogOpen: false,
            addStandardProtocolDialogOpen: false,
            editProtocolSettingsDialogOpen: false,
            savingNewProtocol: false,
            savingEditProtocolSettings: false,
            headers: [
                {
                    text: 'ID',
                    value: 'organizationProtocolId',
                },
                {
                    text: 'Name',
                    value: 'name',
                },
                {
                    text: 'Description',
                    value: 'description',
                },
                {
                    text: 'Type',
                    value: 'legacyProtocolId',
                },
                // {
                //     text: 'Symptoms Enabled',
                //     value: 'symptomsEnabled',
                // },
            ],
            addProtocolForm: {
                name: '',
                description: '',
                measures: [],
                symptomsEnabled: false,
                eventsEnabled: false,
            },
            standardProtocols: [],
            addStandardProtocolLegacyId: null,
            dragging: false,
            draggingEnabled: true
        }
    },
    mounted() {
        this.setProtocols();
        this.setOrganizationMeasure();
        this.setOrganizationPreferences();
    },
    methods: {
        async setOrganizationPreferences() {
            const response = await getOrganizationPreferences();
            if (!response.isSuccess) return
            this.organizationPreferences = response.organizationPreferences
        },
        async setProtocols() {
            this.loadingProtocols = true;
            const response = await getProtocols();
            if (!response.isSuccess) return
            const {protocols} = response;
            protocols.sort((a,b) => {
                if (a.legacyProtocolId) return -1
            })
            this.protocols = response.protocols

            if (this.isSuperAdmin){
                this.standardProtocols = [];
                const response2 = await getStandardProtocols();
                for (let i = 0; i < response2.protocols.length; i++) {
                    if (this.protocols.some(x => x.legacyProtocolId === response2.protocols[i].legacyProtocolId))
                        continue;

                    this.standardProtocols.push(response2.protocols[i]);
                }
            }
            
            this.loadingProtocols = false
        },
        async setOrganizationMeasure() {
            const response = await getMeasuresForOrganization();
            if (!response.isSuccess) return
            this.organizationMeasures = response.measures.map(measure => {
                measure.active = false;
	            const {regulationMeasureId} = measure;
	            const measureFromConstants = allMeasuresFromConstants[regulationMeasureId];
	            measure.category = measureFromConstants ? measureFromConstants.category : 8;
	            measure.image = measuresCategoriesById[measure.category].icon;
				measure.color = measuresCategoriesById[measure.category].color;
                return measure
            });
        },
        onRowClick(item) {
            this.$router.push(`/protocols/${item.organizationProtocolId}`)
        },
        async submitEditProtocolSettingsForm() {
            this.savingEditProtocolSettings = true
            let response = await updateOrganizationPreferences(this.organizationPreferences);
            if (!response.isSuccess) {
                
                this.$store.commit(SET_SNACKBAR, {
                    text: 'There was an error saving the protocol settings.',
                    color: 'error',
                    open: true
                });
            } else {
                this.organizationPreferences = response.organizationPreferences
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Successfully updated protocol settings.',
                    color: 'success',
                    open: true
                });
                this.editProtocolSettingsDialogOpen = false
                
            }
            
            this.savingEditProtocolSettings = false;
            
        },
        async submitAddProtocolForm() {
            this.savingNewProtocol = true;
            const request = {...protocolModel}
            const {
                name,
                description,
                symptomsEnabled,
                eventsEnabled,
                measures
            } = this.addProtocolForm;
            
            let isNameTaken = false;
            this.protocols.forEach(x => {

                if (x.name.toLowerCase() === name.toLowerCase()) isNameTaken = true
            })
            
            if (isNameTaken) {
                this.savingNewProtocol = false;
                return this.$store.commit(SET_SNACKBAR, {
                    text: 'Protocol names must be unique.',
                    color: 'error',
                    open: true
                });
            }

            if (!symptomsEnabled && (!measures || measures.length === 0)) {
                this.savingNewProtocol = false;
                return this.$store.commit(SET_SNACKBAR, {
                    text: 'Protocol must include tests or symptom tracking.',
                    color: 'error',
                    open: true
                });
            }

            request.name = name;
            request.description = description;
            request.symptomsEnabled = symptomsEnabled;
            request.eventsEnabled = eventsEnabled;
            request.isDisabled = false;
            request.protocolItems = measures.map((measure, index) => ({
                regulationMeasureId: measure,
                regulationMeasureOrder: index
            }));

            let response = await createProtocol(request);
            if (!response.isSuccess) return
            const {protocol} = response;

            this.addProtocolDialogOpen = false;
            this.savingNewProtocol = false;
            await this.$router.push(`/protocols/${protocol.organizationProtocolId}`)
            this.$store.commit(SET_SNACKBAR, {
                text: 'Protocol Successfully Created',
                color: 'success',
                open: true
            })
            // alert('submit protocol form')
        },
        async submitAddStandardProtocolForm() {
            debugger;
            this.savingNewProtocol = true;

            let response = await addStandardProtocol(this.addStandardProtocolLegacyId);
            if (!response.isSuccess) return
            
            this.addStandardProtocolDialogOpen = false;
            this.savingNewProtocol = false;
            this.$store.commit(SET_SNACKBAR, {
                text: 'Protocol Successfully Added',
                color: 'success',
                open: true
            })

            this.addStandardProtocolLegacyId = null;
            await this.setProtocols();
        },
        addMeasureToProtocol(e, measure, index) {
            if (e) {
                this.addProtocolForm.measures.push(measure)
            } else {
                this.addProtocolForm.measures.splice(index, 1)
            }
            
        },
        // checkMove: function(e) {
        //     window.console.log("Future index: " + e.draggedContext.futureIndex);
        // }
    },
    watch: {
        // addProtocolForm: function(newValue, oldValue) {
        //     
        // }  
    },
    computed: {
        isAdmin() {
            if (!this.account) return false;
            return isAdminOrgAccessType(this.account.selectedOrganizationAccessTypeId);
        },
        isSuperAdmin() {
            return this.$store.state.currentAccount.isSuperAdmin;
        }
    }
}
</script>

<style scoped>
.add-measure-container {
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 2px solid var(--v-light-darken2);
    background-color: var(--v-light-base);
    display: flex;
    align-items: center
}

.add-measure-container:hover {
    cursor: pointer
}

.list-group:hover {
    cursor: move;
}

.list-group-item {
    border: 1px solid var(--v-light-darken2 );
    border-radius: 5px;
    border-left: 5px solid var(--v-secondary-base);
    background-color: white;
}

.logo {
    width: 28px;
    height: 28px;
    margin-right: 10px;
}
</style>