<template>
    <div>
        <div 
            v-for="(result, resultKey) in onFieldAssessment.results" 
            :key="resultKey" 
            :class="{'pdf-avoid-page-break': resultKey === 0}"
        >
            <div class="pdf-section-title" v-if="resultKey === 0">On Field Assessment</div>
            <div class="pdf-avoid-page-break">
                <SectionWrapper
                    :title="result.name"
                    title-only
                    
                >
                    <div class="on-field-assessment-section-container">
                        <div class="on-field-assessment-column">
                            <div v-for="(detail, detailKey) in result.detailRows" :key="detailKey"  >
                                <div>
                                    {{detail.key}}
                                </div>

                            </div>
                        </div>
                        <div class="on-field-assessment-column" >
                            <div v-for="(detail, detailKey) in result.detailRows" :key="detailKey" class="on-field-assessment-answer" >
                                <div >
                                    {{detail.value}}
                                </div>

                            </div>
                        </div>
                    </div>

                </SectionWrapper>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import SectionWrapper from "@/components/pdf-export/SectionWrapper";
export default {
    name: "OnFieldAssessmentPdfSection",
    components: {SectionWrapper},
    props: {
        profileTest: {
            required: true
        }
    },
    computed: {
        onFieldAssessment() {
            return this.profileTest.formattedTestsData["1013"] ?? this.profileTest.formattedTestsData["1018"] ?? this.profileTest.formattedTestsData["1027"];
        }
    }
}
</script>

<style scoped>

</style>