<template>

    <div class="sway-form" style="padding-left:20px">
        <h1>Create New Child Organization</h1>
        <br/>
        <br/>
        <app-form
                @submit="completeSetup"
                :saving="isCompletingSetup"
                style="width: 60%"
                submitButtonTitle="Create Organization"
        >
            <h3>Primary Admin User</h3>

            <div class="inline-block-field" id="first-name">
                <app-text-field v-model="model.firstName" label="First Name" rules="required" />
            </div>

            <div class="inline-block-field" id="last-name">
                <app-text-field v-model="model.lastName" label="Last Name" rules="required" />
            </div>

            <div class="inline-block-field" id="job-title">
                <app-text-field v-model="model.jobTitle" label="Professional Designation" rules="required" />
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.email" label="Email" type="email" rules="required|email" />
            </div>

            <div class="inline-block-field">
                <app-select
                        :items="professionalTypes"
                        label="Medical Credentials"
                        v-model="model.medicalCredentials"
                        rules="required"
                ></app-select>
            </div>

            <br />
            <br />
            
            <h3>Organization Information</h3>

            <div class="org-info">
                <div class="inline-block-field">
                    <app-text-field v-model="model.orgName" label="Name" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgPhone" label="Phone Number" :mask="'###-###-####'" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgAdd1" label="Street Address" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgAdd2" label="Apt, Suite, Bldg (optional)" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgCity" label="City" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgState" label="State/Province" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgZip" label="Postal Code" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-select
                            :items="businessTypeSelectList"
                            label="Business Type"
                            v-model="model.businessTypeId"
                            rules="required"
                            item-text="label"
                            item-value="id"
                    ></app-select>
                </div>

                <div class="inline-block-field">
                    <app-select
                            :items="packageTierSelectList"
                            label="Package Type"
                            v-model="model.packageTierId"
                            rules="required"
                            item-text="text"
                            item-value="value"
                    ></app-select>
                </div>
            </div>

            <br />
            <br />
            
            <h3>Billing Information</h3>

            <div class="inline-block-field checkbox">
                <app-form-checkbox
                        label="Bill To Enterprise"
                        v-model="billToEnterprise"
                ></app-form-checkbox>
            </div>

            <template v-if="!billToEnterprise">
                <div class="inline-block-field checkbox">
                    <app-form-checkbox
                            label="Same as Organization Info"
                            v-model="isSameAddress"
                    ></app-form-checkbox>
                </div>
    
                <div class="inline-block-field">
                    <app-text-field v-model="model.billName" label="Billing Name" rules="required" />
                </div>
    
                <div class="inline-block-field">
                    <app-text-field v-model="model.billPhone" label="Billing Phone" :mask="'###-###-####'" rules="required" />
                </div>
    
                <div class="inline-block-field">
                    <app-text-field v-model="model.billEmail" label="Billing Email" type="email" rules="required|email" />
                </div>
    
                <div class="inline-block-field">
                    <app-text-field v-model="model.billAdd1" label="Street Address" rules="required" />
                </div>
    
                <div class="inline-block-field">
                    <app-text-field v-model="model.billAdd2" label="Apt, Suite, Bldg (optional)" />
                </div>
    
                <div class="inline-block-field">
                    <app-text-field v-model="model.billCity" label="City" rules="required" />
                </div>
    
                <div class="inline-block-field">
                    <app-text-field v-model="model.billState" label="State/Province" rules="required" />
                </div>
    
                <div class="inline-block-field">
                    <app-text-field v-model="model.billZip" label="Postal Code" rules="required" />
                </div>
    
                <div class="inline-block-field">
                    <app-select
                            :items="countrySelectList"
                            label="Country"
                            v-model="model.billCountry"
                            item-text="text"
                            item-value="text"
                            rules="required"
                    ></app-select>
                </div>
            </template>

            <br/>
            <br/>
        </app-form>
    </div>

</template>

<script>
import {
    countryOptions,
    organizationBusinessTypeIds,
    professionalTypes,
    packageTiers
} from "@/js/shared/constants";
import {enrollOrganization} from "@/services/enrollment.service";
import {SET_SNACKBAR} from "@/store/mutation-types";
import {getOrganizationId} from "@/services/userService";

export default {
    name: "EnterpriseEnrollment",
    data() {
        return {
            model: {
                firstName: "",
                lastName: "",
                jobTitle: "",
                email: "",
                medicalCredentials: "",
                orgName: "",
                orgPhone: "",
                orgAdd1: "",
                orgAdd2: "",
                orgCity: "",
                orgState: "",
                orgZip: "",
                billName: "",
                billPhone: "",
                billEmail: "",
                billAdd1: "",
                billAdd2: "",
                billCity: "",
                billState: "",
                billZip: "",
                billCountry: "",
                billMethod: "",
                billToOrgId: null,
                enterpriseOrgId: null
            },
            
            isCompletingSetup: false,

            billToEnterprise: false,

            isSameAddress: false,

            professionalTypes: professionalTypes,

            countrySelectList: countryOptions,

            businessTypeSelectList: organizationBusinessTypeIds
        }
    },
    methods: {
        async completeSetup(){
            this.isCompletingSetup = true;
            
            if (!this.model.billToOrgId) // convert empty strings to null
                this.model.billToOrgId = null;
            
            this.model.enterpriseOrgId = getOrganizationId();
            this.model.isDemo = false;
            this.model.billMethod = 1;
            this.model.orgDistributorId = 48;
            this.model.saleType = this.model.packageTierId > 2 ? 2 : 1;
            this.model.billingCycleTypeId = 1;
            this.model.isTaxExempt = false;
            this.model.isInternalOrg = false;
            if (this.billToEnterprise)
                this.model.billToOrgId = this.model.enterpriseOrgId;

            var response = await enrollOrganization(this.model);
            
            this.isCompletingSetup = false;

            if (response && response.isSuccess && response.organization){
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Organization Successfully Created',
                    color: 'success',
                    open: true
                });
                
                await this.$router.push(`/dashboard`);
            }
            else{
                // do nothing and let upstream methods handle this
                //this.$store.commit(SET_SNACKBAR, {
                //    text: 'Error Updating Organization',
                //    color: 'error',
                //    open: true
                //});
            }
        }
    },
    computed: {
        packageTierSelectList() {
            return packageTiers.filter(x => x.value == 2 || x.value == 8);
        }
    },
    watch: {
        isSameAddress: function() {
            if (this.isSameAddress){
                this.model.billName = this.model.orgName;
                this.model.billPhone = this.model.orgPhone;
                this.model.billAdd1 = this.model.orgAdd1;
                this.model.billAdd2 = this.model.orgAdd2;
                this.model.billCity = this.model.orgCity;
                this.model.billState = this.model.orgState;
                this.model.billZip = this.model.orgZip;
            }
        },
        billToEnterprise: function() {
            if (this.billToEnterprise){
                this.model.billToOrgId = getOrganizationId();
            }
        }
    }
}
</script>

<style scoped>

img {
    max-width: 75vw;
    max-height: 30vh;
    width: auto;
    height: auto;
}

#create-edit .editor-label {
    text-align: left;
}

</style>