<template>
    <div>
        <div style="font-weight: 600; font-size: 14px">
            Device Details
        </div>
        <div v-for="(stat, index) in stats" :key="index">
            <span style="color: #757575">{{stat.label}}:</span> {{stat.value}}
        </div>
    </div>
</template>

<script>
export default {
    name: "TestMetadataDetails",
    props: {
        metadata: {
            required: true
        }
    },
    computed: {
        stats() {
            return [
                {
                    label: 'App Version',
                    value: this.metadata?.appVersion ? this.metadata.appVersion : '-'
                },
                {
                    label: 'Platform',
                    value: this.metadata?.platform ? this.metadata.platform : '-'
                },
                {
                    label: 'Device Manufacturer',
                    value: this.metadata?.deviceManufacturer ? this.metadata.deviceManufacturer : '-'
                },
                {
                    label: 'Device Model',
                    value: this.metadata?.deviceModel ? this.metadata.deviceModel : '-'
                },
                {
                    label: 'OS Version',
                    value: this.metadata?.osVersion ? this.metadata.osVersion : '-'
                },
              {
                label: 'Device ID',
                value: this.metadata?.deviceId ? this.metadata.deviceId : '-'
              },
                
            ]
        }
    }
}
</script>

<style scoped>

</style>