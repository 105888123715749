<template>
    <div class="pdf-section-wrapper pdf-avoid-page-break">
        <div class="pdf-section-title" v-if="!hideTitle">Profile History</div>
<!--        <div class="profile-test-history-container">-->
            <table class="pdf-table" v-for="(table, tableIndex) in tables" :key="tableIndex">

                    <thead class="profile-test-history-table-header">
                    <tr style="">
                        <td
                            v-for="(item, index) in table.metaHeaders"
                            :key="index"
                            :colspan="item.colspan ? item.colspan : 1"
                            :rowspan="item.rowspan ? item.rowspan : 1"
                            class="profile-test-history-table-header profile-test-history-table-header-row-1"
                            style="border: 1px solid #d8d8d8"
                        >
                            {{ item.fullLabel ? item.fullLabel : item.label }}
                        </td>

                    </tr>
                    <tr>
                        <td
                            v-for="(item, index) in table.headers"
                            :key="index"
                            :colspan="item.colspan ? item.colspan : 1"
                            :rowspan="item.rowspan ? item.rowspan : 1"
                            class="profile-test-history-table-header"
                            style="border: 1px solid #d8d8d8"
                        >
                            {{ item.label }}
                        </td>

                    </tr>
                    <tr v-if="table.baselines.length > 1" >
                        <td
                            v-for="(item, index) in table.baselines"
                            :key="index"
                            :colspan="item.colspan ? item.colspan : 1"
                            :rowspan="item.rowspan ? item.rowspan : 1"
                            class="profile-test-history-table-baselines"
                            style="border: 1px solid #d8d8d8"
                        >
                            {{ item.display }}
                        </td>

                    </tr>

                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in table.rows" :key="index" >
	                    <template v-if="!row.isEventRow">
		                    <td 
			                    v-for="(item, itemIndex) in row.data" 
			                    :key="itemIndex" 
			                    class="pdf-table-cell" 
			                    :style="{
									width: itemIndex === 0 ? '125px!important' : null,
									borderLeft: row.isDuringEvent && itemIndex === 0 ? '5px solid rgba(255,161,42,1)' : null
								}"
			                    
		                    >
			                    <div v-if="itemIndex === 0" class="profile-test-history-table-date-cell">
				                    <TestTypeDot  :is-non-baseline="row.isNonBaseline" :has-duplicate-tests="row.hasDuplicateTests" :is-dot-excluded="row.isExcluded"/>
				                    <div style="display: flex; flex-direction: column; margin-left: 5px; flex: 1">
					                    <div>{{item.date}}</div>
					                    <div style="font-size: .9em">{{item.time}}</div>
				                    </div>
			
			                    </div>
			                    <div  v-else >
				                    <div v-if="item.isSurvey">
					                    <div v-if="typeof item.score === 'number'">{{item.score}}</div>
					                    <v-icon v-else-if="item.didPass" class="profile-test-history-checkmark" >mdi-checkbox-marked-circle</v-icon>
				
				                    </div>
				                    <div 
					                    v-else
					                    style="display: flex; justify-content: center"
				                    >
					                    <div 
						                    v-html="item.display"
						                    :style="{
		                                    opacity: item.isExcluded ? 0.5 : 1,
		                                    textDecoration: item.isExcluded ? 'line-through' : null
		                                }"
					                    />
				                    </div>
				
				
				
				                    <!--                                <div v-if="row.isEvent && itemIndex === 0">-->
				                    <!--                                    <div class="chip-small chip-orange">EVENT</div>-->
				                    <!--                                </div>-->
				
				
				                    <div v-if="row.isEvent && item.percentChange !== null && item.showPercentChange" style="display: flex; justify-content: center; flex-wrap: nowrap">
					                    <MeasureChangeChip :change-data="item.changeData" />
				                    </div>
			                    </div>
		
		
		
		
		
		                    </td>
	                    </template>
	                    <template v-else>
		                    <td style="background-color: rgba(255,161,42,.25); border-left: 5px solid rgba(255,161,42,1); text-align: center; padding: 5px 0" :colspan="row.colspan">{{ row.display }}</td>
	                    </template>
                        
                    </tr>
                    </tbody>

                
            </table>
        
            
        <div>
            
            <TestTypeDotExplanation v-if="!hideExplanation" is-pdf />
            <div style="display: flex; margin-top: 10px; align-items: center">
<!--                <div style="margin-right: 5px" class="chip-small chip-grey" >Above &#x25BC;10%</div>-->
<!--                <div style="margin-right: 5px" class="chip-small chip-orange" >&#x25BC;10.01% to &#x25BC;15%</div>-->
<!--                <div class="chip-small chip-red" >Below &#x25BC;15%</div>-->
                
                <div style="margin-right: 5px">Decrease in performance from baseline: </div>
                <div style="margin-right: 5px" class="chip-small chip-grey" ><10%</div>
                <div style="margin-right: 5px" class="chip-small chip-orange" >>10.01 & <15%</div>
                <div class="chip-small chip-red" >>15%</div>
            </div>
	        <div style="display: flex; margin-top: 10px; align-items: center" v-if="profile.reliableChangeValues && profile.reliableChangeValues[1004]">
		        <!--                <div style="margin-right: 5px" class="chip-small chip-grey" >Above &#x25BC;10%</div>-->
		        <!--                <div style="margin-right: 5px" class="chip-small chip-orange" >&#x25BC;10.01% to &#x25BC;15%</div>-->
		        <!--                <div class="chip-small chip-red" >Below &#x25BC;15%</div>-->
		
		        <div style="margin-right: 5px">Decrease in performance from baseline (Inspection Time): </div>
		        <div style="margin-right: 5px" class="chip-small chip-grey" ><{{ profile.reliableChangeValues[1004].yellowChange }}ms</div>
		        <div style="margin-right: 5px" class="chip-small chip-orange" >>{{ profile.reliableChangeValues[1004].yellowChange }}ms & <{{ profile.reliableChangeValues[1004].redChange }}ms</div>
		        <div class="chip-small chip-red" >>{{ profile.reliableChangeValues[1004].redChange }}ms</div>
	        </div>
	        
            <div style="margin-top: 0px;">Excluded Score: <span style="text-decoration: line-through; opacity: 0.5">98</span> or <span style="text-decoration: line-through; opacity: 0.5">202ms</span></div>
        </div>
        </div>
<!--    </div>-->
</template>

<script>
import TestTypeDot from "@/components/TestTypeDot";
import TestTypeDotExplanation from "@/components/pdf-export/components/TestTypeDotExplanation";
import MeasureChangeChip from "@/components/pdf-export/components/MeasureChangeChip";
export default {
    name: "ProfileTestHistoryTable",
    components: {MeasureChangeChip, TestTypeDotExplanation, TestTypeDot},
    props: {
        profile: {
            required: true
        },
        hideTitle: {
            type: Boolean,
            default: false
        },
        hideExplanation: {
            type: Boolean,
            default: false
        },

    },
    data() {
        return {
            tables: [],
            metaHeaders: [],
            headers: [],
            rows: [],
            baselines: []
        }
    },
    mounted() {
        const tables = this.profile.getDetailedHistoryTableData({isMobileView: false});
        this.tables = tables
        // this.metaHeaders = metaHeaders;
        // this.headers = headers;
        // this.rows = rows;
        // this.baselines = baselines
    },
    computed: {
        // headers() {
        //     let headers = []
        //     for (let key in this.tableHeaders) {
        //        
        //     }
        // }
    },
    
}
</script>

<style scoped>

</style>