<template>

    <v-card style="padding: 20px">
        <div v-if="!mfaCodeSent" >

            <h3 style="text-align: center">
                Multi-Factor Authentication
            </h3>
            <p class="mt-2" style="text-align: center">
                Looks like your account requires multi-factor authentication. Select a method to receive your code.
            </p>
            <div
                    v-for="method in verificationMethods"
                    :key="method.type"
                    class="mt-2 pa-3 verificationMethod"

                    :class="{activeVerificationMethod: selectedVerificationTypeId === method.type}"
                    @click="selectedVerificationTypeId = method.type"
            >
                <div style="font-weight: bold">
                    {{method.label}}
                </div>
                {{method.value}}

            </div>
            <div class="mt-4" style="display: flex; justify-content: center">
                <AppBtn
                        :disabled="!selectedVerificationTypeId"
                        @click="requestMfaCode"
                        :loading="requestingMfaCode"
                >Send Code</AppBtn>
            </div>
        </div>
        <div v-else>

            <input
                    type="number"
                    class="custom-field"
                    :value="mfaCode"
                    @input="updateMfaCode"
                    name="thing"
                    ref="mfaCode"
                    style="height: 0; padding: 0"
                    @focusout="mfaFieldFocused = false"

            >
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center">
                <h3>
                    Enter Code
                </h3>
                <p class="mt-2">
                    Enter 6-digit code from your selected method
                </p>
                <div style="display: flex" @click="focusOnMfaCode">
                    <div
                            v-for="(item, index) in 6"
                            :key="item"
                            class="ma-2 pa-3 code-box"
                            style=""
                            :class="{activeCodeBox: (mfaCode[index] || index === mfaCode.length) && mfaFieldFocused}"
                    >
                        <div  style="font-size: 24px">{{mfaCode[index]}}</div>

                    </div>
                </div>
                <AppBtn
                        class="mt-4"
                        @click="verifyMfaCode"
                        :loading="verifyingMfaCode"
                >Submit</AppBtn>
                <AppBtn
                        class="mt-4"
                        outlined
                        @click="requestMfaCode"
                        :loading="requestingMfaCode"
                >Resend Code</AppBtn>
            </div>

        </div>


    </v-card>

</template>

<script>


let viewport = document.querySelector("meta[name=viewport]");
viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

import {
    requestMfaCode,
    verifyMfaCode,
} from "@/services/userService";
export default {
    name: "MfaDialog",
    props: {
        account: {}
    },
    data() {
        return {
            mfaVerificationDialog: false,
            selectedVerificationTypeId: null,
            mfaCodeSent: false,
            mfaCode: '',
            requestingMfaCode: false,
            verifyingMfaCode: false,
            mfaFieldFocused: false
        }
    },
    beforeMount() {
        
    },
    mounted() {

    },
    computed: {
        verificationMethods() {
            if (!this.account) return []
            let response = [
                {
                    type: 1,
                    label: 'Email',
                    value: this.account?.email
                }
            ]

            if (this.account?.phoneNumber) {
                response.push({
                    type: 2,
                    label: 'Phone',
                    value: this.account?.phoneNumber
                })
            }

            return response
        }
    },
    watch: {
        
    },
    methods: {
        focusOnMfaCode() {
            this.$refs.mfaCode.focus();
            this.mfaFieldFocused = true
        },
        updateMfaCode(value) {
            let code = value.currentTarget.value
            console.log(code)
            if (code.length < 6) {
                // do nothing
            }
            if (code.length === 6) {
                this.mfaCode = code;
                this.verifyMfaCode()
            } else {
                this.mfaCode = code
            }
        },
        async requestMfaCode() {
            this.requestingMfaCode = true
            let request = {
                email: this.account.email,
                phoneNumber: this.account.phoneNumber,
                verificationMethod: this.selectedVerificationTypeId
            }

            const response = await requestMfaCode(request);

            if (!response.isSuccess) {
                return
            }

            this.requestingMfaCode = false
            this.mfaCodeSent = true;
            this.mfaCode = ''
        },
        async verifyMfaCode() {
            this.verifyingMfaCode = true
            let request = {
                email: this.account.email,
                mfaCode: this.mfaCode
            }

            let response = await verifyMfaCode(request);

            if (!response.isSuccess) {
                this.mfaCode = ''
            } else {
                const {account, hasMultipleOrganizations, availableOrganizations,selectedOrganization} = response;

                account.availableOrganizations = availableOrganizations;
                account.selectedOrganization = selectedOrganization
                this.$emit('setAccount', account);

                await this.$emit('finishLogin', {account, hasMultipleOrganizations, selectedOrganization})
            }

            this.verifyingMfaCode = false
            
        }
    }
}
</script>


<style scoped>

.activeVerificationMethod {
    background-color: rgba(130,203,195,0.2);
}

.verificationMethod {
    border: 1px solid var(--v-secondary-base);
    border-radius: 5px
}

.verificationMethod:hover {
    cursor: pointer
}

.code-box {
    border: 2px solid #bdbdbd;
    border-radius: 5px;
    height: 60px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center
}

.activeCodeBox {
    border: 2px solid var(--v-primary-base);
    color: var(--v-charcoal-base);
    font-weight: bold
    /*background-color: pink;*/
}

.hover {
    cursor: pointer
}

</style>