<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="12">
                
                <app-card title="Billing Overview">
                    <div class="pa-2">
                        <div class="pr-4 pl-4" >
                            <div style="display: flex; align-items: center; padding-top:10px">
                                <h4 class="mr-2">Account Status</h4>
                            </div>
                            <div v-if="currentBalance">
                                <span v-if="isBalancePastDue" style="color: red; margin-right:15px">You have a past due balance of {{formatMoney(currentBalance)}}.</span>
                                <span v-else style="margin-right:15px">You have an outstanding balance of {{formatMoney(currentBalance)}}.</span>
                                <app-btn @click="goToDueInvoice">
                                    Pay Now
                                </app-btn>
                            </div>
                            <div v-else>
                                <span>No invoices due or outstanding.</span>
                            </div>
                            <div style="margin-top: 15px">
                                <span style="margin-left: 25px;">• Please see <a href="#" @click="goToAllInvoices">View Invoices</a> tab for all invoices</span>
                            </div>
                        </div>
                        <v-divider class="ma-4" v-if="billingOverview.sportsPackageName"></v-divider>
                        <div v-if="billingOverview.sportsPackageName" class="pr-4 pl-4">
                            <div class="mb-4" >
                                <div style="display: flex; align-items: center">
                                    <h4 class="mr-2">Sports Billing Summary</h4>
                                </div>
                                <div>
                                    <div style="display: flex">
                                        <div style="min-width: 150px">
                                            Package Name:
                                        </div>
                                        <div>
                                            {{billingOverview.sportsPackageName}}
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <div style="min-width: 150px">
                                            Profiles Purchased:
                                        </div>
                                        <div>
                                            {{billingOverview.sportsProfilesPurchased}}
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <div style="min-width: 150px">
                                            Profiles Used:
                                        </div>
                                        <div>
                                            {{billingOverview.sportsProfilesUsed}}
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <div style="min-width: 150px">
                                            Profiles Left:
                                        </div>
                                        <div>
                                            {{profilesRemaining}}
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <div style="min-width: 150px">
                                            Next Renewal Date:
                                        </div>
                                        <div>
                                            {{formatDate(billingOverview.sportsPeriodEnd)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-divider class="ma-4" v-if="billingOverview.clinicalPackageName"></v-divider>
                        <div v-if="billingOverview.clinicalPackageName" class="pr-4 pl-4">
                            <div class="mb-4" >
                                <div style="display: flex; align-items: center">
                                    <h4 class="mr-2">Clinical Billing Summary</h4>
                                </div>
                                <div>
                                    <div style="display: flex">
                                        <div style="min-width: 150px">
                                            Package Name:
                                        </div>
                                        <div>
                                            {{billingOverview.clinicalPackageName}}
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <div style="min-width: 150px">
                                            Profiles Purchased:
                                        </div>
                                        <div>
                                            {{billingOverview.clinicalProfilesPurchased}}
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <div style="min-width: 150px">
                                            Profiles Used:
                                        </div>
                                        <div>
                                            {{billingOverview.clinicalProfilesUsed}}
                                        </div>
                                    </div>
                                    <div style="display: flex">
                                        <div style="min-width: 150px">
                                            Next Renewal Date:
                                        </div>
                                        <div>
                                            {{formatDate(billingOverview.clinicalPeriodEnd)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-divider class="ma-4"></v-divider>
                        <div class="pr-4 pl-4">
                            <div class="mb-4" >
                                <div>
                                    To upgrade or purchase additional profiles <a href="https://www.swaymedical.com/contact" target="_blank">Contact Sway</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    

                </app-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment'
import {formatMoney} from '@/js/shared/helper'

// find info for stripe API here
// https://stripe.com/docs/js/element/mount

export default {
    name: "BillingOverview",
    props: {
        invoices: {
            required: true
        },
        billingOverview: {
            required: true
        }
    },
    components: {
    },
    data() {
        return {
            isBalancePastDue: false
        }
    },
    mounted() {
    },
    computed: {
        currentBalance() {
            var overdueInvoices = this.invoices.filter(x => !x.payment && x.isOverdue && !x.deletedOn && !x.isRefunded);
            if (overdueInvoices && overdueInvoices.length > 0) {
                var total = 0.0;
                for(var id in overdueInvoices){
                    total += Number(overdueInvoices[id].total.replace(/[^0-9.-]+/g,""));
                }
                this.isBalancePastDue = true;
                return total;
            }
            
            var balanceInvoices = this.invoices.filter(x => !x.payment && !x.isNotSent && !x.deletedOn && !x.isRefunded)
            if (balanceInvoices && balanceInvoices.length > 0) {
                var total = 0.0;
                for(var id in balanceInvoices){
                    total += Number(balanceInvoices[id].total.replace(/[^0-9.-]+/g,""));
                }
                this.isBalancePastDue = false;
                return total;
            }
        },
        profilesRemaining(){
            return this.billingOverview.sportsProfilesPurchased > this.billingOverview.sportsProfilesUsed
                    ? this.billingOverview.sportsProfilesPurchased - this.billingOverview.sportsProfilesUsed
                    : 0;
        }
    },
    methods: {
        formatMoney(money) {
            return formatMoney(money, 2);
        },
        formatDate(date) {
            return moment(date).format("M/D/YYYY");
        },
        goToDueInvoice(){
            var invoice = this.invoices.find(x => !x.payment && !x.isNotSent)
            this.$router.push({path: '/billing/view-invoices', query: {orgId: this.$route.query.orgId, id: invoice.id}});
        },
        goToAllInvoices(){
            this.$router.push({path: '/billing/view-invoices', query: {orgId: this.$route.query.orgId}});
        }
    },
    
}
</script>

<style scoped>

</style>