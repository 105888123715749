<template>
    <div  class="login-form-container">
        <h1 style="font-weight: 600">{{ title }}</h1>
        <div style="margin: 10px; color: var(--v-text-lighten2); max-width: 300px; text-align: center">
            {{message}}
        </div>
        <slot>

        </slot>
        <slot name="above-button"></slot>

        <app-btn
                color="primary"
                style="width: 100%"
                class="mt-3"
                @click="$emit('submit')"
                :loading="loading"
                v-if="buttonText"
                :disabled="disabled"
        >{{ buttonText }}</app-btn>
        <div style="margin-top: 20px; color: var(--v-text-lighten2)" class="hover" @click="$emit('submit2')" v-if="buttonText2">
            {{buttonText2}}
        </div>
        
    </div>
</template>

<script>

export default {
    name: "LoginForm",
    props: {
        title: {
            type: String
        },
        message: {

        },
        button: {
            type: Boolean
        },
        buttonText: {
            type: String
        },
        loading: {
            type: Boolean
        },
        buttonText2: {

        },
        disabled: {
            type: Boolean
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    methods: {
        
    }
}
</script>

<style scoped>


.login {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    background-color: white;
    height: 100vh
}

.login-input {
    width: 300px
}

.hover {
    cursor: pointer
}

.login-form-container {
    display: flex; flex-direction: column; align-items: center; flex: 2
}

</style>