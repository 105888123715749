import { render, staticRenderFns } from "./AppSelect.vue?vue&type=template&id=32907e68&scoped=true"
import script from "./AppSelect.vue?vue&type=script&lang=js"
export * from "./AppSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32907e68",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VListItemContent,VListItemTitle,VSelect})
