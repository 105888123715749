<template>
	<div>
		<v-container fluid>
			<v-row>
				<v-col sm="12" lg="9" xl="6" v-if="!(billingChildOrgs && billingChildOrgs.length > 0)">
					<AppCard title="Parent Billing Organization" class="mb-4">
						<template #header-right>
							<AppBtn icon="mdi-plus" @click="showAddConnectPopup('Parent')" v-if="!billingInfo.billToOrgId">Add Parent Organization</AppBtn>
						</template>
						<app-data-table :items="billingParentOrg" :headers="billingParentTableHeaders"  >
							<!--							<template #item.parentOrganizationName="{item}">-->
							<!--								<div>-->
							<!--									{{item.parentOrganizationName}}-->
							<!--									<AppChip :color="item.connectionStatusChipColor" small label class="ml-2">{{item.connectionStatus}}</AppChip>-->
							<!--								</div>-->
							<!--							</template>-->
							<template v-slot:item.actions="{ item }">
								
								<v-icon
									small
									@click="showRemoveConnectPopup('Parent', item)"
								>
									mdi-delete
								</v-icon>
							</template>
						</app-data-table>
					
					</AppCard>
				</v-col>
				<v-col sm="12" lg="9" xl="6" v-if="!(billingInfo && billingInfo.billToOrgId)">
					<AppCard title="Child Billing Organizations" class="mb-4">
						<template #header-right>
							<AppBtn icon="mdi-plus" @click="showAddConnectPopup('Child')">Add Child Organization</AppBtn>
						</template>
						<app-simple-table :items="billingChildOrgs" :headers="billingChildTableHeaders" >
							<template #item.childOrganizationName="{item}">
								<div>
									{{item.name}}
									<!--									<AppChip :color="item.connectionStatusChipColor" small label class="ml-2">{{item.connectionStatus}}</AppChip>-->
								</div>
							</template>
							<template v-slot:item.actions="{ item }">
								
								<v-icon
									small
									@click="showRemoveConnectPopup('Child', item)"
								>
									mdi-delete
								</v-icon>
							</template>
						</app-simple-table>
					</AppCard>
				</v-col>
			</v-row>
		</v-container>
		
		<DialogForm
			:title="`Add Access ${orgToAddType} Org`"
			:open="addBillingConnectDialogOpen"
			@close="addBillingConnectDialogOpen = false"
			@submit="confirmAddBillingConnect"
			submit-button-title="Save"
			:saving="savingConnection"
		>
			<template #form-content>
				<app-text-field
					label="Org Id"
					v-model="orgToAddId"
					@input="updateSearchString"
					rules="required|positive"
				></app-text-field>
				<div
					v-if="orgToAdd"
					class="pa-2 mt-2 mb-2"
					style="border-radius: 5px"
					:style="{backgroundColor: orgToAdd.id ? '#e8f5e9' : '#ffebee'}"
				>
					✅ <strong>{{orgToAdd.name}}</strong> ({{ orgToAdd.id}})
				</div>
				<div
					v-else-if="orgToAdd"
					class="pa-2 mt-2 mb-2"
					style="border-radius: 5px; background-color: #ffebee"
				>
					❌ Invalid Org ID
				</div>
			
			</template>
		
		</DialogForm>
		
		<AppConfirmDialog
			title="Are you sure?"
			:open="removeBillingConnectDialogOpen"
			confirm-delete
			@close="removeBillingConnectDialogOpen = false"
			submit-button-title="Remove Connection"
			@confirm="confirmRemoveBillingConnect"
		>
			<template>
				Are you sure you want to remove <strong>{{ orgToRemove ? orgToRemove.name : null}}</strong> as a {{ orgToRemoveType }} Billing Organization?
			</template>
		</AppConfirmDialog>
	</div>
</template>

<script>
import {
	getBillingChildOrgs,
	removeBillingChildOrg,
	addBillingChildOrg,
	getBillingInfo,
	updateBillToOrg
} from "@/services/billing.service";
import {getOrganizationById} from "@/services/organization.service";

export default {
	name: "BillingConnections",
	props: {
		billingInfo: {
			required: true
		},
		billingChildOrgs: {
			required: true
		},
	},
	data() {
		return {
			addChildOrgDialogOpen: false,
			
			
			
			removeBillingConnectDialogOpen: false,
			orgToRemove: null,
			orgToRemoveType: null,
			
			addBillingConnectDialogOpen: false,
			orgToAddId: null,
			orgToAdd: null,
			orgToAddType: null,
			
			savingConnection: false,
			
			billingParentOrg: [],
			
			billingParentTableHeaders: [
				
				{
					text: 'ID',
					value: 'id'
				},
				{
					text: 'Name',
					value: 'name'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				},
			],
			billingChildTableHeaders: [
				
				{
					text: 'ID',
					value: 'organizationId'
				},
				{
					text: 'Name',
					value: 'organizationName'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				},
			],
		}
	},
	mounted() {
		// this.getBillingChildOrgs();
		// this.getBillingInfo();
		
		if (this.billingInfo?.billToOrgId) {
			this.billingParentOrg = [
				{
					id: this.billingInfo.billToOrgId,
					name: this.billingInfo.billToOrgName,
					actions: null,
				}
			];
		}
	},
	methods: {
		showRemoveConnectPopup(type, item) {
			this.orgToRemoveType = type;
			this.orgToRemove = item;
			this.removeBillingConnectDialogOpen = true
		},
		showAddConnectPopup(type) {
			this.orgToAddType = type;
			this.addBillingConnectDialogOpen = true;
		},
		async confirmRemoveBillingConnect(){
			if (this.orgToRemoveType === "Child") {
				const result = await removeBillingChildOrg({OrganizationId: this.orgToRemove.id});
				this.orgToRemove = null;
				this.removeBillingConnectDialogOpen = false;
				await this.getBillingChildOrgs();
			} else {
				const result = await updateBillToOrg({OrganizationId: null});
				this.orgToRemove = null;
				this.removeBillingConnectDialogOpen = false;
				await this.getBillingInfo();
			}
			
		},
		async confirmAddBillingConnect() {
			this.savingConnection = true;
			
			if (this.orgToAddType === "Child") {
				const result = await addBillingChildOrg({OrganizationId: this.orgToAdd.id});
				await this.getBillingChildOrgs();
			} else {
				const result = await updateBillToOrg({OrganizationId: this.orgToAdd.id});
				await this.getBillingInfo();
			}
			
			this.orgToAdd = null;
			this.orgToAddId = null;
			this.addBillingConnectDialogOpen = false;
			
			this.savingConnection = false
		},
		
		updateSearchString(event) {
			// const x = event;
			// debugger;
			clearTimeout(this.typingTimer);
			this.typingTimer =  setTimeout(async () => {
				let response = await getOrganizationById(parseInt(event));
				if (response.isSuccess) {
					this.orgToAdd = response.organization;
				}
			}, 300)
		},
	}
}
</script>

<style scoped>

</style>