import balanceIcon from './img/icons/balance-icon.svg'
import functionalIcon from './img/icons/functional-icon.svg'
import cognitiveIcon from './img/icons/cognitive-icon.svg'
import surveyIcon from './img/icons/survey-icon.svg'

import darkBalanceIcon from './img/icons/balance-icon-dark.svg'
import darkFunctionalIcon from './img/icons/functional-icon-dark.svg'
import darkCognitiveIcon from './img/icons/cognitive-icon-dark.svg'
import darkSurveyIcon from './img/icons/survey-icon-dark.svg'

// categories
// 1 = balance
// 2 = functional
// 3 = attention
// 4 = executive function
// 5 = memory
// 6 = neuromotor
// 7 = ocular
// 8 = executive function
// 9 = covid

import moment from "moment";

const timezone = 'America/Chicago'

const measuresCategories = {
    balance: {
        fullLabel: 'Balance',
        label: 'Balance',
        shortLabel: 'Balance',
        value: 1,
        hasChartableBaselines: true,
        color: '#33D4FF',
        icon: balanceIcon,
        darkIcon: darkBalanceIcon
    },
    functional: {
        fullLabel: 'Functional',
        label: 'Functional',
        shortLabel: 'Functional',
        value: 2,
        hasChartableBaselines: true,
        color: '#00EDBA',
        icon: functionalIcon,
        darkIcon: darkFunctionalIcon
    },
    // processing: {
    //     fullLabel: 'Cognitive - Processing',
    //     label: 'Processing',
    //     shortLabel: 'Processing',
    //     value: 3,
    //     hasChartableBaselines: true,
    //     color: '#9EB0FF',
    //     icon: cognitiveIcon
    // },
    // executiveFunction: {
    //     fullLabel: 'Cognitive - Executive Function',
    //     label: 'Executive Function',
    //     shortLabel: 'Exec Func',
    //     value: 4,
    //     hasChartableBaselines: true,
    //     color: '#9EB0FF',
    //     icon: cognitiveIcon
    // },
    // memory: {
    //     fullLabel: 'Cognitive - Memory',
    //     label: 'Memory',
    //     shortLabel: 'Memory',
    //     value: 5,
    //     hasChartableBaselines: true,
    //     color: '#9EB0FF',
    //     icon: cognitiveIcon
    // },
    neuromotor: {
        fullLabel: 'Neuromotor',
        label: 'Neuromotor',
        shortLabel: 'Neuromotor',
        value: 6,
        hasChartableBaselines: true,
        color: '#D3D7DE',
        icon: balanceIcon,
        darkIcon: darkBalanceIcon
    },
    oculomotor: {
        fullLabel: 'Ocular',
        label: 'Ocular',
        shortLabel: 'Ocular',
        value: 7,
        hasChartableBaselines: true,
        color: '#D3D7DE',
        icon: balanceIcon,
        darkIcon: darkBalanceIcon
    },
    survey: {
        fullLabel: 'Survey',
        label: 'Survey',
        shortLabel: 'Survey',
        value: 8,
        hasChartableBaselines: false,
        // trendsAreNotChartable: false,
        // isNotChartable: true,
        hideDetails: false,
        color: '#D3D7DE',
        icon: surveyIcon,
        darkIcon: darkSurveyIcon
    },
    covid: {
        fullLabel: 'COVID',
        label: 'COVID',
        shortLabel: 'COVID',
        value: 9,
        hasChartableBaselines: false,
        trendsAreNotChartable: true,
        color: '#D3D7DE',
        icon: surveyIcon,
        darkIcon: darkSurveyIcon
        
        // excludeFromHistoryTable: true
    },
    symptoms: {
        fullLabel: 'Symptoms',
        label: 'Symptoms',
        shortLabel: 'Symptoms',
        value: 10,
        hasChartableBaselines: false,
        color: '#D3D7DE',
        icon: surveyIcon,
        darkIcon: darkSurveyIcon
    },
    cognitive: {
        fullLabel: 'Cognitive',
        label: 'Cognitive',
        shortLabel: 'Cognitive',
        value: 11,
        hasChartableBaselines: true,
        color: '#9EB0FF',
        icon: cognitiveIcon,
        darkIcon: darkCognitiveIcon
    },

}

const measuresCategoriesById = {};
    for (let key in measuresCategories) {
    let number = measuresCategories[key].value;
        measuresCategoriesById[number] = measuresCategories[key]
        measuresCategoriesById[number].name = key 
}

let timeScales = {
    seconds: {
        text: 'seconds',
        short: 's'
    },
    milliseconds: {
        text: 'milliseconds',
        short: 'ms'
    }
}
// 




// export const measuresCategoriesById = {
//     1: {
//         label: 'Balance',
//         value: 1,
//         name: 'balance'
//     },
//     2: {
//         label: 'Functional',
//         value: 2,
//         name: 'functional'
//     },
//     3: {
//         label: 'Processing',
//         value: 3,
//         name: 'attention'
//     },
//     4: {
//         label: 'Executive Function',
//         value: 4,
//         name: 'executiveFunction'
//     },
//     5: {
//         label: 'Memory',
//         value: 5,
//         name: 'memory'
//     },
//     6: {
//         label: 'Neuromotor',
//         value: 6,
//         name: 'neuromotor'
//     },
//     7: {
//         label: 'Ocular',
//         value: 7,
//         name: 'oculomotor'
//     },
//     8: {
//         label: 'Survey',
//         value: 8,
//         name: 'survey',
//         isNotChartable: true
//     },
//     9: {
//         label: 'COVID',
//         value: 9,
//         name: 'covid',
//         isNotChartable: true
//     },
//     10: {
//         label: 'Symptoms',
//         value: 10,
//         name: 'symptoms',
//         // isNotChartable: false
//     }
//
// }



let generalBalanceDescription = 'An assessment of static postural stability.'

const allMeasuresFromConstants = { // TODO: refactor this so it's a method getMeasure(measureNumber) and it can return default values
    
    1: {
        name: 'singleLegECL',
        label: 'Single Leg Eyes Closed Left',
        shortLabel: 'Single Leg (EC/L)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription,
    },
    2: {
        name: 'tandemECR',
        label: 'Tandem Eyes Closed Right',
        shortLabel: 'Tandem (EC/R)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    7: {
        name: 'feetTogetherEyesClosed',
        label: 'Feet Together Eyes Closed',
        shortLabel: 'Feet Together (EC)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    10: {
        name: 'singleLegECR',
        label: 'Single Leg Eyes Closed Right',
        shortLabel: 'Single Leg (EC/R)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    11: {
        name: 'tandemECL',
        label: 'Tandem Eyes Closed Left',
        shortLabel: 'Tandem (EC/L)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    12: {
        name: 'shoulderWidthEO',
        label: 'Shoulder Width Eyes Open',
        shortLabel: 'Shoulder Width (EO)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    13: {
        name: 'shoulderWidthEC',
        label: 'Shoulder Width Eyes Closed',
        shortLabel: 'Shoulder Width (EC)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    14: {
        name: 'shoulderWidthEOF',
        label: 'Shoulder Width Eyes Open Foam',
        shortLabel: 'Shoulder Width (EO/F)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    15: {
        name: 'shoulderWidthECF',
        label: 'Shoulder Width Eyes Closed Foam',
        shortLabel: 'Shoulder Width (EC/F)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    16: {
        name: 'feetTogetherEO',
        label: 'Feet Together Eyes Open',
        shortLabel: 'Feet Together (EO)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    18: {
        name: 'feetTogetherEOF',
        label: 'Feet Together Eyes Open Foam',
        shortLabel: 'Feet Together (EO/F)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    19: {
        name: 'feetTogetherECF',
        label: 'Feet Together Eyes Closed Foam',
        shortLabel: 'Feet Together (EC/F)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    23: {
        name: 'semiTandemEO',
        label: 'Semi Tandem Eyes Open',
        shortLabel: 'Semi Tandem (EO)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    37: {
        name: 'tandemEO',
        label: 'Tandem Eyes Open',
        shortLabel: 'Tandem (EO)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    41: {
        name: 'tandemEOL',
        label: 'Tandem Eyes Open Left',
        shortLabel: 'Tandem (EO/L)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    46: {
        name: 'tandemEOR',
        label: 'Tandem Eyes Open Right',
        shortLabel: 'Tandem (EO/R)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    49: {
        name: 'singleLegEO',
        label: 'Single Leg Eyes Open',
        shortLabel: 'Sign Leg (EO)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    53: {
        name: 'singleLegEOL',
        label: 'Single Leg Eyes Open Left',
        shortLabel: 'Single Leg (EO/L)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    55: {
        name: 'singleLegEOR',
        label: 'Single Leg Eyes Open Right',
        shortLabel: 'Single Leg (EO/R)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    60: {
        name: 'ocularSaccades',
        label: 'Ocular Saccades',
        category: measuresCategories.oculomotor.value,
        description: ``,
        versions: [
            {
                number: 501,
                stage: 1
            }
        ],
    },
    61: {
        name: 'ocularSmoothPursuit',
        label: 'Ocular Smooth Pursuit',
        category: measuresCategories.oculomotor.value,
        description: ``,
        versions: [
            {
                number: 501,
                stage: 1
            }
        ],
    },
    64: {
        name: 'memory',
        label: 'Memory',
        category: measuresCategories.cognitive.value,
        showDetails: true,
        description: `The Memory Tests measures working memory and delayed recall. Working memory is an evaluation of information processing and short term storage and recall. Delayed recall measures the storage and retrieval of information after a distraction period.`,
        advertiseMeasure: true
    },
    65: {
        name: 'multipleObjectTracking',
        label: 'Multiple Object Tracking',
        category: measuresCategories.oculomotor.value,
        description: `Measures a patient's attention and visual processing.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    66: {
        name: 'singleLegSquatL',
        label: 'Single Leg Squat Left',
        category: measuresCategories.functional.value,
        description: `An assessment of dynamic functional flexibility, core strength, balance, and neuromuscular control.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    67: {
        name: 'singleLegSquatR',
        label: 'Single Leg Squat Right',
        category: measuresCategories.functional.value,
        description: `An assessment of dynamic functional flexibility, core strength, balance, and neuromuscular control.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    68: {
        name: 'shoulderWidthSquat',
        label: 'Shoulder Width Squat',
        category: measuresCategories.functional.value,
        description: `An assessment of dynamic functional flexibility, core strength, balance, and neuromuscular control.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    69: {
        name: 'neuromotorTriangle',
        label: 'Neuromotor Triangle',
        category: measuresCategories.neuromotor.value,
        description: `An assessment of neuromotor control.`,
        versions: [
            {
                number: 501,
                stage: 1
            }
        ],
    },
    70: {
        name: 'neuromotorSquare',
        label: 'Neuromotor Square',
        category: measuresCategories.neuromotor.value,
        description: `An assessment of neuromotor control.`,
        versions: [
            {
                number: 501,
                stage: 1
            }
        ],
    },
    71: {
        name: 'neuromotorCircle',
        label: 'Neuromotor Circle',
        category: measuresCategories.neuromotor.value,
        description: `An assessment of neuromotor control.`,
        versions: [
            {
                number: 501,
                stage: 1
            }
        ],
        
    },
    72: {
        name: 'numberCounting',
        label: 'Number Counting 15',
        category: measuresCategories.cognitive.value,
        description: `Measures a patient's attention, visual processing speed, and executive functioning.`,
        versions: [
            {
                number: 501,
                stage: 1
            }
        ],
    },
    73: {
        name: 'organizationSurvey',
        label: 'Organization Survey',
        category: measuresCategories.survey.value,
        isNotChartable: true,
        description: ``,
        hasNoBaseline: true
    },
    74: {
        name: 'numberCounting20',
        label: 'Reverse Number Counting',
        category: measuresCategories.cognitive.value,
        description: `Measures a patient's attention, visual processing speed, and executive functioning.`,
        versions: [
            {
                number: 501,
                stage: 3
            }
        ],
        showTimeScores: true,
        timeScale: timeScales.seconds,
        advertiseMeasure: true
    },
    75: {
        name: 'numberCounting24',
        label: 'Number Counting 24',
        category: measuresCategories.cognitive.value,
        description: `Measures a patient's attention, visual processing speed, and executive functioning.`,
        versions: [
            {
                number: 501,
                stage: 1
            }
        ],
    },
    76: {
        name: 'cuedStroop',
        label: 'Cued Stroop - Non Touch',
        category: measuresCategories.cognitive.value,
        description: `Measures a patient's attention, response inhibition and reaction time.`,
        isInactive: true
    },
    77: {
        name: 'cuedStroopTouch',
        label: 'Cued Stroop',
        category: measuresCategories.cognitive.value,
        showDetails: true,
        description: `Measures a patient's attention, response inhibition and reaction time.`,
        versions: [
            {
                number: 501,
                stage: 3
            }
        ],
        advertiseMeasure: true
    },
    10004: {
        name: 'cuedStroopImpairment',
        label: 'Cued Stroop - Impairment',
        category: measuresCategories.cognitive.value,
        showDetails: true,
        description: `Measures a patient's attention, response inhibition and reaction time.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
        showTimeScores: true,
        timeScale: timeScales.seconds
    },
    78: {
        name: 'cobalt',
        label: 'VMS',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `An assessment of balance and control during a dynamic vestibular challenge.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
        
        
    },
    79: {
        name: 'sitToStand',
        label: 'CDC 30-Second Chair Stand',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `An assessment of dynamic functional flexibility, core strength, balance, and neuromuscular control.`,
        versions: [
            {
                number: 501,
                stage: 3
            }
        ],
        advertiseMeasure: true
    },
    80: {
        name: 'cobaltArmsExtendedFoam',
        label: 'VMS (Foam)',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `An assessment of balance and control during a dynamic vestibular challenge.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    81: {
        name: 'cobaltHeadRotation',
        label: 'Active Head Rotation (EC)',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `An assessment of balance and control during a dynamic vestibular challenge.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    82: {
        name: 'cobaltHeadRotation',
        label: 'Active Head Rotation (EC/F)',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `An assessment of balance and control during a dynamic vestibular challenge.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    83: {
        name: 'timedUpAndGo',
        label: 'Timed Up And Go',
        category: measuresCategories.functional.value,
        showDetails: false,
        description: `RESEARCH ONLY - timed up and go test`
    },
    84: {
        name: 'tandemGait',
        label: 'Tandem Gait',
        category: measuresCategories.functional.value,
        showDetails: false,
        description: `RESEARCH ONLY - tandem gait test`
    },
    85: {
        name: 'memoryUpdated',
        label: 'Memory (Updated)',
        category: measuresCategories.cognitive.value,
        showDetails: true,
        description: `The Memory Tests measures working memory and delayed recall. Working memory is an evaluation of information processing and short term storage and recall. Delayed recall measures the storage and retrieval of information after a distraction period.`
    },
    86: {
        name: 'inspectionTimeUpdated',
        label: 'Inspection Time (Updated)',
        category: measuresCategories.cognitive.value,
        showTimeScores: true,
        description: `Measures a patient's visual processing speed - a foundational component of cognition.`
    },
    87: {
        name: 'tandemEyesClosedHeadTurning',
        label: 'Tandem Eyes Closed Head Turning',
        shortLabel: 'Tandem (EC/HT)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    88: {
        name: 'feetTogetherEyesOpenHeadFlexion',
        label: 'Feet Together Eyes Open Flexion',
        shortLabel: 'Feet Together (EO/Flexion)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    89: {
        name: 'feetTogetherEyesOpenHeadExtension',
        label: 'Feet Together Eyes Open Extension',
        shortLabel: 'Feet Together (EO/Extension)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    90: {
        name: 'feetTogetherEyesOpenHeadRightLateralFlexion',
        label: 'Feet Together Eyes Open Right Lateral Flexion',
        shortLabel: 'Feet Together (EO/Right Lateral Flexion)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    91: {
        name: 'feetTogetherEyesOpenHeadLeftLateralFlexion',
        label: 'Feet Together Eyes Open Left Lateral Flexion',
        shortLabel: 'Feet Together (EO/Left Lateral Flexion)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    92: {
        name: 'feetTogetherEyesOpenHeadRightRotation',
        label: 'Feet Together Eyes Open Right Rotation',
        shortLabel: 'Feet Together (EO/Right Rotation)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    93: {
        name: 'feetTogetherEyesOpenHeadLeftRotation',
        label: 'Feet Together Eyes Open Left Rotation',
        shortLabel: 'Feet Together (EO/Left Rotation)',
        category: measuresCategories.balance.value,
        description: generalBalanceDescription
    },
    94: {
        name: 'vomsBaselineSymptoms',
        label: 'VOMS Baseline Symptoms',
        shortLabel: 'Baseline Symptoms',
        category: measuresCategories.symptoms.value,
        description: 'VOMS Baseline Symptoms'
    },
    95: {
        name: 'vomsSmoothPursuits',
        label: 'VOMS Smooth Pursuits',
        shortLabel: 'Smooth Pursuits',
        category: measuresCategories.symptoms.value,
        description: 'VOMS Smooth Pursuits'
    },
    96: {
        name: 'vomsSaccadesHorizontal',
        label: 'VOMS Saccades - Horizontal',
        shortLabel: 'Saccades - Horizontal',
        category: measuresCategories.symptoms.value,
        description: 'VOMS Saccades - Horizontal'
    },
    97: {
        name: 'vomsBaselineVertical',
        label: 'VOMS Saccades - Vertical',
        shortLabel: 'Saccades - Vertical',
        category: measuresCategories.symptoms.value,
        description: 'VOMS Saccades - Vertical'
    },
    98: {
        name: 'vomsConvergence',
        label: 'VOMS Convergence (Near Point)',
        shortLabel: 'Convergence (Near Point)',
        category: measuresCategories.symptoms.value,
        description: 'VOMS Convergence (Near)'
    },
    99: {
        name: 'vomsVorHorizontal',
        label: 'VOMS VOR - Horizontal',
        shortLabel: 'VOR - Horizontal',
        category: measuresCategories.symptoms.value,
        description: 'VOMS VOR - Horizontal'
    },
    100: {
        name: 'vomsVorVertical',
        label: 'VOMS VOR - Vertical',
        shortLabel: 'VOR - Vertical',
        category: measuresCategories.symptoms.value,
        description: 'VOMS VOR - Vertical'
    },
    101: {
        name: 'vomsVisualMotionSensitivity',
        label: 'VOMS Visual Motion Sensitivity',
        shortLabel: 'Visual Motion Sensitivity',
        category: measuresCategories.symptoms.value,
        description: 'VOMS Visual Motion Sensitivity'
    },
    102: {
        name: 'cobaltArmsExtended2',
        label: 'Active Rotation - Arms Extended (DO NOT USE)',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `An assessment of balance and control during a dynamic vestibular challenge.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    103: {
        name: 'cobaltArmsExtendedFoam2',
        label: 'Active Rotation - Arms Extended (Foam) (DO NOT USE)',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `An assessment of balance and control during a dynamic vestibular challenge.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    111: {
        name: 'tandemGaitDualTask',
        label: 'Tandem Gait Dual Task',
        category: measuresCategories.functional.value,
        showDetails: false,
        description: `RESEARCH ONLY - tandem gait dual task test`
    },
    
    
    
    
    10006: {
        name: 'flanker',
        label: 'Flanker',
        category: measuresCategories.cognitive.value,
        showDetails: true,
        description: `I need a definition.`,
        versions: [
            {
                number: 501,
                stage: 2
            }
        ],
    },
    // 10007: {
    //     name: 'tandemGait',
    //     label: 'Tandem Gait',
    //     category: measuresCategories.functional.value,
    //     showDetails: true,
    //     description: `I need a definition.`,
    //     versions: [
    //         {
    //             number: 501,
    //             stage: 2
    //         }
    //     ],
    // },
    10008: {
        name: 'voms',
        label: 'VOMS',
        category: measuresCategories.survey.value,
        showDetails: true,
        description: `I need a definition.`,
        versions: [
            {
                number: 501,
                stage: 3
            }
        ],
    },
    // 10009: {
    //     name: 'vomsSmoothPursuit',
    //     label: 'VOMS Smooth Pursuit',
    //     category: measuresCategories.survey.value,
    //     showDetails: true,
    //     description: `I need a definition.`,
    //     versions: [
    //         {
    //             number: 501,
    //             stage: 3
    //         }
    //     ],
    // },
    // 10010: {
    //     name: 'vomsSaccadesHorizontal',
    //     label: 'VOMS Saccades Horizontal',
    //     category: measuresCategories.survey.value,
    //     showDetails: true,
    //     description: `I need a definition.`,
    //     versions: [
    //         {
    //             number: 501,
    //             stage: 3
    //         }
    //     ],
    // },
    // 10011: {
    //     name: 'vomsSaccadesVertical',
    //     label: 'VOMS Saccades Vertical',
    //     category: measuresCategories.survey.value,
    //     showDetails: true,
    //     description: `I need a definition.`,
    //     versions: [
    //         {
    //             number: 501,
    //             stage: 3
    //         }
    //     ],
    // },
    // 10012: {
    //     name: 'vomsConvergence',
    //     label: 'VOMS Convergence',
    //     category: measuresCategories.survey.value,
    //     showDetails: true,
    //     description: `I need a definition.`,
    //     versions: [
    //         {
    //             number: 501,
    //             stage: 3
    //         }
    //     ],
    // },
    // 10013: {
    //     name: 'vomsVorHorizontal',
    //     label: 'VOMS - VOR Horizontal',
    //     category: measuresCategories.survey.value,
    //     showDetails: true,
    //     description: `I need a definition.`,
    //     versions: [
    //         {
    //             number: 501,
    //             stage: 3
    //         }
    //     ],
    // },
    // 10014: {
    //     name: 'vomsVorHorizontal',
    //     label: 'VOMS - VOR Vertical',
    //     category: measuresCategories.survey.value,
    //     showDetails: true,
    //     description: `I need a definition.`,
    //     versions: [
    //         {
    //             number: 501,
    //             stage: 3
    //         }
    //     ],
    // },
    // 10015: {
    //     name: 'vomsVMS',
    //     label: 'VOMS VMS',
    //     category: measuresCategories.survey.value,
    //     showDetails: true,
    //     description: `I need a definition.`,
    //     versions: [
    //         {
    //             number: 501,
    //             stage: 3
    //         }
    //     ],
    // },

    // aggregate test batteries

    1001: {
        name: 'mBess',
        label: 'mBESS',
        category: measuresCategories.balance.value,
        showDetails: true,
        description: `The mBESS (Modified Balance Error Scoring System) Protocol consists of 5 balance tests which measure postural stability.`,
        isProtocol: true,
        protocolTestTypeIds: [7,2,11,1,10],
        advertiseMeasure: true
    },
    1002: {
        name: 'motionReaction',
        label: 'Reaction Time',
        // pdfLabel: 'Reaction Time',
        category: measuresCategories.cognitive.value,
        showTimeScores: true,
        description: `Measures a patient's visual processing and neuromotor function.`,
        advertiseMeasure: true
    },
    1003: {
        name: 'impulseControl',
        label: 'Impulse Control',
        category: measuresCategories.cognitive.value,
        showTimeScores: true,
        description: `Measures a patient's inhibitory control and basic executive function when compared to reaction time.`,
        advertiseMeasure: true
    },
    1004: {
        name: 'inspectionTime',
        label: 'Inspection Time',
        category: measuresCategories.cognitive.value,
        showTimeScores: true,
        description: `Measures a patient's visual processing speed - a foundational component of cognition.`,
        advertiseMeasure: true
    },

    // other test batteries with non-standard Interval or NumPoints
    
    1005: {
        name: 'banner',
        label: 'Banner',
        category: measuresCategories.balance.value,
        description: ``,
        protocolTestTypeIds: [16],
        isProtocol: true
    },
    1006: {
        name: 'fourStageBalance',
        label: 'CDC 4-Stage Balance',
        category: measuresCategories.balance.value,
        description: `A balance protocol designed to assess static postural stability through progressively more challenging stances.`,
        isProtocol: true,
        protocolTestTypeIds: [16, 23, 37, 49],
        advertiseMeasure: true
    },
    1007: {
        name: 'mctsib',
        label: 'mCTSIB',
        category: measuresCategories.balance.value,
        description: `A balance protocol designed to assess static postural stabilty through progressively more challenging stances under various sensory conditions.`,
        isProtocol: true,
        advertiseMeasure: true,
        protocolTestTypeIds: [16,7,18,19],
    },
    1008: {
        name: 'mBess30Hz',
        label: 'mBESS (30Hz)',
        category: measuresCategories.balance.value,
        description: `The mBESS (Modified Balance Error Scoring System) Protocol consists of 5 balance test types measuring static postural stability.`,
        isProtocol: true,
        protocolTestTypeIds: [7,2,11,10,1]
    },
    1009: {
        name: 'wilkerson',
        label: 'Wilkerson',
        category: measuresCategories.balance.value,
        description: ``,
        isProtocol: true,
        protocolTestTypeIds: [7,7,7, 10, 1],
    },
    1010: {
        name: 'bWellShoulderWidth',
        label: 'BWell Shoulder Width',
        category: measuresCategories.balance.value,
        description: ``,
        isProtocol: true,
        protocolTestTypeIds: [12,13,14,15],
        
    },
    1011: {
        name: 'bWellFeetTogether',
        label: 'BWell Feet Together',
        category: measuresCategories.balance.value,
        description: ``,
        isProtocol: true,
        protocolTestTypeIds: [16,7,18,19],
    },
    1012: {
        name: 'mBess100Hz',
        label: 'mBESS (100Hz)',
        category: measuresCategories.balance.value,
        description: `The mBESS (Modified Balance Error Scoring System) Protocol consists of 5 balance test types measuring static postural stability.`,
        isProtocol: true,
        protocolTestTypeIds: [7,2,11,1,10]
    },
    1013: {
        name: 'onFieldAssessment',
        label: 'On Field Assessment',
        category: measuresCategories.survey.value,
        isNotChartable: true,
        description: ``,
        hasNoBaseline: true,
        advertiseMeasure: true
    },
    1014: {
        name: 'covid',
        label: 'COVID-19',
        category: measuresCategories.covid.value,
        description: ``,
        excludeFromHistoryTable: true,
        noBaselineChart: true,
        hasNoBaseline: true,
        hasNoTrendChart: true,
        isNotChartable: true,
    },
    1016: {
        name: 'phq9',
        label: 'PHQ-9',
        category: measuresCategories.survey.value,
        isNotChartable: true,
        description: ``,
        hasNoBaseline: true,
        advertiseMeasure: true
    },
    1017: {
        name: 'gad7',
        label: 'GAD-7',
        category: measuresCategories.survey.value,
        isNotChartable: true,
        description: ``,
        hasNoBaseline: true,
        advertiseMeasure: true
    },
    1018: {
        name: 'surfSideAssessment',
        label: 'Surf Side Assessment',
        category: measuresCategories.survey.value,
        isNotChartable: true,
        description: ``,
        hasNoBaseline: true,
        advertiseMeasure: false
    },
    1019: {
        name: 'cervicalSpineBalance',
        label: 'Cervical Spine',
        category: measuresCategories.balance.value,
        showDetails: true,
        description: `The Cervical Spine Protocol consists of 7 balance tests which measure postural stability.`,
        isProtocol: true,
        protocolTestTypeIds: [16,88,89,90,91,92,93],
        advertiseMeasure: true
    },
    1020: {
        name: 'VOMS',
        label: 'VOMS',
        category: measuresCategories.survey.value,
        showDetails: true,
        description: `Vestibular Ocular Motor Screening`,
        isProtocol: true,
        protocolTestTypeIds: [94,95,96,97,98,99,100,101],
        advertiseMeasure: true,
        isNotBaselineable: true,
        singleAssessmentTest: true
    },
    1021: {
        name: 'CHS',
        label: 'CHS',
        category: measuresCategories.survey.value,
        showDetails: true,
        description: `CHS.`,
        advertiseMeasure: false,
        isNotBaselineable: true,
        singleAssessmentTest: true
    },
    1022: {
        name: 'Cobalt',
        label: 'Cobalt',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `Cobalt test with and without foam.`,
        advertiseMeasure: false,
        isNotBaselineable: false
    },
    1023: {
        name: 'CobaltNoFoam',
        label: 'Cobalt No Foam',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `Cobalt test without foam.`,
        advertiseMeasure: false,
        isNotBaselineable: false
    },
    1024: {
        name: 'mBess2',
        label: 'mBess2',
        category: measuresCategories.balance.value,
        showDetails: true,
        description: `mBess test for data collection.`,
        advertiseMeasure: false,
        isNotBaselineable: false
    },
    1025: {
        name: 'mCobalt',
        label: 'mCobalt',
        category: measuresCategories.functional.value,
        showDetails: true,
        description: `Cobalt VOR and VMS tests only.`,
        advertiseMeasure: false,
        isNotBaselineable: false
    },
    1026: {
        name: 'SleepSurvey',
        label: 'Sleep Survey',
        category: measuresCategories.survey.value,
        isNotChartable: true,
        description: ``,
        hasNoBaseline: true,
        advertiseMeasure: true
    },
    1027: {
        name: 'onFieldAssessment2',
        label: 'On Field Assessment (SCAT-6)',
        category: measuresCategories.survey.value,
        isNotChartable: true,
        description: ``,
        hasNoBaseline: true,
        advertiseMeasure: true
    },
    9999: {
        name: 'symptoms',
        label: 'Symptoms',
        category: measuresCategories.symptoms.value,
        description: `An assessment of 22 concussion related symptoms, using a 6-point Likert Scale.`,
        hasNoBaseline: true,
    }
};

const profileFormOptions = {
    yesNoOptions: [
        {
            text: 'Yes',
            value: true
        },
        {
            text: 'No',
            value: false
        }
    ],
    yesNoIdkOptions: [
        {
            text: 'Yes',
            value: 1
        },
        {
            text: 'No',
            value: 2
        },
        {
            text: `I don't know`,
            value: 3
        },

    ],
    languageOptions: [
        
        {
            text: 'English',
            value: 1
        },
        {
            text: 'Spanish',
            value: 2
        },
        {
            text: 'Chinese',
            value: 3
        },
        {
            text: 'Tagalog',
            value: 4
        },
        {
            text: 'Vietnamese',
            value: 5
        },
        {
            text: 'Arabic',
            value: 6
        },
        {
            text: 'French',
            value: 7
        },
        {
            text: 'Korean',
            value: 8
        },
        {
            text: 'Russian',
            value: 9
        },
        {
            text: 'Haitian',
            value: 10
        },
        {
            text: 'German',
            value: 11
        },
        {
            text: 'Other',
            value: 0
        },
    ],
    raceOptions: [
        {
            text: 'White',
            value: 5
        },
        {
            text: 'Black or African American',
            value: 3
        },
        {
            text: 'Hispanic or Latino',
            value: 6
        },
        {
            text: 'Asian',
            value: 2
        },
        {
            text: 'American Indian or Alaska Native',
            value: 1
        },
        
        {
            text: 'Native Hawaiian or Other Pacific Islander',
            value: 4
        },
        {
            text: 'Other',
            value: 0
        },
    ],
    ethnicityOptions: [
        {
            text: 'Hispanic or Latino',
            value: 1
        },
        {
            text: 'Not Hispanic or Latino',
            value: 2
        },
    ],
    sexOptions: [
        {
            text: 'Male',
            value: 'M'
        },
        {
            text: 'Female',
            value: 'F'
        },
        // {
        //     text: 'Intersex',
        //     value: 'I'
        // },

    ],
    genderOptions: [
        {
            text: 'Male',
            value: 1
        },
        {
            text: 'Female',
            value: 2
        },
        {
            text: 'Non-Binary',
            value: 3
        },
        {
            text: 'Prefer not to disclose',
            value: 4
        },
    ],
    feetOptions: [
        {
            text: 0,
            value: 0
        },
        {
            text: 1,
            value: 1
        },
        {
            text: 2,
            value: 2
        },
        {
            text: 3,
            value: 3
        },
        {
            text: 4,
            value: 4
        },
        {
            text: 5,
            value: 5
        },
        {
            text: 6,
            value: 6
        },
        {
            text: 7,
            value: 7
        },
        {
            text: 8,
            value: 8
        },
    ],
    inchesOptions: [
        {
            text: 0,
            value: 0
        },
        {
            text: 1,
            value: 1
        },
        {
            text: 2,
            value: 2
        },
        {
            text: 3,
            value: 3
        },
        {
            text: 4,
            value: 4
        },
        {
            text: 5,
            value: 5
        },
        {
            text: 6,
            value: 6
        },
        {
            text: 7,
            value: 7
        },
        {
            text: 8,
            value: 8
        },
        {
            text: 9,
            value: 9
        },
        {
            text: 10,
            value: 10
        },
        {
            text: 11,
            value: 11
        },

    ],
    graduationYearOptions: getGraduationYearOptions()
    
}

const disclaimer = `Normative data is representative of baseline data collected from Sway Medical Inc. users of various age and sex.  Normative values are intended for reference purposes only and should not be the determining factor in clinical decisions.`

const isPortal = process.env.VUE_APP_IS_PORTAL === "1"


function getGraduationYearOptions() {
    let response = [{text: 'Not Applicable', value: 1}];
    let year = moment().add(10, 'years').year();
    for (let i = 0 ; i < 15; i++) {
        response.push({
            text: year - i,
            value: year - i
        })
    }
    return response
}

const PHQ9Table = {
    rows: [
        {
            minValue: 1,
            maxValue: 4,
            description: `Minimal depression`
        },
        {
            minValue: 5,
            maxValue: 9,
            description: `Mild depression`
        },
        {
            minValue: 10,
            maxValue: 14,
            description: `Moderate depression`
        },
        {
            minValue: 15,
            maxValue: 19,
            description: `Moderate severe depression`
        },
        {
            minValue: 20,
            maxValue: 27,
            description: `Severe depression`
        }],
    surveySubject: "Depression"
}

const GAD7Table = {
    rows: [
        {
            minValue: 0,
            maxValue: 4,
            description: `Minimal anxiety`
        },
        {
            minValue: 5,
            maxValue: 9,
            description: `Mild anxiety`
        },
        {
            minValue: 10,
            maxValue: 14,
            description: `Moderate anxiety`
        },
        {
            minValue: 15,
            maxValue: 21,
            description: `Severe anxiety`
        }],
    surveySubject: "Anxiety"
}

const testTypeOptions = [
    {
        label: 'Screening',
        description: `A screening is a quick test that only requires 1 repetition of each test. Screenings do not include practice trials.`,
        value: 1
    },
    {
        label: 'Assessment',
        description: `<div>An assessment is longer than a screening, requiring 2 or 3 repetitions of each test. Assessments begin with a practice trial before each test. <span style="font-weight: 700">Highly recommended for baseline testing athletes.</span></div>`,
        value: 2
    },

]

let screeningOrAssessmentOptions = [
    {
        label: 'Screening',
        value: 0,
        description: `A screening is a quick test that only requires 1 repetition of each test. Screenings do not include practice trials.`
    },
    {
        label: 'Assessment',
        value: 1,
        description: `<div>An assessment is longer than a screening, requiring 2 or 3 repetitions of each test. Assessments begin with a practice trial before each test. <strong>Highly recommended for baseline testing athletes.</strong></div>`

    }
]

const eventTypes = [
    
    {
        name: 'Suspected Concussion',
        id: 2,
    },
    {
        name: 'Clinically Diagnosed Concussion',
        id: 3,
    },
    {
        name: 'Fall',
        id: 4,
    },
    {
        name: 'Back Injury',
        id: 7,
    },
    {
        name: 'Hip Injury',
        id: 6,
    },
    {
        name: 'Knee Injury',
        id: 9,
    },
    {
        name: 'Foot/Ankle Injury',
        id: 10,
    },
    {
        name: 'Lower Extremity Injury',
        id: 5,
    },
    {
        name: 'Other',
        id: 8,
    },
    {
        name: 'Not Specified',
        id: 1,
    },
]

let organizationBusinessTypeIds = [
    {
        label: 'Other',
        id: 0
    },
    {
        label: 'College or University',
        id: 1
    },
    {
        label: 'High School',
        id: 2
    },
    {
        label: 'Medical',
        id: 3
    },
    {
        label: 'Middle School',
        id: 4
    },
    {
        label: 'Pro Sports',
        id: 5
    },
    {
        label: 'Youth Sports',
        id: 6
    },
    {
        label: 'Hospital',
        id: 7
    },
    {
        label: 'Chiropractor',
        id: 9
    },
    {
        label: 'School District',
        id: 10
    },
    {
        label: 'Physical Therapy',
        id: 11
    },
    {
        label: 'Primary Care',
        id: 12
    },
    {
        label: 'Orthopedic',
        id: 13
    },
    {
        label: 'Neurology',
        id: 14
    },
    {
        label: 'Health System',
        id: 15
    },
    {
        label: 'Military',
        id: 16
    },
    {
        label: 'Other Healthcare Organization',
        id: 17
    },
    {
        label: 'Senior Living',
        id: 18
    },
    {
        label: 'Sports Medicine Clinic',
        id: 19
    },
    {
        label: 'Club Team',
        id: 20
    },
    {
        label: 'Private School',
        id: 21
    },
]

let organizationTypeIds = [
    {
        label: 'Other',
        id: 1
    },
    {
        label: 'Athletic',
        id: 2
    },
    {
        label: 'Medical',
        id: 3
    },
    {
        label: 'Employee Safety',
        id: 5
    },
]

let billMethodIds = [
    {
        label: 'Credit Card',
        id: 1
    },
    {
        label: 'Manual',
        id: 2
    },
    {
        label: 'Free',
        id: 3
    },
    {
        label: 'PO',
        id: 4
    }
]

let countryOptions = [
    {text: "United States", abbr: "US"},
    {text: "Canada", abbr: "CA"},
    {text: "Australia", abbr: "AU" },
    {text: "Austria", abbr: "AT"},
    {text: "Belgium", abbr: "BE"},
    {text: "Bulgaria", abbr: "BG"},
    {text: "Croatia", abbr: "HR"},
    {text: "Republic of Cyprus", abbr: "CY"},
    {text: "Czech Republic", abbr: "CZ"},
    {text: "Denmark", abbr: "DK"},
    {text: "Estonia", abbr: "EE"},
    {text: "Finland", abbr: "FI"},
    {text: "France", abbr: "FR"},
    {text: "Germany", abbr: "DE"},
    {text: "Greece", abbr: "GR"},
    {text: "Hungary", abbr: "HU"},
    {text: "Ireland", abbr: "IE"},
    {text: "Italy", abbr: "IT"},
    {text: "Latvia", abbr: "LV"},
    {text: "Lithuania", abbr: "LT"},
    {text: "Luxembourg", abbr: "LU"},
    {text: "Malta", abbr: "MT"},
    {text: "Netherlands", abbr: "NL"},
    {text: "Poland", abbr: "PL"},
    {text: "Portugal", abbr: "PT"},
    {text: "Romania", abbr: "RO"},
    {text: "Slovakia", abbr: "SK"},
    {text: "Slovenia", abbr: "SI"},
    {text: "Spain", abbr: "ES"},
    {text: "Sweden", abbr: "SE"},
    {text: "United Kingdom", abbr: "GB"}
]

let clinicalPackageTiers = [
    {
        text: 'Clinical Basic',
        value: 3
    },
    {
        text: 'Clinical Premium',
        value: 4
    },
    {
        text: 'Clinical Enterprise',
        value: 5
    },
    {
        text: 'Clinical Balance Only',
        value: 7
    },
    {
        text: 'Clinical All Access',
        value: 8
    }
]

let sportsPackageTiers = [
    {
        text: 'Sports',
        value: 1
    },
    {
        text: 'Sports+',
        value: 2
    },
    {
        text: 'Covid-19 Only',
        value: 6
    }
]

let packageTiers = sportsPackageTiers.concat(clinicalPackageTiers);

let subscriptionColors = {
    sports: {
        r: 76,
        g: 175,
        b: 80,
        chip: 'green'
    },
    clinical: {
        r: 156,
        g: 39,
        b: 176,
        chip: 'purple'
    },
    free: {
        r: 255,
        g: 235,
        b: 59,
        chip: 'pink'
    },
    manual: {
        r: 121,
        g: 85,
        b: 72,
        chip: 'brown'
    },
    trial: {
        r: 255,
        g: 152,
        b: 0,
        chip: 'orange'
    }
    
}

let billingCycleTypes = [
    {
        text: 'Monthly',
        value: 2
    },
    {
        text: 'Quarterly',
        value: 3
    },
    {
        text: 'Annual',
        value: 1
    }
]

let invoiceApprovalStatuses = [
    { 
        name: "unknown",
        text: "🟠 Unknown", 
        value: 0,
        tagColor: "orange",
        description: "The approval status is unknown, meaning automated emails will not be sent."
    },
    { 
        name: "unapproved",
        text: "❌ Unapproved - Auto", 
        value: 1,
        tagColor: "red",
        description: "This invoice was created automatically but has not been approved by Sway personnel."
    },
    { 
        name: "approvedAuto",
        text: "✅ Approved - Auto", 
        value: 2 ,
        tagColor: "green",
        description: "This invoice was created automatically and has been reviewed & approved by Sway personnel."
    },
    { 
        name: "approvedManual",
        text: "✅ Approved - Manual", 
        value: 3 ,
        tagColor: "green",
        description: "This invoice was created manually and is therefore automatically approved."
    }
]

let invoiceApprovalStatusesAsObject = {};
invoiceApprovalStatuses.forEach(x => {
    invoiceApprovalStatusesAsObject[x.name] = x;
})

let superAdminSymbol = '👨‍🚀'

let orgConnectStatusSelectList = [
    {
        text: 'Requested',
        value: 1,
        chipColor: 'blue',
        emoji: '🔵',
        color: '#2196f3'
    },
    {
        text: 'Approved',
        value: 2,
        chipColor: 'green',
        emoji: '✅',
        color: '#4caf50'
        
    },
    {
        text: 'Declined',
        value: 3,
        chipColor: 'red',
        emoji: '❌',
        color: '#f44336'
    },
    {
        text: 'Expired',
        value: 4,
        chipColor: 'red',
        emoji: '❌',
        color: '#f44336'
    }
]

let orgConnectTypeSelectList = [
    {
        text: 'Remote Care Provider',
        value: 2,
        color: '#e91e63',
        emoji: '🩷'
    },
    {
        text: 'Connected Organization',
        value: 3,
        color: '#2196f3',
        emoji: '🔵'
    },
    {
        text: 'Enterprise Admin',
        value: 4,
        color: '#ff9800',
        emoji: '🟠'
    }
]

let profileCreditUsageTypes = [
    {
        value: 1, // saved to the database every time there's an invoice created (Clinical Only)
        text: 'Actual Usage',
        chipColor: 'blue'
    },
    {
        value: 2,
        text: 'Purchased Upfront',
        chipColor: 'green'
    },
    {
        value: 3, // Manually create a negative credit usage record
        text: 'Credits Removed',
        chipColor: 'red'
    },
    {
        value: 4,
        text: 'Purchased in Arrears',
        chipColor: 'green'
    },
    {
        value: 5, // Same as #1 but hasn't been saved to the database. For Sports its just usage, for clinical its the stage before #1 (when it gets saved to the database)
        text: 'Profile Credits Used (Unsaved)',
        chipColor: 'purple'
    },
]

let ehrPartnerOptions = [
    { text: "ATS", value: 1 },
    { text: "PyraMed", value: 2 },
    { text: "Healthy Roster", value: 4 },
    { text: "Privit", value: 5 },
    { text: "Telegra", value: 6 },
    { text: "SportsWare Online", value: 7 },
    { text: "Custom", value: 3 },
    { text: "Other", value: 0 },
]

let pdfSyncStatusOptions = [ //extend this to 11 options
    {
        text: 'Ready',
        displayText: 'Pending',
        value: 1,
        chipColor: 'blue'
    },
    {
        text: 'Working',
        displayText: 'Pending',
        value: 2,
        chipColor: 'blue'
    },
    {
        text: 'Completed',
        displayText: 'Successful',
        value: 3,
        chipColor: 'green'
    },
    {
        text: 'Failed',
        displayText: 'Pending',
        value: 4,
        chipColor: 'blue'
    },
    {
        text: 'Failed Abandoned',
        displayText: 'Failed',
        value: 5,
        chipColor: 'red'
    },
    {
        text: 'Manual Abandoned',
        displayText: 'Abandoned',
        value: 6,
        chipColor: 'gray'
    },
    {
        text: 'Expired',
        displayText: 'Expired',
        value: 7,
        chipColor: 'gray'
    },
    {
        text: 'No Profile Match',
        displayText: 'Failed (No Profile Match)',
        value: 8,
        chipColor: 'red'
    },
    {
        text: 'Delayed',
        displayText: 'Pending',
        value: 9,
        chipColor: 'blue'
    },
    {
        text: 'Multiple Profile Match',
        displayText: 'Failed (No Profile Match)',
        value: 10,
        chipColor: 'red'
    },
    {
        text: 'Manual Upload',
        displayText: 'Successful',
        value: 11,
        chipColor: 'green'
    }
]

const professionalTypes = [
    {
        text: 'Athletic Trainer',
        value: 1
    },
    {
        text: 'Exercise Physiologist',
        value: 2
    },
    {
        text: 'Nurse',
        value: 3
    },
    {
        text: 'Occupational Therapist',
        value: 4
    },
    {
        text: 'Physical Therapist',
        value: 5
    },
    {
        text: 'Physical Therapist Assistant',
        value: 10
    },
    {
        text: 'Physician',
        value: 6
    },
    {
        text: 'Chiropractor',
        value: 8
    },
    {
        text: 'Neuropsychologist',
        value: 9
    },
    {
        text: 'Other',
        value: 7
    },

];



export {
    eventTypes,
    testTypeOptions,
    GAD7Table,
    PHQ9Table,
    isPortal,
    disclaimer,
    profileFormOptions,
    allMeasuresFromConstants,
    measuresCategoriesById,
    measuresCategories,
    timezone,
    organizationBusinessTypeIds,
    organizationTypeIds,
    billMethodIds,
    screeningOrAssessmentOptions,
    countryOptions,
    
    clinicalPackageTiers,
    sportsPackageTiers,
    packageTiers,
    subscriptionColors,
    
    billingCycleTypes,
    superAdminSymbol,
    invoiceApprovalStatuses,
    invoiceApprovalStatusesAsObject,
    
    orgConnectStatusSelectList,
    orgConnectTypeSelectList,

    profileCreditUsageTypes,
    ehrPartnerOptions,
    pdfSyncStatusOptions,
    professionalTypes
}
