<template>
    <v-dialog
        v-model="open"
        :width="width"
        :max-width="maxWidth"
        @click:outside="$emit('close')"
        @keydown.esc="$emit('close')"
        style="overflow-x: hidden; background-color: pink;"
    >
        <validation-observer
            ref="observer"
            v-slot="{ invalid, validate }"
        >
            
            <v-form @submit.prevent="handleSubmit" style="background-color: red; border-top: 8px solid var(--v-primary-base)">
                <v-card style="overflow-y: scroll; overflow-x: hidden; max-height: 80vh;">
                    <v-card-title style=" padding-top: 15px; text-align: center">
                        <h2 style="font-weight: bold; margin-top: 15px; ">{{ title }}</h2>
                        <v-spacer></v-spacer>
                        <v-icon  @click="closeDialog" color="accent">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text  class="text--text">
                        <!--        <slot name="form-content"></slot>-->
                        <v-container >

                            <slot name="form-content"></slot>


                        </v-container>
                        <!--          <small>*indicates required field</small>-->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
<!--                        <app-btn-->
<!--                            @click="validate"-->
<!--                            >Validate-->
<!--                            -->
<!--                        </app-btn>-->
                        <app-btn
                            :color="invalid ? '#757575' : 'primary'"
                            type="submit"

                            class="mb-4"
                            :disabled="invalid"
                            :loading="saving"
                        >
                            {{ submitButtonTitle }} 
                        </app-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-form>
        </validation-observer>
    </v-dialog>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

export default {
    props: {
        open: {
            required: true,
            type: Boolean
        },
        title: {
            required: true,
            type: String
        },
        width: {
            required: false,
            type: Number,
            default: 500
        },
        maxWidth: {
            required: false,
            type: Number,
        },
        onSubmit: {
            type: Function,
        },
        submitButtonTitle: {
            type: String,
            default: 'Save'
        },
        saving: {
            type: Boolean
        },
    },
    name: "DialogForm",
    components: {
        ValidationObserver
    },
    data() {
        return {
            lastName: ''
        }
    },
    methods: {  
        handleSubmit() {
            this.$emit('submit');
            // this.closeDialog()
        },
        closeDialog() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.v-card {
    border-radius: 0!important;
}
</style>