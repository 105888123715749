<template>
  <div class="avoid-page-break">

      <TestRow class="avoid-page-break" id="1002" :profileTest="profileTest" name="Reaction Time"/>
      <TestRow class="avoid-page-break" id="1003" :profileTest="profileTest" name="Impulse Control"/>
      <TestRow class="avoid-page-break" id="1004" :profileTest="profileTest" name="Inspection Time"/>
  </div>
    
  
  
</template>

<script>
import ProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
import TestRow from "./TestRow";

export default {
  props: {
    profileTest: ProfileTest
  },
  name: "CognitiveTest",
  components: {
    TestRow
  },
  
  
}
</script>

<style scoped>


</style>