<template>
    <div>
        <v-row style="display: flex; align-items: center" class="mt-2">
            <v-col cols="3">
                Card Number
            </v-col>
            <v-col cols="9">
                <div
                    id="card-number-element"
                    class="element"
                    ref="cardNumber"
                ></div>
            </v-col>
        </v-row>
        <v-row style="display: flex; align-items: center" class="mt-2">
            <v-col cols="3">
                Card Expiration Date
            </v-col>
            <v-col cols="9">
                <div class="element" id="card-expiry-element"></div>
            </v-col>
        </v-row>
        <v-row style="display: flex; align-items: center" class="mt-2">
            <v-col cols="3">
                Card CVC
            </v-col>
            <v-col cols="9">
                <div class="element" id="card-cvc-element"></div>
            </v-col>
        </v-row>
        <v-row style="display: flex; align-items: center" class="mt-2">
            <v-col cols="3">
                Enroll in Autopay
            </v-col>
            <v-col cols="9">
                <app-form-checkbox
                    checkboxLeftSide
                    v-model="enrollInAutopay"
                ></app-form-checkbox>
            </v-col>
        </v-row>
        <v-row class="mt-2">
            <v-col cols="12" style="display: flex; justify-content: center">
                <app-btn @click="saveCreditCard" :loading="savingCard" color="primary">Save Card</app-btn>
                <app-btn outlined class="ml-2" @click="$emit('cancel')" color="primary">Cancel</app-btn>
            </v-col>
            
        </v-row>
        <br /><br />
        <v-row>
            By clicking the below "Save Card" button, you confirm that you
            are authorized to provide this information and authorize Sway
            to keep your card on file to charge future renewal invoices.
        </v-row>
    </div>
</template>

<script>
import Checkout from "@/components/Checkout";
import {addEditCard} from '@/services/billing.service'

// find info for stripe API here
// https://stripe.com/docs/js/element/mount

export default {
    name: "Credit Card Form",
    components: {
        Checkout
    },
    data() {
        return {
            card: null,
            cardNumber: null,
            cardExpiry: null,
            cardCvc: null,
            savingCard: false,
            enrollInAutopay: false
        }
    },
    computed: {
    },
    mounted() {
        let style = {
            base: {
                iconColor: '#ff0000',
                backgroundColor: '#FFF',
                padding: 30,
                color: '#000',
                // fontWeight: 500,
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                // fontSize: '14px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#757575',
                },
                '::placeholder': {
                    color: '#757575',
                },
            },
            invalid: {
                iconColor: '#ff0000',
                color: '#ff0000',
            },
        }

        let stripeKey = process.env.STRIPE_KEY;
        let env = process.env.NODE_ENV;
        let something = process.env;
        this.stripe = Stripe(env === 'development' ? 'pk_test_bwNgLYNXOqtB1wd0BtmRI1yR' : 'pk_live_8MGVcdKiDlPrBlXHTzV8qR2G');
        this.elements = this.stripe.elements();
        // let cardElement = elements.create('card');

        // this.card = this.elements.create('card', { style }); 

        let cardNumber = this.elements.create('cardNumber', { style });

        cardNumber.on('change', (x) => {
            // comsole.log(x)
        });
        this.cardNumber = cardNumber;

        this.cardExpiry = this.elements.create('cardExpiry', { style });
        this.cardCvc = this.elements.create('cardCvc', { style });


        // cardElement.mount('#card-element');
        // this.card.mount('#card-element');

        this.cardNumber.mount('#card-number-element');
        this.cardExpiry.mount('#card-expiry-element');
        this.cardCvc.mount('#card-cvc-element');
    },
    methods: {
        async saveCreditCard() {
            this.savingCard = true;
            // let stripe = Stripe('pk_test_bwNgLYNXOqtB1wd0BtmRI1yR');
            const {elements} = this;

            // let card = elements.getElement('card')

            // let cardNumber = this.$refs.cardNumber;

            let cardNumber = elements.getElement('cardNumber');
            let cardExpiry = elements.getElement('cardExpiry');
            let cardCvc = elements.getElement('cardCvc');

            // https://stripe.com/docs/api/payment_methods/object;

            let cardElement = {
                ...cardNumber,
                ...cardExpiry,
                ...cardCvc
            }

            let result = await this.stripe.createToken(cardElement);

            // let response = await this.stripe
            //     .createPaymentMethod({
            //         type: 'card',
            //         card: {
            //             ...cardNumber,
            //             ...cardExpiry,
            //             ...cardCvc
            //         },
            //
            //         billing_details: {
            //             name: 'Jenny Rosen',
            //         },
            //     });

            // let cardId = response.paymentMethod.id;
            console.log(result)
            const response2 = await addEditCard({cardToken: result.token.id, isAutoPayEnrolled: this.enrollInAutopay});
            
            if (!response2.errors) {
                this.$emit('cardSaved')
            }

            this.savingCard = false;
        }
    }
}
</script>

<style scoped>
.element {
    background-color: white;
    padding: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-bottom: 5px
}
</style>