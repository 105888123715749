import colors from 'vuetify/lib/util/colors'

export const swayThemes = {
    name: 'sway',
    light: {
        primary: {
            base: '#34d3fe',
            darken1: '#6AB6D0'
        },
        secondary: '#82CBC3',
        // secondary: '#68cb46',
        tertiaryBrand: '#F3D9DA',
        // dark: '#102A43',
        dark: '#333333',
        success: '#4CAF50',
        // text: '#2E343B',
        text: '#424242',
        accent: '#5C97BF',
        light: '#f5f5f5',
        // background: colors.grey.lighten5,
        background: '#fcfcfc',
        swayBlue: '#34d3fe',
        swayOrange: {
            base: '#ffba79',
            darken1: '#FFA12A'
        },
        border: '#d8d8d8',
        event: '#F0923A'
    },
    dark: {
        primary: colors.blue,
        secondary: colors.green,
        tertiaryBrand: '#F3D9DA',
        dark: '#102A43',
        success: '#83C9A0',
        text: '#5C738A',
        accent: '#E2EAF1',
        light: '#f5f5f5',
        background: colors.grey.darken4,
        border: '#d9d9d9'
    }
}

export const readyCheckThemes = {
    name: 'readyCheck',
    light: {
        // primary: '#65CDBE',
        primary: '#65CDBE',
        secondary: '#4fa1a5',
        tertiaryBrand: '#F3D9DA',
        dark: '#102A43',
        // success: '#83C9A0',
        text: '#212121',
        accent: '#E2EAF1',
        light: '#f5f5f5',
        background: colors.grey.lighten5,
        border: '#d9d9d9'
    },
    dark: {
        primary: '#65CDBE',
        secondary: colors.blue,
        tertiaryBrand: '#F3D9DA',
        dark: '#102A43',
        success: '#83C9A0',
        text: '#5C738A',
        accent: '#E2EAF1',
        light: '#f5f5f5',
        background: colors.grey.darken3,
        border: '#d9d9d9'
    }
}
