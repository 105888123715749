import Subscription from "@/js/shared/classes/Subscription/Subscription";
import moment from "moment";
import {formatMoney, formatNumber, getPackageTierById} from "@/js/shared/helper";
import {subscriptionColors} from "@/js/shared/constants";

export default class ClinicalSubscription extends Subscription {
    constructor(data) {
        super(data)
    }

    getSubscriptionForm = () => {
        const {
            subscriptionStartDate,
            subscriptionEndDate,
            isDemo,
            systemFee,
            isManualBilling,
            profilePrice,
            billingCycle,
            doNotRenew,
            isFree,
            productSubscriptionId,
            profileCount,
            packageTier,
            manualEstimatedTotal,
            
            updateFutureSubscriptions
        } = this.data;

        return {
            subscriptionStartDate,
            subscriptionEndDate,
            billingCycle,
            profileCount,
            profilePrice,
            packageTier,
            systemFee,
            isManualBilling,
            isDemo,
            productSubscriptionId,
            isFree,
            doNotRenew,
            isPrePaid: false,
            manualEstimatedTotal,
            
            updateFutureSubscriptions
        }
    }

    static getNewClinicalSubscriptionForm = () => {
        return {
            subscriptionStartDate: null,
            subscriptionEndDate: null,
            billingCycle: null,
            profileCount: null,
            profilePrice: null,
            packageTier: null,
            systemFee: null,
            isManualBilling: false,
            isDemo: false,
            productSubscriptionId: null,
            isFree: false,
            doNotRenew: false,
            isPrePaid: false,
            manualEstimatedTotal: null,
            
            updateFutureSubscriptions: false
        }
    }
    
}