import store from '../store'
import {SET_SNACKBAR} from "@/store/mutation-types";



export const handleUnexpectedApiError = (response) => {
        store.commit(SET_SNACKBAR, {
            text: 'An unexpected error occured. If this continues, please call Sway support.',
            color: 'error',
            open: true
        });
        
        return {
            isSuccess: false,
            errors: [{
                code: 0,
                message: 'An unexpected error occured. If this continues, please call Sway support.'
            }]
        }

}

export const handleExpectedApiError = (response) => {
        store.commit(SET_SNACKBAR, {
            text: response.errors[0].message,
            color: 'error',
            open: true
        });
        
        return {
            isSuccess: false,
            errors: response.errors
        }
}