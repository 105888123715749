<template>
	<div class="chip-small" :class="getClassFromPercentChange(changeData.colorId)">{{ changeData.direction > 0 ? '&#x25B2;' : '&#x25BC;' }} <span style="margin-left: 0px">{{ changeData.text }}</span></div>
</template>

<script>
export default {
	name: "MeasureChangeChip",
	props: {
		changeData: {
			required: true
		}
	},
	methods: {
		getClassFromPercentChange(changeColorId) {
			if (changeColorId == 0) {
				return 'chip-grey'
			} else if (changeColorId == 1) {
				return "chip-orange"
			} else {
				return "chip-red"
			}
		}
	}
}
</script>

<style scoped>

</style>