<template>
    <div>
        <v-menu offset-y nudge-bottom="5" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <div class="pa-1  add-measure-container" v-bind="attrs" v-on="on" >
                    <span ><v-icon>mdi-plus</v-icon> {{addButtonText}}</span>
                </div>
            </template>
            <div id="multiple-select-dropdown">

                <v-list style="max-height: 500px; overflow-y: scroll" dense>
                    <v-list-item
                        v-for="(item, index) in formattedItems"
                        :key="index"


                    >
                        <template v-slot:default="{ active }">
                            <v-list-item-action >
                                <v-checkbox v-model="item.isSelected" @change="e => addRemoveItem(e, item, index)"></v-checkbox>
                            </v-list-item-action>

                            <v-list-item-content >
	                            <div style="display: flex; align-items: center">
		                            <img :src="item.image" alt="item-image" v-if="item.image" style="height: 25px; margin-right: 8px">
		                            <v-list-item-title>{{ item[itemTitle] }}</v-list-item-title>
	                            </div>
	                            
                                <v-list-item-subtitle>{{ item[itemSubtitle] }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </v-list-item>
                </v-list>

            </div>

        </v-menu>
        <draggable
            :list="selectedItems"
            :disabled="!draggable"
            class="list-group"
            ghost-class="ghost"
            :move="checkMove"
            @start="dragging = true"
            @end="dragging = false"
        >

            <div 
                :class="{'pa-2 mt-2 measure-container list-group-item': true, 'draggable': draggable}" 
                :style="{borderLeftColor: item.color ? item.color : 'inherit'}"
                style="display: flex" v-for="(item, index) in selectedItems" 
                :key="index">
                <div style="flex: 1">
                    <v-icon v-if="draggable">mdi-drag-vertical</v-icon>
                    <span >{{item[itemTitle]}}</span>
                </div>
                <div @click="removeItem(index)" class="pointer">
                    <v-icon>mdi-close</v-icon>
                </div>


            </div>
        </draggable>



       
    </div>
</template>

<script>

import draggable from 'vuedraggable'

// Pretty sure there's a lot of room for improvement in this code but just knocked it out fast

export default {
    name: "AppMultipleSelect",
    components: {
        draggable,
    },
    
    props: {
        draggable: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            required: true
        },
        value: {
            required: true
        },
        itemValue: {
            required: true,
            type: String
        },
        itemTitle: {
            required: true,
            type: String
        },
        itemSubtitle: {
            required: false,
            type: String
        },
        addButtonText: {
            type: String,
            default: "Add New"
        }
        
    },
    data() {
        return {
            selectedItems: []
        }  
    },
    mounted() {
        this.initSelectedItems()
        
    },
    watch: {
        items: function(newValue, oldValue) {
            this.initSelectedItems()
        },
        selectedItems: function(newValue, oldValue) {
            let value = [];
  
            newValue.forEach(x => {
               value.push(x[this.itemValue])
            })
            this.$emit('input', value)
        }
    },
    computed: {
        formattedItems() {
            return this.items.map(item => {
                item.isSelected = !!this.value.find(x => x === item[this.itemValue])
                return item
            })
        }  
    },
    methods: {
        initSelectedItems() {
            const self = this;
            let temp = this.items;
            if (!(this.items.length > 0)) return []
            let selectedItems = [];


            this.value.forEach(item => {
                // const something = self.items;
                // let somethingelse = item;
                // 

                let fullItem = self.items.find(x => x[self.itemValue] === item)
                if (fullItem) {
                    selectedItems.push(fullItem)
                }
            });
            //
            // 

            this.selectedItems = selectedItems;
        },
        checkMove: function(e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
        },
        addRemoveItem(e, item, index) {
            if (e) {
                this.selectedItems.push(item)
            } else {
                let itemIndex = this.selectedItems.findIndex(x => x[this.itemValue] === item[this.itemValue]);
                this.selectedItems.splice(itemIndex, 1);
                
            }

        },
        removeItem(index) {
            this.selectedItems.splice(index, 1)
        },
        isSelected(item) {
            let temp = this.selectedItems.find(x => x[this.itemValue] === item[this.itemValue]);
            let longword = !!temp
            return longword
        }
    }
}
</script>

<style scoped>
.measure-container {
    width: 100%;
    height: 43px;
    border-radius: 10px;
    border: 2px solid var(--v-light-darken2);
    background-color: var(--v-light-base);
    display: flex;
    align-items: center
}

.draggable:hover {
    cursor: move
}


.add-measure-container {
    width: 100%;
    height: 43px;
    border-radius: 10px;
    border: 2px solid var(--v-light-darken2);
    background-color: var(--v-light-base);
    display: flex;
    align-items: center
}

.add-measure-container:hover {
    cursor: pointer
}

/*.list-group:hover {*/
/*    cursor: move;*/
/*}*/

.list-group-item {
    border: 1px solid var(--v-light-darken2 );
    border-radius: 5px;
    border-left: 5px solid var(--v-secondary-base);
    background-color: white;
}


</style>