<template>
    <div>
        <LoginFrame>
            <LoginForm
                title="Forgot Password"
                message="Enter the email address you used to sign up for your Sway account and we'll send a password reset link."
                buttonText="Submit"
                button-text2="Sign In"
                @submit2="$router.push('login?redirect=Dashboard')"
                @submit="onSubmit"
                :loading="loading"
                v-if="!success"
            >
                <app-text-field v-model="email" type="email" label="Email" class="login-input" rules="required|email" @onKeypressEnter="onEnter" />
    
            </LoginForm>
            <LoginForm v-show="success">
                <h1 class="forgot-password-success">Success!</h1>
                <div class="forgot-password-success" style="max-width: 300px; text-align: center; color: var(--v-text-lighten2); margin-top: 10px">
                    An email containing a password reset link has been sent to {{ email }}. If you have issues receiving this email, please reach out to Sway Support.
                </div>
                <AppBtn class="forgot-password-success" style="margin-top: 20px; min-width: 200px" @click="$router.push('login?redirect=Dashboard')">Login</AppBtn>
    
            </LoginForm>
        </LoginFrame>
    </div>
    
</template>

<script>
import gsap from "gsap";
import {forgotPassword} from "@/services/userService";
import LoginFrame from "@/components/Login/LoginFrame.vue";
import LoginForm from "@/components/Login/LoginForm.vue";

export default {
    name: "ForgotPassword",
    components: {LoginForm, LoginFrame},
    props: {

    },
    data() {
        return {
            email: '',
            success: false,
            loading: false
        }
    },
    methods: {
        async onSubmit() {
            
            this.loading = true;
            if (!this.isValidEmail) {
                this.loading = false;
                return;
            }
            
            const {email} = this;
            let response = await forgotPassword({email});
            
            // TODO:
            this.success = true;
            gsap.from('.forgot-password-success', {
                y: 20,
                opacity: 0,
                stagger: 0.1,
                duration: 0.5
            })
        },
        async onEnter(){
            await this.onSubmit();
        }
    },
    computed: {
        isValidEmail() {
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(String(this.email).toLowerCase());
        }
    }
}
</script>

<style scoped>

</style>