<template>
    <div class="">
        <div class="sway-form">
            <form>
                <h3>Billing Email Log</h3>
                <br />
                <div class="inline-block-field">
                    <app-form-date-picker v-model="startDate" label="Start Date"></app-form-date-picker>
                </div>
                <br />
                <div class="inline-block-field">
                    <app-form-date-picker v-model="endDate" label="End Date"></app-form-date-picker>
                </div>
                <br />
                <div>
                    <app-btn style="" @click="getReport()">
                        Get Billing Email Log Report For All Orgs
                    </app-btn>
                    <app-fab-btn type="download" @click="downloadReport()" />
                </div>
               
            </form>
        </div>


        <v-data-table :items="reportRows" :headers="headers">

        </v-data-table>
    </div>
</template>

<script>
import moment from 'moment'
import {getEmailLogReport, getEmailLogReportExport} from "@/services/report.service";

export default {
    name: "EmailReport",
    components: {
    },
    data () {
        return {
            reportRows: [],
            headers: [],
            loading: true,

            startDate: moment().add(-1, "months").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD")
        }
    },

    mounted () {
        //this.getDataFromApi()
    },
    computed: {
        
    },
    methods: {
        async getReport(orgId) {
            
            
            this.loading = true;

            const request = {
                startDate: this.startDate,
                endDate: this.endDate,
                orgId: orgId
            };

            const response = await getEmailLogReport(request);
            this.headers = response.headers;
            this.reportRows = response.report;

            this.loading = false;
        },
        async downloadReport(orgId) {
            

            this.loading = true;

            const request = {
                startDate: this.startDate,
                endDate: this.endDate,
                orgId: orgId
            };

            const response = await getEmailLogReportExport(request);

            this.loading = false;
        }
    },
}
</script>

<style scoped>

</style>