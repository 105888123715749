<template>
    <div>
        <div v-show="!initialLoading">
            <div v-show="!orgHasZeroProfiles || openEventsOnly">
                <page-header title="Profiles" menu>
                    <template #menu-content>
                        <div style="min-width: 200px">
                            <div class="pa-2"
                                 style="text-transform: uppercase; background-color: white; font-size: .8em; color: #bdbdbd; font-weight: 500"
                            >Profiles Actions</div>
                            <v-list>
                                <v-list-item
                                    v-for="(item, index) in profilesActions"
                                    :key="index"
                                    class="pl-2 pr-2"
                                    link
                                    @click="item.onClick"
                                    dense
                                >
                                    <v-list-item-icon>
                                        <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title >{{ item.label }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </div>
                    </template>
                    <div slot="right-content">
                        <!--                <app-btn @click="newProfileDialog = true"><v-icon class="mr-2">mdi-card-account-details-outline</v-icon> New Profile</app-btn>-->
                        <div>
                            <AppBtn class="mr-2" style="font-weight: 600" @click="newProfileDialog = true" icon="mdi-plus">Add Profile </AppBtn>
                            <AppBtn class="mr-2" style="font-weight: 600" @click="() => this.$router.push('/profiles/bulk-move')" icon="mdi-swap-horizontal">Bulk Move </AppBtn>
                            <AppBtn class="mr-2" style="font-weight: 600" @click="exportTestsDialogOpen = true" :loading="exportingTestResults" icon="mdi-download">Export Tests </AppBtn>
                            <AppBtn class="mr-2" style="font-weight: 600" @click="exportEventsDialogOpen = true" :loading="exportingEvents" icon="mdi-download">Export Events </AppBtn>

                            <!--                            <AppBtn  style="font-weight: 600" @click="exportCovidTestsDialogOpen = true" :loading="exportingCovidResults" v-if="organizationPreferences && organizationPreferences.enableCovid19Screening" >Export Covid Screenings </AppBtn>-->
                            
                        </div>
                    </div>
                </page-header>
                <router-view
                    @finishedInitialLoading="initialLoading = false"
                    @setIsEmpty="setIsEmpty($event)"
                    @setOrgHasZeroProfiles="orgHasZeroProfiles = true"

                ></router-view>
            </div>
            <div v-if="orgHasZeroProfiles && !openEventsOnly" >
                <div style="display: flex; flex-direction: column; justify-content: center; align-items: center">
                    <EmptyState
                        title="No profiles"
                        description="No profiles have been created in this organization. Create a code or manually add a new profile to get started!"
                        :svg="emptyProfilesGraphic"
                    />
                    <AppBtn style="margin-top: 30px" @click="() => $router.push('/codes?createCode=1')">Create A Code</AppBtn>
                    <div style="margin-top: 10px; color: var(--v-text-lighten3)">
                        or
                    </div>
                    <AppBtn style="margin-top: 10px" @click="newProfileDialog = true" color="secondary">Add New Profile</AppBtn>
                </div>
                
            </div>
            
            
        </div>
        <div v-if="initialLoading">
            <AppPageLoader text="Loading Profiles..." />
        </div>
        <div>
            
        </div>

        <dialog-form
            :open="newProfileDialog"
            @close="newProfileDialog = false"
            title="Add A New Profile"
            @submit="submitAddProfileForm"
            :width="600"
            :saving="savingProfile"
        >

            <template v-slot:form-content >
                <ProfileCreateUpdateForm 
                    :form="newProfileForm" 
                    :organizationPreferences="organizationPreferences" 
                    :include-groups="true"
                    :groups="groups"
                    :protocols="protocols"
                    creating-profile
                />

            </template>
        </dialog-form>

        <dialog-form
            :open="exportTestsDialogOpen"
            @close="exportTestsDialogOpen = false"
            title="Export Tests"
            :width="500"
            @submit="getTestResultsExport"
        >
            <template v-slot:form-content>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportTestsForm.startDate" label="Start Date" readonly></app-form-date-picker>
                </div>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportTestsForm.endDate" label="End Date" readonly></app-form-date-picker>
                </div>
                <div class="inline-block-field" v-if="$store.state.currentAccount.isSuperAdmin">
                    <app-form-checkbox v-model="exportTestsForm.includeSurveys" label="Include On-Field Assessment, VOMS, and other Surveys" readonly></app-form-checkbox>
                </div>
            </template>

        </dialog-form>

        <dialog-form
            :open="exportEventsDialogOpen"
            @close="exportEventsDialogOpen = false"
            title="Export Events"
            :width="500"
            @submit="getEventsExport"
        >
            <template v-slot:form-content>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportEventsForm.startDate" label="Start Date" readonly></app-form-date-picker>
                </div>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportEventsForm.endDate" label="End Date" readonly></app-form-date-picker>
                </div>
            </template>

        </dialog-form>

        <dialog-form
            :open="exportCovidTestsDialogOpen"
            @close="exportCovidTestsDialogOpen = false"
            title="Export Covid Tests"
            :width="500"
            @submit="getCovidResultsExport"
        >
            <template v-slot:form-content>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportCovidTestsForm.startDate" label="Start Date" readonly></app-form-date-picker>
                </div>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportCovidTestsForm.endDate" label="End Date" readonly></app-form-date-picker>
                </div>
            </template>

        </dialog-form>
        
<!--        <profiles-table/>-->

    </div>
</template>

<script>
import router from "@/router";
import ProfilesTable from "@/components/ProfilesTable";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {getGroups} from '@/services/groups.service'
import {getProtocols} from '@/services/protocol.service'
import {getTestResultsExport, getEventsExport} from '@/services/organization.service'
import {profileModel,createProfile} from '@/services/profile.service'
import moment from 'moment'
import {SET_SNACKBAR} from "@/store/mutation-types";
import {getOrganizationPreferences} from "@/services/organization.service";
import ProfileCreateUpdateForm from "@/components/Profile/ProfileCreateUpdateForm";
import EmptyState from "@/components/EmptyState";
import SwayProfile from "@/js/shared/classes/Profile/SwayProfile";
import emptyProfilesGraphic from '@/assets/svgs/empty-state/empty-group-profile.svg'
import {getAccountProfilesByProfileId} from "../services/profile.service";
import {isAdminOrgAccessType} from "@/js/shared/helper";

export default {
    name: "Profiles",
    components: {
        EmptyState,
        ProfileCreateUpdateForm,
        ProfilesTable,
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            emptyProfilesGraphic,
            orgHasZeroProfiles: false,
            initialLoading: false,
            isEmpty: false,
            exportingTestResults: false,
            exportingEvents: false,
            exportingCovidResults: false,
            exportTestsDialogOpen: false,
            exportEventsDialogOpen: false,
            exportCovidTestsDialogOpen: false,

            exportTestsForm: {
                startDate: null,
                endDate: null,
                includeSurveys: false
            },
            exportEventsForm: {
                startDate: null,
                endDate: null,
            },

            exportCovidTestsForm: {
                startDate: null,
                endDate: null,
            },
            
            valid: false,
            savingProfile: false,
            nameRules: [
                v => !!v || 'Name is required',
                v => v.length <= 10 || 'Name must be less than 10 characters',
            ],
            newProfileDialog: false,
            newProfileForm: null,
            genderOptions: [
                {
                    label: 'Male',
                    value: 'M'
                },
                {
                    label: 'Female',
                    value: 'F'
                },  
                {
                    label: 'Non-binary',
                    value: 'non-binary'
                },  
            ],
            biologicalSexOptions: [
                {
                    label: 'Male',
                    value: 'male'
                },
                {
                    label: 'Female',
                    value: 'female'
                },
            ],
            name: '',
            phoneNumber: '',
            email: '',
            select: null,
            items: [
                'Item 1',
                'Item 2',
                'Item 3',
                'Item 4',
            ],
            checkbox: null,
            groups: [],
            protocols: [],
            organizationPreferences: null,
	
	          openEventsOnly: false,
        }
    },
    beforeMount() {
	    this.openEventsOnly = this.$route?.query?.openEventsOnly === "true" ? true : false
    },
    mounted() {
	    
        this.initialLoading = true;
        this.getOrganizationPreferences()
        this.getGroups()
        this.getProtocols()
        // this.setAddProfileForm()
        
        
        
    },

    computed: {
        today() {
            return moment().format('YYYY-MM-DD')
        },
        profilesActions() {
            var actions = [
                {
                    label: 'Add New Profile',
                    onClick: () => { this.newProfileDialog = true },
                    icon: 'mdi-account-plus'
                },
                {
                    label: 'Bulk Move',
                    onClick: () => this.$router.push('/profiles/bulk-move'),
                    icon: 'mdi-swap-horizontal-circle-outline'
                },
                {
                    label: 'Export Profile Tests',
                    onClick: this.getTestResultsExport,
                    icon: 'mdi-cloud-download'
                },
                {
                    label: 'Export Profile Events',
                    onClick: this.getEventsExport,
                    icon: 'mdi-cloud-download'
                }
            ];
            if (this.organizationPreferences && this.organizationPreferences.enableCovid19Screening) {
                actions.push(
                    {
                        label: 'Export Covid Screenings',
                        onClick: this.getCovidResultsExport,
                        icon: 'mdi-cloud-download'
                    });
            }
        },
    },
    methods: {
        onFinishInitialLoading() {
            this.initialLoading = false;
        },
        isAdminOrgAccessType(id) {
            return isAdminOrgAccessType(id)
        },
        setIsEmpty(value) {
            this.isEmpty = value
        },
        async getOrganizationPreferences() {
            const response = await getOrganizationPreferences();
            
            if (!response.isSuccess) return;
            
            const {organizationPreferences} = response;
            this.organizationPreferences = organizationPreferences;
            this.setAddProfileForm()
        },
        // async getAccountProfiles() {
        //     const response = await getAccountProfilesByProfileId();
        //
        //     if (!response.isSuccess) return;
        //
        //     const {accountProfiles} = response;
        //     return accountProfiles;
        // },
        async setAddProfileForm() {
            this.newProfileForm = SwayProfile.getProfileForm({
                isNewProfile: true,
                profile: null,
                isMetric: this.organizationPreferences.isMetric,
                createdInGroupId: null,
            });
            
            this.newProfileForm.accountProfiles = [];

            if (this.$route.query.addProfile === 'true') {
                this.newProfileDialog = true
            }
        },
        async getTestResultsExport() {
            this.exportTestsDialogOpen = false;
            this.exportingTestResults = true;
            const request = {
                startDate: this.exportTestsForm.startDate,
                endDate: this.exportTestsForm.endDate,
                includeSurveys: this.exportTestsForm.includeSurveys,
                groupIds: [],
                profileIds: [],
                isCovidOnly: false,
                orgId: this.$store.state.currentAccount.selectedOrganization.id
            }
            let response = await getTestResultsExport(request)
            this.exportingTestResults = false;
        },
        async getEventsExport() {
            this.exportEventsDialogOpen = false;
            this.exportingEvents = true;
            const request = {
                startDate: this.exportEventsForm.startDate,
                endDate: this.exportEventsForm.endDate,
                groupIds: [],
                profileIds: [],
                isCovidOnly: false,
                orgId: this.$store.state.currentAccount.selectedOrganization.id
            }
            let response = await getEventsExport(request)
            this.exportingEvents = false;
        },
        async getCovidResultsExport() {
            this.exportCovidTestsDialogOpen = false;
            this.exportingCovidResults = true;
            const request = {
                startDate: this.exportCovidTestsForm.startDate,
                endDate: this.exportCovidTestsForm.endDate,
                groupIds: [],
                profileIds: [],
                isCovidOnly: true,
                orgId: this.$store.state.currentAccount.selectedOrganization.id
            }
            let response = await getTestResultsExport(request)
            this.exportingCovidResults = false;
        },
        async getGroups() {
            const response = await getGroups()
            this.groups = response.groups;
        },
        async getProtocols() {
            const response = await getProtocols();
            this.protocols = response.protocols
        },
        async submitAddProfileForm() {
            // console.log(this.newProfileForm);
            this.savingProfile = true;
            
            let request = SwayProfile.getCreateUpdateProfileRequestFromForm({
                form: this.newProfileForm, 
                isMetric: this.organizationPreferences.isMetric, 
                isMobile: false,
                enableSportsProfileProperties: this.organizationPreferences.enableSportsProfileProperties
            });
            
            // console.log(request)
            // 
            
            let response = await createProfile({profile: request});

            if (!response.isSuccess) {
                this.$store.commit(SET_SNACKBAR, {
                    text: response.errors[0].message,
                    color: 'error',
                    open: true
                });
                this.savingProfile = false;
                return
            }
            const createdProfile = response.profile;
            this.newProfileDialog = false;
            this.savingProfile = false;
            this.$store.commit(SET_SNACKBAR, {
                text: 'Profile Successfully Created',
                color: 'success',
                open: true
            });
            await this.$router.push(`/profiles/${createdProfile.id}/overview`)
            
        }
    },
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
    cursor: pointer;
}
</style>