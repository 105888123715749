<template>
    <div style="display: flex; align-items: center">
        <div 
            v-for="(type, index) in types" 
            :key="index" 
            style="display: flex; align-items: center; margin-right: 10px"
        >
            <TestTypeDot 
                :has-duplicate-tests="type.isAssessment"
                :is-non-baseline="type.isNonBaseline"
                :is-dot-excluded="type.isDotExcluded"
                style="margin-right: 5px"
                
            />
            <div :style="{fontSize: isPdf ? '9px' : '12px'}">
                {{type.name}}
            </div>
        </div>
        
    </div>
</template>

<script>
import TestTypeDot from "@/components/TestTypeDot";
export default {
    name: "TestTypeDotExplanation",
    components: {TestTypeDot},
	props: {
		isPdf: {
			type: Boolean
		}
	},
    data() {
        return {
            types: [
                {
                    name: 'Screening',
                    isNonBaseline: true,
                    isAssessment: false,
                    isDotExcluded: false
                },
                {
                    name: 'Assessment',
	                isNonBaseline: true,
	                isAssessment: true,
                    isDotExcluded: false
                },
                {
                    name: 'Baseline Screening',
	                isNonBaseline: false,
	                isAssessment: false,
                    isDotExcluded: false
                },
                {
                    name: 'Baseline Assessment',
	                isNonBaseline: false,
	                isAssessment: true,
                    isDotExcluded: false
                },
                {
                    name: 'Excluded Baseline Screening',
	                isNonBaseline: false,
	                isAssessment: false,
                    isDotExcluded: true
                },
                {
                    name: 'Excluded Baseline Assessment',
	                isNonBaseline: false,
	                isAssessment: true,
                    isDotExcluded: true
                },
                
            ]
        }
    }
}
</script>

<style scoped>

</style>