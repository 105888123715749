<template>
    <div style="">
	    <AppTextField
		    v-model="form.text"
		    label="Note"
		    error-label="Last Name"
		    :rows="20"
	    />
        
    </div>
</template>

<script>

import AppTextField from "@/components/forms/AppTextField";

export default {
    name: "EditTestNoteForm",
	components: { AppTextField},
	props: {
		form: {
		    required: true
	    }
    },
    data() {        
		return {}
    },

}
</script>

<style scoped>

</style>