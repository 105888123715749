<template>
    <div>
        <div v-if="updatingSession">
            <AppPageLoader text="Updating Code..."></AppPageLoader>
        </div>
        <div v-else>
            <page-header
                :title="isViewingAsSuper && formattedSession.profileId ? `Code - Profile ID: ${formattedSession.profileId}` : `Code - ${formattedSession.displayName}`"
                :editable="isSessionEditable"
                deletable
                @edit="editSessionDialogOpen = true"
                @delete="deleteSessionDialogOpen = true"
            >
                <template #title-right>
                    <app-chip v-if="formattedSession.isActive" color="green" label>Active</app-chip>
                    <app-chip v-else  color="red" label>Expired</app-chip>
                </template>

            </page-header>
            <v-alert type="warning" elevation="2" @click="updateCode" class="old-code-alert" v-if="session.baselineSessionTypeId === 1">
                This code was created under an old session structure. Click here to update to the new session structure.
            </v-alert>
            <app-card title="Code Details">
                <div class="pa-4" style="display: flex">
                    <div style="display: flex;  flex-direction: column; flex: 1;" >
                        <div style="display: flex;  " v-for="(field, index) in fields" :key="index">
                            <div v-if="index === 0" style="display: flex; align-items: flex-end" class="mb-2">
                                <h3 class="mr-2">{{ field.label }}: </h3><app-chip color="blue" large label>{{field.value}}</app-chip>
                            </div>
                            <div v-else-if="index === 1" style="display: flex; align-items: center" class="mb-2">
                                <h4 class="mr-2">{{ field.label }}: </h4><TestTypeDot class="mr-2" :is-non-baseline="session.isNonBaseline" :has-duplicate-tests="!formattedSession.isTest" :is-dot-excluded="false" /><span >{{field.value}}</span>
                            </div>
                            <div v-else style="display: flex">
                                <h4 class="mr-2">{{ field.label }}: </h4>{{field.value}}
                            </div>

                        </div>

                    </div>
                    <div v-if="regulationMeasures" style="flex: 1">
                        <div>
                            <span style="font-weight: bold">Protocol: </span>{{formattedSession.protocolName}}
                        </div>
                        <div style="display: flex; flex-direction: column; justify-content: center">
                            <div v-for="(measure, measureIndex) in regulationMeasures" :key="measureIndex" style="display: flex; align-items: center" class="pt-2">
	                            <img :src="measure.categoryIcon" alt="category-icon" style="height: 25px; margin-right: 5px"> {{measure.name}}
                            </div>
                        </div>

                    </div>
                    
                </div>
            </app-card>
            <app-card hide-header class="mt-4">
                <app-data-table
                    v-if="profiles"
                    :headers="headers"
                    :items="profiles"
                    @onRowClick="onRowClick($event)"
                >
                    <template #item.name="{item}">
                        <div v-if="!isViewingAsSuper">{{item.firstName}} {{item.lastName}}</div>
                        <div v-if="isViewingAsSuper">{{item.id}}</div>
                    </template>
                    <template #item.progress="{item}">
                        <div v-if="regulationMeasures" style="display: flex">
                            <div v-for="(measure, measureIndex) in regulationMeasures">
                                <div
                                    style="height: 10px; width: 10px; border-radius: 10px; margin: 5px"
                                    :style="{backgroundColor: getCiColor(item, measure.regulationMeasureId)}"
                                >

                                </div>
                            </div>
                        </div>
                    </template>
                    <template #no-data>
<!--                        <EmptyState style="margin-top: 30px" :svg="emptyCodeGraphic" description="No profiles have participated under this code."></EmptyState>-->
                        No profiles have participated under this code.
                    </template>
                    <!--                <template #no-data>-->
                    <!--                    I got no data-->
                    <!--                </template>-->

                </app-data-table>
            </app-card>
            <div v-if="profilesLoading">
                <AppPageLoader text="Loading Profiles..." />
            </div>
        </div>
        
        <dialog-form
            :open="editSessionDialogOpen"
            @close="editSessionDialogOpen = false"
            title="Edit Code"
            @submit="submitEditSessionForm"
            :width="600"
            :saving="updatingSession"
        >

            <template v-slot:form-content>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="mb-2">The end time will be set to 11:59pm Central Time of the date you choose.</div>
                            <app-form-date-picker label="End Date"
                                                  v-model="editSessionForm.endDate"
                                                  :allowed-dates="e => allowedDates(e)"

                            />
                        </v-col>
                        
                    </v-row>

                    <v-row></v-row>
                    
                    <v-row>
                        <v-col cols="12">
                            <div class="mb-2">Set Account Notification Preferences</div>
                            <UserNotificationsForm :selected-account-notifications="editSessionForm.selectedAccountNotifications"></UserNotificationsForm>
                        </v-col>

                    </v-row>
                </v-container>
                
            </template>
            <template v-slot:actions>

            </template>



        </dialog-form>
        <app-confirm-dialog 
            title="Delete Session" 
            :open="deleteSessionDialogOpen" 
            @confirm="deleteSession" 
            @close="deleteSessionDialogOpen = false"
            :loading="deletingSession"
            confirm-delete
        >
            <div>
                
                Are you sure you want to delete code {{session.code}}?
            </div>
        </app-confirm-dialog>

        <app-confirm-dialog
            title="QR Code"
            :open="qrCodeDialogOpen"
            @confirm="printPage"
            @close="qrCodeDialogOpen = false"
            max-width="600"
            width="600"
            submit-button-title="Print"
        >
            <div style="text-align: center">
                <div>
                    Scan the QR code with a mobile device and go directly to testing.
                </div>
                <qrcode-vue :value="deeplinkUrl" :size="500" level="H" />
            </div>
        </app-confirm-dialog>
    </div>
    
</template>

<script>
import {
    getSession,
    updateSession,
    sessionModel,
    deleteSession,
    getTestedProfilesBySessionId,
    updateSessionToExpedited,
    getAccountNotificationPrefs
} from '@/services/session.service'
import moment from "moment";
import 'moment-timezone'
import {SET_SNACKBAR} from "@/store/mutation-types";
import {getSessionDetails, getCodeTypeId} from "@/js/shared/helper";
import {getProfileAverages} from "@/services/profile.service";
import {getMeasuresForProtocol} from "@/services/protocol.service";
import TestTypeDot from "@/components/TestTypeDot";
import {getCiColor} from "@/js/shared/helper";
import {asLocalTime} from "@/js/shared/helper";
import emptyCodeGraphic from '@/assets/svgs/empty-state/empty-code.svg'
import EmptyState from "@/components/EmptyState";
import {allMeasuresFromConstants, measuresCategoriesById, screeningOrAssessmentOptions} from "@/js/shared/constants";
import QrcodeVue from "qrcode.vue";
import {baseUrl} from "@/config"
import UserNotificationsForm from "@/components/Sessions/UserNotificationsForm.vue";

export default {
    name: "Session",
    components: {
        UserNotificationsForm,
        EmptyState,
        TestTypeDot,
        QrcodeVue
    },
    data() {
        return {
            emptyCodeGraphic,
            session: null,
            formattedSession: null,
            editSessionDialogOpen: false,
            deleteSessionDialogOpen: false,
            updatingSession: false,
            deletingSession: false,
            profilesLoading: true,
            
            profiles: null,

            qrCodeSize: 200,
            deeplinkUrl: null,
            qrCodeDialogOpen: false,
            
            editSessionForm: {
                endDate: null,
                endTime: {
                    hour: 12,
                    minute: 5,
                    amPm: 'am'
                },
	            testOrSession: null,
                selectedAccountNotifications: []
            },
            hours: [
                {
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                },
                {
                    text: '4',
                    value: 4
                },
                {
                    text: '5',
                    value: 5
                },
                {
                    text: '6',
                    value: 6
                },
                {
                    text: '7',
                    value: 7
                },
                {
                    text: '8',
                    value: 8
                },
                {
                    text: '9',
                    value: 9
                },
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '11',
                    value: 11
                },
                {
                    text: '12',
                    value: 12
                },
            ],
            minutes: [
                {
                    text: '0',
                    value: 0
                },
                {
                    text: '15',
                    value: 15
                },
                {
                    text: '30',
                    value: 30
                },
                {
                    text: '45',
                    value: 45
                },
            ],
            amOrPm: [
                {
                    text: 'AM',
                    value: 'am'
                },
                {
                    text: 'PM',
                    value: 'pm'
                },
            ],
            countdown: null,
            countdownDisplayTime: {
                days: null,
                hours: null,
                minutes: null,
                seconds: null
            },
            headers: [
                {
                    text: 'Name',
                    value: 'name'
                },
                // {
                //     text: 'Completed On',
                //     value: 'completedOn'
                // },
                {
                    text: 'Averages',
                    value: 'progress'
                },
                
            ],
            regulationMeasures: null,
	        screeningOrAssessmentOptions
            
        }
    },
    watch: {
        $route (to, from){
            if (to.params.id !== from.params.id) {
                this.clearSession()
                this.initializeSession()
            }
        },
    },
    mounted() {
        this.initializeSession()
    },
    methods: {
        async onRowClick(profile) {
            await this.$router.push(`/profiles/${profile.id}/overview`)
        },
        async updateCode() {
            this.updatingSession = true
            let {id} = this.session;
            
            const response = await updateSessionToExpedited(id);
            
            if (!response.isSuccess) {
                return
            }

            await this.initializeSession();
            this.updatingSession = false
        },
        getCiColor(item, regulationMeasureId) {
            let variabilityType = item.averages?.allAverages[regulationMeasureId]?.variabilityType;
            return variabilityType ? getCiColor(variabilityType) : 'lightgrey'
        },
        clearSession() {

        },
        printPage(){
            window.print()
        },
        allowedDates(e) {
            return !moment(e).isBefore(moment().subtract(1, 'day'));
        },
        async initializeSession() {
            
            let dateFormat = 'M/D/YYYY h:mm a'
            let sessionId = this.$route.params.id
            
            
            const response = await getSession(sessionId);
            if (!response.isSuccess) return
            
            
            this.session = {...response.session};
            let formattedSession = {...response.session};

            this.formattedSession = getSessionDetails(formattedSession);
            
            //https://portal.swaymedical.com/gotoapp/takebaseline?baselineCode=18496
            this.deeplinkUrl = window.location.origin + '/gotoapp/takebaseline?baselineCode=' + this.session.code;
            console.log(this.deeplinkUrl);
            
            const protocolMeasuresResponse = await getMeasuresForProtocol(this.session.organizationProtocolId);
            if (!protocolMeasuresResponse.isSuccess) {
                return
            }

            const {protocol, regulationMeasures} = protocolMeasuresResponse;
            regulationMeasures.forEach(measure => {
                const {regulationMeasureId} = measure;
                const measureFromConstants = allMeasuresFromConstants[regulationMeasureId];
                measure.category = measureFromConstants ? measureFromConstants.category : 8;
                measure.categoryIcon = measuresCategoriesById[measure.category].icon
            })
            this.regulationMeasures = regulationMeasures;
            
            
            //todo: add profiles loading icon
            this.profilesLoading = true;
            

            const profilesResponse = await getTestedProfilesBySessionId(sessionId);

            if (!profilesResponse.isSuccess) {
                return
            }
            
            const {profiles} = profilesResponse;
            let profileIds = profiles.map(x => x.id)
            
            // profiles.forEach(profile => promises.push(getProfileAverages([profile.id])))

            const profilesAveragesResponse = await getProfileAverages(profileIds)
            profiles.forEach((profile, index) => {
                profile.averages = profilesAveragesResponse.averages[profile.id]
            });
            
            this.profiles = profiles;

            // if (profiles.length > 0) {
            //     const hello = await getProfileAverages(profiles[0].id)
            //     
            // }
            
            
            

            await this.initializeEditSessionForm(formattedSession.endsOnFormatted, formattedSession.isScreening)
            
            this.profilesLoading = false;
            
            if (formattedSession.isActive) this.startCountdown();
            this.getCountdownDisplayTime()
        },
        startCountdown() {
            this.countdown = setTimeout(() => {
                this.getCountdownDisplayTime()
                this.startCountdown()
            }, 1000)
        },

        getCountdownDisplayTime() {

            let differenceInSeconds = moment(this.formattedSession.endsOn).diff(moment(), 'seconds');
            let days = Math.floor(differenceInSeconds / 86400);
            let timeLeft = differenceInSeconds - (days * 86400);
            let hours = Math.floor(timeLeft / 3600);
            timeLeft = timeLeft - (hours * 3600);
            let minutes = Math.floor(timeLeft / 60);
            timeLeft = timeLeft - (minutes * 60)
            let seconds = timeLeft
            
            
            
            
            this.countdownDisplayTime.days = days;
            this.countdownDisplayTime.hours = hours;
            this.countdownDisplayTime.minutes = minutes;
            this.countdownDisplayTime.seconds = seconds;
        },
        async initializeEditSessionForm(endDateTime, isScreening) {
            let endsOn = asLocalTime(moment(endDateTime));
            let endDate = endsOn.format('YYYY-MM-DD')
            let endHour = endsOn.hour() % 12;
            let endMinute = endsOn.minute();
            let endAmOrPm = endsOn.hour() % 12 > 0 ? 'pm' : 'am';

            let roundedEndMinute = Math.ceil(endMinute/5)*5;
            
            this.editSessionForm.endDate = endDate;
			this.editSessionForm.testOrSession = isScreening ? 0 : 1

            this.editSessionForm.selectedAccountNotifications = this.session.selectedAccountNotifications;
        },
        async submitEditSessionForm() {
            this.updatingSession = true;
            let request = {...sessionModel};
            // const {id, groupId, code, startsOn, endsOn, organizationId, organizationProtocolId, profileId, baselineSessionTypeId} = this.sessions;
            
            for (let key in request) {
                request[key] = this.session[key]
            }
	        
	        // request.baselineSessionTypeId = getCodeTypeId({isGroup: !!this.formattedSession.groupId, isNewProfile: this.formattedSession.isNewProfile, isScreening: this.editSessionForm.testOrSession === 0})
	        
            const {endDate, endTime} = this.editSessionForm;
            // const {hour, minute, amPm} = endTime
            //
            // let adjustedEndHour = amPm === 'pm' ? hour + 12 : hour;
            let endDateMoment = moment(endDate)
            let date = endDateMoment.date();
            let month = endDateMoment.month()
            let year = endDateMoment.year();
            let endsOnCentral = asLocalTime(moment(endDate)).year(year).month(month).date(date).hour(23).minute(59).seconds(59)

            let endsOnUTC = moment.utc(endsOnCentral).format()
            //
            // let endDateMoment = moment(endDate)
            // let day = endDateMoment.day();
            // let month = endDateMoment.month()
            // let year = endDateMoment.year()
            
            // let endDateCentral = moment(endDate).tz(timezone)
            //     .year(year).month(month).day(day).hour(23).minute(59).second(59).format();
            
            request.endsOn = moment.utc(endsOnUTC)
            
            request.id = this.session.id;
            
            request.selectedAccountNotifications = this.editSessionForm.selectedAccountNotifications;
			
            const response = await updateSession({session: request});
            
            if (!response.isSuccess) {
                this.updatingSession = false;
                return
            }

            this.$store.commit(SET_SNACKBAR, {
                text: 'Session Successfully Updated',
                color: 'success',
                open: true
            });
            
            await this.initializeSession()
            this.editSessionDialogOpen = false
            this.updatingSession = false;
            
        },
        async deleteSession() {
            this.deletingSession = true
            let response = await deleteSession(this.session.id);
            this.deletingSession = false;
            
            if (!response.isSuccess) return
            
            this.$store.commit(SET_SNACKBAR, {
                text: 'Session Successfully Deleted',
                color: 'success',
                open: true
            })
            // 
            await this.$router.push('/codes')
            
        }
    },
    computed: {
        isSessionEditable() {
            if (!this.session) return null
            return moment(this.session.endsOn).isAfter(moment())  
        },
        isViewingAsSuper(){
            let {viewingAsSuper} = this.$store.state.currentAccount;
            return viewingAsSuper;
        },
        fields() {
            const {formattedSession} = this;
            
            return this.formattedSession ? [
                {
                    label: 'Code',
                    value: formattedSession.code
                },
                {
                    label: 'Type',
                    value: formattedSession.type
                },
                
                
                {
                    label: 'Starts On',
                    value: formattedSession.startsOnFormatted
                },
                {
                    label: 'Ends On',
                    value: formattedSession.endsOnFormatted
                },
                {
                    label: 'Created On',
                    value: formattedSession.createdOnFormatted
                },
                {
                    label: 'Created By',
                    value: formattedSession.createdByAccountEmail
                },
                {
                    label: 'Show Results After Completion?',
                    value: formattedSession.showResultsAfterSession ? 'Yes' : 'No'
                },
                {
                    label: 'Account Creation Enabled',
                    value: formattedSession.isAccountCreationEnabled ? 'Yes' : 'No'
                },
                // {
                //     label: 'Protocol',
                //     value: formattedSession.protocolName
                // },
            ] : null
        },
    }
}
</script>

<style scoped>
td:not(:last-child) {
    border-right: none!important
}
th:not(:last-child) {
    border-right: none!important
}

.old-code-alert:hover {
    cursor: pointer;
}
</style>