<template>
    <div>
        <ICountUp
            :delay="delay"
            :endVal="value"
            :options="options"

        />
    </div>
</template>

<script type="text/babel">
import ICountUp from 'vue-countup-v2';
export default {
    name: 'CountUp',
    components: {
        ICountUp
    },
    props: {
        value: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            delay: 1000,
            // endVal: 120500,
            options: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            }
        };
    },
    methods: {
        onReady: function(instance, CountUp) {
            const that = this;
            instance.update(that.value + 100);
        }
    },
    watch: {
        // value: function(newValue, oldValue) {
        //     let something = newValue;
        //     let other = oldValue;
        //     
        // }
    }
};
</script>

<style scoped>
.iCountUp {
    /*font-size: 12em;*/
    /*margin: 0;*/
    /*color: #4d63bc;*/
    /*color: white*/
}
</style>