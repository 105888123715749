<template>
    <div style="flex: 1; margin-bottom: 200px">
	    <div class="mb-2" >
		    <TestTypeDotExplanation  />
	    </div>
        <app-card title="Previous Tests" >
            
            <v-container fluid >
	            
                <v-row >
                    <v-col cols="3" 
                           style="height: 1000px; display: flex; flex-direction: column; background-color: white; padding: 0!important;  overflow-y: scroll" class="previous-tests-container">

                            <div 
                                v-for="(item, index) in profile.tests" 
                                :key="index" 
                                class="previous-test-container" 
                                :style="{
                                    'border-right': item.id !== currentTestId ? '1px solid var(--v-border-base)' : null, 
                                    'background-color': item.id === currentTestId ? 'white' : 'var(--v-background-base)',
                                    'border-left': item.data.eventId ? '5px solid orange' : null
                                    
                                }"
                                @click="onTestClick(item)"
                            >
                                <div
                                    style="display: flex; align-items: center"
                                    
                                >
                                    
                                  <div style="display: flex; flex-direction: column">
                                      <div style="display: flex; ">
                                          
<!--                                          <span class="dot mr-2" :class="{event: item.data.isEvent, baseline: !item.data.isEvent}" ></span>-->
                                          
                                          <div style="display: flex; flex-direction: column" >
	                                          <div style="display: flex; align-items: center">
		                                          <TestTypeDot
			                                          :is-event="item.data.isEvent"
			                                          :is-non-baseline="item.data.isNonBaseline"
			                                          :has-duplicate-tests="item.data.hasDuplicateTests"
			                                          :is-dot-excluded="item.data.isExcluded || item.data.isBeforeBaselineStartDate" class="mr-2"
		                                          />
		                                          <span style=" font-size: 1.1rem" class="mr-2">{{ item.data.date }}</span>
		                                          <img
			                                          v-if="item.hasTestTypes && item.hasTestTypes.balance"
			                                          :src="balanceIcon"
			                                          class="test-type-icon"
		                                          />
		                                          <img
			                                          v-if="item.hasTestTypes && item.hasTestTypes.cognitive"
			                                          :src="cognitiveIcon"
			                                          class="test-type-icon"
		                                          />
		                                          <img
			                                          v-if="item.hasTestTypes && item.hasTestTypes.functional"
			                                          :src="functionIcon"
			                                          class="test-type-icon"
		                                          />
		
		                                          <img
			                                          v-if="item.hasTestTypes && (item.hasTestTypes.survey || item.hasTestTypes.covid || item.hasTestTypes.symptoms)"
			                                          :src="surveyIcon"
			                                          class="test-type-icon"
		                                          />
		                                          
	                                          </div>
                                              
                                              <div style=" font-size: .9rem; color: #757575" >{{ item.data.time }}</div>
                                          </div>
                                          
                                          
                                      </div>
                                      
                                      <div>
<!--	                                      <img -->
<!--		                                      v-if="item.hasTestTypes && item.hasTestTypes.balance" -->
<!--		                                      :src="balanceIcon" -->
<!--		                                      class="test-type-icon"-->
<!--	                                      />-->
<!--	                                      <img-->
<!--		                                      v-if="item.hasTestTypes && item.hasTestTypes.cognitive"-->
<!--		                                      :src="cognitiveIcon"-->
<!--		                                      class="test-type-icon"-->
<!--	                                      />-->
<!--	                                      <img -->
<!--		                                      v-if="item.hasTestTypes && item.hasTestTypes.functional" -->
<!--		                                      :src="functionIcon" -->
<!--		                                      class="test-type-icon"-->
<!--	                                      />-->
<!--	                                      -->
<!--	                                      <img -->
<!--		                                      v-if="item.hasTestTypes && (item.hasTestTypes.survey || item.hasTestTypes.covid || item.hasTestTypes.symptoms)" -->
<!--		                                      :src="surveyIcon" -->
<!--		                                      class="test-type-icon"-->
<!--	                                      />-->
	                                      
<!--                                          <AppChip small label class="mr-2 mt-1" color="blue" v-if="item.hasTestTypes && item.hasTestTypes.balance">{{measuresCategories.balance.shortLabel}}</AppChip>-->
<!--                                          <AppChip small label class="mr-2 mt-1" color="green" v-if="item.hasTestTypes && item.hasTestTypes.functional">{{measuresCategories.functional.shortLabel}}</AppChip>-->
<!--                                          <AppChip small label class="mr-2 mt-1" color="purple" v-if="item.hasTestTypes && (item.hasTestTypes.cognitive)">Cognitive</AppChip>-->
<!--&lt;!&ndash;                                          <AppChip small label class="mr-2 mt-1" color="orange" v-if="item.hasTestTypes && item.hasTestTypes.executiveFunction">{{measuresCategories.executiveFunction.shortLabel}}</AppChip>&ndash;&gt;-->
<!--&lt;!&ndash;                                          <AppChip small label class="mr-2 mt-1" color="purple" v-if="item.hasTestTypes && item.hasTestTypes.memory">{{measuresCategories.memory.shortLabel}}</AppChip>&ndash;&gt;-->
<!--                                          <AppChip small label class="mr-2 mt-1" color="deep-cyan" v-if="item.hasTestTypes && item.hasTestTypes.neuromotor">{{measuresCategories.neuromotor.shortLabel}}</AppChip>-->
<!--                                          <AppChip small label class="mr-2 mt-1" color="dark-slate-blue" v-if="item.hasTestTypes && item.hasTestTypes.ocular">{{measuresCategories.ocular.shortLabel}}</AppChip>-->
<!--                                          <AppChip small label class="mr-2 mt-1" color="grey" v-if="item.hasTestTypes && item.hasTestTypes.survey">{{measuresCategories.survey.shortLabel}}</AppChip>-->
<!--                                          <AppChip small label class="mr-2 mt-1" color="grey" v-if="item.hasTestTypes && item.hasTestTypes.covid">{{measuresCategories.covid.shortLabel}}</AppChip>-->
<!--                                          <AppChip small label class="mr-2 mt-1" color="grey" v-if="item.hasTestTypes && item.hasTestTypes.symptoms">{{measuresCategories.symptoms.shortLabel}}</AppChip>-->
                                      </div>
                                  </div>
                                    

                                </div>
                            </div>
                        <div style="flex: 1; border-right: 1px solid var(--v-border-base); background-color: var(--v-background-base); flex: 1">
                        </div>
                       
                    </v-col>
                    <v-col 
	                    cols="9" 
	                    style="height: 1000px; overflow-y: scroll; position: relative" 
	                    :style="{backgroundColor: editingScores ? '#ffebee' : 'var(--v-background-base)'}"
                    >
	                    <div 
		                    v-if="editingScores" 
		                    style="background-color: #FE0500; display: flex; justify-content: center; padding: .25rem; color: white;  position: sticky; top: 0; z-index: 1"
	                    >
		                    <div style="flex: 1" />
		                    <div style="flex: 1; text-align: center; font-weight: bold;">
			                    Edit Mode
		                    </div >
		                    
		                    <div style="flex: 1; text-align: right" @click="editingScores = false" class="pointer">
			                    Cancel
		                    </div>
	                    </div>
                        
	                    <div class="test-header">
                        
		                    <div style="flex: 1">
                                <div v-if="currentTest.metadata">
                                    <TestMetadataDetails :metadata="currentTest.metadata"/>
                                </div>
                                <div v-if="currentTest.data.administeredAccountEmail && !this.$store.state.currentAccount.viewingAsSuper" >
                                    <span style="color: #757575">Administered By: </span>{{currentTest.data.administeredAccountEmail}}
                                </div>
                                <div @click="goToCode(currentTest.data.administeredCodeId)" style="cursor: pointer" v-if="currentTest.data.administeredCode" >
                                    <span style="color: #757575">Code used: </span><strong>{{currentTest.data.administeredCode}}</strong>
                                </div>

		                    </div>
		                    <div>
			                    <app-btn @click="editingScores = true" small color="secondary" v-show="!editingScores" class="mr-2">
				                    <v-icon class="mr-2" small>mdi-pencil</v-icon>
				                    Edit Test Scores
			                    </app-btn>
		                    </div>
                            <div v-if="$store.state.currentAccount.isSuperAdmin">
                                <app-btn @click="testDetailsDialogOpen = true" small>👨‍🚀 Get Profile Test Details</app-btn>
                                <app-btn v-if="hasDetailedTestGraph(currentTest)" @click="openInternalGraphs" small>👨‍🚀 Get Detailed Test Graphs</app-btn>
                            </div>
		                    
	                    </div>
                        <div style="padding: 10px">
                            <div >
	                            
	                            
<!--                                <div style="color: #757575" v-if="currentTest.data.note" >-->
<!--                                    Notes: {{currentTest.data.note}}-->
<!--                                </div>-->
<!--                                <div class="pdf-section-title">-->
<!--                                    Test Summary-->
<!--                                </div>-->
	                            <TestCard title="Notes">
		                            <template >
			                            <div v-if="currentTest.data.note">
				                            {{currentTest.data.note}}
			                            </div>
			                            <div v-else>
				                            <app-btn @click="editNotesDialogOpen = true" color="white" small>
					                            <v-icon class="mr-2" small>mdi-pencil</v-icon>
					                            Add Note
				                            </app-btn>
			                            </div>
			                            
		                            </template>
		                            <template #header-right>
			                            <div v-if="currentTest.data.note">
				
				                            <div>
					                            <app-btn @click="editNotesDialogOpen = true" small color="white">
						                            <v-icon class="mr-2" small>mdi-pencil</v-icon>
						                            Edit Note
					                            </app-btn>
				                            </div>
			
			                            </div>
			                           
		                            </template>
	                            </TestCard>
	
	
	                            <div >
		
		                            <TestCard title="Symptoms" :test-category="10" v-if="currentTest.formattedTestsData['9999']" style="margin-top: 1rem" >
			                            <template >
				                            <SymptomsResults :profileTest="currentTest" :key="currentTest.id" show-dropdown/>
			                            </template>
                                        <template #header-right>
                                            <div style="" v-if="currentTest.formattedTestsData['9999'].languageCode">
                                                <span style="float: right; font-size:16px; padding-right:16px">
                                                    <v-icon style="font-size:20px; color:black">mdi-translate-variant</v-icon>{{currentTest.formattedTestsData['9999'].languageCode}}
                                                </span>
                                            </div>
                                        </template>
		                            </TestCard>
	
	                            </div>
	
	                            <div v-for="(measure, measureId) in currentTest.formattedTestsData" :key="measureId" style="margin-top: 1rem">
	                            
		                            
		                            <div v-if="measure.category === 10">
			
<!--			                            <TestCard title="Symptoms" :test-category="10">-->
<!--				                            <template >-->
<!--					                            <SymptomsSummarySection :profileTest="currentTest" :key="currentTest.id" show-dropdown/>-->
<!--				                            </template>-->
<!--			                            </TestCard>-->
		
		                            </div>
		
		
		
		                            <div v-else-if="measure.category == 1 || measure.category == 2 || measure.category == 11">
			
			                            <TestResultsCard
				                            :show-dropdown="true"
				                            :id="measureId"
				                            :profileTest="currentTest"
				                            :name="measure.label"
				                            :measureResult="measure"
				                            :editMode="editingScores"
				                            @excludeIncludeTestResults="excludeIncludeTestResults"
                                            @openCobaltGraphs="openCobaltGraphs"
			                            />
		                            </div>
		                            <div v-else>
			                            <TestCard
				                            v-if="(measureId == 1013 && currentTest.formattedTestsData['1013'])
				                                    || (measureId == 1018 && currentTest.formattedTestsData['1018'])
				                                    || (measureId == 1027 && currentTest.formattedTestsData['1027'])"
				                            title="On Field Assessment"
				                            :testCategory="8"
			                            >
				                            <OnFieldAssessmentResults :profileTest="currentTest" />
			                            </TestCard>
			
			                            <TestCard
				                            v-if="measureId == 1014 && currentTest.formattedTestsData['1014']"
				                            title="COVID-19"
				                            :testCategory="8">
				                            <CovidTestResults :profileTest="currentTest" />
			                            </TestCard>
			
			                            <TestCard
				                            v-if="measureId == 1016 && currentTest.formattedTestsData['1016']"
				                            title="PHQ-9"
				                            :testCategory="8">
				                            <SurveyResultsWithTable :measureId="1016" :formattedTest="currentTest.formattedTestsData['1016']"/>
			                            </TestCard>
			
			                            <TestCard
				                            v-if="measureId == 1017 && currentTest.formattedTestsData['1017']"
				                            title="GAD-7"
				                            :testCategory="8">
				                            <SurveyResultsWithTable :measureId="1017" :formattedTest="currentTest.formattedTestsData['1017']"/>
			                            </TestCard>

                                        <TestCard
                                            v-if="measureId == 1026 && currentTest.formattedTestsData['1026']"
                                            title="Sleep Survey"
                                            :testCategory="8">
                                            <SurveyResultsWithTable :measureId="1026" :formattedTest="currentTest.formattedTestsData['1026']"/>
                                        </TestCard>
			
			                            <TestCard
				                            v-if="measureId == 1020 && currentTest.formattedTestsData['1020']"
				                            title="VOMS"
				                            :testCategory="8">
				                            <VomsTable :individual-measure="currentTest.formattedTestsData['1020']" />
                                            <template #header-right>
                                                <div style="" v-if="currentTest.formattedTestsData['1020'].languageCode">
                                                <span style="float: right; font-size:16px; padding-right:16px">
                                                    <v-icon style="font-size:20px; color:black">mdi-translate-variant</v-icon>{{currentTest.formattedTestsData['1020'].languageCode}}
                                                </span>
                                                </div>
                                            </template>
			                            </TestCard>
		                            </div>
	
	                            </div>
	                            
<!--                            <div v-else>-->
<!--                                <ProfileTrends :profile="profile"/>-->
<!--                            </div>-->
                            
                        </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </app-card>
        <v-dialog
            v-model="testDetailsDialogOpen"
            @click:outside="testDetailsDialogOpen = false"
            hide-overlay
            transition="dialog-bottom-transition"
            max-width="80%"
            style="background-color: white"

        >
            <div style="flex: 1; background-color: white" v-if="testDetailsDialogOpen">
                 <TestAccelerometerData :id="currentTest.id" />
            </div>

        </v-dialog>
	
	    <dialog-form
		    :open="editNotesDialogOpen"
		    title="Edit Note"
		    @close="editNotesDialogOpen = false"
		    @submit="editNote"
		    :width="600"
		    :saving="savingNote"
	    >
		    <template v-slot:form-content>
			    <EditTestNoteForm
				    :form="editNoteForm"
			    />
<!--			    <AppTextField-->
<!--				    v-model="editNoteForm.text"-->
<!--				    label="Note"-->
<!--				    error-label="Note"-->
<!--				    :rows="20"-->
<!--			    />-->
		    </template>
	    </dialog-form>
	    
        <v-dialog
                v-model="detailGraphDialogOpen"
                @click:outside="detailGraphDialogOpen = false"
                hide-overlay
                transition="dialog-bottom-transition"
                max-width="80%"
                style="background-color: white"
        >
            <div style="flex: 1; background-color: white" v-if="detailGraphDialogOpen">
                <div v-if="detailGraphType === 'cobalt'">
                    <CobaltGraphPopup :profile-test-id="currentTest.id"
                                      :profile-test-result-id="selectedResultId"/>
                </div>
                <div v-else-if="detailGraphType === 'internal'">
                    <InternalGraphPopup :id="currentTest.id" />
                </div>
            </div>

        </v-dialog>
    </div>
</template>

<script>
import moment from "moment";
import PDFExport from "@/components/pdf-export";
import SectionWrapper from "@/components/pdf-export/SectionWrapper";
import CategoryTestsSummary from "@/components/pdf-export/CategoryTestsSummary";
import {measuresCategoriesById, measuresCategories} from "@/js/shared/constants";
import ProfileTrends from "@/components/pdf-export/ProfileTrends";
import OnFieldAssessmentPdfSection from "@/components/pdf-export/OnFieldAssessmentPdfSection";
import CovidTestResults from "@/components/Profile/TestsViewer/CovidTestResults";
import TestTypeDot from "@/components/TestTypeDot";
import SymptomsSummarySection from "@/components/pdf-export/SymptomsSummarySection";
import SymptomsDetailsSection from "@/components/pdf-export/SymptomsDetailsSection";
import TestAccelerometerData from "@/components/Profile/TestAccelerometerData";
import InternalGraphPopup from "@/components/ProfileTest/InternalGraphPopup";
import SurveyResultsWithTable from "@/components/Profile/TestsViewer/Surveys/SurveyResultsWithTable";
import TestTypeDotExplanation from "@/components/pdf-export/components/TestTypeDotExplanation";
import VomsTable from "@/components/pdf-export/VomsTable";
import {hasDetailedTestGraph} from "@/js/shared/helper";
import EditTestNoteForm from "@/components/Profile/TestsViewer/EditTestNoteForm";
import {excludeIncludeTestResults, updateProfileTestNote} from "@/services/profile.service";
import {SET_SNACKBAR} from "@/store/mutation-types";
import TestResultsCard from "@/components/Profile/TestsViewer/TestResultsCard";
import TestCard from "@/components/Profile/TestsViewer/TestCard";
import SymptomsResults from "@/components/Profile/TestsViewer/SymptomsResults";
import OnFieldAssessmentResults from "@/components/Profile/TestsViewer/OnFieldAssessmentResults";
import TestMetadataDetails from "@/components/pdf-export/components/TestMetadataDetails.vue";
import balanceIcon from '@/assets/svgs/icons/balance-icon-square.svg'
import cognitiveIcon from '@/assets/svgs/icons/cognitive-icon-square.svg'
import functionIcon from '@/assets/svgs/icons/function-icon-square.svg'
import surveyIcon from '@/assets/svgs/icons/survey-icon-square.svg'
import CobaltGraphPopup from "@/components/ProfileTest/CobaltGraphPopup.vue";
import {getOrganizationId} from "@/services/userService";

export default {
    name: "TestsViewer",
    components: {
        CobaltGraphPopup,
        TestMetadataDetails,
	    OnFieldAssessmentResults,
	    SymptomsResults,
	    TestCard,
	    TestResultsCard,
	    EditTestNoteForm,
	    VomsTable,
	    TestTypeDotExplanation,
        SurveyResultsWithTable,
        TestAccelerometerData,
        InternalGraphPopup,
        SymptomsDetailsSection,
        SymptomsSummarySection,
        TestTypeDot,
        CovidTestResults,
        OnFieldAssessmentPdfSection, ProfileTrends, CategoryTestsSummary, SectionWrapper, PDFExport},
    props: {
        profile: {
            required: true
        }
    },
    data() {
        return {
            cardFlipped: false,
            measuresCategoriesById,
            measuresCategories,
            testDetailsDialogOpen: false,
            detailGraphDialogOpen: false,
            detailGraphType: '',
	
	        editNoteForm: {
				text: ''
	        },
	        editNotesDialogOpen: false,
	        savingNote: false,
	        
	        editingScores: false,
	        savingEditedTest: false,
	
	        balanceIcon,
	        cognitiveIcon,
	        functionIcon,
	        surveyIcon,
            
            selectedResultId: null
        }
    },

    watch: {
        currentTest() {
            this.init()
        }
    },
	mounted() {
		this.init();
		// this.editNote()
	},
    computed: {
        currentTestId() {
            if (this.$route.query.testId) {
                return parseInt(this.$route.query.testId)
            } else {
                return this.profile.tests[0].id
            }
            
        },
        currentTest() {
			console.log('computing currentTest')
            return this.profile.tests.find(test => test.id === this.currentTestId)
        },
        measureResultsByCategory() {
            if (!this.currentTest) return null
            return this.currentTest.getMeasureResultsByCategory()
        },

	    
    },

    methods: {
		init() {
			this.clearNote()
			const {note} = this.currentTest.data
			this.editNoteForm.text = note
		},
	    clearNote() {
			this.editNoteForm.text = ''
	    },
        displayTestDate(item) {
            return moment(item.data.completedOn).format('MMM D, YYYY')
        },
        displayTestDateSubheader(item) {
            return moment(item.data.completedOn).format('dddd @ h:mm a')
        },
        onTestClick(test) {

            this.$router.push({path: this.$route.currentPath, query: {testId: test.id}})
        },
        goToCode(codeId) {
            this.$router.push(`/codes/${codeId}`)
        },
        hasDetailedTestGraph(currentTest) {
            return hasDetailedTestGraph(currentTest);
        },
        hasCobaltTestGraph(currentTest) {
            const isApprovedOrg = this.$store.state.currentAccount.isSuperAdmin || getOrganizationId() === 8322; // banner and super admins only
            return isApprovedOrg && currentTest && currentTest.formattedTestsData &&
                    (currentTest.formattedTestsData['78'] || currentTest.formattedTestsData['80'] || currentTest.formattedTestsData['1022']
                            || currentTest.formattedTestsData['1023'] || currentTest.formattedTestsData['1025']);
        },
	    async editNote() {
		    this.savingNote = true
		    let note = this.editNoteForm.text;
			const {profile, currentTest} = this;
		    
		    let request = {
			    profileId: profile.profileInfo.id,
			    profileTestId: currentTest.id,
			    note: note
		    }
		    
		    let response = await updateProfileTestNote(request);
		
		    if (!response.isSuccess) {
			    this.$store.commit(SET_SNACKBAR, {
				    text: response.errors[0].message,
				    color: 'error',
				    open: true
			    });
			    this.savingNote = false;
			    return
		    }
		
		    this.editNotesDialogOpen = false;
		    this.savingNote = false;
			
		    this.$store.commit(SET_SNACKBAR, {
			    text: 'Test Note Successfully Updated',
			    color: 'success',
			    open: true
		    });
		
		    this.$emit("testUpdated")
			
	    },
	    async excludeIncludeTestResults(request) {
		
		    this.savingEditedTest = true
			
		    let response = await excludeIncludeTestResults(request);
		
		    if (!response.isSuccess) {
			    this.$store.commit(SET_SNACKBAR, {
				    text: response.errors[0].message,
				    color: 'error',
				    open: true
			    });
			    this.savingEditedTest = false;
			    return
		    }
		
		    
		    this.savingEditedTest = false;
		
		    this.$store.commit(SET_SNACKBAR, {
			    text: 'Test Score Successfully Updated',
			    color: 'success',
			    open: true
		    });
		
		    this.$emit("testUpdated")
			
	    },
        async openInternalGraphs() {
            this.detailGraphType = 'internal';
            this.detailGraphDialogOpen = true;
        },
        async openCobaltGraphs(resultId) {
            this.selectedResultId = resultId;
            this.detailGraphType = 'cobalt';
            this.detailGraphDialogOpen = true;
        }
    }
}
</script>

<style scoped>
.previous-test-container {
    
    padding: 1rem;
    border-bottom: 1px solid var(--v-border-base)
  }

.previous-test-container:hover {
    /*background-color: var(--v-background-darken2);*/
    cursor: pointer;
}

.dot {
    height: 12px;
    width: 12px;
    border-radius: 6px;
}

.baseline {
    border: 3px solid var(--v-primary-base);
}

.event {
    background-color: var(--v-secondary-base);
}

.scrollbar-gutter {
    scrollbar: white;
}

.underline {
    width: 100%; height: 1px; background-color: #e0e0e0; margin-bottom: 10px
}

.test-header {
	display: flex; 
	background-color: white; 
	padding: 1rem;
	border-bottom: 1px solid var(--v-border-base)
}

.test-type-icon {
	height: 1.5rem;
	margin-right: .25rem;
	border-radius: 5px;
}
</style>