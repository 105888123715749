<template>
	<div>
		<SectionWrapper title="VOMS" title-only>
			<VomsTable :individual-measure="profileTest.formattedTestsData['1020']" />
		</SectionWrapper>
		
	</div>
</template>

<script>
import VomsTable from "@/components/pdf-export/VomsTable";
import SectionWrapper from "@/components/pdf-export/SectionWrapper";
export default {
	name: "VomsSummarySection",
	components: {SectionWrapper, VomsTable},
	props: {
		profileTest: {
			required: true
		}
	}
}
</script>

<style scoped>

</style>