<template>
    <div v-if="account">
        <div v-if="account.accountTypeId !== 4">

            <div style="display: flex; flex-direction: column; justify-content: center">
                <div class="overline" style="color: #757575">
                    ACCOUNT
                </div>
                <page-header :title="account.firstName + ' ' +account.lastName" class="mb-2">
                    <template #title-right>
                        <app-chip v-if="isAdmin" color="blue" label>Admin</app-chip>
                        <app-chip v-else color="green" label>User</app-chip>
                    </template>
                </page-header>

            </div>

            <!--        <div class="mt-2">-->
            <!--            <app-tabs>-->
            <!--                <app-tab>Info</app-tab>-->
            <!--                <app-tab>Security</app-tab>-->
            <!--            </app-tabs>-->
            <!--        </div>-->
            <div >
                <app-tabs :value="currentTab">
                    <app-tab @click="$router.push(`/account/personal-information`)" icon="mdi-account-outline">Personal Information</app-tab>
                    <app-tab @click="$router.push(`/account/password`)" icon="mdi-lock-outline">Password</app-tab>
                    <app-tab @click="$router.push(`/account/groups`)" icon="mdi-account-group">Groups </app-tab>
                </app-tabs>
            </div>
            <div style="display: flex">
                <router-view
                    :account="account"
                    :isAdmin="isAdmin"
                    :accountGroups="accountGroups"
                    @editAccount="editAccountDialogOpen = true"
                />


            </div>

            
            <dialog-form
                title="Edit Account Info"
                :open="editAccountDialogOpen"
                @close="editAccountDialogOpen = false"
                :width="650"
                @submit="onEditAccountSubmit"
                :saving="savingAccountChanges"
            >
                <template #form-content>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <app-text-field label="First Name" v-model="editAccountForm.firstName" rules="required"></app-text-field>
                            </v-col>
                            <v-col cols="6">
                                <app-text-field label="Last Name" v-model="editAccountForm.lastName"></app-text-field>
                            </v-col>
                            <v-col cols="6">
                                <app-text-field label="Email" v-model="editAccountForm.email"></app-text-field>
                            </v-col>
                            <v-col cols="6">
                                <app-text-field label="Phone Number" v-model="editAccountForm.phoneNumber"></app-text-field>
                            </v-col>
                            <v-col cols="6">
                                <app-select :items="professionalTypes" label="Medical Credentials" v-model="editAccountForm.professionalTypeId"></app-select>
                            </v-col>
                            <v-col cols="6">
                                <app-text-field label="Professional Designation" v-model="editAccountForm.jobTitle"></app-text-field>
                            </v-col>
                            <v-col cols="6" v-if="isAdmin">
                                <app-form-checkbox label="Is Billing Contact" v-model="editAccountForm.isBillingContact"></app-form-checkbox>
                            </v-col>
                            <v-col cols="6" v-if="isSuperAdmin">
                                <app-form-checkbox label="Is Optimized Sync Enabled" v-model="editAccountForm.isOptimizedSyncEnabled"></app-form-checkbox>
                            </v-col>
                            <v-col cols="6" v-if="isSuperAdmin">
                                <app-text-field label="Optimized Sync Lookback Days" v-model="editAccountForm.optimizedSyncTimePeriodDays"></app-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                    <!--                <app-text-field label="Last Name" v-model="editAccountForm.lastName"></app-text-field>-->
                    <!--                <app-text-field label="Email" v-model="editAccountForm.email"></app-text-field>-->
                    <!--                <app-text-field label="Phone Number" v-model="editAccountForm.phoneNumber"></app-text-field>-->
                    <!--                <app-text-field label="Job Title" v-model="editAccountForm.jobTitle"></app-text-field>-->
                    <!--                <app-form-checkbox label="Is Admin" v-model="editAccountForm.isAdmin"></app-form-checkbox>-->
                </template>

            </dialog-form>
        </div>
        <div v-else>
            <app-card title="Account Info"  class="mb-4" style="max-width: 400px">
                <div class="pa-4">
                    <div v-for="item in covidAccountInfo" >
                        <span style="font-weight: bold">{{item.label}}: </span><span>{{item.value}}</span>
                    </div>
                </div>
                
            </app-card>
            
            
            <ChangePassword 
                :account="account"
                            :isAdmin="isAdmin"
                            :accountGroups="accountGroups"
                            @editAccount="editAccountDialogOpen = true"
            />
            <div class="mt-4">
                <h3>
                    Helpful links
                </h3>
                <div v-for="link in covidAccountLinks" style="display: flex">
                    <a :href="link.url" target="_blank" style="font-weight: bold">{{link.label}}</a>
                </div>
            </div>
        </div>
    </div>
    

</template>

<script>
import ChangePassword from "@/components/Account/ChangePassword";
import {
    accountModel,
    updateAccount,
    getAccount,
    updateAccountModel,
    checkEmailAddress,
    updateAccountInStorage
} from '@/services/userService'
import {getGroups} from '@/services/groups.service'
import {SET_SNACKBAR, SET_ACCOUNT} from "@/store/mutation-types";
import {professionalTypes} from "@/js/shared/constants"
import {getProfessionalTypeById} from "@/js/utilities"
import {isAdminOrgAccessType} from "@/js/shared/helper";

export default {
    name: "Account",
    components: {
        ChangePassword
    },
    props: {
        account: Object
    },
    data() {
        return {
            snackbar: false,
            editAccountDialogOpen: false,
            editAccountForm: {
                firstName: this.account.firstName,
                lastName: this.account.lastName,
                email: this.account.email,
                phoneNumber: this.account.phoneNumber,
                professionalTypeId: this.account.professionalTypeId,
                jobTitle: this.account.jobTitle,
                isBillingContact: this.account.isBillingContact,
                isOptimizedSyncEnabled: this.account.isOptimizedSyncEnabled,
                optimizedSyncTimePeriodDays: this.account.optimizedSyncTimePeriodDays
            },

            savingAccountChanges: false,
            editingAccountGroups: false,
            submittingEditGroups: false,
            accountGroups: [],
            groups: [],
            professionalTypes,
            
            covidAccountLinks: [
                {
                    label: `Sway Medical Website`,
                    url: `https://www.swaymedical.com/`
                },
                {
                    label: `Tutorial Videos`,
                    url: `https://www.swaymedical.com/resources/tutorial-videos`
                },
                
            ],
        }
    },
    mounted() {
        if (this.account.accountTypeId !== 4) {
            this.initAccountGroups()
            this.getGroups()
        }



    },
    watch: {
        account(newValue, oldValue) {
            console.log('NEW VALUE: ', newValue)
        }
    },
    computed: {
        isAdmin() {
            return isAdminOrgAccessType(this.account.selectedOrganization?.organizationAccessTypeId) || isAdminOrgAccessType(this.account.selectedOrganizationAccessTypeId)
        },
        isSuperAdmin(){
            return this.$store.state.currentAccount && this.$store.state.currentAccount.isSuperAdmin
        },
        currentTab() {
            const last = this.$route.path.split('/').pop()
            switch (last) {
                case 'password':
                    return 1;
                case 'groups':
                    return 2;
                default:
                    return 0
            }
        },
        covidAccountInfo() {
            if (this.account.accountTypeId !== 4) return []
            return [
                {
                    label: 'Name',
                    value: this.account.firstName + ' ' + this.account.lastName
                },
                {
                    label: 'Email',
                    value: this.account.email
                },
                
            ]
        }

    },
    methods: {
        async initAccountGroups() {
            
            let response = await getAccount(this.account.id);

            if (!response.isSuccess) return;
            this.accountGroups = response.groups

        },

        async getGroups() {
            const response = await getGroups()
            if (!response.isSuccess) return
            this.groups = response.groups
        },
        async changePassword() {
            // const {
            //     oldPassword,
            //     newPassword,
            //     confirmNewPassword
            // } = this.changePasswordForm;

        },

        async onEditAccountSubmit() {
            this.savingAccountChanges = true
            
            let request = {...updateAccountModel}

            const {
                firstName,
                lastName,
                email,
                phoneNumber,
                jobTitle,
                professionalTypeId,
                isSafetyCoach,
                isBillingContact,
                isOptimizedSyncEnabled,
                optimizedSyncTimePeriodDays
            } = this.editAccountForm
            
            request.id = this.account.id;
            request.firstName = firstName;
            request.lastName = lastName;
            request.email = email;
            request.phoneNumber = phoneNumber;
            request.jobTitle = jobTitle;
            request.professionalTypeId = professionalTypeId;
            request.isDisabled = this.account.isDisabled;
            request.isSafetyCoach = isSafetyCoach;
            request.isBillingContact = isBillingContact;
            request.accountTypeId = this.account.accountTypeId;
            request.organizationAccessTypeId = this.account.selectedOrganizationAccessTypeId;
            request.isChangingEmail = false;
            request.isOptimizedSyncEnabled = isOptimizedSyncEnabled;
            request.optimizedSyncTimePeriodDays = optimizedSyncTimePeriodDays;
            
            if (request.email !== this.account.email) {
                const response = await checkEmailAddress(request.email);
                if (!response.isSuccess) return
                // const emailAlreadyExist = response.accountExists

                if (response.accountExists) {
                    this.$store.commit(SET_SNACKBAR, {
                        text: 'Email Address Already Exists',
                        color: 'error',
                        open: true
                    })
                    this.savingAccountChanges = false;
                    return
                }

                request.isChangingEmail = true;
            }

            const response = await updateAccount(request);
            // this.account = response.account;
            
            //TODO: get the backend to send the selectedOrganization back with the account if possible. this feels jank
            response.account.selectedOrganization = this.account.selectedOrganization
            this.$emit('setAccount', response.account)
            this.$store.commit(SET_ACCOUNT, response.account);
            updateAccountInStorage(response.account)


            this.editAccountDialogOpen = false;
            this.savingAccountChanges = false;
            // 
            this.$store.commit(SET_SNACKBAR, {
                text: 'Account Successfully Updated',
                color: 'success',
                open: true
            });
        }
    }
}
</script>

<style scoped>
.edit-icon:hover {
    cursor: pointer
}





</style>