<template>
    <div>
        <div style="padding-bottom: 50px">
            <div v-if="isDataLoaded" id="manage-billing-page">
<!--                <h1>Profile Usage for {{orgName}}</h1>-->

			<v-container fluid>
				<v-row>
					<v-col sm="12" xl="9" >
						<AppCard
							title="Clinical Profile Purchase History"
							v-if="clinicalProfileUsageHistory"
							class="mt-4"
						>
							
							<v-simple-table >
								<thead>
								<tr>
									<th>
										Date
									</th>
									<th>
										Credit Type
									</th>
									
									<th>
										Profile Count
									</th>
									<th v-if="hasMultipleOrganizationUsage">
										Organization
									</th>
									<th>
										Invoice Id
									</th>
									<th>
										Subscription Id
									</th>
									<th>
										Subscription Dates
									</th>
									<th>
										Description
									</th>
									<th>
										Edit
									</th>
									<th v-if="!editUsageId">
										Delete
									</th>
								</tr>
								</thead>
								<tbody>
								<tr v-for="(item, index) in clinicalProfileUsageHistory" :key="index">
									<td>
										<span>{{formatDate(item.usageEnd)}}</span>
									</td>
									<td>
										<AppChip :color="item.usageType.chipColor" small label>{{item.usageType.text}}</AppChip>
										
									</td>
									
									<td>
										<app-text-field v-if="editUsageId && editUsageId === item.profileUsageId" v-model="editUsageCount" rules="number"></app-text-field>
										<span v-else>{{item.usageCount}}</span>
									</td>
									<td v-if="hasMultipleOrganizationUsage">
										<span>{{item.organizationName}}</span>
									</td>
									<td>
										<span>{{item.invoiceId}}</span>
									</td>
									<td>
										<span>{{item.subscriptionId}}</span>
									</td>
									<td>
										<span>{{item.subscriptionDates}}</span>
									</td>
									<td>
										<app-text-field v-if="editUsageId && editUsageId === item.profileUsageId" v-model="editUsageDescription"></app-text-field>
										<span v-else>{{item.description}}</span>
									</td>
									<td>
                                    <span v-if="editUsageId && editUsageId === item.profileUsageId">
                                        <a @click="confirmEdits">Confirm</a> | <a @click="editUsageId = null">Cancel</a>
                                    </span>
									<span v-else-if="item.usageTypeId != 5"><a @click="editUsage(item)">Edit</a></span>
									</td>
									<td v-if="!editUsageId">
										<a v-if="item.usageTypeId != 5" @click="showConfirmDeleteDialog(item.profileUsageId)">X</a>
									</td>
								</tr>
								</tbody>
							</v-simple-table>
							<br />
							<div class="pa-4">
								<span>Profiles Credits Remaining: {{clinicalProfilesRemaining}}</span>
								<div>
									<app-btn @click="showAddUsagePopup" icon="mdi-plus">
										Add Credits
									</app-btn>
								</div>
							</div>
						
						</AppCard>
					</v-col>
				</v-row>
			</v-container>
                

<!--                <AppCard class="mt-4" title="Sports Profile Purchase History">-->
<!--					<v-simple-table>  -->
<!--						<template #default>-->
<!--							<thead>-->
<!--							<tr>-->
<!--								<th>Subscription ID</th>-->
<!--								<th>Period</th>-->
<!--								<th>Organization Name</th>-->
<!--								<th>Credit Type</th>-->
<!--								<th>Credit Count</th>-->
<!--							</tr>-->
<!--								-->
<!--							</thead>-->
<!--							<tbody>-->
<!--								<tr v-for="(item, index) in sportsProfileUsageHistory" :key="index">-->
<!--									<td>{{item.subscriptionId}}</td>-->
<!--									<td :rowspan="index === 1 ? 1 : 1">{{item.usageStart}} - {{item.usageEnd}}</td>-->
<!--									<td>{{item.organizationName}}</td>-->
<!--									<td>-->
<!--										-->
<!--											<div>-->
<!--												<AppChip :color="item.usageType.chipColor" label small>{{item.usageType.text}}</AppChip>-->
<!--											</div>-->
<!--							-->
<!--									</td>-->
<!--									<td>{{item.usageCount}}</td>-->
<!--								</tr>-->
<!--							</tbody>-->
<!--						</template>-->
<!--						-->
<!--&lt;!&ndash;						<template #item.usageStart="{item}">&ndash;&gt;-->
<!--&lt;!&ndash;							<div >&ndash;&gt;-->
<!--&lt;!&ndash;								{{item.usageStart}} - {{item.usageEnd}}&ndash;&gt;-->
<!--&lt;!&ndash;							</div>&ndash;&gt;-->
<!--&lt;!&ndash;						</template>&ndash;&gt;-->
<!--						-->
<!--					</v-simple-table>-->
<!--	                <br />-->
<!--	                <span>Profiles Credits Remaining: {{sportsProfilesRemaining}}</span>-->
<!--                </AppCard>-->
	            <v-alert
		            type="info"
		            text
		            v-if="billingInfo.billToOrgId"
		            
	            >
		            <strong>Billing Parent Org: {{billingInfo.billToOrgName}} ({{billingInfo.billToOrgId}})</strong>
		            <div style="font-size: .9rem">
			            To view the usage history of this organization, visit the <a href="" @click="goToParentOrg">Parent Billing Organization's Usage here.</a>
		            </div>
	            </v-alert>
	            <AppCard class="mt-4" title="Sports Profile Purchase History" v-if="sportsHistoricalUsageTable">
		            <template #header-bottom>
			            <div style="font-weight: 400">
				            Distinct profiles tested during period. Does not matter if deleted, inactive, or active. Only tested profiles.
			            </div>
			            
		            </template>
		            <v-simple-table >
			            <template #default>
				            <thead>
				            <tr>
					            <th>Subscription</th>
					            <th>Period</th>
					            <th>Organization Name</th>
					            <th>Credit Type</th>
					            <th>Credit Count</th>
				            </tr>
				
				            </thead>
				            <tbody>
				            <tr v-for="(row, index) in sportsHistoricalUsageTable" :key="index">
					            <td :rowspan="row.rowspan" v-if="row.isSubscription && !row.isProfileCreditBalance" >{{ row.productSubscriptionId }} <AppChip :color="row.subscription.color.chip" small label>{{row.subscription.displayName}}</AppChip></td>
					            <td :rowspan="row.rowspan" v-if="row.isSubscription  && !row.isProfileCreditBalance">{{ row.subscription.startDate.display }} - {{row.subscription.endDate.display}}</td>
					            <td v-if="!row.isProfileCreditBalance" class="table-left-border">{{ row.organizationName }}</td>
					            <td v-if="!row.isProfileCreditBalance"><AppChip :color="row.usageType.chipColor" label small>{{row.usageType.text}}</AppChip></td>
					            <td v-if="!row.isProfileCreditBalance">{{ row.usageCount }}</td>
					            <td 
						            v-if="row.isProfileCreditBalance" 
						            :colspan="3"
						            class="table-left-border"
						            style="background-color: #fafafa; text-align: center"
						            :style="{color: row.profileCreditBalance >= 0 ? null : 'red', backgroundColor: row.profileCreditBalance >= 0 ? '#fafafa' : '#ffebee'}"	
						            				            >
						            <strong>Profile Credit Balance: {{row.profileCreditBalance}}</strong>
					            </td>
				            </tr>
				            </tbody>
			            </template>
		
		            </v-simple-table>
<!--		            <br />-->
<!--		            <span>Profiles Credits Remaining: {{sportsProfilesRemaining}}</span>-->
	            </AppCard>


                <br>
                <br>
                <h3>Monthly Profile Usage</h3>
                <h4>Note: Distinct profiles tested during period. Does not matter if deleted, inactive, or active. Only tested profiles.</h4>

                <table id="profile-monthly-count-table">
                    <thead>
                        <tr>
                            <td>
                                Start Date
                            </td>
                            <td>
                                End Date
                            </td>
                            <td>
                                Distinct Tested Profiles
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in monthlyProfileCounts" :key="index">
                            <td>
                                <span>{{formatDate(item.startDate)}}</span>
                            </td>
                            <td>
                                <span>{{formatDate(item.endDate)}}</span>
                            </td>
                            <td>
                                <span>{{item.distinctProfileCount}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br>
                <br>
                <h3>Yearly Profile Usage</h3>
                <h4>Note: Distinct profiles tested during period. Does not matter if deleted, inactive, or active. Only tested profiles.</h4>

                <table id="profile-yearly-count-table">
                    <thead>
                        <tr>
                            <td>
                                Start Date
                            </td>
                            <td>
                                End Date
                            </td>
                            <td>
                                Distinct Tested Profiles
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in yearlyProfileCounts" :key="index">
                            <td>
                                <span>{{formatDate(item.startDate)}}</span>
                            </td>
                            <td>
                                <span>{{formatDate(item.endDate)}}</span>
                            </td>
                            <td>
                                <span>{{item.distinctProfileCount}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div v-else>
                <app-page-loader :text="'Loading'"></app-page-loader>
            </div>
            <br>
            <br>
            <br>
        </div>

<!--        <v-dialog v-model="isAddUsageDialogOpen"-->
<!--                  @click:outside="closeDialog"-->
<!--                  width="800">-->
<!--            <v-card>-->
<!--                <v-card-title>-->
<!--                    <h3>Add Profile Credit/Usage</h3><br />-->
<!--                </v-card-title>-->

<!--                <div>-->
<!--                    <div class="inline-block-field">-->
<!--                        <app-select v-model="addUsageType" label="Usage Type" :items="profileCreditUsageTypes" item-text="text" item-value="value" />-->
<!--                    </div>-->
<!--                    <div class="inline-block-field">-->
<!--                        <app-text-field v-model="addUsageCount" label="Profile Count" rules="number"></app-text-field>-->
<!--                    </div>-->
<!--                    <div class="inline-block-field">-->
<!--                        <app-text-field v-model="addUsageInvoiceId" label="Invoice Id (optional)" rules="number"></app-text-field>-->
<!--                    </div>-->
<!--                    <div class="inline-block-field">-->
<!--                        <app-text-field v-model="addSubscriptionId" label="Subscription Id (optional)" rules="number"></app-text-field>-->
<!--                    </div>-->
<!--                    <div class="inline-block-field">-->
<!--                        <app-text-field v-model="addUsageDescription" label="Description (optional)"></app-text-field>-->
<!--                    </div>-->
<!--                    <div class="inline-block-field">-->
<!--                        <app-form-date-picker v-model="addUsageDate" label="Usage Date" mask="##/##/####" placeholder="MM/DD/YYYY"></app-form-date-picker>-->
<!--                    </div>-->
<!--                </div>-->

<!--                <app-btn @click="actuallyAddUsage">-->
<!--                    Add Credit/Usage-->
<!--                </app-btn>-->
<!--            </v-card>-->
<!--        </v-dialog>-->
	
	    <DialogForm 
		    title="Add Profile Credit Usage"
		    :open="isAddUsageDialogOpen"

		    @close="isAddUsageDialogOpen = false"
		    @submit="actuallyAddUsage"
		    submit-button-title="Add Credit/Usage"
	             >

			
			    <template #form-content>
				    <div class="inline-block-field">
					    <app-select v-model="addUsageType" label="Usage Type (required)" :items="profileCreditUsageTypes" item-text="text" item-value="value" rules="required" />
				    </div>
				    <div class="inline-block-field">
					    <app-text-field v-model="addUsageCount" label="Profile Count  (required)" rules="required|positive"></app-text-field>
				    </div>
				    <div class="inline-block-field">
					    <app-text-field v-model="addUsageInvoiceId" label="Invoice Id (optional)" rules="positive"></app-text-field>
				    </div>
				    <div class="inline-block-field">
					    <app-text-field v-model="addSubscriptionId" label="Subscription Id (optional)" rules="positive"></app-text-field>
				    </div>
				    <div class="inline-block-field">
					    <app-text-field v-model="addUsageDescription" label="Description (optional)"></app-text-field>
				    </div>
				    <div class="inline-block-field">
					    <app-form-date-picker v-model="addUsageDate" label="Usage Date  (required)" mask="##/##/####" placeholder="MM/DD/YYYY" rules="required" :min="minDate"></app-form-date-picker>
				    </div>
			    </template>
	    </DialogForm>

        <app-confirm-dialog title="Confirm Delete Profile Usage"
                            submitButtonTitle="Delete"
                            :open="isConfirmDeleteDialogOpen"
                            @confirm="actuallyDeleteUsage"
                            @close="isConfirmDeleteDialogOpen = false"
                            :loading="!isDataLoaded"
                            confirm-delete>
            <div>
                Are you sure you want to delete this profile usage/credit?
            </div>
        </app-confirm-dialog>
    </div>
</template>

<script>
import {SET_SNACKBAR, SET_USER} from "@/store/mutation-types";
import httpService from "@/services/httpService";
import {baseUrl} from "@/config"
import 'moment-timezone'
import moment from "moment";
import {getMonthlyProfileCounts, getProfileUsageHistory, getYearlyProfileCounts, getAllSubscriptions} from "@/services/billing.service";
import {profileCreditUsageTypes} from "@/js/shared/constants";
import {formatDate} from "@/js/shared/helper";
import SportsSubscriptions from "@/js/shared/classes/Subscription/SportsSubscriptions";

export default {
    name: "ProfileCountTables",
	props: {
		billingInfo: {
			required: true
		}
	},
    components: {
    },
    data() {
        return {
            orgName: '',

            isDataLoaded: false,
            
            monthlyProfileCounts: [],
            yearlyProfileCounts: [],

            sportsProfileUsageHistory: null,
	        allSportsSubscriptions: null,
	        sportsHistoricalUsageTable: null,
	        
            clinicalProfileUsageHistory: null,
            hasMultipleOrganizationUsage: false,

            isAddUsageDialogOpen: false,
            addUsageType: null,
            addUsageCount: null,
            addUsageInvoiceId: null,
            addSubscriptionId: null,
            addUsageDescription: null,
            addUsageDate: null,

            editUsageId: null,
            editUsageCount: null,
            editUsageDescription: null,
            
            deleteProfileUsageId: null,
            isConfirmDeleteDialogOpen: false,

            // constant
	        profileCreditUsageTypes,
	        sportsUsageHistoryTableHeaders: [
		        {
					text: 'Period',
			        value: 'usageStart'
		        },
		        {
					text: 'Organization Name',
			        value: 'organizationName'
		        },
		        {
					text: 'Credit Type',
			        value: 'usageType'
		        },
		        {
					text: 'Credit Count',
			        value: 'usageCount'
		        },
		        
	        ]
        }
    },
    mounted() {
        this.getDataFromApi();
    },
    watch: {

    },
    methods: {
        async getDataFromApi() {
            
            this.isDataLoaded = false;
            try {
                await this.getMonthlyProfileCounts();
                await this.getYearlyProfileCounts();
                await this.getUsageHistory();
				await this.getSportsSubscriptions()
            }
            catch (e) {

            }
            this.isDataLoaded = true;
        },
        async getUsageHistory() {

            try {

                let response = await getProfileUsageHistory();
				
				
                if (response.isSuccess) {
					if (response.sportsUsageItems) {
						response.sportsUsageItems.forEach(x => {
							x.usageType = profileCreditUsageTypes.find(y => y.value === x.usageTypeId);
							// Adjust Sports to be blue
							if (x.usageType.value === 5) {
								x.usageType.text = "Profile Credits Used"
								x.usageType.chipColor = "blue"
							}
							x.usageStart = this.formatDate(x.usageStart);
							x.usageEnd = this.formatDate(x.usageEnd);
						})
					}
					
					if (response.clinicalUsageItems) {
						response.clinicalUsageItems.forEach(x => {
							x.usageType = profileCreditUsageTypes.find(y => y.value === x.usageTypeId);
							x.usageStart = this.formatDate(x.usageStart);
							x.usageEnd = this.formatDate(x.usageEnd);
						})
					}
	                
                    this.sportsProfileUsageHistory = response.sportsUsageItems;
                    this.sportsProfilesRemaining = response.sportsProfilesRemaining;
                    this.clinicalProfileUsageHistory = response.clinicalUsageItems;
                    this.clinicalProfilesRemaining = response.clinicalProfilesRemaining;
                    this.hasMultipleOrganizationUsage = response.hasMultipleOrganizationUsage;

                }
            }
            catch (e) {

            }
        },
	    async getSportsSubscriptions() {
			let response = await getAllSubscriptions()
		    let sportsSubscriptions = response.productSubscriptions.map(x => {
			    return x.model.sports
		    })
		
		    this.allSportsSubscriptions = new SportsSubscriptions(sportsSubscriptions);
			this.allSportsSubscriptions.addUsageData(this.sportsProfileUsageHistory)
		    this.sportsHistoricalUsageTable = this.allSportsSubscriptions.getHistoricalUsageTable();

			
	    },
        async getMonthlyProfileCounts() {

            try {
                let request = { startDate: null }
				let response = await getMonthlyProfileCounts(request)
				

                if (response.isSuccess) {
                    console.log(response.counts)
                    this.monthlyProfileCounts = response.counts;
                }
            }
            catch (e) {

            }
        },
        async getYearlyProfileCounts() {

            try {
                let request = { startDate: null }
	            let response = await getYearlyProfileCounts(request)
	            
                if (response.isSuccess) {
                    console.log(response.counts)
                    this.yearlyProfileCounts = response.counts;
                }
            }
            catch (e) {

            }
        },
        formatDate(date) {
            return formatDate(date, "M/D/YYYY")
        },
        getUsageTypeDescription(usageTypeId) {
            return usageTypeId == 1 ? "Actual Usage" :
                usageTypeId == 2 ? "Purchased Upfront" :
                usageTypeId == 3 ? "Credits Removed" :
                usageTypeId == 4 ? "Purchased In Arrears " :
                usageTypeId == 5 ? "Profiles Used (Unsaved) " :
                "Unknown";
        },
        async editUsage(usage) {
            this.editUsageId = usage.profileUsageId;
            this.editUsageCount = usage.usageCount;
            this.editUsageDescription = usage.description;
        },
        async confirmEdits() {
            this.isDataLoaded = false;
            let request = {
                profileUsageId: this.editUsageId,
                usageCount: this.editUsageCount,
                description: this.editUsageDescription
            }
            const endpoint = baseUrl + '/Billing/EditProfileUsage';
            const response = await httpService.post(endpoint, request);

            if (response.isSuccess) {
                this.editUsageId = null;
                await this.getDataFromApi();
            }
            this.isDataLoaded = true;
        },
        async showConfirmDeleteDialog(profileUsageId) {
            this.deleteProfileUsageId = profileUsageId;
            this.isConfirmDeleteDialogOpen = true;
        },
        async actuallyDeleteUsage() {

            this.isConfirmDeleteDialogOpen = false;
            this.isDataLoaded = false;
            //try {
                const endpoint = baseUrl + '/Billing/DeleteProfileUsage?profileUsageId=' + this.deleteProfileUsageId;
                const response = await httpService.post(endpoint);

                if (response.isSuccess) {
                    this.deleteProfileUsageId = null;
                    await this.getDataFromApi();
                }
            //}
            //catch (e) {

            //}
            this.isDataLoaded = true;
        },
        async showAddUsagePopup() {
            this.isAddUsageDialogOpen = true;
        },
        async actuallyAddUsage() {

            this.isAddUsageDialogOpen = false;
            this.isDataLoaded = false;
            //try {
                let request = {
                    usageTypeId: this.addUsageType,
                    usageCount: this.addUsageCount,
                    invoiceId: this.addUsageInvoiceId,
                    subscriptionId: this.addSubscriptionId,
                    description: this.addUsageDescription,
                    usageStart: this.addUsageDate,
                    usageEnd: this.addUsageDate
                }
                
                const endpoint = baseUrl + '/Billing/AddProfileUsage';
                const response = await httpService.post(endpoint, request);
                
                if (response.isSuccess) {
                    await this.getDataFromApi();
                }
            //}
            //catch (e) {

            //}
            this.isDataLoaded = true;
        },
	    goToParentOrg() {
		    window.open(`${window.location.origin}/org-management/usage?orgId=${this.billingInfo.billToOrgId}`, '_blank')
	    }
    },
    computed: {
		minDate() {
			return moment("1970-01-01").format('YYYY-MM-DD')
		}
    }
}

</script>

<style scoped>
.table-left-border {
	border-left: 1px solid var(--main-border-color)!important
}
</style>

<!--
TODO: remove timestamp from date fields, maybe make date fields use calendar selectors
-->