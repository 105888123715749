<template>
    <div>
        <div v-if="!loadingGroups">
            <div v-if="groups.length > 0">
                <page-header
                    title="Groups"
                >
                    <template #right-content>
                        <AppBtn class="mr-2" @click="addGroupDialogOpen = true" icon="mdi-plus">Add Group </AppBtn>
                        <AppBtn v-if="$store.state.currentAccount.isSuperAdmin" class="mr-2" style="font-weight: 600" @click="getGroupsReport" icon="mdi-download">Get Groups Report </AppBtn>
                    </template>
                    <!--                <div slot="right-content">-->
                    <!--                    <app-btn @click="addGroupDialogOpen = true">Add Group</app-btn>-->
                    <!--                </div>-->
                </page-header>
                <app-card hide-header>
                    <app-data-table
                        :headers="headers"
                        :items="groups"
                        @onRowClick="onRowClick($event)"
                    >
                        <template #item.isEnabled="{item}">
                            <div style="display: flex; align-items: center">
                                <div :style="{'background-color': item.isEnabled ? 'limegreen' : 'red'} " class="dot mr-2"></div>
                                <div>
                                    {{item.isEnabled ? 'Active' : 'Inactive'}}
                                </div>

                            </div>
                        </template>


                    </app-data-table>
                </app-card>
            </div>
            
            <div v-else>
                <EmptyState
                    title="No Groups"
                    description="Let's make your first group!"
                    button
                    button-text="Create Group"
                    @buttonClick="addGroupDialogOpen = true"
                />
            </div>

            

        </div>
        <div v-else>
            <AppPageLoader text="Loading Groups..."/>
            
        </div>
        <dialog-form
            :open="addGroupDialogOpen"
            @close="addGroupDialogOpen = false"
            title="Add A New Group"
            :width="500"
            @submit="submitAddGroupForm"
            :saving="savingNewGroup"
        >
            <template v-slot:form-content>
                <app-text-field v-model="addGroupForm.name" label="Group Name" />
                <app-form-checkbox checkbox-left-side v-model="addGroupForm.isInactive" label="Is Inactive" />


                <!--                    <app-form-checkbox v-model="addGroupForm.covidEnabled" label="COVID Screening" />-->
                <!--                    <app-tabs>-->
                <!--                        <app-tab>Users</app-tab>-->
                <!--                        <app-tab v-if="addGroupForm.covidEnabled">COVID</app-tab>-->
                <!--                    </app-tabs>-->
                <!--                    <app-autocomplete-->
                <!--                        v-model="addGroupForm.users"-->
                <!--                        :items="users"-->
                <!--                        label="Select Users"-->
                <!--                    ></app-autocomplete>-->
            </template>

        </dialog-form>
    </div>
</template>

<script>
import {getGroups, createGroup, groupModel, getGroupsReport} from '@/services/groups.service'
import {getOrganizationId, getUser} from "@/services/userService";
import {SET_SNACKBAR} from "@/store/mutation-types";
import EmptyState from "@/components/EmptyState";

export default {
    name: "Groups",
    components: {
        EmptyState

    },
    data() {
        return {
            groups: [],
            loadingGroups: false,
            addGroupDialogOpen: false,
            savingNewGroup: false,
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                },
                {
                    text: 'ID',
                    value: 'id',
                },
                {
                    text: 'Number of Profiles',
                    value: 'profileCount',
                },
                {
                    text: 'Status',
                    value: 'isEnabled',
                },
            ],
            addGroupForm: {
                name: '',
                isInactive: false,
                users: [],
                covidEnabled: false
            },
            users: [
                'Jordan Beacham',
                'Alex Pettigrew',
                'Justin Battenfield',
                'Chase Curtiss',
                'Brek Wilkings',
                'Alex Golimbievsky',
                'Michael Naizer'
            ]
        }
    },
    async mounted() {
        this.loadingGroups = true;
        const response = await getGroups();
        if (!response.isSuccess) {
            return
        }
        // this.groups = response.groups.map(group => ({
        //     name: group.name,
        //     id: group.id
        // }));
        
        let groups = response.groups;


        groups.sort((a, b) => {
            // if (a.isActive && b.isActive) {
            //     if (moment(a.endsOn).isAfter(b.endsOn)) return 1
            // }
            if (a.isEnabled) return -1
        })
        
        this.groups = groups;
        // this.groups = []
        this.loadingGroups = false;
    },
    methods: {
        onRowClick(item) {
            this.$router.push(`/groups/${item.id}/profiles`)
        },
        async getGroupsReport(){
            let user = getUser()

            await getGroupsReport(user.selectedOrganization.id)
        },
        async submitAddGroupForm() {
            this.savingNewGroup = true;
            let group = {...groupModel};
            const {name, isInactive} = this.addGroupForm;

            let isNameTaken = false;
            this.groups.forEach(x => {

                if (x.name.toLowerCase() === name.toLowerCase()) isNameTaken = true
            })

            if (isNameTaken) {
                this.savingNewGroup = false;
                return this.$store.commit(SET_SNACKBAR, {
                    text: 'Group names must be unique.',
                    color: 'error',
                    open: true
                });
            }
            
            
            group.name = name;
            group.isEnabled = !isInactive;
            group.organizationId = getOrganizationId()
            
            let userIds = []
            
            const response = await createGroup({group, userIds})
            const { group: newGroup } = response;
            this.savingNewGroup = false;
            this.$store.commit(SET_SNACKBAR, {
                text: 'Group Successfully Created',
                color: 'success',
                open: true
            })
            await this.$router.push(`/groups/${newGroup.id}/profiles`)

        }
    },
    computed: {

    }
}
</script>

<style scoped>
.dot {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: red;
}
</style>