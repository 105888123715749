<template>
    <div class="avoid-page-break">
        <div 
            v-for="(measureResult, measureId) in category.measureResults" 
            style="padding: 20px 0" 
            :key="measureId"
        >
            
            <TestRow
                class="avoid-page-break" 
                :show-dropdown="showDropdown" 
                :id="measureId" 
                :profileTest="profileTest" 
                :name="measureResult.label" 
                :measureResult="measureResult"
                :editMode="editMode"
                @excludeIncludeTestResults="excludeIncludeTestResults"
            />
            
        </div>

    </div>




</template>

<script>
import ProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
import TestRow from "./TestRow";
import {measuresCategoriesById} from "@/js/shared/constants";

export default {
    props: {
        profileTest: {
            type: ProfileTest
        },
        category: {
            required: true
        },
        showDropdown: {
            type: Boolean
        },
	    editMode: {
		    type: Boolean
	    }
    },
    name: "CategoryTestsSummary",
    data() {
        return {
            
        }
    },
    components: {
        TestRow
    },
	methods: {
		excludeIncludeTestResults(request) {
			this.$emit('excludeIncludeTestResults', request)
		},
	}


}
</script>

<style scoped>


</style>