<template>
    <div class="select-org-container">
        <h2 class="mb-2" style="text-align: center">Choose a profile to share with a medical professional:</h2>
        <div class="pa-4" style="min-width: 400px; max-height: 80%; overflow: scroll">
            <div v-for="(profile, index) in availableProfiles" >
                <div class="link pb-3 pt-3 mt-2 mb-2 pl-3 text-center" @click="selectProfile(profile.id)" >
                    {{profile.firstName}} {{profile.lastName}}
                </div>
                
                <v-divider v-if="index !== availableProfiles.length - 1"></v-divider>
            </div>
        </div>
        <app-btn color="primary" @click="logout" class="mt-2">Logout</app-btn>

        <dialog-form
            :open="profileAccessDialogOpen"
            title="Share Profile"
            width="90%"
            max-width="600"
            @close="profileAccessDialogOpen = false"
            :saving="creatingProfileAccessLink"
            @submit="createProfileAccessLink"
            submit-button-title="Generate New Link"
        >
            <template #form-content>

                <SwayVisaCard :profile-access-link-url="profileAccessLink" :profile="profileToShare"></SwayVisaCard>

                <div style="color: #757575">
                    <span>Share the link or QR code below with a Parent, Guardian, Individual, or a Sway Administrator. With access to their personal profile, they will have these convenient features:</span>
                    <ul>
                        <li>Simplified re-baselining without re-entering profile data.</li>
                        <li>Conveniently update personal information (height, weight, etc.).</li>
                        <li>Comfortably view and complete remotely assigned tests with ease.</li>
                    </ul>
                    <br>

                    <div style="display: flex; align-items: center">
                        <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>
                        <span style="color: rgba(255,0,0,.5); font-weight: 800; margin-left: 5px">IMPORTANT NOTE: </span>
                    </div>

                    <div style="margin-bottom: 10px">
                        This link can only be used ONCE and will expire in 24 hours. If you need to share this profile with more than one person, you will
                        need to generate a new link.
                    </div>
                </div>

            </template>
        </dialog-form>
    </div>

    
</template>

<script>
import {getUser, logout, switchSelectedOrganization, getProfilesForAccount} from '@/services/userService'
import {generateProfileAccessLink} from "@/services/profile.service";
import QrcodeVue from 'qrcode.vue'
import {SET_SNACKBAR} from "@/store/mutation-types";
import SwayVisaCard from "@/components/SwayVisa/SwayVisaCard.vue";

export default {
    name: "PatientDashboard",
    data() {
        return {
            availableProfiles: [],
            profileAccessDialogOpen: false,
            creatingProfileAccessLink: false,
            profileAccessLink: null,
            qrCodeSize: 300,
            profileIdToShare: null,
            profileToShare: null
        }
    },
    components: {
        SwayVisaCard,
        QrcodeVue
    },
    mounted() {
        this.profileIdToShare = null;
        this.getProfiles();
    },
    methods: {
        async getProfiles() {
            let response = await getProfilesForAccount();
            this.availableProfiles = response.profiles;
        },
        logout() {
            logout(() => this.$router.push('/login'));
            // this.$router.push('/login')
        },
        async selectProfile(profileId) {
            this.profileIdToShare = profileId;
            this.profileToShare = this.availableProfiles.find(p => p.id === profileId);
            this.profileAccessDialogOpen = true;
            
            //go ahead and create a share link
            await this.createProfileAccessLink();
        },
        async createProfileAccessLink(){
            this.creatingProfileAccessLink = true;

            const response = await generateProfileAccessLink(this.profileIdToShare, "");
            if (!response.isSuccess) {
                this.creatingProfileAccessLink = false;
                return
            }

            this.profileAccessLink = response.link;

            this.creatingProfileAccessLink = false;
        },
        async copyProfileAccessLink() {
            try {
                await navigator.clipboard.writeText(this.profileAccessLink)
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Link Copied to Clipboard',
                    color: 'success',
                    open: true
                });
            } catch($e) {
            }
        },
    }
}
</script>

<style scoped>
.select-org-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding-top: 10vh;*/
    flex-direction: column;
    height: 100vh;
    background-color: white;
} 

.link {
    /*background-color: red;*/
    /*display: flex;*/
    /*align-items: center;*/
}

.link:hover {
    cursor: pointer;
    background-color: var(--v-light-base);
    border-radius: 5px;
}


</style>