import { render, staticRenderFns } from "./AppForm.vue?vue&type=template&id=d07fee26&scoped=true"
import script from "./AppForm.vue?vue&type=script&lang=js"
export * from "./AppForm.vue?vue&type=script&lang=js"
import style0 from "./AppForm.vue?vue&type=style&index=0&id=d07fee26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d07fee26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VForm})
