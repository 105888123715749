import colors from '@/js/shared/colors'
import {
    getStroopDetailsChart,
    getCiColor,
    getVariabilityFromType,
    getOrdinalSuffix,
    isCobaltMeasureType, asLocalTime
} from "@/js/shared/helper"
import {allMeasuresFromConstants, measuresCategories} from "@/js/shared/constants"
import moment from "moment";
import 'moment-timezone'
import {getOrganizationId} from "@/services/userService";



const _mapDataToFormattedTestsData = Symbol();
const _getPercentChanged = Symbol();
const _mapVomsResults = Symbol();
const _getMeasureChangeInformation = Symbol();


export default class ProfileTest {
    constructor({data: inputData, baselineStartDate = null, profileId}) {
        let baselineStartDateMoment = asLocalTime(moment.utc(baselineStartDate))
        
        // console.log('BASELINE START DATE: ', baselineStartDateMoment.format())
        
        let data = JSON.parse(JSON.stringify(inputData));
        if (data.symptoms) data.measureResults[9999] = data.symptoms
        this.averages = inputData.averages.allAverages;
        this.data = data;
        this.profileId = profileId

        let completedOn = moment.utc(data.completedOn)
        // console.log('COMPLETED ON: ', completedOn.format())
        // console.log('COMPLETED ON UTC: ', moment.utc(data.completedOn).format())
        this.data.isBeforeBaselineStartDate = baselineStartDate ? asLocalTime(completedOn).isBefore(baselineStartDateMoment) : false
        

        this.data.isExcluded = data.isExcluded || (!data.isNonBaseline && this.data.isBeforeBaselineStartDate);
        this.data.profileBaselineStartDate = baselineStartDateMoment.format();
        
        this.id = data.id;
        
        const {formattedTest, hasTestTypes, isCovidOnly} = this[_mapDataToFormattedTestsData](data);
        this.formattedTestsData = formattedTest
        this.hasTestTypes = hasTestTypes;
        this.isCovidOnly = isCovidOnly;
        this.metadata = data.metadata ? JSON.parse(data.metadata) : null


        this.displayDate = asLocalTime(completedOn).format('MM/DD/YYYY @ h:mm a')
        this.data.date = asLocalTime(completedOn).format('M/D/YYYY');
        this.data.time = asLocalTime(completedOn).format('h:mm A z');
        this.data.datetime = asLocalTime(completedOn).format('M/D/YYYY h:mm A z');
        // this.data.time = moment(data.completedOn).format('h:mm A');

        
        
        // let keys = Object.keys(testIncludes);
        // keys.forEach(key => {
        //     this[key] = testIncludes[key]
        // });
        //
        

        // this.hasCognitiveTests = hasCognitiveTests;
        // this.hasBalanceTests = hasBalanceTests;
        // this.hasSymptoms = hasSymptoms;
        // this.hasCovid = hasCovid;
        // this.hasSurvey = hasSurvey;
        //
        // this.covidScreen = hasCovid ? this.data.organizationSurveyResults.results.find(x => x.isCovid === true) : null;


    }



    // getMeasureResultsById(id) {
    //
    //     
    //
    //     let formattedTest = this.formattedTestsData[id]
    //     if (!formattedTest) return
    //
    //
    //     if (name === 'symptoms' || name === "organizationSurveyResults") {
    //         return formattedTest.data;
    //     }
    //
    //     // let nameLabel = formattedTest.label;
    //     // const { data, averages } = formattedTest;
    //     // if (!data || !averages) return
    //
    //     return formattedTest
    // }

    getMeasureScoresByName(name) {
        let formattedTest = this.formattedTestsData[name]
        if (!formattedTest) return

        let nameLabel = formattedTest.label
        let shouldShowTimeScores = (nameLabel !== 'Balance' && name !== 'Memory' && name !== 'MOT');
        const { data, averages } = formattedTest;
        if (!data || !averages) return;

        return {
            y: data.score,
            display: data.display,
            baselineLow: Math.round(averages.lowScore),
            baselineHigh: Math.round(averages.highScore),
            normative: data.percentile,
            percentChange: Math.round(data.percentChange),
            isBaselineSession: this.data.isBaselineSession,
            showTimeScores: shouldShowTimeScores,
        }
    }

    getChartableTests() {
        let keys = Object.keys(this.formattedTestsData)
        console.log('keys ', keys)
        let items = [];
        keys.forEach(key => {
            let test = this.formattedTestsData[key]
            if (test.data && test.canChart) {
                items.push(key)
            }
        })
        return items
    }

    getMeasureColumnRangeChartOptionsById({id, resizeCallback = null, disableAnimation = false, includeNorms = true}) {
        // the name can be input as either the uppercase or lowercase version
        // for example it can be 'reaction' or 'Reaction'. Can't just do .toLowerCase()
        // because some like numberCounting are "Counting15" and "numberCounting15"
        
        let data = this.formattedTestsData[id]
        if (!data) return
        let nameLabel = data.label;
        let categories = [nameLabel]
        let shouldShowTimeScores = !!data.showTimeScores
        
        const averages = this.averages[id];

        let q1;
        let q3;
        let ciColor;
        let minScale;
        let maxScale;
        let softMax = null;
        
        if (averages) {
            q1 = Math.round(averages.highScore);
            q3 = Math.round(averages.lowScore);
            ciColor = data ? getCiColor(averages.variabilityType) : '';
            minScale = shouldShowTimeScores ? Math.min(q3) : Math.min(data.score, averages.lowScale);
            maxScale = shouldShowTimeScores ? Math.max(q1) : Math.max(data.score, averages.highScale);

            if (averages.referenceNorms) {
                minScale = shouldShowTimeScores ? Math.min(averages.referenceNorms['10'],averages.referenceNorms['90'], q3) : Math.min(averages.referenceNorms['10'], data.score, averages.lowScale);
                maxScale = shouldShowTimeScores ? Math.max(averages.referenceNorms['10'],averages.referenceNorms['90'], q1) : Math.max(averages.referenceNorms['90'], data.score, averages.highScale);
            }
        }
        


        // let variability = getVariabilityFromType(averages.variabilityType);

        let tickInterval = null;
        

        
        
        let score = data.score;
  
        minScale -= (q1 * .1);
        maxScale += (q1 * .1)

        if (id == 1002 || name == 1003) {
            if (minScale < 125) minScale = 125;
            if (maxScale > 2000) maxScale = 2000
            // tickInterval = 25
        }

        if (id == 1004) {
            
            if (minScale < 17) minScale = 17;
            tickInterval = 17;
            if (maxScale > 2000) maxScale = 2000
        }
        
        if (id == 74) {
            softMax = score
        }

        if (!shouldShowTimeScores) {
            if (minScale < 0) minScale = 0
            if (maxScale > 100) maxScale = 100;
            tickInterval = 10
        }
        let series = [];
        
        if (!averages) {
            minScale = score - 10;
            maxScale = score + 10
            series = [
                {
                    data: [[score, score]],
                    name: 'Score',
                    // color: ciColor,
                    borderRadius: 5,
                    borderWidth: 0,
                    // pointPlacement: referenceNorms && includeNorms ? -0.2 : 0,
                }
            ]
        }

        if (averages) {
            const {referenceNorms} = averages;

            series = [
                {
                    data: [[q3, q1]],
                    name: 'Baseline Range',
                    color: ciColor,
                    borderRadius: 5,
                    borderWidth: 0,
                    pointPlacement: referenceNorms && includeNorms ? -0.2 : 0,
                },

                // this series is hidden but there to make sure the chart-indicator
                // is shown on the graph since it's place by the annotation
                {
                    data: [[score, score]],
                    name: 'Baseline Range',
                    color: 'rgba(0,0,0,0)',
                    // borderRadius: 5,
                    // borderWidth: 0,
                    // pointPlacement: referenceNorms && includeNorms ? 0.2 : 0,
                },

            ]




            if (referenceNorms && includeNorms) {
                let norm10 = referenceNorms['10'];
                let norm25 = referenceNorms['25'];
                let norm50 = referenceNorms['50'];
                let norm75 = referenceNorms['75'];
                let norm90 = referenceNorms['90'];
                series.push({
                        data: [[norm10, norm90]],
                        name: 'Norms 10%-90%',
                        color: colors.chart.normBadGrey,
                        borderRadius: 5,
                        borderWidth: 0,
                    },
                    {
                        data: [[norm25, norm75]],
                        name: 'Average Norms 25%-75%',
                        color: colors.chart.normGrey,
                        borderRadius: 5,
                        borderWidth: 0,
                    })
            }
        }
        
        



        let chartOptions = {
            chart: {
                type: 'columnrange',
                inverted: true,
                height: '80px',
                style: {
                    fontFamily: "inherit",
                },
                events: {
                    render() {
                        if (!resizeCallback) return;
                        let something = this;

                        let position = this.annotations[0].labels[0].points[0].plotY;
                        let indicatorXPosition = position > 0 ? position : 0;
                        if (indicatorXPosition > this.plotSizeY) indicatorXPosition = this.plotSizeY

                        // let testType = this.annotations[0].labels[0].options.text;
                        resizeCallback(indicatorXPosition)
                    }
                },
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                columnrange: {
                    dataLabels: {
                        enabled: false,
                        format: '{y} ms'
                    },
                    grouping: false
                },
                series: {
                    pointWidth: 10,
                    animation: false
                }
            },
            annotations: [{
                visible: false,
                labelOptions: {
                    borderRadius: 5,
                    style: {
                        fontSize: 16,
                        fontWeight: 400
                    },
                    backgroundColor: 'rgba(0,0,0,1)',
                },
                labels: [{
                    point: {
                        xAxis: 0,
                        yAxis: 0,
                        x: 0,
                        y: score,
                        distance: 50,
                    },
                    // x: 0,
                    text: nameLabel
                },
                ]
            }],
            title: null,
            legend: {
                enabled: false
            },
            xAxis: {
                categories,
                plotLines: [{
                    color: '#ededed',
                    width: 1,
                    value: 0
                }],
                labels: {
                    enabled: false,
                    formatter() {
                        return 'hello'
                    }
                },
                lineWidth: 0,

            },
            yAxis: {
                title: null,
                lineWidth: 0,
                lineColor: 'red',
                reversed: shouldShowTimeScores,
                min: minScale,
                max: maxScale,
                softMax,
                startOnTick: true,
                tickInterval: tickInterval,
                // labels: {
                //     formatter() {
                //         return `${this.value}${shouldShowTimeScores ? ' ms' : null}`
                //     }
                // }
                
                // min: 0
            },
            series: series

        };




        // if (referenceNorms) {
        //    
        // }
        
        return chartOptions;

    };

    wereCategoryMeasuresTaken(category) {

        const formattedData = this[_mapDataToFormattedTestsData](this.data)
        let keys = Object.keys(formattedData);

        let wereTestsTaken = false
        keys.forEach(key => {

            let x = formattedData[key].category === category;
            let y = !!formattedData[key].data;

            if (formattedData[key].category === category && formattedData[key].data) wereTestsTaken = true
        })
        return wereTestsTaken
    }

    getGroupedSymptoms({asArray = false}) {
        let symptoms = this.data['symptoms'].results;
        let symptomKeys = [
            {
                label: "Headache",
                category: 'physical'
            },
            {
                label: "\"Pressure in head\"",
                category: 'physical'
            },
            {
                label: "Neck Pain",
                category: 'physical'
            },
            {
                label: "Nausea or vomiting",
                category: 'physical'
            },
            {
                label: "Dizziness",
                category: 'physical'
            },
            {
                label: "Blurred vision",
                category: 'physical'
            },
            {
                label: "Balance problems",
                category: 'physical'
            },
            {
                label: "Sensitivity to light",
                category: 'physical'
            },
            {
                label: "Sensitivity to noise",
                category: 'physical'
            },
            {
                label: "\"Don't feel right\"",
                category: 'physical'
            },
            {
                label: "More emotional",
                category: 'emotional'
            },
            {
                label: "Irritability",
                category: 'emotional'
            },
            {
                label: "Sadness",
                category: 'emotional'
            },
            {
                label: "Nervous or Anxious",
                category: 'emotional'
            },
            {
                label: "Fatigue or low energy",
                category: 'sleepArousal'
            },
            {
                label: "Drowsiness",
                category: 'sleepArousal'
            },
            {
                label: "Trouble falling asleep",
                category: 'sleepArousal'
            },
            {
                label: "Feeling slowed down",
                category: 'cognitive'
            },
            {
                label: "Feeling like \"in a fog\"",
                category: 'cognitive'
            },
            {
                label: "Difficulty concentrating",
                category: 'cognitive'
            },
            {
                label: "Difficulty remembering",
                category: 'cognitive'
            },
            {
                label: "Confusion",
                category: 'cognitive'
            },
        ]

        let groupedSymptoms = {
            physical: {
                totalScore: 0,
                symptoms: [],
                label: 'Physical',
            },
            emotional: {
                totalScore: 0,
                symptoms: [],
                label: 'Emotional',
            },
            cognitive: {
                totalScore: 0,
                symptoms: [],
                label: 'Cognitive',
            },
            sleepArousal: {
                totalScore: 0,
                symptoms: [],
                label: 'Sleep Arousal',
            }
        }
        symptoms.forEach(symptom => {
            let {category} = symptomKeys.find(symptomKey => symptomKey.label === symptom.name);
            groupedSymptoms[category].totalScore += symptom.score;
            groupedSymptoms[category].symptoms.push(symptom)
        })
        
        if (!asArray) {
            return groupedSymptoms
        } 
        
        return [
            {
                label: 'Physical',
                totalScore: groupedSymptoms.physical.totalScore,
                symptoms: groupedSymptoms.physical.symptoms
            },
            {
                label: 'Emotional',
                totalScore: groupedSymptoms.emotional.totalScore,
                symptoms: groupedSymptoms.emotional.symptoms
            },
            {
                label: 'Cognitive',
                totalScore: groupedSymptoms.cognitive.totalScore,
                symptoms: groupedSymptoms.cognitive.symptoms
            },
            {
                label: 'Sleep Arousal',
                totalScore: groupedSymptoms.sleepArousal.totalScore,
                symptoms: groupedSymptoms.sleepArousal.symptoms
            },
            
        ]
    }

    getMeasureResultsByCategory() {
        let response = [];
        const {formattedTestsData} = this
        let measuresTakenKeys = Object.keys(formattedTestsData);
        let measureCategoryKeys = Object.keys(measuresCategories);
        
        
        
        measureCategoryKeys.forEach(category => {
            let categoryTests = {};
            let testInCategory = false
            measuresTakenKeys.forEach(measureTaken => {
                
                if (formattedTestsData[measureTaken].category === measuresCategories[category].value) {
                    categoryTests[measureTaken] = formattedTestsData[measureTaken]
                    testInCategory = true
                }
            });
            
            if (testInCategory) response.push({
                name: category,
                // label: measuresCategories[category].label,
                // label: measuresCategories[category].label,
                ...measuresCategories[category],
                categoryId: measuresCategories[category].value,
                measureResults: categoryTests
            })
            
            

        });
        
        
        // set symptoms to be first
        response.sort((a,b) => {
            if (a.categoryId === 10) return -1
            return a.categoryId - b.categoryId
        })
        
        return response 
    }


    [_mapDataToFormattedTestsData](data) {
        let self = this
        let formattedTest = {};
        let measuresTaken = Object.keys(data.measureResults);
        let isCovidOnly = true;
        const {isNonBaseline} = data
        
        let hasTestTypes = {
            balance: false,
            cognitive: false,
            ocular: false, 
            survey: false, 
            neuromotor: false, 
            functional: false,
            covid: false,
            symptoms: !!data.symptoms
        }
        
        measuresTaken.forEach(measureNumber => {
            
            let measureResults = data.measureResults[measureNumber];
            let measureId = parseInt(measureNumber);

            data.measureResults[measureNumber].hasBaseline = !!self.averages[measureNumber]?.score

            let showTimeScores = allMeasuresFromConstants[measureNumber]?.showTimeScores;
            if (showTimeScores) {
                data.measureResults[measureNumber].percentChange *= -1
            }
            
            // flag Feet Together Eyes Closed (test number 7) < 90
            if (measureId === 1001) {
                const measureResult = data.measureResults[measureNumber];
                measureResult.results.forEach(result => {
                    result.detailRows.forEach(detailRow => {
                        if (parseInt(result.test) ===  7 && parseInt(detailRow.value) < 90) detailRow.isFlagged = true
                    })
                })
            }

            // If Cued Stroop, get chart options
            if (measureNumber == 77) {
                const {chartOptions, formattedResults} = getStroopDetailsChart(measureResults)
                measureResults.detailsChart = chartOptions;
                measureResults.formattedResults = formattedResults;
            }

            // If VOMS, get VOMS results
            if (measureNumber == 1020) {
                measureResults.vomsResults = this[_mapVomsResults](measureResults);
            }
            
            // Define what categories of tests have been taken
            if (allMeasuresFromConstants[measureNumber]?.category) {
                switch (allMeasuresFromConstants[measureNumber].category) {
                    case 1:
                        hasTestTypes.balance = true;
                        isCovidOnly = false
                        break;
                    case 2:
                        hasTestTypes.functional = true;
                        isCovidOnly = false
                        break;
                    case 6:
                        hasTestTypes.neuromotor = true;
                        isCovidOnly = false
                        break;
                    case 7:
                        hasTestTypes.oculomotor = true;
                        isCovidOnly = false
                        break;
                    case 8:
                        hasTestTypes.survey = true;
                        isCovidOnly = false
                        break
                    case 9:
                        hasTestTypes.covid = true
                        break;
                    case 10:
                        hasTestTypes.symptoms = true;
                        isCovidOnly = false
                        break;
                    case 11:
                        hasTestTypes.cognitive = true;
                        isCovidOnly = false
                        break;
                }
            } else {
                // if the measure is not in the constants file, it means there was a survey added
                hasTestTypes.survey = true;
                isCovidOnly = false;
                measureResults.isNewSurvey = true;
                measureResults.category = 8;
                measureResults.name = measureResults.measureName;
                measureResults.label = measureResults.measureName;
                measureResults.hasNoTrendChart = true;
                measureResults.isNotChartable = true;
                
            }
            

            // get change information if test is nonBaseline
            // if (!isNonBaseline)
            measureResults.changeData = this[_getMeasureChangeInformation](measureResults, measureId);
            
            
            // Get Assessment Information
            const {hasDuplicateTests} = measureResults;
            
            let resultsTable2 = null;
            let hasThirdTest = false;
            let testsArray = []
            let columnCount = 0;

            let entireMeasureExcluded = false;
            
            let orgId = 0;
            try{
                orgId = getOrganizationId();
            }
            catch (e) {
                
            }
            const shouldCreateNewResultsTable = isCobaltMeasureType(measureNumber);
            
            if (true) {
                
                //for (let i = 1; i < 4; i++) {
                //    testsArray.push({
                //        resultIds: [],
                //        values: [],
                //        score: null,
                //        isCompositeScore: false,
                //        isExcluded: false
                //    });
                //}
                
                const {results} = data.measureResults[measureNumber];
                let allDetailRows = []
                
                // get all the detail rows AND populate the testsArray

                results.forEach(result => {
                    const { profileTestResultId, isExcluded } = result;
                    
                    // add total score to test types that have more that 1 detail row (when only 
                    // 1 detail row that value is the total score anyway)

                    result.detailRows.forEach(row => {
                        row.profileTestResultId = result.profileTestResultId
                        allDetailRows.push(row);
                        if (!testsArray[row.testNumber - 1])
                        {
                            testsArray.push({
                                resultIds: [],
                                values: [],
                                score: null,
                                isCompositeScore: false,
                                isExcluded: false
                            });
                        }
                        
                        if (testsArray[row.testNumber - 1] && !testsArray[row.testNumber - 1].resultIds.includes(profileTestResultId)) {
                            testsArray[row.testNumber - 1].resultIds.push(profileTestResultId);
                            testsArray[row.testNumber - 1].score = result.score;
                            testsArray[row.testNumber - 1].display = result.display;
                            testsArray[row.testNumber - 1].isExcluded = result.isExcluded;
                            testsArray[row.testNumber - 1].values.push({
                                value: isNaN(row.value) ? row.value : parseFloat(row.value),
                                name: row.key,
                                isFlagged: row.isFlagged
                            })
                            if (isExcluded) testsArray[row.testNumber - 1].isExcluded = true
                        } else if (testsArray[row.testNumber - 1]) {
                            testsArray[row.testNumber - 1].values.push({
                                value: isNaN(row.value) ? row.value : parseFloat(row.value),
                                name: row.key,
                                isExcluded: result.isExcluded,
                                isFlagged: row.isFlagged
                            })
                        }
                        
                    })
                });
                
                // If there was not a 3rd test taken during the 
                // assessment, remove the placeholder for the third
                // test

                //hasThirdTest = testsArray[2] && testsArray[2].values.length > 0;
                //if (!hasThirdTest) testsArray.pop();
                
                // If it's a balance protocol, need to add the composite score since 
                // it's detailRows are structured differently than those of 
                // aother measure like Memory or Cued Stroop
                
                if (allMeasuresFromConstants[measureId]?.isProtocol) {
                    testsArray.forEach(test => {
                        const {values} = test;
                        let sum = 0;
                         // If the score is not a number, assume it was skipped and the score is 0
                        values.forEach(value => sum += isNaN(value.value) ? 0 : value.value);
                        test.score = Math.round((sum / values.length)*100) / 100;
                        test.display = showTimeScores ? `${test.score} ms` : test.score
                        test.isCompositeScore = true;
                    })
                }
                
                if (!shouldCreateNewResultsTable) {
                    
                    let table2 = [];
                    
                    testsArray.forEach((test, testIndex) => {
                        if (columnCount < testIndex + 1)
                            columnCount = testIndex + 1;
                        
                        if (testIndex === 0) {
                            table2.push([
                                {
                                    text: test.isCompositeScore ? `<div style="font-weight: bold">Composite Score</div>` : 'Score',
                                },
                                {
                                    text: test.display ? test.display : test.score,
                                    isExcluded: test.isExcluded,
                                }
                            ])
                            if (test.values.length > 1) {
                                test.values.forEach(value => {
                                    table2.push([
                                        {
                                            text: value.name,
                                        },
                                        {
                                            text: value.value,
                                            isExcluded: test.isExcluded,
                                            isFlagged: value.isFlagged
                                        }
                                    ])
                                })
                            }
                        } else {
    
                            if (hasDuplicateTests) table2[0].push({text: test.display ? test.display : test.score, isExcluded: test.isExcluded});
                            
                            if (test.values.length > 1) {
                                
                                test.values.forEach((value, valueIndex) => {
                                    
                                    if (!table2[valueIndex + 1]){
                                        table2[valueIndex + 1] = [];
                                    }
                                    
                                    table2[valueIndex + 1].push({text: value.value, isExcluded: test.isExcluded, isFlagged: value.isFlagged})
                                })
                            }
                        }
                    })
                    
                    //debugger;
                    
                    resultsTable2 = table2;
                }
                
                let allTestsAreExcluded = true
                testsArray.forEach(test => {
                    if (!test.isExcluded && test.resultIds.length > 0) allTestsAreExcluded = false
                })
                
                if (allTestsAreExcluded && measureId != 9999) entireMeasureExcluded = true
            }
            
            
            if (!measureResults.hasDuplicateTests && measureResults.results[0]?.isExcluded) {
                entireMeasureExcluded = true
            }
            
            let percentileDisplay = {
                number: null,
                suffix: null,
                isLessThan: false,
                isGreaterThan: false,
            };
            
            if (typeof measureResults.percentileNumber == "number") {
                const {percentileNumber} = measureResults;
                if (percentileNumber < 5) {
                    percentileDisplay.number = 5;
                    percentileDisplay.suffix = getOrdinalSuffix(5)
                    percentileDisplay.isLessThan = true
                    
                } else if (percentileNumber > 95) {
                    percentileDisplay.number = 95;
                    percentileDisplay.suffix = getOrdinalSuffix(95)
                    percentileDisplay.isGreaterThan = true
                } else {
                    percentileDisplay.number = percentileNumber;
                    percentileDisplay.suffix = getOrdinalSuffix(percentileNumber)
                }
            }
            

            formattedTest[measureNumber] = {
                ...data.measureResults[measureNumber],
                ...allMeasuresFromConstants[measureNumber],
                sessionDetails: {
                    columnCount,
                    testsArray,
                    resultsTable2,
                },
                isExcluded: entireMeasureExcluded,
                percentileDisplay
                
            }
            
        });
        
        return {formattedTest, hasTestTypes, isCovidOnly}

    }

    static getTestTypesAsObject(category = null) {
        let tests = this.getTestTypes()
        let response = {};

        if (category) {
            tests.filter(x => x.category === category)
        }

        tests.forEach(test => {
            response[test.name] = {
                label: test.label,
                category: test.category
            }
        })

        return response

    }


    static getTestTypes() {
        return [
            {
                name: 'ocular',
                label: "Oculomotor",
                category: 'cognitive',
            },
            {
                name: 'impulseControl',
                label: 'Impulse Control',
                category: 'cognitive',
            },
            {
                name: 'inspectionTime',
                label: 'Inspection Time',
                category: 'cognitive',
            },
            {
                name: 'memory',
                label: 'Memory',
                category: 'memory',
            },
            {
                name: 'numberCounting15',
                label: 'Counting15',
                category: 'cognitive',
            },
            {
                name: 'numberCounting20',
                label: 'Counting20',
                category: 'cognitive',
            },
            {
                name: 'numberCounting24',
                label: 'Counting24',
                category: 'cognitive',
            },
            {
                name: 'multipleObjectTracking',
                label: 'MOT',
                category: 'cognitive',
            },
            {
                name: 'reaction',
                label: 'Reaction Time',
                category: 'cognitive',
            },
            {
                name: 'balance',
                label: 'Balance',
                category: 'balance',
            }
        ]
    }
    
    // categories
    // 1 = balance
    // 2 = attention
    // 3 = memory
    // 4 = ocular
    // 5 = survey

    [_getPercentChanged](testResultScore, averageScore) {
        if (!testResultScore || !averageScore || averageScore === 0.0)
            return "0.0";
        else
            return ((testResultScore / averageScore - 1.0) * 100).toFixed(1);
    }
    
    [_getMeasureChangeInformation](measureResults, measureId) {

        let self = this;
        
        let colorId = null
        let text = ''
        let direction = -1 // -1 = down and 1 = up
        
        if (measureId == 1004) {
            const {scoreChange, redYellowMeasureDifference} = measureResults
            if (!scoreChange || !redYellowMeasureDifference) return
            const {redChange, yellowChange} = redYellowMeasureDifference;
            if (!redChange || !yellowChange) return
            
            text = `${measureResults.scoreChange} ms`
            direction = measureResults.scoreChange > 0 ? -1 : 1
            
            // self.reliableChangeValues = {
            //     1004: {
            //         redChange,
            //         yellowChange
            //     }
            // }

            if (scoreChange > redChange) {
                colorId = 2
            }
            else if (scoreChange > yellowChange) {
                colorId = 1
            }
            else colorId = 0
        } else {
            const {percentChange} = measureResults;
            
            text = `${Math.abs(percentChange * 100).toFixed(0)}%`
            direction = measureResults.percentChange < 0 ? -1 : 1

            if (percentChange >= -.1) {
                colorId = 0
            } else if (percentChange > -.15) {
                colorId = 1
            } else {
                colorId = 2
            }
        }
        
        
        return {
            colorId,
            text,
            direction
        }
    }
    
    [_mapVomsResults](measureResults) {
        
        const {results} = measureResults;

        //if (results[0].detailRows.length < 4) return
        
        let vomsResults = {}

        let convergenceMeasure1, convergenceMeasure2, convergenceMeasure3;
        let baselineHeadache, baselineDizziness, baselineFogginess, baselineNausea, baselineSymptomsTotal;
        
        let maxHeadacheProvocation = 0;
        let maxDizzinessProvocation = 0;
        let maxNauseaProvocation = 0;
        let maxFogginessProvocation = 0;
        let maxTotalProvocation = 0;
        
        results.forEach((x, index) => {

            if (!(index >= 4 && index <= 6)) {

                // let headacheValue;
                // let dizzinessValue;
                // let nauseaValue;
                // let fogginessValue;
                // let symptomsTotal;
                //
                // if (x.detailRows.length < 4) {
                //     headacheValue = 0
                //     dizzinessValue = 0
                //     nauseaValue = 0
                //     fogginessValue = 0
                //     symptomsTotal = 0
                // }
                //

                let headacheValue = NaN;
                let dizzinessValue = NaN;
                let nauseaValue = NaN;
                let fogginessValue = NaN;
                
                if (x.detailRows.length >= 4)
                {
                    headacheValue = parseInt(x.detailRows[0].value)
                    dizzinessValue = parseInt(x.detailRows[1].value)
                    nauseaValue = parseInt(x.detailRows[2].value)
                    fogginessValue = parseInt(x.detailRows[3].value);
                }
                let symptomsTotal = headacheValue + dizzinessValue + nauseaValue + fogginessValue;

                if (index === 0 && !isNaN(headacheValue)) {
                    baselineHeadache = headacheValue;
                    baselineDizziness = dizzinessValue;
                    baselineNausea = nauseaValue
                    baselineFogginess = fogginessValue;
                    baselineSymptomsTotal = symptomsTotal
                }
                
                let headacheProvocation = headacheValue -  baselineHeadache;
                let dizzinessProvocation = dizzinessValue -  baselineDizziness;
                let nauseaProvocation = nauseaValue -  baselineNausea;
                let fogginessProvocation = fogginessValue -  baselineFogginess;
                let totalProvocation = symptomsTotal -  baselineSymptomsTotal;
                
                if (headacheProvocation > maxHeadacheProvocation) maxHeadacheProvocation = headacheProvocation
                if (dizzinessProvocation > maxDizzinessProvocation) maxDizzinessProvocation = dizzinessProvocation
                if (nauseaProvocation > maxNauseaProvocation) maxNauseaProvocation = nauseaProvocation
                if (fogginessProvocation > maxFogginessProvocation) maxFogginessProvocation = fogginessProvocation
                if (totalProvocation > maxTotalProvocation) maxTotalProvocation = totalProvocation

                vomsResults[x.test] = {
                    headache: {
                        value: isNaN(headacheValue) ? "Skip" : headacheValue,
                        label: 'Headache',
                        provocationValue: isNaN(headacheProvocation) ? "Skip" : headacheProvocation, 
                    },
                    dizziness: {
                        value: isNaN(dizzinessValue) ? "Skip" : dizzinessValue, 
                        label: 'Dizziness',
                        provocationValue: isNaN(dizzinessProvocation) ? "Skip" : dizzinessProvocation,  
                    },
                    nausea: {
                        value: isNaN(nauseaValue) ? "Skip" : nauseaValue, 
                        label: 'Nausea',
                        provocationValue: isNaN(nauseaProvocation) ? "Skip" : nauseaProvocation,  
                    },
                    fogginess: {
                        value: isNaN(fogginessValue) ? "Skip" : fogginessValue, 
                        label: 'Fogginess',
                        provocationValue: isNaN(fogginessProvocation) ? "Skip" : fogginessProvocation,  
                    },
                    total: {
                        value: isNaN(symptomsTotal) ? "Skip" : symptomsTotal, 
                        label: 'Total',
                        provocationValue: isNaN(totalProvocation) ? "Skip" : totalProvocation,  
                    },
                    comments: {
                        value: x.detailRows.length < 5 || x.detailRows[4].value === "Skip" || x.detailRows[4].value === "Skipped"
                            ? null : x.detailRows[4].value,
                        label: 'Comments',
                    }
                }
            } else {
                switch (index) {
                    case 4:
                        convergenceMeasure1 = parseInt(x.detailRows[0].value) ? parseInt(x.detailRows[0].value) : 0
                        break
                    case 5:
                        convergenceMeasure2 = parseInt(x.detailRows[0].value) ? parseInt(x.detailRows[0].value) : 0
                        break
                    case 6:
                        convergenceMeasure3 = parseInt(x.detailRows[0].value) ? parseInt(x.detailRows[0].value) : 0
                        break
                }
                
                
                return
            }
            
            // vomsResults[vomsResults.length - 1].push(allMeasuresFromConstants[x.test].shortLabel)
            //
            // let {detailRows} = x;
            //
            // detailRows.forEach((y, yIndex) => {
            //     if (index === 7 && yIndex === 4) {
            //         response[response.length - 1].push([convergenceMeasure1, convergenceMeasure2, convergenceMeasure3, y.value])
            //         return
            //     }
            //
            //     response[response.length - 1].push(y.value)
            // })

            // const measureLabel = 
            // const {detailRows} = x;
            // if (detailRows.length === 6) {
            //
            // }
        })
        
        
        if (vomsResults[98]){
            vomsResults[98].convergenceMeasures = {
                measure1: convergenceMeasure1,
                measure2: convergenceMeasure2,
                measure3: convergenceMeasure3,
            }
        }

        let vomsTable = {
            headers: ['', 'Headache', 'Dizziness', 'Nausea', 'Fogginess', 'Total', 'Comments'],
            rows: []
        }
        
        for (let test in vomsResults) {
            let isBaseline = (test == 94)
            vomsTable.rows.push([
                {
                    value: allMeasuresFromConstants[test].shortLabel,
                    greyBackground: isBaseline
                },
                {
                    value: vomsResults[test].headache.value,
                    provocationValue: vomsResults[test].headache.provocationValue,
                    isMaxProvocation: maxHeadacheProvocation > 0 && vomsResults[test].headache.provocationValue === maxHeadacheProvocation,
                    greyBackground: isBaseline
                },
                {
                    value: vomsResults[test].dizziness.value,
                    provocationValue: vomsResults[test].dizziness.provocationValue,
                    isMaxProvocation: maxDizzinessProvocation > 0 && vomsResults[test].dizziness.provocationValue === maxDizzinessProvocation,
                    greyBackground: isBaseline
                },
                {
                    value: vomsResults[test].nausea.value,
                    provocationValue: vomsResults[test].nausea.provocationValue,
                    isMaxProvocation: maxNauseaProvocation > 0 && vomsResults[test].nausea.provocationValue === maxNauseaProvocation,
                    greyBackground: isBaseline
                },
                {
                    value: vomsResults[test].fogginess.value,
                    provocationValue: vomsResults[test].fogginess.provocationValue,
                    isMaxProvocation: maxFogginessProvocation > 0 && vomsResults[test].fogginess.provocationValue === maxFogginessProvocation,
                    greyBackground: isBaseline
                },
                {
                    value: vomsResults[test].total.value,
                    provocationValue: vomsResults[test].total.provocationValue,
                    isMaxProvocation: maxTotalProvocation > 0 && vomsResults[test].total.provocationValue === maxTotalProvocation,
                    greyBackground: true
                },
                {
                    value: vomsResults[test].comments.value,
                    greyBackground: isBaseline
                    
                },
            ]);
            
            
            
            if (test == 98) {
                vomsTable.rows[vomsTable.rows.length - 1][6].value = `
                <div>
                    <div>Measure 1 (cm): <span style="border-bottom: 1px solid #333; padding: 0 10px">` + convergenceMeasure1 + `</span></div> 
                    <div>Measure 2 (cm): <span style="border-bottom: 1px solid #333; padding: 0 10px">` + convergenceMeasure2 + `</span></div> 
                    <div>Measure 3 (cm): <span style="border-bottom: 1px solid #333; padding: 0 10px">` + convergenceMeasure3 + `</span></div> 
                    <div>${vomsResults[test].comments.value ? vomsResults[test].comments.value : ''}</div> 
                </div>             
                `
                
            }
        }
        
        
        
        return {
            vomsResults,
            vomsTable,
            averageConvergence: Math.round(((convergenceMeasure1 + convergenceMeasure2 + convergenceMeasure3) / 3)*100)/100,
            baselineSymptomsTotal,
            maxTotalProvocation
        }
       
    }
    
    /*
    getBaselineCodeTable({protocolItems}) {
        const self = this;
        let rows = []
        let headerLength = 0;

        protocolItems.forEach((protocolItem, index) => {
        	let averages = self.averages[protocolItem.regulationMeasureId];
            
            const {isNotBaselineable, singleAssessmentTest, category} = allMeasuresFromConstants[protocolItem.regulationMeasureId]
            
            let hasBegunMeasure = self.formattedTestsData[protocolItem.regulationMeasureId];
            let sessionDetails = hasBegunMeasure ? self.formattedTestsData[protocolItem.regulationMeasureId].sessionDetails : null;
            let testsArray = sessionDetails ? sessionDetails.testsArray : null
            let hasThirdTest = sessionDetails ? sessionDetails.hasThirdTest : null
            if (headerLength < testsArray.length)
                headerLength = testsArray.length;
            
            let measureResults = self.formattedTestsData[protocolItem.regulationMeasureId]
            rows.push([])

        	rows[index].push(
        		{
        			text: protocolItem.label,
                    hasBegunMeasure
        		},
        		{
        			text: averages && averages.variabilityType ? averages.variabilityType : null,
                    isVariabilityType: true,
                    variabilityType: averages && averages.variabilityType ? averages.variabilityType : null,
                    hasBegunMeasure,
                    isNotBaselineable
        		},
        	)

            let testColumns;
            if (testsArray) {
                testColumns = testsArray.map((test, testIndex) => {
                    return  {
                        text: hasBegunMeasure && testsArray[testIndex] ? testsArray[testIndex].display ? testsArray[testIndex].display : testsArray[testIndex].score ? testsArray[testIndex].score : null : null,
                        isExcluded: hasBegunMeasure && testsArray[testIndex] ? testsArray[testIndex].isExcluded : null,
                        resultIds: hasBegunMeasure && testsArray[testIndex] ? testsArray[testIndex].resultIds : null,
                        isTest: true,
                        profileTestId: self.id,
                        isEditable: true,
                        hasBegunMeasure,
                        displayCheckmark: index === 0 && category === 8
                    }
                });
                rows[index].push(testColumns);
            }
            
            // Add Tests to Table
            //rows[index].push(
            //    testsArray.map((test, index) => {
            //        return {
            //            text: hasBegunMeasure && test ?  test.display ? test.display : test.score ? test.score : null : null,
            //            isExcluded: hasBegunMeasure && test ? test.isExcluded : null,
            //            resultIds: hasBegunMeasure && test ? test.resultIds : null,
            //            isTest: true,
            //            profileTestId: self.id,
            //            isEditable: !isNotBaselineable,
            //            hasBegunMeasure,
            //            displayCheckmark: index === 0 && category === 8
            //        }
            //    })
            //);

            rows[index].push(
                {
                    // text: `<div>${averages && averages.percentileDisplay?.number ? averages.percentileNumber : null}</div>`,
                    text: `<div style="display: flex; justify-content: center; align-items: center">
                            <div style="color: #757575; font-size: 9px">${measureResults && measureResults.percentileDisplay?.isLessThan ? "&#x25BC" : measureResults && measureResults.percentileDisplay?.isGreaterThan ? "&#x25B2;" : ''}</div>
                            <div style="margin-left: 2px">${measureResults && measureResults.percentileDisplay?.number ? measureResults.percentileDisplay.number : ''}</div>
                            <div style="font-size: 9px; align-self: flex-start">${measureResults && measureResults.percentileDisplay?.suffix ? measureResults.percentileDisplay.suffix : ''}</div>
                            
                            
                            </div>`,
                    isPercentile: true,
                    hasBegunMeasure
                },
            )
        });
        
        var thisIsHere = 0;
        
        let headers = ["", ""];
        for (let i = 0; i < headerLength; i++)
        {
            headers.push("Test " + (i + 1));
        }
        headers.push("Percentile");
        
        return {headers, rows}
    }
    */
}

