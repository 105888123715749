<template>
    <div>
        <page-header title="Surveys">
           
        </page-header>
        <app-btn @click="$router.push('/surveys/create')">Create Survey</app-btn>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "Surveys"
}
</script>

<style scoped>

</style>