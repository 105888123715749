<template>
    <div class="">
        <div class="sway-form">
            <form>
                <h3>Raw Data Export</h3>
                <br />
                <div class="inline-block-field">
                    <app-text-field v-model="organizationId" label="Organization Id" rules="number"></app-text-field>
                </div>
                <br />
                <div class="inline-block-field">
                    <app-text-field v-model="groupId" label="Group Id" rules="number"></app-text-field>
                </div>
                <br />
                <app-btn style="" @click="downloadXyzReport">
                    Download Balance XYZ Report
                </app-btn>
                <app-btn style="margin-left:10px" @click="downloadTrialsReport">
                    Download All Trials Report
                </app-btn>
            </form>
        </div>
    </div>
</template>

<script>
import TableFooter from "@/components/tables/TableFooter";
import TableOptionsRow from "@/components/tables/TableOptionsRow";
import TableHeader from "@/components/tables/TableHeader";
import TableBody from "@/components/tables/TableBody";
import moment from 'moment'
import { downloadBalanceXyzCsv, downloadAllTrialsCsv } from "@/services/report.service";

export default {
    name: "RawDataExport",
    components: {
        TableFooter,
        TableOptionsRow,
        TableHeader,
        TableBody
    },
    data () {
        return {
            loading: true,
            
            organizationId: 0,
            groupId: null
        }
    },

    mounted () {
        //this.getDataFromApi()
    },
    computed: {
        covidOnly() {
            return this.testFilterType == 1;
        },
        nonCovidOnly() {
            return this.testFilterType == 2;
        }
    },
    methods: {
        async downloadXyzReport() {
            this.loading = true;

            const request = {
                organizationIds: [this.organizationId],
                groupIds: this.groupId ? [this.groupId] : null
            };
            
            const response = await downloadBalanceXyzCsv(request);
            
            this.loading = false;
        },
        async downloadTrialsReport() {
            this.loading = true;

            const request = {
                organizationIds: [this.organizationId],
                groupIds: this.groupId ? [this.groupId] : null
            };

            const response = await downloadAllTrialsCsv(request);

            this.loading = false;
        }
    },
}
</script>

<style scoped>

</style>