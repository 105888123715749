<template>
    <div style="margin: 0px 0px; width: 100%;" class="pdf-section-wrapper pdf-avoid-page-break" id="symptoms-summary">
        <div >
           <div style="font-weight: bold; font-size: 14px" v-if="!hideTotal">
               Total: <span class="symptoms-value">{{totalScore}}</span>
           </div>
            <div 
                v-for="(group, key, i) 
                in groupedSymptoms" 
                :key="i" 
                style="margin-top: 10px"
                v-if="group.totalScore > 0"
            >
                <div>
                    <div style="font-weight: bold">
                        <span >{{ group.label }}</span> <span class="symptoms-value">{{group.totalScore}}</span>
                    </div>
                    <div style="display: flex; flex-wrap: wrap">
                        <div v-for="(item, j) in group.symptoms" :key="j">
                            {{item.name}} <span class="symptoms-value">{{item.score}}</span>

                        </div>
                    </div>
                </div>
                
                
            </div>
         
<!--                <div v-for="(group, index) in displayedGroups" :key="index" style="" >-->
<!--                    <div>-->
<!--                        {{group.label}}-->
<!--                    </div>-->
<!--                        <div style="display: flex; align-items: center">-->
<!--                            <div style="margin-right: 30px; font-weight: bolder" v-if="index === 0"> -->
<!--                                TOTAL SCORE <span class="symptoms-value">{{group.value}}</span>-->
<!--                                -->
<!--                            </div>-->
<!--                            <div v-for="(item, itemIndex) in group.items" :key="itemIndex" style="margin-right: 20px">-->
<!--                                {{item.text}} <span class="symptoms-value">{{item.value}}</span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        -->


<!--                    -->

<!--                </div>-->
            
        </div>
        
            


    </div>
</template>

<script>
import ProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
import SectionWrapper from "@/components/pdf-export/SectionWrapper";

export default {
    name: "SymptomsDetailsSection",
    components: {
        SectionWrapper,
    },
    props: {
        profileTest: {
            type: ProfileTest,
            required: true
        },
        hideTotal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // results: null,
            groupedSymptoms: null
        }
    },
    mounted() {
        // this.results = this.profileTest.getMeasureResultsByName('symptoms').results;
        this.groupedSymptoms = this.profileTest.getGroupedSymptoms({asArray: false})
    },
    computed: {
        totalScore() {
            if (!this.groupedSymptoms) return null
            
            let totalScore = 0
            
            for (let item in this.groupedSymptoms) {
                totalScore += this.groupedSymptoms[item].totalScore
            }
            
            return totalScore
        },
        displayedGroups() {
            if (!this.groupedSymptoms) return []
            
            let response = [];
            
            response.push({
                label: 'Overview',
                value: this.totalScore,
                items: [
                    {
                        text: 'Physical',
                        value: this.groupedSymptoms.physical.totalScore
                    },
                    {
                        text: 'Cognitive',
                        value: this.groupedSymptoms.cognitive.totalScore
                    },
                    {
                        text: 'Emotional',
                        value: this.groupedSymptoms.emotional.totalScore
                    },
                    {
                        text: 'Sleep-Arousal',
                        value: this.groupedSymptoms.sleepArousal.totalScore
                    },
                    
                ]
            });
            
            if (this.groupedSymptoms.physical.totalScore > 0) {
                response.push({
                    label: 'Physical',
                    value: this.groupedSymptoms.physical.totalScore,
                    items: this.groupedSymptoms.physical.symptoms.map(x => ({text: x.name, value: x.display})),
                })
            }

            if (this.groupedSymptoms.cognitive.totalScore > 0) {
                response.push({
                    label: 'Physical',
                    value: this.groupedSymptoms.cognitive.totalScore,
                    items: this.groupedSymptoms.cognitive.symptoms.map(x => ({text: x.name, value: x.display})),
                })
            }

            if (this.groupedSymptoms.emotional.totalScore > 0) {
                response.push({
                    label: 'Physical',
                    value: this.groupedSymptoms.emotional.totalScore,
                    items: this.groupedSymptoms.emotional.symptoms.map(x => ({text: x.name, value: x.display})),
                })
            }

            if (this.groupedSymptoms.sleepArousal.totalScore > 0) {
                response.push({
                    label: 'Physical',
                    value: this.groupedSymptoms.sleepArousal.totalScore,
                    items: this.groupedSymptoms.sleepArousal.symptoms.map(x => ({text: x.name, value: x.display})),
                })
            }
            
            return response
            
            // return [
            //     {
            //         label: 'Physical',
            //         value: this.groupedSymptoms.physical.totalScore,
            //         items: this.groupedSymptoms.physical.symptoms.map(x => ({text: x.name, value: x.display})),
            //     },
            //     {
            //         label: 'Cognitive',
            //         value: this.groupedSymptoms.cognitive.totalScore,
            //         items: this.groupedSymptoms.cognitive.symptoms,
            //     },
            //     {
            //         label: 'Emotional',
            //         value: this.groupedSymptoms.emotional.totalScore,
            //         items: this.groupedSymptoms.emotional.symptoms,
            //     },
            //     {
            //         label: 'Sleep-Arousal',
            //         value: this.groupedSymptoms.sleepArousal.totalScore,
            //         items: this.groupedSymptoms.sleepArousal.symptoms,
            //     },
            //    
            // ]
        },
        symptoms() {
            return this.profileTest.data.symptoms
        }
    }

}
</script>

<style scoped>




</style>