import ProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
import {
    getCiColor, getCiColorForChart, getMeasuresAsArrayByCategoryId,
    inchesToCentimeters, poundsToKg, toFixedNullable, asLocalTime
} from "@/js/shared/helper"
import moment from "moment";
import 'moment-timezone'
import colors from "@/js/shared/colors";
import {
    allMeasuresFromConstants,
    measuresCategories,
    measuresCategoriesById,
    profileFormOptions
} from "@/js/shared/constants"


const _getFormattedAverages = Symbol()
const _getAllTestsAsClasses = Symbol()
const _getHistoryChartSeries = Symbol()
const _getHistoryChartPointMarker = Symbol()
const _addTestsToShowProperty = Symbol()
const _getProfileInfo = Symbol()
const _getTrendableMeasures = Symbol()

export default class Profile {
    constructor(inputData) {
        let data = this[_addTestsToShowProperty](inputData)
        // tests come in the form of oldest to newest so switch so its newest to oldest
        // data.tests.reverse();
        this.formattedAverages = this[_getFormattedAverages](data);
        this.data = data;
        this.events = data.profileEvents;
        this.profileInfo = this[_getProfileInfo](data.profile);
        this.profileInfo.age = moment().diff(moment(data.profile.birthDate), 'years');
        const {formattedTests, measureIdsTaken, categoryIdsTaken} = this[_getAllTestsAsClasses](data.tests, data.averages);
        this.tests = formattedTests;
        


        this.data.averagesCount = Object.keys(this.formattedAverages).filter(key => typeof this.formattedAverages[key].score === 'number').length;

        this.measureIdsTaken = measureIdsTaken;
        this.categoryIdsTaken = categoryIdsTaken;
        this.trendableMeasures = this[_getTrendableMeasures]()

        let hasAtLeastOneCovid = false;
        let hasAtLeastOneNonCovid = false;
        let hasAtLeastOneEvent = false;
        this.tests.forEach(test => {

            test.hasTestTypes.covid ? hasAtLeastOneCovid = true : null;
            if (!test.isCovidOnly) {
                hasAtLeastOneNonCovid = true
            }
        })
        
        if (this.events){
            this.events.forEach(event => {
                hasAtLeastOneEvent = true
            })
        }
        
        this.hasAtLeastOneCovid = hasAtLeastOneCovid;
        this.hasAtLeastOneNonCovid = hasAtLeastOneNonCovid;
        this.hasAtLeastOneEvent = hasAtLeastOneEvent;


        // this.baselines = this[_getProfileBaselines](data.averages);
        // this.testsTaken = this[_getTestsTaken]()



    }

    [_getTrendableMeasures]() {
        let response = {
            count: 0,
            byCategory: [],
            measures: []
        }
        let currentIndex = 0;
        
        this.categoryIdsTaken.forEach((category) => {

            if (!measuresCategoriesById[category]?.trendsAreNotChartable) {
                
                response.byCategory.push({
                    category,
                    measures: [],
                    label: measuresCategoriesById[category].label
                })

                let currentCategoryMeasures = response.byCategory[currentIndex]?.measures;
                
                const measures = getMeasuresAsArrayByCategoryId(category);
                measures.forEach(measure => {
                    
                    // If the measure is not chartable OR the measure is not
                    // included in the constants.js file;
                    
                    if (measure.isNotChartable || measure.isNewSurvey) return
                    
                    
                    if (this.measureIdsTaken.includes(measure.measureId)) {
                        response.count++
                        let index = currentIndex

                        currentCategoryMeasures.push({
                            ...measure,
                            // baselineColumnChartOptions,
                            currentValue: null,
                            percentChange: null

                        })
                    }
                });
                currentIndex++
            }


        });

        // Make sure that any without a measure are not included
        // if a new survey (not in constants.js) is included, it will 
        // need this cleanup logic. I'm sure there's a better way to do it
        // than this
        response.byCategory = response.byCategory.filter(x => x.measures.length > 0)
        
        return response
    }

    [_getProfileInfo](profile) {

        profile.baselineStartDateMoment = asLocalTime(moment.utc(profile.baselineStartDate))

        // profile.baselineStartDateMoment2 = moment.utc(profile.baselineStartDate).tz(timezone).format()
        // 

        profile.heightInFeet = Math.floor(profile.height / 12);
        profile.heightInInches = (profile.height % 12);
        profile.heightInCentimeters = inchesToCentimeters(profile.height);
        profile.displayHeight = `${profile.heightInFeet}'${profile.heightInInches.toFixed(0)}"`;
        profile.displayHeightMetric = `${profile.heightInCentimeters} cm`
        profile.weightInKg = poundsToKg(profile.weight)
        profile.displayWeight = `${profile.weight.toFixed(0)} lbs`
        profile.displayWeightMetric = `${profile.weightInKg} kg`
        profile.age = moment().diff(profile.birthDate, 'years');
        profile.displayBirthdate = moment(profile.birthDate).utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).format('M/D/YYYY');

        function tryFindMatch(property, options) {
            if (typeof property === 'number')
            {
                let match = options.find(x => x.value === property);
                return match ? match.text : '-';
            }
            return '-';
        }

        if (profile.additionalData) {

            profile.additionalData.genderIdentityDisplay = tryFindMatch(profile.additionalData.genderIdentity, profileFormOptions.genderOptions);
            profile.additionalData.raceDisplay = tryFindMatch(profile.additionalData.race, profileFormOptions.raceOptions);
            profile.additionalData.ethnicityDisplay = tryFindMatch(profile.additionalData.ethnicity, profileFormOptions.ethnicityOptions);
            profile.additionalData.hasHadConcussionDisplay = tryFindMatch(profile.additionalData.hasHadConcussion, profileFormOptions.yesNoIdkOptions);
            profile.additionalData.lastConcussionDateDisplay = profile.additionalData.lastConcussionDate ? moment(profile.additionalData.lastConcussionDate).utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).format('M/D/YYYY') : '-';
            profile.additionalData.hasAttentionDisorderDisplay = tryFindMatch(profile.additionalData.hasAttentionDisorder, profileFormOptions.yesNoIdkOptions);
            profile.additionalData.primaryLanguageDisplay = tryFindMatch(profile.additionalData.primaryLanguage, profileFormOptions.languageOptions);
            profile.additionalData.secondaryLanguageDisplay = tryFindMatch(profile.additionalData.secondaryLanguage, profileFormOptions.languageOptions);
        }

        return profile
    }

    [_getFormattedAverages](data) {
        const keys = Object.keys(data.averages.allAverages)

        let result = {};

        keys.forEach(key => {
            if (!allMeasuresFromConstants[key]?.noBaselineChart || measuresCategoriesById[allMeasuresFromConstants[key].category].hasChartableBaselines) {
                result[key] = {...data.averages.allAverages[key], ...allMeasuresFromConstants[key]}
            }

            if (result[key] ) {
                let averages = data.averages.allAverages[key];

                if (typeof averages.percentileNumber === 'number') {
                    let percentileNumber = parseInt(averages.percentileNumber)
                    if (percentileNumber < 5) {
                        result[key].displayPercentile = 5
                    } else if (percentileNumber > 95) {
                        result[key].displayPercentile = 95
                    } else {
                        result[key].displayPercentile = Math.trunc(percentileNumber)
                    }
                } else {
                    result[key].displayPercentile = '-'
                }
            }



        });

        return result

        // this.formattedAverages = data.averages.allAverages.map(average => {
        //     average = {...average, ...allMeasures[average]}
        // })
    }

    // There's definitely a way to make this run faster
    getDetailedHistoryTableData({isMobileView}) {
        let self = this;
        const {tests} = this;

        // get a list of all measures taken by the profile
        // if we want to limit the # of tests, do it here
        let testColumnsPerTable = isMobileView ? 9999 : 8;
        let totalMeasuresCount = 0
        let allCategoriesTaken = {};


        tests.forEach(test => {

            let testMeasureIds = Object.keys(test.formattedTestsData);
            testMeasureIds.forEach(measureId => {
                let currentTest = test.formattedTestsData[measureId];
                if (allMeasuresFromConstants[measureId]?.excludeFromHistoryTable) return
                if (!(currentTest.category in allCategoriesTaken)) allCategoriesTaken[currentTest.category] = {
                    tests: []
                }

                if (!allCategoriesTaken[currentTest.category].tests.includes(measureId)) {
                    totalMeasuresCount++

                    allCategoriesTaken[currentTest.category].tests.push(measureId)
                }

            })

        });


        let tables = [
            {
                testColumns: 0,
                categoriesTaken: {}
            }
        ];

        let allCategoriesTakenSortedKeys = Object.keys(allCategoriesTaken);
        allCategoriesTakenSortedKeys.sort(function(a, b){
            if (parseInt(a) === 10) {
                return -1
            }
            return parseInt(a) - parseInt(b)
        });


        if (totalMeasuresCount > testColumnsPerTable) {
            let categoriesTakenForTables = [];
            let currentTableIndex = 0
            allCategoriesTakenSortedKeys.forEach(categoryId => {

                const {tests} = allCategoriesTaken[categoryId];
                const addTable = () => {
                    tables.push({
                        testColumns: 0,
                        categoriesTaken: {}
                    })
                    currentTableIndex++
                }


                tests.forEach(test => {


                    if (tables[currentTableIndex].testColumns > testColumnsPerTable) {
                        addTable()
                    }

                    let currentTable = tables[currentTableIndex];

                    if (!currentTable.categoriesTaken[categoryId]) {

                        // Goal is to keep all of category on a single table if possible. So check the length of 
                        // the next category, if that pushes the total length over the threshold then start a new
                        // table
                        if (currentTable.testColumns + tests.length > testColumnsPerTable) {
                            addTable()
                        }

                        tables[currentTableIndex].categoriesTaken[categoryId] = {
                            tests: []
                        }
                    }

                    tables[currentTableIndex].categoriesTaken[categoryId].tests.push(test);
                    tables[currentTableIndex].testColumns++;

                    // if (tables[currentTableIndex].testColumns > 5) {
                    //     
                    //     tables.push({
                    //         testColumns: 0,
                    //         categoriesTaken: {}
                    //     })
                    //     currentTableIndex++
                    // };
                    // currentTable = tables[currentTableIndex];
                })





                // tables[currentTableIndex].categoriesTaken.push({
                //     categoryId: {
                //         tests: []
                //     }
                // })
                // categoriesTaken[categoryId].tests.forEach(test => {
                //    
                // })

            })
        } else {
            tables[0].categoriesTaken = allCategoriesTaken
        }

        // 

        tables.forEach(table => {
            const {categoriesTaken} = table
            let metaHeaders = [{label: 'Date', rowspan: 2}];
            let headers = [];
            let rows = [];
            let baselines = [{display: 'Baselines'}]

            allCategoriesTakenSortedKeys.forEach(category => {

                // if (measuresCategoriesById[category].excludeFromHistoryTable) return
                if (!categoriesTaken[category]?.tests) return
                metaHeaders.push({
                    colspan: categoriesTaken[category].tests.length,
                    ...measuresCategoriesById[category]
                });

                categoriesTaken[category].tests.forEach(test => {
                    if (allMeasuresFromConstants[test].excludeFromHistoryTable) return
                    headers.push({
                        ...allMeasuresFromConstants[test],
                        measureId: test
                    })
                })
            })

            tests.forEach((test, testIndex) => {
                // do not include covid on history table
                if (test.isCovidOnly) return;
                
                const {formattedTestsData} = test;
                
                let temp = moment(test.data.completedOn)
                let row = {
                    datetime: temp,
                    isEventRow: false,
                    isDuringEvent: !!test.data.eventId,
                    isEvent: test.data.isEvent,
                    isNonBaseline: test.data.isNonBaseline,
                    hasDuplicateTests: test.data.hasDuplicateTests,
                    isExcluded: test.data.isExcluded,
                    data: [{
                        display: temp.format('M/D/YY h:mm a'),
                        date: test.data.date,
                        time: test.data.time,
                        id: test.data.id
                    }]
                }

                headers.forEach((header, index) => {
                    
                    const {measureId} = header
                    let formattedAverage;
                    // set up the baselines, only need to do once
                    if (testIndex === 0 && Object.keys(this.formattedAverages).length > 0) {

                        formattedAverage = this.formattedAverages[measureId]

                        if (formattedAverage && formattedAverage.score) {

                            // if it is a survey we don't want a value to show
                            if (formattedAverage.category === 8) {
                                baselines.push({
                                    display: '-',
                                })
                            } else {
                                baselines.push({
                                    display: formattedAverage.score.toFixed(2),
                                })
                            }


                        } else {
                            baselines.push({
                                display: '-'
                            })
                        }
                    }
                    

                    if (formattedTestsData[measureId]) {

                        let measure = formattedTestsData[measureId]
                        

                        // If it is a survey we want to show that it was taken, not a value
                        if (measureId == 1020) {
                            row.data.push({
                                display: `<div style="display: flex; flex-direction: column; max-width: 200px">
                                        <div style="display: flex; "><div style="flex: 1; text-align: left; color: #757575; margin-right: 5px" >Avg. Convergence:</div><div style="font-weight: bold">${measure.vomsResults.averageConvergence} cm</div></div>
                                        <div style="display: flex; "><div style="flex: 1; text-align: left; color: #757575; margin-right: 5px" >Baseline Symptoms: </div><div style="font-weight: bold">${measure.vomsResults.baselineSymptomsTotal}</div></div>
                                        <div style="display: flex; "><div style="flex: 1; text-align: left; color: #757575; margin-right: 5px" >Max Total Symptoms: </div><div style="font-weight: bold">${measure.vomsResults.maxTotalProvocation + measure.vomsResults.baselineSymptomsTotal}</div> </div>
                                        
                                        </div>`,
                                isSurvey: false,
                                // didPass: didPass,
                                // score: header.measureId == 1016 || header.measureId == 1017 ? measure.score : null
                            })
                        } else if (measure.category === 8) {
                            let didPass = true;
                            measure.results.forEach(result => {
                                if (result.didPass === false) didPass = false
                            })
                            
                            row.data.push({
                                display: didPass,
                                isSurvey: true,
                                didPass: didPass,
                                score: header.measureId == 1016 || header.measureId == 1017 ? measure.score : null
                            })
                        } else {
                            row.data.push({
                                display: allMeasuresFromConstants[header.measureId]?.showTimeScores ? (allMeasuresFromConstants[header.measureId].timeScale ? `${measure.score} ${allMeasuresFromConstants[header.measureId].timeScale.short}` :  `${measure.score} ms`) : measure.score,
                                percentChange: parseInt(header.measureId) === 9999 || !measure.percentChange === null  ?  null : measure.percentChange,
                                showPercentChange: measure.percentChange !== null,
                                
                                changeData: measure.changeData,
                                
                                // changeDirection: measure.changeData.direction,
                                // changeText: measure.changeData.text,
                                // changeColorId: measure.changeData.colorId,
                                
                                isExcluded: measure.isExcluded,
                                
                            })
                        }




                    } else {
                        row.data.push({
                            display: '-',
                            showPercentChange: false
                        })
                    }
                });
                rows.push(row)
            });
            
            const {events} = self;
            
            events.forEach(event => {
                
                rows.push({
                    isEventRow: true,
                    display: `${event.name ? event.name : 'Event'} - Started ${asLocalTime(moment(event.startedOn)).format('M/D/YY')}`,
                    datetime: event.startedOn,
                    colspan: '100%'
                })
                
                if (event.endedOn) {
                    rows.push({
                        isEventRow: true,
                        display: `Event - Ended ${asLocalTime(moment(event.endedOn)).format('M/D/YY')}`,
                        datetime: event.endedOn,
                        colspan: '100%'
                    })
                }
                
            })
            
            rows.sort((a, b) => {
                return moment(a.datetime).isBefore(moment(b.datetime)) ? 1 : -1
            })
            
            

            table.metaHeaders = metaHeaders;
            table.headers = headers;
            table.rows = rows;
            table.baselines = baselines
        });

        // 






        return tables



        // let labeledCategories = {}
        //
        // let keys = Object.keys(categoriesTaken);
        // let measuresCategoriesKeys = Object.keys(measuresCategories)
        // keys.forEach(categoryId => {
        //     measuresCategoriesKeys.forEach(category => {
        //         if (measuresCategories[category].value == categoryId) {
        //             labeledCategories[category] = {
        //                 ...categoriesTaken[categoryId],
        //                 ...measuresCategories[category],
        //                 name: category
        //             }
        //         }
        //     })
        // });
        // 


        // for (let value in labeledCategories) {
        //     labeledCategories[value].tests = labeledCategories[value].tests.map(test => ({
        //          measureId: test,
        //         label: allMeasures[test].label
        //     }))
        //     const x = value;
        //    
        // }
        //
        // return labeledCategories


    }

    getBaselineColumnChartByMeasureId({measure}) {
        // let baselineData = this.data.averages[measure];

        if (!this.formattedAverages[measure]) return null
        let averages = this.formattedAverages[measure]
        const {name} = averages
        // let formattedTest = this.formattedTestsData[name]
        // if (!formattedTest || !formattedTest.canChart) return
        let nameLabel = averages.label;
        let categories = [nameLabel]
        let shouldShowTimeScores = allMeasuresFromConstants[measure]?.showTimeScores;

        // const { data, averages } = formattedTest;

        let q1;
        let q3;

        // if (includeNorms) {
        //     q1 = averages.lowScale;
        //     q3 = averages.highScale;
        // } else {
        q1 = Math.round(averages.highScore);
        q3 = Math.round(averages.lowScore);
        // }


        // let variability = getVariabilityFromType(averages.variabilityType);
        let ciColor = getCiColor(averages.variabilityType);
        let tickInterval = null;

        let minScale = shouldShowTimeScores ? Math.min( q3) : averages.lowScale;
        let maxScale = shouldShowTimeScores ? Math.max(q1) : averages.highScale;



        if (averages.referenceNorms) {
            minScale = shouldShowTimeScores ? Math.min(averages.referenceNorms['10'],averages.referenceNorms['90'], q3) : Math.min(averages.referenceNorms['10'], averages.lowScale);
            maxScale = shouldShowTimeScores ? Math.max(averages.referenceNorms['10'],averages.referenceNorms['90'], q1) : Math.max(averages.referenceNorms['90'], averages.highScale);
        }

        // 

        // let score = shouldShowTimeScores ? data.timeScore : data.score;
        minScale -= (q1 * .1);
        maxScale += (q1 * .1)

        if (measure == 1002 || measure == 1003) {
            if (minScale < 125) minScale = 125;
            // tickInterval = 25
        }

        if (measure == 1004) {
            if (minScale < 17) minScale = 17;
            tickInterval = 17
        }

        if (!shouldShowTimeScores) {
            if (minScale < 0) minScale = 0
            if (maxScale > 100) maxScale = 100;
            tickInterval = 10
        }

        const {referenceNorms} = averages;


        // TODO: change this to officially how we're going to handle if the CI is width of 0
        // if (q3 === q1) {
        //     q1 -= 5
        //     q3 += 5
        //    
        // }

        // 

        let series = [
            {
                data: [[q3, q1]],
                name: 'Baseline Range',
                color: ciColor,
                borderRadius: 5,
                borderWidth: 0,
                pointPlacement: referenceNorms ? -0.2 : 0,
            },
        ]
        if (referenceNorms) {
            let norm10 = referenceNorms['10'];
            let norm25 = referenceNorms['25'];
            let norm50 = referenceNorms['50'];
            let norm75 = referenceNorms['75'];
            let norm90 = referenceNorms['90'];
            series.push({
                    data: [[norm10, norm90]],
                    name: 'Norms 10%-90%',
                    color: colors.chart.normBadGrey,
                    borderRadius: 5,
                    borderWidth: 0,
                },
                {
                    data: [[norm25, norm75]],
                    name: 'Average Norms 25%-75%',
                    color: colors.chart.normGrey,
                    borderRadius: 5,
                    borderWidth: 0,
                })
        }



        let chartOptions = {
            chart: {
                type: 'columnrange',
                inverted: true,
                height: '80px',
                style: {
                    fontFamily: "inherit",
                },
                animation: false,
                events: {
                    render() {
                        // if (!resizeCallback) return;
                        // let something = this;
                        //
                        // let position = this.annotations[0].labels[0].points[0].plotY;
                        // let indicatorXPosition = position > 0 ? position : 0;
                        // if (indicatorXPosition > this.plotSizeY) indicatorXPosition = this.plotSizeY
                        //
                        // // let testType = this.annotations[0].labels[0].options.text;
                        // resizeCallback(indicatorXPosition)
                    }
                },
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                columnrange: {
                    dataLabels: {
                        enabled: false,
                        format: '{y} ms'
                    },
                    grouping: false
                },
                series: {
                    pointWidth: 10,
                    animation: false
                }
            },
            // annotations: [
            //     {
            //         visible: false,
            //         labelOptions: {
            //             borderRadius: 5,
            //             style: {
            //                 fontSize: 16,
            //                 fontWeight: 400
            //             },
            //             backgroundColor: 'rgba(0,0,0,1)',
            //         },
            //         labels: [{
            //             point: {
            //                 xAxis: 0,
            //                 yAxis: 0,
            //                 x: 0,
            //                 y: score,
            //                 distance: 50,
            //             },
            //             // x: 0,
            //             text: nameLabel
            //         },
            //         ]
            //     }
            // ],
            title: null,
            legend: {
                enabled: false
            },
            xAxis: {
                categories,
                plotLines: [{
                    color: '#ededed',
                    width: 1,
                    value: 0
                }],
                labels: {
                    enabled: false
                },
                lineWidth: 0,

            },
            yAxis: {
                title: null,
                lineWidth: 0,
                lineColor: 'red',
                reversed: shouldShowTimeScores,
                min: minScale,
                max: maxScale,
                startOnTick: true,
                tickInterval: tickInterval
                // min: 0
            },
            series: series

        };




        // if (referenceNorms) {
        //    
        // }

        // 
        return chartOptions;
    };

    getTrendChartOptionsByMeasureId({
                                        measureId,
                                        isMobileView = false,
                                        onMouseOver = null,
                                        showNormsPlotBands = false,
                                        showCiPlotBands = true,
                                        onMouseOut = null,
                                        tooltipEnabled = true,
                                        resizeCallback = null,
                                        onSeriesClick = null,


                                    }) {
        // if (!this.testsTaken[measure]) return null
        let self = this;
        
        // remove tests that are covid Only and only display last 10 tests
        let nonCovidOnlyTests = this.tests.filter(x => !x.isCovidOnly);
        let tests = nonCovidOnlyTests.slice(0, 10).reverse();

        // only want the events that ended after the first shown test date
        // let events = self.events
        let events = self.events.filter(event => {
            if (!event.endedOn) return true
            else if (event.endedOn && moment(event.endedOn).isAfter(moment(tests[0].data.datetime))) return true
        })
        
        let categories = [];
        let series = [{
            name: '',
            data: [],
            color: '#bdbdbd'
        }];
        
        
        
        let data = [];
        let data2 = [];
        let data3 = [];
        let isSymptoms = parseInt(measureId) === 9999;
        let isVoms = parseInt(measureId) === 1020;


        let maxTotalProvocationColor = "#ffba5e";
        let baselineSymptomsColor = '#ff8f00';
        let convergenceColor = '#34d3fd';
        
        
        if (isVoms) {
            series.push({
                name: 'Baseline Symptoms',
                data: [],
                color: baselineSymptomsColor,
                stack: "Symptoms"
            });

            series.push({
                name: 'Average Convergence',
                data: [],
                yAxis: 1,
                type: 'column',
                // dashStyle: 'ShortDash',
                color: convergenceColor,
                stack: "Convergence"
            })
        }
        

        // VOMS Colors
        

        // if (isSymptoms) {
        //     this[_getSymptomsTrendChartOptions]({tests, isMobileView})
        // }

        let shouldShowTimeScore = allMeasuresFromConstants[measureId]?.showTimeScores;

        let dateFormat = 'M/D/YY'
        let allRelevantDates = []
        
        tests.forEach(x => {
            let date = asLocalTime(moment(x.data.completedOn))
            allRelevantDates.push({
                date,
                formattedDate: date.format(dateFormat),
                test: x
            })
        })
        events.forEach(x => {
            let startDate = asLocalTime(moment(x.startedOn))
            allRelevantDates.push({
                date: startDate,
                formattedDate: startDate.format(dateFormat),
                event: x,
                eventStart: true
            })
            if (x.endedOn) {
                let endDate = asLocalTime(moment(x.endedOn))
                allRelevantDates.push({
                    date: endDate,
                    formattedDate: endDate.format(dateFormat),
                    event: x,
                    eventStart: false
                })
            }
        })

        allRelevantDates
            .sort((a, b) => {
                return a.date.isAfter(b.date) ? 1 : -1
            })

        let xPlotBands = [];

        allRelevantDates.forEach((item, index) => {
            if (item.test) {
                categories.push(item.formattedDate)
                let test = item.test;
                if (test.formattedTestsData[measureId]) {
                    let testData = test.formattedTestsData[measureId];
                    const {isNonBaseline} = test.data
                    
                    
                    let marker = self[_getHistoryChartPointMarker]({test: test, isNonBaseline: test.data.isNonBaseline, hasDuplicateTests: test.data.hasDuplicateTests, isExcluded: (test.data.isExcluded || testData?.isExcluded)});
                    if (isSymptoms) { // use for isSymtoms if we want symptoms to be a different chart type

                        let {fillColor, lineColor, lineWidth} = marker;
                        const {skipped} = testData;
                        
                        let pointWidth = isMobileView && tests.length > 10 ? null : 8
                        
                        series[0].data.push({
                            y: testData.skipped ? 0 : testData.score,
                            // y: 10,
                            // marker: self[_getHistoryChartPointMarker](test.data),
                            percentChange: testData.percentChange,
                            color: skipped ? 'transparent' : lineColor,
                            borderColor: lineColor,
                            borderWidth: 2,
                            pointWidth,
                            testId: test.id,
                            skipped: testData.skipped,
                            dataLabels: {
                                enabled: true,
                                allowOverlap: true,
                                rotation: testData.skipped ? 270 : 0,

                                inside: false,
                                y: skipped ? -20 : -5,
                                padding: 0,
                                style: {
                                    fontSize: testData.skipped ? 7 : 10,

                                },
                                formatter() {
                                    let self = this;
                                    return self.point.skipped ? 'Skipped' : testData.score
                                }
                            }
                        })
                    } 
                    else if (isVoms) {
                        
                        //if testData.vomsResults is not null
                        if (testData.vomsResults) {
                            let something = testData;
                            let {fillColor, lineColor, lineWidth} = marker;

                            const {
                                averageConvergence,
                                baselineSymptomsTotal,
                                maxTotalProvocation
                            } = testData.vomsResults
                            let pointWidth = isMobileView && tests.length > 10 ? null : 8

                            let testIncludesVoms = !!testData.vomsResults

                            let white = "#fff"
                            let borderWidth = isNonBaseline ? 3 : 0;

                            series[0].name = "Max Provocation"
                            series[0].color = maxTotalProvocationColor
                            series[0].stack = "Symptoms"

                            series[0].data.push({
                                y: testIncludesVoms ? maxTotalProvocation : 0,
                                averageConvergence,
                                baselineSymptomsTotal,
                                maxTotalProvocation,
                                // y: 10,
                                // marker: self[_getHistoryChartPointMarker](test.data),
                                percentChange: testData.percentChange,
                                color: isNonBaseline ? white : maxTotalProvocationColor,
                                borderColor: isNonBaseline ? maxTotalProvocationColor : lineColor,
                                borderWidth,
                                pointWidth,
                                testId: test.id,
                                skipped: testData.skipped,
                                dataLabels: {
                                    enabled: false,
                                    allowOverlap: true,
                                    rotation: testData.skipped ? 270 : 0,

                                    inside: false,
                                    y: testData.skipped ? -20 : -5,
                                    padding: 0,
                                    style: {
                                        fontSize: testData.skipped ? 7 : 10,

                                    },
                                    formatter() {
                                        let self = this;

                                        return self.point.skipped ? 'Skipped' : testData.score
                                    }
                                }
                            })
                            series[1].data.push({
                                y: testIncludesVoms ? baselineSymptomsTotal : 0,
                                averageConvergence,
                                baselineSymptomsTotal,
                                maxTotalProvocation,
                                // y: 10,
                                // marker: self[_getHistoryChartPointMarker](test.data),
                                percentChange: testData.percentChange,
                                color: isNonBaseline ? white : baselineSymptomsColor,
                                borderColor: isNonBaseline ? baselineSymptomsColor : lineColor,
                                borderWidth,
                                pointWidth,
                                testId: test.id,
                                skipped: testData.skipped,
                                dataLabels: {
                                    enabled: false,
                                    allowOverlap: true,
                                    rotation: testData.skipped ? 270 : 0,

                                    inside: false,
                                    y: testData.skipped ? -20 : -5,
                                    padding: 0,
                                    style: {
                                        fontSize: testData.skipped ? 7 : 10,

                                    },
                                    formatter() {
                                        let self = this;

                                        return self.point.skipped ? 'Skipped' : testData.score
                                    }
                                }
                            })
                            series[2].data.push({
                                y: testIncludesVoms ? averageConvergence : 0,
                                averageConvergence,
                                baselineSymptomsTotal,
                                maxTotalProvocation,
                                // y: 10,
                                // marker: self[_getHistoryChartPointMarker](test.data),
                                percentChange: testData.percentChange,
                                color: isNonBaseline ? white : convergenceColor,
                                borderColor: isNonBaseline ? convergenceColor : lineColor,
                                borderWidth,
                                pointWidth,
                                testId: test.id,
                                skipped: testData.skipped,
                                dataLabels: {
                                    enabled: false,
                                    allowOverlap: true,
                                    rotation: testData.skipped ? 270 : 0,

                                    inside: false,
                                    y: testData.skipped ? -20 : -5,
                                    padding: 0,
                                    style: {
                                        fontSize: testData.skipped ? 7 : 10,

                                    },
                                    formatter() {
                                        let self = this;

                                        return self.point.skipped ? 'Skipped' : testData.score + ' cm'
                                    }
                                }
                            })
                        }
                        
                        
                    } else {
                        
                        series[0].data.push({
                            y: shouldShowTimeScore ? testData.score : testData.score,
                            marker: marker,
                            percentChange: testData.percentChange,
                            color: isSymptoms ? test.data.isEvent ?  colors.chart.scoreOrangeBorder : colors.chart.scoreBlueBorder : null,
                            testId: test.id
                        })
                    }

                } else if (isVoms) {
                    // if Getting the trends for VOMS but the current test does not have VOMS
                    series[0].data.push(null)
                    series[1].data.push(null)
                    series[2].data.push(null)
                } else {
                    series[0].data.push(null)
                }
            } else if (item.event) {
                series[0].data.push(null)
                categories.push(item.formattedDate)
                if (item.eventStart) {
                    xPlotBands.push({
                        color: 'rgba(255,161,42,.15)', // Color value
                        from: index, // Start of the plot band
                        to: 1000, // End of the plot band
                        eventId: item.event.id,
                        label: {
                            text: item.event.name ? item.event.name : 'Event'
                        }
                    })
                } else {
                    let eventPlotBand = xPlotBands.find(x => item.event.id === x.eventId)
                    if (eventPlotBand)
                        eventPlotBand.to = index
                }     
            }
        })
        
        
        
        let yPlotBands = [];
        
        let plotLines = [];
        let annotations = [];
        let softMin = null
        let plotBandColor = null;
        let ciColor = null;
        let baselineData = this.formattedAverages[measureId];
        // 
        if (!isSymptoms && baselineData) {
            
            plotBandColor = getCiColorForChart(baselineData.variabilityType)
            ciColor = getCiColor(baselineData.variabilityType)
            // const isTimeScore = !!baselineData.timeHighScore

            let baselineHigh = baselineData.highScore
            let baselineLow = baselineData.lowScore


            softMin = baselineLow - baselineLow * .1 > 0 ? baselineLow - baselineLow * .1 : 0


            if (showCiPlotBands && !showNormsPlotBands) {
                // let plotBandColor = getCiColorForChart(baselineData.variabilityType);
                yPlotBands.push({
                    color: getCiColorForChart(baselineData?.variabilityType), // Color value
                    from: baselineLow, // Start of the plot band
                    to: baselineHigh,
                    zIndex: 0,
                    // label: {
                    //     text: 'Profile Baseline'
                    // }
                })
            }
            



            if (true) {
                const {referenceNorms} = this.formattedAverages[measureId];

                let values = [
                    {
                        name: 'bl',
                        value: baselineLow
                    },
                    {
                        name: 'bh',
                        value: baselineHigh
                    },
                ]
                if (referenceNorms) {
                    // let values = [baselineLow, baselineHigh, referenceNorms[10], referenceNorms[25], referenceNorms[75], referenceNorms[90]];
                    values.push({
                            name: 'r10',
                            value: referenceNorms[10]
                        },
                        {
                            name: 'r25',
                            value: referenceNorms[25]
                        },
                        {
                            name: 'r75',
                            value: referenceNorms[75]
                        },
                        {
                            name: 'r90',
                            value: referenceNorms[90]
                        })

                }

                values.forEach(x => {
                    annotations.push({
                        visible: false,
                        labels: [{
                            point: {
                                xAxis: 0,
                                yAxis: 0,
                                x: 0,
                                y: x.value,
                                distance: 50,
                            },
                            // x: 0,
                            text: x.name
                        }]
                    })
                })

            }
        }

        let yAxisMax = shouldShowTimeScore || isSymptoms || isVoms || parseInt(measureId) === 79 ? null : 100;
        
        let yAxis = [{
            title: {
                text: ''
            },
            opposite: false,
            reversed: shouldShowTimeScore,
            // tickInterval: shouldShowTimeScore ? null : 10,
            // tickAmount: shouldShowTimeScore ? 6 : null,
            labels: {
                formatter() {
                    return shouldShowTimeScore ? `${this.value} ms` : this.value
                },
                // x: -20,
                // y: -4
            },
            plotLines,
            // plotBands: yPlotBands,
            softMin,
            // softMax: isTimeScore ? baselineHigh + baselineLow * .1 : 100,
            max: yAxisMax,
            stackLabels: {
                enabled: isVoms
            }
        }]
        
        if (isVoms) {
            yAxis.push({
                title: {
                    text: '',
                    
                },
                opposite: true,
                reversed: false,
                // tickInterval: shouldShowTimeScore ? null : 10,
                // tickAmount: shouldShowTimeScore ? 6 : null,
                labels: {
                    formatter() {
                        return `<strong>${this.value} cm</strong>`
                    },
                    style: {
                        color: '#34d3fd'
                    }
                    // x: -20,
                    // y: -4
                },
                plotLines,
                // plotBands: yPlotBands,
                softMin,
                // softMax: isTimeScore ? baselineHigh + baselineLow * .1 : 100,
                max: yAxisMax,
                stackLabels: {
                    enabled: isVoms
                }
            })
        }


        let options =  {
            chart: {
                style: {
                    fontFamily: "inherit",
                },
                events: {
                    render() {
                        // const hello = this; debugger
                        if (!resizeCallback || isSymptoms) return;
                        let self = this

                        const {plotTop, plotHeight, plotLeft, plotWidth, plotSizeY} = self;
                        // let xAxisBottom = self.xAxis.bottom;

                        let baselineLow, baselineHigh, r10, r25, r75, r90, yAxisOffset;

                        self.annotations.forEach(x => {
                            let text = x.labels[0].options.text;
                            let value = x.labels[0].points[0].plotY;
                            yAxisOffset = x.chart.axes[x.chart.axes.length - 1].top;
                            switch (text) {
                                case 'bl':
                                    baselineLow = value;
                                    break;
                                case 'bh':
                                    baselineHigh = value;
                                    break;
                                case 'r10':
                                    r10 = value;
                                    break;
                                case 'r25':
                                    r25 = value;
                                    break;
                                case 'r75':
                                    r75 = value;
                                    break;
                                case 'r90':
                                    r90 = value;
                                    break;

                            }

                        });
                        //
                        // let position = this.annotations[0].labels[0].points[0].plotY;
                        // let indicatorXPosition = position > 0 ? position : 0;
                        // if (indicatorXPosition > this.plotSizeY) indicatorXPosition = this.plotSizeY

                        // let testType = this.annotations[0].labels[0].options.text;

                        let baselineBottom = Math.max(baselineLow, baselineHigh)
                        let baselineTop = Math.min(baselineLow, baselineHigh)

                        let normThinBottom = Math.max(r25, r75)
                        let normThinTop = Math.min(r25, r75)
                        let normWideBottom = Math.max(r10, r90)
                        let normWideTop = Math.min(r10, r90)


                        let isBaselineTopCutOff = baselineTop < 0;
                        let isBaselineBottomCutOff = baselineBottom > plotHeight;
                        let isNormThinTopCutOff = normThinTop < 0;
                        let isNormThinBottomCutOff = normThinBottom > plotHeight;
                        let isNormWideTopCutOff = normWideTop < 0;
                        let isNormWideBottomCutOff = normWideBottom > plotHeight;

                        let isNormThinVisible = (normThinBottom > 0 && normThinBottom < plotHeight) || (normThinTop > 0 && normThinTop < plotHeight)
                        let isNormWideVisible = (normWideBottom > 0 && normWideBottom < plotHeight) || (normWideTop > 0 && normWideTop < plotHeight)



                        let response = {
                            positions: {
                                // baselineTop: isBaselineTopCutOff ? 0 : baselineTop,
                                // baselineBottom: isBaselineBottomCutOff ? plotHeight : baselineBottom,
                                //
                                //
                                // normThinTop: isNormThinTopCutOff ? 0 : normThinTop,
                                // normThinBottom: isNormThinBottomCutOff ? plotHeight : normThinBottom,
                                //
                                // normWideTop: isNormWideTopCutOff ? 0 : normWideTop,
                                // normWideBottom: isNormWideBottomCutOff ? plotHeight : normWideBottom,

                                baselineTop: isBaselineTopCutOff ? 0 : baselineTop,
                                baselineBottom: isBaselineBottomCutOff ? plotHeight : baselineBottom,


                                normThinTop: !isNormThinVisible ? null : isNormThinTopCutOff ? 0 : normThinTop,
                                normThinBottom: !isNormThinVisible ? null : isNormThinBottomCutOff ? plotHeight : normThinBottom,

                                normWideTop: !isNormWideVisible ? null : isNormWideTopCutOff ? 0 : normWideTop,
                                normWideBottom: !isNormWideVisible ? null : isNormWideBottomCutOff ? plotHeight : normWideBottom,

                            },
                            cutOffs: {
                                baselineTop: isBaselineTopCutOff,
                                baselineBottom: isBaselineBottomCutOff,
                                normThinTop: isNormThinTopCutOff,
                                normThinBottom: isNormThinBottomCutOff,
                                normWideTop: isNormWideTopCutOff,
                                normWideBottom: isNormWideBottomCutOff,

                            },
                            yAxisOffset,
                            ciColor
                        }
                        console.log(response)
                        resizeCallback(response)
                    }
                },

                type: isSymptoms || isVoms ? 'column' : 'line'
            },
            title: {
                text: ''
            },

            subtitle: {
                text: ''
            },
            annotations,

            yAxis,

            xAxis: [
                {
                    accessibility: {
                        rangeDescription: ''
                    },
                    crosshair: {
                        width: 1,
                        color: '#bdbdbd'
                    },
                    categories,
                    plotBands: xPlotBands,
                },
            ],
            tooltip: {
                enabled: tooltipEnabled,
                useHTML: true,
                backgroundColor: isVoms ? '#fafafa' : 'black',
                borderColor: isVoms ? '#CCC' : 'black',
                shadow: false,
                outside: true,
                formatter() {
                    let chartSelf = this;
                    
                    // let something = () => console.log('hello')
                    if (!isVoms) {
                        return `<div style="color: white; z-index: 10; text-align: center">
<!--                            <span style="font-size: 9px">Baseline Assessment</span><br/>-->
                            ${chartSelf.y.toFixed(0)} ${shouldShowTimeScore ? 'ms' : ''}
                            </div>`
                    } else {
                        
                        const {testId} = chartSelf.point;
                        const isNonBaseline = self.tests.find(x => x.id === testId).data.isNonBaseline
                        const {averageConvergence, baselineSymptomsTotal, maxTotalProvocation} = chartSelf.point;
                        let white = "#fff";
                        
                        let baselineSymptomsSquareBackgroundColor = isNonBaseline ? white : baselineSymptomsColor;
                        let baselineSymptomsSquare = `<div style="height: 10px; width: 10px; margin-right: 3px; border: 3px solid ` + baselineSymptomsColor + ` ; background-color:  ` + baselineSymptomsSquareBackgroundColor + ` "></div>`
                        
                        let maxTotalProvocationSquareBackgroundColor = isNonBaseline ? white : maxTotalProvocationColor;
                        let maxTotalProvocationSquare = `<div style="height: 10px; width: 10px; margin-right: 3px; border: 3px solid ` + maxTotalProvocationColor + ` ; background-color:  ` + maxTotalProvocationSquareBackgroundColor + ` "></div>`
                        
                        let averageConvergenceSquareBackgroundColor = isNonBaseline ? white : convergenceColor;
                        let averageConvergenceSquare = `<div style="height: 10px; width: 10px; margin-right: 3px; border: 3px solid ` + convergenceColor + ` ; background-color:  ` + averageConvergenceSquareBackgroundColor + ` "></div>`
                        
                        let transparentSquare = `<div style="height: 10px; width: 10px; margin-right: 3px; border: 3px solid transparent; background-color: transparent"></div>`
                        
                        return `<div style="color: black; z-index: 10; background-color: white; font-size: 14px">
                            <div style="font-weight: bold; padding-bottom: 5px">${isNonBaseline ? 'Non-Baseline' : 'Baseline'} Test</div>
                                <div style="display: flex"><div style="display: flex; align-items: center; flex: 1">${baselineSymptomsSquare}<span style="color: #616161">Baseline Symptoms: </span></div><span style="font-weight: bold; margin-left: 3px"> ${baselineSymptomsTotal}</span></div>
                                <div style="display: flex"><div style="display: flex; align-items: center; flex: 1">${maxTotalProvocationSquare}<span style="color: #616161">Max Provocation: </span></div><span style="font-weight: bold; margin-left: 3px"> ${maxTotalProvocation}</span></div>
                                <div><hr style="margin-top: 2px; margin-bottom: 2px; margin-left: 13px"></div>
                                <div style="display: flex"><div style="display: flex; align-items: center; flex: 1">${transparentSquare}<span style="color: #616161">Max Total Symptoms: </span></div><span style="font-weight: bold; margin-left: 3px"> ${baselineSymptomsTotal + maxTotalProvocation}</span></div>
                                <div style="display: flex; align-items: center; margin-top: 10px">${averageConvergenceSquare}<span style="color: #616161">Average Convergence: </span><span style="font-weight: bold; margin-left: 3px"> ${averageConvergence} cm</span></div>
         
                            </div>`
                    }
                   

                }
            },

            plotOptions: {
                column: {
                    grouping: true,
                    stacking: isVoms ? 'normal' : null,
                    dataLabels: {
                        enabled: isSymptoms || isVoms,
                        allowOverlap: true,
                    },
                },
                series: {
                    animation: false,
                    connectNulls: true,
                    point: {
                        events: {
                            mouseOver: function(event) {
                                onMouseOver ? onMouseOver(event) : null
                            },
                            mouseOut: function(event) {
                                onMouseOut ? onMouseOut(event) : null
                            },
                            click: function(event) {
                                onSeriesClick ? onSeriesClick(event) : null
                            }
                        }
                    },
                    // pointPadding: isVoms ? -.9 : null,
                    groupPadding: isVoms && isMobileView ? .39 : isVoms ? .45 : null
                }
            },
            legend: {
                enabled: isVoms
            },

            series,
            credits: {
                enabled: false
            }

        }

        // if (isSymptoms) {
        //     
        // }
        
        console.log(measureId, options);
        
        return options;
    };
    
    getHistoryChartOptions({tooltipCallback}) {
        let series = [];

        const {data} = this;
        console.log("Before series data: ", data)

        if (data && data.averages) {
            if (data.averages.balance.testCount > 0)
                series.push(this[_getHistoryChartSeries]('Balance', '#333', 'ShortDash', 'balanceResults', data.averages.balance));
            if (data.averages.reaction.testCount > 0)
                series.push(this[_getHistoryChartSeries]('Reaction', '#A1A1A1', 'Solid', 'reactionResults', data.averages.reaction));
            if (data.averages.ocular.testCount > 0)
                series.push(this[_getHistoryChartSeries]('Oculomotor', '#A1A1A1', 'ShortDot', 'ocularResults', data.averages.ocular));
            if (data.averages.impulseControl.testCount > 0)
                series.push(this[_getHistoryChartSeries]('Impulse Control', '#333', 'ShortDashDot', 'impulseControlResults', data.averages.impulseControl));
            if (data.averages.inspectionTime.testCount > 0)
                series.push(this[_getHistoryChartSeries]('Inspection Time', '#A1A1A1', 'Dot', 'inspectionTimeResults', data.averages.inspectionTime));
            if (data.averages.memory.testCount > 0)
                series.push(this[_getHistoryChartSeries]('Memory', '#333', 'LongDash', 'memoryResults', data.averages.memory));
            if (data.averages.numberCounting15.testCount > 0)
                series.push(this[_getHistoryChartSeries]('Counting 15', '#333', 'LongDashDotDot', 'numberCounting15Results', data.averages.numberCounting15));
            if (data.averages.numberCounting20.testCount > 0)
                series.push(this[_getHistoryChartSeries]('Counting 20', '#333', 'ShortDot', 'numberCounting20Results', data.averages.numberCounting20));
            if (data.averages.numberCounting24.testCount > 0)
                series.push(this[_getHistoryChartSeries]('Counting 24', '#333', 'LongDashDot', 'numberCounting24Results', data.averages.numberCounting24));
            if (data.averages.multipleObjectTracking.testCount > 0)
                series.push(this[_getHistoryChartSeries]('MOT', '#A1A1A1', 'LongDashDot', 'multipleObjectTrackingResults', data.averages.multipleObjectTracking));
        }

        if (series.length === 0 || data.tests.length === 0) {
            return null
        }

        console.log("after series: ", series)

        // if (series.length > 1) {
        //     series = series.slice(-1)
        // }

        return {
            credits: false,
            chart: {
                type: 'line',
                backgroundColor: '#FFF'
            },
            exporting: {
                enabled: false
            },
            title: null,
            xAxis: {
                labels: {
                    enabled: false
                },
                tickLength: 0
            },
            yAxis: {
                lineWidth: 0,
                title: null,
                tickInterval: 20,
                max: 100,
                min: 0,
                labels: {
                    style: {
                        color: '#A1A1A1',
                        fontFamily: "SF Pro Text",
                        textOverflow: 'none'
                    }
                }
            },
            tooltip: {
                crosshairs: [true, false],
                backgroundColor: '#F9F9F9',
                borderWidth: 1,
                borderColor: '#CACACA',
                useHTML: true,
                formatter: function (e) {
                    // testClicked(data.testsToShow[this.x].test.id);
                    // console.log("Test ID: ", data.testsToShow[this.x].test.id);
                    tooltipCallback(this.point.testId)
                    let date = data.testsToShow[this.x].completedOn;
                    let score = this.y;
                    let tooltipString = `${moment.tz(date, "America/Chicago").format('M/D/YY h:mma')}<br/>
                    Score: ${toFixedNullable(score, 2)}`;
                    return tooltipString;
                }
            },
            plotOptions: {
                series: {
                    animation: false
                }
            },
            legend: {
                enabled: true,
                symbolWidth: 24,
                symbolHeight: 0,
                itemStyle: {
                    color: '#A1A1A1',
                    fontWeight: 'regular',
                    fontFamily: "SF Pro Text",
                    fontSize: '10px'
                }
            },
            series: series,
            navigation: {
                buttonOptions: {
                    verticalAlign: 'top',
                    y: -15
                }
            }
        }
    }

    // mobile only
    getBaselinesByCategory(category) {
        // return ProfileTest.getTestTypesAsObject(category)
        let response = [];

        const {formattedAverages} = this;

        // filter by averages that actually have a baseline (if norms are available they will be returned in the averages object even if no baseline)
        let averagesKeys = Object.keys(formattedAverages).filter(key => typeof formattedAverages[key].score === 'number');




        let measureCategoryKeys = Object.keys(measuresCategories);



        measureCategoryKeys.forEach((category, index) => {
            let categoryTests = {};
            let testInCategory = false
            averagesKeys.forEach(measureTaken => {

                if (formattedAverages[measureTaken].category === measuresCategories[category].value) {
                    categoryTests[measureTaken] = formattedAverages[measureTaken]
                    testInCategory = true
                }
            });

            if (testInCategory) response.push({
                name: category,
                label: measuresCategories[category].label,
                categoryId: measuresCategories[category].value,
                measureResults: categoryTests,
                hasChartableBaselines: measuresCategories[category].hasChartableBaselines
            })

            // 


        });

        return response
    }
    

    [_getHistoryChartSeries](name, color, dashStyle, resultObjName, average) {
        let data = this.data;
        let scores = [];
        let timeScores = [];
        let isExcludedArray = [];
        // TODO: use complex object arrays instead of parallel arrays
        // console.log('TESTS TO SHOW: ', data.testsToShow)

        if (!data.testsToShow || data.testsToShow.length === 0) return null

        // Limit chart to showing the previous 20 tests
        let graphableTests = data.testsToShow;
        if (data.testsToShow > 20) {
            graphableTests = data.testsToShow.slice(-20)
        }

        graphableTests.forEach((test, i) => {
            scores[i] = test[resultObjName] ? {y: test[resultObjName].score, marker: this[_getHistoryChartPointMarker](test), testId: test.id} : null;
            // timeScores[i] = test[resultObjName] ? test[resultObjName].timeScore : null;
            isExcludedArray[i] = test[resultObjName] ? test[resultObjName].results.every(function (result) { //if all results for that test category are excluded, show as black
                return result.isExcluded;
            }) : false;
        })

        // limit to last 20 tests

        let variabilityColor = average.variabilityType ? getCiColor(average.variabilityType) : '';

        return {
            name: name,
            color: color,
            data: scores,
            scores: scores,
            // timeScores: timeScores,
            isExcludedArray: isExcludedArray,
            dashStyle: dashStyle,
            lineWidth: 2,
            marker: {
                lineWidth: 1,
                radius: 3,
                symbol: 'circle'
            },
            highScore: toFixedNullable(average.highScore, 2),
            lowScore: toFixedNullable(average.lowScore, 2),
            highScale: toFixedNullable(average.highScale, 2),
            lowScale: toFixedNullable(average.lowScale, 2),
            // timeHighScore: toFixedNullable(average.timeHighScore, 2),
            // timeLowScore: toFixedNullable(average.timeLowScore, 2),
            // timeHighScale: toFixedNullable(average.timeHighScale, 2),
            // timeLowScale: toFixedNullable(average.timeLowScale, 2),
            variabilityColor: variabilityColor
        };
    }

    [_getAllTestsAsClasses](tests, averages) {
        let self = this;
        if (!tests || tests.length < 1) return {formattedTests: [], measureIdsTaken: [], categoryIdsTaken: []}

        let measureIdsTaken = [];
        let categoryIdsTaken = [];
        
        let events = JSON.parse(JSON.stringify(self.data.profileEvents))

        let formattedTests = tests.map(test => {
            
            let measuresIds = Object.keys(test.measureResults)
            
            // check if test was taken while the profile was in an event
            test.eventId = null
            this.events.forEach(event => {
                if (!event?.testIds) event.testIds = []
                // if the event doesn't have an end date it means its still open
                let endDate = event.endedOn ? event.endedOn : moment().add(10, "years");
                if (moment(test.completedOn).isBetween(moment(event.startedOn), endDate)) {
                    // test.isDuringEvent = true;
                    // debugg
                    test.eventId = event.id;
                    
                    event.testIds.push(test.id)
                }
                
            })

            // Add symptoms if its been taken and put it at the front
            if (test.symptoms) {
                if (!measureIdsTaken.includes("9999")) {
                    measureIdsTaken.push("9999")
                }
                if (!categoryIdsTaken.includes(10)) {
                    categoryIdsTaken.splice(1,0,10);
                }
            }

            measuresIds.forEach(id => {
                if (!measureIdsTaken.includes(id)) {
                    measureIdsTaken.push(id);
                }
                if (allMeasuresFromConstants[id]?.category && !categoryIdsTaken.includes(allMeasuresFromConstants[id].category)) {
                    categoryIdsTaken.push(allMeasuresFromConstants[id].category)
                }
                
                
                self.reliableChangeValues = {
                    [id]: {
                        redChange: test.measureResults[id].redYellowMeasureDifference?.redChange,
                        yellowChange: test.measureResults[id].redYellowMeasureDifference?.yellowChange

                    }
                }

            })
            test.averages = averages;
            
            return new ProfileTest({data: test, baselineStartDate: self.profileInfo.baselineStartDate, profileId: self.profileInfo.id})
        })

        //put categories in order and put 10 @ front

        categoryIdsTaken.sort(function(a, b){
            if (a === 10) return -1;
            if (b === 10) return 1
            return a-b
        });
        console.log(categoryIdsTaken)
        return {formattedTests, measureIdsTaken, categoryIdsTaken}

    }

    [_getHistoryChartPointMarker]({test, isNonBaseline, hasDuplicateTests, isExcluded}) {
        console.log(test)
        let startDate = moment(this.data.profile.baselineStartDate);
        let white = '#ffffff'

        const {event, baseline, excluded} = colors.chart;
        
        let mainColor = '#333';
        let excludedColor = '#999';
        
        

        if (isNonBaseline) {
            // if (t.isExcluded || moment(t.completedOn) < startDate) {
            //     return {
            //         fillColor: dark,
            //         lineColor: orange,
            //         lineWidth: 2
            //     }
            // } else 
            if (hasDuplicateTests) {
                return {
                    fillColor: white,
                    lineColor: mainColor,
                    lineWidth: 2,
                    symbol: 'square'
                }
            } else {
                // 
                return {
                    fillColor: white,
                    lineColor: mainColor,
                    lineWidth: 2
                }
            }
        } else {
            if (isExcluded || moment(test.completedOn) < startDate) {
                return {
                    fillColor: excludedColor,
                    lineColor: excludedColor,
                    lineWidth: 2
                }
            } else if (hasDuplicateTests) {
                return {
                    fillColor: mainColor,
                    lineColor: mainColor,
                    lineWidth: 2,
                    symbol: 'square'
                }
            } else {
                return {
                    fillColor: mainColor,
                    lineColor: mainColor,
                    lineWidth: 2,
                }
            }
        }
    }

    [_addTestsToShowProperty](data) {
        let testsWithGraphableData = data.tests.filter(function (test) {
            return test.hasGraphableData;
        });

        if (testsWithGraphableData.length > 0) {
            data.testsToShow = testsWithGraphableData.slice(-20);
        }
        return data
    }

    static getProfileForm({
                              isNewProfile = false,
                              profile = null,
                              isMetric = false,
                              createdInGroupId = null,
                              isMobile = true,
                              isCovidOrg = false
                          }) {
        let model = {
            id: null,
            firstName: '',
            lastName: '',
            birthDate: null,
            sex: null,
            gender: null,
            heightInCentimeters: null,
            heightInFeet: null,
            heightInInches: null,
            inches: null,
            weight: null,
            race: null,
            ethnicity: null,
            graduationYear: null,
            homeOtherThanEnglish: null,
            primaryLanguage: null,
            speakSecondaryLanguage: null,
            secondaryLanguage: null,
            hasHadConcussion: null,
            lastConcussionDate: null,
            hasAttentionDisorder: null,
            email: '',
            password: '',
            userWantsAnAccount: null,
            groupIds: [],
            organizationProtocolId: null,
            defaultOrganizationProtocolId: null,
            baselineStartDate: null,
            isInactive: false,
            organizationId: null,
            externalId: null
        }

        if (isNewProfile) {
            if (createdInGroupId) {
                model.groupIds = [createdInGroupId]
            }
            
            if (!isCovidOrg) {
                model.userWantsAnAccount = false
            }



        }

        if (profile && !isNewProfile) {
            let {
                id,
                firstName,
                lastName,
                birthDate,
                gender,
                height,
                weight,
                isInactive,
                externalId,
                organizationId,
                defaultOrganizationProtocolId,
                additionalData,
                baselineStartDate,
                graduationYear,
                groupIds,
                accountEmail,
                accountPassword,
                email,
            } = profile;

            if (typeof additionalData === "string") {
                additionalData = JSON.parse(additionalData)
            }

            // let {
            //     hasHadConcussion,
            //     lastConcussionDate,
            //     hasAttentionDisorder,
            //     race,
            //     primaryLanguage,
            //     secondaryLanguage,
            //     genderIdentity
            // } = additionalDataModel;



            let heightInFeet, heightInInches, heightInCentimeters

            if (!isMetric) {
                heightInFeet = Math.floor(height / 12)
                heightInInches = height % 12
            } else {
                heightInCentimeters = inchesToCentimeters(height)

                weight = poundsToKg(weight)
            }

            let falseTemp = isMobile ? 'false' : false;
            let trueTemp = isMobile ? 'true' : true

            // ;
            model.id = id;
            model.firstName = firstName;
            model.lastName = lastName;
            model.birthDate = moment(birthDate).utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).format('YYYY-MM-DD');
            model.sex = gender;
            model.gender = additionalData ? additionalData.genderIdentity : null;
            model.heightInCentimeters = heightInCentimeters;
            model.heightInFeet = heightInFeet;
            model.heightInInches = heightInInches;
            model.inches = height;
            model.weight = weight;
            model.race = additionalData ? additionalData.race : null;
            model.ethnicity = additionalData ? additionalData.ethnicity : null;
            model.graduationYear = graduationYear ? graduationYear : 1;
            model.homeOtherThanEnglish = additionalData?.primaryLanguage ? additionalData.primaryLanguage === 1 ? falseTemp : trueTemp : null;
            model.primaryLanguage = additionalData ? additionalData.primaryLanguage : null;
            model.speakSecondaryLanguage = additionalData?.secondaryLanguage ? additionalData.secondaryLanguage ? trueTemp : falseTemp : additionalData?.genderIdentity ? falseTemp : null;
            model.secondaryLanguage = additionalData ? additionalData.secondaryLanguage : null;
            model.hasHadConcussion = additionalData ? additionalData.hasHadConcussion : null;
            model.lastConcussionDate = additionalData?.lastConcussionDate ? moment(additionalData.lastConcussionDate).utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).format('YYYY-MM-DD') : null;
            model.hasAttentionDisorder = additionalData ? additionalData.hasAttentionDisorder : null;
            model.email = accountEmail ? accountEmail : email ? email : null;
            model.password = accountPassword;
            model.userWantsAnAccount = accountPassword ? trueTemp : falseTemp;
            model.groupIds = groupIds;
            model.defaultOrganizationProtocolId = defaultOrganizationProtocolId;
            model.baselineStartDate = baselineStartDate;
            model.isInactive = isInactive;
            model.organizationId = organizationId;
            model.externalId = externalId;
        }

        // portal API set up for this object
        // model.additionalData = model.additionalDataModel;


        return model

    }

    static getCreateUpdateProfileRequestFromForm({form, isMetric, isMobile = true, enableSportsProfileProperties}) {
        let request = {
            id: null,
            firstName: null,
            lastName: null,
            birthDate: null,
            gender: null,
            height: null,
            weight: null,
            isInactive: false,
            organizationId: null,
            defaultOrganizationProtocolId: null,
            additionalData: {
                hasHadConcussion: null,
                lastConcussionDate: null,
                hasAttentionDisorder: null,
                race: null,
                ethnicity: null,
                primaryLanguage: null,
                secondaryLanguage: null,
                genderIdentity: null
            },
            baselineStartDate: null,
            graduationYear: null,
            groupIds: [],
            accountEmail: null,
            accountPassword: null,
            accountProfiles: [],
        }

        let {
            id,
            firstName,
            lastName,
            birthDate,
            sex,
            gender,
            heightInCentimeters,
            heightInFeet,
            heightInInches,
            weight,
            race,
            ethnicity,
            graduationYear,
            homeOtherThanEnglish,
            homeLanguage,
            primaryLanguage,
            speakSecondaryLanguage,
            secondaryLanguage,
            hasHadConcussion,
            lastConcussionDate,
            hasAttentionDisorder,
            email,
            password,
            userWantsAnAccount,
            defaultOrganizationProtocolId,
            organizationId,
            groupIds,
            accountProfiles,
            externalId
        } = form;

        hasHadConcussion = hasHadConcussion ? parseInt(hasHadConcussion) : null
        hasAttentionDisorder = hasAttentionDisorder ? parseInt(hasAttentionDisorder) : null



        let height;
        if (!isMetric) {
            height = parseInt(heightInFeet) * 12 + parseInt(heightInInches);
            weight = parseInt(weight)
        } else {
            height = heightInCentimeters * 0.393701;
            weight = parseInt(weight) * 2.20462
        }

        let falseTemp = isMobile ? 'false' : false;
        let trueTemp = isMobile ? 'true' : true

        request.id = id;
        request.firstName = firstName;
        request.lastName = lastName;
        request.birthDate = moment(birthDate).utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).format();
        request.gender = sex;
        request.height = height;
        request.weight = weight;
        request.externalId = externalId;
        
        request.accountEmail = email;
        request.email = email;
        request.accountPassword = password;
        request.defaultOrganizationProtocolId = defaultOrganizationProtocolId;
        request.organizationId = organizationId;
        request.accountProfiles = accountProfiles;
        //
        // if (!groupIds) {
        //     groupIds = [];
        //     if (this.createdInGroupId)
        //         groupIds.push(this.createdInGroupId)
        // }


        request.groupIds = groupIds;

        // 
        
        if (!enableSportsProfileProperties) {
            request.additionalData.hasHadConcussion = null
            request.additionalData.hasAttentionDisorder = null;
            request.additionalData.lastConcussionDate = null;
            request.additionalData.race = null;
            request.additionalData.ethnicity = null;
            request.additionalData.primaryLanguage = null;
            request.additionalData.secondaryLanguage = null;
            request.additionalData.genderIdentity = null;
        } else {
            request.graduationYear = parseInt(graduationYear) !== 1 ? parseInt(graduationYear) : null;
            
            request.additionalData.hasHadConcussion = hasHadConcussion ? hasHadConcussion : null;
            request.additionalData.hasAttentionDisorder = hasAttentionDisorder ? hasAttentionDisorder : null;
            request.additionalData.lastConcussionDate = lastConcussionDate ? moment(lastConcussionDate).utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0}).format() : null;
            request.additionalData.race = race ? parseInt(race) : null;
            request.additionalData.ethnicity = ethnicity ? parseInt(ethnicity) : null;
            request.additionalData.primaryLanguage = homeOtherThanEnglish === trueTemp ? parseInt(primaryLanguage) : 1;
            request.additionalData.secondaryLanguage = speakSecondaryLanguage === trueTemp ? parseInt(secondaryLanguage) : null;
            request.additionalData.genderIdentity = parseInt(gender);
        }

        

        // the portal API is set to receive this object
        // request.additionalData = request.additionalDataModel

        return request
    }

}