import httpService from "@/services/httpService";
import {baseUrl} from "@/config"


const endpoint = baseUrl + "/Billing"

const updateBillingInfoModel = {
    billingAddress1: "string",
    billingAddress2: "string",
    billingCity: "string",
    billingState: "string",
    billingPostalCode: "string",
    billingPhone: "string",
    billMethod: 0,
    billingName: "string",
    billingContactName: "string",
    billingEmail: "string"
}

const getInvoices = async () => {
    try {
        return await httpService.post(endpoint + '/GetInvoices', )
    } catch (e) {
    }
}

const getInvoice = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetInvoice', {id})
    } catch (e) {
    }
}

const getInvoiceForAzureAD = async (id, authHeader) => {
    try {
        return await httpService.post(endpoint + '/GetInvoiceForAzureAD', {id}, {
            headers: {
                'Authorization': "Bearer " + authHeader
            }
        })
    } catch (e) {
    }
}

const getInvoiceForPortal = async (id, authHeader, orgId) => {
    try {
        return await httpService.post(endpoint + '/GetInvoiceForPortal', {id}, {
            headers: {
                'Authorization': "Bearer " + authHeader,
                'Selected-Organization-Id': orgId
            }
        })
    } catch (e) {
    }
}

const getBillingInfo = async () => {
    try {
        return await httpService.post(endpoint + '/GetBillingInfo', )
    } catch (e) {
    }
}

const getBillingOverview = async () => {
    try {
        return await httpService.post(endpoint + '/GetBillingOverview', )
    } catch (e) {
    }
}

const addEditCard = async (request) => {
    try {
        return await httpService.post(endpoint + '/AddEditCard', request)
    } catch (e) {
    }
}

const updateBillingInfo = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateBillingInfo', request)
    } catch (e) {
    }
}

const updateBillToOrg = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateBillToOrg', request)
    } catch (e) {
    }
}

const updateBillingCycle = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateBillingCycle', request)
    } catch (e) {
    }
}

const payInvoice = async (request) => {
    try {
        return await httpService.post(endpoint + '/PayInvoice', request)
    } catch (e) {
    }
}

const createInvoice = async (request) => {
    try {
        return await httpService.post(endpoint + '/CreateInvoice', request)
    } catch (e) {
    }
}

const updateInvoice = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateInvoice', request)
    } catch (e) {
    }
}

const deleteInvoice = async (id, voidReason) => {
    try {
        let query = 'id=' + id + '&voidReason=' + voidReason;
        return await httpService.post(endpoint + '/DeleteInvoice?' + query)
    } catch (e) {
    }
}

const removeCreditCard = async () => {
    try {
        return await httpService.post(endpoint + '/RemoveCreditCard')
    } catch (e) {
    }
}

const sendInvoicePdfEmail = async (id) => {
    try {
        let query = 'invoiceId=' + id;
        return await httpService.post(endpoint + '/SendInvoicePdfEmail?' + query, )
    } catch (e) {
    }
}

const sendInvoiceReceiptEmail = async (id) => {
    try {
        let query = 'invoiceId=' + id;
        return await httpService.post(endpoint + '/SendInvoiceReceiptEmail?' + query, )
    } catch (e) {
    }
}

const getBillingChildOrgs = async () => {
    try {
        return await httpService.post(endpoint + '/GetBillingChildOrgs', )
    } catch (e) {
    }
}

const addBillingChildOrg = async (request) => {
    try {
        return await httpService.post(endpoint + '/AddBillingChildOrg', request)
    } catch (e) {
    }
}

const removeBillingChildOrg = async (request) => {
    try {
        return await httpService.post(endpoint + '/RemoveBillingChildOrg', request)
    } catch (e) {
    }
}

const getProfileUsageHistory = async () => {
    try {
        return await httpService.post(endpoint + '/GetProfileUsageHistory', {})
    } catch (e) {
    }
}

const getMonthlyProfileCounts = async (request) => {
    try {
        return await httpService.post(endpoint + '/GetMonthlyProfileCounts', request)
    } catch (e) {
    }
}

const getYearlyProfileCounts = async (request) => {
    try {
        return await httpService.post(endpoint + '/GetYearlyProfileCounts', request)
    } catch (e) {
    }
}

const invoicePdfForPortal = async (request) => {
    try {
        // return await httpService.post(endpoint + '/InvoicePdfForPortal', request)

        let response = await httpService.post(endpoint + '/InvoicePdfForPortal', request, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer'
        })

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        let documentName = `Sway Invoice ${request.invoiceId}.pdf`

        link.setAttribute('download', documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
        return response
        
    } catch (e) {
    }
}

const createDraftInvoicePdf = async ({request}) => {

    try {
        let response = await httpService.post(endpoint + '/DraftPdfReport', request, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer'
        })

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        let documentName = `Draft_Invoice.pdf`

        link.setAttribute('download', documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
        return response


    } catch(error) {
        console.log(error)
    }
}



const createOrUpdateProductSubscriptionById = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateProductSubscriptionById', request)
    } catch (e) {
    }
}

const deleteProductSubscriptionById = async (id) => {
    try {
        return await httpService.post(endpoint + '/DeleteProductSubscription', id, {
                headers: {'Content-Type': 'application/json; charset=utf-8'}
            })
        // return await httpService.post(endpoint + '/DeleteProductSubscription')
    } catch(e) {
        
    }
}

const getAllSubscriptions = async (isSports = true) => {
    try {
        return await httpService.post(endpoint + `/GetAllSubscriptions?isSports=${isSports}`)
    } catch (e) {
    }
}

const createInvoiceFromSubscription = async (id) => {
    try {
        return await httpService.post(endpoint + `/CreateInvoiceFromSubscription?productSubscriptionId=${id}`)
    } catch (e) {
    }
}


export {
    updateBillingInfoModel,
    getInvoices,
    getInvoice,
    getInvoiceForAzureAD,
    getBillingInfo,
    getBillingOverview,
    addEditCard,
    updateBillingInfo,
    updateBillToOrg,
    updateBillingCycle,
    payInvoice,
    createInvoice,
    updateInvoice,
    deleteInvoice,
    removeCreditCard,
    sendInvoicePdfEmail,
    sendInvoiceReceiptEmail,
    createDraftInvoicePdf,
    getBillingChildOrgs,
    addBillingChildOrg,
    removeBillingChildOrg,
    getProfileUsageHistory,
    getMonthlyProfileCounts,
    getYearlyProfileCounts,
    
    getInvoiceForPortal,
    invoicePdfForPortal,
    createOrUpdateProductSubscriptionById,
    deleteProductSubscriptionById,
    getAllSubscriptions,
    createInvoiceFromSubscription
}
