import httpService from "@/services/httpService";
import {baseUrl} from "@/config"
import store from '../store'
import {SET_ACCOUNT, SET_ORGANIZATION} from "@/store/mutation-types";
import router from "@/router";
import {auth} from "@/main";
import {getOrganization} from "@/services/organization.service";

const endpoint = baseUrl + "/account"
let tempEndpoint = baseUrl + "/account"

const accountModel = {
    id: 0,
    organizationId: 0,
    organizationName: "string",
    email: "string",
    password: "string",
    firstName: "string",
    lastName: "string",
    phoneNumber: "string",
    jobTitle: "string",
    resetToken: "string",
    professionalTypeId: 0,
    isDisabled: false,
    isAdmin: true,
    isSafetyCoach: false,
    accountTypeId: 1
}

const updateAccountModel = {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    jobTitle: "",
    professionalTypeId: 0,
    isDisabled: false,
    isSafetyCoach: false,
    isBillingContact: false,
    accountTypeId: 1,
    organizationAccessTypeId: 1,
    groupIds: [
        0
    ]
}

const updateAccountGroupsModel = {
    id: 0,
    groupIds: [
        0
    ]
}

const newAccountModel = {
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    jobTitle: "",
    professionalTypeId: 0,
    isDisabled: false,
    isSafetyCoach: false,
    isBillingContact: false,
    accountTypeId: 1,
    organizationAccessTypeId: 1,
    groupIds: []

}

const updateAccountPasswordModel = {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    jobTitle: "",
    professionalTypeId: 0,
    isDisabled: false,
    isSafetyCoach: false,
    accountTypeId: 1,
    organizationAccessTypeId: 1,
    groupIds: [
        0
    ],
    updateGroups: false,
    oldPassword: "",
    newPassword: ""
}

const getloginmethod = async (email) => {
    try {
        const response = await httpService.post(tempEndpoint + '/GetLoginMethod', {email});
        return response;
    } catch {

    }
}

const samllogin = async () => {
    try {
        const response = await httpService.post(tempEndpoint + '/samllogin');
        
        return await saveStuffFromLogin(response, "SamlSso");
    } catch {

    }
}

const ssologin = async (token) => {
    try {
        if (token)
            httpService.setAuthTokenHeader(token)
        const response = await httpService.post(tempEndpoint + '/ssologin');

        return await saveStuffFromLogin(response, "OAuthSso");
    } catch (ex) { }
}

const login = async (email, password) => {
    try {
        let response = await httpService.post(tempEndpoint + '/login', {email, password});

        response = await saveStuffFromLogin(response, "SwayUsernamePassword");
        httpService.setAuthTokenHeader(response.token)
        return response
    } catch {

    }
}

const switchSelectedOrganization = async (organizationId, router) => {
    try {
        const response = await httpService.post(endpoint + '/SwitchSelectedOrganization', {organizationId});

        return await saveStuffFromLogin(response);
    } catch {

    }
}

const verifyMfaCode = async (request) => {
    try {
        const response = await httpService.post(tempEndpoint + '/VerifyMfaCode', request)
        
        if (response)
            httpService.setAuthTokenHeader(response.token)
        
        return await saveStuffFromLogin(response, "SwayUsernamePassword");
    } catch {

    }
}

const getMobileToken = async (request) => {
    try {
        const response = await httpService.post(tempEndpoint + '/GetMobileToken', request);

        return response;
    } catch {

    }
}

const saveStuffFromLogin = async (response, authSource) => {
    if (response && response.isSuccess) {
        console.log("response");
        const {account, token, hasMultipleOrganizations, availableOrganizations, selectedOrganization, viewingAsSuper} = response;
        localStorage.setItem("jwtToken", token);

        account.availableOrganizations = availableOrganizations;
        account.hasMultipleOrganizations = hasMultipleOrganizations;
        account.selectedOrganization = selectedOrganization;
        account.viewingAsSuper = viewingAsSuper;
        account.isEnterpriseAdminOrg = selectedOrganization ? selectedOrganization.isEnterpriseAdminOrg : false;
        store.commit(SET_ACCOUNT, account);
        if (account){
            store.commit(SET_ORGANIZATION, selectedOrganization)
        }
        const selectedOrgId = selectedOrganization ? selectedOrganization.id : null;
        localStorage.setItem("selectedOrganizationId", selectedOrgId);
        if (authSource)
            localStorage.setItem("authenticationSourceType", authSource);
        httpService.setSelectedOrgId(selectedOrgId);
        storeAccountInStorage(account);
        //httpService.setAuthTokenHeader(token)
        return response;
    }
}

const storeAccountInStorage = account => {

    localStorage.setItem('user', JSON.stringify(account))
}

// for when the user updates the myAccount page
const updateAccountInStorage = newAccount => {
    let currentObject = getUser();
    let keys = Object.keys(newAccount);
    // 
    keys.forEach(key => {
        if (key in currentObject) {
            currentObject[key] = newAccount[key]
        }
        
    })
    
    storeAccountInStorage(currentObject)
    store.commit(SET_ACCOUNT, currentObject)
}

const updateAccount = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateAccount', request)

        
    } catch {

    }
}

const updateAccountGroups = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateAccountGroups', request)


    } catch {

    }
}

const updateAccountOrganization = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateAccountOrganization', request)


    } catch {

    }
}

const requestMfaCode = async (request) => {
    try {
        return await httpService.post(tempEndpoint + '/RequestMfaCode', request)


    } catch {

    }
}

const forgotPassword = async (request) => {
    try {
        return await httpService.post(endpoint + '/ForgotPassword', request)


    } catch {

    }
}

const resetPassword = async (request) => {
    try {
        return await httpService.post(endpoint + '/ResetPassword', request)


    } catch {

    }
}

const updatePassword = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdatePassword', request)

        
    } catch {

    }
}

const refreshToken = async () => {
    
    try {
        //set and update a localstorage item of the time here and only continue if it's been more than 30 seconds since the last refresh
        if (localStorage.getItem('lastRefresh') && (new Date() - new Date(localStorage.getItem('lastRefresh'))) / 1000 < 30) 
            return;
        
        localStorage.setItem('lastRefresh', new Date())

        let response = await httpService.post(endpoint + '/RefreshToken')
        
        if (response && response.isSuccess){
            localStorage.setItem('jwtToken', response.token);
            httpService.setAuthTokenHeader(response.token)
        }
        
        return response;
    } catch {

    }
}

const deleteAccount = async (id) => {
    try {
        return await httpService.post(endpoint + '/DeleteAccount', {id})


    } catch {

    }
}

const createAccount = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/CreateAccount', request)
        return response
    } catch {

    }
}

const createParentAccount = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/CreatePatientAccount', request)
        return response
    } catch {

    }
}

const getProfilesForAccount = async () => {
    try {
        return await httpService.post(endpoint + '/GetProfilesForAccount' )
    } catch (e) {

    }
}

const getAccounts = async () => {
    try {
        return await httpService.post(endpoint + '/GetAccounts' )
    } catch (e) {

    }
}

const getAccount = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetAccount', {id} )

    } catch (e) {

    }
}

const checkEmailAddress = async (email) => {
    try {
        return await httpService.post(endpoint + '/CheckEmailAddress', {email} )

    } catch (e) {

    }
}

const logout = (noReturnUrl = false) => {
    clearLogin();
    
    let redirect = noReturnUrl ? null : (location.pathname+location.search).substr(1);
    
    router.push(`/login?${!redirect ? '' : 'redirect=' + redirect}`);
    // 
}

const clearLogin = () => {
    try {
        if (auth.getAllAccounts().length > 0)
            auth.logout(); // logout of OAuth
    }
    catch (ex){
        // do nothing
    }

    // do single logout before deleting cookies
    try {
        //alert("trying auth source")
        var authSource = localStorage.getItem('authenticationSourceType');
        if (authSource){
            localStorage.removeItem('authenticationSourceType');
            if (authSource == "SamlSso"){
                let url = baseUrl + "/saml/InitiateSingleLogout";
                httpService.post(url).then(value => {});
            }
        }
    }
    catch (ex) {
        // do nothing
    }

    try {
        localStorage.removeItem('user');
        localStorage.removeItem('jwtToken');
    }
    catch (ex) {
        // do nothing
    }

    // retrieve all cookies
    try {
        var cookies = document.cookie.split(';');
        // set past expiry to all cookies
        for (var i = 0; i < cookies.length; i++) {
            document.cookie = cookies[i] + "=; expires="+ new Date(0).toUTCString();
        }
    }
    catch (ex) {
        // do nothing
    }

    try {
        httpService.removeAuthTokenHeader();
    }
    catch (ex) {
        // do nothing
    }
}

const getUser = () => {
    let user = localStorage.getItem('user');
   if (!user) return null
    let x = JSON.parse(user);
    return x
}

const getOrganizationId = () => {
    let userSring = localStorage.getItem('user')
    let user = JSON.parse(userSring)
    return user.selectedOrganization.id
}

const isSuperView = () => {
    let user = localStorage.getItem('user')
    let temp = JSON.parse(user)
    return temp.viewingAsSuper
}

const isEnterpriseAdminOrg = () => {
    let user = localStorage.getItem('user')
    let temp = JSON.parse(user)
    return temp.isEnterpriseAdminOrgOrg
}

const isPatient = () => {
    let user = localStorage.getItem('user')
    let temp = JSON.parse(user)
    return temp.accountTypeId === 5
}





export {
    getloginmethod,
    samllogin,
    ssologin,
    login,
    getUser,
    logout,
    clearLogin,
    getMobileToken,
    updateAccount,
    updateAccountGroups,
    getAccounts,
    accountModel,
    updateAccountModel,
    updateAccountGroupsModel,
    getAccount,
    switchSelectedOrganization,
    isSuperView,
    isEnterpriseAdminOrg,
    newAccountModel,
    createAccount,
    getOrganizationId,
    updateAccountPasswordModel,
    updatePassword,
    checkEmailAddress,
    updateAccountInStorage,
    forgotPassword,
    resetPassword,
    deleteAccount,
    requestMfaCode,
    verifyMfaCode,
    refreshToken,
    createParentAccount,
    isPatient,
    getProfilesForAccount,
    updateAccountOrganization
}