<template>
	<app-chip
		:color="value > 0 ? 'blue' : value == 0 ? 'green' : 'red' "
		label
		class="ml-2"
		small
	>
		{{ value !== 0 ? Math.abs(value) : 'today' }} {{ value > 0 ? 'days out' : value < 0 ? 'days past due' : null }}
	</app-chip>
</template>

<script>
export default {
	name: "InvoiceDaysTillChip",
	props: {
		value: {
			required: true
		}
	}
}
</script>

<style scoped>

</style>