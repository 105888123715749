import Vue from 'vue'
import Vuex from 'vuex'
import {
    SET_PRODUCT,
    SET_SNACKBAR,
    SET_PROFILE,
    SET_GROUP,
    SET_PROTOCOL,
    SET_SESSION,
    SET_USER,
    SET_ACCOUNT,
    SET_ORGANIZATION,
    SET_MOBILEAPPVERSION
} from './mutation-types'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentProduct: null,
    snackbar: {
      color: 'success',
      text: 'I am a snackbar',
      open: false
    },
    currentProfile: null,
    currentUser: null,
    currentSession: null,
    currentProtocol: null,
    currentGroup: null,
    currentAccount: null, 
    currentOrganization: null,
    latestMobileAppVersion: null
  },
  mutations: {
    [SET_PRODUCT](state, currentProduct) {
      state.currentProduct = currentProduct
    },
    [SET_ORGANIZATION](state, currentOrganization) {
      state.currentOrganization = currentOrganization
    },
    [SET_SNACKBAR](state, {color, text, open}) {
      state.snackbar = {
        color,
        text,
        open
      }
    },
    [SET_PROFILE](state, currentProfile) {
      state.currentProfile = currentProfile
    },
    [SET_GROUP](state, currentGroup) {
      state.currentGroup = currentGroup
    },
    [SET_PROTOCOL](state, currentProtocol) {
      state.currentProtocol = currentProtocol
    },
    [SET_SESSION](state, currentSession) {
      state.currentSession = currentSession
    },
    [SET_USER](state, currentUser) {
      state.currentUser = currentUser
    },
    [SET_ACCOUNT](state, currentAccount) {
      state.currentAccount = currentAccount
    },
    [SET_MOBILEAPPVERSION](state, version) {
      state.latestMobileAppVersion = version
    }
    
  },
  actions: {
  },
  modules: {
  }
})
