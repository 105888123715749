var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-card',{attrs:{"hide-header":""}},[_c('div',{},[_c('v-data-table',{staticClass:"profiles-table",attrs:{"headers":_vm.headers,"items":_vm.organizations,"items-per-page":_vm.tableSettings.rowsPerPage,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"page":_vm.tableSettings.pageNumber,"hide-default-footer":true,"show-expand":""},on:{"update:options":function($event){_vm.options=$event},"click:row":function (item, slot) { return _vm.onRowClick(item); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('table-options-row',{scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('div',{staticClass:"pa-3",staticStyle:{"width":"100%"}},[_c('v-icon',{staticClass:"ml-2 mr-2"},[_vm._v("mdi-magnify")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableSettings.searchString),expression:"tableSettings.searchString"}],staticStyle:{"width":"80%"},attrs:{"placeholder":"Search"},domProps:{"value":(_vm.tableSettings.searchString)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.tableSettings, "searchString", $event.target.value)},_vm.updateSearchString]}})],1)]},proxy:true}])})]},proxy:true},{key:"no-data",fn:function(ref){
var items = ref.items;
return [_c('div',{staticStyle:{"height":"200px","width":"200px","background-color":"red"}},[_vm._v("There are no profiles that match your search")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),(item.isResearch)?_c('app-chip',{staticClass:"ml-2",attrs:{"color":"purple","label":"","small":""}},[_vm._v(" Research ")]):_vm._e(),(item.isDemo)?_c('app-chip',{staticClass:"ml-2",attrs:{"color":"green","label":"","small":""}},[_vm._v(" Demo ")]):_vm._e(),(item.isPilot)?_c('app-chip',{staticClass:"ml-2",attrs:{"color":"deep-cyan","label":"","small":""}},[_vm._v(" Pilot ")]):_vm._e(),(item.isDisabled)?_c('app-chip',{staticClass:"ml-2",attrs:{"color":"red","label":"","small":""}},[_vm._v(" Disabled ")]):_vm._e(),(item.isInternalOrg)?_c('app-chip',{staticClass:"ml-2",attrs:{"color":"blue","label":"","small":""}},[_vm._v(" Internal ")]):_vm._e(),(item.isEnterpriseAdminOrg)?_c('app-chip',{staticClass:"ml-2",attrs:{"color":"orange","label":"","small":""}},[_vm._v(" Enterprise ")]):_vm._e()],1)]}},{key:"item.isInactive",fn:function(ref){
var item = ref.item;
return [_c('app-chip',{attrs:{"color":item.isInactive ? 'red' : 'green',"dark":""}},[_vm._v(" "+_vm._s(item.isInactive ? 'Inactive' : 'Active')+" ")])]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDisplayDate(item.createdOn))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"background-color":"#fafafa"},attrs:{"colspan":headers.length}},[_c('SuperAdminOrgTableItemExpandSection',{attrs:{"organization":item},on:{"updatedOrg":_vm.init}})],1)]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('table-footer',{attrs:{"pagination":props.pagination},on:{"previousPage":function($event){return _vm.previousPage(props)},"nextPage":function($event){return _vm.nextPage(props)},"setPage":function($event){return _vm.setPage($event)},"setItemsPerPage":function($event){return _vm.setItemsPerPage($event)}}})]}}])}),(_vm.organizations.length === 0 && !_vm.loading)?_c('div',{staticClass:"no-data"},[_vm._v(" No profiles match your search criteria ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }