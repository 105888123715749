<template>
    <div>
        <LoginFrame>
            <LoginForm
                title="Reset Password"
                message=""
                buttonText="Submit"
                @submit2=""
                @submit="onSubmit"
                v-if="!success"
                :disabled="!formIsValid"
            >
    
                <!--            <app-text-field v-model="password" type="password" label="Password" class="login-input"/>-->
                <!--            <app-text-field v-model="confirmPassword" label="Confirm Password" class="login-input" style="margin-top: 10px"/>-->
                <app-text-field
                    label="Password"
                    v-model="changePasswordForm.newPassword"
                    type="password"
                    name="new-password"
                    @focus="showPasswordCriteria = true"
                    :success="newPasswordIsValid"
                    :error="!newPasswordIsValid && submitClicked"
                    class="login-input"
                />
                <div class="pa-2" style="align-self: flex-start">
                    <div :class="[getMessageClass(newPasswordsLength)]">
                        <span v-if="newPasswordsLength">&#10003;</span><span v-else>&#10007;</span> contains 6 characters
                    </div>
                    <div :class="[getMessageClass(newPasswordHasUppercase)]">
                        <span v-if="newPasswordHasUppercase">&#10003;</span><span v-else>&#10007;</span> contains 1 uppercase letter
                    </div>
                    <div :class="[getMessageClass(newPasswordHasLowercase)]">
                        <span v-if="newPasswordHasLowercase">&#10003;</span><span v-else>&#10007;</span> contains 1 lowercase letter
                    </div>
                    <div :class="[getMessageClass(newPasswordHasDigit)]">
                        <span v-if="newPasswordHasDigit">&#10003;</span><span v-else>&#10007;</span> contains 1 digit
                    </div>
                    <div :class="[getMessageClass(newPasswordHasSpecialCharacter)]">
                        <span v-if="newPasswordHasSpecialCharacter">&#10003;</span><span v-else>&#10007;</span> contains 1 special character
                    </div>
                </div>
    
                <app-text-field
                    label="Confirm Password"
                    v-model="changePasswordForm.confirmNewPassword"
                    type="password"
                    name="confirm-password"
                    @focus="showConfirmPasswordIsSameMessage = true"
                    :success="confirmPasswordMatches"
                    :error="!confirmPasswordMatches && submitClicked"
                    class="login-input"
                />
                <div class="pa-2"  style="align-self: flex-start">
                    <div :class="[confirmPasswordMatches ? 'password-item-good' : 'password-item-bad']">
                        <span v-if="confirmPasswordMatches">&#10003;</span><span v-else>&#10007;</span> passwords match
                    </div>
                </div>
                <!--        <app-btn-->
                <!--            class="mt-4"-->
    
                <!--        >-->
                <!--            Submit Change-->
                <!--        </app-btn>-->
                <template #above-button v-if="errorResettingPassword" >
                    <div style="color: red; max-width: 300px; text-align: center; opacity: 0.8">
                        {{errorResettingPassword}}
                    </div>
                    
                </template>
    
            </LoginForm>
            <LoginForm v-show="success">
                <h1 class="reset-password-success">Success!</h1>
                <div class="reset-password-success" style="max-width: 300px; text-align: center; color: var(--v-text-lighten2); margin-top: 10px">
                    Password successfully reset.
                </div>
                <AppBtn class="reset-password-success" style="margin-top: 20px; min-width: 200px" @click="$router.push('login?redirect=Dashboard')">Login</AppBtn>
    
            </LoginForm>
        </LoginFrame>
    </div>
    
</template>

<script>
import gsap from "gsap";
import {resetPassword} from "@/services/userService";
import AppSnackbar from "@/components/AppSnackbar";
import {SET_SNACKBAR} from "@/store/mutation-types";
import LoginFrame from "@/components/Login/LoginFrame.vue";
import LoginForm from "@/components/Login/LoginForm.vue";

export default {
    name: "ResetPassword",
    components: {LoginForm, LoginFrame, AppSnackbar},
    props: {

    },
    data() {
        return {
            password: '',
            confirmPassword: '',
            showPasswordCriteria: true,
            showConfirmPasswordIsSameMessage: false,
            changePasswordForm: {
                newPassword: '',
                confirmNewPassword: ''
            },
            submitClicked: false,
            savingPassword: false,
            
            success: false,
            noValidTokenOrAid: false,
            errorResettingPassword: null
        }
    },
    methods: {
        async onSubmit() {
            // TODO:
            this.submitClicked = true
            this.savingPassword = true;
            this.errorResettingPassword = null;
            
            const {token, aid} = this.$route.query;

            if (!token || !aid ) {
                this.$store.commit(SET_SNACKBAR, {
                    text: 'There was an issue resetting your password, if this continues, please reach out to Sway Support',
                    color: 'error',
                    open: true
                })
                return this.noValidTokenOrAid = true
            }
            
            let request = {
                password: this.changePasswordForm.newPassword,
                token,
                aid
            }
            
            let response = await resetPassword(request);
                        
            if (!response.isSuccess) {
                this.changePasswordForm.newPassword = '';
                this.changePasswordForm.confirmNewPassword = '';
                this.savingPassword = false
                this.errorResettingPassword = response.errors && response.errors[0] && response.errors[0].message
                        ? response.errors[0].message
                        : "There was an issue resetting your password, if this continues, please reach out to Sway Support"
                return;
            }
            
            this.success = true;
            gsap.from('.reset-password-success', {
                y: 20,
                opacity: 0,
                stagger: 0.1,
                duration: 0.5
            })
        },
        getMessageClass(valid) {
            if (valid) {
                return 'password-item-good'
            }
            else if (!valid && !this.submitClicked) {
                return 'password-item-bad'
            } else {
                return 'password-item-bad-error'
            }
        },
    },
    computed: {
        newPasswordsLength() {
            const {newPassword} = this.changePasswordForm
            return newPassword.length > 5
        },
        newPasswordHasUppercase() {
            const {newPassword} = this.changePasswordForm
            return newPassword.match(/(?=.*[A-Z])/)
        },
        newPasswordHasLowercase() {
            const {newPassword} = this.changePasswordForm
            return newPassword.match(/(?=.*[a-z])/)
        },
        newPasswordHasDigit() {
            const {newPassword} = this.changePasswordForm;
            let temp = newPassword.match(/(?=.*\d)/);
            return newPassword.match(/(?=.*\d)/)

        },
        newPasswordHasSpecialCharacter() {
            const {newPassword} = this.changePasswordForm;
            let temp = newPassword.match(/(?=.*\W)/);
            return newPassword.match(/(?=.*\W)/)

        },
        confirmPasswordMatches() {
            const {newPassword, confirmNewPassword} = this.changePasswordForm;
            return confirmNewPassword.length > 0 && newPassword === confirmNewPassword

        },
        newPasswordIsValid() {
            return this.newPasswordsLength && this.newPasswordHasUppercase && this.newPasswordHasLowercase && this.newPasswordHasDigit && this.newPasswordHasSpecialCharacter
        },
        formIsValid() {
            return this.newPasswordIsValid && this.confirmPasswordMatches
        },
        changePasswordButtonEnabled() {
            const {

                newPassword,
                confirmNewPassword
            } = this.changePasswordForm;

            return !!newPassword

        },
    }
}
</script>

<style scoped>
.password-item-good {
    color: #4caf50;
    font-weight: bold
}

.password-item-bad {
    color: var(--v-text-lighten3)
}

.password-item-bad-error {
    color: var(--v-error-base)
}
</style>