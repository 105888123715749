<template>
    <div style="margin-top: 1em; margin-bottom: 1em; margin-left: 0; margin-right: 0; width: 890px">
        <div class="pdf-wrapper" id="test-pdf">
            <div class="pdf-content">
                <ProfileHeader :profile="profile" :profile-test="profileTest" :enableSportsProfileProperties="enableSportsProfileProperties" />
                <div v-if="!profile.hasAtLeastOneNonCovid" style="margin-top: 30px; font-size: 16px">
                    This profile has only taken COVID-19 screenings. PDF reports do not include COVID-19 screening results. 
                </div>
                <div v-else-if="profileTest.isCovidOnly" style="margin-top: 30px; font-size: 16px">
                    The selected test for this PDF report appears to be a COVID only test. PDF reports do not include COVID-19 screening results.
                </div>
                
                <div v-else>
                    <div v-if="profileTest.metadata">
                        <TestMetaDataHeader :metadata="profileTest.metadata"/>
                    </div>
                    
                    <div style="display: flex; align-items: center; margin-right: 15px">
                        <div class="pdf-section-title" style="flex: 1">Test Summary</div>
                        <div style="flex: 1; text-align: center; align-items: center; display: flex; flex-direction: column; justify-content: center">
	                        <div style="font-weight: bold">
		                        {{profileTest.data.datetime}}
	                        </div>
                            <div v-if="profileTest.data.administeredAccountEmail">Administered by: {{profileTest.data.administeredAccountEmail}}</div>
                        </div>
                        <div style="flex: 1; display: flex; justify-content: flex-end; align-items: center">
                            <TestTypeDot
                                :is-event="profileTest.data.isEvent"
                                :has-duplicate-tests="profileTest.data.hasDuplicateTests"
                                :is-dot-excluded="profileTest.data.isExcluded"
                                style="margin-right: 5px"
                                :is-non-baseline="profileTest.data.isNonBaseline"
                            />
                            <div>
                                {{profileTest.data.organizationProtocolName ? profileTest.data.organizationProtocolName : 'Custom'}}
                            </div>
                        </div>

                    </div>


                    <SectionWrapper title-only title="Notes" v-if="profileTest.data.note">
                        {{profileTest.data.note}}
                    </SectionWrapper>


                    <SymptomsSummarySection v-if="profileTest.formattedTestsData[9999]" :profileTest="profileTest" />
                    
                    

                    <SectionWrapper
                        v-for="(category, index) in measureResultsByCategory"
                        :key="index" :title="category.fullLabel ? category.fullLabel : category.label"
                        v-if="measuresCategoriesById[category.categoryId].hasChartableBaselines"
                        class="pdf-avoid-page-break"
                    >

                        <div>
                            <CategoryTestsSummary
                                :profileTest="profileTest"
                                :category="category"
                            />
                        </div>

                    </SectionWrapper>

                    <BaselineExplanationSection v-if="showBaselineExplanationSection" />

                    <!--                <div v-if="profileTest.formattedTestsData['9999']">-->
                    <!--                    <SymptomsSummarySection :profileTest="profileTest" />-->
                    <!--                </div>-->
                    <div v-if="profileTest.formattedTestsData['1013'] || profileTest.formattedTestsData['1018'] || profileTest.formattedTestsData['1027']">
                        <OnFieldAssessmentPdfSection :profileTest="profileTest" />
                    </div>
	
	                <div v-if="profileTest.formattedTestsData['1020']">
		                <VomsSummarySection :profileTest="profileTest" />
	                </div>
	                
	                <div class="pdf-avoid-page-break" v-if="event">
		                <div class="pdf-section-title" style="flex: 1">Event Summary</div>
		                <EventSection :event="event" />
	                </div>
	                
                    <SectionWrapper title="Surveys" title-only v-if="profileTest.formattedTestsData['1016'] || profileTest.formattedTestsData['1017']">
                        <div v-if="profileTest.formattedTestsData['1016']">
                            <div style="font-weight: bold ">PHQ-9</div>
                            <div class="divider"/>
                            Total Score: {{profileTest.formattedTestsData['1016'].score}}
                            <SurveyResultsTable :table="PHQ9Table" :score="profileTest.formattedTestsData['1016'].score" />
                        </div>
                        <div v-if="profileTest.formattedTestsData['1017']">
                            <div style="font-weight: bold">GAD-7</div>
                            <div class="divider"/>
                            Total Score: {{profileTest.formattedTestsData['1017'].score}}
                            <SurveyResultsTable :table="GAD7Table" :score="profileTest.formattedTestsData['1017'].score" />
                        </div>
                    </SectionWrapper>
                    
                    

                    <TestDetailsSection :profileTest="profileTest" :measureResultsByCategory="measureResultsByCategory"/>

                    <!--                <div style="width: 100%; height: 2px; background-color: #F4F4F4"></div>-->



                    <!--                <div class="section-title">Test Details</div>-->

                    <!--                <Symptoms :profileTest="profileTest" v-if="profileTest.hasTestTypes.symptoms"/>-->

                    <div v-if="profile && profile.tests.length > 1">

                        <ProfileTestHistoryTable :profile="profile"/>

                        <ProfileTrends :profile="profile" style="margin-top: 20px"/>

                        <!--                    <div class="pdf-section-title" >Baseline Sessions Details</div>-->
                    </div>
                </div>
                
                <div style="margin-top: 100px; " class="pdf-avoid-page-break">
                    <div v-if="profileTest.metadata" style="margin-bottom: 20px">
                        <TestMetadataDetails :metadata="profileTest.metadata"/>
                    </div>
                    <div style="margin-bottom: 20px">
                        For information on research, test interpretation, billing, and product training, please go to <a href="https://www.swaymedical.com/" style="color: #2196f3">swaymedical.com</a>.
                    </div>
                    <div style="color: #757575; font-size: 10px">
                        <span style="font-weight: bold">Disclaimer: </span>Sway Medical, Inc. does not warrant the accuracy or completeness of any information or data described or contained on the platform.  Normative data is representative of baseline data collected from Sway Medical Inc. users of various age and sex.  Normative values are intended for reference purposes only and should not be the determining factor in clinical decisions.  Sway Medical, Inc., nor any of any of its respective officers, directors, employees or representatives shall be liable for any direct, indirect, incidental, special, exemplary, or consequential damages resulting from, related to, or arising out of any use of or reliance upon the information or data described or contained on the platform and assumes no responsibility for anyone's use of or reliance upon such information. This disclaimer of liability applies to any damages or injury, whether based on alleged breach of contract, tortious behavior, negligence, or any other cause of action.
                    </div>
                   
                </div>
                
<!--                <SectionWrapper title="Symptoms" v-if="profileTest.hasTestTypes.symptoms">-->
                    
<!--                </SectionWrapper>-->
                
                

                
                
                

            </div>

        </div>

    </div>


</template>

<script>

import PageHeader from "./PageHeader";
import ProfileHeader from "./ProfileHeader/index";
import CognitiveTests from "./CognitiveTests";
import MemoryTests from "@/components/pdf-export/MemoryTests";
import SectionWrapper from "@/components/pdf-export/SectionWrapper";
import BalanceTests from "@/components/pdf-export/BalanceTests";
import BaselineExplanationSection from "@/components/pdf-export/BaselineExplanationSection";
import CategoryTestsSummary from "@/components/pdf-export/CategoryTestsSummary";
import SymptomsSummarySection from "@/components/pdf-export/SymptomsSummarySection";
import CategoryTestsDetails from "@/components/pdf-export/CategoryTestsDetails";
import ProfileTestHistoryTable from "@/components/pdf-export/ProfileTestHistoryTable";
import ProfileTrends from "@/components/pdf-export/ProfileTrends";
import {GAD7Table, measuresCategoriesById, PHQ9Table} from "@/js/shared/constants";
import OnFieldAssessmentPdfSection from "@/components/pdf-export/OnFieldAssessmentPdfSection";
import TestTypeDot from "@/components/TestTypeDot";
import TestDetailsSection from "@/components/pdf-export/TestDetailsSection";
import TestTypeDotExplanation from "@/components/pdf-export/components/TestTypeDotExplanation";
import TestMetaDataHeader from "@/components/pdf-export/components/TestMetaDataHeader";
import TestMetadataDetails from "@/components/pdf-export/components/TestMetadataDetails";
import SurveyResultsTable from "@/components/Profile/TestsViewer/Surveys/SurveyResultsTable";
import EventSection from "@/components/pdf-export/components/EventSection";
import VomsSummarySection from "@/components/pdf-export/components/VomsSummarySection";

export default {
    name: "PDF-Export",
    props: {
        // simpleProfile: {
        //     required: true
        // },
        profileTest: {
            required: true
        },
        isFullpage: {
            default: false
        },
        profile: {
            
        },
        enableSportsProfileProperties: {
            default: true
        }
    },
    data() {
        return {
            measureResultsByCategory: [],
            measuresCategoriesById,
            PHQ9Table: PHQ9Table,
            GAD7Table: GAD7Table
        }
    },
    components: {
	    VomsSummarySection,
	    EventSection,
        SurveyResultsTable,
        TestMetadataDetails,
        TestMetaDataHeader,
        TestTypeDotExplanation,
        TestDetailsSection,
        TestTypeDot,
        OnFieldAssessmentPdfSection,
        ProfileTrends,
        ProfileTestHistoryTable,
        CategoryTestsDetails,
        SymptomsSummarySection,
        CategoryTestsSummary,
        BaselineExplanationSection,
        PageHeader,
        ProfileHeader,
        CognitiveTests,
        MemoryTests,
        SectionWrapper,
        BalanceTests
    },
    watch: {
        profileTest() {
            this.setMeasureResultsByCategory()

        }
    },
    mounted() {
        if (this.isFullpage){
            let head = document.head;
            let link = document.createElement("link");

            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = `${window.location.origin}/pdf.css`;

            head.appendChild(link)
        }
        
        this.setMeasureResultsByCategory()
    },
    
    computed: {
        // profileTest() {
        //     return this.profile.tests[0]
        // },
        // measureResultsByCategory() {
        //     let response = this.profileTest.getMeasureResultsByCategory();
        //     
        //     return response
        // },
        
        cognitiveTestsTaken() {
            if (!this.profileTest) return null

            return this.profileTest.wereCategoryMeasuresTaken("cognitive")

        },
	    event() {
			let self = this;
			
			if (!this.profileTest.data.eventId) return null
			
		    return this.profile.events.find(x => x.id === this.profileTest.data.eventId)
	    },
	    showBaselineExplanationSection() {
			// If the test does not include a balance, cognitive, or functional test, do not show
		    // the explanation section
			return this.profileTest.hasTestTypes.balance || this.profileTest.hasTestTypes.cognitive || this.profileTest.hasTestTypes.functional
	    }
    },
    methods: {
        setMeasureResultsByCategory() {
            this.measureResultsByCategory = this.profileTest.getMeasureResultsByCategory();
        },
    }
}
</script>

<style>


#test-pdf {
    color: #414D55
}

.divider {
    width: 100%; height: 1px; background-color: #e0e0e0; margin: 5px 0
}
</style>