<template>
    <div>
        <validation-provider
            v-slot="{ errors, valid }"
            :name="errorLabel ? errorLabel : name"
            :rules="rules"
        >
            <div>
                <span style="color: #757575">{{label}}</span> <span v-if="errors" class="error--text text-caption">{{errors[0]}}</span>
            </div>


            <v-autocomplete
                :value="value"
                :items="items"
                :multiple="multiple"
                
                outlined
                dense
                color="text"
                @input="update"
                @update:search-input="updateString"
                :item-text="itemText"
                :item-value="itemValue"
                :placeholder="placeholder"
                :no-data-text="noDataText"
                :deletable-chips="deletableChips"
                :error-messages="errors.length > 0 ? [''] : []"
                :disabled="disabled"
            >
                <template #selection="data">
                    {{data.item.fullName}}
                </template>
            </v-autocomplete>


        </validation-provider>
    </div>
<!--    <v-row>-->
<!--        <v-col cols="3" style="display: flex; align-items: center">-->
<!--            {{ label }}-->
<!--        </v-col>-->
<!--        <v-col cols="9">-->
<!--            <validation-provider-->
<!--                v-slot="{ errors }"-->
<!--                :name="errorLabel ? errorLabel : label"-->
<!--                :rules="rules"-->
<!--            >-->
<!--                <v-autocomplete-->
<!--                    :value="value"-->
<!--                    :items="items"-->
<!--                    :multiple="multiple"-->
<!--                    :chips="chips"-->
<!--                    outlined-->
<!--                    color="text"-->
<!--                    @input="update"-->
<!--                    @update:search-input="updateString"-->
<!--                    :item-text="itemText"-->
<!--                    :item-value="itemValue"-->
<!--                    :placeholder="placeholder"-->
<!--                    :no-data-text="noDataText"-->
<!--                    :deletable-chips="deletableChips"-->
<!--                    :error-messages="errors.length > 0 ? [''] : []"-->
<!--                ></v-autocomplete>-->
<!--                <div v-if="errors" class="error&#45;&#45;text text-caption">{{errors[0]}}</div>-->
<!--            </validation-provider>-->
<!--        </v-col>-->
<!--    </v-row>-->
</template>

<script>
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {required} from "vee-validate/dist/rules";

setInteractionMode('eager')

extend('required', {
    ...required,
    // message: '{_field_} can not be empty',
    message: 'required',
})

export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },
    props: {
        value: {
            required: true,
            type: Array
        },
        label: {
            required: true,
            type: String
        },
        items: {
            required: true,
            type: Array
        },
        itemText: {
            type: String
        },
        itemValue: {
            type: String
        },
        multiple: {
            type: Boolean,
        },
        chips: {
            type: Boolean
        },
        placeholder: {
            type: String
        },
        noDataText: {
            type: String
        },
        deletableChips: {
            type: Boolean,
            default: true
        },
        rules: {

        },
        errorLabel: {
            type: String
        },
        disabled: {
            type: Boolean
        },
        name: {
            
        }
    },
    name: "AppAutocomplete",
    methods: {
        update(e) {
            this.$emit('input', e)
        },
        updateString(e) {
            this.$emit('update-search-input', e)
        }
    }
}
</script>

<style scoped>

</style>