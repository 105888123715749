<template>
    <div v-if="covidGroupPreference && covidNotificationPreferences">
        <app-card hide-header>
            <v-simple-table>
                <template>
                    <thead>
                    <tr >
                        <th :rowspan="2" class="text-center border-bottom">
                            Name
                        </th>
<!--                        <th :rowspan="2" class="text-center border-bottom">-->
<!--                            Last Name-->
<!--                        </th>-->
                        <th :colspan="2" class="text-center border-bottom">
                            COVID
                            <app-btn v-if="isCovidDisabled" x-small class="ml-2" @click="$router.push(`/groups/${group.id}/schedule`)">Enable</app-btn>
                        </th>
<!--                        <th :colspan="2" class="text-center border-bottom">-->
<!--                            Cognitive-->
<!--                            <app-btn v-if="isCognitiveDisabled" x-small class="ml-2" @click="$router.push(`/groups/${group.id}/schedule?editSchedule=true&demandTypeId=0`)">Enable</app-btn>-->
<!--                        </th>-->
                        

                    </tr>
                    <tr>
                        <th  class="text-center border-bottom">
                            Email
                        </th>
                        <th  class="text-center border-bottom">
                            SMS
                        </th>
<!--                        <th :class="{disabled: isCognitiveDisabled}" class="text-center">-->
<!--                            SMS-->
<!--                        </th>-->
<!--                        <th :class="{disabled: isCognitiveDisabled}" class="text-center">-->
<!--                            Email-->
<!--                        </th>-->
                    </tr>
                    

                    </thead>
                    <tbody>
                    <tr v-for="(user, index) in covidNotificationPreferences" :key="index" class="text-center">
                        <td >
                            {{user.name}}
                        </td>
<!--                        <td>-->
<!--                            {{user.lastName}}-->
<!--                        </td>-->
                        
                        <td :class="{disabled: isCovidDisabled}">
                            <div class="checkbox-container">
                            <v-checkbox 
                                dense 
                                v-model="user.emailNotification"
                                @change="updateGroupNotificationPreferences(user)"
                                :disabled="isCovidDisabled"
                            ></v-checkbox>
                            </div>
                        </td>
                        <td :class="{disabled: isCovidDisabled}" >
                            <div class="checkbox-container">
                                <v-checkbox
                                    dense
                                    v-model="user.smsNotification"
                                    @change="updateGroupNotificationPreferences(user)"
                                    :disabled="isCovidDisabled || !user.phoneNumber"
                                    class="text-center"
                                ></v-checkbox>
                            </div>

                        </td>
<!--                        <td :class="{disabled: isCognitiveDisabled}">-->
<!--                            <div class="checkbox-container">-->
<!--                            <v-checkbox -->
<!--                                dense -->
<!--                                v-model="user.cognitiveNotificationPreferences.smsNotification"-->
<!--                                @change="updateGroupNotificationPreferences(user.cognitiveNotificationPreferences)"-->
<!--                                :disabled="isCognitiveDisabled"-->
<!--                            ></v-checkbox>-->
<!--                            </div>-->
<!--                        </td>-->
<!--                        <td :class="{disabled: isCognitiveDisabled}">-->
<!--                            <div class="checkbox-container">-->
<!--                            <v-checkbox -->
<!--                                dense -->
<!--                                v-model="user.cognitiveNotificationPreferences.emailNotification"-->
<!--                                @change="updateGroupNotificationPreferences(user.cognitiveNotificationPreferences)"-->
<!--                                :disabled="isCognitiveDisabled"-->
<!--                                -->
<!--                            ></v-checkbox>-->
<!--                            </div>-->
<!--                        </td>-->
                        <!--                            <td>-->
                        <!--                                <v-checkbox v-model="temp" color="secondary"></v-checkbox>-->
                        <!--                            </td>-->
                        <!--                            <td>-->
                        <!--                                <v-checkbox v-model="temp" color="secondary"></v-checkbox>-->
                        <!--                            </td>-->
                        
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </app-card>
    </div>
    <div v-else>
        <EmptyState :svg="emptyScheduleGraphic" 
                    title="No Schedules"  
                    description="Looks like this group does not have any schedules. Create a schedule before setting up notification preferences." 
                    button-text="Create Schedule"
                    @buttonClick="$router.push(`/groups/${group.id}/schedule`)"
                    button
        />
    </div>
</template>

<script>
import {updateGroupNotificationPreferences} from '@/services/groups.service'
import EmptyState from "@/components/EmptyState";
import emptyScheduleGraphic from '@/assets/svgs/empty-state/empty-group-schedule.svg'
import {SET_SNACKBAR} from "@/store/mutation-types";

export default {
    name: "GroupNotifications",
    components: {EmptyState},
    props: {
        users: {
            type: Array,
            required: true
        },
        covidNotificationPreferences: {
            type: Array
        },
        // cognitiveNotificationPreferences: {
        //     type: Array
        // },
        groupPreferences: {
            
        },
        covidGroupPreference: {
            required: true
        },
        // cognitiveGroupPreference: {
        //     required: true  
        // },
        group: {
            required: true
        }
    },
    data() {
        return {
            emptyScheduleGraphic
        }
    },
    computed: {
        formattedUsers() {
             let formattedUsers = this.users.map(user => {
                user.covidNotificationPreferences = null;
                // user.cognitiveNotificationPreferences = null;
                
                if (this.covidNotificationPreferences) {
                    user.covidNotificationPreferences = this.covidNotificationPreferences.find(item => item.accountId === user.id)
                }

                // if (this.cognitiveNotificationPreferences) {
                //     user.cognitiveNotificationPreferences = this.cognitiveNotificationPreferences.find(item => item.accountId === user.id)
                // }
                
                return user
            });
             
             return formattedUsers
        },
        showCovid() {
            return this.covidNotificationPreferences && this.covidNotificationPreferences?.isEnabled
        },
        showCognitive() {
            
        },
        isCovidDisabled() {

            return !this.covidGroupPreference?.reminderPushNotificationEnabled
        },
        // isCognitiveDisabled() {
        //     return !this.cognitiveGroupPreference.reminderPushNotificationEnabled
        // }
    },
    methods: {
        async updateGroupNotificationPreferences(preferencesObject) {

            const request = {
                groupId: this.group.id,
                groupNotificationPreferences: [preferencesObject]
            };
            
            
            const response = await updateGroupNotificationPreferences(request);
            
            if (!response.isSuccess) {
                return this.$store.commit(SET_SNACKBAR, {
                    text: `There was an error updating the notification preferences.`,
                    color: 'error',
                    open: true
                });
            }
            return this.$store.commit(SET_SNACKBAR, {
                text: `Successfully updated notification preferences.`,
                color: 'success',
                open: true
            });
            

        }
    }
}
</script>

<style scoped>
.disabled {
    background-color: var(--v-light-base);
}

.border-bottom {
    border-bottom: 1px solid var(--v-border-base)
}

.checkbox-container {
    display: flex; 
    justify-content: center; 
    align-items: center
}

</style>