<template>
	<div>
		<app-card hide-header class="pa-4">
			<h2>Settings</h2>
			<div style="display: flex">
				<div style="max-width: 300px" class="mr-4">
					<app-select
						label="Organization Business Type"
						item-title="label"
						item-value="value"
						item-text="label"
						v-model="selectedOrganizationBusinessType"
						:items="organizationBusinessTypeOptions"
					/>
					
				</div>
				<div>
					<app-text-field label="Offset" v-model="offset"></app-text-field>
				</div>
				
				<v-spacer />
				<app-btn @click="start()" :disabled="!selectedOrganizationBusinessType">Start</app-btn>
			</div>
			
			
			
		</app-card>
		<div style="display: flex;" v-if="hasStarted && !isLoading">
			<div style="flex: 1" class="pa-4">
				<h2>Hubspot Company</h2>
				<div>
					<app-card hide-header class="pa-4 mt-4">
						<div style="display: flex; flex-direction: column">
							<div v-for="(field, fieldIndex) in currentCompanyFields" :key="fieldIndex" >
								<div style="display:flex" ><div style="color: #757575; width: 120px">{{field.label}}:</div> {{currentCompany[field.value]}} </div>
							</div>
                            <div style="display:flex" v-if="currentCompany['swayOrganizationId']"><div style="color: #757575; width: 120px"></div>Sway Org: {{currentCompany['swayOrganizationId']}} <a target="_blank" :href="'https://portal.swaymedical.com/dashboard?orgId=' + currentCompany['swayOrganizationId']">Portal Link</a></div>
                            <div style="display:flex" ><div style="color: #757575; width: 120px"></div><a target="_blank" :href="'https://app.hubspot.com/contacts/9414515/company/' + currentCompany['id']">GO TO Hubspot Link</a></div>
						</div>
						<v-checkbox label="Change to district" v-model="changeHubspotCompanyToSchoolDistrict" />
					</app-card>
				</div>
				<div class="mt-4">
<!--					<app-btn class="mr-2" @click="currentCompanyIndex&#45;&#45;" :disabled="currentCompanyIndex < 1">Back</app-btn>-->
					<app-btn class="mr-2" @click="skipSchool">Skip</app-btn>
					<app-btn :disabled="selectedMatchIndex == null" @click="matchSchool" color="secondary">Match</app-btn>
				</div>
				<div class="mt-2">
					<div>Current Hubspot Company Index: {{currentCompanyIndex}}</div>
					<div>Current Hubspot Company Batch Size: {{hubspotCompanies.length}}</div>
				</div>
				
				
			</div>
			<div style="flex: 1" class="pa-4">
				<h2>Potential Matches</h2>
				<div v-if="loadingSchools">
					LOADING
				</div>
				<div v-else-if="filteringSchools">
					Matching Schools...
				</div>
				<div v-else-if="potentialSchools.length > 0" v-for="(item, index) in potentialSchools" :key="index">
					<div class="potential-match pa-4 mt-4" :class="{'match-selected': index === selectedMatchIndex}" @click="selectedMatchIndex === index ? selectedMatchIndex = null : selectedMatchIndex = index" >
						<div style="display: flex; flex-direction: column">
							<div v-for="(field, fieldIndex) in compareSchoolFields" :key="fieldIndex" >
								<div style="display:flex" ><div style="color: #757575; width: 120px">{{field.label}}:</div> {{item[field.value]}} </div>
							</div>
							
							
						</div>
					</div>
				</div>
				<div v-else>
					No schools or districts match the state of the current company
				</div>
				
				
			</div>
			
		</div>
        <div v-else-if="isLoading">
            <AppPageLoader text="Loading Hubspot data for you, G..." />
        </div>
	</div>
</template>

<script>

import * as stringSimilarity from "string-similarity"
import {getHubspotCompaniesWithoutNces, updateHubspotCompanyModel} from "@/services/superAdmin.service";
import {updateBillingInfo} from "@/services/billing.service";
import {SET_SNACKBAR} from "@/store/mutation-types";
import axios from "axios";

export default {
	name: "NcesIdTool",
	components: {},
	
	data() {
		return {
			hasStarted: false,
			selectedOrganizationBusinessType: "High School",
			offset: 0,
			loadingSchools: false,
			filteringSchools: false,
			currentCompanyIndex: 0,
            isLoading: false,
            publicHighSchoolsJson: null,
            privateSchoolsJson: null,
            postSecondarySchoolsJson: null,
            schoolDistrictsJson: null,
			changeHubspotCompanyToSchoolDistrict: false,
			hubspotCompanies: [],
	
			// allSchools: [],
			// potentialSchools: [],
			selectedMatchIndex: null,

			
			
			organizationBusinessTypeOptions: [
				{
					label: 'High Schools',
					value: 'High School'
				},
				// {
				// 	label: 'Middle Schools',
				// 	value: 'Middle School'
				// },
				{
					label: 'School Districts',
					value: 'School District'
				},
				// {
				// 	label: 'Private Schools',
				// 	value: 'Private School'
				// },
				{
					label: 'College or University',
					value: 'College or University'
				},
				
			],
			
			propertyMaps: {
				publicSchools: {
					name: "SCH_NAME",
					address: "LSTREET1",
					city: "LCITY",
					state: "LSTATE",
					zip: "LZIP",
					ncesId: "NCESSCH"
				},
				privateSchools: {
					name: "NAME",
					address: "STREET",
					city: "CITY",
					state: "STATE",
					zip: "ZIP",
					ncesId: "PPIN"
				},
				postSecondarySchools: {
					name: "NAME",
					address: "STREET",
					city: "CITY",
					state: "STATE",
					zip: "ZIP",
					ncesId: "UNITID"
				},
				schoolDistricts: {
					name: "LEA_NAME",
					address: "LSTREET1",
					city: "LCITY",
					state: "LSTATE",
					zip: "LZIP",
					ncesId: "LEAID"
				},
				
			},
			
			currentCompanyFields: [
				{
					label: 'Name',
					value: "name",
				},
				{
					label: 'Address',
					value: "address",
				},
				{
					label: 'City',
					value: "city",
				},
				{
					label: 'State',
					value: "state",
				},
				{
					label: 'Offset ID',
					value: "id",
				},
				{
					label: 'Type',
					value: "organizationBusinessType",
				},
			],
			
			compareSchoolFields: [
				{
					label: 'Name',
					value: "name",
				},
				{
					label: 'Address',
					value: "address",
				},
				{
					label: 'City',
					value: "city",
				},
				{
					label: 'State',
					value: "state",
				},
				{
					label: 'NCES ID',
					value: "id",
				},
				{
					label: 'Type',
					value: "type",
				},
				{
					label: 'Match Rating',
					value: "matchRating",
				},
			],
			stateAbbreviations: [
				{
					"name": "Alabama",
					"abbreviation": "AL"
				},
				{
					"name": "Alaska",
					"abbreviation": "AK"
				},
				{
					"name": "American Samoa",
					"abbreviation": "AS"
				},
				{
					"name": "Arizona",
					"abbreviation": "AZ"
				},
				{
					"name": "Arkansas",
					"abbreviation": "AR"
				},
				{
					"name": "California",
					"abbreviation": "CA"
				},
				{
					"name": "Colorado",
					"abbreviation": "CO"
				},
				{
					"name": "Connecticut",
					"abbreviation": "CT"
				},
				{
					"name": "Delaware",
					"abbreviation": "DE"
				},
				{
					"name": "District Of Columbia",
					"abbreviation": "DC"
				},
				{
					"name": "Federated States Of Micronesia",
					"abbreviation": "FM"
				},
				{
					"name": "Florida",
					"abbreviation": "FL"
				},
				{
					"name": "Georgia",
					"abbreviation": "GA"
				},
				{
					"name": "Guam",
					"abbreviation": "GU"
				},
				{
					"name": "Hawaii",
					"abbreviation": "HI"
				},
				{
					"name": "Idaho",
					"abbreviation": "ID"
				},
				{
					"name": "Illinois",
					"abbreviation": "IL"
				},
				{
					"name": "Indiana",
					"abbreviation": "IN"
				},
				{
					"name": "Iowa",
					"abbreviation": "IA"
				},
				{
					"name": "Kansas",
					"abbreviation": "KS"
				},
				{
					"name": "Kentucky",
					"abbreviation": "KY"
				},
				{
					"name": "Louisiana",
					"abbreviation": "LA"
				},
				{
					"name": "Maine",
					"abbreviation": "ME"
				},
				{
					"name": "Marshall Islands",
					"abbreviation": "MH"
				},
				{
					"name": "Maryland",
					"abbreviation": "MD"
				},
				{
					"name": "Massachusetts",
					"abbreviation": "MA"
				},
				{
					"name": "Michigan",
					"abbreviation": "MI"
				},
				{
					"name": "Minnesota",
					"abbreviation": "MN"
				},
				{
					"name": "Mississippi",
					"abbreviation": "MS"
				},
				{
					"name": "Missouri",
					"abbreviation": "MO"
				},
				{
					"name": "Montana",
					"abbreviation": "MT"
				},
				{
					"name": "Nebraska",
					"abbreviation": "NE"
				},
				{
					"name": "Nevada",
					"abbreviation": "NV"
				},
				{
					"name": "New Hampshire",
					"abbreviation": "NH"
				},
				{
					"name": "New Jersey",
					"abbreviation": "NJ"
				},
				{
					"name": "New Mexico",
					"abbreviation": "NM"
				},
				{
					"name": "New York",
					"abbreviation": "NY"
				},
				{
					"name": "North Carolina",
					"abbreviation": "NC"
				},
				{
					"name": "North Dakota",
					"abbreviation": "ND"
				},
				{
					"name": "Northern Mariana Islands",
					"abbreviation": "MP"
				},
				{
					"name": "Ohio",
					"abbreviation": "OH"
				},
				{
					"name": "Oklahoma",
					"abbreviation": "OK"
				},
				{
					"name": "Oregon",
					"abbreviation": "OR"
				},
				{
					"name": "Palau",
					"abbreviation": "PW"
				},
				{
					"name": "Pennsylvania",
					"abbreviation": "PA"
				},
				{
					"name": "Puerto Rico",
					"abbreviation": "PR"
				},
				{
					"name": "Rhode Island",
					"abbreviation": "RI"
				},
				{
					"name": "South Carolina",
					"abbreviation": "SC"
				},
				{
					"name": "South Dakota",
					"abbreviation": "SD"
				},
				{
					"name": "Tennessee",
					"abbreviation": "TN"
				},
				{
					"name": "Texas",
					"abbreviation": "TX"
				},
				{
					"name": "Utah",
					"abbreviation": "UT"
				},
				{
					"name": "Vermont",
					"abbreviation": "VT"
				},
				{
					"name": "Virgin Islands",
					"abbreviation": "VI"
				},
				{
					"name": "Virginia",
					"abbreviation": "VA"
				},
				{
					"name": "Washington",
					"abbreviation": "WA"
				},
				{
					"name": "West Virginia",
					"abbreviation": "WV"
				},
				{
					"name": "Wisconsin",
					"abbreviation": "WI"
				},
				{
					"name": "Wyoming",
					"abbreviation": "WY"
				}
			],
			
			highSchoolString: "High School",
			schoolDistrictString: "School District",
			postSecondarySchoolString: "College or University",
			privateSchoolString: "Private School"
		}
	},
    async created(){
        //this.json = await import("./assets/");

        axios.get('/api/Admin/PostSecondarySchoolsJson').then(res =>
            {
                this.postSecondarySchoolsJson = res;
            });

        axios.get('/api/Admin/PublicHighSchoolsJson').then(res =>
        {
            this.publicHighSchoolsJson = res;
            console.log(this.publicHighSchoolsJson)
        });

        axios.get('/api/Admin/PrivateSchoolsJson').then(res =>
        {
            this.privateSchoolsJson = res;
        });

        axios.get('/api/Admin/PublicSchoolDistrictsJson').then(res =>
        {
            this.schoolDistrictsJson = res;
        });
        
        //do this if you want it to be bundled and loaded
        // this.publicHighSchoolsJson = await import('../../assets/jsons/NcesData/public-high-schools.json').then(module=>{
        //     return module.default;
        // });
        //
        // this.privateSchoolsJson = await import('../../assets/jsons/NcesData/private-schools.json').then(module=>{
        //     return module.default;
        // });
        //
        // this.postSecondarySchoolsJson= await import('../../assets/jsons/NcesData/post-secondary-schools.json').then(module=>{
        //     return module.default;
        // });
        //
        // this.schoolDistrictsJson= await import('../../assets/jsons/NcesData/public-school-districts.json').then(module=>{
        //     return module.default;
        // });
    },
	async mounted() {

      
	},
	methods: {

		async start() {
			
			this.hasStarted = true;
			await this.getHubspotCompanies()
			// await this.getAllSchools()
			// await this.getPotentialSchools()
			
			
		},
		async getHubspotCompanies() {
            
            this.isLoading = true;

				const {
					highSchoolString,
					schoolDistrictString,
					postSecondarySchoolString,
					privateSchoolString
				} = this;
				
				let request = {
					offset: parseInt(this.offset),
					businessType: null
				}
				
				switch (this.selectedOrganizationBusinessType) {
					case schoolDistrictString:
						request.businessType = schoolDistrictString
						break;
					case postSecondarySchoolString:
						request.businessType = postSecondarySchoolString
						break;
					case privateSchoolString:
						request.businessType = privateSchoolString
						break;
					default:
						request.businessType = highSchoolString
				}
				
				//TODO: Make This request
			
				let response = await getHubspotCompaniesWithoutNces(request)
			
				if (response.isSuccess) {
					// this.$store.commit(SET_SNACKBAR, {
					// 	text: 'Successfully Updated Hubspot Company',
					// 	color: 'success',
					// 	open: true
					// });

					this.hubspotCompanies = response.companies

				} else {
					this.$store.commit(SET_SNACKBAR, {
						text: 'There was an issue trying to update the hubspot companies',
						color: 'error',
						open: true
					});
				}
                
                this.isLoading = false;
			
				return 
			
			
		},
		
		getPotentialSchools() {
			this.filteringSchools = true

			let self = this;
			
			const {allSchools} = this;
			let potentialSchools = allSchools.filter(x => {
				
				// hopefully the state in hubspot in the two letter abbreviation and this first options gets hit.
				if (x.state === this.currentCompany.state) return true
					
				// If state string is more than two characters, try to match state to abbreviation
				let state = this.stateAbbreviations.find(x => x.name.toLowerCase() === this.currentCompany.state.toLowerCase().trim())
				return state?.abbreviation === x.state
			})
			
			potentialSchools.forEach(x => {
				x.matchRating = stringSimilarity.compareTwoStrings(x.name.toLowerCase(), self.currentCompany.name.toLowerCase())
			})
			
			potentialSchools.sort((a,b) => {
				return a.matchRating > b.matchRating ? -1 : 1
			})
			
			self.potentialSchools = potentialSchools
			this.filteringSchools = false
			
		},
		
		async matchSchool() {
            
            this.isLoading = true;
			
			const {
				highSchoolString,
				schoolDistrictString,
				postSecondarySchoolString,
				privateSchoolString,
				currentCompany
			} = this;
			
			let matchedCompany = this.potentialSchools[this.selectedMatchIndex];
            
            //set hubspot company type to be the correct type
            currentCompany.organizationBusinessType = matchedCompany.type;
            
			switch (matchedCompany.type) {
				case schoolDistrictString:
					currentCompany.schoolDistrictNcesId = matchedCompany.id;
					break;
				case postSecondarySchoolString:
					currentCompany.postSecondaryNcesUnitId = matchedCompany.id
					break;
				case privateSchoolString:
					currentCompany.privateSchoolNcesId = matchedCompany.id
					break;
				case highSchoolString:
					currentCompany.schoolNcesId = matchedCompany.id
					break;
			}
            
            let request = {company: currentCompany}
			
			// TODO: Submit to Hubspot API
			let response = await updateHubspotCompanyModel(request)
            
			if (response.isSuccess) {
				this.$store.commit(SET_SNACKBAR, {
					text: 'Successfully Updated Hubspot Company',
					color: 'success',
					open: true
				});

                this.selectedMatchIndex = null;
				this.changeHubspotCompanyToSchoolDistrict = false
                this.currentCompanyIndex++;
				
			} else {

                let errorMessage = 'There was an issue trying to update the hubspot company';
                
                if (response.errors != null) {
                    errorMessage = response.errors[0].message;
                }
                
				this.$store.commit(SET_SNACKBAR, {
					text: errorMessage,
					color: 'error',
					open: true
				});

                //delay after 3 seconds of error showing error because it breaks snackbar
                setTimeout(() => {
                    this.selectedMatchIndex = null;
                    this.currentCompanyIndex++;
                }, 5000)
			}

            this.isLoading = false;
		},

        async skipSchool() {

            this.isLoading = true;
            
            const {
                highSchoolString,
                schoolDistrictString,
                postSecondarySchoolString,
                privateSchoolString,
                currentCompany
            } = this;
            
            currentCompany.ncesToolSkipped = true;
			if (this.changeHubspotCompanyToSchoolDistrict) currentCompany.organizationBusinessType = "School District"
			

            let request = {company: currentCompany}

            // TODO: Submit to Hubspot API
            let response = await updateHubspotCompanyModel(request)

            if (response.isSuccess) {
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Successfully Updated Skipped Hubspot Company',
                    color: 'success',
                    open: true
                });

                this.selectedMatchIndex = null;
                this.currentCompanyIndex++
	            this.changeHubspotCompanyToSchoolDistrict = false

            } else {
                let errorMessage = 'There was an issue trying to update the hubspot company';

                if (response.errors != null) {
                    errorMessage = response.errors[0].message;
                }

                this.$store.commit(SET_SNACKBAR, {
                    text: errorMessage,
                    color: 'error',
                    open: true
                });

                //delay after 3 seconds of error showing
                setTimeout(() => {
                    this.selectedMatchIndex = null;
                    this.currentCompanyIndex++;
                }, 5000)
            }

            this.isLoading = false;
        }
	},
	computed: {
		currentCompany() {
			if (!this.hubspotCompanies) return null
			return this.hubspotCompanies[this.currentCompanyIndex]
		},
		allSchools() {
			if (!this.hasStarted) return
			let self = this;
			
			const {
				highSchoolString,
				schoolDistrictString,
				postSecondarySchoolString,
				privateSchoolString
			} = this;
            
			let allSchools = [];
			
			self.loadingSchools = true
            
            console.log(this.publicHighSchoolsJson)
			
			switch (this.selectedOrganizationBusinessType) {
				case schoolDistrictString:
					this.schoolDistrictsJson.forEach(x => {
						allSchools.push({
							name: x[this.propertyMaps.schoolDistricts.name],
							address: x[this.propertyMaps.schoolDistricts.address],
							city: x[this.propertyMaps.schoolDistricts.city],
							state: x[this.propertyMaps.schoolDistricts.state],
							zip: x[this.propertyMaps.schoolDistricts.zip],
							id: x[this.propertyMaps.schoolDistricts.ncesId],
							type: schoolDistrictString
						})
					});
					break;
				case postSecondarySchoolString:
                    this.postSecondarySchoolsJson.forEach(x => {
						allSchools.push({
							name: x[this.propertyMaps.postSecondarySchools.name],
							address: x[this.propertyMaps.postSecondarySchools.address],
							city: x[this.propertyMaps.postSecondarySchools.city],
							state: x[this.propertyMaps.postSecondarySchools.state],
							zip: x[this.propertyMaps.postSecondarySchools.zip],
							id: x[this.propertyMaps.postSecondarySchools.ncesId],
							type: postSecondarySchoolString
						})
					});
					break;
				case privateSchoolString:
					break;
				default:
					// Get all the schools based on that type
                    this.publicHighSchoolsJson.forEach(x => {
						allSchools.push({
							name: x[this.propertyMaps.publicSchools.name],
							address: x[this.propertyMaps.publicSchools.address],
							city: x[this.propertyMaps.publicSchools.city],
							state: x[this.propertyMaps.publicSchools.state],
							zip: x[this.propertyMaps.publicSchools.zip],
							id: x[this.propertyMaps.publicSchools.ncesId],
							type: highSchoolString
						})
					});

                    this.privateSchoolsJson.forEach(x => {
						allSchools.push({
							name: x[this.propertyMaps.privateSchools.name],
							address: x[this.propertyMaps.privateSchools.address],
							city: x[this.propertyMaps.privateSchools.city],
							state: x[this.propertyMaps.privateSchools.state],
							zip: x[this.propertyMaps.privateSchools.zip],
							id: x[this.propertyMaps.privateSchools.ncesId],
							type: privateSchoolString
						})
					});
			}
			self.loadingSchools = false
			return allSchools;
			
		},
		potentialSchools() {
			if (!this.currentCompany) return 
			this.filteringSchools = true
			
			let self = this;
			
			const {allSchools} = this;
			
			let potentialSchools = [];
			
			if (!this.currentCompany.state) {
				potentialSchools = allSchools;
			} else {
				potentialSchools = allSchools.filter(x => {
					
					// hopefully the state in hubspot in the two letter abbreviation and this first options gets hit.
					if (x.state === this.currentCompany.state) return true
					
					// If state string is more than two characters, try to match state to abbreviation
					let state = this.stateAbbreviations.find(x => x.name.toLowerCase() === this.currentCompany?.state.toLowerCase().trim())
					return state?.abbreviation === x.state
				})
			}
			
			
			potentialSchools.forEach(x => {
				x.matchRating = stringSimilarity.compareTwoStrings(x.name.toLowerCase(), self.currentCompany.name.toLowerCase())
			})
			
			potentialSchools.sort((a,b) => {
				return a.matchRating > b.matchRating ? -1 : 1
			})
			this.filteringSchools = false
			return potentialSchools.slice(0, this.currentCompany.state ? 10 : 50);
			
		}
	}
}
</script>

<style scoped>

.potential-match {
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 5px
}

.potential-match:hover {
	background-color: #e0e0e0;
	cursor: pointer
}

.match-selected {
	background-color: #c8e6c9;
}

.match-selected:hover {
	background-color: #a5d6a7;
}

</style>