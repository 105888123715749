<template>
    <div>
        <div v-if="!loading">
            <page-header :title="account.selectedOrganization.name" subtitle="Organization"></page-header>
            <app-tabs :value="tab">
                <app-tab v-for="tab in tabs" @click="goToRoute(tab.route)" :icon="tab.icon">{{ tab.label }}</app-tab>
            </app-tabs>
            <router-view
                v-if="organizationPreferences"
                :organizationPreferences="organizationPreferences"
                :organization="organization"
                @setOrganizationPreferences="changeOrganizationPreferences($event)"
            ></router-view>
        </div>
        <div v-else>
            <AppPageLoader text="Loading Organization Settings..." />
        </div>
    </div>
</template>

<script>
import {getOrganization, getOrganizationPreferences} from "@/services/organization.service";

export default {
    name: "Organization",
    props: ['account'],
    data() {
        return {
            loading: false,
            billingInfo: null,
            organizationPreferences: null,
            tabs: [
                // {
                //     label: 'Overview',
                //     route: '/organization/overview'
                // },
                {
                    label: 'Settings',
                    route: '/organization/settings',
                    icon: 'mdi-cog-outline'
                },
                {
                    label: 'Security',
                    route: '/organization/security',
                    icon: 'mdi-lock-outline'
                },
                {
                    label: 'Statistics',
                    route: '/organization/statistics',
                    icon: 'mdi-chart-line'
                },
                
            ],
            invoices: null,
            loadingInvoices: false,
            organization: {}
        }
    },
    async mounted() {
        this.loading = true
        await Promise.all([ this.getOrganizationPreferences(), this.getOrganization()]);

        if (this.organizationPreferences.externalApiEnabled){
            this.tabs.push({
                label: 'External Integrations',
                route: '/organization/api',
                icon: 'mdi-chart-line'
            })
        }
        
        this.loading = false
        // this.getBillingInfo()
        // this.getOrganizationPreferences()
        // this.getInvoices();
        
        
    },
    methods: {
        goToRoute(route) {
            this.$router.push(route)
        },
        changeOrganizationPreferences(organizationPreferences) {
            this.organizationPreferences = organizationPreferences
        },
        async getOrganizationPreferences() {
            const response = await getOrganizationPreferences();
            if (!response.isSuccess) {
                return
            }

            const {organizationPreferences} = response;
            this.organizationPreferences = organizationPreferences;
        },
        async getOrganization() {
            const response = await getOrganization();
            if (!response.isSuccess) {
                return
            }

            const {organization} = response;
            this.organization = organization;
        },
    },
    computed: {
        tab() {
            let value = 0;
            let path = this.$route.path.split('/');
            let last = path[2];

            switch (last) {
                case 'settings':
                    value = 0;
                    break;
                case 'security':
                    value = 1;
                    break;
                case 'statistics':
                    value = 2;
                    break;
                case 'api':
                    value = 3;
                    break;
            }
            
            return value
        }
    }
}
</script>

<style scoped>

</style>