<template>
	<div>
		<page-header title="Reporting" ></page-header>
		
		<AppCard hide-header class="pa-4">

				<v-row>
					<v-col :cols="4">
						<AppFormDatePicker v-model="startDate" label="Start Date" />
					</v-col>
					<v-col :cols="4">
						<AppFormDatePicker v-model="endDate" label="End Date" />
					</v-col>
				</v-row>
				<AppBtn style="" @click="getReport" :loading="loading">
					Get Test Volume Report 
				</AppBtn>
<!--				<app-fab-btn type="download" @click="downloadReport" />-->

			
			
		</AppCard>
		<AppCard hide-header class="mt-4" v-if="rows.length > 0">
			<v-data-table :items="rows" :headers="headers" >
				<template v-slot:top>
					<div class="pa-2">
						<AppBtn small @click="copyCsv">Copy Table to Clipboard</AppBtn>
					</div>
				</template>
			</v-data-table>
		</AppCard>

        <AppCard hide-header class="mt-4" v-if="rows.length > 0">
            <BalanceTestTypeCharts :organizations="rows"></BalanceTestTypeCharts>
        </AppCard>
        
        
		
		
		
	</div>
</template>

<script>
import moment from "moment";
import {GetOrganizationsBalanceTestVolumeReport} from "@/services/enterprise.service";
import BalanceTestTypeCharts from '../components/EnterpriseDashboard/BalanceTestTypeCharts.vue'

export default {
	name: "Reporting",
    components: {BalanceTestTypeCharts},
	data() {
		return {
			startDate: null,
			endDate: null,
			loading: false,
			headers: [
				{
					text: 'Org ID',
					value: 'organizationId'
				},
				{
					text: 'Org Name',
					value: 'organizationName'
				},
				{
					text: 'Total Test Count',
					value: 'totalTestCount'
				},
				{
					text: 'Total Profile Count',
					value: 'totalProfileCount'
				},
				{
					text: 'mBESS Count',
					value: 'mBESSCount'
				},
				{
					text: 'mCTSIB Count',
					value: 'mCTSIBCount'
				},
				{
					text: 'CDC 4-Stage Count',
					value: 'cdC4Count'
				},
        {
          text: 'Logged Events',
          value: 'eventCount'
        },
			],
			rows: []
		}
	},
	methods: {
		async getReport() {
			this.loading = true;
			
			const {startDate, endDate} = this;
			
			// const request = {
			// 	startDate,
			// 	endDate,
			// };
			
			const response = await GetOrganizationsBalanceTestVolumeReport(startDate, endDate);
			
			this.rows = response.organizations
			
			this.loading = false;
		},
		
		copyCsv() {
			
			const {rows} = this;
			
			let arrayForCsv = []
			
			rows.forEach(org => {
				let orgRow = []
				for (let key in org) {
					orgRow.push(org[key])
				}
				arrayForCsv.push(orgRow)
				
			})
			
			// Create a new textarea element to hold the copied text
			const textarea = document.createElement('textarea');

			// Convert the array of arrays to a string with tab and newline separators
			const text = arrayForCsv.map(row => row.join('\t')).join('\n');

			// Set the textarea's value to the copied text and append it to the document
			textarea.value = text;
			document.body.appendChild(textarea);

			// Select the textarea and copy its contents to the clipboard
			textarea.select();
			document.execCommand('copy');

			// Remove the textarea from the document
			document.body.removeChild(textarea);
		
		}
	},
}
</script>

<style scoped>

</style>