<template>
    <div>
        <page-header title="Internal Sway Admin" subtitle=""></page-header>
        <app-tabs :value="tab">
            <app-tab v-for="tab in tabs" @click="goToRoute(tab.route)" :icon="tab.icon">{{ tab.label }}</app-tab>
        </app-tabs>
        <router-view
        ></router-view>
    </div>
</template>

<script>
// import SuperAdminOrganizationsTable from "@/components/SuperAdmin/SuperAdminOrganizationsTable";
// import SuperAdminProfileUserTable from "@/components/SuperAdmin/SuperAdminProfileUserTable";
// import NewOrgEnrollment from "@/components/SuperAdmin/NewOrgEnrollment";
// import OrgConnectManagement from "@/components/SuperAdmin/OrgConnectManagement";
import {SET_SNACKBAR} from "@/store/mutation-types";

export default {
    name: "SuperAdmin",
    components: {
        // SuperAdminOrganizationsTable,
        // SuperAdminProfileUserTable,
        // NewOrgEnrollment,
        // OrgConnectManagement
    },
    data(){
        return{
            tabs: [
                // {
                //     label: 'Overview',
                //     route: '/organization/overview'
                // },
                {
                    label: 'Organizations',
                    route: '/super-admin/organizations',
                    icon: 'mdi-hospital-building'
                },
                {
                    label: 'Profiles and Users',
                    route: '/super-admin/profiles-and-users',
                    icon: 'mdi-account-group'
                },
                {
                    label: 'All Invoices',
                    route: '/super-admin/all-invoices',
                    icon: 'mdi-credit-card-outline'
                },
                {
                    label: 'New Org Enrollment',
                    route: '/super-admin/new-org-enrollment',
                    icon: 'mdi-plus-box-outline'
                },
	            {
		            label: 'Reports',
		            route: '/super-admin/reporting',
		            icon: 'mdi-chart-areaspline'
	            },
                // {
                //     label: 'Org Connect Management',
                //     route: '/super-admin/org-connect-management',
                //     icon: 'mdi-vector-line'
                // },
	            // {
		        //     label: 'NCES ID Tool',
		        //     route: '/super-admin/nces-tool',
		        //     icon: 'mdi-tools'
	            // },
	            
	            
            ]
        }
    },
    methods:{
        goToRoute(route) {
            this.$router.push(route)
        },
    },
    computed: {
        tab() {
            let value = 0;
            let path = this.$route.path.split('/');
            let last = path[2];

            switch (last) {
                case 'organizations':
                    value = 0;
                    break;
                case 'profiles-and-users':
                    value = 1;
                    break;
                case 'all-invoices':
                    value = 2;
                    break;
                case 'new-org-enrollment':
                    value = 3;
                    break;
                case 'reporting':
                    value = 4;
                    break;
	            case 'org-connect-management':
		            value = 5;
		            break;
            }

            return value
        }
    }
}
</script>

<style scoped>

</style>