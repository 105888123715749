import { render, staticRenderFns } from "./HelperMessage.vue?vue&type=template&id=65b132ae&scoped=true"
import script from "./HelperMessage.vue?vue&type=script&lang=js"
export * from "./HelperMessage.vue?vue&type=script&lang=js"
import style0 from "./HelperMessage.vue?vue&type=style&index=0&id=65b132ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65b132ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VMenu})
