<template>
    
    <v-data-table 
	    :headers="headers" 
	    :items="items"
	    @click:row="onRowClick"
	    hide-default-footer
	    :loading="loading"
    >
	    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="{item}">
		    <slot :name="slot" :item="item"></slot>
	    </template>
        <template v-slot:default>
            <thead style="width: 200%">
            <tr>
                <th class="text-left app-simple-table-header" :style="{'backgroundColor': isOpenEventsTable ? '#fb8c01' : null}" v-for="header in headers">
                    <span class="text--white">{{ header.text }}</span>
                </th>
            </tr>
            </thead>

	        
<!--            <slot name="body">-->
<!--                <table-body :headers="headers" :items="items" @onRowClick="onRowClick($event)"/>-->
<!--            </slot>-->
	        
        </template>
	    <template #no-data>
		    <slot name="no-data"></slot>
	    </template>
    </v-data-table>
</template>

<script>
import TableBody from "@/components/tables/TableBody";
export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
	    isOpenEventsTable: {
			type: Boolean
	    },
	    loading: {
		    type: Boolean
	    }

    },
    components: {
        TableBody
    },
    name: "AppSimpleTable",
    data () {
        return {

        }
    },
    methods: {
        onRowClick(item) {
            this.$emit('onRowClick', item)
        }
    }
}
</script>

<style scoped>
.app-simple-table-header {
    /*background-color: var(--v-light-base);*/
    
    /*color: white!important;*/
}

.app-simple-table-row:hover {
    background-color: var(--v-light-base)!important;
    cursor: pointer
}


</style>