<template>
    <v-container>
        <v-row>
            <v-col cols="12" lg="12">
                
                <app-card  title="Billing Info">
                    <div class="pa-2">
                        <div v-if="$store.state.currentAccount.isSuperAdmin" class="pr-4 pl-4" >
                            <div style="display: flex; align-items: center">
                                <h4 class="mr-2">Billing Cycle</h4>
                                <span>
                                    <app-fab-btn
                                            x-small
                                            type="edit"
                                            @click="editBillingCycle"
                                            v-if="!editingBillingCycle"
                                    />
                                </span>
                            </div>
                            <div v-if="!editingBillingCycle">
                                <app-select v-model="billingInfo.billingCycleTypeId" label="Billing Cycle" :items="billingCycleTypes()" item-text="text" item-value="value" disabled="disabled" />
                            </div>
                            <div v-else>
                                <app-form
                                        cancelable
                                        @submit="onSubmitBillingCycle"
                                        @cancel="editingBillingCycle = false"
                                        :saving="savingBillingCycle"
                                        style="max-width: 500px"
                                >
                                    <div>
                                        <app-select v-model="billingInfo.billingCycleTypeId" label="Billing Cycle" :items="billingCycleTypes()" item-text="text" item-value="value" />
                                    </div>
                                </app-form>
                            </div>
                        </div>
                        <v-divider v-if="$store.state.currentAccount.isSuperAdmin" class="ma-4"></v-divider>
                        <div v-if="$store.state.currentAccount.isSuperAdmin && !(billingChildOrgs && billingChildOrgs.length > 0)" class="pr-4 pl-4" >
                            <div style="display: flex; align-items: center">
                                <h4 class="mr-2">Billing Parent Org</h4>
                                <span>
                                    <app-fab-btn
                                            x-small
                                            type="edit"
                                            @click="editBillToOrg"
                                            v-if="!editingBillToOrg"
                                    />
                                </span>
                            </div>
                            <div v-if="!editingBillToOrg">
                                <h4 v-if="billingInfo.billToOrgId" class="mr-2">{{billingInfo.billToOrgName}} ({{billingInfo.billToOrgId}})</h4>
                                <h4 v-else class="mr-2">None</h4>
                            </div>
                            <div v-else>
                                <app-form
                                        cancelable
                                        @submit="onSubmitBillToOrg"
                                        @cancel="editingBillToOrg = false"
                                        :saving="savingBillToOrg"
                                        style="max-width: 500px"
                                >
                                    <div>
                                        <app-text-field label="Billing Parent Org" dense v-model="newBillToOrgId"></app-text-field>
                                    </div>
                                </app-form>
                            </div>
                        </div>
                        <v-divider v-if="$store.state.currentAccount.isSuperAdmin && !(billingChildOrgs && billingChildOrgs.length > 0)" class="ma-4"></v-divider>
                        <div v-if="$store.state.currentAccount.isSuperAdmin && !billingInfo.billToOrgId" class="pr-4 pl-4" >
                            <div style="display: flex; align-items: center">
                                <h4 class="mr-2">Billing Child Orgs</h4>
                                <span>
                                    <app-fab-btn
                                            x-small
                                            type="edit"
                                            @click="editBillingChildOrgs"
                                            v-if="!editingBillingChildOrgs"
                                    />
                                </span>
                            </div>
                            <div v-if="!editingBillingChildOrgs">
                                <div v-if="billingChildOrgs && billingChildOrgs.length > 0" v-for="org in billingChildOrgs">
                                    <span class="mr-2">{{org.organizationName}} ({{org.organizationId}})</span>
                                </div>
                                <h4 v-else class="mr-2">None</h4>
                            </div>
                            <div v-else>
                                <div v-for="org in billingChildOrgs">
                                    <a @click="showConfirmRemoveBillingChildOrg(org)" style="margin-right: 5px">X</a>
                                    <span class="mr-2">{{org.organizationName}} ({{org.organizationId}})</span>
                                </div>
                                <div>
                                    <div v-if="!isShowingAddBillingChildOrg">
                                        <app-btn @click="isShowingAddBillingChildOrg = true">
                                            Add Organization
                                        </app-btn>
                                        <app-btn @click="editingBillingChildOrgs = false">
                                            Done
                                        </app-btn>
                                    </div>
                                    <div v-else>
                                        <app-text-field label="Org Id" v-model="newBillingChildOrgId"></app-text-field>
                                        <app-btn @click="confirmAddBillingChildOrg">Add</app-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-divider v-if="$store.state.currentAccount.isSuperAdmin && !billingInfo.billToOrgId" class="ma-4"></v-divider>
                        <div class="pr-4 pl-4" >
                            <div style="display: flex; align-items: center">
                                <h4 class="mr-2">Billing Contact</h4>
                                <span>
                                <app-fab-btn
                                    x-small
                                    type="edit"
                                    @click="editBillingContactInfo"
                                    v-if="!editingBillingContactInfo"
                                />
                            </span>
                            </div>
                            <div v-if="!editingBillingContactInfo">
                                <div v-for="item in generalItems" :key="item.value" style="display: flex">
                                    <div style="min-width: 150px">
                                        {{item.text}}:
                                    </div>
                                    <div >
                                        {{billingInfo[item.value]}}
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <app-form
                                    cancelable
                                    @submit="onSubmitBillingContactInfo"
                                    @cancel="editingBillingContactInfo = false"
                                    :saving="savingBillingContactInfo"
                                    style="max-width: 500px"
                                >
                                    <div>
                                        <app-text-field label="Org Billing Name" dense v-model="updateBillingContactInfoForm.billingName" rules="required"></app-text-field>
                                        <app-text-field label="Contact Phone" dense v-model="updateBillingContactInfoForm.billingPhone"></app-text-field>
                                        <app-text-field label="Contact Email" dense v-model="updateBillingContactInfoForm.billingEmail"></app-text-field>
                                        <app-text-field label="Contact Name" dense v-model="updateBillingContactInfoForm.billingContactName"></app-text-field>
                                    </div>
                                </app-form>

                            </div>
                        </div>
                        <v-divider class="ma-4"></v-divider>
                        <div class="pr-4 pl-4">
                            <div class="mb-4" >
                                <div style="display: flex; align-items: center">
                                    <h4 class="mr-2">Mailing Address</h4>
                                    <span>
                                    <app-fab-btn
                                        x-small
                                        type="edit"
                                        @click="editMailingAddressInfo"
                                        v-if="!editingMailingAddressInfo"
                                    />
                                </span>
                                </div>
                                <div v-if="!editingMailingAddressInfo">
                                    <div v-for="item in mailingAddressItems" :key="item.value" style="display: flex">
                                        <div style="min-width: 150px">
                                            {{item.text}}:
                                        </div>
                                        <div>
                                            {{billingInfo[item.value]}}
                                        </div>

                                    </div>
                                </div>
                                <div v-else>
                                    <app-form
                                        cancelable
                                        @submit="onSubmitMailingAddressInfo"
                                        @cancel="editingMailingAddressInfo = false"
                                        :saving="savingMailingAddressInfo"
                                        style="max-width: 500px"
                                    >
                                        <div >
                                            <app-text-field label="Address 1" dense v-model="updateMailingAddressInfoForm.address1"></app-text-field>
                                            <app-text-field label="Address 2" dense v-model="updateMailingAddressInfoForm.address2"></app-text-field>
                                            <app-text-field label="City" dense v-model="updateMailingAddressInfoForm.city"></app-text-field>
                                            <app-text-field label="State" dense v-model="updateMailingAddressInfoForm.state"></app-text-field>
                                            <app-text-field label="Postal Code" dense v-model="updateMailingAddressInfoForm.postalCode"></app-text-field>
                                            <app-select :items="countryOptions" item-text="text" item-value="text" label="Country" dense v-model="updateMailingAddressInfoForm.country"></app-select>
                                        </div>
                                    </app-form>

                                </div>
                            </div>
                        </div>
                        <v-divider class="ma-4"></v-divider>
                        <div class="pr-4 pl-4">
                            <div class="mb-4" >
                                <div style="display: flex; align-items: center">
                                    <h4 class="mr-2">Billing Address</h4>
                                    <span>
                                        <app-fab-btn
                                            x-small
                                            type="edit"
                                            @click="editBillingAddressInfo"
                                            v-if="!editingBillingAddressInfo"
                                        />
                                    </span>
                                </div>
                                <div v-if="!editingBillingAddressInfo">
                                    <div v-for="item in billingAddressItems" :key="item.value" style="display: flex">
                                        <div style="min-width: 150px">
                                            {{item.text}}:
                                        </div>
                                        <div>
                                            {{billingInfo[item.value]}}
                                        </div>

                                    </div>
                                </div>
                                <div v-else>
                                    <app-form
                                        cancelable
                                        @submit="onSubmitBillingAddressInfo"
                                        @cancel="editingBillingAddressInfo = false"
                                        :saving="savingBillingAddressInfo"
                                        style="max-width: 500px"
                                    >
                                        <div >
                                            <app-text-field label="Address 1" dense v-model="updateBillingAddressInfoForm.billingAddress1"></app-text-field>
                                            <app-text-field label="Address 2" dense v-model="updateBillingAddressInfoForm.billingAddress2"></app-text-field>
                                            <app-text-field label="City" dense v-model="updateBillingAddressInfoForm.billingCity"></app-text-field>
                                            <app-text-field label="State" dense v-model="updateBillingAddressInfoForm.billingState"></app-text-field>
                                            <app-text-field label="Postal Code" dense v-model="updateBillingAddressInfoForm.billingPostalCode"></app-text-field>
                                            <app-select :items="countryOptions" item-text="text" item-value="text" label="Country" dense v-model="updateBillingAddressInfoForm.billingCountry"></app-select>
                                        </div>
                                    </app-form>

                                </div>
                            </div>
                        </div>
                        <v-divider class="ma-4"></v-divider>
                        <div class="pr-4 pl-4">
                            <div class="mb-4" >
                                <div style="display: flex; align-items: center">
                                    <h4 class="mr-2">Card Info</h4>
                                    <span>
                                    <app-fab-btn
                                        x-small
                                        type="edit"
                                        @click="editCreditCardInfo"
                                        v-if="!editingCreditCardInfo"
                                    />
                                </span>
                                </div>
                                <div v-show="!editingCreditCardInfo">
                                    <div v-if="billingInfo.lastFourDigits">
                                        Credit card ending in {{ billingInfo.lastFourDigits }} <app-fab-btn type="delete" color="red" small @click="deleteCreditCardDialogOpen = true"></app-fab-btn>
                                        <br />
                                        <div v-if="!savingAutopay">
                                            <app-btn @click="disableAutopay" v-if="billingInfo.automatedBillingAcceptedDate">Disable Autopay</app-btn>
                                            <app-btn @click="enrollInAutopay" v-else>Enroll in Autopay</app-btn>
                                        </div>
                                        <div v-else>
                                            <AppPageLoader />
                                        </div>
                                    </div>
                                    <div v-else>
                                        No credit card on file. <app-fab-btn type="add" small color="secondary" dark @click="editingCreditCardInfo = true"></app-fab-btn>
                                    </div>

                                </div>
                                <div v-show="editingCreditCardInfo">
                                    <credit-card-form
                                        @cancel="editingCreditCardInfo = false"
                                        @cardSaved="cardSaved"
                                        style="max-width: 650px"
                                    ></credit-card-form>
                                </div>

                            </div>
                        </div>
                    </div>
                    
                    

                </app-card>
            </v-col>
	        <v-col cols="12" lg="12">
		
		        <app-card title="Properties (Internal Sway Admin Only)" v-if="$store.state.currentAccount.isSuperAdmin">
			        <app-form-checkbox
				        checkbox-left-side label="Is Internal Org"
				        v-model="isInternalOrg"
				        helper-message="Defines whether or not the Organization is an Internal Sway Org. Used for reporting purposes. Go to Internal Sway Admin page to edit."
                        :disabled="true"
			        ></app-form-checkbox>		
		        </app-card>
	        </v-col>
        </v-row>
        <app-confirm-dialog
            title="Delete Credit Card" 
            :open="deleteCreditCardDialogOpen" 
            @close="deleteCreditCardDialogOpen = false"
            @confirm="deleteCreditCard"
            :loading="deletingCreditCard"
            confirm-delete
        >
            Are you sure you want to delete the credit card ending in {{ billingInfo.lastFourDigits }}?
        </app-confirm-dialog>

        <app-confirm-dialog
                title="Remove Billing Child Org"
                submitButtonTitle="Remove"
                :open="billingChildOrgToDelete"
                @close="billingChildOrgToDelete = null"
                @confirm="confirmRemoveBillingChildOrg"
                confirm-delete
        >
            <span v-if="billingChildOrgToDelete">Are you sure you want to remove {{billingChildOrgToDelete.organizationName}} ({{billingChildOrgToDelete.organizationId}})?</span>
        </app-confirm-dialog>
    </v-container>
</template>

<script>
import {
    getInvoices, getBillingInfo, updateBillingInfoModel, updateBillingInfo, updateBillToOrg, removeCreditCard,
    getBillingChildOrgs, addBillingChildOrg, removeBillingChildOrg, updateBillingCycle, addEditCard
} from '@/services/billing.service';
import moment from 'moment'
import {formatMoney} from '@/js/shared/helper'
import {SET_SNACKBAR} from "@/store/mutation-types";
import CreditCardForm from "@/components/Billing/CreditCardForm";
import {getOrganizationRequestModelFromOrganization, updateOrganizationInfo} from "@/services/organization.service";
import {billingCycleTypes, countryOptions} from "@/js/shared/constants";
import AppButton from "@/components/AppButton";

// find info for stripe API here
// https://stripe.com/docs/js/element/mount

export default {
    name: "BillingSettings",
    props: {
        billingInfo: {
            required: true
        }  
    },
    components: {
        CreditCardForm
    },
	watch: {
        
	},
    data() {
        return {
            
            // Dialog
            deleteCreditCardDialogOpen: false,
            
            // which section is being edited
            editingBillingContactInfo: false,
            editingBillingAddressInfo: false,
            editingCreditCardInfo: false,
            editingMailingAddressInfo: false,
            editingBillToOrg: false,
            editingBillingChildOrgs: false,
            editingBillingCycle: false,
            // loaders
            savingBillingContactInfo: false,
            savingBillingAddressInfo: false,
            savingMailingAddressInfo: false,
            savingCreditCardInfo: false,
            deletingCreditCard: false,
            savingBillToOrg: false,
            savingBillingCycle: false,
            savingAutopay: false,
            
            // Forms
            updateBillingContactInfoForm: null,
            updateBillingAddressInfoForm: null,
            updateMailingAddressInfoForm: null,

            newBillToOrgId: null,

            billingChildOrgs: null,
            isShowingAddBillingChildOrg: false,
            newBillingChildOrgId: null,
            billingChildOrgToDelete: null,
	        
	        isInternalOrg: false,

            mailingAddressItems: [
                {
                    text: 'Address 1',
                    value: 'address1'
                },
                {
                    text: 'Address 2',
                    value: 'address2'
                },
                {
                    text: 'City',
                    value: 'city'
                },

                {
                    text: 'State',
                    value: 'state'
                },
                {
                    text: 'Postal Code',
                    value: 'postalCode'
                },
                {
                    text: 'Country',
                    value: 'country'
                },
            ],
            
            billingAddressItems: [
                {
                    text: 'Address 1',
                    value: 'billingAddress1'
                },
                {
                    text: 'Address 2',
                    value: 'billingAddress2'
                },
                {
                    text: 'City',
                    value: 'billingCity'
                },
                
                {
                    text: 'State',
                    value: 'billingState'
                },
                {
                    text: 'Postal Code',
                    value: 'billingPostalCode'
                },
                {
                    text: 'Country',
                    value: 'billingCountry'
                },
            ],
            addressItems: [
                {
                    text: 'Address 1',
                    value: 'address1'
                },
                {
                    text: 'Address 2',
                    value: 'address2'
                },
                {
                    text: 'City',
                    value: 'city'
                },
                {
                    text: 'Country',
                    value: 'country'
                },
                {
                    text: 'Postal Code',
                    value: 'postalCode'
                },
                {
                    text: 'State',
                    value: 'state'
                },
                
            ],
            generalItems: [
                {
                    text: 'Org Name',
                    value: 'billingName'
                },
                {
                    text: 'Phone',
                    value: 'billingPhone'
                },
                {
                    text: 'Email',
                    value: 'billingEmail'
                },
                {
                    text: 'Contact Name',
                    value: 'billingContactName'
                },
            ],
            countryOptions: countryOptions
        }
    },
    mounted() {
        // this.getBillingInfo()
        
        if (this.$store.state.currentAccount.isSuperAdmin) {
            this.getBillingChildOrgs();
        }
        
        const {
            name,
            phone,
            email,
            
            address1,
            address2,
            city,
            state,
            postalCode,
            country,
            
            
            billingName,
            billingPhone,
            billingEmail,
            billingContactName,
            
            billingAddress1,
            billingAddress2,
            billingCity,
            billingState,
            billingPostalCode,
            billingCountry,

	        isInternalOrg
        } = this.billingInfo;
        
        this.updateBillingContactInfoForm = {
            billingName,
            billingPhone,
            billingEmail,
            billingContactName,
        };
        
        this.updateBillingAddressInfoForm = {
            billingAddress1,
            billingAddress2,
            billingCity,
            billingState,
            billingPostalCode,
            billingCountry
        }
        
        this.updateMailingAddressInfoForm = {
            address1,
            address2,
            city,
            state,
            postalCode,
            country
        }
		
		this.isInternalOrg = isInternalOrg
    },
    computed: {
        
    },
    methods: {
        billingCycleTypes() {
            return billingCycleTypes
        },
        async deleteCreditCard() {
            this.deletingCreditCard = true;
            
            let result = await removeCreditCard();
            this.$store.commit(SET_SNACKBAR, {
                text: 'Credited Card Successfully Deleted',
                color: 'success',
                open: true
            });
            await this.resetBillingInfo()
            this.deleteCreditCardDialogOpen = false
            this.deletingCreditCard = false;
        },
        async onSubmitBillingContactInfo() {
            this.savingBillingContactInfo = true;
            let request = {...updateBillingInfoModel};

            const {
                billingAddress1,
                billingAddress2,
                billingCity,
                billingState,
                billingPostalCode,
                billingMethod,
                billingCountry
            } = this.billingInfo

            const {
                billingName,
                billingPhone,
                billingEmail,
                billingContactName
            } = this.updateBillingContactInfoForm
            
            // From form
            request.billingName = billingName;
            request.billingPhone = billingPhone;
            request.billingEmail = billingEmail;
            request.billingContactName = billingContactName;
            
            // From existing
            request.billingAddress1 = billingAddress1;
            request.billingAddress2 = billingAddress2;
            request.billingCity = billingCity;
            request.billingState = billingState;
            request.billingPostalCode = billingPostalCode;
            request.billingCountry = billingCountry;
            request.billingMethod = billingMethod;
            
            const response = await updateBillingInfo(request);
            await this.resetBillingInfo()
            
            if (response.isSuccess){
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Billing Contact Successfully Updated',
                    color: 'success',
                    open: true
                });
                this.editingBillingContactInfo = false;
            }
            
            this.savingBillingContactInfo = false;
        },
        async onSubmitMailingAddressInfo() {
            this.savingMailingAddressInfo = true;
            

            const {
                address1,
                address2,
                city,
                state,
                postalCode,
                country
            } = this.updateMailingAddressInfoForm

            const {
                phone,
                medicalProfessionalName,
                medicalProfessionalEmail
            } = this.billingInfo
	
	        let request = getOrganizationRequestModelFromOrganization({...this.billingInfo})
	        
	        request.address1 = address1
	        request.address2 = address2
	        request.city = city
	        request.state = state
	        request.postalCode = postalCode
	        request.phone = phone
	        request.medicalProfessionalName = medicalProfessionalName
	        request.medicalProfessionalEmail = medicalProfessionalEmail
	        request.country = country
	        
            // let request = {
            //     adminAccountId,
            //     address1,
            //     address2,
            //     city,
            //     state,
            //     postalCode,
            //     phone,
            //     medicalProfessionalName,
            //     medicalProfessionalEmail,
            //     country
            // };

			
			
            const response = await updateOrganizationInfo({organization: request});
            await this.resetBillingInfo()

            if (response.isSuccess){
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Mailing Address Successfully Updated',
                    color: 'success',
                    open: true
                });
                this.editingMailingAddressInfo = false;
            }

            this.savingMailingAddressInfo = false;
        },
        async onSubmitBillingAddressInfo() {
            this.savingBillingAddressInfo = true;
            let request = {...updateBillingInfoModel};

            const {
                billingAddress1,
                billingAddress2,
                billingCity,
                billingState,
                billingPostalCode,
                billingCountry
            } = this.updateBillingAddressInfoForm

            const {
                billingName,
                billingPhone,
                billingEmail,
                billingMethod,
                billingContactName
            } = this.updateBillingContactInfoForm
            
            // From existing
            request.billingName = billingName;
            request.billingPhone = billingPhone;
            request.billingEmail = billingEmail;
            request.billingMethod = billingMethod;
            request.billingContactName = billingContactName;
            
            // From form
            request.billingAddress1 = billingAddress1;
            request.billingAddress2 = billingAddress2;
            request.billingCity = billingCity;
            request.billingState = billingState;
            request.billingPostalCode = billingPostalCode;
            request.billingCountry = billingCountry;
            
            
            const response = await updateBillingInfo(request);
            await this.resetBillingInfo()

            if (response.isSuccess){
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Billing Address Successfully Updated',
                    color: 'success',
                    open: true
                });
                this.editingMailingAddressInfo = false;
            }

            this.savingBillingAddressInfo = false;
            this.editingBillingAddressInfo = false;
        },
        async onSubmitBillingCycle() {
            
            this.savingBillingCycle = true;

            let request = {
                BillingCycleTypeId: this.billingInfo.billingCycleTypeId ? this.billingInfo.billingCycleTypeId : null
            };

            const response = await updateBillingCycle(request);
            await this.resetBillingInfo();

            if (response.isSuccess){
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Billing Cycle Successfully Updated',
                    color: 'success',
                    open: true
                });
            }

            this.savingBillingCycle = false;
            this.editingBillingCycle = false;
        },
        async cardSaved() {
            await this.resetBillingInfo();
            this.$store.commit(SET_SNACKBAR, {
                text: 'Credit Card Successfully Updated',
                color: 'success',
                open: true
            });
            this.editingCreditCardInfo = false
        },
        async resetBillingInfo() {
            const result = await getBillingInfo();
            
            return this.$emit('setBillingInfo', result)
        },
        async getInvoices() {
            return this.$emit('getInvoices');
        },
        async onSubmitBillToOrg() {
            const result = await updateBillToOrg({OrganizationId: this.newBillToOrgId == "" ? null : this.newBillToOrgId});
            await this.resetBillingInfo();

            if (result.isSuccess){
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Bill To Organization Successfully Updated',
                    color: 'success',
                    open: true
                });
            }
        },
        async getBillingChildOrgs(){
            const result = await getBillingChildOrgs();
            this.billingChildOrgs = result.organizations;
        },
        async showConfirmRemoveBillingChildOrg(org){
            // show a modal popup to confirm
            this.billingChildOrgToDelete = org;
        },
        async confirmRemoveBillingChildOrg(){
            const result = await removeBillingChildOrg({OrganizationId: this.billingChildOrgToDelete.organizationId});
            this.billingChildOrgToDelete = null;
            await this.getBillingChildOrgs();
        },
        async confirmAddBillingChildOrg(){
            if (!this.newBillingChildOrgId) {
                this.isShowingAddBillingChildOrg = false;
                return;
            }
                
            const result = await addBillingChildOrg({OrganizationId: this.newBillingChildOrgId});
            this.isShowingAddBillingChildOrg = false;
            await this.getBillingChildOrgs();
        },
        async enrollInAutopay(){
            this.savingAutopay = true;
            const result = await addEditCard({cardToken: null, isAutoPayEnrolled: true});
            await this.resetBillingInfo();
            this.savingAutopay = false;
        },
        async disableAutopay(){
            this.savingAutopay = true;
            const result = await addEditCard({cardToken: null, isAutoPayEnrolled: false});
            await this.resetBillingInfo();
            this.savingAutopay = false;
        },
        editBillToOrg() {
            this.newBillToOrgId = this.billingInfo.billToOrgId;
            this.editingBillToOrg = true;
            this.editingBillingChildOrgs = false;
            this.editingBillingContactInfo = false;
            this.editingMailingAddressInfo = false;
            this.editingBillingAddressInfo = false;
            this.editingCreditCardInfo = false;
            this.editingBillingCycle = false;
        },
        editBillingChildOrgs() {
            this.editingBillToOrg = false;
            this.editingBillingChildOrgs = true;
            this.editingBillingContactInfo = false;
            this.editingMailingAddressInfo = false;
            this.editingBillingAddressInfo = false;
            this.editingCreditCardInfo = false;
            this.editingBillingCycle = false;
        },
        editBillingContactInfo() {
            this.editingBillToOrg = false;
            this.editingBillingChildOrgs = false;
            this.editingBillingContactInfo = true;
            this.editingMailingAddressInfo = false;
            this.editingBillingAddressInfo = false;
            this.editingCreditCardInfo = false;
            this.editingBillingCycle = false;
        },
        editBillingAddressInfo() {
            this.editingBillToOrg = false;
            this.editingBillingChildOrgs = false;
            this.editingBillingAddressInfo = true;
            this.editingMailingAddressInfo = false;
            this.editingBillingContactInfo = false;
            this.editingCreditCardInfo = false;
            this.editingBillingCycle = false;
        },
        editMailingAddressInfo() {
            this.editingBillToOrg = false;
            this.editingBillingChildOrgs = false;
            this.editingBillingAddressInfo = false;
            this.editingMailingAddressInfo = true;
            this.editingBillingContactInfo = false;
            this.editingCreditCardInfo = false;
            this.editingBillingCycle = false;
        },
        editCreditCardInfo() {
            this.editingBillToOrg = false;
            this.editingBillingChildOrgs = false;
            this.editingCreditCardInfo = true;
            this.editingMailingAddressInfo = false;
            this.editingBillingAddressInfo = false;
            this.editingBillingContactInfo = false;
            this.editingBillingCycle = false;
            
        },
        editBillingCycle() {
            this.editingBillToOrg = false;
            this.editingBillingChildOrgs = false;
            this.editingCreditCardInfo = false;
            this.editingMailingAddressInfo = false;
            this.editingBillingAddressInfo = false;
            this.editingBillingContactInfo = false;
            this.editingBillingCycle = true;
        }
    },
    
}
</script>

<style scoped>

</style>