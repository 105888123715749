<template>

    <dialog-form
        :open="open"
        @close="$emit('close')"
        title="Create a New Code"
        @submit="submitCreateSessionForm"
        :saving="savingNewSession"
    >
        <template v-slot:form-content>
            <div>
                <app-select
                    :items="[{label: 'Group', value: 'group'}, {label: 'Profile', value: 'individual'}]"
                    v-model="form.groupOrIndividual"
                    label="Group or Profile*"
                    item-text="label"
                    item-value="value"
                    rules="required"
                    :disabled="groupPredefined || profilePredefined"
                    @input="onGroupOrIndividualChanged"
                />

                <app-select
                    :items="protocols"
                    v-model="form.organizationProtocolId"
                    label="Protocol*"
                    item-text="name"
                    item-value="organizationProtocolId"
                    rules="required"
                    class="pt-2"
                />
<!--                <app-select-->
<!--                    :items="endsOnOptions"-->
<!--                    v-model="form.endsOn"-->
<!--                    label="End Date*"-->
<!--                    item-text="label"-->
<!--                    item-value="value"-->
<!--                    rules="required"-->
<!--                />-->
                <app-form-date-picker label="End Date (Central Time)*"
                                      v-model="form.endsOn"
                                      :allowed-dates="e => allowedDates(e)"
                                      class="pt-2"

                />
                

                <div v-if="form.groupOrIndividual">
                    <v-divider class="mt-4 mb-4"></v-divider>
                    <div v-if="form.groupOrIndividual === 'individual'">
                        <app-autocomplete
                            :items="localProfiles"
                            v-model="form.profileId"
                            label="Profile*"
                            item-text="fullName"
                            item-value="id"
                            placeholder="Start typing to search profiles..."
                            @update-search-input="updateSearchString($event)"
                            no-data-text="No profiles match your search"
                            chips
                            deletable-chips
                            rules="required"
                            :disabled="profilePredefined"
                            @input="onGroupOrProfileSelected"
                        />
                        <app-select
                            :items="screeningOrAssessmentOptions"
                            v-model="form.testOrSession"
                            label="Screening or Assessment"
                            item-text="label"
                            item-value="value"
                            rules="required"
                            class="pt-2"
                            show-description
                        />
	                    <v-checkbox
		                    v-model="form.isBaseline"
		                    color="primary"
		                    label="Baseline"
		                    item-text="label"
		                    item-value="value"
		                    class="pt-2"
	                    />
                    </div>
                    <div v-if="form.groupOrIndividual === 'group' || form.profileId === 0">
                        <app-select
                            :items="localGroups"
                            v-model="form.groupId"
                            label="Group*"
                            item-text="name"
                            item-value="id"
                            rules="required"
                            :disabled="groupPredefined"
                            @input="onGroupOrProfileSelected"
                        />
	                    <app-select
		                    :items="screeningOrAssessmentOptions"
		                    v-model="form.testOrSession"
		                    label="Screening or Assessment*"
		                    item-text="label"
		                    item-value="value"
		                    rules="required"
		                    class="pt-2"
		                    show-description
	                    />
	                    <v-checkbox
		                    v-model="form.isBaseline"
		                    color="primary"
		                    label="Baseline"
		                    item-text="label"
		                    item-value="value"
		                    class="pt-2"
	                    />
	                   
                    </div>
	                <v-checkbox
		                v-model="form.showResultsAfterSession"
		                color="primary"
		                label="Show Results after test"
		                item-text="label"
		                item-value="value"
		                class="pt-2"
		                helperMessage="hello"
	                />
                    <v-checkbox v-if="shouldShowAccountCreation"
                        v-model="form.isAccountCreationEnabled"
                        color="primary"
                        label="Enable Account Creation (RECOMMENDED)"
                        item-text="label"
                        item-value="value"
                        class="pt-2"
                        helperMessage="Allow users to create an account during profile creation. This will give them access to share their profile or take remote assigned tests."
                    />

                </div>
                
                <UserNotificationsForm :selected-account-notifications="form.selectedAccountNotifications"/>
            </div>

        </template>

    </dialog-form>

</template>

<script>
import {getProtocols} from "@/services/protocol.service";
import {getGroups} from "@/services/groups.service";
import {getProfilesPaged} from "@/services/profile.service";
import {createSession, getAccountNotificationPrefs, sessionModel} from "@/services/session.service";
import moment from "moment";
import 'moment-timezone'
import {SET_SNACKBAR} from "@/store/mutation-types";
import {getCodeTypeId, asLocalTime} from "@/js/shared/helper";
import {screeningOrAssessmentOptions} from "@/js/shared/constants";
import UserNotificationsForm from "@/components/Sessions/UserNotificationsForm.vue";

export default {
    name: "SessionCreateForm",
    components: {UserNotificationsForm},
    props: {
        open: {
            type: Boolean
        },
        groups: {
            type: Array,
            required: false
        },
        group: {
            required: false
        },
        profiles: {
            type: Array,
            required: false
        },
        profile: {
            required: false
        },
        organizationPreferences: {
            required: false
        }
    },
    data() {
        return {
            savingNewSession: false,
            addSessionProfileSearchString: '',
            protocols: [],
            localGroups: [],
            localProfiles: [],
            endsOnOptions: [
                {
                    label: '24 hours',
                    value: 1
                },
                {
                    label: '72 hours',
                    value: 3
                },
                {
                    label: '1 week',
                    value: 7
                },
                {
                    label: '30 days',
                    value: 30
                },
                {
                    label: '1 year',
                    value: 365
                },
            ],
	        screeningOrAssessmentOptions,
            form: null,
            groupPredefined: false,
            profilePredefined: false,
        }
    },
    mounted() {
        this.init()

    },
    computed: {
        shouldShowAccountCreation() {
            let {profileId, groupOrIndividual} = this.form;

            let isGroup = groupOrIndividual === 'group';
            let isNewProfile = groupOrIndividual === 'individual' && profileId === 0;

            let shouldshow = this.organizationPreferences != null && this.organizationPreferences.enablePatientAccountCreation && (isGroup || isNewProfile)

            return shouldshow;
        },
        isViewingAsSuper() {
            let {viewingAsSuper} = this.$store.state.currentAccount;
            return viewingAsSuper;
        },
    },
    methods: {
        allowedDates(e) {
            return !moment(e).isBefore(moment().subtract(1, 'day'));
        },
        async init() {
            await Promise.all([this.setGroups(), this.setProtocols(), this.setProfilesForNewSessionSelect()]);

            let form = {
                groupId: null,
                profileId: null,
                organizationProtocolId: null,
                groupOrIndividual: null,
                endsOn: null,
                isBaseline: null,
                testOrSession: null,
	            showResultsAfterSession: null,
                isAccountCreationEnabled: null,
                selectedAccountNotifications: []
            }
            
            if (this.group && this.groups) {
                this.localGroups = this.groups;
                form.groupId = this.group.id;
                form.groupOrIndividual = 'group';
                this.groupPredefined = true;
            }
            
            if (this.profile && this.profiles) {
                this.localProfiles = this.profiles.map(profile => {
                    
                    //variable for fullname that uses ID if viewing as super, otherwise uses first and last name
                    profile.fullName = this.isViewingAsSuper ? profile.id : `${profile.firstName} ${profile.lastName}`;
                    return profile
                });
                form.profileId = this.profile.id;
                form.groupOrIndividual = 'individual';
                this.profilePredefined = true;
            }
            
            if (this.organizationPreferences != null) {
                form.isAccountCreationEnabled = this.organizationPreferences.enablePatientAccountCreation;
            }
            
            this.form = form

            if (this.profilePredefined || this.groupPredefined){
                await this.onGroupOrProfileSelected();
            }
        },
        updateSearchString(value) {
            if (value !== this.addSessionProfileSearchString && this.form.profileId === null) {
                this.addSessionProfileSearchString = value;
                this.setProfilesForNewSessionSelect();
            }
        },
        async setProtocols() {
            const response = await getProtocols();
            this.protocols = response.protocols;
        },
        async setGroups() {
            const response = await getGroups();
            this.localGroups = response.groups;
        },

        async setProfilesForNewSessionSelect() {
            let request = {
                pageNumber: 1,
                rowsPerPage: 10,
                sortByColumn: null,
                sortAscending: null,
                searchString: this.addSessionProfileSearchString,
                groupIds: null
            };

            let response = await getProfilesPaged(request);

            if (!response.isSuccess) return
            const {profiles} = response;


            let formattedProfiles = profiles.map(profile => {

                //variable for fullname that uses ID if viewing as super, otherwise uses first and last name
                profile.fullName = this.isViewingAsSuper ? profile.id : `${profile.firstName} ${profile.lastName}`;
                return profile
            });

            formattedProfiles.unshift({
                fullName: '+ New Profile',
                id: 0
            })

            this.localProfiles = formattedProfiles
        },
        async submitCreateSessionForm() {
            this.savingNewSession = true;
            let request = {...sessionModel}
            let {
                groupId,
                profileId,
                organizationProtocolId,
                endsOn,
                groupOrIndividual,
                isBaseline,
                testOrSession,
                showResultsAfterSession,
                isAccountCreationEnabled,
                selectedAccountNotifications
            }= this.form;
            

            let baselineSessionTypeId;
			
			let codeTypeId = getCodeTypeId({
				isGroup: groupOrIndividual === 'group',
				isNewProfile: groupOrIndividual === 'individual' && profileId === 0,
				isScreening: testOrSession === 0
			})

            switch (groupOrIndividual) {
                case 'group':
                    baselineSessionTypeId = 8;
                    break;
                case 'individual':
                    // is Test
                    if (testOrSession === 0) {
                        if (profileId === 0) baselineSessionTypeId = 5
                        else baselineSessionTypeId = 3
                    } 
                    // is Session
                    else {
                        if (profileId === 0) baselineSessionTypeId = 11
                        else baselineSessionTypeId = 9
                    }
                    
            }
            // 

            let endDateMoment = moment(endsOn)
            let date = endDateMoment.date();
            let month = endDateMoment.month()
            let year = endDateMoment.year();
            let endsOnCentral = asLocalTime(moment(endsOn)).year(year).month(month).date(date).hour(23).minute(59).seconds(59)

            let endsOnUTC = moment.utc(endsOnCentral).format()
            
            // const response = getUser();
            request.groupId = groupId;
            request.profileId = profileId;
            request.organizationProtocolId = organizationProtocolId;
            request.startsOn = moment.utc().format();
            request.endsOn = endsOnUTC
            request.baselineSessionTypeId = codeTypeId;
            request.isNonBaseline = !isBaseline
            request.showResultsAfterSession = showResultsAfterSession;
            request.isAccountCreationEnabled = isAccountCreationEnabled;
            request.selectedAccountNotifications = selectedAccountNotifications;

            let response = await createSession({session: request});
            this.savingNewSession = false;

            if (!response.isSuccess) {

                return
            }

            this.$store.commit(SET_SNACKBAR, {
                text: 'Session Successfully Created',
                color: 'success',
                open: true
            });
            this.savingNewSession = false;
            // this.addSessionDialogOpen = false;
            this.$emit('close')

            await this.$router.push(`/codes/${response.session.id}`)

        },
        onGroupOrIndividualChanged(){
            this.form.groupId = null;
            this.form.profileId = null;
        },
        async onGroupOrProfileSelected(){
            let response = await getAccountNotificationPrefs({groupId: this.form.groupId, profileId: this.form.profileId});
            this.form.selectedAccountNotifications = response.selectedAccountNotifications;
        }
    }
}
</script>

<style scoped>

</style>