<template>
    <v-app class="app" id="app">
        <div>
            <router-view
                @setAccount="updateAccount($event)"
                :account="account"
            ></router-view>
            <app-snackbar></app-snackbar>
    
<!--            <app-confirm-dialog-->
<!--                title="Are you still there?"-->
<!--                submitButtonTitle="I'm Still Here"-->
<!--                :open="youStillThereDialogOpen"-->
<!--                @close="confirmStillThere"-->
<!--                confirm-delete-->
<!--                @confirm="confirmStillThere"-->
<!--            >-->
<!--                This window will close in {{secondsLeft}} seconds.-->
<!--            </app-confirm-dialog>-->
        </div>
    </v-app>
</template>

<script>
// import SidebarLeft from "@/components/SidebarLeft";
// import NavbarTop from "@/components/NavbarTop";
import Highcharts from 'highcharts'
import {swayThemes, readyCheckThemes} from "@/config/themes"
import {getUser, getAccounts, logout, refreshToken} from '@/services/userService'
import {getProduct} from '@/js/utilities'
import {SET_ACCOUNT, SET_ORGANIZATION} from "@/store/mutation-types";

import AppSnackbar from "@/components/AppSnackbar";
import {SET_PRODUCT} from "@/store/mutation-types";
import {getOrganization, getOrganizationPreferences} from "@/services/organization.service";

// import '@/styles/pdf.css'
// import '@/styles/general.css'

const timeoutWarningDelay = 18; //if no one touches screen for 18 minutes, popup will show asking if still there
const timeoutNowDelay = .9; //dialog will stay open for .9 minutes

// Set timeout variables.
const timeoutWarning = 1000 * 60 * timeoutWarningDelay; // Display warning in 19 minutes.
const timeoutNow = 1000 * 60 * timeoutNowDelay; // Warning has been shown, give the user 30 seconds to interact

export default {
    name: 'App',
    components: {
        // 'sidebar-left': SidebarLeft,
        // 'navbar-top': NavbarTop,
        AppSnackbar
    },

    data: () => ({
        company: 'sway',
        theme: 'light',
        account: null,
        youStillThereDialogOpen: false,
        secondsLeft: null,
    }),
    created() {
        this.setTheme();
        const product = getProduct();
        this.$store.commit(SET_PRODUCT, product);

        const favicon = document.getElementById("favicon");
        const origin = window.location.origin;

        
        if (product === 'readycheck') {
            favicon.href = `${origin}/readycheck-favicon.ico`;
            window.document.title = 'ReadyCheck'
        } else {
            favicon.href = `${origin}/sway-favicon.ico`;
            window.document.title = 'Sway'
        }
         
        // 
    },
    async mounted() {
        let account = await getUser();
        if (account){
            let organization = await getOrganization();
            this.$store.commit(SET_ORGANIZATION, organization)
        }
        this.$store.commit(SET_ACCOUNT, account)
        this.account = account ? account : 'null';
    },
    updated() {
        // let account = getUser();
        // this.account = account ? account : 'null';
    },

    methods: {
        updateAccount(account) {
            this.account = account
            // console.log('SEETING ACCOUNT', event)
        },
        onCompanyChange(e) {
            this.company = e;
            this.setTheme()
        },
        onLightDarkChange() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        },
        setTheme() {
            let newTheme = getProduct() === 'readycheck' ? readyCheckThemes : swayThemes;
            this.company = getProduct()

            let darkKeys = Object.keys(newTheme.dark)
            darkKeys.forEach(key => {
                this.$vuetify.theme.themes.dark[key] = newTheme.dark[key]
            })
            let lightKeys = Object.keys(newTheme.light)
            lightKeys.forEach(key => {
                this.$vuetify.theme.themes.light[key] = newTheme.light[key]
            });
        },
    },
};
</script>
<style scoped>


#app {
    /* font-family: proxima-nova,Helvetica Neue,Helvetica,Arial,sans-serif; */
    display: flex;
    background-color: var(--v-background-base);
}

.sidebar-container {
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    width: 240px
}

.main-content {
    height: calc(100vh - 80px);
    width: calc(100vw - 240px);
    padding: 0 20px;
    overflow-y: scroll;
}

/* .main-content::-webkit-scrollbar-track {
  background: rgb(255, 0, 0);
} */

.top-bar {
    height: 80px;
    width: calc(100vw - 240px)
}

.theme-picker {
    position: fixed;
    bottom: 10px;
    right: 20px;
    /*height: 20px;*/
    /*width: 20px;*/
    /*background-color: red;*/
}
</style>

<style lang="scss">


//@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Bitter&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');
//
//@import '~vuetify/src/styles/styles.sass';
//
//html, body {
//  overflow-y: hidden;
//  overflow-x: hidden;
//  font-family: $font-family;
//  //color: red!important
//}
//
//.v-application {
//  font-family: $font-family;
//  color: var(--v-text-base)!important
//}
//
//.v-tab {
//  text-transform: none!important;
//}
//
////.theme--light {
////  color: red!important
////}
//
//h1, h2, h3, h4 {
//  font-family: $header-font-family;
//  color: var(--v-dark-base)
//}
//
//.v-dialog {
//  position: absolute!important;
//  top: 10vh!important;
//}
//
//// highcharts adjustments
//.highcharts-background {
//  fill: transparent
//}
//
//.highcharts-root {
//  font-family: $font-family
//}
//
//.v-input__control {
//  max-height: 56px!important
//}
</style>
