<template>
    <div>
        <v-menu
            open-on-hover
            nudge-right="30"
            nudge-top="10"
            right

            transition="slide-x-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <div class="helper-container ml-2" v-bind="attrs" v-on="on">
                    <v-icon x-small color="white" >mdi-help</v-icon>
                </div>
            </template>

            <app-card class="pa-2" hide-header>
                {{ message }}
            </app-card>
        </v-menu>
    </div>
    
</template>

<script>
export default {
    name: "HelperMessage",
    props: {
        message: {
            required: true,
            type: String
        }
    }
}
</script>

<style scoped>
.helper-container {
    background-color: var(--v-text-base);
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center
}
</style>