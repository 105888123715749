<template>
    <v-menu 
        :close-on-content-click="false" 
        offset-y
        :nudge-bottom="5"
    >
        <template v-slot:activator="{ on: menu, attrs }">

            <div

                v-bind="attrs"
                v-on="{ ...menu }"
            >
                {{ value.hour }}:{{ displayMinute }} {{ value.amPm }}
            </div>


        </template>
        <div style="display: flex; background-color: white; ">
            <div >
                <div class="text-overline text-center" style="border-right: 1px solid #ececec">HH</div>
                <v-list style="flex: 1; max-height: 300px; overflow-y: scroll" dense>
                    <v-list-item
                        v-for="(item, index) in hours"
                        :key="index"
                        :class="{active: item.value === value.hour}"
                        @click="hour = item"
                    >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
            <div >
                <div class="text-overline ml-4" style="border-right: 1px solid #ececec">MM</div>
                <v-list style="flex: 1; max-height: 300px; overflow-y: scroll" dense>
                    <v-list-item
                        v-for="(item, index) in minutes"
                        :key="index"
                        :class="{active: item.value === value.minute}"
                        @click="minute = item"
                    >
                        <v-list-item-title >{{ item.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
            <div>
                <div class="text-overline ml-4">A</div>
                <v-list style="flex: 1" dense>
                    <v-list-item
                        v-for="(item, index) in amOrPm"
                        :key="index"
                        :class="{active: item.value === value.amPm}"
                        @click="amPm = item"
                    >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>

        </div>

    </v-menu>
</template>

<script>
export default {
    name: "AppTimePicker",
    props: {
        value: {
            required: true
        }
    },
    data() {
        return {
            hour: {
                text: '12',
                value: 12
            },
            minute: {
                text: '00',
                value: 0
            },
            amPm: {
                text: "AM",
                value: 'am'
            },
            hours: [
                {
                    text: '12',
                    value: 12
                },
                {
                    text: '1',
                    value: 1
                },
                {
                    text: '2',
                    value: 2
                },
                {
                    text: '3',
                    value: 3
                },
                {
                    text: '4',
                    value: 4
                },
                {
                    text: '5',
                    value: 5
                },
                {
                    text: '6',
                    value: 6
                },
                {
                    text: '7',
                    value: 7
                },
                {
                    text: '8',
                    value: 8
                },
                {
                    text: '9',
                    value: 9
                },
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '11',
                    value: 11
                },
                
            ],
            minutes: [
                {
                    text: '00',
                    value: 0
                },
                {
                    text: '05',
                    value: 5
                },
                {
                    text: '10',
                    value: 10
                },
                {
                    text: '15',
                    value: 15
                },
                {
                    text: '20',
                    value: 20
                },
                {
                    text: '25',
                    value: 25
                },
                {
                    text: '30',
                    value: 30
                },
                {
                    text: '35',
                    value: 35
                },
                {
                    text: '40',
                    value: 40
                },
                {
                    text: '45',
                    value: 45
                },
                {
                    text: '50',
                    value: 50
                },
                {
                    text: '55',
                    value: 55
                },
            ],
            amOrPm: [
                {
                    text: "AM",
                    value: 'am'
                },
                {
                    text: "PM",
                    value: 'pm'
                },

            ]
        }
    },
    methods: {
        onChange() {
            const {hour, minute, amPm} = this;
            this.$emit('input', {
                hour: hour.value, 
                minute: minute.value, 
                amPm: amPm.value
            })
        }
    },
    watch: {
        hour(to, from) {
            this.onChange()
        },
        minute(to, from) {
            this.onChange()
        },
        amPm(to, from) {
            this.onChange()
        },
        
    },
    computed: {
        displayMinute() {
            let display = this.minutes.find(minute => minute.value === this.value.minute)
            return display.text
        }
    }
}
</script>

<style scoped>
.active {
    background-color: var(--v-primary-base);
}
</style>