import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import appComponents from "@/plugins/appComponents";
import Highcharts from 'highcharts'
import highchartsMore from "highcharts/highcharts-more";
import highcharts3d from 'highcharts/highcharts-3d'
import sankey from 'highcharts/modules/sankey'
import Annotations from "highcharts/modules/annotations";
import HighchartsVue from 'highcharts-vue';
import stockInit from 'highcharts/modules/stock';
import HighchartsXrange from 'highcharts/modules/xrange'
import HighchartsTreemap from 'highcharts/modules/treemap'
import HighchartsTreegraph from 'highcharts/modules/treegraph'
import { ValidationProvider, extend } from 'vee-validate';
import VueAnalytics from 'vue-analytics';
import '@/styles/main.scss'
import 'moment-timezone'
import Multiselect from 'vue-multiselect'
import * as VueGoogleMaps from 'vue2-google-maps'
import {UserAgentApplication, LogLevel} from "msal";

const clientId = 'a83dabf8-d5cb-407e-ae5c-25f1cd6e358b'; //'0011cdb3-4314-4d04-96c3-7892dbce04bb'; // 'a83dabf8-d5cb-407e-ae5c-25f1cd6e358b';
const tenantId = '54580ea7-f79c-4bd3-a966-99a677cc66aa';
const redirectUri = window.location.origin;

const config = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${ tenantId }`,
    redirectUri,
    postLogoutRedirectUri: redirectUri,
    clientCapabilities: ['CP1']
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    loggerOptions: {
      loggerCallback(logLevel, message) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
};

export const tokenConfig = {
  //scopes: [ 'Files.Read' ]
  //scopes: ['api://0011cdb3-4314-4d04-96c3-7892dbce04bb/.default']
  //scopes: ["api://0011cdb3-4314-4d04-96c3-7892dbce04bb/Files.Read"]
  //scopes: ["https://devportalv2.swaymedical.com/user_impersonation/Files.Read"]
  //scopes: ["https://portal.swaymedical.com/user_impersonation/Files.Read"]
  scopes: ["https://devportal.swaymedical.com/user_impersonation/Files.Read"]
};

export const loginRequest = {
  //scopes: ['Files.Read']
  scopes: ["openid", "profile"]
};

export const tokenRequest = {
  scopes: ['api://your-api-client-id/.default']
};

export const auth = new UserAgentApplication(config);

auth.handleRedirectCallback((error) => {
  if (error) {
    console.error(error);
  }
});


// https://www.highcharts.com/docs/getting-started/how-to-set-options
Highcharts.setOptions({
  colors: ['#1E90FF', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572',
    '#FF9655', '#FFF263', '#6AF9C4'],
  chart: {
    // styledMode: true,
    style: {
      fontFamily: 'SF Pro Display, Bitter,Helvetica Neue,Helvetica,Arial,sans-serif;',
      backgroundColor: 'transparent'
    },
  }
});

highchartsMore(Highcharts)
Annotations(Highcharts)
stockInit(Highcharts)
sankey(Highcharts)
highcharts3d(Highcharts)
HighchartsXrange(Highcharts)
HighchartsTreemap(Highcharts)
HighchartsTreegraph(Highcharts)

Vue.use(HighchartsVue)
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(appComponents)
Vue.use( {
  id: 'UA-38316397-7',
  router
})

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDnkW5sFvqmZPYvrU6-epJ7dwXAbDztzKY',
    libraries: 'places',
  }
});


Vue.component('multiselect', Multiselect)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
