import Subscriptions from "@/js/shared/classes/Subscription/Subscriptions";

const _formatSubscription = Symbol()

export default class ClinicalSubscriptions extends Subscriptions {
    constructor(data) {
        super(data)
        

    }

    static getSubscriptionModel() {
        return {
            startDate: null,
            endDate: null,
            isTrial: null,
            systemFee: null,
            isManualBilling: null,
            profilePrice: null,
            billingCycle: null
        }
    }
    
}