<template>
    <div >

        <v-row v-if="profile && profile.tests.length === 0">
            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%">
                <EmptyState :svg="emptyProfileGraphic" title="No Test Data" description="This profile has not yet taken any tests or established a baseline. Let's get them started."/>
                <app-btn class="mt-4" color="primary" @click="$emit('createSession')">Create Profile Code</app-btn>
            </div>
            
        </v-row>
        <div v-else >
            <div class="mt-6">
                <TestsViewer :profile="profile" @testUpdated="$emit('testUpdated')" />
            </div>

        </div>


    </div>
</template>

<script>
import PdfExport from '@/components/pdf-export/index'
import ProfileTestPdfExportMixin from '@/mixins/profile-test-pdf-export'
import SessionCreateForm from "@/components/Sessions/SessionCreateForm";
import TestsViewer from "@/components/Profile/TestsViewer";
import EmptyState from "@/components/EmptyState";
import emptyProfileGraphic from '@/assets/svgs/empty-state/empty-profile-graphic.svg'

export default {
    name: "ProfileOverview",
    props: {
        profile: {
            required: true
        },
        simpleProfile: {
            required: true // required for PDF export
        },
        // profileTest: {
        //     required: false
        // },
        loadingWebDetails: {
            required: true
        },
    },
    mixins: [ProfileTestPdfExportMixin],
    components: {
        EmptyState,
        TestsViewer,
        SessionCreateForm,
        PdfExport
    },


    data() {
        return {
            emptyProfileGraphic,
            profileTest: null,
        }
    },
    computed: {
    },
    mounted() {

        if (this.profile.tests && this.profile.tests.length > 0) {
            
            this.profileTest = this.profile.tests[2];


        }
    },
    methods: {
    }
}
</script>

<style scoped>
.dot {
    height: 8px; width: 8px; border-radius: 4px; background-color: red;
}

</style>