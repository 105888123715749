import { render, staticRenderFns } from "./TestMetadataDetails.vue?vue&type=template&id=066e0302&scoped=true"
import script from "./TestMetadataDetails.vue?vue&type=script&lang=js"
export * from "./TestMetadataDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066e0302",
  null
  
)

export default component.exports