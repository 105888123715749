<template>
    <div>
        <h4>{{profileTest.formattedTestsData[name].label}}</h4>
        <div class="highcharts-custom-container">
            <highcharts :options="chartOptions"></highcharts>
            <chart-indicator :xPosition="indicatorXPosition" :label="displayScore"></chart-indicator>
        </div>
        
    </div>
    
</template>

<script>

import ProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
import ChartIndicator from "@/components/ChartIndicator";

export default {
    name: "ColumnRangeContainer",
    components: {
        ChartIndicator  
    },
    props: {
        profileTest: {
            type: ProfileTest,
            required: true
        },
        name: {
            required: true,
            type: String
        }
    },
    data() {
        return {
            chartOptions: null,
            indicatorXPosition: 200,
            displayScore: null
        }
    },
    created() {
        let results = this.profileTest.getMeasureResultsByName(this.name);

        this.displayScore = results.timeScore ? results.timeScore.toFixed(0) : results.score.toFixed(0);
        
        this.chartOptions = this.profileTest.getMeasureColumnRangeChartOptionsById({id: this.name, resizeCallback: (indicatorXPosition) => {
            this.indicatorXPosition = indicatorXPosition;
        }});
    }
}
</script>

<style scoped>

</style>