<template>
    <div class="avoid-page-break">
        <div v-for="(measureResult, measureId) in category.measureResults">
            <TestRow class="avoid-page-break" :id="measureId" :profileTest="profileTest" :name="measureResult.label"/>
        </div>
    </div>
  
    
  
  
</template>

<script>
import ProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
import TestRow from "./TestRow";

export default {
  props: {
    profileTest: {
        type: ProfileTest
    },
      category: {
        required: true
      }
  },
  name: "CategoryTestsDetails",
  components: {
    TestRow
  },
  
  
}
</script>

<style scoped>


</style>