<template>
    <app-card hide-header>
        <div class="">
            <v-data-table
                :headers="headers"
                :items="searchResults"
                :options.sync="options"
                :page="tableSettings.pageNumber"
                :hide-default-footer="false"
                :loading="loading"
                class="profiles-users-table"
                id="profiles-users-table"
                @click:row="onRowClick"
                @update:options="updateOptions"

            >
                <template v-slot:top>
                    <table-options-row>
                        <template #search>
                            <div class="pa-3">
                                <v-icon class="ml-2 mr-2">mdi-magnify</v-icon>
                                <input
                                    v-model="tableSettings.searchString"
                                    placeholder="Search"
                                    @input="updateSearchString"
                                >
                            </div>
                            
                        </template>
                    </table-options-row>
                </template>
                <template v-slot:no-data="{items}">
                    <div style="height: 200px; width: 200px; background-color: red">There are no profiles or users that match your search</div>
                </template>
                <!--                <template-->
                <!--                    v-slot:body="{ items, headers }"-->
                <!--                >-->
                <!--                    <table-body -->
                <!--                        :items="items"-->
                <!--                        :headers="headers"-->
                <!--                        @onRowClick="onRowClick($event)"-->
                <!--                    ></table-body>-->
                <!--                </template>-->

                <template v-slot:item.type="{ item }">
          
                        
                        <div style="display: flex; align-items: center">
                            <app-chip 
                                v-if="item.type === 1"
                                color="blue"
                                label
                                class="ml-2"
                            >
                                {{getType(item.type)}}
                            </app-chip>
                            <app-chip
                                v-if="item.type === 4"
                                color="green"
                                label
                                class="ml-2"
                            >
                                {{getAccountType(item.accountTypeId)}}
                            </app-chip>
                        </div>
                    
                    
                    


                </template>

                <template v-slot:item.email="{ item }">
                    {{getEmail(item)}}


                </template>

<!--                <template v-slot:footer="{ props }" >-->
<!--                    <table-footer-->
<!--                        :pagination="props.pagination"-->
<!--                        @previousPage="previousPage(props)"-->
<!--                        @nextPage="nextPage(props)"-->
<!--                        @setPage="setPage($event)"-->
<!--                        @setItemsPerPage="setItemsPerPage($event)"-->

<!--                    ></table-footer>-->
<!--                </template>-->
            </v-data-table>
            <div v-if="searchResults.length === 0 && !loading" class="no-data">
                No profiles or users match your search criteria
            </div>
        </div>



    </app-card>
</template>

<script>
import {masterSearchSuperAdmin} from "@/services/organization.service";
import TableFooter from "@/components/tables/TableFooter";
import TableOptionsRow from "@/components/tables/TableOptionsRow";
import TableHeader from "@/components/tables/TableHeader";
import TableBody from "@/components/tables/TableBody";
import moment from 'moment'

export default {
    name: "SuperAdminProfileUserTable",
    components: {
        TableFooter,
        TableOptionsRow,
        TableHeader,
        TableBody
    },
    data() {
        return {
            totalItems: 200,
            tableSettings: {
                pageNumber: 1,
                rowsPerPage: 10,
                sortByColumn: '',
                sortAscending: false,
                searchString: ''
            },
            searchResults: [],
            loading: false,
            options: {},
            headers: [
                {text: 'Type', value: 'type'},
                {
                    text: 'AccountId',
                    align: 'start',
                    sortable: false,
                    value: 'accountId',
                    class: 'th-border'
                },
                {
                    text: 'ProfileId',
                    align: 'start',
                    sortable: false,
                    value: 'profileId',
                    class: 'th-border'
                },
                {text: 'Name', value: 'title'},
                {text: 'Email', value: 'email'},
                {text: 'Organization Id', value: 'organizationId'},
                {text: 'Organization Name', value: 'organizationName'},


                // { text: 'Last Test Date', value: 'lastTestDate', class: 'text--text' },
                // { text: 'Active/Inactive', value: 'isInactive', class: 'text--text' },
            ],
            typingTimer: null,
            requestNum: 0
        }
    },

    mounted() {
        this.getDataFromApi()
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.totalProfiles / 10)
        },
    },
    methods: {
        getDisplayDate(value) {
            return moment(value).format('MM-DD-YYYY')
        },
        getType(value) {
            if (value === 1)
                return 'Profile'
            if (value === 4)
                return 'User'

            return 'Unknown'
        },
        getAccountType(value) {
            if (value === 1)
                return 'User'
            if (value === 4)
                return 'Covid'
            if (value === 5)
                return 'Patient'

            return 'Unknown'
        },
        getEmail(item) {
            if (item.type === 1) {
                if (item.email) {
                    return item.email + ' ' + '(Covid)'
                }
                return '';
            }

            if (item.type === 4)
                return item.email

            return 'Unknown'
        },
        async onRowClick(item) {
            let x = item;
            //let result = await switchSelectedOrganization(item.organizationId);

            if (item.type === 1) { //profile
                window.open(`${window.location.origin}/profiles/${item.profileId}/overview?orgId=${item.organizationId}`, '_blank')
            } else if (item.type === 4) { //account
                
                //if accountTypeId === 4 or 5 then return profiles link
                if (item.accountTypeId === 4 || item.accountTypeId === 5) {
                    window.open(`${window.location.origin}/profiles/${item.profileId}/overview?orgId=${item.organizationId}`, '_blank')
                } else {
                    window.open(`${window.location.origin}/users/${item.accountId}?orgId=${item.organizationId}`, '_blank')
                }
            }
        },
        // previousPage(props) {
        //     const {pagination} = props;
        //     if (pagination.page === 1) return;
        //
        //     this.tableSettings.pageNumber--;
        //     this.updateOptions()
        // },
        // nextPage(props) {
        //     const {pagination} = props;
        //     if (pagination.page === pagination.pageCount) return;
        //     this.tableSettings.pageNumber++;
        //     this.updateOptions()
        // },
        // setPage(page) {
        //     this.tableSettings.pageNumber = page;
        //     this.updateOptions()
        // },
        // setItemsPerPage(value) {
        //     this.tableSettings.rowsPerPage = value;
        //     this.updateOptions()
        // },

        updateSearchString(event) {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                this.tableSettings.pageNumber = 1;
                this.updateOptions()
                // alert('stopped typing')
            }, 300)
        },
        // updatePageNumber(pageNumber) {
        //     this.updateOptions()
        // },
        async updateOptions() {
            
            if (this.requestNum === 0){
                this.options.page = parseInt(this.$route.query.PageNumber ?? '') || 1;
                this.tableSettings.pageNumber = parseInt(this.$route.query.PageNumber ?? '') || 1;
                this.tableSettings.rowsPerPage = parseInt(this.$route.query.RowsPerPage ?? '') || 10;
                this.options.sortBy[0] = this.$route.query.SortByColumn ?? '';
                this.options.sortDesc[0] = (this.$route.query.SortAscending === 'true') ?? false;
                this.tableSettings.searchString = this.$route.query.SearchString ?? '';
            }
            
            // 
            const {page, itemsPerPage, sortBy, sortDesc, groupBy, groupDesc} = this.options;
            // this.tableSettings.pageNumber = page

            this.requestNum++;
            
            let pageNumber = page;
            if (!pageNumber || pageNumber == 0)
                pageNumber = 1;

            // let rowsPerPage = itemsPerPage;
            // if (!rowsPerPage || rowsPerPage == 0)
            let rowsPerPage = this.tableSettings.rowsPerPage;

            this.loading = true
            const response = await masterSearchSuperAdmin(this.tableSettings.searchString);

            //set query string
            let request = {
                PageNumber: pageNumber,
                RowsPerPage: this.tableSettings.rowsPerPage,
                SortByColumn: sortBy[0],
                SortAscending: sortDesc[0],
                SearchString: this.tableSettings.searchString
            }

            this.$router.push({path: this.$route.path, query: {...request}})
            
            this.loading = false
            // 
            // const {data} = response;
            const {searchResults} = response

            if (!searchResults){
                this.searchResults = [];
                this.totalItems = 0;
                return;
            }
            
            // if (!profiles || !totalRowCount) return;
            this.searchResults = searchResults;
            this.totalItems = searchResults.length;

            
        },
        async getDataFromApi() {
            //console.log('Called getDataFromAPI')
            this.loading = true;

            const response = await masterSearchSuperAdmin(this.tableSettings.searchString);
            this.loading = false
            const {searchResults} = response;
            // if (!profiles || !totalRowCount) return;
            
            if (!searchResults){
                this.searchResults = [];
                this.totalItems = 0;
                return;
            }
            
            this.searchResults = searchResults;
            this.totalItems = searchResults.length;

        },
        isEmpty(str) {
            return (!str || str.length === 0);
        }
    },
}
</script>

<style scoped>
.profiles-table {
    /*color: var(--v-text-base)*/
}

/*.profiles-table >>> tbody tr :hover {*/
/*  cursor: pointer;*/
/*  background-color: var(--v-accent-base);*/
/*}*/

/*.th-border {*/
/*    border: 10px solid red!important*/
/*}*/
/*.sortable {*/
/*    border: 10px solid red!important*/
/*}*/
/*.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {*/
/*    border: 10px solid red!important*/
/*}*/

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input {
    /*height: 40px*/
    width: 50%;
    /*height: 100%*/
}

.table-row:hover {
    background-color: var(--v-accent-base)!important;
    cursor: pointer
}
.table-row {
    background-color: white;
}

.search-input-area {
    border-radius: 15px;
    /*background-color: grey;*/
    height: 0px

}

.no-data {
    color: var(--v-text-base);
    margin: 20px
}

/*thead {*/
/*  background-color: red!important;*/
/*}*/

/*#profiles-table table thead tr th:nth-child(1) {*/
/*  background: green;*/
/*}*/
/*#profiles-table table thead tr th:nth-child(2) {*/
/*  background: red;*/
/*}*/

/*.v-data-table >>> thead {*/
/*    background-color: var(--v-text-base)!important;*/
/*    border: 10px solid red!important*/
/*}*/

.v-data-table >>> thead >>> tr >>> th {
    color: white;
    border: 1px solid red!important
}



path {
    color: red!important
}
</style>