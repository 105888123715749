<template>
    <div>
        <AppCard title="Test History" v-if="profile.hasAtLeastOneNonCovid" >
            <div style="padding: 10px">
                <ProfileTestHistoryTable :profile="profile" style="background-color: white" hide-title />
            </div>
            
        </AppCard>
        <div v-else>
            <EmptyState title="No History" :svg="emptyProfileGraphic" description="This profile does not have any test history to show in table format. COVID-19 Screening are not included in test history tables." />
        </div>
        
    </div>
</template>

<script>
import ProfileTestHistoryTable from "@/components/pdf-export/ProfileTestHistoryTable";
import EmptyState from "@/components/EmptyState";
import emptyProfileGraphic from '@/assets/svgs/empty-state/empty-profile-graphic.svg'

export default {
    name: "ProfileTestHistory",
    props: {
        profile: {
            required: true
        }
    },
    components: {
        ProfileTestHistoryTable,
        EmptyState
    },
    data() {
        return {
            emptyProfileGraphic
        }
    }
}
</script>

<style scoped>

</style>