<template>
    <div style="max-width: 890px; min-height: 968px; background-color: white">
        <div v-if="currentInvoice && billingInfo">
            <InvoicePdf
                :currentInvoice="currentInvoice"
                :billingInfo="billingInfo"
                :isFullpage="true"
            ></InvoicePdf>
        </div>
        <div v-else>
            Loading...
        </div>
    </div>
</template>

<script>
import {getInvoiceForAzureAD, getInvoiceForPortal} from '@/services/billing.service'
import InvoicePdf from "@/components/invoice-pdf/InvoicePdf";
import {mapInvoiceToDisplay} from "@/js/shared/helper";

export default {
  components: {InvoicePdf},
    name: "FullpageInvoice",
    props: {

    },
    data() {
        return {
            currentInvoice: null,
            billingInfo: null
        }
    },
    mounted() {
        this.getInvoice()
        // this.getInvoiceLocal()
    },
    computed: {

    },
    methods: {
        async getInvoice() {
            if (!this.currentInvoice){
                const {invoiceId, authToken, isPortal, orgId} = this.$route.query
                let response = isPortal ?  await getInvoiceForPortal(invoiceId, authToken, orgId) : await getInvoiceForAzureAD(invoiceId, authToken);
                if (response.isSuccess && response.invoice)
                    this.currentInvoice = mapInvoiceToDisplay(response.invoice);
                if (response.isSuccess && response.organization)
                    this.billingInfo = response.organization;
            }
        },
        async getInvoiceLocal() {
            
            //TEST URL ONLY http://localhost:8080/invoice-fullpage?isPortal=true&invoiceId=7708&authToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImpiQHN3YXltZWRpY2FsLmNvbSIsInVuaXF1ZV9uYW1lIjoiSm9yZGFuIEJlYWNoYW0iLCJBY2NvdW50SWQiOiIxNDQ0NiIsIlNlbGVjdGVkT3JnYW5pemF0aW9uSWQiOiI2MTI3IiwiSXNTdXBlckFkbWluIjoiVHJ1ZSIsIlNlbGVjdGVkT3JnYW5pemF0aW9uQWNjZXNzIjoiMiIsIlZpZXdpbmdBc1N1cGVyIjoiRmFsc2UiLCJuYmYiOjE2MjIwMzUyMTIsImV4cCI6MTYyMjEyMTYxMiwiaWF0IjoxNjIyMDM1MjEyfQ._BZ6jRikF8I2dcQ6yAnFijKIKVFxRAuu9jzwQ8DAdAI

            let {invoiceId, authToken, orgId} = this.$route.query;
            let response = await getInvoiceForPortal(invoiceId, authToken, orgId);
            const {invoice, organization} = response;
            this.currentInvoice = invoice;
            this.billingInfo = organization
            // 
        }
    },
}
</script>

<style scoped>
/* TODO: add custom pdf download stuff */
</style>