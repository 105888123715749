<template>
    <div v-if="isParentOrIndvidual">
        <router-view myTestProp="this is a test prop" :account="storeAccount" @setAccount="$emit('setAccount', $event)"/>
    </div>
    <div v-else style="overflow-y: hidden; overflow-x: hidden; ">

        <div v-if="storeAccount && storeAccount.viewingAsSuper" 
             style="width: 100%; background-color: #e3f2fd; height: 40px; display: flex; justify-content: center; align-items: center; border-bottom: 1px solid var(--main-border-color)">
            <div style="flex: 1; justify-content: center; display: flex; color: #757575">
	            🧑‍🚀 Viewing <span style="font-weight: 600; color: black" class="ml-2 mr-2">{{storeAccount.selectedOrganization.name}}</span> as Internal Sway Admin
	            <AppChip color="red" small class="ml-2" v-if="isDisabledOrganization">Disabled Organization {{disabledDateString}}</AppChip>
            </div>
            
            <v-menu
                offset-y
                nudge-bottom="0"
                nudge-left="20"
                :left="true"
                open-on-hover
                v-if="storeAccount.availableOrganizations"

            >
                <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs" style="font-weight: bold; color: black" class="pr-2">
                        EXIT
                    </div>
                </template>

                <app-card
                    class="mx-auto"
                    tile
                    :min-width="300"
                    hide-header
                >
                    <v-divider></v-divider>


                    <v-list v-if="storeAccount.availableOrganizations">
                        <v-list-item-group>
                            <v-list-item
                                v-for="(item, i) in storeAccount.availableOrganizations"
                                :key="i"
                                @click="changeOrganization(item)"
                                class="account-dropdown-list-item"
                                link
                            >

                                <v-list-item-content >
                                    <v-list-item-title v-text="item.name"
                                                       class="list-item-text"></v-list-item-title>

                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-divider></v-divider>
                </app-card>

            </v-menu>
            <div v-else style="margin-right: 20px; font-weight: bold; color: black" @click="changeOrganization(storeAccount.selectedOrganization)">
                EXIT
            </div>
        </div>
	    
        <div v-else-if="storeAccount && storeAccount.isEnterpriseAdminOrg"
             style="width: 100%; background-color: #34d3fe; height: 40px; display: flex; justify-content: center; align-items: center">
            <div style="flex: 1; justify-content: center; display: flex">
                Viewing <span style="color: #fafafa; font-weight: 600" class="ml-2 mr-2">{{storeAccount.selectedOrganization.name}}</span> as Management Admin
            </div>
        </div>
	    <div v-if="isAccountPastDue"
	         style="width: 100%; background-color: #fdf8f1; height: 70px; padding: 1rem; display: flex; align-items: center; border-bottom: 1px solid var(--main-border-color)">
		    <div style="font-weight: bold">
			    Your account is past due
		    </div>
		    
		    <div style="padding: 0 1rem; color: #222222">
			    To avoid issues accessing and using your account, please make a payment. If payment has already been submitted, please be aware that processing may take a few days. 
			    <br />
			    
			    <div style="font-size: .8em">If you have any questions, please reach out to <a href="mailto:billing@swaymedical.com" style="color: blue">billing@swaymedical.com</a> or call 855-792-9633 ext3.</div>
		    </div>
		    <div style="flex: 1"></div>
		    <AppBtn small @click="$router.push(`/billing/billing-history`)">View Invoices</AppBtn>
	
	
	    </div>
        <div id="app" v-if="storeAccount">

            <div :class="sidebarClass">
<!--                <SidebarLeft :company="company"/>-->
                <SidebarLeft :company="company"/>
            </div>
            <div>
                <div class="top-bar">
<!--                    <navbar-top  />-->
                    <NavbarTop />
                </div>
                <div>
                    
                </div>
                
                <div :class="mainContentClass" >
                    <app-breadcrumbs :path="routePath"></app-breadcrumbs>
                    <router-view myTestProp="this is a test prop" :account="storeAccount" @setAccount="$emit('setAccount', $event)"/>
                </div>
            </div>
            <!--      <div class="theme-picker">-->
            <!--        <v-radio-group-->
            <!--            v-model="company"-->
            <!--            column-->
            <!--            @change="onCompanyChange"-->
            <!--        >-->
            <!--          <v-radio label="Sway" value="sway"></v-radio>-->
            <!--          <v-radio label="ReadyCheck" value="readyCheck"></v-radio>-->
            <!--        </v-radio-group>-->
            <!--        <v-radio-group-->
            <!--          v-model="theme"-->
            <!--          column-->
            <!--          @change="onLightDarkChange"-->
            <!--          -->
            <!--        >-->
            <!--          <v-radio label="Light" value="light"></v-radio>-->
            <!--          <v-radio label="Dark" value="dark"></v-radio>-->
            <!--        </v-radio-group>-->
            <!--      </div>-->
        </div>

    </div>
</template>

<script>

// import NavbarTop from "@/components/NavbarTop";
import {isSuperView, getUser, switchSelectedOrganization, isPatient} from '@/services/userService'
// import NavbarTop from "@/components/NavbarTop";
import Highcharts from 'highcharts'
import {swayThemes, readyCheckThemes} from "@/config/themes"
// import SidebarLeft from "@/components/SidebarLeft";
import SidebarLeft from "@/components/SidebarLeft";
import NavbarTop from "@/components/NavbarTop";
import {getInvoices} from "@/services/billing.service";
import {isAdminOrgAccessType} from "@/js/shared/helper";
import moment from "moment";

export default {
    name: 'DashboardLayout',
    // props: {
    //     account: Object
    // },
    components: {
        NavbarTop,
        SidebarLeft,
        // SidebarLeft,
        // NavbarTop,

    },
    data: () => ({
        company: 'sway',
        theme: 'light',
        // isSuperView: false,
	    isAccountPastDue: false,
    }),
    created() {
        
    },
	mounted() {
        if (!this.isParentOrIndvidual) 
		    this.checkIfPastDueInvoice()
	},
	computed: {
        routePath() {
            return this.$route.path
        },
        storeAccount() {
            return this.$store.state.currentAccount
        },
        isDisabledOrganization() {
            return this.$store.state.currentOrganization?.organization?.isDisabled ? this.$store.state.currentOrganization?.organization?.isDisabled : false
        },
        disabledDateString() {
            return this.$store.state.currentOrganization?.organization?.disabledDate ? '(' + moment(this.$store.state.currentOrganization?.organization?.disabledDate).format("M/D/YYYY") + ')' : ""
        },
        isSuperView() {
            return isSuperView();
        },
        isParentOrIndvidual() {
            return isPatient();
        },
        sidebarClass(){
            return 'sidebar-container ' +
                    (this.isSuperView && this.isAccountPastDue ? 'sidebar-container-super-pastdue' :
                    this.isSuperView ? 'sidebar-container-super' : 
                    this.isAccountPastDue ? 'sidebar-container-pastdue' : 'sidebar-container-normal');
        },
        mainContentClass(){
            return 'main-content ' +
                    (this.isSuperView && this.isAccountPastDue ? 'main-content-super-pastdue' :
                    this.isSuperView ? 'main-content-super' :
                    this.isAccountPastDue ? 'main-content-pastdue' : 'main-content-normal');
        }
    },
    // watch: {
    //     $store.state.currentAccount() {
    //        
    //     }       
    // },
    methods: {
        onCompanyChange(e) {
            this.company = e;
            this.setTheme()
        },
        onLightDarkChange() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        },
        setTheme() {
            let newTheme = this.company === 'sway' ? swayThemes : readyCheckThemes;

            let darkKeys = Object.keys(newTheme.dark)
            darkKeys.forEach(key => {
                this.$vuetify.theme.themes.dark[key] = newTheme.dark[key]
            })
            let lightKeys = Object.keys(newTheme.light)
            lightKeys.forEach(key => {
                this.$vuetify.theme.themes.light[key] = newTheme.light[key]
            });

        },
	    async checkIfPastDueInvoice() {
            if (this.isParentOrIndvidual || !this.storeAccount) return

            if (isAdminOrgAccessType(this.storeAccount.selectedOrganizationAccessTypeId) || this.storeAccount.isSuperAdmin || this.storeAccount.isBillingContact){
                // Show banner on dashboard if the organization has a past due invoice
                let response = await getInvoices();
                let isAccountPastDue = false
                if (response.isSuccess) {
                    response.invoices.forEach(x => x.isOverdue ? isAccountPastDue = true : null)
                }
                this.isAccountPastDue = isAccountPastDue
            }
		},
        async changeOrganization(organization) {
            await switchSelectedOrganization(organization.id);
            await this.$router.push(`/dashboard?orgId=${organization.id}`);
            location.reload();
            // await this.$router.push('/dashboard');

        },

    },
};
</script>
<style scoped>
#app {
    /* font-family: proxima-nova,Helvetica Neue,Helvetica,Arial,sans-serif; */
    display: flex;
}

body {
    overflow: hidden; /* Hide scrollbars */
}

.sidebar-container {
    position: -webkit-sticky;
    position: sticky;
    width: 240px
}

.sidebar-container-normal {
    height: 100vh;
}

.sidebar-container-super {
    height: calc(100vh - 40px);
}

.sidebar-container-super-pastdue {
    height: calc(100vh - 40px - 70px);
}

.sidebar-container-pastdue {
    height: calc(100vh - 70px);
}

.main-content {
    width: calc(100vw - 240px);
    padding: 30px 50px;
    overflow-y: scroll;
}

.main-content-normal {
    height: calc(100vh - 80px);
}

.main-content-super {
    height: calc(100vh - 80px - 40px);
}

.main-content-super-pastdue {
    height: calc(100vh - 80px - 40px - 70px);
}

.main-content-pastdue {
    height: calc(100vh - 80px - 70px);
}

.main-content-super {
    height: calc(100vh - 80px - 40px);
}

/* .main-content::-webkit-scrollbar-track {
  background: rgb(255, 0, 0);
} */

.top-bar {
    height: 80px;
    width: calc(100vw - 240px)
}

.theme-picker {
    position: fixed;
    bottom: 10px;
    right: 20px;
    /*height: 20px;*/
    /*width: 20px;*/
    /*background-color: red;*/
}
</style>
