<template>
    <div class="chart-indicator-container" :style="{ 'left': xPosition - 10 + 'px'}">
        <div class="chart-indicator-top-half">
            {{ label }}
        </div>
        

        <div class="chart-indicator-bottom-arrow" style="">
            <!--      <?xml version="1.0" encoding="UTF-8"?>-->
<!--            <svg width="40px" height="11px" viewBox="0 0 40 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">-->
<!--                <title>Path</title>-->
<!--                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">-->
<!--                    <g id="Artboard" fill="#1E242C">-->
<!--                        <polygon id="Path" points="3.74861685e-14 0 40 0 40 1 20 11 0 1"></polygon>-->
<!--                    </g>-->
<!--                </g>-->
<!--            </svg>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "ChartIndicator",
    props: {
        label: {required:true},
        xPosition: {required: true, type: Number}
    }
}
</script>

<style scoped>
/*.chart-indicator-container {*/
/*    position: absolute;*/
/*    */
/*    !*height: 25px;*!*/
/*    width: 40px;*/
/*    */
/*    top: -10px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*}*/

/*.chart-indicator-top-half {*/
/*    border-top-right-radius: 5px;*/
/*    border-top-left-radius: 5px;*/
/*    background-color: #1E242C;*/
/*    color: white;*/
/*    font-weight: 400;*/
/*    font-size: 16px;*/
/*    text-shadow: none;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    text-align: center;*/
/*    padding-top: 5px;*/
/*}*/

/*.chart-indicator-bottom-arrow {*/
/*    !*position: absolute;*!*/
/*    height: 11px;*/
/*    width: 40px;*/
/*    transform: translateY(-1px);*/
/*    background-color: #1E242C;*/
/*    !*bottom: -5px;*!*/
/*    clip-path: polygon(100% 0, 100% 10%, 50% 100%, 0 10%, 0 0);*/

/*}*/

/*!*-10px for viewing in portal and printing from mobile, -7px for printing for portal*!*/
/*@media print {*/
/*    .chart-indicator-container {*/
/*        !*top: -7px *!*/
/*    }*/
/*}*/
</style>