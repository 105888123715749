<template>
  <v-tab  @click="$emit('click')" :ripple="false" class="tab" active-class="active">
      <v-icon v-if="icon" class="pr-1" small >{{icon}}</v-icon>
      <slot/>
  </v-tab>
</template>

<script>
export default {
  name: "AppTab",
    props: {
      icon: {
          type: String
      }
    },
    methods: {
        doSomething() {
            // alert('I did something')
        }
    }
}
</script>

<style>
.tab {
  /*border-radius: 10px;*/
    font-size: 14px;
    font-weight: 400;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    overflow: hidden;
    padding: 4px 8px;

}
.v-tab:hover {
    background-color: transparent;
    /*font-weight: bold*/
}

/*.v-tab::before {*/
/*  background-color: transparent;*/
/*  */
/*}*/

/*.v-tab--active {*/
/*  !*background-color: var(--v-secondary-base)!important;*!*/
/*  !*  font-weight: 700;*!*/
/*    color: red!important*/
/*    !*color: white*!*/
/*}*/

.active {
    /*color: yellow!important*/
    
}
.v-tab {
    text-transform: none!important;
    color: var(--v-text-lighten3)!important;
}

.v-tab .v-icon {
    color: var(--v-dark-base);
}

.v-tab--active {
    color: var(--v-dark-base)!important;
    font-weight: 600;
}


.v-tab:not(.v-tab--active) .v-icon {
    color: var(--v-text-lighten3)!important;
}

.v-tab:not(.v-tab--active) .v-icon {
    color: var(--v-text-lighten3)!important;
}
</style>