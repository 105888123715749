<template>
    <div >
<!--        <div class="section-title" >Profile Trends</div>-->
        <div 
            v-for="categoryId in profile.categoryIdsTaken" 
            class="pdf-avoid-page-break" 
            v-if="!measuresCategoriesById[categoryId].trendsAreNotChartable"
        >
            <div style="width: 100%; background-color: #F4F4F4; display: flex; padding: 5px 10px; margin-bottom: 10px">
                <div style="font-weight: 800; text-transform: uppercase; color: #898C90; flex: 1">
                    {{measuresCategoriesById[categoryId].fullLabel ? measuresCategoriesById[categoryId].fullLabel : measuresCategoriesById[categoryId].label}}
                </div>

            </div>
            <ProfileAttentionTrends  :profile="profile" :category-id="categoryId"/>
            
        </div>
        
    </div>
</template>

<script>
import ProfileAttentionTrends from "@/components/Profile/ProfileAttentionTrends";
import {measuresCategoriesById} from "@/js/shared/constants";

export default {
    name: "ProfileTrends",
    components: {ProfileAttentionTrends},
    props: {
        profile: {
            required: true
        }
    },
    data() {
        return {
            measuresCategoriesById,
        }
    }
}
</script>

<style scoped>

</style>