<template>
    <div>
        <div style="display: flex; align-items: center">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="7" cy="7" r="7" fill="#EF465E"/>
                <path d="M7.1853 8.17765L7.40765 2.47059H5.82647L6.06118 8.17765H7.1853ZM6.62942 10.8335C7.09883 10.8335 7.48177 10.4382 7.48177 9.96882C7.48177 9.51177 7.09883 9.11647 6.62942 9.11647C6.16 9.11647 5.76471 9.51177 5.76471 9.96882C5.76471 10.4382 6.16 10.8335 6.62942 10.8335Z" fill="white"/>
            </svg>
            <div style="margin-left: 5px">
                Fall Risk
            </div>

        </div>
        <div v-if="showDetails">
            This result was flagged for indicating a high risk of fall.
        </div>
    </div>
</template>

<script>
export default {
    name: "FallRiskTag",
    props: {
        showDetails: {
            type: Boolean
        }
    }
}
</script>

<style scoped>

</style>