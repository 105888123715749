import colors from "@/js/shared/colors";
import {
    allMeasuresFromConstants,
    measuresCategoriesById,
    isPortal,
    eventTypes,
    packageTiers
} from "@/js/shared/constants";
import moment from "moment";
import 'moment-timezone'

export function asLocalTime(date){

    //get current query string parameters
    let urlParams = new URLSearchParams(window.location.search);
    
    //get timezone offset from query string if exists
    let timezoneOffset = urlParams.get('timezoneOffset');

    if (timezoneOffset){
        let res = date.utcOffset(parseInt(timezoneOffset));
        return res;
    }
    
    let timezone = guessCurrentTimezone();
    
    let res = date.tz(timezone);
    return res;
}

export function guessCurrentTimezone(){
    
    //get current query string parameters
    let urlParams = new URLSearchParams(window.location.search);

    //get timezone from query string if exists
    let timezone = urlParams.get('timezone');

    //if timezone is not in query string, get it from browser
    if (!timezone) {
        timezone = moment.tz.guess(true);
    }
    
    return timezone;
}

function getCiColor(variabilityType) {
    switch (variabilityType) {
        case 1:
            return colors.chart.ciGreenFill;
        case 2:
            return colors.chart.ciYellowFill;
        default:
            return colors.chart.ciRedFill;
    }
}

function getCiColorForChart(variabilityType) {
    switch (variabilityType) {
        case 1:
            return colors.chart.ciGreenFillChart;
        case 2:
            return colors.chart.ciYellowFillChart;
        default:
            return colors.chart.ciRedFillChart;
    }
}

function getVariabilityFromType(variabilityType) {
    switch (variabilityType) {
        case 1:
            return "Low";
        case 2:
            return "Moderate";
        default:
            return "High";
    }
}

function getBaselineConsistencyFromVariabilityType(variabilityType) {
    switch (variabilityType) {
        case 1:
            return "High";
        case 2:
            return "Moderate";
        default:
            return "Low";
    }
}

function getTestChangeColor({percentChange, measureId, scoreChange}) {
    
    if (measureId && measureId == 1004) {
        
    }
    
    // percent change should be on a 0-1 scale, not 0-100
    if (percentChange > -.1) {
        return 'percent-change-normal'
    } else if (percentChange > -.15) {
        return 'percent-change-warning'
    } else {
        return 'percent-change-bad'
    }
}

function toFixedNullable(value, decimalPlaces) {
    if (!value)
        return "0.00";
    else
        return value.toFixed(decimalPlaces)
}

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        if (!amount)
            return "$0.00";
        
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + '$' + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

function formatNumber(x) {
    if (!x) return null
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function formatDate(date, format = "M/D/YYYY") {
    return moment(date).format(format)
}

let getPackageTierById = (id) => {
    if (!id) return null
    return packageTiers.find(x => x.value === id)?.text;
}

function getCodeTypeId({isGroup, isNewProfile, isScreening}) {
    // MassBaselineSession = 1, //old baseline session
    // SymptomSession = 2, // unused
    // AtHomeTestSession = 3, //SINGLE TEST - assigned to existing profile
    // SymptomSessionNewProfile = 4, // unused
    // AtHomeTestSessionNewProfile = 5, //SINGLE TEST - NEW PROFILE CREATION
    // Covid19SurveySession = 6, // also includes a few Baseline Session tests with early expedited implementation 
    // ReadyCheck3TestSession = 7, // ReadyCheck only
    // MassExpeditedBaseline = 8, // NEW baseline session - GROUP
    // IndividualExpeditedBaseline = 9, // NEW baseline session - EXISTING profile
    // FakeExpeditedBaseline = 10, // used when they take a "session" while logged in
    // IndividualExpeditedBaselineNewProfile = 11 // NEW baseline session - NEW PROFILE CREATION
    // GroupSingleTest = 12 // Group Screening Code
    
    let response;

    if (isScreening) {
        if (isGroup) {
            response = 12
        } else if (isNewProfile) {
            response = 5
        } else {
            response = 3
        }
    } else {
        if (isGroup) {
            response = 8
        } else if (isNewProfile) {
            response = 11
        } else {
            response = 9
        }
    }
    
    return response
    
}

function getSessionDetails(session, isSuperAdmin) {


    // MassBaselineSession = 1, //old baseline session
    // SymptomSession = 2, // unused
    // AtHomeTestSession = 3, //SINGLE TEST - assigned to existing profile
    // SymptomSessionNewProfile = 4, // unused
    // AtHomeTestSessionNewProfile = 5, //SINGLE TEST - NEW PROFILE CREATION
    // Covid19SurveySession = 6, // also includes a few Baseline Session tests with early expedited implementation 
    // ReadyCheck3TestSession = 7, // ReadyCheck only
    // MassExpeditedBaseline = 8, // NEW baseline session - GROUP
    // IndividualExpeditedBaseline = 9, // NEW baseline session - EXISTING profile
    // FakeExpeditedBaseline = 10, // used when they take a "session" while logged in
    // IndividualExpeditedBaselineNewProfile = 11 // NEW baseline session - NEW PROFILE CREATION
    // GroupSingleTest = 12 // Group Screening Code
    // 
    // 

    let type, typeDetailed, displayName;
    let isProfileSession = false;
    let isNewProfile = false
    let isTest = false;
    switch (session.baselineSessionTypeId) {
        case 1:
            typeDetailed = 'Group Baseline';
            type = 'Assessment';
            displayName = session.groupName;
            break;
        case 2:
            typeDetailed = 'Symptoms';
            type = 'Screening';
            displayName = session.groupName;
            break;
        case 3:
            typeDetailed = session.isNonBaseline ? 'Profile Event Screening' : 'Profile Baseline Screening';
            type = 'Screening';
            displayName = isSuperAdmin ? session.profileId : session.profileName;
            isProfileSession = true
            isTest = true
            break;
        case 4:
            typeDetailed = 'Symptoms New Profile';
            type = 'Screening';
            displayName = `+ New Profile`;
            isProfileSession = true;
            isNewProfile = true
            break;
        case 5:
            typeDetailed = session.isNonBaseline ? 'Profile Event Screening - New Profile' : 'Profile Baseline Screening - New Profile';
            type = 'Screening';
            displayName = `+ New Profile`;
            isProfileSession = true;
            isTest = true;
            isNewProfile = true
            break;
        case 6:
            typeDetailed = 'COVID-19 Survey';
            type = 'Screening';
            displayName = session.groupName;
            break
        case 7:
            typeDetailed = 'ReadyCheck Baseline';
            type = 'Screening';
            break;
        case 8:
            typeDetailed = session.isNonBaseline ? 'Group Event Assessment' : 'Group Baseline Assessment';
            type = 'Assessment';
            displayName = session.groupName;
            break;
        case 9:
            typeDetailed = session.isNonBaseline ? 'Profile Event Assessment' : 'Profile Baseline Assessment';
            type = 'Assessment';
            displayName = isSuperAdmin ? session.profileId : session.profileName;
            isProfileSession = true;
            break;
        case 10:
            typeDetailed = session.isNonBaseline ? 'Profile Event Assessment' : 'Profile Baseline Assessment';
            type = 'Assessment';
            displayName = isSuperAdmin ? session.profileId : session.profileName;
            break;
        case 11:
            typeDetailed = session.isNonBaseline ? 'Profile Event Assessment - New Profile' : 'Profile Baseline Assessment - New Profile';
            type = 'Assessment';
            displayName = `+ New Profile`;
            isProfileSession = true;
            isNewProfile = true
            break;
        case 12:
            typeDetailed = session.isNonBaseline ? 'Group Event Screening' : 'Group Baseline Screening';
            type = 'Screening';
            displayName = session.groupName;
            isTest = true
            break;
        default:
            typeDetailed = 'N/A'
    }


    //
    // let group = groups.find(x => x.id === session.groupId)
    // name = `${group.name}`
    // isProfile = false
    //
    // if (session.profileId) {
    //     let profile = profiles.find(x => x.id === session.profileId);
    //     if (profile) {
    //         name = `${profile.firstName} ${profile.lastName}`
    //         isProfile = true
    //     } else {
    //         name = '+ New Profile'
    //     }
    //
    // }

    session.displayName = displayName;
    session.isProfileSession = isProfileSession;
    session.type = (session.isNonBaseline ? '' : 'Baseline ') + type;

    // 

    let startsOn = moment.utc(session.startsOn);
    let endsOn = moment.utc(session.endsOn)
    let createdOn = moment.utc(session.createdOn)

    session.startsOnFormatted = asLocalTime(startsOn).format('M/D/YY h:mm a z')
    session.endsOnFormatted = asLocalTime(endsOn).format('M/D/YY @ h:mm a z')
    session.createdOnFormatted = asLocalTime(createdOn).format('M/D/YY h:mm a z')
    session.isActive = startsOn ? asLocalTime(moment()).isBetween(startsOn, endsOn) : asLocalTime(moment()).isBefore(endsOn)
    session.isTest = isTest
    session.isScreening = isTest
    session.isNewProfile = isNewProfile

    if (session.protocolItems) {
        session.protocolItems.forEach(item => {
            let label = allMeasuresFromConstants[item.regulationMeasureId].label
            
            if (!allMeasuresFromConstants[item.regulationMeasureId]) {
                item.label = "";
                return;
            }
            
            item.label = label
        })
    }


    return session
}

function getColumnRangeOptions({series, resizeCallback, disabledAnimation = false, reversed,}) {

}

function getMeasuresAsArrayByCategoryId(categoryId) {
    let response = [];
    let measureIds = Object.keys(allMeasuresFromConstants);
    measureIds.forEach(id => {
        if (!categoryId) return response.push({...allMeasuresFromConstants[id], measureId: id})

        if (allMeasuresFromConstants[id].category == categoryId) response.push({...allMeasuresFromConstants[id], measureId: id})
    });

    return response

}

function getCountdownDisplayTime(date) {
    let differenceInSeconds = moment(date).diff(moment(), 'seconds');
    let days = Math.floor(differenceInSeconds / 86400);
    let timeLeft = differenceInSeconds - (days * 86400);
    let hours = Math.floor(timeLeft / 3600);
    timeLeft = timeLeft - (hours * 3600);
    let minutes = Math.floor(timeLeft / 60);
    timeLeft = timeLeft - (minutes * 60)
    let seconds = timeLeft

    return {
        days,
        hours,
        minutes,
        seconds
    }
}

function getOrdinalSuffix(n) {

    if (!n) return null
    n = parseInt(n)
    let s = ["th", "st", "nd", "rd"];
    let v = n%100;
    let value = (s[(v-20)%10] || s[v] || s[0])
    return value
}

function inchesToCentimeters(inches) {
    return (inches * 2.54).toFixed(0)
}

function poundsToKg(pounds) {
    return (pounds * 0.453592).toFixed(0)
}

function isAdminOrgAccessType(accessTypeId) {
    return accessTypeId === 2 || accessTypeId === 4;
}

function isCovidAccount(accountTypeId) {
    return accountTypeId === 4;
}

function isPatientAccount(accountTypeId) {
    return accountTypeId === 5;
}

function getTestTypesByCategories({stages = null, version = null}) {

    let totalTestTypes = 0;
    const measureCategoriesByIdCopy = JSON.parse(JSON.stringify(measuresCategoriesById))

    for (let categoryId in measureCategoriesByIdCopy) {
        measureCategoriesByIdCopy[categoryId].testTypes = []
    }

    for (let measureId in allMeasuresFromConstants) {
        let measure = allMeasuresFromConstants[measureId]
        
        if(!measure)
            continue;
        
        if (!measure?.isProtocol && !measure?.isInactive) {
            if (version && stages) {

                let currentStage = measure.versions ? measure.versions.find(x => x.number === version)?.stage : 4;

                if (stages.includes(currentStage)) {
                    measureCategoriesByIdCopy[measure.category].testTypes.push(measure);
                    totalTestTypes++
                }
            } else {
                measureCategoriesByIdCopy[measure.category].testTypes.push(measure);
                totalTestTypes++
            }

        }

    }

    return {testTypesByCategories: measureCategoriesByIdCopy, totalTestTypes}
}

function getStroopDetailsChart(measureResults) {
    let something = measureResults;


    let response = {
        totalErrors: [],
        totalTime: [],
        averageTime: [],
        congruent: {
            word: {
                averageTime: [],
                errors: [],
            },
            color: {
                averageTime: [],
                errors: [],
            }
        },
        incongruent: {
            word: {
                averageTime: [],
                errors: [],
            },
            color: {
                averageTime: [],
                errors: [],
            }
        },
        neutral: {
            word: {
                averageTime: [],
                errors: [],
            },
            color: {
                averageTime: [],
                errors: [],
            }
        },
    };
    
   

    let wordColor = '#0000ff';
    let wordExcludedColor = 'rgba(0,0,255,.2)';
    let colorColor = '#34d3fe';
    let colorExcludedColor = 'rgba(52,211,253,.2)';



        measureResults.results.forEach((result, resultIndex) => {
        let {detailRows, isExcluded} = result;
        
        detailRows.forEach(row => {
            let {key, value, isFlagged} = row;
            key = key.toLowerCase();
            let isWord = false;
            let isColor = false;

            let isCongruent = false;
            let isNeutral = false;
            let isIncongruent = false;

            let isFatigue = false;

            let isError = false;
            let isTime = false;

            if (key.includes('word')) isWord = true
            if (key.includes('color')) isColor = true
            if (key.includes('congruent') && !key.includes('incongruent')) isCongruent = true
            if (key.includes('neutral')) isNeutral = true
            if (key.includes('incongruent')) isIncongruent = true
            if (key.includes('fatigue')) isFatigue = true
            if (key.includes('error')) isError = true

            // if (isWord || isColor) {
            //     if (isCongruent) {
            //         if (!isError) {
            //             response.word.congruent.averageTime = parseInt(value)
            //         } else {
            //             response.word.congruent.errors = parseInt(value)
            //         }
            //     } else if (isNeutral) {
            //        
            //     }
            // }

            if (key === "errors") response.totalErrors.push(parseFloat(value))
            if (key === "total time") response.totalTime.push(parseFloat(value))
            if (key === "average reaction time") response.averageTime.push(parseFloat(value))
            
            // 
            if (isWord || isColor) {
                response
                    [isCongruent ? 'congruent' : isNeutral ? 'neutral' : 'incongruent']
                    [isWord ? 'word' : 'color']
                    [isError ? 'errors' : 'averageTime']
                    [resultIndex]
                    = typeof value === 'number' ? value : parseFloat(value)

            }



        });
    })

    let series;
    

    if (measureResults.hasDuplicateTests) {
        series = [];
        let numberOfTests = measureResults.results.length;
        for (let i = 0; i < numberOfTests; i++) {
            const isExcluded = measureResults.results[i].isExcluded
            series.push({
                name: `Word Test #${i + 1}`,
                data: [
                    {
                        y: response.congruent.word.averageTime[i],
                        errors: response.congruent.word.errors[i],
                        isDotExcluded: isExcluded
                    },
                    {
                        y: response.neutral.word.averageTime[i],
                        errors: response.neutral.word.errors[i],
                        isDotExcluded: isExcluded
                    },
                    {
                        y: response.incongruent.word.averageTime[i],
                        errors: response.incongruent.word.errors[i],
                        isDotExcluded: isExcluded
                    },
                ],
                color: isExcluded ? wordExcludedColor : wordColor
            })
        }
        for (let i = 0; i < numberOfTests; i++) {
            const isDotExcluded = measureResults.results[i].isExcluded
            series.push({
                name: `Color Test #${i + 1}`,
                data: [
                    {
                        y: response.congruent.color.averageTime[i],
                        errors: response.congruent.color.errors[i],
                        isDotExcluded: isDotExcluded
                    }, 
                    {
                        y: response.neutral.color.averageTime[i],
                        errors: response.neutral.color.errors[i],
                        isDotExcluded: isDotExcluded
                    },
                    {
                        y: response.incongruent.color.averageTime[i],
                        errors: response.incongruent.color.errors[i],
                        isDotExcluded: isDotExcluded
                    },
                ],
                color: isDotExcluded ? colorExcludedColor : colorColor
            })
        }

    } else {
        series = [
            {
                name: 'Word',
                data: [
                    {
                        y: response.congruent.word.averageTime[0],
                        errors: response.congruent.word.errors[0]
                    },
                    {
                        y: response.neutral.word.averageTime[0],
                        errors: response.neutral.word.errors[0]
                    },
                    {
                        y: response.incongruent.word.averageTime[0],
                        errors: response.incongruent.word.errors[0]
                    },
                ],
                color: wordColor
            },
            {
                name: 'Color',
                data: [
                    {
                        y: response.congruent.color.averageTime[0],
                        errors: response.congruent.color.errors[0]
                    },
                    {
                        y: response.neutral.color.averageTime[0],
                        errors: response.neutral.color.errors[0]
                    },
                    {
                        y: response.incongruent.color.averageTime[0],
                        errors: response.incongruent.color.errors[0]
                    },
                ],
                color: colorColor
            },
        ]
    }


    let categories = ['Congruent', 'Neutral', 'Incongruent'];


    // return response

    let chartOptions = {
        chart: {
            type: 'bar',
            height: measureResults.hasDuplicateTests ? 575 : 250,
            style: {
                fontFamily: "inherit",
                
            },
        },
        title: {
            text: ' '
        },
        subtitle: {
            text: ' '
        },
        xAxis: {
            categories,
            title: {
                text: null
            },
            
            
        },
        yAxis: {
            min: 0,
            title: {
                text: ' ',
                align: 'high'
            },
            lineWidth: 1,
            lineColor: '#d9dfed',
            labels: {
                overflow: 'justify'
            },
            // plotLines: [{
            //     color: '#000',
            //     width: 2,
            //     value: response.averageTime,
            //     dashStyle: 'LongDash',
            //     label: {
            //         useHTML: true,
            //         rotation: 0,
            //         x: -45,
            //         y: 10,
            //         formatter() {
            //             return `<div>Average Time</div>`
            //         }
            //     }
            // }]
        },
        tooltip: {
            valueSuffix: ' ms'
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    // backgroundColor: '#ff0000',
                    padding: 3,
                    // borderRadius: 20,
                    // color: '#fff',
                    //
                    x: 5,
                    // style: {
                    //     fontSize: 10,
                    //     display: 'flex',
                    //     justifyContent: 'center',
                    //     textShadow: 'none',
                    //     width: 100,
                    //     backgroundColor: 'pink',
                    //
                    //
                    // },
                    useHTML: true,
                    formatter() {
                        let something = this;
                        // if (!this.point.errors && !isPortal) return null
                        let errorCount = this.point.errors;
                        let isExcluded = this.point.isExcluded;
                        let errorsCircle = errorCount ? `<div 
                                style="
                                background-color: red; 
                                height: 12px; 
                                width: 12px; 
                                border-radius: 10px; 
                                color: white; 
                                padding: 2px; 
                                font-size: 10px; 
                                display: flex; 
                                justify-content: center; 
                                align-items: center;
                                
                                ">
                                    ${errorCount}
                                </div>` : null;
                        let displayTime = `<div style="text-decoration: ${isExcluded ? 'line-through' : null}; margin-left: ${errorsCircle ? 3 : 0}px; text-shadow: ${!isExcluded ? '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white' : null}">${this.point.y} ms</div>`
                        
                        let response = `<div style="display: flex; opacity: ${isExcluded ? .5 : 1}">`
                        if (errorsCircle) response += errorsCircle
                        response += displayTime
                        response += `</div>`
                        return response
                    }
                }
            }
        },
        legend: {
            // layout: 'vertical',
            // align: 'right',
            // verticalAlign: 'top',
            // x: -40,
            // y: 80,
            // floating: true,
            enabled: false,
            // borderWidth: 1,
            // backgroundColor: '#FFFFFF',
            // shadow: true
        },
        credits: {
            enabled: false
        },
        series
    };
    let formattedResults = response;
    return {chartOptions, formattedResults}

}

function getMotionOrGyroChart({series, xAxisPlotLines, title, testStartTime}) {
    return {
        chart: {
            zoomType: 'x',
            style: {
                fontFamily: 'inherit'
            }
        },

        title: {
            text: title
        },

        subtitle: {
            text: ' '
        },

        tooltip: {
            valueDecimals: 2,
            shared: true,
            useHTML: true,
            backgroundColor: '#fff',
            formatter() {
                const self = this;
                let pointIsBeforeStart = moment(testStartTime).isAfter(moment(self.x))
                let elapsedTime = pointIsBeforeStart ? moment(testStartTime - self.x) :  moment(self.x - testStartTime)
                let timeInTest = `${pointIsBeforeStart ? '-' : ''}${elapsedTime.format('s')}.${parseInt(elapsedTime.format('SSS'))} seconds`;
                // 
                let points = self.points.map(point => (
                    `<div style="display: flex; align-items: center"><div style="background-color: ${point.color}; height: 10px; width: 10px; border-radius: 10px; margin-right: 3px"></div> <div style="; margin-right: 3px">${point.series.name}:</div><span style="font-weight: bold">${point.y}</span></div>`
                ))
                let timeTitle = `<div>Time Elapsed</div>`
                let timeElapsed = `<div style="font-size: 18px; margin-bottom: 10px; font-weight: bold">${timeInTest}</div>`
                let response = `<div >`;
                response += timeTitle
                response += timeElapsed
                points.forEach(point => response += point)
                response += `</div>`
                return response
            }
        },

        xAxis: {
            type: 'datetime',
            plotLines: xAxisPlotLines,
            labels: {
                formatter() {
                    let self = this;
                    return moment(self.value - testStartTime).format('s')
                }
            },
            // tickmarkPlacement: 'on',
            // startOnTick: true,
        },
        credits: {
            enabled: false,
        },
        series
    }
}

function buildLineChart(lineGraph) {
    return {
        chart: {
            zoomType: 'x',
            style: {
                fontFamily: 'inherit'
            }
        },
        title: {
            text: lineGraph.title
        },
        xAxis: {
            title: {
                text: lineGraph.xLabel
            },
        },
        yAxis: {
            title: {
                text: lineGraph.yLabel
            },
        },
        series: lineGraph.series.map(item => ({ name: item.name, data: item.points }))
    }
}

function buildLineChart3d(lineGraph) {

    // Set up the chart
    var chart = {
        chart: {
            renderTo: "container3d",
            type: 'scatter3d',
            options3d: {
                enabled: true
            },
            frame: {
                bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
                back: { size: 1, color: 'rgba(0,0,0,0.04)' },
                side: { size: 1, color: 'rgba(0,0,0,0.06)' }
            },
            fitToPlot: false,
        },
        title: {
            text: lineGraph.title
        },
        plotOptions: {
            
        },
        xAxis: {
            title: {
                text: lineGraph.xLabel
            },
            alignTicks: true
        },
        yAxis: {
            title: {
                text: lineGraph.yLabel
            },
            alignTicks: true
        },
        zAxis: {
            title: {
                text: lineGraph.zLabel
            },
            alignTicks: true
        },
        legend: {
            
        },
        series: lineGraph.series.map(item => ({ name: item.name, data: item.points }))
    };
    
    return chart;
}

const getEventTypeById = (id) => {

    return eventTypes.find(x => x.id === id)

}

const hasDetailedTestGraph = (currentTest) => {

    return currentTest && currentTest.formattedTestsData && (currentTest.formattedTestsData['79'] || currentTest.formattedTestsData['78'] || currentTest.formattedTestsData['80']
        || currentTest.formattedTestsData['1022'] || currentTest.formattedTestsData['1023'] || currentTest.formattedTestsData['66'] || currentTest.formattedTestsData['67']
        || currentTest.formattedTestsData['68'] || currentTest.formattedTestsData['84'] || currentTest.formattedTestsData['111']);

}

const isCobaltMeasureType = (measureId) => {
    return measureId == 78 || measureId == 80 || measureId == 1022 || measureId == 1023 || measureId == 1025;
}

const mapInvoiceToDisplay = (invoice) => {
    const {id, displayInvoiceDate, displayDueDate, total, issuedOn} = invoice;
    let format = "M/D/YYYY";
    invoice.id = id;
    invoice.displayNumber = id + (invoice.deletedOn ? ' (VOIDED)' : (invoice.isRefunded ? ' (REFUNDED)' : (invoice.isNotSent ? ' (NOT SENT)' : '')));
    invoice.displayInvoiceDate = moment(displayInvoiceDate).format(format);
    invoice.displayDueDate = moment(displayDueDate).format(format);
    invoice.total = formatMoney(total, 2);
    invoice.totalNumeric = total;
    invoice.paidTotal = invoice.payment ? (formatMoney(invoice.payment.total, 2) + (invoice.isRefunded ? ' (REFUNDED)' : '')) : '$0.00';
    invoice.paidTotalNumeric = invoice.payment ? invoice.payment.total : 0.0;
    invoice.isPastDue = !invoice.payment && moment().isAfter(moment(displayDueDate)) && !invoice.deletedOn;
    invoice.issuedOn = moment(issuedOn).format(format);

    if (invoice.deletedOn)
        invoice.displayRange = invoice.id + ' (VOID)'
    else if (invoice.isNotSent)
        invoice.displayRange = invoice.id + ' (Visible on ' + invoice.displayInvoiceDate + ')'
    else if (invoice.payment && invoice.payment.total === total)
        invoice.displayRange = invoice.id + ' (Paid on ' + moment(invoice.payment.paymentDate).format(format) + ')'
    else
        invoice.displayRange = invoice.id + ' (Due ' + invoice.displayDueDate + ')'
    
    return invoice;
}

export {
    getCiColor,
    getCiColorForChart,
    getVariabilityFromType,
    getBaselineConsistencyFromVariabilityType,
    toFixedNullable,
    formatMoney,
    formatNumber,
    formatDate,
    getPackageTierById,
    
    getSessionDetails,
    getCodeTypeId,
    
    getMeasuresAsArrayByCategoryId,
    getCountdownDisplayTime,
    getOrdinalSuffix,
    inchesToCentimeters,
    poundsToKg,
    isAdminOrgAccessType,
    isCovidAccount,
    isPatientAccount,
    getTestChangeColor,
    getTestTypesByCategories,
    getStroopDetailsChart,
    getMotionOrGyroChart,
    buildLineChart,
    buildLineChart3d,
    getEventTypeById,
    hasDetailedTestGraph,
    isCobaltMeasureType,

    mapInvoiceToDisplay
}
