<template>
    <div>
        <v-row>
            <v-col cols="12" lg="12">
                <app-card hide-header>

                    <app-data-table
                        :items="invoices"
                        :headers="invoicesTableHeaders"
                        :loading="loadingInvoices"
                        @onRowClick="onRowClick"
                    >
                        <template #item.paidTotal="{item}">

                            <div v-if="item.payment && !item.isRefunded">
                                {{ item.paidTotal }}
                                <app-chip  color="green" label small class="ml-2">Paid</app-chip>
                            </div>
                            <div v-else-if="item.isRefunded">
                                <app-chip  color="yellow" label small class="ml-2">Payment Refunded</app-chip>
                            </div>
                            <div v-else-if="item.deletedOn">
                                <app-chip  color="gray" label small class="ml-2">VOIDED</app-chip>
                            </div>
                            <div v-else>
                                <app-chip :color="item.isPastDue ? 'red' : 'blue'" label small>{{ item.isPastDue ? 'Past Due' : 'Outstanding' }}</app-chip>
                            </div>
                            
                        </template>
                    </app-data-table>

                </app-card>
            </v-col>
        </v-row>
        
        <router-view 
            :currentInvoice="currentInvoice"
            :billingInfo="billingInfo"
        ></router-view>  
    </div>
</template>

<script>

export default {
    name: "BillingHistory",
    props: {
        billingInfo: {
            type: Object,
            required: true,
        },
        invoices: {
            required: true
        }
    },
    data() {
        return {
            // invoices: [],
            loadingInvoices: false,
            currentInvoice: null,
            // invoiceHeaders: [
            //     {
            //         text: 'Description',
            //         value: 'name'
            //     },
            //     {
            //         text: 'Detail',
            //         value: 'description'
            //     },
            //     {
            //         text: 'Quantity',
            //         value: 'quantity'
            //     },
            //     {
            //         text: 'Amount',
            //         value: 'subTotal'
            //     },
            // ],
            invoicesTableHeaders: [
                {
                    text: 'Invoice Number',
                    value: 'displayNumber'
                },
                {
                    text: 'Invoice Date',
                    value: 'issuedOn'
                },
                {
                    text: 'Due Date',
                    value: 'displayDueDate'
                },
                {
                    text: 'Total',
                    value: 'total'
                },
                {
                    text: 'Payment',
                    value: 'paidTotal'
                },
            ],
            
        }
    },
    mounted() {
        // this.getInvoices();
    },
    computed: {
        
    },
    methods: {
        
        onRowClick(item) {
            this.currentInvoice = item;
            this.$router.push({ path: '/billing/view-invoices', query: { orgId: this.billingInfo.organizationId, id: item.id }});
        }
    }
}
</script>

<style scoped>

</style>