<template>
    <div style="display: flex"
         
    >
        <div style="flex: 2; padding: 10px; border-left: 1px solid var(--main-border-color); border-bottom: 1px solid var(--main-border-color);border-top: 1px solid var(--main-border-color);">
            <div  :class="{'pdf-excluded-section': measure.isExcluded}">
                <div :style="{height: alignProfileBaselineDetails ? '164px' : null}">
                    <div style="font-weight: bold; font-size: 16px">
                        {{measureInfo.label}}
                    </div>
                    <div v-if="measure.isExcluded" style="color: red; font-weight: bold">
                        EXCLUDED FROM BASELINE
                    </div>
                    <div style="margin-top: 10px">
                        {{measureInfo.description}}
                    </div>
                </div>

                <div v-if="baselineStats && categoryId !== 8">
                    <div style="font-weight: bold; margin-top: 20px">
                        Profile Baseline Details
                    </div>
                    <div v-for="(stat, statIndex) in baselineStats" :key="statIndex">
                        <span v-if="stat.label">{{stat.label}}: </span>{{stat.value}}

                    </div>
                </div>
                <div v-else-if="measureId == 9999">

                </div>
                <div v-else-if="categoryId !== 8">
                    This profile does not have an established baseline for this measure.
                </div>
                
                <div v-if="measure.fallRiskTypeId == 1" style="margin-top: 20px">
                    <FallRiskTag show-details/>
                </div>
                
            </div>
        </div>
        
        <div style="
                flex: 3; 
                padding: 10px; 
                border: 1px solid var(--main-border-color); 
                display: flex; 
                flex-direction: column;
                "
             :style="{justifyContent: !alignProfileBaselineDetails ? 'center' : null}"
             
        >
	        <div v-if="measureId == 1020">
		        <VomsTable :individual-measure="profileTest.formattedTestsData['1020']" />
	        </div>
            <div v-else-if="measureId !== 9999 && categoryId !== 8" :class="{'pdf-excluded-section': measure.isExcluded}">
                <div>
                    <div style="padding: 20px">
                        <TestColumnChart :profile-test="profileTest" :measure-id="measureId" />
                    </div>
                    <div style="display: flex">
                        <div v-for="(stat, statIndex) in testStats" :key="statIndex" style="flex: 1; display: flex;  justify-content: center">
                            {{stat.label}} <span style="margin-left: 10px; font-weight: bold">{{stat.value}}</span>

                        </div>
                        <div style="flex: 1; display: flex;  justify-content: center; align-items: center">
                            Change: <MeasureChangeChip :change-data="measure.changeData" style="margin-left: 5px" />
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px">
                    <div v-if="parseInt(measureId) === 77">
                        <highcharts :options="measure.detailsChart" />
                        <CuedStroopDetailsChartKey isPdf />
                    </div>
                    <div v-else >
                        <TestDetailTable :measure-results="measureResults" :all-detail-rows="allDetailRows" />
                    </div>
                </div>
                
                
            </div>
            <div v-else-if="categoryId === 8">
                <SurveyResultsWithTable :formatted-test="measureResults" :measure-id="measureId" />
            </div>
            <div v-else>
                <SymptomsDetailsSection :profile-test="profileTest" />
            </div>
           
            
            
        </div>
        
        
        
    </div>
</template>

<script>

import {getOrdinalSuffix, getBaselineConsistencyFromVariabilityType} from "@/js/shared/helper";
import TestColumnChart from "@/components/pdf-export/components/TestColumnChart";
import SymptomsDetailsSection from "@/components/pdf-export/SymptomsDetailsSection";
import TestDetailTable from "@/components/pdf-export/components/TestDetailTable";
import CuedStroopDetailsChartKey from "@/components/pdf-export/components/CuedStroopDetailsChartKey";
import SurveyResultsWithTable from "@/components/Profile/TestsViewer/Surveys/SurveyResultsWithTable";
import FallRiskTag from "@/components/pdf-export/components/FallRiskTag";
import {allMeasuresFromConstants} from "@/js/shared/constants";
import MeasureChangeChip from "@/components/pdf-export/components/MeasureChangeChip";
import VomsTable from "@/components/pdf-export/VomsTable";

export default {
    name: "TestDetail",
    components: {
	    VomsTable,
	    MeasureChangeChip,
        FallRiskTag,
        SurveyResultsWithTable,
        CuedStroopDetailsChartKey, TestDetailTable, SymptomsDetailsSection, TestColumnChart},
    props: {
        profileTest: {
            required: true
        },
        measureId: {
            required: true
        },
        measure: {
            required: true
        },
        categoryId: {
            
        }
    },
    computed: {
        measureResults() {
            return this.profileTest.formattedTestsData[this.measureId]
        },
        measureInfo() {
            return allMeasuresFromConstants[this.measureId]
        },
        
        baselineStats() {
            
            if (!this.profileTest.averages[this.measureId]) return null
            let averages = this.profileTest.averages[this.measureId];
            if (averages.baselineTestCount === 0) {
                return [
                    {
                        label: null,
                        value: 'This profile does not have an established baseline for this measure.'
                    }
                ]
            } 
            
            

            const {showTimeScores} = this.measureInfo
            return [
                {
                    label: 'Baseline',
                    value: !averages.score ? '-' : showTimeScores ? `${averages.score.toFixed(1)} ms` : averages.score.toFixed(1)
                },
                {
                    label: 'Percentile',
                    value: typeof averages.percentileNumber === 'number' ? `${averages.percentileNumber.toFixed(0)}${getOrdinalSuffix((averages.percentileNumber).toFixed(0))}` : '-'
                },
                {
                    label: 'Range',
                    value: showTimeScores ? `${averages.lowScore.toFixed(0)} ms - ${averages.highScore.toFixed(0)} ms` : `${averages.lowScore.toFixed(0)} - ${averages.highScore.toFixed(0)}`
                },
                {
                    label: 'Consistency',
                    value: getBaselineConsistencyFromVariabilityType(averages.variabilityType)
                },
                {
                    label: 'Test Count',
                    value: averages.baselineTestCount
                },
                
            ]
        },
        testStats() {
            const {percentileNumber, score} = this.measureResults
            const {showTimeScores} = this.measureInfo;

            // console.log('Percentile Number: ', (percentileNumber).toFixed(0), getOrdinalSuffix(percentileNumber))
            return [
                {
                    label: 'Score',
                    value: showTimeScores ? `${score} ms` : score
                },
                {
                    label: 'Percentile',
                    value: percentileNumber ? `${percentileNumber.toFixed(0)}${getOrdinalSuffix((percentileNumber).toFixed(0))}` : '-'
                },
                
            ]
        },
        allDetailRows() {
            let response = [];
            this.measureResults.results.forEach(result => {
                result.detailRows.forEach(row => response.push(row))
            })
            return response
        },
        
        alignProfileBaselineDetails() {
            return this.measureResults.hasDuplicateTests || this.allDetailRows.length > 4
        }
        
        
    },
    methods: {
        getPercentChangeColor() {
            const {percentChange} = this.measureResults
            if (percentChange > -0.1) {
                return 'var(--percentChange-normal-color)'
            } else if (percentChange > -0.15) {
                return 'var(--percentChange-warning-color)'
            } else {
                return 'var(--percentChange-bad-color)'
            }
        }
    }
}
</script>

<style scoped>

</style>