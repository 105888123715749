<template>
    <div>
        <div v-if="!loadingSessions">
            
            <div v-if="allSessions.length > 0">
                <page-header title="Codes">
                    <div slot="right-content">
                        <app-btn @click="addSessionDialogOpen = true" icon="mdi-plus">Create Code</app-btn>
                    </div>
                </page-header>
                <div class="mb-2" >
                    <TestTypeDotExplanation  />
                </div>
                <div>
                    <app-form-checkbox
                        checkbox-left-side label="Show Expired Codes"
                        v-model="showExpiredCodes"
                        helper-message="Show expired codes."
                    ></app-form-checkbox>
<!--                    <input type="checkbox" id="showExpired" v-model="showExpiredCodes">-->
<!--                    <label for="showExpired">Show Expired Codes</label>-->
                </div>
                <SessionsTable
                    :sessions="sessions"
                    @onRowClick="onRowClick($event)"
                />


                
            </div>
            <div v-else style="margin-top: 10vh">
                <EmptyState
                    title="Make your first code."
                    description="Codes allow you to activate and test profiles by sending a 5-digit code."
                    :svg="emptyStateSvg"
                    button
                    button-text="Create Code"
                    @buttonClick="addSessionDialogOpen = true"
                />
                
            </div>

            <SessionCreateForm
                :open="addSessionDialogOpen"
                @close="addSessionDialogOpen = false"
                :organizationPreferences="organizationPreferences"
            />
            

        </div>
        <div v-else>
            <AppPageLoader text="Loading Codes..."/>
            
        </div>
    </div>
</template>

<script>
import {getSessions} from '@/services/session.service'
import {getSessionDetails} from '@/js/shared/helper'
import sessionMixin from "@/mixins/sessionMixin";
import SessionsTable from "@/components/Sessions/SessionsTable";
import SessionCreateForm from "@/components/Sessions/SessionCreateForm";
import EmptyState from "@/components/EmptyState";
import emptyStateSvg from '@/assets/svgs/empty-state/empty-group-sessions.svg'
import {getOrganizationPreferences} from "@/services/organization.service";
import TestTypeDotExplanation from "@/components/pdf-export/components/TestTypeDotExplanation.vue";


export default {
    name: "Sessions",
    components: {
        TestTypeDotExplanation,
        EmptyState,
        SessionCreateForm,
        SessionsTable

    },
    mixins: [sessionMixin],
    data() {
        return {
            emptyStateSvg,
            allSessions: [],
            sessions: [],
            addSessionDialogOpen: false,
            loadingSessions: false,
            organizationPreferences: null,
            showExpiredCodes: false,
        }
    },
    mounted() {
        let self = this;
        this.init();
        if (parseInt(self.$route.query?.createCode) === 1) {
            this.addSessionDialogOpen = true
        }
        this.getOrganizationPreferences()

    },
    watch: {
        showExpiredCodes() {
            this.setSessions();
        }
    },
    
    methods: {
        async init() {
            await this.setSessions();
        },

        async getOrganizationPreferences() {
            const response = await getOrganizationPreferences();

            if (!response.isSuccess) return;

            const {organizationPreferences} = response;
            this.organizationPreferences = organizationPreferences;
        },
        onRowClick(item) {
            this.$router.push(`/codes/${item.id}`)
        },

        async setSessions() {
            this.loadingSessions = true;
            const data = await getSessions();
            if (!data.isSuccess) {
                this.loadingSessions = false;
            }
            let sessions = data.sessions;
            sessions.forEach(x => {
                x = getSessionDetails(x)
            })
            
            //set allSessions
            this.allSessions = sessions;

            // Filter sessions based on showExpiredCodes
            if (!this.showExpiredCodes) {
                sessions = sessions.filter(session => session.isActive);
            }
            
            sessions.sort((a, b) => {
                // if (a.isActive && b.isActive) {
                //     if (moment(a.endsOn).isAfter(b.endsOn)) return 1
                // }
                if (a.isActive) return -1
            })
            
            this.sessions = sessions
            // this.sessions = []
            this.loadingSessions = false;
        },
        
 
    },
    computed: {
    }
}
</script>

<style scoped>

</style>