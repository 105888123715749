<template>
    <v-btn 
        icon 
        @click="onClick" 
        :color="color" 
        :small="small" 
        fab 
        :loading="loading"
        :x-small="xSmall"
    >
        <v-icon class="edit-icon" >{{ iconName }}</v-icon>
    </v-btn>
</template>

<script>
export default {
    name: "AppFabButton",
    props: {
        type: {
            required: true,
            type: String
        },
        color: {
            type: String
        },
        small: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
        xSmall: {
            type: Boolean
        }
        
    },
    methods: {
        onClick() {
            this.$emit('click')
        }
    },
    computed: {
        iconName() {
            switch (this.type) {
                case 'edit':
                    return 'mdi-pencil'
                case 'share':
                    return 'mdi-share'
                case 'delete':
                    return 'mdi-trash-can-outline'
                case 'download':
                    return 'mdi-download-outline'
                case 'calendar':
                    return 'mdi-calendar';
                case 'copy':
                    return 'mdi-content-copy';
                case 'close':
                    return 'mdi-close';
                case 'add':
                    return 'mdi-plus';
                case 'print':
                    return 'mdi-printer'
                case 'menu': 
                    return 'mdi-dots-vertical'
                case 'chevrondown':
                    return 'mdi-chevron-down'
                case 'chevronup':
                    return 'mdi-chevron-up'
                case 'chart':
                    return 'mdi-chart-line'
            }
        }
    }
}
</script>

<style scoped>

</style>