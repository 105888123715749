<template>
    <div >
        <div id="loading-screen" ref="loadingScreen" :style="{width: sizing.totalWidth}">

                <div class="loading-rect small-right rect1" ref="rect1" :class="[rectClass]"></div>
                <div class="loading-rect small-left rect2" ref="rect2" :class="[rectClass]"></div>
                <div class="loading-rect small-right rect3" ref="rect3" :class="[rectClass]"></div>
                <div class="loading-rect small-left rect4" ref="rect4" :class="[rectClass]"></div>
        </div>
    </div>
    
</template>

<script>
import gsap, {Power1} from 'gsap'

export default {
    name: "SwayLoader",
    props: {
        small: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        xLarge: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            wiggleInterval: null,
            duration: null
        }
    },
    mounted() {
        this.wiggle()
    },
    computed: {
        sizing() {
            
            // let height, marginTop, width, marginHorizontal;
            
            let height = 28;
            let width = 8;
            let marginTop = 1;
            let marginHorizontal = 7;
            
            
            if (this.small) {
                marginHorizontal = 5.5;
            } else if (this.large) {
                marginHorizontal = 10;
            } else if (this.xLarge) {
                marginHorizontal = 14
            }
            
            let totalWidth = marginHorizontal * 17

            
            return {
                marginHorizontal,
                totalWidth:`${totalWidth}px`
            }
        },
        rectClass() {
            if (this.small) {
                return 'small'
            } else if (this.xSmall) {
                return 'x-small'
            } else if (this.large) {
                return 'large'
            } else if (this.xLarge) {
                return 'x-large'
            } else {
                return 'normal'
            }
        }
    },
    methods: {
        startLoader() {
            
            // this.$refs.loadingScreen.show().animate({ opacity: 1 });
            // let loadingDuration = (1400 / 1000);
            // this.wiggle(loadingDuration)
            this.wiggleInterval = setInterval(function () {
                this.wiggle(loadingDuration);
            }, (1600 * loadingDuration) + 300);
        },
        stopLoader() {
            clearInterval(this.wiggleInterval);
            this.$refs.loadingScreen.stop().hide();
        },
        wiggle(duration) {
            let ease = Power1.easeOut;
            const {marginHorizontal} = this.sizing;

            let movementOdd1 = {
                duration: 1.4 * .5,
                x: marginHorizontal * 6,
                ease: ease
            }
            
            let movementOdd2 = {
                duration: 1.4 * .5,
                x: - marginHorizontal * 6,
                ease: ease
            }
            
            let movementOdd3 = {
                duration: 1.4 * .4,
                x: 0 + marginHorizontal / 2,
                ease: ease
            }

            let movementEven1 = {
                duration: 1.4 * .5,
                x: marginHorizontal * 6,
                ease: ease
            }

            let movementEven2 = {
                duration: 1.4 * .5,
                x: - marginHorizontal * 6,
                ease: ease
            }

            let movementEven3 = {
                duration: 1.4 * .4,
                x: 0 - marginHorizontal / 2,
                ease: ease
            }
            
            gsap.timeline()
                .to('.rect1', movementOdd1)
                .to('.rect1', movementOdd2)
                .to('.rect1', movementOdd3)
                .repeatDelay(.5)
                .repeat(-1)

            gsap.timeline()
                .delay(.1)
                .to('.rect2', movementEven1)
                .to('.rect2', movementEven2)
                .to('.rect2', movementEven3)
                .repeatDelay(.5)
                .repeat(-1)

            gsap.timeline()
                .delay(.2)
                .to('.rect3', movementOdd1)
                .to('.rect3', movementOdd2)
                .to('.rect3', movementOdd3)
                .repeatDelay(.5)
                .repeat(-1)

            gsap.timeline()
                .delay(.3)
                .to('.rect4', movementEven1)
                .to('.rect4', movementEven2)
                .to('.rect4', movementEven3)
                .repeatDelay(.5)
                .repeat(-1)



       
        }
    }
}
</script>

<style scoped lang="scss">

#loading-screen {
    //background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#loading-screen .loading-rect{
    
    background-color: var(--v-primary-base)
}

.small {
    height:6px;
    width:21px;
    margin-top: 1px;
}

.small-right {
    transform: translateX(4px);
}

.small-left {
    transform: translateX(-4px);
}

.normal {
    height:8px;
    width:28px;
    margin-top: 1px;
}

.large {
    height:12px;
    width:42px;
    margin-top: 2px;
}

.x-large {
    height:16px;
    width:56px;
    margin-top: 3px;
}


//#loading-screen .loading-rect:last-child{
//    margin-top: 1px
//}
//#loading-screen .loading-rect:first-child{
//    /*margin-top: 20px;*/
//    margin-top: 2px
//}
//#loading-screen p{
//    margin:25px auto 0;
//    text-align:center;
//    color:#fff;
//    font-weight:600;
//    font-size:19px;
//    font-family:"proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif
//}
#loading-screen .loading-rect.move-left{
    margin-left:40px;
}
#loading-screen .loading-rect.move-right{
    margin-left:50px
}

</style>