<template>
    <div id="attention-charts-container" >
        <div v-for="(item, index) in data" :key="index" style="display: flex; align-items: center; margin-bottom: 20px">
            <div style="display: flex; text-align: left" >
                <div class="test-title" style="font-weight: 400; width: 150px">
                    {{ item.label }}
                </div>
            </div>
            <div style="flex: 1; position: relative; margin-right: 10px">
                <highcharts
                    v-if="item[currentChartOptions]"
                    :options="item[currentChartOptions]"
                    :ref="item.name"
                    class="chart-container"
                ></highcharts>
                <ProfileTrendsBaselineNorms :data="item" />
            </div>
        </div>
    </div>
</template>

<script>
// import VueFlip from 'vue-flip';
import {getMeasuresAsArrayByCategoryId} from '@/js/shared/helper'
import {allMeasures} from "@/js/shared/constants";
import ProfileTrendsBaselineNorms from "@/components/Profile/ProfileTrendsBaselineNorms";

export default {
    name: "ProfileAttentionTrends",
    props: {
        profile: {
            required: true
        },
        categoryId: {
            default: null
        }
    },
    components: {
        ProfileTrendsBaselineNorms
        // VueFlip
    },

    data() {
        return {
            data: []
        }
    },
    mounted() {
        let self = this;
        const measures = getMeasuresAsArrayByCategoryId(this.categoryId)

        let data = []

        measures.forEach(measure => {

            if (this.profile.measureIdsTaken.includes(measure.measureId)) {

                let trendChartOptions = this.profile.getTrendChartOptionsByMeasureId({
                    measureId: measure.measureId, 
                    onMouseOver: this.onMouseOver,
                    resizeCallback: ({positions, cutOffs, yAxisOffset, ciColor}) => {
                        // const {
                        //     baselineTop,
                        //     baselineHeight,
                        //     normWideTop,
                        //     normWideHeight,
                        //     normThinTop,
                        //     normThinHeight,
                        // } = sizing
            
                        let index = self.data.findIndex(x => x.measureId === measure.measureId)
                        self.data[index].positions = positions
                        self.data[index].cutOffs = cutOffs

                        self.data[index].yAxisOffset = yAxisOffset
                        self.data[index].ciColor = ciColor
                    }
                });
                
                data.push({
                    ...measure,
                    trendChartOptions,
                    currentValue: null,
                    percentChange: null,
                    positions: null,
                    yAxisOffset: null

                })
            }

        });

        this.data = data;

    },
    computed: {
        currentChartOptions() {
            return 'trendChartOptions'
        }
    },
    methods: {
        onMouseOver(event) {
            let self = this;
            let keys = Object.keys(this.$refs);

            keys.forEach((key, keyIndex) => {
                // if (chart === this.series.chart) return;
                let chart = this.$refs[key][0].chart;

                chart.series.forEach((series) => {
                    series.data.forEach((point) => {

                        if (point.x === event.target.x) {

                            if (event.type === "mouseOver") {
                                self.data[keyIndex].currentValue = point.y;
                                self.data[keyIndex].percentChange = point.percentChange
                                point.setState('hover');
                                chart.tooltip.refresh(point);
                                chart.xAxis[0].drawCrosshair(event, point);
                            } else {
                                self.data[keyIndex].currentValue = null;
                                self.data[keyIndex].percentChange =  null;
                                point.setState();
                                chart.tooltip.hide();
                                chart.xAxis[0].hideCrosshair();
                            }
                        }
                    });
                });
            });
        },
        getDisplayPercent(value) {
            return value !== null && value !== undefined ? value + '%' : null
        }
    }
}
</script>

<style scoped>
.chart-container {
    height: 150px;
    flex: 1;
}

.test-title {
    flex: 1;
    padding: 10px;
    font-weight: bold;
}

.current-value {
    /*padding: 10px;*/
    font-weight: 900
}
</style>