import httpService from "@/services/httpService";
import {baseUrl} from "@/config"

const endpoint = baseUrl + "/Tools"

const startMergeProfiles = async (request) => {
    try {
        return await httpService.post(endpoint + '/StartMergeProfiles', request)
    } catch {

    }
}

const confirmMergeProfiles = async (request) => {
    try {
        return await httpService.post(endpoint + '/ConfirmMergeProfiles', request)
    } catch {

    }
}

const startTransferProfile = async (request) => {
    try {
        return await httpService.post(endpoint + '/StartTransferProfile', request)
    } catch {

    }
}

const confirmTransferProfile = async (request) => {
    try {
        return await httpService.post(endpoint + '/ConfirmTransferProfile', request)
    } catch {

    }
}

const transferGroupOfProfiles = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/TransferGroupOfProfiles', request)
        return response
    } catch (e) {
    }
}

const transferTest = async (request) => {
    try {
        return await httpService.post(endpoint + '/TransferTest', request, {
            // headers: {
            //     'Authorization': "Bearer " + authToken
            // }
        })
    } catch {

    }
}


export {
    startMergeProfiles,
    confirmMergeProfiles,
    startTransferProfile,
    confirmTransferProfile,
    transferGroupOfProfiles,
    transferTest
}

