<template>
    <div>
        <LoginFrame hide-contact>
            <h1 ></h1>
            <svg width="210" height="102" viewBox="0 0 210 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d)">
                    <path d="M53.7747 58.2415H64.1529V69.4224H53.7747V88H39.8415V69.4224H3.77577L3.37441 60.9364L39.3828 4.51562H53.7747V58.2415ZM17.9956 58.2415H39.8415V23.3799L38.8094 25.2147L17.9956 58.2415ZM127.798 52.8517C127.798 64.8545 125.543 73.9139 121.032 80.03C116.56 86.1078 109.737 89.1468 100.563 89.1468C91.5414 89.1468 84.7373 86.1843 80.1502 80.2593C75.6014 74.2962 73.2696 65.4852 73.155 53.8265V39.3772C73.155 27.3744 75.3911 18.3723 79.8635 12.371C84.3741 6.36956 91.2356 3.36886 100.448 3.36886C109.584 3.36886 116.388 6.29311 120.86 12.1416C125.371 17.9901 127.684 26.7437 127.798 38.4025V52.8517ZM113.865 37.2557C113.865 29.4195 112.795 23.6857 110.654 20.0543C108.513 16.3846 105.111 14.5498 100.448 14.5498C95.8991 14.5498 92.5544 16.2891 90.4138 19.7676C88.2731 23.2079 87.1455 28.5977 87.0308 35.9369V54.8585C87.0308 62.6565 88.1202 68.4668 90.2991 72.2893C92.4779 76.1119 95.8991 78.0232 100.563 78.0232C105.035 78.0232 108.341 76.2648 110.482 72.748C112.623 69.1931 113.75 63.6504 113.865 56.12V37.2557ZM187.258 58.2415H197.636V69.4224H187.258V88H173.325V69.4224H137.259L136.858 60.9364L172.866 4.51562H187.258V58.2415ZM151.479 58.2415H173.325V23.3799L172.293 25.2147L151.479 58.2415Z" fill="url(#paint0_linear)"/>
                </g>
                <defs>
                    <filter id="filter0_d" x="0.928083" y="0.922468" width="208.94" height="100.456" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dx="4.89286" dy="4.89286"/>
                        <feGaussianBlur stdDeviation="3.66964"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.483333 0 0 0 0 0.483333 0 0 0 0 0.483333 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <linearGradient id="paint0_linear" x1="10.2993" y1="93.9821" x2="200.173" y2="5.36223" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#34D3FE"/>
                        <stop offset="1" stop-color="#34D3FE" stop-opacity="0.52"/>
                    </linearGradient>
                </defs>
            </svg>
            

            <h1 >Page Not Found</h1>
            <div>
                Looks like this page doesn't exist or can't be found.
            </div>
            <AppBtn style="margin-top: 20px" dense @click="$router.push('/')">Go back home</AppBtn>
        </LoginFrame>
    </div>
</template>

<script>
import LoginFrame from "@/components/Login/LoginFrame.vue";
export default {
    name: "PageNotFound",
    components: {LoginFrame}
}
</script>

<style scoped>

</style>