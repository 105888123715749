<template>
    <v-row v-if="checkboxLeftSide" style="display: flex; align-items: center">
        <v-checkbox
            v-model="myValue"
            @change="update"
            color="primary"
            :disabled="disabled"
        >
	        <template v-slot:label>
		        <span style="color: #757575">{{ label }}</span>
	        </template>
        </v-checkbox>
       

<!--        <v-menu-->
<!--            open-on-hover-->
<!--            nudge-right="30"-->
<!--            nudge-top="10"-->
<!--            right-->
<!--            v-if="helperMessage"-->
<!--            transition="slide-x-transition"-->
<!--        >-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--                <div class="helper-container ml-2" v-bind="attrs" v-on="on">-->
<!--                    <v-icon x-small color="white" >mdi-help</v-icon>-->
<!--                </div>-->
<!--            </template>-->

<!--            <app-card class="pa-2" hide-header>-->
<!--                {{ helperMessage }}-->
<!--            </app-card>-->
<!--        </v-menu>-->
        <HelperMessage 
            v-if="helperMessage" 
            :message="helperMessage"
        />



    </v-row>
    <v-row v-else>
        <v-col v-if="includeLabel" cols="3" style="display: flex; align-items: center; color: #757575">
            {{ label }}
        </v-col>
        <v-col cols="9">
            <v-checkbox
                v-model="myValue"
                @change="update"
                color="text"
                :disabled="disabled"
            ></v-checkbox>
        </v-col>
    </v-row>
</template>

<script>
import HelperMessage from "@/components/HelperMessage";
export default {
    components: {HelperMessage},
    props: {
        value: {
            required: true,
            type: Boolean
        },
        label: {
            required: false,
            type: String
        },
        checkboxLeftSide: {
            type: Boolean,
            default: false
        },
        helperMessage: {
            type: String
        },
        color: {
            default: 'primary'
        },
        disabled: {
            type: Boolean
        },
        includeLabel:{
            type: Boolean,
            default: true
        }
    },
    name: "AppFormCheckbox",
    data() {
        return {
            myValue: false
        }
    },
    methods: {
        update(e) {
            // 
            this.$emit('input', e)
        }
    },
    mounted() {
        this.myValue = this.value;
    }
}
</script>

<style scoped>

</style>