<template>
    <v-row  style="display: flex; height: 100%; width: 100%; align-items: center" class="ma-0" id="navbar-top">
        <div v-if="account.accountTypeId !== 4" style="flex: 1; display: flex; align-items: center;" class="ml-4">
            <div style="width: 80%">
                <app-test-search-two class="ml-2"></app-test-search-two>
            </div>
        </div>
        <div v-else style="flex: 1">

        </div>
	    <div>
		    <v-tooltip bottom>
			    <template v-slot:activator="{ on, attrs }">
				    <a 
					    href="https://university.swaymedical.com/" 
					    target="_blank" 
					    v-bind="attrs"
				       v-on="on"
				    >
					    <img :src="swayULogo" alt="" style="height: 30px">
				    </a>
			    </template>
			    <span>
				    Sway University
			    </span>
		    </v-tooltip>
		    
		    
	    </div>
	    <div style="height: 100%;" class="pa-3">
		    <v-divider vertical></v-divider>
	    </div>
	    
        <div>
            <v-menu
                offset-y
                nudge-bottom="0"
                nudge-left="20"
                :left="true"
                open-on-hover
				style="overflow: hidden"
            >
                <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                        <div style="display: flex; flex-direction: row; align-items: center" class="mr-10">

                            <!--                            <div class="mr-3">-->
                            <!--                                <v-avatar-->
                            <!--                                    color="light"-->
                            <!--                                    size="48"-->
                            <!--                                >-->
                            <!--                                    <span>-->
                            <!--                                      <v-icon class="icon" size="36" color="dark">{{ icon }}</v-icon>-->
                            <!--                                    </span>-->
                            <!--                                </v-avatar>-->
                            <!--                            </div>-->
                            <div style="display: flex; flex-direction: column;">
                                <h3>
                                    {{ firstName }}
                                </h3>
                                <div style="color: var(--v-text-lighten2); font-size: 13px">
                                    {{ organizationNameToDisplay }}
                                </div>
                            </div>


                            <div class=" ml-2">
                                <v-icon class="account-dropdown-arrow" color="dark">mdi-chevron-down</v-icon>
                            </div>
                        </div>
                    </div>
                </template>

                <app-card style="overflow: scroll; max-height:600px"
                    class="mx-auto"
                    tile
                    :min-width="300"
                    hide-header
                >
                    <!--                    <v-menu open-on-hover offset-x left>-->
                    <!--                        <template v-slot:activator="{ on, attrs }">-->
                    <div class="pa-3" style="display: flex; align-items: center">
                        <div >
                            <v-icon :size="40">mdi-account-circle-outline</v-icon>
                        </div>
                        <div  class="ml-4">

                            <div style="display: flex; flex-direction: row" >
                                <div style="flex: 1; font-size: 16px; font-weight: 500">{{ firstName }} {{ lastName }}</div>
                                <!--                                    <v-icon class="account-dropdown-arrow">mdi-swap-horizontal</v-icon>-->
                            </div>
                            <div v-if="allowEditAccount" style="font-size: 13px; color: #0070c9; font-weight: 400" class="custom-text-link" @click="$router.push(`/account/personal-information`)">Edit Account</div>
                        </div>
                    </div>

                    <!--                        </template>-->
                    <!--                        <app-card>-->
                    <!--                            WORDS-->
                    <!--                        </app-card>-->
                    <!--                    </v-menu>-->
                    <v-divider ></v-divider>
                    <div v-if="account.availableOrganizations">
                        <!--                        <span class="pa-4" style="color: #bdbdbd; font-size: .8em; font-weight: 500">ORGANIZATIONS</span>-->
                        <v-list >
                            <v-list-item-group>
                                <v-list-item
                                    v-for="(item, i) in account.availableOrganizations"
                                    :key="i"
                                    @click="changeOrganization(item)"
                                    class="account-dropdown-list-item"
                                    link
                                    dense

                                >

                                    <v-list-item-content >
                                        <div style="display: flex; align-items: center">
                                            <div style="width: 30px">
                                                <v-icon v-if="item.id === $store.state.currentAccount.selectedOrganization.id" :size="14">mdi-check-bold</v-icon>
                                            </div>
                                            <div style="font-size: 14px">
                                                {{item.name}} {{isSuperAdmin ? `(${item.id})` : ''}}
                                            </div>
                                            <span v-if="item.isEnterpriseAdminOrg" class="ml-2">
                                                <app-chip label color="blue" small class="ma-1">Enterprise</app-chip>
                                            </span>

                                        </div>


                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                        <v-divider></v-divider>
                    </div>



                    <v-list>
                        <v-list-item-group>
                            <v-list-item
                                v-for="(item, i) in items"
                                :key="i"
                                @click="item.onClick()"
                                class="account-dropdown-list-item"
                                dense
                            >

                                <v-list-item-content >
                                    <div >
                                        <div class="list-item-text" style="font-size: 14px; margin-left: 30px">
                                            {{item.text}}
                                        </div>
                                        <!--                                        <v-list-item-title v-text="item.text"-->
                                        <!--                                                           class="list-item-text"></v-list-item-title>-->
                                    </div>
                                </v-list-item-content>
                                <!--                                <v-list-item-content v-else>-->
                                <!--                                    <v-list-item-title v-text="item.text" class="list-item-text"></v-list-item-title>-->
                                <!--                                </v-list-item-content>-->
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </app-card>

            </v-menu>

        </div>
    </v-row>
</template>

<script>


import AppTestSearchTwo from "@/components/AppTestSearch2";
import {mdiAccountOutline} from "@mdi/js";
import {logout, switchSelectedOrganization} from "@/services/userService";
import {masterSearch} from "@/services/organization.service";
import swayULogo from '@/assets/svgs/sway-univeristy-logo.svg'


export default {
    name: "NavbarTop",
    components: {
        AppTestSearchTwo
    },
    data() {
        return {
            icon: mdiAccountOutline,
            selectedItem: 1,
            search: '',
            select: null,
            searchItems: [],
	        swayULogo
        }
    },
    watch: {
        search(val) {
            val && val !== this.select && this.performMasterSearch(val)
        },
    },
    mounted() {
        if (this.account.accountTypeId !== 4) {
            this.performMasterSearch('')
        }

    },
    computed: {
        account() {
            
            console.log(this.$store.state.currentAccount.availableOrganizations)
            
            return this.$store.state.currentAccount
        },
        searchPlaceholder() {
            return !this.searchField && 'Search'
        },
        firstName() {
            let firstName = this.$store.state.currentAccount ? this.$store.state.currentAccount.firstName :  this.account.firstName
            return firstName
        },
        lastName() {
            let lastName = this.$store.state.currentAccount ? this.$store.state.currentAccount.lastName :  this.account.lastName
            return lastName
        },
        organization() {
            // let organization = this.$store.state.currentAccount ? this.$store.state.currentAccount.selectedOrganization.name :  this.account.selectedOrganization.name
            let organization = this.$store.state.currentAccount ? this.$store.state.currentAccount.selectedOrganization.name :  this.account.selectedOrganization.name
            return organization
        },
        allowEditAccount() {
            return !this.$store.state.currentAccount.viewingAsSuper
        },
        viewingAsSuper() {
            return this.$store.state.currentAccount.viewingAsSuper
        },
        isSuperAdmin() {
            return this.$store.state.currentAccount.isSuperAdmin
        },
        organizationNameToDisplay() {
            if (this.organization.length < 30) {
                return this.organization
            } else {
                return this.organization.slice(0,30) + '...'
            }
        },
        items() {
            let items = []

            // if (this.allowEditAccount) {
            //     items.push({text: 'My Account', icon: 'mdi-clock', onClick: () => this.$router.push('/account/personal-information')})
            // }

            // items.push({text: 'Organization', icon: 'mdi-clock', onClick: () => this.$router.push('/organization')})
            items.push({text: 'Sign Out', icon: 'mdi-flag', onClick: this.logout},)
            return items
        }
    },
    methods: {
        logout() {
            //set currentAccount to null
            this.$store.state.currentAccount = null;
            logout(() => this.$router.push('/login'))
        },
        async changeOrganization(organization) {
            await switchSelectedOrganization(organization.id);
            await this.$router.push(`/dashboard?orgId=${organization.id}`);
            location.reload();



        },
        async performMasterSearch() {
            const data = await masterSearch(this.search)

            if (!data.searchResults) return null

            // type 1 = Profile
            // type 2 = Group
            // type 3 = Protocol
            // type 4 = Account

            let profiles = [];
            let groups = [];
            let protocols = [];
            let accounts = [];

            data.searchResults.forEach(result => {
                switch (result.type) {
                    case 1:
                        profiles.push(result);
                        break;
                    case 2:
                        groups.push(result);
                        break;
                    case 3:
                        protocols.push(result);
                        break;
                    case 4:
                        accounts.push(result)
                }
            })

            let final = [];
            if (profiles.length > 0) {
                final.push({header: 'Profiles'})
                profiles.forEach(profile => {
                    final.push({
                        name: profile.title,
                        id: profile.id
                    })
                })
            }
            if (groups.length > 0) {
                final.push({header: 'Groups'})
                groups.forEach(group => {
                    final.push({
                        name: group.title,
                        id: group.id
                    })
                })
            }
            if (protocols.length > 0) {
                final.push({header: 'Protocols'})
                protocols.forEach(protocol => {
                    final.push({
                        name: protocol.title,
                        id: protocol.id
                    })
                })
            }
            if (accounts.length > 0) {
                final.push({header: 'Accounts'})
                accounts.forEach(account => {
                    final.push({
                        name: account.title,
                        id: account.id
                    })
                })
            }


            this.itemsForDropdown = final
        }
    },
}
</script>

<style scoped lang="scss">

#navbar-top {
    background-color: white;
    border-bottom: 1px solid var(--v-border-base)
}

.v-autocomplete__content {
    border-radius: 10px !important;
}

.v-text-field .v-input__control .v-input__slot {
    max-height: 30px !important;
    display: flex !important;
    align-items: center !important;
}

.icon {
    fill: currentColor;
}

.account-dropdown-arrow-container {
    height: 25px;
    width: 25px;
    background-color: var(--v-light-base);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px
}

.account-dropdown-arrow {
    color: var(--v-background-darken4)
}

.search-input-area {
    //background-color: var(--v-background-base);
    border-radius: 15px;

}

.v-text-field--outlined {
    border: none !important;
    border-bottom-color: blue !important;
}

//.list-item-text {
//    color: red!important;
//}
.list-item-text {
    color: var(--v-text-base);
}

.account-dropdown-list-item:hover {
    background-color: var(--v-light-base);
    //color: white !important;
}

.account-dropdown-list-item:hover .list-item-text {
    //color: white !important;
}

.account-dropdown-list-item {
    color: var(--v-text-base) !important;
}

//.org-name {
//  color: var(--v-light-darken4)
//}

//.v-sheet {
//  background-color: red!important;
//  border-radius: 10px;
//  margin-top: 20px
//}
div[role=listbox] {
    //background-color: red!important;
    //border-radius: 10px;
    //margin-top: 5px;
}


.route {
    text-decoration: none;
}
</style>