<template>
	<app-chip 
		:color="color" 
		
		:small="small"
		:label="label"
	>
		{{ text }}
	</app-chip>
</template>

<script>
import Invoice from "@/js/shared/classes/Invoice/Invoice";

export default {
	name: "InvoiceApprovalStatusChip",
	props: {
		approvalStatusId: {
			required: true
		},
		small: {
			type: Boolean
		},
		label: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			color: null,
			text: null,
		}
	},
	watch: {
		approvalStatusId() {
			this.init()
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		init() {
			let approvalStatusObject = Invoice.invoiceApprovalStatuses.find(x => x.value === this.approvalStatusId);
			this.color = approvalStatusObject.chipColor
			this.text = approvalStatusObject.text
		}
	}
	
}
</script>

<style scoped>

</style>