<template>
    <div>
        <dialog-form
            title="Edit Group COVID Schedule"
            :open="editingSchedule"
            @close="editingSchedule = false"
            :width="800"
            @submit="submitSchedule"
            :submit-button-title="'Save ' + currentForm.label + ' Schedule'"
            :saving="savingSchedule"
        >

            <template #form-content>
<!--                <div>-->
<!--                    <app-tabs v-model="currentTab">-->
<!--                        <app-tab>Cognitive</app-tab>-->
<!--                        <app-tab>COVID</app-tab>-->
<!--                        &lt;!&ndash;                        <app-tab>Stability</app-tab>&ndash;&gt;-->
<!--                    </app-tabs>-->
<!--                </div>-->

                


                <!--                           <v-col cols="12">-->
                <!--                               <div style="display: flex; justify-content: flex-end">-->
                <!--                                   <app-fab-btn type="close"  small @click="editingSchedule = false"></app-fab-btn>-->
                <!--                               </div>-->
                <!--                           </v-col>-->


            </template>


        </dialog-form>


        <app-card  title="Group Schedule" >
<!--            <calendar class="pa-2" :events="events" @edit="editingSchedule = !editingSchedule"></calendar>-->
            <div style="padding: 20px; max-width: 800px">

                <v-switch
                    v-model="currentForm.screeningEnabled"
                    :label="currentForm.label + ' Screening Enabled'"
                    color="secondary"
                ></v-switch>
                <div v-if="currentForm.screeningEnabled">
                    <v-radio-group
                        v-model="currentForm.weekVsBlock"
                        row
                        color="secondary"
                    >
                        <v-radio
                            label="Week"
                            value="week"
                            color="secondary"
                        ></v-radio>
                        <v-radio
                            label="Block"
                            value="block"
                            color="secondary"
                        ></v-radio>
                    </v-radio-group>
                    <div v-if="currentForm.weekVsBlock">
                        <div style="display: flex; align-items: center">
                            <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>
                            <span style="color: rgba(255,0,0,.5); font-weight: 800; margin-left: 5px">IMPORTANT NOTE: </span>
                        </div>
                        <div style="margin-bottom: 10px">
                            All schedule times are based on the <strong>Central Time Zone (America/Chicago)</strong>. Please adjust your schedule times accordingly.
                        </div>
                    </div>
                    
                    <div style="max-width: 800px; position: relative" v-if="currentForm.weekVsBlock === 'week'">
                        <v-simple-table style="border: 1px solid lightgrey; border-radius: 5px">
                            <template>
                                <thead>
                                <tr>
                                    <th>Day of Week</th>
                                    <th>Time of day</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody >
                                <tr v-for="(item, index) in currentForm.weekSchedule" :key="index">
                                    <td >
                                        <v-select
                                            :items="weekdays"
                                            item-text="label"
                                            item-value="value"
                                            color="secondary"
                                            solo
                                            flat
                                            dense
                                            v-model="item.day"
                                            :menu-props="{ offsetY: true }"

                                        >

                                        </v-select>
                                    </td>
                                    <td>
                                        <app-time-picker
                                            v-model="item.startTime"
                                        ></app-time-picker>
                                    </td>
                                    <td>
                                        <v-icon @click="removeWeekScheduleItem(index)">mdi-trash-can-outline</v-icon>
                                    </td>
                                </tr>

                                </tbody>
                            </template>
                        </v-simple-table>
                        <div
                            style="
                                display: flex; 
                                position: absolute; 
                                bottom: -30px; 
                                justify-content: center;                    
                                width: 100%;          
                                "
                        >
                            <app-btn
                                @click="addWeekScheduleItem"
                                class="mb-2"
                                color="secondary"
                                fab
                                small
                            >
                                <v-icon>mdi-plus</v-icon>
                            </app-btn>
                        </div>

                    </div>
                    <div style="max-width: 800px; position: relative" v-if="currentForm.weekVsBlock === 'block'">
                        <v-simple-table style="border: 1px solid lightgrey; border-radius: 5px">
                            <template>
                                <thead>
                                <tr>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Time of day</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody >
                                <tr v-for="(item, index) in currentForm.blockSchedule" :key="index">
                                    <td >
                                        <app-date-picker
                                            v-model="item.startDate"
                                            :allowed-dates="e => blockAllowedStartDates(e, index)"
                                        ></app-date-picker>
                                       
                                    </td>
                                    <td>
                                        <app-date-picker
                                            v-model="item.endDate"
                                            :allowed-dates="e => allowedDates(e, index)"
                                        ></app-date-picker>

                                    </td>
                                    <td>
                                        <app-time-picker v-model="item.startTime"></app-time-picker>
                                    </td>
                                    <td>
                                        <v-icon @click="removeBlockScheduleItem(index)">mdi-trash-can-outline</v-icon>
                                    </td>
                                </tr>

                                </tbody>
                            </template>
                        </v-simple-table>
                        <div
                            style="
                                display: flex; 
                                position: absolute; 
                                bottom: -30px; 
                                justify-content: center;                    
                                width: 100%;          
                                "
                        >
                            <app-btn
                                @click="addBlockScheduleItem"
                                class="mb-2"
                                color="secondary"
                                fab
                                small
                            >
                                <v-icon>mdi-plus</v-icon>
                            </app-btn>
                        </div>

                    </div>

                    <v-switch
                        v-if="currentForm.weekVsBlock !== 'week'"
                        v-model="currentForm.screeningOnWeekends"
                        label="Screen on Weekends"
                        color="secondary"
                    ></v-switch>
                    <v-switch
                        v-model="currentForm.screeningOnHolidays"
                        label="Screen on Holidays"
                        color="secondary"
                    ></v-switch>
                    <v-text-field
                        outlined
                        label="Duration"
                        v-model="currentForm.duration"
                        style="max-width: 100px"
                        dense
                    >

                    </v-text-field>
                </div>
                <div style="margin-top: 20px">
                    <AppBtn @click="submitSchedule" :loading="savingSchedule">Save Schedule</AppBtn>
                </div>

                <!--                   <app-btn-->
                <!--                       color="primary"-->
                <!--                       @click="submitSchedule"-->
                <!--                       class="mt-4"-->
                <!--                   >Save</app-btn>-->
                <!--                   <app-btn-->
                <!--                       color="primary"-->
                <!--                       @click="editingSchedule = false"-->
                <!--                       class="mt-4 ml-2"-->
                <!--                       outlined-->

                <!--                   >Cancel</app-btn>-->
            </div>
        </app-card>

<!--        <div style="display: flex; justify-content: center">-->
<!--            <EmptyState -->
<!--                :svg="emptyStateSvg"-->
<!--                button-->
<!--                button-text="Create Schedule"-->
<!--                description="This group does not have any active schedules"-->
<!--                @buttonClick="editingSchedule = true"-->
<!--            />-->


<!--        </div>-->

        <dialog-form
            title="Schedule COVID Screening"
            :open="covidScreeningDialogOpen"
            @close="covidScreeningDialogOpen = false"
            :width="800"
        >

        </dialog-form>
    </div>
</template>

<script>
import Calendar from "@/components/Calendar";
import moment from "moment";
import 'moment-timezone';
import {asLocalTime} from "@/js/shared/helper";
import {groupPreferencesModel, createOrUpdateGroupPreferences, getGroup} from '@/services/groups.service'
import {SET_SNACKBAR} from "@/store/mutation-types";
import EmptyState from "@/components/EmptyState";
import emptyStateSvg from '@/assets/svgs/empty-state/empty-group-schedule.svg'

export default {
    name: "GroupSchedule",
    components: {
        EmptyState,
        Calendar,
    },
    props: {
        group: {
            type: Object
        },
        groupPreferences: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            emptyStateSvg,
            cognitiveColor: '#68cb46',
            covidColor: '#68cb46',
            stabilityColor: '#68cb46',
            currentTab: 1,
            covidScreeningDialogOpen: false,
            editingSchedule: false,
            savingSchedule: false,

            covidPreferences: null,
            cognitivePreferences: null,
            stabilityPreferences: null,



            covidForm: {
                label: "COVID",
                screeningEnabled: false,
                screeningOnWeekdays: false,
                screeningOnWeekends: false,
                screeningOnHolidays: false,
                weekSchedule: [],
                blockSchedule: [],
                weekVsBlock: null,
                duration: 60,
                groupPreferencesId: null,
            },
            cognitiveForm: {
                label: "Cognitive",
                screeningEnabled: false,
                screeningOnWeekdays: false,
                screeningOnWeekends: false,
                screeningOnHolidays: false,
                weekSchedule: [],
                blockSchedule: [],
                weekVsBlock: null,
                duration: 60,
                groupPreferencesId: null,
            },
            stabilityForm: {
                label: "Stability",
                screeningEnabled: false,
                screeningOnWeekdays: false,
                screeningOnWeekends: false,
                screeningOnHolidays: false,
                weekSchedule: [],
                blockSchedule: [],
                weekVsBlock: null,
                duration: 60,
                groupPreferencesId: null,
            },
            // covidScreeningEnabled: false,
            // covidScreeningOnWeekends: false,
            // covidScreeningOnHolidays: false,
            picker: new Date().toISOString().substr(0, 10),
            // weekSchedule: [],
            // blockSchedule: [],
            events: [],
            weekdays: [
                {
                    label: 'Sunday',
                    value: 'sunday'
                },
                {
                    label: 'Monday',
                    value: 'monday'
                },
                {
                    label: 'Tuesday',
                    value: 'tuesday'
                },
                {
                    label: 'Wednesday',
                    value: 'wednesday'
                },
                {
                    label: 'Thursday',
                    value: 'thursday'
                },
                {
                    label: 'Friday',
                    value: 'friday'
                },
                {
                    label: 'Saturday',
                    value: 'saturday'
                },

            ],
            months: [
                {
                    text: 'January',
                    value: 0
                },
                {
                    text: 'February',
                    value: 1
                },
                {
                    text: 'March',
                    value: 2
                },
                {
                    text: 'April',
                    value: 3
                },
                {
                    text: 'May',
                    value: 4
                },
                {
                    text: 'June',
                    value: 5
                },
                {
                    text: 'July',
                    value: 6
                },
                {
                    text: 'August',
                    value: 7
                },
                {
                    text: 'September',
                    value: 8
                },
                {
                    text: 'October',
                    value: 9
                },
                {
                    text: 'November',
                    value: 10
                },
                {
                    text: 'December',
                    value: 11
                },
            ],
            

        }
    },
    computed: {
        blockAllowedDates(e) {
            
        },
        daysOfMonth() {
            let days = [];
            for (let i = 1; i < 31; i++) {
                days.push(i)
            }
            return i
        },
        hasAtLeastOneSchedule() {
            let hasSchedule = false;
            this.groupPreferences.forEach(x => {
                if (x.testScheduleCron !== null) hasSchedule = true
            })

            return hasSchedule
        },
        currentForm() {
            switch (this.currentTab) {
                case 0:
                    return this.cognitiveForm;
                case 1:
                    return this.covidForm;
                // case 2:
                //     return this.stabilityForm;
                default:
                    return this.covidForm
            }
        },
        currentDemandType() {
            switch (this.currentTab) {
                case 0:
                    return 0; // Demand type 1 = COVID
                case 1:
                    return 1; // Demand Type 0 = Cognitive
                case 2:
                    return 2; // Demand Type 2 = Stability
            }
        },
        emptyState() {
            let response = true
            this.groupPreferences.forEach(item => {
                if (item.reminderPushNotificationEnabled) response = false
            })
            
            return response
        }
    },
    mounted() {
        // this.addWeekScheduleItem()
        // this.addBlockScheduleItem()
        this.init();
        if (this.$route.query.editSchedule === "true") {
            this.editingSchedule = true;
            switch (this.$route.query.demandTypeId) {
                case "1":
                    this.currentTab = 1;
                    break;
                default:
                    this.currentTab = 0
            }
        }

    },

    methods: {
        init() {
            this.events.length = 0;
            this.setSchedulePreferences()
        },
        setSchedulePreferences() {
            const groupPreferences = this.groupPreferences;
            let cognitivePreferences = groupPreferences.find(x => x.demandTypeId === 2);
            let covidPreferences = groupPreferences.find(x => x.demandTypeId === 1);
            let stabilityPreferences = groupPreferences.find(x => x.demandTypeId === 3);

            // this.cognitivePreferences = cognitivePreferences ? cognitivePreferences : this.getEmptyPreferencesObject(1);
            this.covidPreferences = covidPreferences ? covidPreferences : this.getEmptyPreferencesObject(1);
            
            // this.stabilityPreferences = stabilityPreferences ? stabilityPreferences : this.getEmptyPreferencesObject(3);

            groupPreferences.forEach(item => {
                switch (item.demandTypeId) {
                    case 2:
                        this.cognitiveForm = this.getFormFromPreferences(item, 'Cognitive');
                        break;
                    case 1:
                        this.covidForm = this.getFormFromPreferences(item, 'COVID')
                        break;
                    case 3:
                        this.stabilityForm = this.getFormFromPreferences(item, 'Stability')
                }
            })

        },
        getFormFromPreferences(preferences, label) {
            let weekSchedule = [];
            let blockSchedule = [];
            let weekVsBlock;


            if (preferences.testScheduleCron) {
                const testScheduleCron = JSON.parse(preferences.testScheduleCron);
                if (testScheduleCron.WeeklySchedule) {
                    const {WeeklySchedule} = testScheduleCron;
                    WeeklySchedule.forEach(schedule => {
                        weekVsBlock = 'week';
                        weekSchedule.push({
                            day: schedule.DayOfWeek.toLowerCase(),
                            startTime: {
                                hour: schedule.Hour,
                                minute: parseInt(schedule.Minute),
                                amPm: schedule.AmPm.toLowerCase()
                            }
                        });

                        if (!preferences.reminderPushNotificationEnabled) return

                        // this.createEventsFromWeeklySchedule({
                        //     schedule,
                        //     testDuration: preferences.testDemandDurationMinutes,
                        //     label
                        // })
                    })
                }
                
                if (testScheduleCron.BlockSchedule) {
                    const {BlockSchedule} = testScheduleCron;
                    weekVsBlock = 'block';
                    BlockSchedule.forEach(schedule => {
                        let startDate = moment().year(moment().year()).month(schedule.StartMonth).date(schedule.StartDate);
                        let nextYear = moment().add(1, 'year').year()
                        
                        let endDate = moment().year(nextYear).month(schedule.EndMonth).date(schedule.EndDate).isAfter(startDate.clone().add(1, 'year'))
                            ? moment().year(nextYear - 1).month(schedule.EndMonth).date(schedule.EndDate) :
                            moment().year(nextYear).month(schedule.EndMonth).date(schedule.EndDate)
                        
                        blockSchedule.push({
                            startDate: startDate.format('YYYY-MM-DD'),
                            endDate: endDate.format('YYYY-MM-DD'),
                            startTime: {
                                hour: schedule.Hour,
                                minute: parseInt(schedule.Minute),
                                amPm: schedule.AmPm.toLowerCase()
                            }
                        })

                        // do not create events if its not enabled
                        if (!preferences.reminderPushNotificationEnabled) return

                        // this.createEventsFromBlockSchedule({
                        //     schedule,
                        //     testDuration: preferences.testDemandDurationMinutes,
                        //     label
                        // })
                    })
                }
                
                // let schedules = JSON.parse(preferences.testScheduleCron);
                // schedules.forEach(temp => {
                //    
                //     if ('WeeklySchedule' in temp) {
                //         let schedule = temp['WeeklySchedule'];
                //        
                //         weekVsBlock = 'week';
                //         weekSchedule.push({
                //             day: schedule.DayOfWeek,
                //             startTime: {
                //                 hour: schedule.Hour,
                //                 minute: schedule.Minute,
                //                 amPm: schedule.AmPm.toLowerCase()
                //             }
                //         })
                //         // do not create events if its not enabled
                //         if (!preferences.reminderPushNotificationEnabled) return
                //
                //         this.createEventsFromWeeklySchedule({
                //             schedule, 
                //             testDuration: preferences.testDemandDurationMinutes,
                //             label
                //         })
                //     } else {
                //         let schedule = temp['BlockSchedule']
                //         weekVsBlock = 'block'
                //         blockSchedule.push({
                //             startDate: moment().year(schedule.StartYear).month(schedule.StartMonth).date(schedule.StartDate).format('YYYY-MM-DD'),
                //             endDate: moment().year(schedule.EndYear).month(schedule.EndMonth).date(schedule.EndDate).format('YYYY-MM-DD'),
                //             startTime: {
                //                 hour: schedule.Hour,
                //                 minute: schedule.Minute,
                //                 amPm: schedule.AmPm.toLowerCase()
                //             }
                //         })
                //
                //         // do not create events if its not enabled
                //         if (!preferences.reminderPushNotificationEnabled) return
                //
                //         this.createEventsFromBlockSchedule({
                //             schedule,
                //             testDuration: preferences.testDemandDurationMinutes,
                //             label
                //         })
                //     }
                // })

            }

            return {
                label,
                groupPreferencesId: preferences.groupPreferencesId,
                screeningEnabled: preferences.reminderPushNotificationEnabled,
                screeningOnWeekdays: preferences.reminderPushNotificationWeekdays,
                screeningOnWeekends: preferences.reminderPushNotificationWeekends,
                screeningOnHolidays: preferences.reminderPushNotificationHolidays,
                weekSchedule: weekSchedule ? weekSchedule : [],
                blockSchedule: blockSchedule ? blockSchedule : [],
                weekVsBlock: weekVsBlock,
                duration: preferences.testDemandDurationMinutes,
            }
        },
        getEmptyPreferencesObject(demandTypeId) {
            return  {
                groupPreferencesId: null,
                organizationId: this.group.organizationId,
                groupId: this.group.id,
                reminderPushNotificationEnabled: true,
                reminderPushNotificationWeekdays: true,
                reminderPushNotificationWeekends: true,
                reminderPushNotificationHolidays: true,
                averageScoreThreshold: 0,
                createdByApp: "string",
                testScheduleCron: demandTypeId === 1 ? '' : false,
                testDemandDurationMinutes: 60,
                demandTypeId: demandTypeId
            }
        },
        addWeekScheduleItem() {
            this.currentForm.weekSchedule.push({
                day: 'sunday',
                startTime: {
                    hour: 12,
                    minute: 5,
                    amPm: 'am'
                }
            })
        },
        addBlockScheduleItem() {
            this.currentForm.blockSchedule.push({
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().add(1, 'year').format('YYYY-MM-DD'),
                startTime: {
                    hour: 12,
                    minute: 5,
                    amPm: 'am'
                },
            })
        },
        removeWeekScheduleItem(index) {
            let weekSchedule = [...this.currentForm.weekSchedule]
            weekSchedule.splice(index, 1);
            this.currentForm.weekSchedule = weekSchedule
        },
        removeBlockScheduleItem(index) {
            let blockSchedule = [...this.currentForm.blockSchedule]
            blockSchedule.splice(index, 1);
            this.currentForm.blockSchedule = blockSchedule
        },
        allowedDates(e, index) {
            let x = e
            return !moment(e).isBefore(moment(this.currentForm.blockSchedule[index].startDate)) && !moment(e).isAfter(moment().add(1, 'year'));
        },
        blockAllowedStartDates(e) {
            return !moment(e).isBefore(moment().subtract(2, 'day'))
                && !moment(e).isAfter(moment().add(1, 'year'))
        },
        async submitSchedule() {
            this.savingSchedule = true

            let groupPreferences = {}
            groupPreferences.groupId = this.group.id;
            // groupPreferences.TestDemandDurationMinutes = parseInt(this.duration)
            // groupPreferences.ReminderPushNotificationWeekdays = this.currentForm.weekVsBlock === 'week' ? false : this.currentForm.screeningOnWeekends;
            // groupPreferences.ReminderPushNotificationHolidays = this.currentForm.screeningOnHolidays;

            let blockSchedule = [];
            let weeklySchedule = [];
            
            this.currentForm.blockSchedule.forEach(block => {
                let startDate = moment(block.startDate)
                let endDate = moment(block.endDate)
                let startTime = block.startTime;
                blockSchedule.push({
                    // StartYear: startDate.year(),
                    StartMonth: startDate.format('MMMM'),
                    StartDate: startDate.date(),
                    // EndYear: endDate.year(),
                    EndMonth: endDate.format('MMMM'),
                    EndDate: endDate.date(),
                    Hour: startTime.hour,
                    Minute: startTime.minute < 10 ? '0' + startTime.minute : startTime.minute,
                    AmPm: startTime.amPm.toUpperCase(),
                }) 
            });

             this.currentForm.weekSchedule.forEach(row => {
                const {day, startTime} = row;
                const {hour, minute, amPm} = startTime;

                weeklySchedule.push({
                    DayOfWeek: day.charAt(0).toUpperCase() + day.slice(1),
                    Hour: hour,
                    Minute: minute < 10 ? '0' + minute : minute,
                    AmPm: amPm.toUpperCase()
                })
            });
             
             let testScheduleCron = this.currentForm.weekVsBlock === 'week' ? {WeeklySchedule: weeklySchedule} : {BlockSchedule: blockSchedule}
             console.log('SCHEDULE: ', testScheduleCron)
            // {"WeeklySchedule":[{"DayOfWeek":"Monday","Hour":"7","Minute":"30","AmPm":"AM"},{"DayOfWeek":"Tuesday","Hour":"7","Minute":"30","AmPm":"AM"}]}

            let request = {...groupPreferencesModel}
            request.groupPreferencesId = this.currentForm.groupPreferencesId;
            request.organizationId = this.group.organizationId;
            request.groupId = this.group.id;
            request.reminderPushNotificationEnabled = this.currentForm.screeningEnabled;
            request.reminderPushNotificationHolidays = this.currentForm.screeningOnHolidays;
            request.reminderPushNotificationWeekdays = this.currentForm.screeningOnWeekdays;
            request.reminderPushNotificationWeekends = this.currentForm.screeningOnWeekends;
            request.averageScoreThreshold = 0;
            request.createdByApp = 'portal';
            request.testScheduleCron = JSON.stringify(testScheduleCron);
            request.testDemandDurationMinutes = parseInt(this.currentForm.duration);
            
            // hardcode COVID
            request.demandTypeId = 1;

            const response = await createOrUpdateGroupPreferences(request);
            if (!response.isSuccess) return

            

            const groupResponse = await getGroup(this.$route.params.id);
            if (!response.isSuccess) {
                return
            }

            // 
            // this.groupPreferences = groupResponse.groupPreferences;
            await this.$emit('resetGroupPreferences', groupResponse.groupPreferences);
            this.init();

            this.$store.commit(SET_SNACKBAR, {
                text: 'Schedule Successfully Updated',
                color: 'success',
                open: true
            });
            this.savingSchedule = false


            // groupPreferences.TestDemandDurationMinutes = parseInt(this.duration)
            // groupPreferences.ReminderPushNotificationWeekdays = this.currentForm.weekVsBlock === 'week' ? false : this.currentForm.screeningOnWeekends;
            // groupPreferences.ReminderPushNotificationHolidays = this.currentForm.screeningOnHolidays;
            //    
            // groupPreferences.BlockSchedule = blockSchedule;
            // groupPreferences.WeeklySchedule = weeklySchedule;


            this.events = [];
            this.init()
            // if (this.currentForm.weekVsBlock === 'week') {
            //     this.createEventsFromWeeklySchedule(groupPreferences)
            // } else {
            //    
            //     this.createEventsFromBlockSchedule(groupPreferences);
            // }


        },

        createEventsFromBlockSchedule({schedule, testDuration, label}) {
            
            let events = [];
            
            const {reminderPushNotificationHolidays, reminderPushNotificationWeekends} = this.covidPreferences;            
            
            // let blockSchedule = testScheduleCron;


            const {
                StartYear: startYear,
                StartMonth: startMonth,
                StartDate: startDate,
                EndYear: endYear,
                EndMonth: endMonth,
                EndDate: endDate,
                Hour: hour,
                Minute: minute,
                AmPm: amPm
            } = schedule

            let startDateMoment = moment().year(startYear).month(startMonth).date(startDate)
            let endDateMoment = moment().year(endYear).month(endMonth).date(endDate)

            let daysDuration = moment.duration(endDateMoment.diff(startDateMoment)).as('days')

            for (let i = 0; i < daysDuration; i++) {

                // get the correct hour based on AmPm
                let calcHour;
                if (hour === 12 && amPm === 'PM') calcHour = hour
                else calcHour = amPm === 'PM' ? hour + 12 : hour;


                let startTime = asLocalTime(moment(startDateMoment)).add(i, 'days').hour(calcHour).minute(minute).second(0);
                let endTime = asLocalTime(moment(startTime)).add(testDuration, 'minutes');
                
                
                // remove weekends if excluded
                let startDay = startTime.day();
                let endDay = endTime.day()
                let isWeekend = ((startDay === 0 && endDay === 0) || (startDay === 6 && endDay === 6))
                
                if (!reminderPushNotificationWeekends && isWeekend) continue

                events.push({
                    color: this.getColorFromLabel(label),
                    start: asLocalTime(startTime).valueOf(),
                    name: `COVID Screening`,
                    end: endTime.valueOf(),
                    timed: true
                })
            }

            this.events = [...this.events, ...events];
            this.editingSchedule = false
        },
        createEventsFromWeeklySchedule({schedule, testDuration, label}) {
            if (!schedule) return

            // 
            let events = [];
            // let weeklySchedule = groupPreferences.WeeklySchedule;
            
            const {
                DayOfWeek: day,
                Hour: hour,
                Minute: minute,
                AmPm: amPm
            } = schedule;

            let weeksDuration = 52; // weeks per year

            for (let i = 0; i < weeksDuration; i++) {

                // get the correct hour based on AmPm
                let calcHour;
                if (hour === 12 && amPm === 'PM') calcHour = hour
                else calcHour = amPm === 'PM' ? hour + 12 : hour;


                let startTime = moment().add(i * 7, 'days').day(day).hour(calcHour).minute(minute).second(0);
                let endTime = moment(startTime).add(testDuration, 'minutes')
                events.push({
                    color: this.getColorFromLabel(label),
                    start: startTime.valueOf(),
                    name: `COVID Screening`,
                    end: endTime.valueOf(),
                    timed: true
                })
            }



            this.events = [...this.events, ...events];
            this.editingSchedule = false
        },
        getColorFromLabel(label) {
            return label === "COVID" ? this.stabilityColor : label === 'Cognitive' ? this.stabilityColor : this.stabilityColor;
        }

    },

}
</script>

<style scoped>
.v-text-field--outlined > .v-input__control > .v-input__slot:before, .v-text-field--outlined > .v-input__control > .v-input__slot:after, .v-text-field--solo > .v-input__control > .v-input__slot:before, .v-text-field--solo > .v-input__control > .v-input__slot:after, .v-text-field--rounded > .v-input__control > .v-input__slot:before, .v-text-field--rounded > .v-input__control > .v-input__slot:after {
    margin-bottom: 0!important
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0!important
}

.v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0!important
}
</style>