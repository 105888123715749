<template>
    <div>
        <app-tabs :value="currentTab" underline>
            <app-tab
                v-for="(tab, index) in tabs"
                :key="index"
                @click="$router.push(tab.route)"
                :icon="tab.icon"
            >{{ tab.label }}</app-tab>
        </app-tabs>
        <router-view
	        :billingInfo="billingInfo"
	        :billingChildOrgs="billingChildOrgs"
	        :accessChildOrgs="accessChildOrgs"
	        :accessParentOrgs="accessParentOrgs"
	        :allSportsSubscriptions="allSportsSubscriptions"
	        :allClinicalSubscriptions="allClinicalSubscriptions"
        />
    </div>
</template>

<script>
import {getBillingChildOrgs, getBillingInfo} from "@/services/billing.service";
import {getOrgConnects} from "@/services/organization.service";
import {baseUrl} from "@/config";
import httpService from "@/services/httpService";
import SportsSubscriptions from "@/js/shared/classes/Subscription/SportsSubscriptions";
import ClinicalSubscriptions from "@/js/shared/classes/Subscription/ClinicalSubscriptions";
import SportsSubscription from "@/js/shared/classes/Subscription/SportsSubscription";
import ClinicalSubscription from "@/js/shared/classes/Subscription/ClinicalSubscription";
import {orgConnectTypeSelectList, orgConnectStatusSelectList} from "@/js/shared/constants";

export default {
    name: "OrgManagement",
    data() {
        return {
	        accessParentOrgs: [],
	        accessChildOrgs: [],
	        billingChildOrgs: [],
	        billingInfo: null,
	        allSportsSubscriptions: [],
	        allClinicalSubscriptions: [],
            tabs: [
	            {
		            label: 'Overview',
		            route: `/org-management/overview`,
		            icon: 'mdi-view-dashboard'
	            },
                {
                    label: 'Usage',
                    route: `/org-management/usage`,
                    icon: 'mdi-chart-donut'
                },
                {
                    label: 'Subscription',
                    route: `/org-management/subscription`,
                    icon: 'mdi-calendar'
                },
	            {
		            label: 'Connections',
		            route: '/org-management/connections',
		            icon: 'mdi-vector-line'
	            },
                {
                    label: 'Measures',
                    route: `/org-management/measures`,
                    icon: 'mdi-clipboard-check-multiple-outline'
                },
	            
	            {
		            label: 'Reports',
		            route: `/org-management/reporting`,
		            icon: 'mdi-chart-areaspline'
	            },
	            
            ],
	        
	        orgConnectStatusSelectList,
	        orgConnectTypeSelectList,
        }
    },
	mounted() {
		this.getBillingChildOrgs();
		this.getOrgConnects();
		this.getBillingInfo()
		this.getSubscriptions()
	},
	methods: {
		async getSubscriptions() {
			

			let query2 = 'isSports=' + true;
			let endpoint2 = baseUrl + '/Billing/GetAllSubscriptions?' + query2;
			let response2 = await httpService.post(endpoint2);
			
			let sportsSubscriptions = response2.productSubscriptions.map(x => {
				return x.model.sports
			})
			
			this.allSportsSubscriptions = new SportsSubscriptions(sportsSubscriptions);
			
			let query3 = 'isSports=' + false;
			let endpoint3 = baseUrl + '/Billing/GetAllSubscriptions?' + query3;
			let response3 = await httpService.post(endpoint3);
			
			let clinicalSubscriptions = response3.productSubscriptions.map(x => {
				return x.model.clinical
			})
			
			this.allClinicalSubscriptions = new ClinicalSubscriptions(clinicalSubscriptions);
		},
		async getBillingInfo() {
			let response = await getBillingInfo();
			this.billingInfo = response;
			if (response.billToOrgId) {
				
				this.billingParentOrg = [{
					id: response.billToOrgId,
					name: response.billToOrgName
				}]
			}
		},
		async getBillingChildOrgs(){
			const result = await getBillingChildOrgs();
			if (!result.organizations) return
			result.organizations.forEach(x => {
				if (x.sports) x.sports = new SportsSubscription(x.sports)
				if (x.clinical) x.clinical = new ClinicalSubscription(x.clinical)
			})
			this.billingChildOrgs = result.organizations;
		},
		async getOrgConnects(){
			this.loadingOrgConnects = true;
			
			var orgConnects = await getOrgConnects();
			
			this.accessParentOrgs = orgConnects.parentOrgConnects.map(x => {
				x.connectionType = this.getConnectType(x.orgConnectTypeId)
				x.connectionStatus = this.getStatusType(x.orgConnectStatusTypeId)
				// x.connectionStatusChipColor = this.getStatusTypeChipColor(x.orgConnectStatusTypeId)
				return x
			});
			this.accessChildOrgs = orgConnects.childOrgConnects.map(x => {
				x.connectionType = this.getConnectType(x.orgConnectTypeId)
				x.connectionStatus = this.getStatusType(x.orgConnectStatusTypeId)
				// x.connectionStatusChipColor = this.getStatusTypeChipColor(x.orgConnectStatusTypeId)
				return x
			});
			
			this.loadingOrgConnects = null;
		},
		getConnectType(orgConnectTypeId){
			let connectionType = this.orgConnectTypeSelectList.find(x => x.value === orgConnectTypeId);
			return connectionType ? connectionType : null;
		},
		getStatusType(statusTypeId){
			let statusType = this.orgConnectStatusSelectList.find(x => x.value === statusTypeId);
			return statusType ? statusType : null;
		},
		// getStatusTypeChipColor(statusTypeId){
		// 	let statusType = this.orgConnectStatusSelectList.find(x => x.value === statusTypeId);
		// 	return statusType ? statusType.chipColor : null;
		// }
	},
    computed: {
        currentTab() {
            let items = this.$route.path.split('/')
            let last = items.pop();
			
			if (items[2] === "reporting") return 4
			
            switch (last) {

                case 'usage':
                    return 1
	            case 'subscription':
		            return 2
	            case 'connections':
		            return 3
                case 'measures':
                    return 4
	            
	            case 'reporting':
		            return 5
                default:
                    return 0
            }

        }
    }
}
</script>

<style scoped>

</style>