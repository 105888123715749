import {getProfilesPaged, getProfileAverages} from "@/services/profile.service";
import moment from "moment";
import {asLocalTime} from "@/js/shared/helper";

export default {
    data() {
        return {
            totalItems: null,
            tableSettings: {
                pageNumber: 1,
                rowsPerPage: 10,
                sortByColumn: '',
                sortAscending: false,
                searchString: '',
                graduationSearchString: '',
                groupIds: [],
                skipAverages: false
            },
            profiles: [],
            loading: true,
            loadingAverages: false,
            initialLoading: false,
            options: {},
            typingTimer: null,
            receivedFirstProfilesSet: false,
            requestNum: 0
        }
    },
    async mounted () {
        
        // await this.updateOptions('mounted');
        // this.initialLoading = false;
        // if (this.totalItems === 0) {
        //     this.$emit('setOrgHasZeroProfiles')
        // }
        //
        // this.$emit('finishedInitialLoading')

    },
    computed: {
        isViewingAsSuper(){
            let {viewingAsSuper} = this.$store.state.currentAccount;
            return viewingAsSuper;
        },
    },
    methods: {
        onRowClick(profile) {
            this.$router.push(`/profiles/${profile.id}/overview`)
        },

        updateSearchString(event) {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                this.tableSettings.pageNumber = 1;
                this.updateOptions()
                // alert('stopped typing')
            }, 300)
        },
        async updateOptions(options) {
            //mounted is not called before this because these mixin things petti uses are weird af
            //this is for initial load, setting to query param values
            if (this.requestNum === 0){
                this.options.page = parseInt(this.$route.query.PageNumber ?? '') || 1;
                this.tableSettings.pageNumber = parseInt(this.$route.query.PageNumber ?? '') || 1;
                this.options.itemsPerPage = parseInt(this.$route.query.RowsPerPage ?? '') || 10;
                this.options.sortBy[0] = this.$route.query.SortByColumn ?? '';
                this.options.sortDesc[0] = (this.$route.query.SortAscending === 'true') ?? false;
                this.tableSettings.searchString = this.$route.query.SearchString ?? '';
                this.tableSettings.graduationSearchString = this.$route.query.graduationSearchString ?? '';
                this.tableSettings.groupIds = this.$route.query.groupIds ?? [];
            }
            
            let {page, itemsPerPage, sortBy, sortDesc, groupBy, groupDesc} = this.options;
            if (itemsPerPage < 0) itemsPerPage = 99999999;
            //
            //

            //this.$route.query.testJordan = 1;
            
            this.requestNum++;
            const thisRequestNum = this.requestNum;

            if (this.groupId)
                this.tableSettings.groupIds = [this.groupId];

            let request = {
                PageNumber: page,
                RowsPerPage: itemsPerPage,
                SortByColumn: sortBy[0],
                SortAscending: sortDesc[0],
                SearchString: this.tableSettings.searchString,
                graduationSearchString: this.tableSettings.graduationSearchString,
                groupIds: this.tableSettings.groupIds,
                openEventsOnly: this.openEventsOnly,
            }
            
            this.$router.push({path: this.$route.path, query: {...request}})
            
            console.log('REQUEST: ', request);
            this.loading = true;
            let {profiles, totalRowCount} = await getProfilesPaged(request)
            this.$emit('setTotalRowCount', totalRowCount)

            let profileIds = profiles.map(x => x.id);
            
            if (this.requestNum == thisRequestNum) // avoid async errors, only update data is this is the latest response
                this.setProfiles({profiles})
            
            // this.profiles = [];
            // totalRowCount = 0

            if (this.profiles.length < 1) {
                this.$emit('setIsEmpty', true)
            } else {
                this.$emit('setIsEmpty', false)
            }

            this.totalItems = parseInt(totalRowCount);
            this.loading = false;
            
            this.initialLoading = false;
            const isSkip = this.tableSettings && this.tableSettings.skipAverages;
            if (!isSkip) {
                this.loadingAverages = true
                let averagesResponse = await getProfileAverages(profileIds);

                if (this.requestNum == thisRequestNum) // avoid async errors, only update data is this is the latest response
                    this.setProfiles({profiles, averages: averagesResponse.averages})
            }
            
            if (!this.receivedFirstProfilesSet) {
                this.receivedFirstProfilesSet = true;
                if (this.totalItems === 0) {
                    this.$emit('setOrgHasZeroProfiles')
                }

                this.$emit('finishedInitialLoading')
            }
            
            return
        },
        setProfiles({profiles, averages = null}) {
            this.profiles = profiles.map(x => {
                let {id, firstName, lastName, graduationYear, lastTestDate, inActiveGroup, inEvent} = x;
                
                console.log('isViewingAsSuper', this.isViewingAsSuper)
                
                //variable for fullname that uses ID if viewing as super, otherwise uses first and last name
                let fullName = this.isViewingAsSuper ? id : `${firstName} ${lastName}`;
                
                //variables for firstname and lastname that is blank string if viewing as super, otherwise uses first and last name
                firstName = this.isViewingAsSuper ? '' : firstName;
                lastName = this.isViewingAsSuper ? '' : lastName;
                
                return {
                    id,
                    firstName,
                    lastName,
                    graduationYear: graduationYear > 1950 ? graduationYear : null,
                    lastTestDate: lastTestDate ? asLocalTime(moment.utc(lastTestDate)).format('LLL z') : null,
                    inActiveGroup,
                    fullName: fullName,
                    averages: averages && averages[id] ? averages[id].allAverages : null,
                    inEvent
                }
            });
            if (averages) this.loadingAverages = false
        }

    },

}