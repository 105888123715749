<template>
    <div 
         style="width: 100%; min-height: 400px; display: flex; justify-content: center; align-items: center; flex-direction: column">
<!--        <v-progress-circular-->
<!--            indeterminate-->
<!--            color="primary"-->
<!--            :size="100"-->
<!--        ></v-progress-circular>-->
        <SwayLoader />
        <span class="mt-4 loading-text">
                {{text}}
            </span>

    </div>
</template>

<script>
import SwayLoader from "@/components/SwayLoader";
export default {
    name: "AppPageLoader",
    components: {SwayLoader},
    props: {
        text: {
            type: String,
            required: false
        }
    }
}
</script>

<style scoped>
.loading-text {
    color: var(--v-text-lighten3)
}
</style>