<template>
    <div>
        <app-card title="Security">
            <div class="pa-4">
                <h2>Mobile Device Security</h2>
                <div class="mt-2">Sway allows organizations to manage device security by enabling a local passcode on each device.</div>
                <div class="mb-2">Click Require PIN below to require a PIN/passcode on each device with Sway access.</div>
                <app-form-checkbox
                    checkbox-left-side
                    label="Require PIN"
                    v-model="isPasscodeEnabled"
                    class="pl-2"
                ></app-form-checkbox>
            </div>
            <div class="pa-4">
                <h2>Mobile Device Management</h2>
            </div>
            
            <v-data-table
                :headers="headers"
                :items="devices"
                style="border-top: 1px solid lightgrey"
            >
                <template #item.syncedOn="{item}" >
                    <td >
                        {{ item.syncedOnFormatted }}
                    </td>
                </template>
                <template #item.actions="{item}" >
                    <td @click="setDeviceToRemove(item)">

                            <v-icon  color="red">mdi-close-circle</v-icon>

                        Log Out Device
                    </td>
                </template>
                
            </v-data-table>
            <app-confirm-dialog
                :open="removeDeviceDialogOpen"
                title="Remove Device"
                @close="removeDeviceDialogOpen = false"
                submit-button-title="Remove"
                v-if="deviceToRemove"
                @confirm="removeDevice"
                confirm-delete
                :loading="deletingDevice"
            >
                Are you sure you want to remove device named "{{deviceToRemove.deviceName}}"?
            </app-confirm-dialog>
        </app-card>
    </div>
</template>

<script>
import {
    getDevices,
    organizationPreferencesModel,
    removeDevice,
    updateOrganizationPreferences,
    updatePasscodeEnabled
} from '@/services/organization.service'
import moment from 'moment'
import {SET_SNACKBAR} from "@/store/mutation-types";

export default {
    name: "OrganizationSecurity",
    props: {
        organizationPreferences: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            devices: [],
            deviceToRemove: null,
            deletingDevice: false,
            isPasscodeEnabled: this.organizationPreferences.isPasscodeEnabled,
            removeDeviceDialogOpen: false,
            savingPasscodeEnabledChanges: false,
            headers: [
                {
                    text: 'Device Name',
                    value: 'deviceName'
                },
                {
                    text: 'Synced On',
                    value: 'syncedOn'
                },
                {
                    text: 'Actions',
                    value: 'actions'
                },
                
            ]
        }
    },
    watch: {
        isPasscodeEnabled() {
            this.togglePasscodeEnabled()
        }  
    },
    mounted() {
        this.getDevices()
    },
    methods: {
        async getDevices() {
            let response = await getDevices();
            if (!response.isSuccess) {
                return
            }
            const {devices} = response
            this.devices = devices.map(device => {
                device.syncedOnFormatted = moment(device.syncedOn).format('MMM D, YYYY @ h:mm A')
                return device
            })
        },
        setDeviceToRemove(item) {
            this.deviceToRemove = item;
            this.removeDeviceDialogOpen = true
        },
        async togglePasscodeEnabled() {
            this.savingPasscodeEnabledChanges = true;
            
            const {
                isPasscodeEnabled,
            } = this;

            const response = await updatePasscodeEnabled(isPasscodeEnabled, this.organizationPreferences);
            if (!response.isSuccess) {
                this.savingChanges = false;
            }

            this.$store.commit(SET_SNACKBAR, {
                text: 'Require PIN Successfully Updated',
                color: 'success',
                open: true
            })

            const {organizationPreferences} = response;

            this.$emit('setOrganizationPreferences', organizationPreferences)
            // this.organizationPreferences = organizationPreferences
            this.savingChanges = false;
        },
        async removeDevice() {
            this.deletingDevice = true
            let id = this.deviceToRemove.id;
            let response = await removeDevice(id);
            if (!response.isSuccess) {
                return
            }
            await this.getDevices()
            this.removeDeviceDialogOpen = false
            this.deletingDevice = false
        }
    }
}
</script>

<style scoped>

</style>