<template>
    <v-container>
        <div>
            <div v-if="!loadingApiKeys">

                <div v-if="externalApiKeys && externalApiKeys.length > 0">
                    <page-header title="External Integrations">
                        <div slot="right-content">
                            <app-btn @click="addApiKeyDialogOpen = true">Create Integration/API Key</app-btn>
                        </div>
                    </page-header>
                    <ExternalApiTable
                        :apiKeys="externalApiKeys"
                        @editKey="open($event)"
                        @onRowClick="onRowClick"
                    />
                </div>
                <div v-else style="margin-top: 10vh">
                    <EmptyState
                        title="Generate an Integration/API Key."
                        description="Integrate with EHR to access Sway's API or External EHR API"
                        :svg="emptyStateSvg"
                        button
                        button-text="Create"
                        @buttonClick="addApiKeyDialogOpen = true"
                    />

                </div>

                <ExternalApiCreateForm
                    :open="addApiKeyDialogOpen"
                    @close="addApiKeyDialogOpen = false"
                    @apiKeyCreated="getExternalApiKeys"
                />


            </div>
            <div v-else>
                <AppPageLoader text="Loading Integrations..."/>

            </div>
        </div>

        <ExternalApiEditForm
            :open="editApiKeyModel !== null"
            :form="editApiKeyModel"
            @close="editApiKeyModel = null"
            @apiKeyEdited="getExternalApiKeys"
        />
    </v-container>
</template>

<script>
import {getExternalIntegrations, disableExternalIntegration} from "@/services/organization.service";
import ExternalApiTable from "@/components/OrganizationPages/ExternalApiTable";
import ExternalApiCreateForm from "@/components/OrganizationPages/ExternalApiCreateForm";
import EmptyState from "@/components/EmptyState";
import emptyStateSvg from '@/assets/svgs/empty-state/empty-group-sessions.svg'
import {removeCreditCard} from "@/services/billing.service";
import {SET_SNACKBAR} from "@/store/mutation-types";
import ExternalApiEditForm from "@/components/OrganizationPages/ExternalApiEditForm.vue";


// find info for stripe API here
// https://stripe.com/docs/js/element/mount

export default {
    name: "OrganizationApi",
    props: {

    },
    components: {
        ExternalApiEditForm,
        EmptyState,
        ExternalApiCreateForm,
        ExternalApiTable
    },
    data() {
        return {
            emptyStateSvg,
            // Dialog
            externalApiKeys: [],

            addApiKeyDialogOpen: false,
            loadingApiKeys: false,

            editApiKeyModel: null
        }
    },
    mounted() {
        // this.getBillingInfo()
        this.getExternalApiKeys();
    },
    computed: {
        
    },
    methods: {
        async getExternalApiKeys() {
            this.loadingApiKeys = true;
            
            let result = await getExternalIntegrations();
            this.externalApiKeys = result.models;
            
            this.loadingApiKeys = false;
        },
        onRowClick(item) {
            this.editApiKeyModel = item;
        },
    },
    
}
</script>

<style scoped>

</style>