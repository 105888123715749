
<template>
    <div class="pdf-profile-header-container" v-if="profileInfo">
        <div class="pdf-stat-label">PATIENT {{ viewingAsSuper ? 'ID' : 'NAME' }}</div>
        <div class="pdf-section-title">{{ viewingAsSuper ? profileInfo.id : profileInfo.name }}</div>
        <div v-if="!viewingAsSuper">
            <div class="pdf-stat-label">COMPLETED ON:</div>
            <div class="pdf-stat-value" style="text-transform: none">{{ completedOnDate }}</div>
            <div>
                <div class="pdf-info-row" >
        <span v-for="(stat, index) in stats" :key="index" >
          <span class="pdf-stat-label">{{stat.label}}:</span>
          <span class="pdf-stat-value">{{stat.value ? stat.value : profile.data[stat.name]}}</span>
        </span>
                </div>
            </div>
        </div>
        

    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "ProfileHeaderWithoutAdditionalData",
    props: {
        profile: {
            type: Object,
            required: true
        },
        profileTest: {
            required: true
        },
        // stats: {
        //     required: true
        // },
        completedOnDate: {
            required: true
        },
        viewingAsSuper: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        }
    },
    computed: {
        profileInfo() {
            return this.profile.profileInfo;
        },
        stats: function() {
            const {profileInfo} = this;

            return [
                {
                    label: 'DOB',
                    name: 'birthDate',
                    value: profileInfo.displayBirthdate
                },
                {
                    label: 'Age',
                    name: 'age',
                    value: profileInfo.age
                },
                {
                    label: 'Height',
                    name: 'height',
                    value: profileInfo.displayHeight
                },
                {
                    label: 'Weight',
                    name: 'weight',
                    value: profileInfo.displayWeight

                },
                {
                    label: 'Sex',
                    name: 'sex',
                    value: profileInfo.gender
                },
                
                
                
            ]
        },
    },

}
</script>

<style scoped>

</style>

