<template>

    <div style="height: 100vh; overflow-y: scroll">
<!--        <div style="height: 3000px; background-color: yellow">-->
<!--            Words-->
<!--        </div>-->
        
<!--        <div style="position: absolute; height: 100px; width: 100px; bottom: 20px; right: 20px; z-index: 999">-->
<!--            <v-progress-circular :size="100" :rotate="-90" :value="countdownValue" color="primary">-->
<!--                <div>-->
<!--                    <h1>{{ countdownDisplay }}</h1>-->
<!--                </div>-->
<!--                -->
<!--            </v-progress-circular>-->
<!--        </div>-->
        <div style="width: 100%; height: 60px; background-color: #2d2f34; display: flex; align-items: center; padding: 0 20px">
   
                <div style="transform: translateY(5px); flex: 1">
                    <svg width="125" height="35" viewBox="0 0 125 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.3035 7.32427C39.3035 4.79047 41.5748 2.81094 45.1384 2.81094C47.958 2.81094 50.5034 3.72152 53.0489 5.81983L54.8503 3.4048C52.0698 1.14813 49.2111 0 45.2167 0C40.0084 0 36.2098 3.16725 36.2098 7.60141C36.2098 12.1939 39.1077 14.2526 45.4125 15.6383C51.1692 16.8656 52.5398 18.3305 52.5398 20.9435C52.5398 23.7544 50.1118 25.6943 46.4307 25.6943C42.6321 25.6943 39.8909 24.3879 37.0322 21.7749L35.1133 24.0711C38.4028 27.0404 41.9664 28.5053 46.3132 28.5053C51.7566 28.5053 55.6726 25.4172 55.6726 20.6267C55.6726 16.3509 52.8531 14.0943 46.744 12.7482C40.7133 11.4417 39.3035 9.97685 39.3035 7.32427Z" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M72.838 7.56165H70.3317L64.8883 24.071L59.5625 7.64083H56.3513L63.4394 28.2676H66.1023L71.5848 12.1146L77.0282 28.2676H79.6519L86.7792 7.64083H83.6463L78.3205 24.071L72.838 7.56165Z" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M95.4512 17.7365C97.9183 17.7365 99.9155 18.172 101.404 18.6075V20.587C101.404 23.8335 98.3491 26.1297 94.6288 26.1297C91.8876 26.1297 89.5379 24.6253 89.5379 22.0123C89.5379 19.3993 91.6526 17.7365 95.4512 17.7365ZM95.3337 10.0559C99.1323 10.0559 101.364 11.9563 101.364 15.6382V16.3508C99.5631 15.8362 97.7225 15.4798 95.1379 15.4798C90.0079 15.4798 86.4834 17.7761 86.4834 22.1311C86.4834 26.4069 90.282 28.5448 94.0414 28.5448C97.605 28.5448 99.9547 26.8819 101.364 25.0212V28.1093H104.262V15.5986C104.262 10.2143 101.169 7.36377 95.647 7.36377C92.7099 7.36377 90.5169 8.03681 88.2848 9.06617L89.1855 11.5604C91.0652 10.6894 92.9449 10.0559 95.3337 10.0559Z" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M125 7.64099H121.789L115.288 24.7046L107.926 7.64099H104.597L113.8 28.0302C112.586 30.9203 111.451 31.8705 109.649 31.8705C108.357 31.8705 107.456 31.6329 106.477 31.1183L105.459 33.5333C106.83 34.2063 108.083 34.5627 109.767 34.5627C112.821 34.5627 114.779 33.0186 116.502 28.7824L125 7.64099Z" fill="white"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 23.54V29.2125H19.6357V23.54H0Z" fill="#34D3FE"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6 17V22.6725H25.6409V17H6Z" fill="#34D3FE"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 10.46V16.1326H19.6357V10.46H0Z" fill="#34D3FE"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.69897 3.91296V9.58551H25.3399V3.91296H5.69897Z" fill="#34D3FE"/>
                    </svg>
                </div>
<!--            <div >-->
<!--                <router-link to="/" style="color: white; text-decoration: none">-->
<!--                    Back to Portal-->
<!--                </router-link>-->
<!--                -->
<!--            </div>-->

            
            

        </div> 
        <div v-if="loadingDailyTestCounts" >
            <div style="display: flex; justify-content: center; align-items: center; height: calc(100vh - 60px)">
                <v-progress-circular indeterminate :size="200" color="primary"><span style="color: #757575">Loading Data...</span></v-progress-circular>
            </div>
        </div>
        <div v-else >
            <div class="pa-4">
                <v-container style="max-width: 10000px; max-height: 1000px" >
                    <v-row class="mb-3">
                        <v-col cols="12">
                            <h2>Stats (Only Updated ONCE PER HOUR)</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" v-for="(item, itemIndex) in totalStats">
                            <StatCard :item="item" :is-ready-check="false" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" v-for="(item, itemIndex) in stats">
                            <StatCard :item="item" :is-ready-check="false" />
                        </v-col>
                    </v-row>
                    <v-row class="mb-3 mt-3">
                        <v-col cols="12">
                            <h2>Test History</h2>
                        </v-col>
                    </v-row>
                    <v-row >
                        <v-col cols="12">
                            <app-card hide-header>
                      
                                <div >
                                    <div>

                                        <v-chip
                                            label
                                            v-for="item in chips"
                                            class="ma-2"
                                            :color="item.value === dailyCountsTypeShown ? 'primary' : 'light'"
                                            @click="selectChartDataType(item.value)"
                                        >{{item.label}}</v-chip>

                                    </div>
                                    <div>
                                        <highcharts :options="chartOptions" :constructor-type="'stockChart'" v-if="chartOptions"></highcharts>
                                    </div>


                                </div>
                            </app-card>
                        </v-col>
                        <!--                    <v-col cols="12">-->
                        <!--                        <app-card>-->
                        <!--                            <v-simple-table>-->
                        <!--                                <template v-slot:default>-->
                        <!--                                    <thead>-->
                        <!--                                    <tr>-->
                        <!--                                        <th class="text-center" colspan="2">-->
                        <!--                                            2017-->
                        <!--                                        </th>-->
                        <!--                                        <th class="text-center" colspan="2">-->
                        <!--                                            2018-->
                        <!--                                        </th>-->
                        <!--                                        <th class="text-center" colspan="2">-->
                        <!--                                            2019-->
                        <!--                                        </th>-->
                        <!--                                        <th class="text-center" colspan="2">-->
                        <!--                                            2020-->
                        <!--                                        </th>-->
                        <!--                                        <th class="text-center" colspan="2">-->
                        <!--                                            2021-->
                        <!--                                        </th>-->
                        <!--                                    </tr>-->
                        <!--                                    <tr>-->
                        <!--                                        <template v-for="year in [1,2,3,4,5]">-->
                        <!--                                            <th class="text-center">-->
                        <!--                                                Sway-->
                        <!--                                            </th>-->
                        <!--                                            <th class="text-center">-->
                        <!--                                                ReadyCheck-->
                        <!--                                            </th>-->
                        <!--                                        </template>-->
                        <!--                                        -->
                        <!--      -->
                        <!--                                    </tr>-->
                        <!--                                    </thead>-->
                        <!--                                    <tbody>-->
                        <!--                                    <tr-->
                        <!--                                        v-for="item in desserts"-->
                        <!--                                        :key="item.name"-->
                        <!--                                    >-->
                        <!--                                        <td>{{ item.name }}</td>-->
                        <!--                                        <td>{{ item.calories }}</td>-->
                        <!--                                    </tr>-->
                        <!--                                    </tbody>-->
                        <!--                                </template>-->
                        <!--                                -->
                        <!--                            </v-simple-table>-->
                        <!--                        </app-card>-->
                        <!--                    </v-col>-->
                    </v-row>
                </v-container>
            </div>
        </div>
        
        
        

        
    </div>
    
</template>

<script>
import {getHistoricalDailyTestCounts, getTotalTestCount, getDailyNonCovidTestCountOnly, getTodayTotalTestCount, getTotalProfileCount, getTotalOrgCount} from '@/services/superAdmin.service';
import SwayLogo from "@/assets/svgs/sway-logo.svg"
import RcLogo from "@/assets/svgs/rc-logo.svg"

import moment from 'moment'
import StatCard from "@/components/SwayDashboard/StatCard";
export default {
    name: "SwayDashboard",
    components: {
        StatCard
    },
    data() {
        return {
            countdownInterval: {},
            countdownDisplay: 60,
            countdownValue: 0,
            swayLogo: SwayLogo,
            rcLogo: RcLogo,
            updatingPage: false,
            loadingDailyTestCounts: false,
            loadingRcDailyTestCounts: false,
            rcDailyTestCounts: null,
            rcDailyCountsFormatted: null,
            rcOrgCount: null,
            rcProfileCount: null,
            rcTotalTestCount: null,
            rcTotalCovidTestCount: null,
            rcTotalNonCovidTestCount: null,
            
            swayDailyTestCounts: null,
            swayDailyCountsFormatted: null,
            swayOrgCount: null,
            swayProfileCount: null,
            swayTotalTestCount: null,
            swayTotalCovidTestCount: null,
            swayTotalNonCovidTestCount: null,
            dailyCountsCategories: null,
            
            dailyCountsTypeShown: 'totalTestCount',
            selectedTimeWindow: {
                value: 'year',
                time: {
                    number: 20,
                    type: 'year'
                }
            },
            
            swayStats: null,
            rcStats: null,
            

            // windowChips: [
            //     {
            //         label: '1 Week',
            //         value: 'week',
            //         time: {
            //             number: 1,
            //             type: 'week'
            //         }
            //     },
            //     {
            //         label: '1 Month',
            //         value: 'oneMonth',
            //         time: {
            //             number: 1,
            //             type: 'month'
            //         }
            //     },
            //     {
            //         label: '3 Months',
            //         value: 'threeMonths',
            //         time: {
            //             number: 3,
            //             type: 'month'
            //         }
            //     },
            //     {
            //         label: '1 Year',
            //         value: 'year',
            //         time: {
            //             number: 1,
            //             type: 'year'
            //         }
            //     },
            //     {
            //         label: 'All Time',
            //         value: 'allTime',
            //         time: {
            //             number: 100,
            //             type: 'years'
            //         }
            //     },
            // ],
            
            chips: [
                {
                    label: 'All',
                    value: 'totalTestCount'
                },
                {
                    label: 'Non-COVID',
                    value: 'nonCovidTestCount'
                },
                {
                    label: 'COVID',
                    value: 'covidTestCount'
                },
                {
                    label: 'Balance',
                    value: 'balanceTestCount'
                },
                {
                    label: 'Reaction Time',
                    value: 'reactionTimeTestCount'
                },
                {
                    label: 'Impulse Control',
                    value: 'impulseControlTestCount'
                },
                {
                    label: 'Inspection Time',
                    value: 'inspectionTimeTestCount'
                },
                {
                    label: 'Memory',
                    value: 'memoryTestCount'
                },
                {
                    label: 'Number Counting',
                    value: 'numberCountingTestCount'
                },
                
            ],
            
            statCards1: {
                swayCovid: {
                    label: 'Sway Covid',
                    count: 20,
                },
                swayNonCovid: {
                    label: 'Sway Covid',
                    count: 20,
                },
                rcCovid: {
                    label: 'Sway Covid',
                    count: 20,
                },
                rcNonCovid: {
                    label: 'Sway Covid',
                    count: 20,
                },
            },
            
            statCards: [
                {
                    label: 'Sway Covid',
                    count: 20,
                },
                {
                    label: 'Sway Non-Covid',
                    count: 20,
                },
                // {
                //     label: 'ReadyCheck Covid',
                //     count: 20,
                // },
                // {
                //     label: 'ReadyCheck Non-Covid',
                //     count: 20,
                // },
                {
                    label: 'Sway Orgs',
                    count: 20,
                },
                {
                    label: 'Sway Profiles',
                    count: 20,
                },
                // {
                //     label: 'ReadyCheck Orgs',
                //     count: 20,
                // },
                // {
                //     label: 'ReadyCheck Profiles',
                //     count: 20,
                // },
            ]
        }
    },
    mounted() {
        this.getDailyTestCounts();
        this.getTotalTestCount();
        
    },
    computed: {
        stats() {
            if (!this.swayStats) return null
            
            return [
                {
                    label: 'Sway COVID',
                    value: this.swayStats.covid,
                    logo: this.swayLogo
                },
                {
                    label: 'Sway Non-COVID',
                    value: this.swayStats.nonCovid,
                    logo: this.swayLogo
                },
                // {
                //     label: 'ReadyCheck COVID',
                //     value: this.rcStats.covid,
                //     logo: this.rcLogo
                // },
                // {
                //     label: 'ReadyCheck Non-COVID',
                //     value: this.rcStats.nonCovid,
                //     logo: this.rcLogo
                // },
                {
                    label: 'Sway Orgs',
                    value: this.swayStats.orgs,
                    logo: this.swayLogo
                },
                {
                    label: 'Sway Profiles',
                    value: this.swayStats.profiles,
                    logo: this.swayLogo
                },
                // {
                //     label: 'ReadyCheck Orgs',
                //     value: this.rcStats.orgs,
                //     logo: this.rcLogo
                // },
                // {
                //     label: 'ReadyCheck Profiles',
                //     value: this.rcStats.profiles,
                //     logo: this.rcLogo
                // },
                
            ]
        },
        totalStats() {
            if (!this.swayStats) return null

            return [
                {
                    label: 'Sway Total',
                    value: this.swayStats.totalTests,
                    logo: this.swayLogo
                },
                // {
                //     label: 'ReadyCheck Total',
                //     value: this.rcStats.totalTests,
                //     logo: this.rcLogo
                // },
            ]
        },
        chartOptions() {
            if (!this.swayDailyCountsFormatted) return null
            
            let swayData = null;
            if (this.swayDailyCountsFormatted) {
                swayData = this.swayDailyCountsFormatted[this.dailyCountsTypeShown];
            }
            
            // let rcData = null;
            // if (this.rcDailyCountsFormatted) {
            //     rcData = this.rcDailyCountsFormatted[this.dailyCountsTypeShown];
            // }


            return {
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },

                subtitle: {
                    text: ''
                },

                yAxis: {
                    title: {
                        text: 'Number of Tests'
                    }
                },

                xAxis: {
                    accessibility: {
                        rangeDescription: 'Range: 2010 to 2017'
                    },
                    type: 'datetime'
                    // categories: this.dailyCountsCategories
                },

                // legend: {
                //     layout: 'vertical',
                //     align: 'right',
                //     verticalAlign: 'middle'
                // },

                plotOptions: {
                    series: {
                        label: {
                            connectorAllowed: false
                        },
                    }
                },

                series: [{
                    name: 'Sway Test Count',
                    data: swayData
                },
                //     {
                //     name: 'ReadyCheck Test Count',
                //     data: rcData
                // },
                ],

                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 500
                        },
                        chartOptions: {
                            legend: {
                                layout: 'horizontal',
                                align: 'center',
                                verticalAlign: 'bottom'
                            }
                        }
                    }]
                }
            }
        }
    },
    methods: {
        setCountdown() {
            clearInterval(this.countdownInterval)
            this.countdownDisplay = 60;
            this.countdownValue = 0
            this.countdownInterval = setInterval(() => {
                if (this.countdownValue === 100) {
                    return clearInterval(this.countdownInterval)
                    
                }

                this.countdownDisplay -= 1
                this.countdownValue += 1.66
            }, 1000)
        },
        selectTimeWindow(item) {

            this.selectedTimeWindow = {
                value: item.value,
                type: item.type
            }

        },
        selectChartDataType(value) {
            this.dailyCountsTypeShown = value;
        },
        async getDailyTestCounts() {
            this.loadingDailyTestCounts = true
            let startDate = moment().subtract(this.selectedTimeWindow.time.number, this.selectedTimeWindow.time.type).format();
            let swayStartDate = moment("8/1/2013").format();
            let rcStartData = moment("12/17/2018").format();  
            let endDate = moment().add(1,'day').format();
            
            let swayRequest = {
                startDate: swayStartDate,
                endDate,
                isReadyCheck: false
            }

            let swayOrgRequest = {
                isReadyCheck: false
            }
            
            // console.log('SWAY REQUEST ORIGINAL: ', swayRequest)
            
            let rcRequest = {
                startDate: rcStartData,
                endDate,
                isReadyCheck: true
            }

            let rcOrgRequest = {
                isReadyCheck: true
            }

            
            
            let response = await Promise.all([
                getHistoricalDailyTestCounts(swayRequest),
                //getHistoricalDailyTestCounts(rcRequest),
                getTotalOrgCount(swayOrgRequest),
                //getTotalOrgCount(rcOrgRequest),
            ]);

            let swayDailyTestCounts = response[0].counts;
            //let rcDailyTestCounts = response[1].counts;
            let swayOrgCount = response[1].count;
            //let rcOrgCount = response[3].count;
            
            this.swayDailyTestCounts = swayDailyTestCounts;
            this.swayOrgCount = swayOrgCount;
            this.swayDailyCountsFormatted = this.getFormattedDailyCounts('sway')
            
            //this.rcDailyTestCounts = rcDailyTestCounts;
            //this.rcOrgCount = rcOrgCount;
            //this.rcDailyCountsFormatted = this.getFormattedDailyCounts('rc')
            
            await this.updatePage()
            
            // this.swayStats = this.calcTestsThisWeek('sway');
            // this.rcStats = this.calcTestsThisWeek('rc');

            this.loadingDailyTestCounts = false;
            // this.initiatePageUpdate()
            
        },
        initiatePageUpdate() {
            //disabling this for now, as it's too resource intensive
            // setTimeout(() => {
            //     this.updatePage()
            // }, 60*1000)
        },
        async updatePage() {
            this.updatingPage = true;
            
            let swayRequestCovid = {
                covidOnly: true,
                nonCovidOnly: false,
                isReadyCheck: false
            };
            
            let swayRequestNonCovid = {
                covidOnly: false,
                nonCovidOnly: true,
                isReadyCheck: false
            };

            let swayRequestProfiles = {
                isReadyCheck: false
            };

            let rcRequestCovid = {
                covidOnly: true,
                nonCovidOnly: false,
                isReadyCheck: true
            };

            let rcRequestNonCovid = {
                covidOnly: false,
                nonCovidOnly: true,
                isReadyCheck: true
            };

            let rcRequestProfiles = {
                isReadyCheck: true
            };

            let startDate = null;
            let endDate = null;
            let covidOnly = false;
            let nonCovidOnly = false;
            let isReadyCheck = false;
            let totalSwayTestCountRequest = {isReadyCheck}
            isReadyCheck = true;
            let totalRcTestCountRequest = {isReadyCheck}
            
            
            

            let response = await Promise.all([
                getTodayTotalTestCount(swayRequestCovid),
                getTodayTotalTestCount(swayRequestNonCovid),
                //getTodayTotalTestCount(rcRequestCovid),
                //getTodayTotalTestCount(rcRequestNonCovid),
                getTotalProfileCount(swayRequestProfiles),
                //getTotalProfileCount(rcRequestProfiles),
                getTotalTestCount(totalSwayTestCountRequest),
                //getTotalTestCount(totalRcTestCountRequest)
            ]);
            //
            // let response = [];
            //
            // response.push(await getTodayTotalTestCount(swayRequestCovid))
            // response.push(await getTodayTotalTestCount(swayRequestNonCovid))
            // response.push(await getTodayTotalTestCount(rcRequestCovid))
            // response.push(await getTodayTotalTestCount(rcRequestNonCovid))
            
            let swayTodayTotalTestCounts = response[0].testCount;
            let swayTodayTotalNonCovidOnlyTestCounts = response[1].testCount;
            //let rcTodayTotalTestCounts = response[2].testCount;
            //let rcTodayTotalNonCovidOnlyTestCounts = response[3].testCount;
            let swayProfileCount = response[2].count;
            //let rcProfileCount = response[5].count;
            let swayTotalTestCount = response[3].testCount.totalTestCount;
            let swayTotalCovidTestCount = response[3].testCount.covidTestCount;
            let swayTotalNonCovidTestCount = response[3].testCount.nonCovidTestCount;
            //let rcTotalTestCount = response[7].testCount.totalTestCount;
            //let rcTotalCovidTestCount = response[7].testCount.covidTestCount;
            //let rcTotalNonCovidTestCount = response[7].testCount.nonCovidTestCount;
            
            //
            
            let swayDailyTestCounts = this.swayDailyTestCounts;
            //let rcDailyTestCounts= this.rcDailyTestCounts;
            // 
            
            swayDailyTestCounts[swayDailyTestCounts.length - 1].covidTestCount = swayTodayTotalTestCounts;
            swayDailyTestCounts[swayDailyTestCounts.length - 1].nonCovidTestCount = swayTodayTotalNonCovidOnlyTestCounts;
            //rcDailyTestCounts[rcDailyTestCounts.length - 1].covidTestCount = rcTodayTotalTestCounts;
            //rcDailyTestCounts[rcDailyTestCounts.length - 1].nonCovidTestCount = rcTodayTotalNonCovidOnlyTestCounts;
            
            this.swayDailyTestCounts = swayDailyTestCounts;
            //this.rcDailyTestCounts = rcDailyTestCounts;
            this.swayProfileCount = swayProfileCount;
            //this.rcProfileCount = rcProfileCount;
            this.swayTotalTestCount = swayTotalTestCount;
            this.swayTotalCovidTestCount = swayTotalCovidTestCount;
            this.swayTotalNonCovidTestCount = swayTotalNonCovidTestCount;
            //this.rcTotalTestCount = rcTotalTestCount;
           // this.rcTotalCovidTestCount = rcTotalCovidTestCount;
            //this.rcTotalNonCovidTestCount = rcTotalNonCovidTestCount;

            this.swayStats = this.calcTestsThisWeek('sway');
            //this.rcStats = this.calcTestsThisWeek('rc');

            //only call for tests and profiles if still on Sway Dashboard
            if(this.$router.currentRoute.name === "Sway Dashboard"){
                this.initiatePageUpdate();
            }
            
            this.setCountdown()

            this.updatingPage = false;
        },
        getFormattedDailyCounts(company) {
            let dailyTestCounts = company === 'rc' ? this.rcDailyTestCounts : this.swayDailyTestCounts;
            // let nonCovidOnlyDailyTestCount = company === 'rc' ? this.rcNonCovidOnlyDailyTestCounts : this.swayNonCovidOnlyDailyTestCounts
            
            let countsFormatted = {
                balanceTestCount: [],
                covidTestCount: [],
                cuedStroopTestCount: [],
                impulseControlTestCount: [],
                inspectionTimeTestCount: [],
                memoryTestCount: [],
                numberCountingTestCount: [],
                otherSurveyTestCount: [],
                reactionTimeTestCount: [],
                totalTestCount: [],
                nonCovidTestCount:[]
            };

            dailyTestCounts.forEach((day, index) => {
                // if (index === dailyTestCounts.length - 1) return
                // categories.push(moment(day.date).format('M/D/YY'));
                let datetime = moment(day.date).valueOf()
                countsFormatted.balanceTestCount.push([datetime, day.balanceTestCount]);
                countsFormatted.covidTestCount.push([datetime, day.covidTestCount]);
                countsFormatted.cuedStroopTestCount.push([datetime, day.cuedStroopTestCount]);
                countsFormatted.impulseControlTestCount.push([datetime, day.impulseControlTestCount]);
                countsFormatted.inspectionTimeTestCount.push([datetime, day.inspectionTimeTestCount]);
                countsFormatted.memoryTestCount.push([datetime, day.memoryTestCount]);
                countsFormatted.numberCountingTestCount.push([datetime, day.numberCountingTestCount]);
                countsFormatted.otherSurveyTestCount.push([datetime, day.otherSurveyTestCount]);
                countsFormatted.reactionTimeTestCount.push([datetime, day.reactionTimeTestCount]);
                countsFormatted.totalTestCount.push([datetime, day.totalTestCount]);
                countsFormatted.nonCovidTestCount.push([datetime, day.nonCovidTestCount]);
            });

            return countsFormatted;
        },
        calcTestsThisWeek(company) {
            const testCountType = company === 'rc' ? 'rcDailyTestCounts' :'swayDailyTestCounts';
            
            let testCounts = this[testCountType];
            
            // 
            let dayInWeek = moment().day();
            let date = moment().date();
            let yearStart = moment().year(moment().year()).month(0).date(1).hour(0).minute(0).second(0);
            let dayOfYear = moment().diff(yearStart, 'days')

            // we want to take out the most
            
            let todayTestCounts = this[testCountType].slice(-1)
            let thisWeekTestCounts = this[testCountType].slice(-1 * (1 + dayInWeek));
            let thisMonthTestCounts = this[testCountType].slice(-1 * (date));
            let thisYearTestCounts = this[testCountType].slice(-1 * dayOfYear);
            
            let covidTestToday = todayTestCounts[0].covidTestCount;
            let covidTestThisWeek = 0;
            let covidTestsThisMonth = 0;
            let covidTestsThisYear = 0;
            
            let nonCovidTestToday = todayTestCounts[0].nonCovidTestCount;
            let nonCovidTestThisWeek = 0;
            let nonCovidTestsThisMonth = 0;
            let nonCovidTestsThisYear = 0;
            
            
            
            thisWeekTestCounts.forEach(day => {
                
                covidTestThisWeek += day.covidTestCount;
                nonCovidTestThisWeek += day.nonCovidTestCount
            });

            thisMonthTestCounts.forEach(day => {
                covidTestsThisMonth += day.covidTestCount;
                nonCovidTestsThisMonth += day.nonCovidTestCount
            });

            thisYearTestCounts.forEach(day => {
                covidTestsThisYear += day.covidTestCount;
                nonCovidTestsThisYear += day.nonCovidTestCount
            });
            
            let profileCount = 0;
            let orgCount = 0;
            let totalTestCount = 0;
            let totalCovidTestCount = 0;
            let totalNonCovidTestCount = 0;
            if (company === 'rc'){
                profileCount = this.rcProfileCount;
                orgCount = this.rcOrgCount;
                totalTestCount = this.rcTotalTestCount;
                totalCovidTestCount = this.rcTotalCovidTestCount;
                totalNonCovidTestCount = this.rcTotalNonCovidTestCount;
            }else{
                profileCount = this.swayProfileCount;
                orgCount = this.swayOrgCount;
                totalTestCount = this.swayTotalTestCount;
                totalCovidTestCount = this.swayTotalCovidTestCount;
                totalNonCovidTestCount = this.swayTotalNonCovidTestCount;
            }
            
            return {
                covid: [
                    {
                        label: 'All-Time COVID Tests',
                        value: totalCovidTestCount,
                        type: 'total'
                    },
                    {
                        label: 'tests today',
                        value: covidTestToday,
                        type: 'day'
                    },
                    {
                        label: 'tests this week',
                        value: covidTestThisWeek,
                        type: 'week'
                    },
                    {
                        label: 'tests this month',
                        value: covidTestsThisMonth,
                        type: 'month'
                    },
                    {
                        label: 'tests this year',
                        value: covidTestsThisYear,
                        type: 'year'
                    },
                ], 
                nonCovid: [
                    {
                        label: 'All-Time Non-COVID Tests',
                        value: totalNonCovidTestCount,
                        type: 'total'
                    },
                    {
                        label: 'tests today',
                        value: nonCovidTestToday,
                        type: 'day'
                    },
                    {
                        label: 'tests this week',
                        value: nonCovidTestThisWeek,
                        type: 'week'
                    },
                    {
                        label: 'tests this month',
                        value: nonCovidTestsThisMonth,
                        type: 'month'
                    },
                    {
                        label: 'tests this year',
                        value: nonCovidTestsThisYear,
                        type: 'year'
                    },
                ],
                totalTests: [
                    {
                        label: 'Total Tests',
                        value: totalTestCount,
                        type: 'total'
                    }
                ],
                profiles: [
                    {
                        label: 'Total Profiles',
                        value: profileCount,
                        type: 'day'
                    }
                ],
                orgs: [
                    {
                        label: 'Total Active Orgs',
                        value: orgCount,
                        type: 'day'
                    }
                ],
            }
            
            
        },
        async getTotalTestCount() {
            let startDate = moment().subtract(7, 'days').format();
            let endDate = moment().format();
            startDate = null;
            endDate = null;
            let covidOnly = false;
            let nonCovidOnly = false;
            let request = {startDate, endDate, covidOnly, nonCovidOnly}
        },
        
        

    }
}
</script>

<style scoped>
html, body {
    overflow-y: scroll!important
}
</style>