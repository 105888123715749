<template>
    <div style="max-width: 500px">
        <div style="font-size: 16px; font-weight: bold; margin-bottom: 10px">
            Total Score: {{score}}
        </div>
        <div
            v-for="(item, index) in detailRows"
            :key="index"
            :style="{color: item.isFlagged ? 'red' : null}"

        >
            <div style="display: flex; max-width: 500px; padding: 3px 0">
                <div style="flex: 1">
                    {{item.key}}
                </div>
                <div style="padding-left: 20px">
                    {{(index === detailRows.length - 1 && isPhqOrGad7) ? item.valueDescription : item.value}}
                </div>
            </div>

        </div>

        <SurveyResultsTable :table="table" :score="score" />
    </div>
</template>

<script>
import {GAD7Table, PHQ9Table} from "@/js/shared/constants";
import SurveyResultsTable from "@/components/Profile/TestsViewer/Surveys/SurveyResultsTable";

export default {
    name: "SurveyResultsWithTable",
    components: {SurveyResultsTable},
    props: {
        formattedTest: {
            required: true
        },
        measureId: {
            default: 1017
        }
    },
    data() {
        return {

           
        }
    },
    computed: {
        table() {
            if (this.measureId == 1016) {
                return PHQ9Table
            } else if (this.measureId == 1017) {
                return GAD7Table
            }
        },
        detailRows() {
            return this.formattedTest.results[0].detailRows
        },
        score() {
            return this.formattedTest.score
        },
        tableRows() {
            return this.table.map(x => {
                x.isActive = this.score >= x.minValue && this.score <= x.maxValue;
                return x
            })
        },
	    isPhqOrGad7() {
			return this.measureId === 1016 || this.measureId === 1017
	    }
    }
}
</script>

<style scoped>
.activeRow {
    background-color: rgba(255,0,0,.1);
}
</style>