export default {
    
    
    chart: {
        ciRedFill: '#DA5466',
        ciRedFillChart: 'rgba(239,70,94,.1)',
        ciGreenFill: '#82CBC3',
        ciGreenFillChart: 'rgba(101,205,190,.1)',
        ciYellowFill: '#FBCC88',
        ciYellowFillChart: 'rgba(255,210,43,.1)',
        // scoreOrangeFill: 'rgba(255,161,42,0.5)',
        // scoreOrangeBorder: '#FFA12A',
        event: '#FFA12A',
        // scoreBlueFill: 'rgba(0,205,250,0.1)',
        // scoreBlueBorder: '#00CDFA',
        baseline: '#34d3fd',
        excluded: '#333',
        
        normBadGrey: '#626262',
        normGrey: '#989898'
    },
    
    pdf: {
        headerBackground: '#000'
    }
}