<template>
    <div style="display: flex">
        <div>
            <div class="section-title">
                Inactive Measures
            </div>
            <div v-if="formattedMeasures" v-for="(measure, index) in formattedMeasures" :key="index" style="max-width: 500px">
                <app-card v-if="!measure.isActive" class="pa-2 ma-2" hide-header>
                    <div style="display: flex; align-items: center">
                        <div style="flex: 1">
                            <span style="color: #757575">{{measure.regulationMeasureId}}:</span>
                            <span style="font-weight: bold; margin-left: 10px">{{ measure.name }}</span>
                        </div>
                        <div v-if="!measure.isLoading">

                            <AppBtn  class="button" small dense @click="addRemoveMeasure(measure, index)">Add</AppBtn>
<!--                            <AppBtn v-else @click="addRemoveMeasure(measure, index)" color="red">Remove</AppBtn>-->

                            <!--                        <v-switch-->
                            <!--                            v-model="measure.isActive"-->
                            <!--                            @change="addRemoveMeasure(measure, index)"-->
                            <!--                            inset-->
                            <!--                            label=""-->
                            <!--                            dense-->
                            <!--                            style="height: 14px; transform: translateY(-14px)"-->
                            <!--                        ></v-switch>-->
                        </div>
                        <div v-else>
                            <sway-loader small></sway-loader>

                        </div>

                    </div>

                </app-card>

            </div>
        </div>
        <div>
            <div class="section-title">
                Active Measures
            </div>
            <div v-if="formattedMeasures" v-for="(measure, index) in formattedMeasures" :key="index" style="max-width: 500px">
                <app-card v-if="measure.isActive" class="pa-2 ma-2" hide-header>
                    <div style="display: flex; align-items: center">
                        <div style="flex: 1">
                            <span style="color: #757575">{{measure.regulationMeasureId}}:</span>
                            <span style="font-weight: bold; margin-left: 10px">{{ measure.name }}</span>
                        </div>
                        <div v-if="!measure.isLoading">
                            
                            <AppBtn class="button" small dense @click="addRemoveMeasure(measure, index)" color="red">Remove</AppBtn>

                            <!--                        <v-switch-->
                            <!--                            v-model="measure.isActive"-->
                            <!--                            @change="addRemoveMeasure(measure, index)"-->
                            <!--                            inset-->
                            <!--                            label=""-->
                            <!--                            dense-->
                            <!--                            style="height: 14px; transform: translateY(-14px)"-->
                            <!--                        ></v-switch>-->
                        </div>
                        <div v-else>
                            <sway-loader small></sway-loader>

                        </div>

                    </div>

                </app-card>

            </div>
        </div>
        
       
    </div>
</template>

<script>
import {
    getAllMeasures,
    getMeasuresForOrganization,
    addMeasureToOrganization,
    removeMeasureFromOrganization
} from "@/services/protocol.service";
import {SET_SNACKBAR} from "@/store/mutation-types";

export default {
    name: "SubscriptionMeasures",
    data() {
        return {
            allMeasures: null,
            organizationMeasures: null,
            formattedMeasures: []
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        allMeasures() {
            this.getFormattedMeasures()
        },
        organizationMeasures() {
            this.getFormattedMeasures()
        }
    },
    computed: {
        activeFormattedMeasures() {
            
            return this.formattedMeasures.filter(measure => measure.isActive)
        },
        inactiveFormattedMeasures() {
            return this.formattedMeasures.filter(measure => !measure.isActive)
        }
    },
    methods: {
        getFormattedMeasures() {
            const {allMeasures, organizationMeasures} = this;
            if (allMeasures === null || organizationMeasures === null) return null
            
            this.formattedMeasures = allMeasures.map(measure => {
                measure.isActive = false;
                measure.isLoading = false
                let matchedMeasure = organizationMeasures.find(orgMeasure => orgMeasure.regulationMeasureId === measure.regulationMeasureId);
                if (matchedMeasure) {
                    measure.isActive = true;
                }
                return measure
            });
        },
        init() {
            this.getAllMeasures();
            this.getMeasuresForOrganization()
        },
        async getAllMeasures() {
            const response = await getAllMeasures();
            if (!response.isSuccess) return;
            this.allMeasures = response.measures;
        },
        async getMeasuresForOrganization() {
            const response = await getMeasuresForOrganization();
            if (!response.isSuccess) return;
            this.organizationMeasures = response.measures;
        },
        async addRemoveMeasure(measure, index) {
            
            // measure.isLoading = true;
            // let something = measure;
            let formattedMeasures = JSON.parse(JSON.stringify(this.formattedMeasures))
            formattedMeasures[index].isLoading = true;
            this.formattedMeasures = formattedMeasures
            
            if (!measure.isActive) {
                
                let response = await addMeasureToOrganization(measure.regulationMeasureId);
                if (response.isSuccess) {
                    formattedMeasures[index].isActive = true;
                    this.$store.commit(SET_SNACKBAR, {
                        text: `${measure.name} added`,
                        color: 'success',
                        open: true
                    });
                }
                
            } else {
                
                let response = await removeMeasureFromOrganization(measure.regulationMeasureId);
                if (response.isSuccess) {
                    formattedMeasures[index].isActive = false;
                    this.$store.commit(SET_SNACKBAR, {
                        text: `${measure.name} removed`,
                        color: 'success',
                        open: true
                    });
                }
            }
            // measure.isLoading = false
            formattedMeasures[index].isLoading = false;
            this.formattedMeasures = formattedMeasures

        }
    }
}
</script>

<style scoped>
.section-title {
    font-size: 24px;
    font-weight: 700;
    margin: 10px 20px 
}

.button {
    margin-left: 20px
}
</style>