
<template>
    <div class="pdf-profile-header-container">
        <div style="display: flex">
            <div style="flex: 1">
                <div class="pdf-stat-label">PATIENT {{ viewingAsSuper ? 'ID' : 'NAME' }}</div>
                <div style="display: flex; align-items: flex-end">
                    <div class="pdf-section-title" style="margin: 0; flex: 1">{{ !viewingAsSuper ? profileInfo.firstName + ' ' + profileInfo.lastName : profileInfo.id }}</div>
                </div>
            </div>
            
            <div style="margin-left: 10px; display: flex; flex-direction: column; align-items: flex-end; text-transform: none; color: #8E9195">
                <div>
                    Profile ID
                </div>
                <div>{{ profileInfo.id }}</div>
            </div>
        </div>
        
        

        <!--        <div class="pdf-stat-label">BASIC INFORMATION</div>-->
        <div v-if="!viewingAsSuper">
            <div class="pdf-info-row">
            <span v-for="(stat, index) in basicInformationRow1" :key="index" style="margin-top: 5px" >
                  <span class="pdf-profile-header-with-additional-data-stat-label">{{stat.label}}:</span>
                  <span class="pdf-stat-value">{{stat.value ? stat.value : profile.data[stat.name]}}</span>
                </span>

                <span v-for="(stat, index) in medicalInformationRow1" :key="index" style="margin-top: 5px">
                  <span class="pdf-profile-header-with-additional-data-stat-label">{{stat.label}}</span>
                  <span class="pdf-stat-value">{{stat.value ? stat.value : profile.data[stat.name]}}</span>
                </span>
                <span v-for="(stat, index) in educationInformation" :key="index" style="margin-top: 5px">
                  <span class="pdf-profile-header-with-additional-data-stat-label">{{stat.label}}:</span>
                  <span class="pdf-stat-value">{{stat.value ? stat.value : profile.data[stat.name]}}</span>
                </span>
            </div>
                
                
            </div>


<!--            <div class="pdf-info-row">-->
<!--            <span v-for="(stat, index) in medicalInformationRow1" :key="index" >-->
<!--                  <span class="pdf-profile-header-with-additional-data-stat-label">{{stat.label}}</span>-->
<!--                  <span class="pdf-stat-value">{{stat.value ? stat.value : profile.data[stat.name]}}</span>-->
<!--                </span>-->
<!--            </div>-->


<!--            <div class="pdf-info-row">-->
<!--            <span v-for="(stat, index) in educationInformation" :key="index" >-->
<!--                  <span class="pdf-profile-header-with-additional-data-stat-label">{{stat.label}}:</span>-->
<!--                  <span class="pdf-stat-value">{{stat.value ? stat.value : profile.data[stat.name]}}</span>-->
<!--                </span>-->
<!--            </div>-->
        </div>


    

</template>

<script>
import moment from "moment";

export default {
    name: "ProfileHeaderWithAdditionalData",
    props: {
        profile: {
            type: Object,
            required: true
        },
        profileTest: {
            required: true
        },
        stats: {
            required: true
        },
        completedOnDate: {
            required: true
        },
        viewingAsSuper: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // viewingAsSuper: false
        }
    },
    computed: {

        profileInfo() {
            return this.profile.data.profile;
        },
        basicInformationRow1() {
            return [
                {
                    label: 'DOB',
                    value: this.profileInfo.displayBirthdate
                },
                {
                    label: 'Age',
                    value: this.profileInfo.age
                },
                {
                    label: 'Height',
                    value: this.profileInfo.displayHeight
                },
                {
                    label: 'Weight',
                    value: this.profileInfo.displayWeight
                },
                {
                    label: 'Sex',
                    value: this.profileInfo.gender
                },
                {
                    label: 'Gender',
                    value: this.profileInfo.additionalData.genderIdentityDisplay
                },
                {
                    label: 'Race',
                    value: this.profileInfo.additionalData.raceDisplay
                },
            ]
        },

        medicalInformationRow1() {
            return [
                {
                    label: 'History of concussion?',
                    value: this.profileInfo.additionalData.hasHadConcussionDisplay
                },
                {
                    label: 'Approximate date of last concussion?',
                    value: this.profileInfo.additionalData.lastConcussionDateDisplay
                },
                {
                    label: 'Diagnosed with ADD or ADHD?',
                    value: this.profileInfo.additionalData.hasAttentionDisorderDisplay
                },
            ];
        },

        educationInformation() {
            return [
                {
                    label: 'Primary Language',
                    value: this.profileInfo.additionalData.primaryLanguageDisplay
                },
                {
                    label: 'Secondary Language',
                    value: this.profileInfo.additionalData.secondaryLanguageDisplay
                },
            ];


        },

    },

}
</script>

<style scoped>

</style>
