<template>
    <app-card  hide-header>
        <app-data-table
            :headers="headers"
            :items="apiKeys"
            @onRowClick="$emit('onRowClick', $event)"
            :loading="loading"
        >
            <template v-slot:item.isEnabled="{ item }">
                <div style="display: flex; align-items: center">
                    <div :style="{'background-color': item.isEnabled ? isExpired(item) ? 'red' : 'limegreen' : 'red'} " class="dot mr-2"></div>
                    <div>
                        {{item.isEnabled ? isExpired(item) ? 'Expired' : 'Active' : 'Disabled'}}
                    </div>

                </div>
            </template>
            <template v-slot:item.apiKey="{ item }">
                <app-chip
                        v-if="item.apiKey"
                        :color="'blue'"
                        dark
                >
                    {{ item.apiKey.substring(0,20) }}...
                </app-chip>
                <app-btn v-if="item.apiKey" @click="copyApiKey(item.apiKey,$event)" icon="mdi-content-copy" style="min-width: auto; height: auto; padding: 5px"></app-btn>
            </template>
            <template v-slot:item.ehrPartner="{ item }">
                {{ getEhrPartnerName(item) }}
            </template>
            <template #no-data>
                <slot name="no-data"></slot>
            </template>
        </app-data-table>
        
    </app-card>
</template>

<script>

import {ehrPartnerOptions} from "@/js/shared/constants";

export default {
    name: "ExternalApiTable",
    props: {
        apiKeys: {
            required: true
        },
        loading: {
            default: false
        }
    },
    data() {
        return {

        }
    },
    methods: {
        isExpired(apiKey) {
            if (!apiKey.expirationDate)
                return false;
            
            let now = Date.now();
            return new Date(apiKey.expirationDate) < now;
        },
        getEhrPartnerName(item){
            let ehrPartner = ehrPartnerOptions.find(x => x.value === item.ehrPartnerId);
            if (!ehrPartner)
                return 'Other';
            
            return ehrPartner.value === 0 ? 'Other (' + item.otherApiPartner + ')' : ehrPartner.text;
        },
        async copyApiKey(mytext, event){
            if (event){
                event.stopPropagation()
            }
            await navigator.clipboard.writeText(mytext)
        }
    },
    computed: {
        headers() {
            // if (!) {
                return [
                    {
                        text: 'Ehr Partner',
                        value: 'ehrPartner',
                    },
                    {
                        text: 'Api Key',
                        value: 'apiKey',
                    },
                    {
                        text: 'Expiration (UTC)',
                        value: 'expirationDate',
                    },
                    {
                        text: 'Status',
                        value: 'isEnabled',
                    },
                ]
            //}
        },
    }
}

</script>

<style scoped>
.dot {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: red;
}
</style>