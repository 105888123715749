<template>
    <div>
       
        <div v-if="!editingGroupProfiles">
            
            <div v-if="!hasZeroProfiles">
                
                <div >
                    <div style="display: flex" class="mb-2">
                        <div style="flex: 1">
<!--                            <h2>Profiles</h2>-->
                        </div>
                        <app-btn class="mr-2" @click="$router.push(`/profiles/bulk-move/${group.id}`)">Add/Remove Profiles</app-btn>
                        <app-btn class="mr-2" @click="exportTestsDialogOpen = true">Export Tests</app-btn>
                        <app-btn class="mr-2" @click="exportEventsDialogOpen = true">Export Events</app-btn>
                        <!--<app-btn v-if="organizationPreferences && organizationPreferences.enableCovid19Screening" @click="getCovidResultsExport">Export Covid Screenings</app-btn>-->
                    </div>
<!--                    <app-card hide-header>-->
<!--                        <v-data-table-->
<!--                            :headers="groupProfilesTableHeaders"-->
<!--                            :items="groupProfiles"-->
<!--                            :items-per-page="10"-->
<!--                            @click:row="goToProfile"-->
<!--                            style="border-top: 1px solid lightgrey"-->
<!--                        >-->
<!--                            <template #item.lastTestDate="{item}">-->
<!--                                <div>-->
<!--                                    {{getFormattedTestDate(item.lastTestDate)}}-->
<!--                                </div>-->
<!--                            </template>-->
<!--                            &lt;!&ndash;                        <template #item.actions="{item}">&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <div @click.prevent="openRemoveProfileDialog(item)" style="background-color: pink">&ndash;&gt;-->
<!--                            &lt;!&ndash;                                <v-tooltip bottom :open-delay="1000">&ndash;&gt;-->
<!--                            &lt;!&ndash;                                    <template v-slot:activator="{ on, attrs }">&ndash;&gt;-->
<!--                            &lt;!&ndash;                                        <v-icon v-bind="attrs"&ndash;&gt;-->
<!--                            &lt;!&ndash;                                                v-on="on" color="red" >mdi-close-circle</v-icon>&ndash;&gt;-->
<!--                            &lt;!&ndash;                                    </template>&ndash;&gt;-->
<!--                            &lt;!&ndash;                                    <span>Remove Profile From Group</span>&ndash;&gt;-->
<!--                            &lt;!&ndash;                                </v-tooltip>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </div>&ndash;&gt;-->
<!--                            &lt;!&ndash;                        </template>&ndash;&gt;-->
<!--                        </v-data-table>-->
<!--                    </app-card>-->
                    
                </div>
                <ProfilesTable 
                    :group-id="group.id" 
                    @setOrgHasZeroProfiles="hasZeroProfiles = true"
                    @setTotalRowCount="setNumberOfProfiles($event)"
                />
            </div>
            
            
            
            <div v-else>
                <EmptyState 
                    :svg="emptyStateSvg"
                    description="There are no profiles in this group. Creating a group code to get started."
                    button
                    button-text="Create Group Code"
                    @buttonClick="() => $router.push(`/groups/${this.group.id}/codes?createCode=true`)"
                >
                </EmptyState>
                
            </div>
        </div>
        <v-row v-if="editingGroupProfiles">
            <v-col cols="12" style="padding-top: 0; padding-bottom: 0; display: flex; justify-content: flex-end">
                <app-btn @click="updateGroupProfiles" :loading="savingProfiles">Save</app-btn>
                <app-btn @click="editingGroupProfiles = false" outlined class="ml-2">Cancel</app-btn>
            </v-col>
        </v-row>
        
<!--        <v-row v-if="editingGroupProfiles" class="mt-4" style="display: flex">-->
<!--            -->
<!--            <v-col cols="12" md="5">-->
<!--                <app-card  >-->
<!--                    <div class="pa-2">-->
<!--                        <h4>All Profiles</h4>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                        <div class="pl-2 pr-2">-->
<!--                            <v-text-field-->
<!--                                placeholder="Search Profiles"-->
<!--                                v-model="profileSearchString"-->
<!--                                outlined-->
<!--                                dense-->
<!--                                prepend-inner-icon="mdi-magnify"-->
<!--                                class="mt-2 mb-2"-->
<!--                                background-color="background"-->
<!--                            >-->

<!--                            </v-text-field>-->

<!--                            -->

<!--                        </div>-->
<!--                        <div class="pl-2 pr-2" style="height: 500px; overflow-y: scroll; overflow-x: hidden">-->


<!--                            <div  v-if="loadingProfiles">-->

<!--                                <div >-->
<!--                                    <v-skeleton-loader-->
<!--                                        type="list-item@5"-->
<!--                                        class="mt-4"-->
<!--                                    ></v-skeleton-loader>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div v-else>-->
<!--                                <div-->

<!--                                    class="mt-2"-->
<!--                                    v-for="(profile, index) in profiles"-->
<!--                                    :key="index"-->
<!--                                    @click="moveProfile(profile, index)"-->
<!--                                    v-if="profile.show"-->
<!--                                >-->

<!--                                    <ProfileMoveCard :profile="profile" />-->


<!--                                </div>-->
<!--                            </div>-->

<!--                        </div>-->
<!--                    </div>-->
<!--                    -->
<!--                    -->
<!--                </app-card>-->
<!--            </v-col>-->
<!--            <v-col cols="2" style="align-self: center">-->
<!--                <div style="display: flex; justify-content: center">-->
<!--                    <v-icon :size="50">mdi-arrow-right</v-icon>-->
<!--                </div>-->
<!--                <div class="text-center">-->
<!--                    -->
<!--                    Click a profile add to "{{ group.name }}"-->
<!--                </div>-->
<!--            </v-col>-->
<!--            <v-col cols="12" md="5" style="flex: 1; min-height: 500px">-->
<!--                <app-card style="height: 100%">-->
<!--                    <div class="pa-2">-->
<!--                        <h4>{{ group.name }}</h4>-->
<!--                    </div>-->
<!--                    -->
<!--                    <div v-if="loadingProfiles" class="pl-2 pr-2" >-->
<!--                        <v-skeleton-loader-->
<!--                            type="list-item@5"-->
<!--                            class="mt-4"-->
<!--                        ></v-skeleton-loader>-->
<!--                    </div>-->
<!--                    <div v-else style="overflow-y: scroll; overflow-x: hidden">-->
<!--                        <div class="pl-2 pr-2">-->
<!--                            <transition-group name="list" tag="p">-->
<!--                                <div -->
<!--                                    class="mt-2" -->
<!--                                    v-for="(profile, index) in movedProfiles" -->
<!--                                    :key="index" -->
<!--                                    @click=""-->
<!--                                    -->
<!--                                >-->
<!--                                    <ProfileMoveCard :profile="profile" />-->
<!--                                </div>-->
<!--                            </transition-group>-->
<!--                        </div>-->
<!--                        -->
<!--                    </div>-->
<!--                    -->

<!--                </app-card>-->

<!--            </v-col>-->
<!--        </v-row>-->

        <dialog-form
                :open="exportTestsDialogOpen"
                @close="exportTestsDialogOpen = false"
                title="Export Tests"
                :width="500"
                @submit="getTestResultsExport"
        >
            <template v-slot:form-content>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportTestsForm.startDate" label="Start Date" readonly></app-form-date-picker>
                </div>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportTestsForm.endDate" label="End Date" readonly></app-form-date-picker>
                </div>
                <div class="inline-block-field" v-if="$store.state.currentAccount.isSuperAdmin">
                    <app-form-checkbox v-model="exportTestsForm.includeSurveys" label="Include On-Field Assessment, VOMS, and other Surveys" readonly></app-form-checkbox>
                </div>
            </template>

        </dialog-form>

        <dialog-form
            :open="exportEventsDialogOpen"
            @close="exportEventsDialogOpen = false"
            title="Export Events"
            :width="500"
            @submit="getEventsExport"
        >
            <template v-slot:form-content>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportEventsForm.startDate" label="Start Date" readonly></app-form-date-picker>
                </div>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportEventsForm.endDate" label="End Date" readonly></app-form-date-picker>
                </div>
            </template>

        </dialog-form>

        <dialog-form
                :open="exportCovidTestsDialogOpen"
                @close="exportCovidTestsDialogOpen = false"
                title="Export Covid Tests"
                :width="500"
                @submit="getCovidResultsExport"
        >
            <template v-slot:form-content>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportCovidTestsForm.startDate" label="Start Date" readonly></app-form-date-picker>
                </div>
                <div class="inline-block-field">
                    <app-form-date-picker v-model="exportCovidTestsForm.endDate" label="End Date" readonly></app-form-date-picker>
                </div>
            </template>

        </dialog-form>

        <app-confirm-dialog
            title="Remove Profile from Group"
            submitButtonTitle="Remove Profile"
            :open="removeProfileDialogOpen"
            @close="removeProfileDialogOpen = false"
            confirm-delete

        >
            Are you sure you want to remove {{userToRemove && userToRemove.firstName}} {{userToRemove && userToRemove.lastName}} from the group?
        </app-confirm-dialog>
    </div>
</template>

<script>
import {updateGroupProfiles} from "@/services/groups.service";
import ProfileMoveCard from "@/components/ProfileMoveCard";
import emptyStateSvg from '@/assets/svgs/empty-state/empty-group-profile.svg'
import EmptyState from "@/components/EmptyState";
import moment from 'moment'
import 'moment-timezone'
import ProfilesTable from "@/components/ProfilesTable";
import {getTestResultsExport, getEventsExport} from "@/services/organization.service";

export default {
    name: "GroupProfiles",
    props: {
        group: {
            required: true
        },
        // groupProfiles: {
        //     required: true
        // }
    },
    components: {
        ProfilesTable,
        EmptyState,
        ProfileMoveCard
    },
    data() {
        return {
            emptyStateSvg,
            savingProfiles: false,
            editingGroupProfiles: false,
            removeProfileDialogOpen: false,
            profileListHasChanged: false,
            profileSearchString: '',
            profiles: [],
            movedProfiles: [],
            
            loadingProfiles: false,
            hasZeroProfiles: false,
            
            exportingTestResults: false,
            exportingEvents: false,
            exportingCovidResults: false,
            exportTestsDialogOpen: false,
            exportEventsDialogOpen: false,
            exportCovidTestsDialogOpen: false,

            exportTestsForm: {
                startDate: null,
                endDate: null,
                includeSurveys: false
            },
            
            exportEventsForm: {
                startDate: null,
                endDate: null,
            },

            exportCovidTestsForm: {
                startDate: null,
                endDate: null,
            },
        }
    },
    mounted() {
        this.init()
        if (this.$route.query.editProfiles === 'true') {
            this.editingGroupProfiles = true
        }
    },
    watch: {
        groupedProfiles(newValue, oldValue) {
            this.movedProfiles = this.groupProfiles
        },
        profileSearchString(newValue, oldValue) {
            this.setProfiles()
        },
        groupProfiles() {
            this.init()
        }
    },
    computed: {
        groupProfilesTableHeaders() {
            if (!this.$store.state.currentAccount.viewingAsSuper) {
                return [
                    {
                        text: 'First Name',
                        value: 'firstName',
                    },
                    {
                        text: 'Last Name',
                        value: 'lastName',
                    },
                    {
                        text: 'ID',
                        value: 'id',
                    },
                    {
                        text: 'Last Tested On',
                        value: 'lastTestDate',
                    },
                ]
            } else {
                return [
                    {
                        text: 'ID',
                        value: 'id',
                    },
                ]
            }
        }
        // filteredProfiles() {
        //     if (!this.profileSearchString) return this.profiles
        //     return this.profiles.filter(profile => profile.fullName.toLowerCase().includes(this.profileSearchString.toLowerCase()))
        // },
    },
    methods: {
        init() {
            this.setProfiles();
            this.movedProfiles = this.groupProfiles.map(profile => {
                profile.fullName = `${profile.firstName} ${profile.lastName}`
                return profile
            });

            if (this.$route.query.addProfile === '1') {
                this.editingGroupProfiles = true
            }
        },
        setNumberOfProfiles(value) {
            this.$emit('setNumberOfProfiles', value)  
        },
        getFormattedTestDate(date) {
            return moment(date).format('M/D/YY')
        },
        async updateGroupProfiles() {
            this.savingProfiles = true;
            let request = {
                groupId: this.group.id,
                profileIds: this.movedProfiles.map(profile => profile.id)
            }
            
            let response = await updateGroupProfiles(request)
            
            if (!response.isSuccess) {
                this.savingProfiles = false;
                return 
            }
                        
            this.$emit('updateGroupProfiles')
            this.savingProfiles = false;
        },
        moveProfile(profile, index) {
            this.profileListHasChanged = true;
            profile.hasBeenAdded = true;
            this.movedProfiles.push(profile)
            this.profiles.splice(index, 1);
        },
        async setProfiles() {
            // this.loadingProfiles = true;
            // let response = await getProfilesPaged({
            //     pageNumber: 1,
            //     rowsPerPage: 20,
            //     sortByColumn: "",
            //     sortAscending: true,
            //     searchString: this.profileSearchString
            // });
            //
            // if (!response.isSuccess) {
            //     this.loadingProfiles = false;
            //     return
            // }
            //
            // this.profiles = response.profiles.map(profile => {
            //     profile.fullName = `${profile.firstName} ${profile.lastName}`
            //     profile.show = true
            //    
            //     let groupProfile = this.groupProfiles.find(groupProfile => groupProfile.id === profile.id)
            //     if (groupProfile) {
            //         profile.show = false
            //     }
            //    
            //     return profile
            // });
            // this.loadingProfiles = false;
        },
        saveProfileList() {
            let profileIds = this.movedProfiles.map(profile => profile.id);
            let groupId = this.group.id; 

        },
        goToProfile(profile, obj) {
            let something = obj
            this.$router.push(`/profiles/${profile.id}`)
        },
        openRemoveProfileDialog(profile) {

            this.removeProfileDialogOpen = true
        },
        async getTestResultsExport() {
            this.exportTestsDialogOpen = false;
            this.exportingTestResults = true;
            const request = {
                startDate: this.exportTestsForm.startDate,
                endDate: this.exportTestsForm.endDate,
                includeSurveys: this.exportTestsForm.includeSurveys,
                groupIds: [this.group.id],
                profileIds: [],
                isCovidOnly: false,
                orgId: this.$store.state.currentAccount.selectedOrganization.id
            }
            let response = await getTestResultsExport(request)
            this.exportingTestResults = false;
        },
        async getEventsExport() {
            this.exportEventsDialogOpen = false;
            this.exportingEvents = true;
            const request = {
                startDate: this.exportEventsForm.startDate,
                endDate: this.exportEventsForm.endDate,
                groupIds: [this.group.id],
                profileIds: [],
                isCovidOnly: false,
                orgId: this.$store.state.currentAccount.selectedOrganization.id
            }
            let response = await getEventsExport(request)
            this.exportingEvents = false;
        },
        async getCovidResultsExport() {
            this.exportCovidTestsDialogOpen = false;
            this.exportingCovidResults = true;
            const request = {
                startDate: this.exportCovidTestsForm.startDate,
                endDate: this.exportCovidTestsForm.endDate,
                groupIds: [this.group.id],
                profileIds: [],
                isCovidOnly: true,
                orgId: this.$store.state.currentAccount.selectedOrganization.id
            }
            let response = await getTestResultsExport(request)
            this.exportingCovidResults = false;
        },
    }
}
</script>

<style scoped>
.profile-container {
    border: 1px solid var(--v-border-base);
    border-radius: 5px;
    display: flex;
}

.added-profile {
    border: 1px solid var(--v-primary-base);;
}

.profile-id {
    color: var(--v-light-darken4)
}

.profile-container:hover {
    background-color: var(--v-light-base);
    cursor: pointer;
    
    
}

.list-item {
    overflow: hidden;
}
.list-enter-active, .list-leave-active {
    transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(30px);
    background-color: var(--v-light-base);
}
</style>