<template>
    <div class="mt-2" >
        <app-card title="Groups" style="min-width: 500px"  >
<!--            <div style="display: flex; align-items: center" class="mb-2 pa-4 pb-0">-->

<!--                &lt;!&ndash;                        <span v-if="!editingAccountGroups && !is">&ndash;&gt;-->
<!--                &lt;!&ndash;                                    <app-fab-btn type="edit" x-small @click="editingAccountGroups = true"/>&ndash;&gt;-->
<!--                &lt;!&ndash;                                </span>&ndash;&gt;-->
<!--            </div>-->
            <div style="display: flex; justify-content: center" >
                <div v-if="isAdmin" class="ml-4 mr-4 mt-4 text-center" style="width: 70%; color: var(--v-text-lighten1)">
                    As an Admin, you have access to all groups in the organization.
                </div>
            </div>
            
            <div class="pa-4">
                <div 
                    v-for="group in accountGroups" 
                    :key="group.id"
                    class="group-card pa-2" 
                    @click="$router.push(`/groups/${group.id}/overview`)"
                >
                    {{group.name}}
                </div>
            </div>

            <!--                    <div v-if="!editingAccountGroups" >-->
            <!--                        <div v-for="group in accountGroups" :key="group.id" style="border: 1px solid lightgrey; border-radius: 5px; margin: 5px 0;" class="pa-2">-->
            <!--                            {{group.name}}-->
            <!--                        </div>-->

            <!--                    </div>-->
            <!--                    <div v-else>-->
            <!--                        <AppMultipleSelect-->
            <!--                            v-model="editAccountGroups"-->
            <!--                            :items="groups"-->
            <!--                            item-text="name"-->
            <!--                            item-value="id"-->
            <!--                            item-title="name"-->
            <!--                        >-->

            <!--                        </AppMultipleSelect>-->
            <!--                        <app-btn @click="submitEditGroupsForm" :loading="submittingEditGroups">Save Changes</app-btn>-->
            <!--                        <app-btn outlined class="ml-2" @click="editingAccountGroups = false">Cancel</app-btn>-->
            <!--                    </div>-->

        </app-card>

    </div>
</template>

<script>
export default {
    name: "AccountGroups",
    props: {
        account: {
            required: true
        },
        isAdmin: {
            required: true
        },
        accountGroups: {
            required: true
        }
    }
}
</script>

<style scoped>
.group-card {
    border: 1px solid lightgrey; 
    border-radius: 5px; 
    margin: 5px 0;
    background-color: white;
}
.group-card:hover {
    cursor: pointer;
}
</style>