<template>
    <div style="margin: auto">
        <span v-if="!isErrorState">Getting Mobile Token</span>
        <span v-if="isErrorState">An error occurred</span>
        <br/>
        <app-btn v-if="isErrorState" @click="backToLogin">Back To Login</app-btn>
        <app-loader v-if="isLoading"></app-loader>
    </div>
</template>

<script>

import funcs from "@/js/funcs";
import {clearLogin, getMobileToken} from "@/services/userService";
import httpService from "@/services/httpService";
import store from "@/store";
import {SET_SNACKBAR} from "@/store/mutation-types";
import semver from "semver";
export default {
    name: "GetMobileToken",
    props: {
        
    },
    data() {
        return {
            isLoading: true,
            isErrorState: false
        }
    },
    beforeMount() {
        
    },
    mounted() {
        this.doGetMobileLoginToken();
    },
    computed: {
        
    },
    watch: {

    },
    methods: {
        async doGetMobileLoginToken(){
            let deviceId = this.$route.query.deviceId;
            let deviceName = this.$route.query.deviceName;
            let appVersion = this.$route.query.appVersion;
            let email = this.$route.query.email;
            let platform = this.$route.query.platform;
            let osVersion = this.$route.query.osVersion;
            let token = this.$route.query.token;
            
            await this.getMobileLoginToken(deviceId, deviceName, appVersion, email, platform, osVersion, token);
        },
        async getMobileLoginToken(deviceId, deviceName, appVersion, email, platform, osVersion, token){

            this.mobileAppVersion = appVersion;
            
            if (token && email){
                httpService.setAuthTokenHeader(token);

                let request = {
                    DeviceInfo:
                            {
                                DeviceId: deviceId,
                                DeviceName: deviceName,
                                AppVersion: appVersion,
                                Platform: platform,
                                OsVersion: osVersion
                            },
                    Email: email
                };
                let response = await getMobileToken(request);
                if (response)
                {
                    if (response.message) {
                        this.sendNativeCommand('cancelLogin', response.message);
                        this.isLoading = false;
                        this.isErrorState = true;
                    }
                    else {
                        this.sendNativeCommand('mobileTokenReceived', JSON.stringify(response));
                    }
                    return;
                }
            }
            
            this.isLoading = false;
            clearLogin();
            this.sendNativeCommand('cancelLogin', 'An unexpected error occured. If this continues, please call Sway support.');
            this.isErrorState = true;
        },
        async backToLogin(){
            this.isLoading = false;
            clearLogin();
            this.sendNativeCommand('cancelLogin');
            this.isErrorState = false;
        },
        sendNativeCommand(command, payload){
            if (this.mobileAppVersion && semver.gte(this.mobileAppVersion, '5.6.0')){
                if (command == 'cancelLogin' && payload){
                    command = 'cancelLoginWithMessage'; // hack: HybridViewModel does not support overloading or optional parameters
                }
                
                funcs.sendNativeCommandMaui(command, payload);
            }
            else {
                funcs.sendNativeCommand(command, payload);
            }
        }
    }
}
</script>

<style scoped>

</style>