<template>
    <div>
        <page-header 
            :title="group ? group.name : 'Group'"
            @edit="editGroupDialogOpen = true"
            @delete="deleteGroupDialogOpen = true"

            :editable="!!group"
            :deletable="!!group"
            :menu="!!group && group.isEnabled"
        >
            <template #menu-content v-if="group">
                <div style="min-width: 200px">
                    <div class="pa-2"
                         style="text-transform: uppercase; background-color: white; font-size: .8em; color: #bdbdbd; font-weight: 500"
                    >Group Actions</div>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in groupActions"
                            :key="index"
                            class="pl-2 pr-2"
                            link
                            @click="item.onClick"
                            dense
                        >
                            <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-title >{{ item.label }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>
            </template>
            <template #title-right>
                <div v-if="group">
                    <app-chip v-if="group.isEnabled" color="green" label>Active</app-chip>
                    <app-chip v-else color="red" label>Inactive</app-chip>
                </div>
                
            </template>
        </page-header>

        <app-tabs :value="tabValue" v-if="group">
            <app-tab
                v-for="(tab, index) in tabs"
                :key="index"
                @click="$router.push(tab.route)"
                :icon="tab.icon"
            >{{ tab.label }}
            </app-tab>
        </app-tabs>
        
        <div v-if="group && !loadingGroup">
            
            <router-view
                :group="group"
                :groupPreferences="groupPreferences"
                :groupProfiles="groupProfiles"
                :numberOfProfiles="numberOfProfiles"
                :loadingGroupProfiles="loadingGroupProfiles"
                :groupTestSummaryData="groupTestSummaryData"
                :users="users"
                :accounts="accounts"
                @resetGroupPreferences="resetGroupPreferences($event)"
                :cognitiveNotificationPreferences="cognitiveNotificationPreferences"
                :covidNotificationPreferences="covidNotificationPreferences"
                :covidGroupPreference="covidGroupPreference"
                :cognitiveGroupPreference="cognitiveGroupPreference"
                :sessions="sessions"
                @updateGroupProfiles="getGroupProfiles"
                @updateGroupAccounts="updateGroupAccounts($event)"
                @setNumberOfProfiles="numberOfProfiles = $event"
            ></router-view>
            
            <dialog-form
                :open="editGroupDialogOpen"
                @close="editGroupDialogOpen = false"
                title="Edit Group"
                @submit="submitEditGroupForm"
                :width="600"
                :saving="updatingGroup"
            >

                <template v-slot:form-content>
                    <!--                <app-text-field v-model="editSessionForm.endDate" label="End Date" />-->
                    <app-text-field label="Name" v-model="editGroupForm.name"></app-text-field>
                    <div class="mb-2">Inactive Groups will not show in the app. (DOES NOT affect billing in any way).</div>
                    <app-form-checkbox checkbox-left-side label="Is Active" v-model="editGroupForm.isEnabled"></app-form-checkbox>

                </template>
                <template v-slot:actions>
                </template>
            </dialog-form>

            <app-confirm-dialog
                title="Delete Group"
                :open="deleteGroupDialogOpen"
                @confirm="deleteGroup"
                @close="deleteGroupDialogOpen = false"
                :loading="deletingGroup"
                confirm-delete
            >
                <div>
                    Are you sure you want to delete group {{group.name}}?
                </div>
            </app-confirm-dialog>
            
        </div>
        <div v-else>
            <app-page-loader text="Loading Group..."></app-page-loader>
            
        </div>

        <dialog-form
            :open="adjustBaselineDialogOpen"
            title="Adjust Baseline Calculation"
            :width="600"
            @close="adjustBaselineDialogOpen = false"
            :saving="savingBaselineStartDate"
            @submit="submitAdjustBaselineCalculation"
        >
            <template #form-content>
                <v-radio-group v-model="adjustBaselineCalculationType">
                    <v-radio v-for="item in adjustBaselineStartDateRadioOptions" :key="item.value" :label="item.label" :value="item.value"></v-radio>
                </v-radio-group>
                <div v-if="adjustBaselineCalculationType === 1">
                    <app-form-date-picker
                        label="Start Date"
                        v-model="adjustBaselineStartDate"
                        class="mt-2"
                    />
                    <app-form-time-picker
                        label="Start Time"
                        v-model="adjustBaselineStartTime"
                        class="mt-2"
                    />
                </div>
               
            </template>
        </dialog-form>

        <dialog-form
            :open="adjustProtocolDialogOpen"
            title="Adjust Default Protocol"
            :width="600"
            @close="adjustProtocolDialogOpen = false"
            :saving="savingProtocol"
            @submit="submitAdjustDefaultOrganizationProtocol"
        >
            <template #form-content>
                <div class="profile-create-form-section-title">Default Protocol</div>
                <div class="profile-input-field">
                    <app-select row v-model="defaultOrganizationProtocolId" label="Default Protocol" :items="protocols" item-value="organizationProtocolId" item-text="name" rules="required"  />
                </div>

            </template>
        </dialog-form>
        

        
        
    </div>
</template>

<script>
import {
    getGroup,
    updateGroup,
    deleteGroup,
    getGroupTestDataSummary,
    getGroupNotificationPreferences,
    getGroups,
    updateGroupAccounts,
    adjustGroupBaselineStartDate, adjustGroupDefaultOrganizationProtocol
} from '@/services/groups.service'
import {adjustBaselineCalculationDate, getProfilesPaged} from "@/services/profile.service";
import {SET_GROUP, SET_SNACKBAR} from "@/store/mutation-types";
import {getAccounts} from "@/services/userService";
import {mdiCardAccountDetailsOutline} from "@mdi/js";
import {getUser} from "@/services/userService";
import {getSessions} from "@/services/session.service";
import {getSessionDetails, isAdminOrgAccessType, asLocalTime} from "@/js/shared/helper";
import moment from "moment";
import 'moment-timezone'
import {getProtocols} from "@/services/protocol.service";

export default {
    name: "Group",
    
    data() {
        return {
            group: null,
            groupPreferences: null,
            covidGroupPreference: null,
            cognitiveGroupPreference: null,
            cognitiveNotificationPreferences: null,
            covidNotificationPreferences: null,
            updatingGroup: false,
            deletingGroup: false,
            loadingGroupProfiles: false,
            createSessionDialogOpen: false,
            editGroupForm: {
                id: null,
                organizationId: null,
                name: '',
                isEnabled: false,
                isDefault: false,
            },
            editGroupDialogOpen: false,
            deleteGroupDialogOpen: false,
            groupProfiles: null,
            loadingGroupTestDataSummary: false,
            groupTestSummaryData: null,
            accounts: [],
            users: [],
            loadingSessions: false,
            sessions: [],
            groups: [],
            loadingGroup: false,
            numberOfProfiles: null,
            adjustBaselineDialogOpen: false,
            savingBaselineStartDate: false,
            adjustBaselineCalculationType: 0,
            adjustBaselineStartDate: moment().format('YYYY-MM-DD'),
            adjustBaselineStartTime: {
                hour: 12,
                minute: 0,
                amPm: 'am'
            },
            adjustBaselineStartDateRadioOptions: [
                {
                    label: "Use all tests",
                    value: 0,
                },
                {
                    label: "Use tests starting from date",
                    value: 1
                }
            ],
            adjustProtocolDialogOpen: false,
            savingProtocol: false,
            defaultOrganizationProtocolId: null,
            protocols: []
        }
    },
    async mounted() {
        this.init()
        
        // this.getAllGroupNotificationPreferences()
    },
    computed: {
        isAdmin() {
              let user = getUser()
            return isAdminOrgAccessType(user.selectedOrganizationAccessTypeId);
        },
        route() {
            return this.$route
        },
        // usersWithNotificationPreferences() {
        //     // if (!this.allNotificationPreferences && !this.users) return [];
        //     const {groupPreferences} = this
        //     
        //     if (!groupPreferences) return
        //    
        //     return groupPreferences
        //   
        //     this.users.map(user => {
        //         let userNotifications = this.allNotificationPreferences.filter(item => item.account.id === user.id);
        //        
        //     })  
        // },
        tabValue() {
          let routeArray = this.$route.path.split('/');
          let page = routeArray.pop();
          if (!this.group.isEnabled) {
              return 0
          }
          if (this.isAdmin) {
              switch (page) {
                  case 'profiles':
                      return 0;
                  case 'overview':
                      return 1;
                  case 'users':
                      return 2;
                  case 'codes':
                      return 3;
                  case 'schedule':
                      return 4
                  case 'notifications':
                      return 5
                  default:
                      return 0
              }
          }

            switch (page) {
                case 'profiles':
                    return 0;
                case 'overview':
                    return 1;
                case 'codes':
                    return 2;
                case 'schedule':
                    return 3;
                case 'notifications':
                    return 4
                default:
                    return 0
            }
          
        },
        tabs() {
            const id = this.$route.params.id
            
            if (!this.group.isEnabled) {
                return [
                    {
                        label: 'Profiles',
                        route: `/groups/${id}/profiles`,
                        icon: 'mdi-card-account-details-outline'
                    }
                ]
            }
            
            if (this.isAdmin) {
                return [
                    {
                        label: 'Profiles',
                        route: `/groups/${id}/profiles`,
                        icon: 'mdi-card-account-details-outline'
                    },
                    {
                        label: 'Overview',
                        route: `/groups/${id}/overview`,
                        icon: 'mdi-chart-timeline-variant'
                    },
                    
                    {
                        label: 'Users',
                        route: `/groups/${id}/users`,
                        icon: 'mdi-clipboard-account-outline'
                    },
                    {
                        label: 'Codes',
                        route: `/groups/${id}/codes`,
                        icon: 'mdi-account-multiple-plus'
                    },
                    {
                        label: 'Schedule',
                        route: `/groups/${id}/schedule`,
                        icon: 'mdi-calendar'
                    },
                    {
                        label: 'Notifications',
                        route: `/groups/${id}/notifications`,
                        icon: 'mdi-bell-outline'
                    },

                ]
            }
            
            return [
                {
                    label: 'Profiles',
                    route: `/groups/${id}/profiles`,
                    icon: 'mdi-card-account-details-outline'
                },
                {
                    label: 'Overview',
                    route: `/groups/${id}/overview`,
                    icon: 'mdi-chart-timeline-variant'
                },
                
                {
                    label: 'Codes',
                    route: `/groups/${id}/codes`,
                    icon: 'mdi-account-multiple-plus'
                },
                {
                    label: 'Schedule',
                    route: `/groups/${id}/schedule`,
                    icon: 'mdi-calendar'
                },
                {
                    label: 'Notifications',
                    route: `/groups/${id}/notifications`,
                    icon: 'mdi-bell-outline'
                },
            ]
            
        },
        groupActions() {
            let items = [
                {
                    label: 'Add Profile',
                    onClick: () => this.$router.push('/profiles?addProfile=true'),
                    icon: 'mdi-card-account-details-outline'
                },
                {
                    label: 'Edit Schedule',
                    onClick: this.onEditSchedule,
                    icon: 'mdi-clock-time-four-outline'
                },
                {
                    label: 'Set Notifications',
                    onClick: () => this.$router.push(`/groups/${this.group.id}/notifications`),
                    icon: 'mdi-bell-outline'
                },
                {
                    label: 'Create Code',
                    onClick: () => this.$router.push(`/groups/${this.group.id}/codes?createCode=true`),
                    icon: 'mdi-account-multiple-plus'
                },
                {
                    label: 'Adjust Group Baseline Start Date',
                    onClick: () => this.adjustBaselineDialogOpen = true,
                    icon: 'mdi-calendar'
                },
                {
                    label: 'Adjust Group Default Protocol',
                    onClick: () => this.adjustProtocolDialogOpen = true,
                    icon: 'mdi-card-account-details-outline'
                }
            ]
            if (this.isAdmin) {
                return [
                    {
                        label: 'Add User',
                        onClick: this.onAddUser,
                        icon: 'mdi-account-plus'
                    },
                    ...items,

                ]
            }  return items
            
        }
    },
    methods: {
        async getProtocols() {
            const response = await getProtocols();
            this.protocols = response.protocols
        },
        async submitAdjustBaselineCalculation() {
            this.savingBaselineStartDate = true;

            const {adjustBaselineStartDate} = this;
            let {hour, minute, amPm} = this.adjustBaselineStartTime;
            hour = amPm.toLowerCase() === 'pm' ? hour + 12 : hour;
            
            let adjustBaselineStartDateCentral = '2000-01-01T00:00:00';
            if (this.adjustBaselineCalculationType !== 0) {
                let date = asLocalTime(moment(adjustBaselineStartDate));
                adjustBaselineStartDateCentral = date.hour(hour).minute(minute).format();
            }
            let adjustBaselineStartDateToSubmit = moment.utc(adjustBaselineStartDateCentral).format();

            const request = {
                groupId: this.group.id,
                baselineStartDateUtc: adjustBaselineStartDateToSubmit
            };
            
            const response = await adjustGroupBaselineStartDate(request);
            if (!response.isSuccess) {
                this.savingBaselineStartDate = false;
                this.$store.commit(SET_SNACKBAR, {
                    text: `There was an error updating the group baseline start date.`,
                    color: 'error',
                    open: true
                });
                return
            }

            this.savingBaselineStartDate = false;
            this.adjustBaselineDialogOpen = false;
            this.$store.commit(SET_SNACKBAR, {
                text: `Successfully updated group baseline start date.`,
                color: 'success',
                open: true
            });
        },
        async submitAdjustDefaultOrganizationProtocol() {
            this.savingProtocol = true;

            const request = {
                groupId: this.group.id,
                organizationProtocolId: this.defaultOrganizationProtocolId
            };

            const response = await adjustGroupDefaultOrganizationProtocol(request);

            if (!response.isSuccess) {
                this.savingProtocol = false;
                this.$store.commit(SET_SNACKBAR, {
                    text: `There was an error updating the group default protocol.`,
                    color: 'error',
                    open: true
                });
                return
            }

            this.savingProtocol = false;
            this.adjustProtocolDialogOpen = false;
            this.$store.commit(SET_SNACKBAR, {
                text: `Successfully updated default protocol of all profiles in this group.`,
                color: 'success',
                open: true
            });
        },
        
        async init() {
            this.loadingGroup = true
            await this.getGroups()
            await this.setGroup();
            await Promise.all([this.getGroupTestDataSummary(), this.getAccounts(), this.setSessions(), this.getProtocols()])
            this.loadingGroup = false
        },
        async getGroups() {
            this.loadingGroups = true;
            const response = await getGroups();
            if (!response.isSuccess) {
                return
            }
            // this.groups = response.groups.map(group => ({
            //     name: group.name,
            //     id: group.id
            // }));

            let groups = response.groups;

            this.groups = groups;
            // this.groups = []
            this.loadingGroups = false;
        },
        async setSessions() {
            // let self = this;
            this.loadingSessions = true;
            const data = await getSessions();
            if (!data.isSuccess) {
                this.loadingSessions = false;
            }
            let {sessions} = data;

            let filteredSessions = [];
            
            if (sessions.length > 0) {
                sessions.forEach(session => {
                    session = getSessionDetails(session);
                    if (session.groupId === this.group.id && !session.isProfileSession) filteredSessions.push(session)
                });
            }
            
            



            this.sessions = filteredSessions
            this.loadingSessions = false;
        },
        onEditSchedule() {
            this.$router.push({path: `/groups/${this.group.id}/schedule`, query: { editSchedule: 'true' }})
        },
        onAddProfile() {
            this.$router.push({path: `/groups/${this.group.id}/profiles`, query: { editProfiles: 'true' }})
        },
        onAddUser() {
            this.$router.push({path: `/groups/${this.group.id}/users`, query: { addUser: '1' }})
        },
        getEmptyNotificationPreferenceObject(userId, groupPreferencesId) {
            return {
                createdDate: "",
                createdByUser: "",
                modifiedDate: "",
                modifiedByUser: "",
                // accountGroupNotificationPreferenceId: 0,
                organizationId: this.group.organizationId,
                groupId: this.group.id,
                accountId: userId,
                emailNotification: false,
                smsNotification: false,
                isEnabled: false,
                groupPreferencesId: groupPreferencesId,
                email: "",
                phoneNumber: "",
                name: ""
            }
        },
        async getAllGroupNotificationPreferences() {
            const {groupPreferences} = this;
            
            let covidGroupPreference = groupPreferences.find(item => item.demandTypeId === 1)
            let cognitiveGroupPreference = groupPreferences.find(item => item.demandTypeId === 2)
            
            this.covidGroupPreference = covidGroupPreference;
            this.cognitiveGroupPreference = cognitiveGroupPreference;
            
            let promises = [];
            let promisesDemandTypes = [];
            let covidNotificationPreferences = null;
            let cognitiveNotificationPreferences = null;
            
            if (covidGroupPreference) {
                let response = await getGroupNotificationPreferences({groupId: this.group.id, groupPreferencesId: covidGroupPreference.groupPreferencesId})
                covidNotificationPreferences = response.groupNotificationPreferences;
                
            }

            if (cognitiveGroupPreference) {
                let response = await getGroupNotificationPreferences({groupId: this.group.id, groupPreferencesId: cognitiveGroupPreference.groupPreferencesId})
                cognitiveNotificationPreferences = response.groupNotificationPreferences
            }
            
            this.cognitiveNotificationPreferences = cognitiveNotificationPreferences;
            this.covidNotificationPreferences = covidNotificationPreferences
                       
            
            // const responses = await Promise.all([promises]);
            //
            // // 
            //
            // responses.forEach((response, index) => {
            //     response.groupNotificationPreferences.forEach(preference => {
            //         preference.demandType = promisesDemandTypes[index]
            //     })
            // });
            
            
            
            // 
            //
            //
            // let usersWithNotificationPreferences = users.map(user => {
            //     let userCovidPreference = covidNotificationPreferences.find(item => item.accountId === user.id);
            //     let userCognitivePreference = cognitiveNotificationPreferences.find(item => item.accountId === user.id);
            //    
            //     user.cognitivePreferences = userCovidPreference ? userCovidPreference : this.getEmptyNotificationPreferenceObject(user.id, covidGroupPreference.groupPreferencesId)
            //     user.covidPreferences = userCognitivePreference ? userCognitivePreference : this.getEmptyNotificationPreferenceObject(user.id, cognitiveGroupPreference.groupPreferencesId)
            //    
            //     return user
            //    
            // })
            // 
            // this.users = usersWithNotificationPreferences

        },
        onClick() {
            alert('Clicked')
        },
        async getAccounts() {
            if (!this.isAdmin) return []
            let response = await getAccounts();
            if (!response.isSuccess) {
                return
            }
            this.accounts = response.accounts;
        },
        async getGroupTestDataSummary() {
            this.loadingGroupTestDataSummary = true;
            
            let request = {
                lookBackDays: 30,
                groupId: this.$route.params.id
            }
            
            let response = await getGroupTestDataSummary(request)
            if (!response.isSuccess) {
                return
            }
            this.groupTestSummaryData = response.groupTestDataSummary;
            this.loadingGroupTestDataSummary = true;
        },
        resetGroupPreferences(groupPreferences) {
            this.groupPreferences = groupPreferences;
            this.getAllGroupNotificationPreferences()
        },
        async updateGroupAccounts(request) {
            let response = await updateGroupAccounts(request)
            if (!response.isSuccess) {
                return this.$store.commit(SET_SNACKBAR, {
                    text: `There was an error updating the group's users.`,
                    color: 'error',
                    open: true
                });
            }

            this.$store.commit(SET_SNACKBAR, {
                text: `Successfully updated group's users.`,
                color: 'success',
                open: true
            });
            
            await this.init()
        },
        async setGroup() {
            this.$store.commit(SET_GROUP, null)

            const response = await getGroup(this.$route.params.id);
            if (!response.isSuccess) {
                return
            }
            
            this.group = response.group;

            this.groupPreferences = response.groupPreferences;
            let users = response.accounts;
            
            users.forEach(user => {
                user.isAdmin = isAdminOrgAccessType(user.selectedOrganizationAccessTypeId);
            })
            
            this.users = users
            
            await this.getAllGroupNotificationPreferences()
            
            const {
                id,
                organizationId,
                name,
                isDefault,
                isEnabled
            } = this.group
            
            this.editGroupForm.id = id;
            this.editGroupForm.organizationId = organizationId;
            this.editGroupForm.name = name;
            this.editGroupForm.isDefault = isDefault;
            this.editGroupForm.isEnabled = isEnabled;
            
            
            this.$store.commit(SET_GROUP, this.group)
        },
        async getGroupProfiles() {
            this.loadingGroupProfiles = true
            let request = {
                pageNumber: 1,
                rowsPerPage: 10000,
                sortByColumn: null,
                sortAscending: true,
                searchString: '',
                groupIds: [
                    parseInt(this.$route.params.id)
                ]
            }
             let response = await getProfilesPaged(request);
            // 
            this.groupProfiles = response.profiles;
            this.loadingGroupProfiles = false

        },
        async submitEditGroupForm() {
            this.updatingGroup = true;
            let self = this;

            let isNameTaken = false;
            this.groups.forEach(x => {
                if (self.editGroupForm.id !== x.id) {
                    if (x.name.toLowerCase() === self.editGroupForm.name.toLowerCase()) isNameTaken = true
                }
            })

            if (isNameTaken) {
                this.updatingGroup = false;
                return this.$store.commit(SET_SNACKBAR, {
                    text: 'Group names must be unique.',
                    color: 'error',
                    open: true
                });
            }
            
            let response = await updateGroup({group: this.editGroupForm});
            if (!response.isSuccess) {
                return
            }
            this.$store.commit(SET_SNACKBAR, {
                text: 'Group Successfully Updated',
                color: 'success',
                open: true
            })
            await this.setGroup()
            this.updatingGroup = false;
            this.editGroupDialogOpen = false
        },
        async deleteGroup() {
            this.deletingGroup = true;
            let response = await deleteGroup(this.group.id);
            if (!response.isSuccess) {
                return 
            }
            this.$store.commit(SET_SNACKBAR, {
                text: 'Group Successfully Deleted',
                color: 'success',
                open: true
            })
            this.deletingGroup = false;
            await this.$router.push('/groups')
            
        },
    }

}
</script>

<style scoped>

</style>