<template>
    <div class="">
	    <div style="display: flex">
		    <div>
			    <app-btn style="" @click="getReportPrevious">
				    Get Previous Subscription Report
			    </app-btn>
			    <app-fab-btn type="download" @click="downloadReportPrevious" />
		    </div>
		    <div >
			    <app-btn style="" @click="getReportCurrent">
				    Get Current Subscription Report
			    </app-btn>
			    <app-fab-btn type="download" @click="downloadReportCurrent" />
		    </div>
		    <div >
			    <app-btn style="" @click="getReportRenewal">
				    Get Renewal Subscription Report
			    </app-btn>
			    <app-fab-btn type="download" @click="downloadReportRenewal" />
		    </div>
			<div >
				<app-btn style="" @click="getMissingReport">
					Get Missing Subscription Report
				</app-btn>
				<app-fab-btn type="download" @click="downloadMissingReport" />
			</div>
	    </div>
	    
	    <app-card hide-header class="pa-4">
		    <div v-if="currentReport" >
			    <h2>{{currentReport === 1 ? 'Previous Subscriptions' : currentReport === 2 ? 'Current Subscriptions' : 'Renewal Subscriptions'}}</h2>
			    <v-divider class="mb-2 mt-2"></v-divider>
		    </div>
		    
		    <div style="display: flex">
			    <div style="flex: 1">
					<div><strong>Total Subscriptions</strong>: {{stats.totalSubscriptionCount}}</div>
				    <div class="pl-4">
					    <div>Sports Subscriptions: {{stats.sportsSubscriptionCount}} ({{displayPercent(stats.sportsSubscriptionCount / stats.totalSubscriptionCount)}})</div>
					    <div>Clinical Subscriptions: {{stats.clinicalSubscriptionCount}} ({{displayPercent(stats.clinicalSubscriptionCount / stats.totalSubscriptionCount)}})</div>
				    </div>
				
				    <div class="mt-2"><strong>Manual Subscriptions</strong>: {{stats.manualSubscriptionCount}} ({{displayPercent(stats.manualSubscriptionCount / stats.totalSubscriptionCount)}})</div>
				    <div class="pl-4">
					    <div>Sports Subscriptions: {{stats.sportsManualSubscriptionCount}} ({{displayPercent(stats.sportsManualSubscriptionCount / stats.manualSubscriptionCount)}})</div>
					    <div>Clinical Subscriptions: {{stats.clinicalManualSubscriptionCount}} ({{displayPercent(stats.clinicalManualSubscriptionCount / stats.manualSubscriptionCount)}})</div>
				    </div>
				
				    <div class="mt-2"><strong>Automated Subscriptions</strong>: {{stats.automatedSubscriptionCount}} ({{displayPercent(stats.automatedSubscriptionCount / stats.totalSubscriptionCount)}})</div>
				    <div class="pl-4">
					    <div>Sports Subscriptions: {{stats.sportsAutomatedSubscriptionCount}} ({{displayPercent(stats.sportsAutomatedSubscriptionCount / stats.automatedSubscriptionCount)}})</div>
					    <div>Clinical Subscriptions: {{stats.clinicalAutomatedSubscriptionCount}} ({{displayPercent(stats.clinicalAutomatedSubscriptionCount / stats.automatedSubscriptionCount)}})</div>
				    </div>
			    </div>
			    <div style="flex: 1">
				    <div><strong>Sports</strong>: {{stats.sportsSubscriptionCount}}</div>
				    <div class="pl-4">
					    <div>Sports: {{stats.sportsBasicSubscriptionCount}} ({{displayPercent(stats.sportsBasicSubscriptionCount / stats.sportsSubscriptionCount)}})</div>
					    <div>Sports Plus: {{stats.sportsPlusSubscriptionCount}} ({{displayPercent(stats.sportsPlusSubscriptionCount / stats.sportsSubscriptionCount)}})</div>
					    <div>COVID Only: {{stats.sportsCovidOnlySubscriptionCount}} ({{displayPercent(stats.sportsCovidOnlySubscriptionCount / stats.sportsSubscriptionCount)}})</div>
					    <div>Unknown: {{stats.sportsUnknownSubscriptionCount}} ({{displayPercent(stats.sportsUnknownSubscriptionCount / stats.sportsSubscriptionCount)}})</div>
				    </div>
				
				    <div class="mt-2"><strong>Clinical</strong>: {{stats.clinicalSubscriptionCount}} </div>
				    <div class="pl-4">
					    <div>Clinical Basic: {{stats.clinicalBasicSubscriptionCount}} ({{displayPercent(stats.clinicalBasicSubscriptionCount / stats.clinicalSubscriptionCount)}})</div>
					    <div>Clinical Premium: {{stats.clinicalPremiumSubscriptionCount}} ({{displayPercent(stats.clinicalPremiumSubscriptionCount / stats.clinicalSubscriptionCount)}})</div>
					    <div>Clinical Enterprise: {{stats.clinicalEnterpriseSubscriptionCount}} ({{displayPercent(stats.clinicalEnterpriseSubscriptionCount / stats.clinicalSubscriptionCount)}})</div>
					    <div>Clinical Balance Only: {{stats.clinicalBalanceOnlySubscriptionCount}} ({{displayPercent(stats.clinicalBalanceOnlySubscriptionCount / stats.clinicalSubscriptionCount)}})</div>
					    <div>Clinical All Access: {{stats.clinicalAllAccessSubscriptionCount}} ({{displayPercent(stats.clinicalAllAccessSubscriptionCount / stats.clinicalSubscriptionCount)}})</div>
					    <div>Clinical Unknown: {{stats.clinicalUnknownSubscriptionCount}} ({{displayPercent(stats.clinicalUnknownSubscriptionCount / stats.clinicalSubscriptionCount)}})</div>
				    </div>
				    
			    </div>
			    <div style="flex: 1">
				    <div style="display: flex; gap: .25rem">
					    <div>Subscription Type: </div>
					
					    <app-chip color="green" label small>Sports</app-chip>
					    <app-chip color="blue" label small>Clinical</app-chip>
					    <app-chip color="orange" label small>Sports + Clinical</app-chip>
				    </div>
				    <div style="display: flex; gap: .25rem" class="pt-2">
					    <div>Billing Mode: </div>
					    <app-chip color="blue" label small>Automated</app-chip>
					    <app-chip color="orange" label small>Manual</app-chip>
				    </div>
				    <div style="display: flex; gap: .25rem" class="pt-2">
					    <div>Organization Attributes: </div>
					    <app-chip color="purple" label small>Research</app-chip>
					    <app-chip color="green" label small>Trial</app-chip>
					    <app-chip color="blue" label small>Internal</app-chip>
					    <app-chip color="red" label small>Disabled</app-chip>
				    </div>
			    </div>
		    </div>
		   
		
<!--		    <div class="pt-2">-->
<!--			    <div style="font-weight: bold">Unsent Invoice Report Description</div>-->
<!--			    <p>The Unsent Invoice Report shows all invoices that have been created but not sent to the customer through the portal.</p>-->
<!--		    </div>-->
		    
		    
	    </app-card>
        
		<app-card hide-header class="mt-2">
			<v-data-table 
				:items="reportRows" 
				:headers="headers"
				show-expand
				@click:row="(item, slot) => slot.expand(!slot.isExpanded)"
				id="subscriptions-table"
				ref="subscriptions-table"
				single-expand
				:options.sync="options"
				item-key="organizationId"
			>
				
				
				<template v-slot:item.organizationName="{ item }">
					{{item.organizationName}}
					<app-chip
						v-if="item.isResearch"
						color="purple"
						label
						class="ml-2"
						small
					>
						Research
					</app-chip>
					<app-chip
						v-if="item.isTrial"
						color="green"
						label
						class="ml-2"
						small
					>
						Trial
					</app-chip>
					<app-chip
						v-if="item.isDisabled"
						color="red"
						label
						class="ml-2"
						small
					>
						Disabled
					</app-chip>
					<app-chip
						v-if="item.isInternalOrg"
						color="blue"
						label
						class="ml-2"
						small
					>
						Internal
					</app-chip>
				</template>
				<template v-slot:item.subscriptionType="{ item }">
					<app-chip
						v-if="item.isSportsSubscription && item.isClinicalSubscription"
						color="orange"
						label
					>
						Sports + Clinical
					</app-chip>
					<app-chip
						v-else-if="item.isSportsSubscription"
						color="green"
						label
					>
						Sports
					</app-chip>
					<app-chip
						v-if="item.isClinicalSubscription"
						color="blue"
						label
					>
						Clinical
					</app-chip>
				</template>
				
				<template v-slot:item.isManualBilling="{ item }">
					<app-chip
						v-if="item.isManualBilling"
						color="orange"
						label
					>
						Manual
					</app-chip>
					<app-chip
						v-else
						color="blue"
						label
					>
						Automated
					</app-chip>
				</template>
				
				<template v-slot:item.link="{ item }">
					<div @click="onRowClick(item)">
						<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M15.3089 18.8397H2.35521C1.05396 18.8397 0 17.7858 0 16.4847V3.53244C0 2.23132 1.05396 1.17748 2.35521 1.17748H7.06563V3.53244H2.35521V16.4847H15.3089V11.7748H17.6641V16.4847C17.6641 17.7858 16.6101 18.8397 15.3089 18.8397ZM9.06757 11.4298L7.40718 9.76487L14.8178 2.35496H10.5984V0H18.8417V8.24235H16.4865V4.02106L9.06757 11.4298Z" fill="#CCCCCC"/>
						</svg>
					
					</div>
				
				
				
				</template>
				
				<template v-slot:expanded-item="{ headers, item }" >
					<td :colspan="headers.length" style="background-color: #fafafa">
						<SubscriptionReportsDetails :subscription="item" />
					</td>
				
				</template>
			</v-data-table>
		</app-card>
        
    </div>
</template>

<script>
import moment from 'moment'
import {
	getSubscriptionReport, 
    getMissingSubscriptionReport,
    getMissingSubscriptionReportExport,
	getSubscriptionReportExport
} from "@/services/report.service";
import SubscriptionReportsDetails from "@/components/Reporting/SubscriptionReports/SubscriptionReportsDetails";

export default {
    name: "SubscriptionReport",
    components: {
	    SubscriptionReportsDetails
    },
    data () {
        return {
			stats: {
				totalSubscriptionCount: null,
				sportsSubscriptionCount: null,
				clinicalSubscriptionCount: null,
				manualSubscriptionCount: null,
				sportsManualSubscriptionCount: null,
				clinicalManualSubscriptionCount: null,
				automatedSubscriptionCount: null,
				sportsAutomatedSubscriptionCount: null,
				clinicalAutomatedSubscriptionCount: null,
				
				sportsBasicSubscriptionCount: null,
				sportsPlusSubscriptionCount: null,
				sportsCovidOnlySubscriptionCount: null,
				sportsUnknownSubscriptionCount: null,
				
				clinicalBasicSubscriptionCount: null,
				clinicalPremiumSubscriptionCount: null,
				clinicalEnterpriseSubscriptionCount: null,
				clinicalBalanceOnlySubscriptionCount: null,
				clinicalAllAccessSubscriptionCount: null,
				clinicalUnknownSubscriptionCount: null,
				
			},
			
			
			currentReport: null, // 1 = previous, 2 = current, 3 = renewal
	        options: {},
            reportRows: [],
            headers: [
	            {
		            "text": "Org Id",
		            "value": "organizationId"
	            },
	            {
		            "text": "Org Name",
		            "value": "organizationName"
	            },
	            {
		            "text": "Bill To Org ID",
		            "value": "billToOrganizationId"
	            },
	            {
		            "text": "Bill To Org Name",
		            "value": "billToOrganizationName"
	            },
	            {
		            "text": "Subscription Type",
		            "value": "subscriptionType"
	            },
	            
	            {
		            "text": "Billing Mode",
		            "value": "isManualBilling"
	            },
	            {
		            "text": " ",
		            "value": "link"
	            },
            ]
        }
    },

    mounted () {
    },
    methods: {
        async getReportPrevious() {
            this.loading = true;
			this.currentReport = 1;

            const response = await getSubscriptionReport({IsUpcoming: false, IsPrevious: true});
            // this.headers = response.headers;
	        this.reportRows = this.formatReport(response.report);
        },
        async getReportCurrent() {
            this.loading = true;

			this.currentReport = 2
            const response = await getSubscriptionReport({IsUpcoming: false, IsPrevious: false});
            // this.headers = response.headers;
	        this.reportRows = this.formatReport(response.report);
        },
        async getReportRenewal() {
            this.loading = true;
	        
			this.currentReport = 2
            const response = await getSubscriptionReport({IsUpcoming: true, IsPrevious: false});
            // this.headers = response.headers;
            this.reportRows = this.formatReport(response.report);
        },
        async getMissingReport() {
            this.loading = true;

            const response = await getMissingSubscriptionReport();
	        this.reportRows = this.formatReport(response.report);
        },
	    formatReport(report) {
			
			let manualSubscriptionCount = 0;
			let sportsSubscriptionCount = 0;
			let clinicalSubscriptionCount = 0;
			let sportsManualSubscriptionCount = 0;
			let clinicalManualSubscriptionCount = 0;
			let organizationsWithoutSubscriptionsCount = 0;
			
			let sportsBasicSubscriptionCount = 0;
			let sportsPlusSubscriptionCount = 0;
			let sportsCovidOnlySubscriptionCount = 0;
			let sportsUnknownSubscriptionCount = 0;
			
			let clinicalBasicSubscriptionCount = 0;
			let clinicalPremiumSubscriptionCount = 0;
			let clinicalEnterpriseSubscriptionCount = 0;
			let clinicalBalanceOnlySubscriptionCount = 0;
			let clinicalAllAccessSubscriptionCount = 0;
			let clinicalUnknownSubscriptionCount = 0;
			
			
			report.forEach(subscription => {
				
				// Calculate Statistics and Format Subscriptions
				subscription.isSportsSubscription = !!subscription.sportsSubscriptionStartDate;
				subscription.isClinicalSubscription = !!subscription.clinicalSubscriptionStartDate;
				subscription.subscriptionType = subscription.isSportsSubscription && subscription.isClinicalSubscription ? 'Sports + Clinical' : subscription.isSportsSubscription ? 'Sports' : 'Clinical'
				
				
				if (subscription.isManualBilling) manualSubscriptionCount++
				if (subscription.isSportsSubscription) sportsSubscriptionCount++
				if (subscription.isClinicalSubscription) clinicalSubscriptionCount++
				if (subscription.isManualBilling && subscription.isSportsSubscription) sportsManualSubscriptionCount++
				if (subscription.isManualBilling && subscription.isClinicalSubscription) clinicalManualSubscriptionCount++
				
				// Count Sports Subscription Types
				switch (subscription.sportsPackageTier) {
					case 1:
						sportsBasicSubscriptionCount++
						break
					case 2:
						sportsPlusSubscriptionCount++
						break
					case 3:
						sportsCovidOnlySubscriptionCount++
						break;
					default:
						if (subscription.sportsPackageTier) sportsUnknownSubscriptionCount++
						break
				}
				
				// Count Clinical Subscription Types
				switch (subscription.clinicalPackageTier) {
					case 3:
						clinicalBasicSubscriptionCount++
						break
					case 4:
						clinicalPremiumSubscriptionCount++
						break
					case 5:
						clinicalEnterpriseSubscriptionCount++
						break;
					case 7:
						clinicalBalanceOnlySubscriptionCount++
						break;
					case 8:
						clinicalAllAccessSubscriptionCount++
						break;
					default: 
						if (subscription.clinicalPackageTier) clinicalUnknownSubscriptionCount++
						break
				}
				
				// Format Dates
				subscription.sportsSubscriptionStartDate = subscription.sportsSubscriptionStartDate ? moment(subscription.sportsSubscriptionStartDate).format('YYYY-MM-DD') : null
				subscription.sportsSubscriptionEndDate = subscription.sportsSubscriptionEndDate ? moment(subscription.sportsSubscriptionEndDate).format('YYYY-MM-DD') : null
				subscription.clinicalSubscriptionStartDate = subscription.clinicalSubscriptionStartDate ? moment(subscription.clinicalSubscriptionStartDate).format('YYYY-MM-DD') : null
				subscription.clinicalSubscriptionEndDate = subscription.clinicalSubscriptionEndDate ? moment(subscription.clinicalSubscriptionEndDate).format('YYYY-MM-DD') : null
				
			})
		    
		    this.stats.totalSubscriptionCount = report.length;
		    this.stats.sportsSubscriptionCount = sportsSubscriptionCount
		    this.stats.clinicalSubscriptionCount = clinicalSubscriptionCount
		    this.stats.manualSubscriptionCount = manualSubscriptionCount
		    this.stats.sportsManualSubscriptionCount = sportsManualSubscriptionCount
		    this.stats.clinicalManualSubscriptionCount = clinicalManualSubscriptionCount
		    this.stats.automatedSubscriptionCount = report.length - manualSubscriptionCount
		    this.stats.sportsAutomatedSubscriptionCount = sportsSubscriptionCount - sportsManualSubscriptionCount
		    this.stats.clinicalAutomatedSubscriptionCount = clinicalSubscriptionCount - clinicalManualSubscriptionCount
		    
		    this.stats.sportsBasicSubscriptionCount = sportsBasicSubscriptionCount;
		    this.stats.sportsPlusSubscriptionCount = sportsPlusSubscriptionCount;
		    this.stats.sportsCovidOnlySubscriptionCount = sportsCovidOnlySubscriptionCount;
		    this.stats.sportsUnknownSubscriptionCount = sportsCovidOnlySubscriptionCount;
		    
			
			this.stats.clinicalBasicSubscriptionCount = clinicalBasicSubscriptionCount;
			this.stats.clinicalPremiumSubscriptionCount = clinicalPremiumSubscriptionCount;
			this.stats.clinicalEnterpriseSubscriptionCount = clinicalEnterpriseSubscriptionCount;
			this.stats.clinicalBalanceOnlySubscriptionCount = clinicalBalanceOnlySubscriptionCount;
			this.stats.clinicalAllAccessSubscriptionCount = clinicalAllAccessSubscriptionCount;
			this.stats.clinicalUnknownSubscriptionCount = clinicalUnknownSubscriptionCount;		    
		    
			return report
	    },
        async downloadReportPrevious() {
            this.loading = true;

            const response = await getSubscriptionReportExport({IsUpcoming: false, IsPrevious: true});
        },
        async downloadReportCurrent() {
            this.loading = true;
            
            const response = await getSubscriptionReportExport({IsUpcoming: false, IsPrevious: false});
        },
        async downloadReportRenewal() {
            this.loading = true;

            const response = await getSubscriptionReportExport({IsUpcoming: true, IsPrevious: false});
        },
        async downloadMissingReport() {
            this.loading = true;

            const response = await getMissingSubscriptionReportExport();
        },
	    displayPercent(number) {
			if (isNaN(number)) return '0%';
			return `${(number * 100).toFixed(2)}%`
	    },
	    async onRowClick(item) {
		
		    window.open(`${window.location.origin}/subscription/setup?orgId=${item.organizationId}`, '_blank')
	    },
    },
}
</script>

<style scoped>

</style>