<template>
        
        <app-card  title="Personal Information" editable @edit="$emit('editAccount')">
<!--            <div style="display: flex; align-content: center">-->
<!--                <v-spacer></v-spacer>-->

<!--                <v-btn icon @click="editAccountDialogOpen = true">-->
<!--                    <v-icon class="edit-icon">mdi-pencil</v-icon>-->
<!--                </v-btn>-->

<!--            </div>-->
            <div class="pa-4 text--text">
                <div v-for="item in mappedAccountProperties" class="mt-2 " v-if="item.value">
                    <div style="display: flex; align-items: center">
                        <div v-if="item.icon" style="padding: 8px; border: 1px solid var(--v-border-base); border-radius: 30px">
                            <v-icon >{{item.icon}}</v-icon>
                        </div>

                        <div class="ml-2 label" >{{item.label}}: </div>
                        <div style="color: var(--v-text-darken2); font-weight: 500">
                            <span class="ml-2" >{{item.value}}</span>
                        </div>
                    </div>

                </div>
<!--                <div style="display: flex" class="mt-8">-->
<!--                    <app-btn @click="$emit('editAccount')">Edit Account</app-btn>-->
<!--                </div>-->
            </div>
            
            
        </app-card>
    

</template>

<script>
import {getProfessionalTypeById} from "@/js/utilities";

export default {
    name: "AccountPersonalInformation",
    props: {
        account: {
            required: true
        },
        isAdmin: {}
    },
    computed: {
        mappedAccountProperties() {
            let professionalType = getProfessionalTypeById(this.account.professionalTypeId)
            var fields = [
                {
                    label: 'Name',
                    value: this.account.firstName + ' ' + this.account.lastName,
                    icon: 'mdi-account'
                },
                // {
                //     label: 'Last Name',
                //     value: this.account.lastName
                // },
                {
                    label: 'Professional Designation',
                    value: this.account.jobTitle && this.account.jobTitle !== 'N/A' ? this.account.jobTitle : '',
                    icon: 'mdi-tie'
                },
                {
                    label: 'Email Address',
                    value: this.account.email,
                    icon: 'mdi-email-outline'
                },
                {
                    label: 'Phone Number',
                    value: this.account.phoneNumber,
                    icon: 'mdi-phone'
                },
                {
                    label: 'Medical Credentials',
                    value: professionalType,
                    icon: 'mdi-badge-account-horizontal-outline'
                },
                // {
                //     label: 'Access Level',
                //     value: accessType,
                // },

            ]
            
            return fields;
        }
    }
}
</script>

<style scoped>
.label {
    color: var(--v-text-lighten2)
}
</style>