<template>
	<AppCard hide-header>
		<div style="font-size: 1.2em; font-weight: bold">
			{{name}}
		</div>
		<div class="mt-2">
			<div>
				{{address1}}
			</div>
			<div>
				{{address2}}
			</div>
            <br/>
            <div>
                Total Tests: {{totalTestCount}}
            </div>
            <div>
                Total Profiles: {{totalProfileCount}}
            </div>
		</div>
		<AppButton small class="mt-2" @click="goToOrg">
			View Org
		</AppButton>
		
	</AppCard>
</template>

<script>
import AppButton from "@/components/AppButton";
export default {
	name: "MapLocationCard",
	components: {AppButton},
	props: {
		name: {
			type: String
		},
		address1: {
			type: String
		},
		address2: {
			type: String
		},
        totalTestCount: {
            type: String
        },
        totalProfileCount: {
            type: String
        },
		id: {}
		
	},
	methods: {
		goToOrg() {
			this.$emit('goToOrg', {id: this.id})
		}
	}
}
</script>

<style scoped>

</style>