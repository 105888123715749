<template>
	<div style="overflow-x: scroll">
		<div style="font-size: 12px" v-if="individualMeasure.vomsResults">
			<!--			<table>-->
			<!--				<tr -->
			<!--					style="color: var(&#45;&#45;text-primary); background-color: var(&#45;&#45;main-bg-color)"-->
			<!--					-->
			<!--				>-->
			<!--					<th -->
			<!--						v-for="(header, index) in tableHeaders"-->
			<!--						:key="index"-->
			<!--					>-->
			<!--						{{header}}-->
			<!--					</th>-->
			<!--					-->
			<!--				</tr>-->
			<!--				<tr  -->
			<!--					v-for="(row, index) in tableRows"-->
			<!--					:key="index"-->
			<!--				>-->
			<!--					<td v-for="(item, itemIndex) in row" :key="itemIndex">-->
			<!--						<div v-if="Array.isArray(item)">-->
			<!--							<div v-for="(x, xIndex) in item" :key="xIndex" style="min-width: 100px">{{x}}</div>-->
			<!--						</div>-->
			<!--						<div v-else>-->
			<!--							{{item}}-->
			<!--						</div>-->
			<!--						-->
			<!--					</td>-->
			<!--				</tr>-->
			<!--			</table>-->
			
			<div>
				<div v-for="(stat, index) in stats" :key="index">
					<span style="color: var(--v-text-lighten2)">{{stat.label}}:</span> <strong>{{stat.value}}</strong>
				</div>
			</div>
			<table>
				<tr
					style="color: red; background-color: #fafafa"
				
				>
					<th
						v-for="(header, index) in headers"
						:key="index"
						style="background-color: white; color: black!important; text-align: center"
					>
						{{header}}
					</th>
				
				</tr>
				<tr
					v-for="(row, index) in rows"
					:key="index"
				
				>
					<td v-for="(item, itemIndex) in row" :key="itemIndex" :style="{backgroundColor: item.greyBackground ? `#f5f5f5` : null, minWidth: itemIndex === 6 ? '125px' : null}">
						<!--						<div v-if="Array.isArray(item)">-->
						<!--							<div v-for="(x, xIndex) in item" :key="xIndex" style="min-width: 100px">{{x}}</div>-->
						<!--						</div>-->
						<!--						<div v-else>-->
						<!--							{{item}}-->
						<!--						</div>-->
						<div style="justify-content: center; align-items: center">
							<div v-html="item.value" :style="{textAlign: itemIndex == 6 || itemIndex == 0 ? null : 'center'}"></div>
							<div v-if="item.isMaxProvocation" style="background-color: rgba(0,0,0,.1); color: #878787; font-weight: bold;  padding: 3px 10px; border-radius: 5px; text-align: center; min-width: 50px">&#9650; {{ item.provocationValue }}</div>
						</div>
					
					
					</td>
				</tr>
			</table>
		</div>
	</div>


</template>

<script>
import {allMeasuresFromConstants} from "@/js/shared/constants";

export default {
	name: "VomsTable",
	props: {
		// score: {
		//     type: Number,
		//     required: true
		// },
		individualMeasure: {
			
		}
	},
	data() {
		return {
			tableHeaders: ['', 'Headache', 'Dizziness', 'Nausea', 'Fogginess', 'Comments'],
		}
	},
	computed: {
		tableRows() {
			// return this.table.map(x => {
			//     x.isActive = this.score >= x.minValue && this.score <= x.maxValue;
			//     return x
			// })
			
			
			const {results} = this.individualMeasure;
			let response = [];
			
			let convergenceMeasure1 = 'Measure 1 (cm): '
			let convergenceMeasure2 = 'Measure 2 (cm): '
			let convergenceMeasure3 = 'Measure 3 (cm): '
			
			results.forEach((x, index) => {
				
				if (!(index >= 4 && index <= 6)) {
					response.push([])
				} else {
					switch (index) {
						case 4:
							convergenceMeasure1 += x.detailRows[0].value ;
							break
						case 5:
							convergenceMeasure2 += x.detailRows[0].value
							break
						case 6:
							convergenceMeasure3 += x.detailRows[0].value
							break
					}
					
					return
				}
				
				response[response.length - 1].push(allMeasuresFromConstants[x.test].shortLabel)
				
				let {detailRows} = x;
				
				detailRows.forEach((y, yIndex) => {
					if (index === 7 && yIndex === 4) {
						response[response.length - 1].push([convergenceMeasure1, convergenceMeasure2, convergenceMeasure3, y.value])
						return
					}
					
					response[response.length - 1].push(y.value)
				})
				
				// const measureLabel = 
				// const {detailRows} = x;
				// if (detailRows.length === 6) {
				//
				// }
			})
			
			return response
		},
		structuredData() {
			const {results} = this.individualMeasure;
			
			results.forEach(x => {
				const measureLabel = allMeasuresFromConstants[x.test].label;
				
			})
			
		},
		headers() {
			return this.individualMeasure.vomsResults.vomsTable.headers
		},
		rows() {
			return this.individualMeasure.vomsResults.vomsTable.rows
		},
		stats() {
			return [
				{
					label: 'Average Convergence',
					value: this.individualMeasure.vomsResults.averageConvergence + ' cm'
				},
				{
					label: 'Baseline Symptoms',
					value: this.individualMeasure.vomsResults.baselineSymptomsTotal
				},
				{
					label: 'Max Provocation',
					value: this.individualMeasure.vomsResults.maxTotalProvocation
				},
                {
                    label: 'Max Total Symptoms',
                    value: (this.individualMeasure.vomsResults.baselineSymptomsTotal || 0)
                            + this.individualMeasure.vomsResults.maxTotalProvocation
                },
			]
		}
		
	}
}
</script>

<style scoped>
.activeRow {
	font-weight: bold;
	color: var(--text-primary);
	/*background-color: var(--main-bg-color);*/
	background-color: rgba(255,0,0,.1);
}

table {
	font-family: arial, sans-serif;
	border-collapse: collapse;
	width: 100%;
}

td, th {
	border: 1px solid #dddddd;
	text-align: left;
	padding: 8px;
}
</style>