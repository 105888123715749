import {getProtocols} from "@/services/protocol.service";
import {getGroups} from "@/services/groups.service";

export default {
    data() {
        return {
            protocols: [],
            groups: [],
            profiles: [],
        }
    },
    methods: {
        async setProtocols() {
            const response = await getProtocols();
            this.protocols = response.protocols;


        },
        async setGroups() {
            const response = await getGroups();
            this.groups = response.groups;
        },
    }
    
}