import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import {swayThemes, readyCheckThemes} from '@/config/themes'
// import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

let theme = 'sway';

try {
    theme = process.env.VUE_APP_PRODUCT_ENVIRONMENT
} catch(e) {
    
}



export default new Vuetify({
    theme: {
        options: { customProperties: true },
        dark: false,
        themes: theme === 'sway' ? swayThemes : readyCheckThemes
    },
    icons: {
        iconfont: 'mdiSvg', // default - only for display purposes
    },
});
