import moment from "moment";
import {formatMoney, formatNumber, getPackageTierById} from "@/js/shared/helper";
import {subscriptionColors} from "@/js/shared/constants";

export default class Subscription {
    constructor(data) {
        const {
            subscriptionStartDate,
            subscriptionEndDate,
            isDemo,
            systemFee,
            isManualBilling,
            profilePrice,
            billingCycle,
            doNotRenew,
            isFree,
            productSubscriptionId,
            profileCount,
            profileAddOnCount,
            packageTier
        } = data;

        this.data = data;

        this.isCurrent = moment().isBetween(subscriptionStartDate, subscriptionEndDate);
        this.isFuture = moment().isBefore(subscriptionStartDate)


        let dateFormat = "MMM D, YYYY"

        this.productSubscriptionId = productSubscriptionId;
        this.startDate = {
            value: subscriptionStartDate,
            display: moment(subscriptionStartDate).format(dateFormat)
        };
        this.endDate = {
            value: subscriptionEndDate,
            display: moment(subscriptionEndDate).format(dateFormat)
        }

        let duration = moment(subscriptionEndDate).diff(subscriptionStartDate, 'days')
        this.duration = {
            value: duration,
            display: `${duration} days`
        }

        this.isDemo = isDemo;

        this.systemFee = {
            value: systemFee,
            display: formatMoney(systemFee)
        };
        this.isManualBilling = isManualBilling;
        this.profilePrice = {
            value: profilePrice,
            display: formatMoney(profilePrice)
        };

        this.billingCycle = billingCycle;
        this.doNotRenew = doNotRenew;
        this.isFree = isFree;

        this.profileCount = {
            value: profileCount,
            display: formatNumber(profileCount)
        };
        this.profileAddOnCount = {
            value: profileAddOnCount,
            display: formatNumber(profileAddOnCount)
        };
        this.totalProfileCount = {
            value: profileCount + profileAddOnCount,
            display: formatNumber(profileCount + profileAddOnCount)
        };
        this.packageTier = {
            value: packageTier,
            display: getPackageTierById(packageTier)
        };
        this.total = {
            value: profileCount * profilePrice + profileAddOnCount * profilePrice + systemFee,
            display: formatMoney(profileCount * profilePrice + profileAddOnCount * profilePrice + systemFee)
        }


        // this.displayName = packageTier ? this.packageTier.display : isFree ? 'Free Forever' : isManualBilling ? 'Manual' : isDemo ? 'Trial' : 'Other'

        if (packageTier) {
            this.displayName = this.packageTier.display;
            this.color = (packageTier >= 3 && packageTier <= 5 || packageTier == 7 || packageTier == 8) ? subscriptionColors.clinical : subscriptionColors.sports; // Tiers 3 - 5 are legacy clinical subscriptions
        } else if (isFree) {
            this.displayName = 'Free';
            this.color = subscriptionColors.free;
        } else if (isManualBilling) {
            this.displayName = 'Manual';
            this.color = subscriptionColors.manual;
        } else if (isDemo) {
            this.displayName = 'Trial';
            this.color = subscriptionColors.trial;
        } else {
            this.displayName = 'Other';
            this.color = subscriptionColors.manual;
        }


    }

    getSubscriptionForm = () => {
        const {
            subscriptionStartDate,
            subscriptionEndDate,
            isDemo,
            systemFee,
            isManualBilling,
            profilePrice,
            billingCycle,
            doNotRenew,
            isFree,
            productSubscriptionId,
            profileCount,
            profileAddOnCount,
            packageTier,
            manualEstimatedTotal,
        } = this.data;

        return {
            subscriptionStartDate,
            subscriptionEndDate,
            billingCycle,
            profileCount,
            profileAddOnCount,
            profilePrice,
            packageTier,
            systemFee,
            isManualBilling,
            isDemo,
            productSubscriptionId,
            isFree,
            doNotRenew,
            manualEstimatedTotal,
        }
    }

    static getNewSportsSubscriptionForm = () => {
        return {
            subscriptionStartDate: null,
            subscriptionEndDate: null,
            billingCycle: null,
            profileCount: null,
            profileAddOnCount: null,
            profilePrice: null,
            packageTier: null,
            systemFee: null,
            isManualBilling: null,
            isDemo: null,
            productSubscriptionId: null,
            isFree: null,
            doNotRenew: null
        }
    }




}