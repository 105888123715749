<template>
    <div style="position: relative">
        <highcharts :options="chartOptions" ref="highchart"/>
        <ChartIndicator :label="displayScore" :xPosition="indicatorXPosition" />
    </div>
</template>

<script>
import ChartIndicator from "@/components/ChartIndicator";
export default {
    name: "TestColumnChart",
    components: {ChartIndicator},
    props: {
        profileTest: {
            required: true
        },
        measureId: {
            required: true
        }
    },
    data() {
        return {
            indicatorXPosition: 40,
        }
    },
    computed: {
        chartOptions() {
            if (!this.profileTest) return null;

            return this.profileTest.getMeasureColumnRangeChartOptionsById({
                id: this.measureId,
                disableAnimation: true,
                resizeCallback: (indicatorXPosition) => {
                    // 
                    this.indicatorXPosition = indicatorXPosition;
                }});
        },
        displayScore() {
            let results = this.profileTest.formattedTestsData[this.measureId];
            return results.score.toFixed(0);
        }
    }
}
</script>

<style scoped>

</style>