<template>
    <div>
        <app-loader v-if="isLoading"></app-loader>
        <LoginFrame :isMobileSso=isMobileSso>
            <div v-show="!isLoading" class="login-form-container">
                <h1 style="font-weight: 600">{{ titleText }}</h1>
                <br />
                <div>
                    <app-text-field id="username" name="username" v-model="email" type="email" label="Email" class="login-input" :disabled="!isPreLoginShowing" @onKeypressEnter="onEnter"/>
                    <app-text-field id="password" name="password" v-model="password" type="password" label="Password" class="mt-3 login-input" v-if="isPasswordShowing" @onKeypressEnter="onEnter"/>
                    <a v-if="showOAuthLoginLink && !isPasswordShowing" href="#" @click="loginWithSso">Log in with SSO</a>
                    <a v-else-if="showSamlLoginLink && !isPasswordShowing" href="#" @click="loginWithSaml">Log in with SSO</a>
                    <br/>
                    <a v-if="isPasswordOptionShowing" href="#" @click="showPasswordLogin">Log in with Sway password</a>
                </div>
                <app-btn
                        color="primary"
                        style="width: 100%"
                        class="mt-3"
                        @click="onSubmit"
                        :loading="loading"
                        v-if="isPreLoginShowing || isPasswordShowing"
                >Submit</app-btn>
                <div style="margin-top: 20px; color: var(--v-text-lighten2)" class="hover" @click="forgotPassword" v-if="isPasswordShowing">
                    Forgot Password
                </div>
                <div style="margin-top: 20px; color: var(--v-text-lighten2)" class="hover" @click="backToPreLogin" v-if="showOAuthLoginLink || showSamlLoginLink || isPasswordShowing">
                    Login With Different Email
                </div>

            </div>
        </LoginFrame>
        <v-dialog
            v-model="mfaVerificationDialog"
            :width="600"
        >
            <MfaDialog
                    :account="account"
                    @setAccount="setAccount"
                    @finishLogin="finishLogin">
            </MfaDialog>
        </v-dialog>
    </div>


</template>

<script>

import semver from "semver";

let viewport = document.querySelector("meta[name=viewport]");
viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

import funcs from "@/js/funcs";
import httpService from "@/services/httpService";
import {auth, loginRequest, tokenConfig} from "@/main";
import {baseUrl} from "@/config"
import {isMobileBrowser} from "@/js/utilities";
import LoginFrame from "@/components/Login/LoginFrame.vue";
import MfaDialog from "@/components/Login/MfaDialog.vue";
import {
    login,
    ssologin,
    switchSelectedOrganization,
    samllogin, getloginmethod
} from "@/services/userService";
export default {
    name: "Login",
    components: {MfaDialog, LoginFrame},
    props: {
        account: {}
    },
    data() {
        return {
            titleText: 'Login',
            email: '',
            password: '',
            failedLogin: false,
            loading: false,
            mfaVerificationDialog: false,

            ssoAccount: null,
            ssoToken: null,

            isPreLoginShowing: true,
            showOAuthLoginLink: false,
            showSamlLoginLink: false,
            
            isPasswordOptionShowing: false,
            isPasswordShowing: false,
            isLoading: true,
            isMobileSso: false,
            mobileAppVersion: null,
            isCallback: false,
            isSamlLinkPressed: false
        }
    },
    beforeMount() {
        if (this.$route.query.mobileSso)
            this.isMobileSso = true;
        if (this.$route.query.mobileAppVersion)
            this.mobileAppVersion = this.$route.query.mobileAppVersion;
        if (this.$route.query.isCallback)
            this.isCallback = true;
        
        try{
            this.ssoAccount = auth.getAccount();

            if (this.ssoToken){
                this.extraLoginOauth();
            }
            
            if (this.ssoAccount) {
                this.isLoading = true;
                this.getTokenSso().then((token) => {
                    try{
                        this.ssoToken = token.accessToken;
                        if (this.ssoToken){
                            this.extraLoginOauth();
                        }
                    }
                    catch (ex){}
                    this.isLoading = false;
                });
            }
        }
        catch (ex){
            this.isLoading = false;
        }
        
        try{
            let email = this.$route.query.email;
            if (email && this.isMobileSso && !this.isCallback){
                this.email = email;
                this.onSubmit();
                return;
            }
        }
        catch (ex){

        }

        try{
            var cookies = document.cookie;
            var samlCookie = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith("portal_samljwt="))
                    ?.split("=")[1];
            if (samlCookie)
            {
                if (this.isMobileSso) {
                    try {
                        this.sendNativeCommand('samlReceived', samlCookie);
                        return;
                    } catch (ex) {
                        // do nothing, might not be using Sway app
                    }
                    return;
                }
                
                httpService.setAuthTokenHeader(samlCookie);
                this.extraLoginSaml();
                return;
            }
            this.isLoading = false;
        }
        catch (ex){
            this.isLoading = false;
        }
    },
    mounted() {
        
    },
    computed: {
        samlUrl() {
            return baseUrl + "/saml/InitiateSingleSignOn?returnurl=" + this.returnUrl + "&email=" + encodeURIComponent(this.email);
            //return 'https://localhost:5001/api/saml/InitiateSingleSignOn?returnurl=' + this.returnUrl;
        },
        returnUrl() {
            let inputUrl = new URL(window.location.href);
            let inputParams = inputUrl.searchParams;
            if (!inputParams.has('isCallback'))
                inputParams.append('isCallback', 'true');
            if (!inputParams.has('email')) // HACK: SOMETIMES MOBILE POPULATES THIS
                inputParams.append('email', this.email);
            inputUrl.search = inputParams.toString();
            return encodeURIComponent(inputUrl.toString());
        }
    },
    watch: {
        
    },
    methods: {
        onEnter() {
            this.onSubmit()
        },
        async onSubmit() {
            if (!this.email)
                return;
            
            this.loading = true;
            if (this.isPreLoginShowing){
                const response = await getloginmethod(this.email);
                if (!response.isSuccess) {
                    this.loading = false;
                    this.failedLogin = true;
                }
                
                if (response && response.isSuccess){
                    this.loading = false;
                    this.isPreLoginShowing = false;
                    
                    if (response.authenticationSource == 1){ // sway username password
                        this.isPasswordShowing = true;
                        this.showOAuthLoginLink = false;
                        this.showSamlLoginLink = false;
                        this.isPasswordOptionShowing = false;
                    }
                    else if (response.authenticationSource == 2){ // oauth
                        this.titleText = "SSO Login";
                        this.isPasswordShowing = false;
                        this.showOAuthLoginLink = true;
                        this.showSamlLoginLink = false;
                        this.isPasswordOptionShowing = response.allowSwayUsernamePassword;
                    }
                    else if (response.authenticationSource == 3){ // saml
                        this.titleText = "SSO Login";
                        this.isPasswordShowing = false;
                        this.showOAuthLoginLink = false;
                        this.showSamlLoginLink = true;
                        this.isPasswordOptionShowing = response.allowSwayUsernamePassword;
                    }
                }
                
                this.isLoading = false;
            }
            else{
                const response = await login(this.email, this.password);
                await this.onLoginResponse(response);
            }
        },
        async backToPreLogin(){
            if (this.isMobileSso){
                this.sendNativeCommand('cancelLogin');
                return;
            }
            this.showOAuthLoginLink = false;
            this.showSamlLoginLink = false;
            this.isPasswordShowing = false;
            this.isPasswordOptionShowing = false;
            this.isPreLoginShowing = true;
            this.email = null;
            this.titleText = 'Login';
            this.isLoading = false;
        },
        async forgotPassword(){
            await this.$router.push('forgot-password');
        },
        async onLoginResponse(response) {
            if (!response || !response.isSuccess) {
                this.loading = false;
                this.failedLogin = true
                // this.$store.commit(SET_SNACKBAR, {
                //     text: 'Incorrect Username/Password',
                //     color: 'error',
                //     open: true
                // });
            }
            const {account, hasMultipleOrganizations, availableOrganizations,selectedOrganization, mfaIsRequired} = response;
            account.availableOrganizations = availableOrganizations;
            account.selectedOrganization = selectedOrganization
            this.setAccount(account);

            if (mfaIsRequired) {
                this.mfaVerificationDialog = true;
                this.loading = false;
                // await this.$router.push({name: 'MultiFactorAuthentication', query: { redirect: this.$route.query.redirect ? this.$route.query.redirect : '/'}})
            } else {
                await this.finishLogin({account, hasMultipleOrganizations})
                this.isLoading = false;
            }

            // axios.post("/api/account/login", {'email': this.email, 'password': this.password}).then((response) => {
            //     console.log(response);
            // })
        },
        async finishLogin({account, hasMultipleOrganizations, selectedOrganization}) {
            
            let redirectOrgId = this.$route.query.orgId;
            
            // if (redirectString){
            //     let decodedRedirectString = fullyDecodeURI(redirectString);
            //    
            // }
            
            var redirectString = this.$route.query.redirect;
            if (redirectString) {
                
                //if there are multiple redirect strings, we need to get the last one
                if (Array.isArray(redirectString)) {
                    redirectString = redirectString[redirectString.length - 1]
                }
                
                if (redirectString.toLowerCase().includes('login') || redirectString.toLowerCase().includes('reset-password')) {
                    redirectString = '/'
                }
            }

            let {profileAccessLink} = this.$route.query;
            
            if (profileAccessLink){
                this.setAccount(account);
                
                await this.$router.push('/redeem-profile-access-link?orgSelected=false&profileAccessLink=' + profileAccessLink)
            } else if (redirectOrgId){
                const {account} = await switchSelectedOrganization(redirectOrgId);

                this.setAccount(account);

                await this.$router.push(redirectString ? redirectString : '/')
                location.reload()
            } else if (hasMultipleOrganizations) {
                let temp = {name: 'SelectOrganization', query: { redirect: redirectString ? redirectString : '/', orgId: selectedOrganization ? selectedOrganization.id : null}}
                await this.$router.push(temp)
            } else if (account.accountTypeId === 4) {
                await this.$router.push('/account')
            } else {
                await this.$router.push(redirectString ? redirectString : '/')
            }
            this.loading = false;
        },
        loginWithSso() {
            auth.loginRedirect(loginRequest);
        },
        getTokenSso() {
            let _this = this;
            return auth.acquireTokenSilent(tokenConfig)
                    .catch(() => auth.acquireTokenRedirect(tokenConfig))
                    //.then(( dbg ) => {
                    //    const g = dbg;
                    //    debugger;
                    //    if (dbg.accessToken)
                    //    {
                    //        this.ssoToken = dbg.accessToken;
                    //        _this.ssoToken = dbg.accessToken;
                    //    }
                    //    console.log(this.loading);
                    //    console.log(_this.loading);
                    //    debugger;
                    //});
                    //.then(({ idToken }) => {
                    //        debugger;
                    //        this.ssoToken = idToken.rawIdToken;
                    //        console.log(this.ssoToken);
                    //    });
                    .then(( token ) => token);
        },
        async extraLoginOauth() {
            var response = await ssologin(this.ssoToken);
            if (response.isSuccess)
                await this.onLoginResponse(response);
        },
        async extraLoginSaml() {
            var response = await samllogin();
            try{
                await this.onLoginResponse(response);
            }
            catch(e){
                await this.backToPreLogin();
            }
        },
        setAccount(account){
            this.$emit('setAccount', account)
        },
        showPasswordLogin(){
            if (this.isMobileSso){
                this.sendNativeCommand('loginWithPassword');
                return;
            }
            this.isPasswordShowing = true;
            this.isPasswordOptionShowing = false;
        },
        loginWithSaml(){
            if (this.isSamlLinkPressed)
                return; // prevent duplicate clicks
            
            this.isSamlLinkPressed = true;
            window.location.href = this.samlUrl;
        },
        sendNativeCommand(command, payload){
            if (this.mobileAppVersion && semver.gte(this.mobileAppVersion, '5.6.0')){
                funcs.sendNativeCommandMaui(command, payload);
            }
            else {
                funcs.sendNativeCommand(command, payload);
            }
        }
    }
}
</script>

<style scoped>

.login {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    background-color: white;
    height: 100vh
}

.login-input {
    width: 300px
}

.login-form-container {
    display: flex; flex-direction: column; align-items: center; flex: 2
}

.hover {
    cursor: pointer
}

</style>