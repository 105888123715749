<template>
    <div>
        <PatientDashboard v-if="isPatient"></PatientDashboard>
        <Dashboard v-else-if="!isEnterpriseAdminOrg"></Dashboard>
        <EnterpriseDashboard v-else-if="isEnterpriseAdminOrg"></EnterpriseDashboard>
    </div>
</template>

<script>
import Dashboard from "@/views/Dashboard";
import EnterpriseDashboard from "@/views/EnterpriseDashboard";
import PatientDashboard from "@/views/PatientDashboard";
import {isPatient} from "@/services/userService";

export default {
    name: "DashboardSwitcher",
    components: {EnterpriseDashboard, Dashboard, PatientDashboard},
    data() {
        return {
        }
    },
    computed: {
        isPatient() {
            if (this.$store.state.currentAccount)
                return (this.$store.state.currentAccount.accountTypeId === 5);
            else 
                return false;
        },
        isEnterpriseAdminOrg() {
            return this.$store.state.currentAccount.isEnterpriseAdminOrg;
        }
    }
}
</script>

<style scoped>

</style>