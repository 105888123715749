import { render, staticRenderFns } from "./DashboardLayout.vue?vue&type=template&id=f6fd8bc2&scoped=true"
import script from "./DashboardLayout.vue?vue&type=script&lang=js"
export * from "./DashboardLayout.vue?vue&type=script&lang=js"
import style0 from "./DashboardLayout.vue?vue&type=style&index=0&id=f6fd8bc2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6fd8bc2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VDivider,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VMenu})
