<template>
    <div>
        <PageHeader :title="welcomeString" />
        <div class="mb-2">
<!--            Welcome back {{firstName}} - get after it-->
            <span v-if="isSuperView" style="padding-left:100px;">Org ID: {{account.selectedOrganization.id}}</span>
            <span v-if="isSuperView" style="padding-left:100px;"><a @click="goToSubscriptionPage">Go To Subscription Page</a></span>
        </div>
<!--        <div>-->
<!--            <sway-loader />-->
<!--        </div>-->
        <div style="width: 100%">
            <v-container fluid>
                <v-row v-if="account.selectedOrganizationAccessTypeId === 2 && organization != null && organization.canEnableProfileSharing && organizationPreferences != null && !organizationPreferences.enablePatientAccountCreation">
                    <v-col>

                            <div style="display: flex; align-items: center">
                                <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>
                                <span style="color: rgba(255,0,0,.5); font-weight: 800; margin-left: 5px">IMPORTANT NOTE: </span>
                            </div>

                            <div style="margin-bottom: 10px">
                                <div>Your organization does not have Profile Sharing enabled. Please click here to Learn More and enable the new features for your organization.</div>
                            </div>
                            <div align="center" justify="space-around">
                                <template>
                                    <v-btn
                                            color="primary"
                                            @click="goToSettingsPage"
                                    >
                                        Learn More <v-icon size="14" >mdi-arrow-right</v-icon>
                                    </v-btn>
                                </template>
                            </div>
                        
                        
                    </v-col>
                </v-row>
                <v-row>
	                <v-col cols="12" md="6" >
		                <div class="mb-1" style="display: flex">
			                <h4 style="flex: 1">Open Events</h4> 
			                <div class="see-all-events" style="display: flex" v-if="openEvents.length > 0"> 
				                <div @click="$router.push({path: '/profiles', query: { openEventsOnly: 'true' }})" class="mr-1">See all Open Events </div> 
				                <v-icon size="14" >mdi-arrow-right</v-icon></div>
		                </div>
		                
<!--		                <app-chip class="mb-1" color="warning" label>Open Events</app-chip>-->
		                <app-card hide-header>
			                <!--                    <app-data-table-->
			                <!--                        :headers="mostRecentProfileTestedTableHeaders"-->
			                <!--                        :items="mostRecentProfileTested"-->
			                <!--                        @onRowClick="selectProfileTest($event)"-->
			                <!--                    />-->
			                <app-simple-table
				                :headers="openEventsTableHeaders"
				                :items="openEvents"
				                @onRowClick="selectProfileTest($event)"
				                :isOpenEventsTable="true"
				                :loading="loadingOpenEvents"
			                >
				                <!--                                <template #item.lastName="{item}">-->
				                <!--                                    <span style="color:red">{{item.lastName}}</span>-->
				
				                <!--                                </template>-->
				                <template v-slot:no-data>
					                <div style="display: flex; justify-content: center; padding: 20px">
						                No open events
					
					                </div>
				                </template>
				                <template #item.lastTestDate="{item}">
					                <div style="display: flex; align-items: center">
						                <div>{{ item.lastTestDate }}</div>
<!--						                <AppChip small color="event" class="ml-2" label>39 days</AppChip>-->
					                </div>
					                
				                </template>
			
			                </app-simple-table>
<!--			                <div v-if="loadingOpenEvents">-->
<!--				                <div style="display: flex; justify-content: center; padding: 20px">-->
<!--					                <SwayLoader  />-->
<!--				-->
<!--				                </div>-->
<!--			                </div>-->
<!--			                <div v-else-if="openEvents.length < 1">-->
<!--				                <div style="display: flex; justify-content: center; padding: 20px">-->
<!--					                No open events-->
<!--				-->
<!--				                </div>-->
<!--			                </div>-->
		                </app-card>
	                </v-col>
                    <v-col cols="12" md="6" >
                        <h4 class="mb-1">Recent Activity</h4>
                        <app-card hide-header>
                            <!--                    <app-data-table-->
                            <!--                        :headers="mostRecentProfileTestedTableHeaders"-->
                            <!--                        :items="mostRecentProfileTested"-->
                            <!--                        @onRowClick="selectProfileTest($event)"-->
                            <!--                    />-->
                            <app-simple-table
                                :headers="mostRecentProfileTestedTableHeaders"
                                :items="mostRecentProfileTested"
                                @onRowClick="selectProfileTest($event)"
                                :loading="loadingRecentTests"
                                
                            >
<!--                                <template #item.lastName="{item}">-->
<!--                                    <span style="color:red">{{item.lastName}}</span>-->

<!--                                </template>-->
	
	                            <template v-slot:no-data>
		                            <div style="display: flex; justify-content: center; padding: 20px">
			                            No recent activity
		
		                            </div>
	                            </template>
                            </app-simple-table>
<!--                            <div v-if="loadingRecentTests">-->
<!--                                <div style="display: flex; justify-content: center; padding: 20px">-->
<!--                                    <SwayLoader  />-->
<!--                                   -->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div v-else-if="mostRecentProfileTested.length < 1">-->
<!--                                <div style="display: flex; justify-content: center; padding: 20px">-->
<!--                                    No recent activity-->

<!--                                </div>-->
<!--                            </div>-->
                        </app-card>
                    </v-col>
                </v-row>
	            <v-row>
                    <v-col cols="12" md="12" >
                        <h4 class="mb-1">Active Codes</h4>
                        <app-card hide-header>
                            <app-simple-table
                                :headers="sessionsTableHeaders"
                                :items="mostRecentSessions"
                                @onRowClick="selectSession($event)"
                                :loading="loadingRecentSessions"
                            >
<!--                                <template v-slot:body>-->
<!--                                    <tbody>-->
<!--                                    <tr v-for="item in mostRecentSessions" @click="selectSession(item)">-->
<!--                                        <td>-->
<!--                                            <app-chip-->
<!--                                                :color="item.isProfileSession ? 'blue' : 'green'"-->
<!--                                                dark-->
<!--                                            >-->
<!--                                                {{ item.code }}-->
<!--                                            </app-chip>-->

<!--                                        </td>-->
<!--                                        <td>{{item.displayName}}</td>-->
<!--                                        <td>{{ item.protocolName }}</td>-->
<!--                                        <td>{{ item.endsOnFormatted }}</td>-->
<!--                                    </tr>-->
<!--                                    </tbody>-->

<!--                                </template>-->
	                            <template v-slot:no-data>
		                            <div style="display: flex; justify-content: center; padding: 20px">
			                            No active codes
		
		                            </div>
	                            </template>
                            </app-simple-table>
<!--                            <div v-if="loadingRecentSessions">-->
<!--                                <div style="display: flex; justify-content: center; padding: 20px">-->
<!--                                    <SwayLoader  />-->

<!--                                </div>-->
<!--                            </div>-->
<!--                            <div v-else-if="mostRecentSessions.length < 1">-->
<!--                                <div style="display: flex; justify-content: center; padding: 20px">-->
<!--                                    No active sessions-->

<!--                                </div>-->
<!--                            </div>-->
                        </app-card>
                    </v-col>


                </v-row>

<!--                <v-row>-->
<!--                                    <v-col cols="12">-->
<!--                                        <h4 class="mt-4 mb-1 pl-2">Organization Statistics</h4>-->
<!--                                    </v-col>-->

<!--                    <v-col cols="4" v-for="(item, index) in organizationStats" :key="index">-->

<!--                                            <app-stat-card-->
<!--                                                :title="item.title"-->
<!--                                                :value="item.value"-->
<!--                                            ></app-stat-card>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <v-row>-->

<!--                    <v-col md="12" class="mt-4 pa-2">-->
<!--                        <test-data-summary-chart -->
<!--                            :tests-data="historicalTestsData" -->
<!--                            :loading="chartsLoading"-->
<!--                        ></test-data-summary-chart>-->
<!--                    </v-col>-->
<!--                </v-row>-->
            </v-container>
        </div>
        
        
        <!--    <router-view></router-view>-->
        <!--    <v-img src="@/assets/images/sway_gif_150x105.gif" height="150" width="300"/>-->
    </div>
</template>

<script>
import AppSimpleTable from "@/components/tables/AppSimpleTable";
import {getUser, isSuperView, isEnterpriseAdminOrg} from "@/services/userService";
import {
    getOrganizationTestDataSummary,
    getTotalGroupsCount,
    getTotalProfileCount,
    getDailyTestCounts,
    getTotalTestCount,
    getOrganization, getOrganizationPreferences
} from "@/services/organization.service";
import {getProfilesPaged} from '@/services/profile.service'
import {getSessions} from '@/services/session.service'
import {getAccounts} from "@/services/userService";
import moment from "moment";
import apiMixin from '@/mixins/api'
import AppStatCard from "@/components/AppStatCard";
import TestDataSummaryChart from "@/components/TestDataSummaryChart";
import SwayLoader from "@/components/SwayLoader";
import {getSessionDetails, asLocalTime} from "@/js/shared/helper";
import {timezone} from "@/js/shared/constants";

export default {
    name: "Dashboard",
    props: ['account'],
    mixins: [apiMixin],
    components: {
        SwayLoader,
        AppSimpleTable,
        AppStatCard,
        TestDataSummaryChart
    },
    
    data() {
        return {
            totalChartOptions: null,
            covidChartOptions: null,
            chartsLoading: false,
            historicalTestsData: null,
            chartDataToDisplay: 'totalTestCount',
            mostRecentProfileTested: [],
	        openEvents: [],
            organization: null,
            organizationPreferences: null,
	        
            loadingRecentTests: false,
	        loadingOpenEvents: false,
	        
            mostRecentSessions: [],
            loadingRecentSessions: false,
            profileCount: null,
            groupsCount: null,
            totalTestsCount: null,
            userCount: null,
            
            // mostRecentProfileTestedTableHeaders: ,
            // sessionsTableHeaders: ['Code', 'Start Date', 'End Date', 'Created On'],
            sessionsTableHeaders: [
                {
                    text: 'Code',
                    value: 'code'
                },
                {
                    text: 'Name',
                    value: 'displayName'
                },
                {
                    text: 'Protocol',
                    value: 'protocolName'
                },
                {
                    text: 'End Date',
                    value: 'endsOnFormatted'
                },

            ],
            chips: [
                {
                    label: 'All',
                    value: 'totalTestCount'
                },
                {
                    label: 'COVID',
                    value: 'covidTestCount'
                },
                {
                    label: 'Balance',
                    value: 'balanceTestCount'
                },
                {
                    label: 'Reaction Time',
                    value: 'reactionTimeTestCount'
                },
                {
                    label: 'Impulse Control',
                    value: 'impulseControlTestCount'
                },
                {
                    label: 'Inspection Time',
                    value: 'inspectionTimeTestCount'
                },
                {
                    label: 'Memory',
                    value: 'memoryTestCount'
                },
                {
                    label: 'Number Counting',
                    value: 'numberCountingTestCount'
                },
            ]
        }
    },
    computed: {
        mostRecentProfileTestedTableHeaders() {
            let {viewingAsSuper} = this.$store.state.currentAccount;
            if (viewingAsSuper) {
                return [
                    {
                        text: ' Test Date',
                        value: 'lastTestDate'
                    },
                    {
                        text: 'ID',
                        value: 'id'
                    },
                ]
            }
            return [
                {
                    text: ' Test Date',
                    value: 'lastTestDate'
                },
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'First Name',
                    value: 'firstName'
                },
                {
                    text: 'Last Name',
                    value: 'lastName'
                },
            ]
        },
	    openEventsTableHeaders() {
		    let {viewingAsSuper} = this.$store.state.currentAccount;
		    if (viewingAsSuper) {
			    return [
				    {
					    text: 'Last Test',
					    value: 'lastTestDate'
				    },
				    {
					    text: 'ID',
					    value: 'id'
				    },
			    ]
		    }
		    return [
			    {
				    text: 'Last Test',
				    value: 'lastTestDate'
			    },
			    {
				    text: 'ID',
				    value: 'id'
			    },
			    {
				    text: 'First Name',
				    value: 'firstName'
			    },
			    {
				    text: 'Last Name',
				    value: 'lastName'
			    },
		    ]
	    },
        welcomeString() {
            // return `Hey ${this.account ? this.account.firstName : null}`
            return 'Dashboard'
        },
        // firstName() {
        //     return this.$store.state.currentAccount ? this.$store.state.currentAccount.firstName : null
        // },
        organizationStats() {
            const {profileCount, groupsCount, totalTestsCount, userCount} = this;
            return [
                {
                    title: 'Total Tests Taken (all time)',
                    value: totalTestsCount ? totalTestsCount : '-',
                    loading: false
                },
                {
                    title: 'Number of Profiles (all time)',
                    value: profileCount ? profileCount : '-',
                    loading: false
                },
                {
                    title: 'Number of Groups',
                    value: groupsCount ? groupsCount : '-',
                    loading: false
                },
                // {
                //     title: 'Number of Users',
                //     value: userCount ? userCount : '-',
                //     loading: false
                // },
            ]
        },
        isSuperView() {
            return isSuperView();
        }
    },
    mounted() {
        let account = this.$store.state.currentAccount;
        this.account = account ? account : 'null';

        this.setupCharts();
        this.setupProfilesTable();
		this.setupOpenEventsTable();
        this.setupSessionsTable();
        this.getTotalProfileCount()
        this.getTotalGroupsCount()
        this.getTotalTestCount()
        this.getOrganization()
        this.getOrganizationPreferences()
        // this.getTotalUsersCount()

    },

    methods: {
        async getTotalProfileCount() {
            let response = await getTotalProfileCount();
            if (!response.isSuccess) return
            this.profileCount = response.count
        },
        async getOrganization() {
            let response = await getOrganization();
            if (!response.isSuccess) return
            this.organization = response.organization
        },
        async getOrganizationPreferences() {
            let response = await getOrganizationPreferences();
            if (!response.isSuccess) return
            this.organizationPreferences = response.organizationPreferences
        },
        async getTotalUsersCount() {
            let response = await getAccounts();
            if (!response.isSuccess) return
            this.userCount = response.accounts.length;
        },
        async getTotalGroupsCount() {
            let response = await getTotalGroupsCount();
            if (!response.isSuccess) return
            this.groupsCount = response.count
        },
        async getTotalTestCount() {
            let response = await getTotalTestCount();
            if (!response.isSuccess) return
            this.totalTestsCount = response.testCount
        },
        selectProfileTest(item) {
            // TODO: can we add the most recent testId to the general profile info?
            this.$router.push(`/profiles/${item.id}/overview`)
        },
        selectSession(item) {
            // TODO: can we add the most recent testId to the general profile info?
            this.$router.push(`/codes/${item.id}`)
        },
        selectChartDataType(value) {
            this.chartDataToDisplay = value;
            this.setupCharts()
        },
        async setupCharts() {
            return 
            this.chartsLoading = true;
            if (!this.historicalTestsData) {
                
                const response = await getOrganizationTestDataSummary(7);
                
                if (!response.isSuccess) {
                    return 
                }
                const { organizationTestDataSummary } = response;
                this.historicalTestsData = organizationTestDataSummary;
            }

            let totalTestsCategories = [];
            let totalTestsData = [];

            this.historicalTestsData.forEach(day => {
                totalTestsCategories.push(moment(day.date).format('M/D/YY'))
                totalTestsData.push(day[this.chartDataToDisplay]);
            })
            
            this.chartsLoading = false
        },
        async setupProfilesTable() {
            this.loadingRecentTests = true
            const response = await getProfilesPaged({
                PageNumber: 1,
                RowsPerPage: 5,
                SearchString: "",
                SortAscending: false,
                SortByColumn: "lastTestDate"
            });
            
            if (!response.isSuccess) {
                return this.loadingRecentTests = false
            }
            
            const {profiles: mostRecentProfileTested} = response

            mostRecentProfileTested.forEach((profile) => {
                profile.lastTestDate = asLocalTime(moment.utc(profile.lastTestDate)).format('MMMM D, YYYY')
            })
            this.mostRecentProfileTested = mostRecentProfileTested;
            // this.mostRecentProfileTested = [];
            this.loadingRecentTests = false
        },
	    async setupOpenEventsTable() {
		    this.loadingOpenEvents = true
		    const response = await getProfilesPaged({
			    PageNumber: 1,
			    RowsPerPage: 50,
			    SearchString: "",
			    SortAscending: false, 
			    SortByColumn: "lastTestDate",
			    openEventsOnly: true
		    });
		
		    if (!response.isSuccess) {
			    return this.loadingOpenEvents = false
		    }
		
		    const {profiles: openEvents} = response
		
		    openEvents.sort((a,b) => moment(a.lastTestDate).isBefore(moment(b.lastTestDate)) ? 1 : -1)
		    
		    openEvents.forEach((profile) => {
			    profile.lastTestDate = asLocalTime(moment.utc(profile.lastTestDate)).format('MMMM D, YYYY')
		    })
		    
		    
		    
		    this.openEvents = openEvents.slice(0, 5);
		    // this.mostRecentProfileTested = [];
		    this.loadingOpenEvents = false
	    },
        async setupSessionsTable() {    
            this.loadingRecentSessions = true   
            const data = await getSessions();
            let responseSessions = data.sessions;
            let formattedSessions = responseSessions.map(session => {
                session = getSessionDetails(session, isSuperView)
                return session
            })
            
            const filteredSessions = formattedSessions.filter(session => session.isActive)
            let slicedSessions = filteredSessions.slice(0,5);
			
			
            
            this.mostRecentSessions = slicedSessions;
            // this.mostRecentSessions = [];
	        
            this.loadingRecentSessions = false
        },
        goToSubscriptionPage() {
            this.$router.push(`/org-management/subscription`)
        },
        goToSettingsPage() {
            this.$router.push(`/organization/settings`)
        },
    },

}
</script>

<style scoped>
.custom-card {
    /*height: 200px*/
}

.see-all-events:hover {
	cursor: pointer
}

h4 {
    color: var(--v-dark-base)
}

.table {
    color: var(--v-text-base)!important
}
</style>