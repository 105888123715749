<template>
    <div>
        <div v-show="isLoaded">
            <div id="container3d"></div>
        </div>
        <div v-show="!isLoaded">
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; padding: 50px">
                <SwayLoader />
                <div style="margin-top: 10px">
                    Loading Data...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Highcharts from "highcharts";


export default {
    name: "InternalGraphPopup3d",
    props: {
        chartData: {
            required: true
        }
    },
    data() {
        return {
            isLoaded: false
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {

            if (this.chartData){
                var chart = new Highcharts.Chart(this.chartData);

                // Add mouse and touch events for rotation
                (function (H) {
                    function dragStart(eStart) {
                        eStart = chart.pointer.normalize(eStart);

                        var posX = eStart.chartX,
                                posY = eStart.chartY,
                                alpha = chart.options.chart.options3d.alpha,
                                beta = chart.options.chart.options3d.beta,
                                sensitivity = 5,  // lower is more sensitive
                                handlers = [];

                        function drag(e) {
                            // Get e.chartX and e.chartY
                            e = chart.pointer.normalize(e);

                            chart.update({
                                chart: {
                                    options3d: {
                                        alpha: alpha + (e.chartY - posY) / sensitivity,
                                        beta: beta + (posX - e.chartX) / sensitivity
                                    }
                                }
                            }, undefined, undefined, false);
                        }

                        function unbindAll() {
                            handlers.forEach(function (unbind) {
                                if (unbind) {
                                    unbind();
                                }
                            });
                            handlers.length = 0;
                        }

                        handlers.push(H.addEvent(document, 'mousemove', drag));
                        handlers.push(H.addEvent(document, 'touchmove', drag));


                        handlers.push(H.addEvent(document, 'mouseup', unbindAll));
                        handlers.push(H.addEvent(document, 'touchend', unbindAll));
                    }
                    H.addEvent(chart.container, 'mousedown', dragStart);
                    H.addEvent(chart.container, 'touchstart', dragStart);
                }(Highcharts));
                
            }
            
            this.isLoaded = true;
        }
    }
}
</script>

<style scoped>

</style>