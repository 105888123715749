<template>
    <div style="">
        <div v-if="organizationPreferences.enableSportsProfileProperties">
            <div style="display: flex; flex-direction: column;">
                <div class="profile-create-form-section-title">Profile Information</div>
                <div v-show="showIdentifyingInfo" class="profile-input-field">
                    <app-text-field
                        v-model="form.firstName"
                        label="First Name*"
                        error-label="First Name"
                        rules="required|max:50"
                        row

                    />
                </div>
                <div v-show="showIdentifyingInfo" class="profile-input-field">
                    <app-text-field
                        v-model="form.lastName"
                        label="Last Name*"
                        error-label="Last Name"
                        rules="required|max:50"
                        row
                    />
                </div>
                <div class="profile-create-form-section-title">Basic Information</div>
                <div v-show="showIdentifyingInfo" class="profile-input-field">
                    <app-form-date-picker row v-model="form.birthDate" label="Birth Date*" mask="##/##/####" placeholder="MM/DD/YYYY" :max="today" start-with-year />
                </div>
                <div class="profile-input-field">
                    <app-select row v-model="form.sex" label="Sex*" :items="sexOptions" />
                </div>
                <div class="profile-input-field">
                    <app-select row v-model="form.gender" label="Gender*" :items="genderOptions" />
                </div>
                <div class="profile-input-field">
                    <app-select row v-model="form.race" label="Race*" :items="raceOptions" />
                </div>
                <div class="profile-input-field" v-if="is5510Released()">
                    <app-select row v-model="form.ethnicity" label="Ethnicity*" :items="ethnicityOptions" />
                </div>
                <div v-if="organizationPreferences.isMetric" >
                    <div class="profile-input-field">
                        <app-text-field row v-model="form.heightInCentimeters" label="Height*" rules="required|centimeters" suffix="cm" />
                    </div>
                </div>
                <div v-else>
                    <div class="profile-input-field">
                        <app-text-field row v-model="form.heightInFeet" label="Height (feet)" type="number" rules="required|feet" suffix="ft" />
                    </div>
                    <div class="profile-input-field">
                        <app-text-field row v-model="form.heightInInches" label="Height (inches)" type="number" rules="required|inches" suffix="inches" />
                    </div>
                </div>

                <div class="profile-input-field">
                    <app-text-field row v-model="form.weight" label="Weight*" type="number" rules="required|positive" :suffix="organizationPreferences.isMetric ? 'kg' : 'lbs'" />
                </div>


                <div class="profile-create-form-section-title">Education Information</div>
                <div class="profile-input-field">
                    <app-select row v-model="form.graduationYear" label="Graduation Year" :items="graduationYearOptions"  />
                </div>
                <div class="profile-input-field">
                    <app-select row v-model="form.homeOtherThanEnglish" label="Does this person speak a language other than English at home?" :items="yesNoOptions"  />
                </div>
                <div class="profile-input-field" v-if="form.homeOtherThanEnglish">
                    <app-select  row v-model="form.primaryLanguage" label="What language does this person speak at home?" :items="languageOptions" />
                </div>
                <div class="profile-input-field">
                    <app-select row v-model="form.speakSecondaryLanguage" label="Does this person speak a secondary language?" :items="yesNoOptions"  />
                </div>
                <div class="profile-input-field" v-if="form.speakSecondaryLanguage">
                    <app-select row v-model="form.secondaryLanguage" label="What is this person's secondary language?" :items="languageOptions" />
                </div>
                <div class="profile-create-form-section-title">Medical Information</div>
                <div class="profile-input-field">
                    <app-select row v-model="form.hasHadConcussion" label="Has this person ever had a concussion?" :items="yesNoIdkOptions" />
                </div>
                <div class="profile-input-field" v-if="form.hasHadConcussion == 1">
                    <app-form-date-picker row v-model="form.lastConcussionDate" label="What is the approximate date of this person's last concussion?" mask="##/##/####" placeholder="MM/DD/YYYY" :max="today" start-with-year />
                </div>
                <div class="profile-input-field">
                    <app-select row v-model="form.hasAttentionDisorder" label="Has this person ever been diagnosed with Attention Deficit Disorder (ADD) or Attention Deficit Hyperactivity Disorder (ADHD)?" :items="yesNoIdkOptions"  />
                </div>
                <div v-if="includeGroups" class="profile-input-field">
                    <div class="profile-create-form-section-title">Select Groups</div>
                    <app-form-multiple-select
                        v-if="groups"
                        v-model="form.groupIds"
                        :items="groups"
                        rules="required"
                        item-text="name"
                        item-value="id"
                        label="Groups*"
                        sublabel="(minimum of 1)"
                        name="groupIds"
                    ></app-form-multiple-select>
                </div>

                <div class="profile-create-form-section-title">Default Protocol*</div>
                <div class="profile-input-field">
                    <app-select row v-model="form.defaultOrganizationProtocolId" label="Default Protocol" :items="protocols" item-value="organizationProtocolId" item-text="name" rules="required"  />
                </div>
            </div>
        </div>
        <div v-else>
            <div style="display: flex; flex-direction: column;" >
                <div class="profile-create-form-section-title">Profile Information</div>
                <div class="profile-input-field" v-show="showIdentifyingInfo">
                    <app-text-field
                        v-model="form.firstName"
                        label="First Name*"
                        error-label="First Name"
                        rules="required|max:50"
                        row
                        
                    />
                </div>
                <div class="profile-input-field" v-show="showIdentifyingInfo">
                    <app-text-field
                        v-model="form.lastName"
                        label="Last Name*"
                        error-label="Last Name"
                        rules="required|max:50"
                        row
                    />
                </div>
                <div class="profile-input-field" v-show="showIdentifyingInfo">
                    <app-form-date-picker row v-model="form.birthDate" label="Birth Date*" mask="##/##/####" placeholder="MM/DD/YYYY" :max="today" start-with-year />
                </div>
                <div class="profile-input-field">
                    <app-select row v-model="form.sex" label="Sex*" :items="sexOptions" />
                </div>
                <div v-if="organizationPreferences.isMetric" >
                    <div class="profile-input-field">
                        <app-text-field row v-model="form.heightInCentimeters" label="Height*" rules="required|centimeters" suffix="cm" />
                    </div>
                </div>
                <div v-else>
                    <div class="profile-input-field">
                        <app-text-field row v-model="form.heightInFeet" label="Height (feet)" type="number" rules="required|feet" suffix="ft" />
                    </div>
                    <div class="profile-input-field">
                        <app-text-field row v-model="form.heightInInches" label="Height (inches)" type="number" rules="required|inches" suffix="inches" />
                    </div>
                </div>

                <div class="profile-input-field">
                    <app-text-field row v-model="form.weight" label="Weight*" type="number" rules="required|positive" :suffix="organizationPreferences.isMetric ? 'kg' : 'lbs'" />
                </div>


               
                <div v-if="includeGroups" class="profile-input-field">
                    <div class="profile-create-form-section-title">Select Groups</div>
                    <app-form-multiple-select
                        v-if="groups"
                        v-model="form.groupIds"
                        :items="groups"
                        rules="required"
                        item-text="name"
                        item-value="id"
                        label="Groups*"
                        sublabel="(minimum of 1)"
                        name="groupIds"
                    ></app-form-multiple-select>
                </div>

                <div class="profile-create-form-section-title">Default Protocol*</div>
                <div class="profile-input-field">
                    <app-select row v-model="form.defaultOrganizationProtocolId" label="Default Protocol" :items="protocols" item-value="organizationProtocolId" item-text="name" rules="required"  />
                </div>

            </div>
        </div>
        <div class="profile-create-form-section-title">Account Information</div>
        <div v-if="organizationPreferences.enablePatientAccountCreation">
            <div v-if="(form.accountProfiles && form.accountProfiles.length > 0) || (profileAccessLinks && profileAccessLinks.length > 0)">
                <div class="section-content" style="padding-bottom: 10px">
                    <span>With access to their personal profile, they will have these convenient features:</span>
                    <ul>
                        <li>Simplified re-baselining without re-entering profile data.</li>
                        <li>Conveniently update personal information (height, weight, etc.).</li>
                        <li>Comfortably view and complete remotely assigned tests with ease.</li>
                    </ul>
                </div>
                <div class="pa-4" >
                    <!--                            <div style="display: flex; align-items: center" class="mb-2">-->
                    <!--                                <h4 class="mr-2">Groups</h4>-->
                    <!--                                -->
                    <!--                            </div>-->
                    <div>
                        <!--                                    <div style="display: flex; align-items: center">-->
                        <!--                                        <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>-->
                        <!--                                        <span style="color: rgba(255,0,0,.5); font-weight: 800; margin-left: 5px">IMPORTANT NOTE: </span>-->
                        <!--                                    </div>-->
                        <!--                                    <div style="margin-bottom: 10px">-->
                        <!--                                        <strong>User: </strong>Users are required to be granted access to individual groups within the selected organization. Users do not have access to configure settings or add/update other users on the organization.-->
                        <!--                                    </div>-->
                        <!--                                    <div style="margin-bottom: 10px">-->
                        <!--                                        <strong>Admin: </strong>Admins have access to all groups and profiles within the selected organization. Admins can configure settings and add/update other users on the organization.-->
                        <!--                                    </div>-->
                        <div v-for="accountProfile in form.accountProfiles"
                             :key="accountProfile.id"
                             style="border: 1px solid lightgrey; border-radius: 5px; margin: 5px 0; display: flex; align-items: center"
                             class="pa-2">
                            <div style="flex:1">
                                {{accountProfile.email}}
                            </div>
                            <v-btn-toggle
                                v-if="accountProfile.id != 0"
                                v-model="accountProfile.relationshipTypeId"
                                mandatory
                                :color="accountProfile.relationshipTypeId == 3 ? 'green' : 'blue'"
                            >
                                <v-btn :value="3" style="height: 30px">
                                    Parent
                                </v-btn>
                                <v-btn :value="5" style="height: 30px">
                                    Self
                                </v-btn>
                            </v-btn-toggle>
                            <app-chip v-else color="blue" label small>
                                Invite Sent on SAVE
                            </app-chip>
                        </div>
                        <div v-for="profileAccessLink in profileAccessLinks"
                             style="border: 1px solid lightgrey; border-radius: 5px; margin: 5px 0; display: flex; align-items: center"
                             class="pa-2">
                            <div style="flex:1">
                                {{profileAccessLink.sentToEmail}}
                            </div>
                            <app-chip v-if="isProfileAccessLinkExpired(profileAccessLink)" color="red" label small>
                                Expired: {{displayTestDate(profileAccessLink.expiresOn)}}
                            </app-chip>
                            <app-chip v-else color="green" label small>
                                Expires on: {{displayTestDate(profileAccessLink.expiresOn)}}
                            </app-chip>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="section-content" style="padding-bottom: 10px">
                    No email addresses are associated with this Sway Profile. Share this profile with another Sway Administrator, Parent/Guardian, or a Personal email address.
                </div>
            </div>

            <div class="profile-input-field">
                <v-text-field
                    v-model="emailToAdd"
                    clearable
                    label="Email"
                    type="text"
                    variant="outlined"
                >
                    <template v-slot:append>
                        <v-menu>
                            <template v-slot:activator="{ props }">
                                <v-btn @click="addEmailToAccountProfiles" class="mt-n2">
                                    <v-icon icon="mdi-menu" start></v-icon>
                                    Add Email
                                </v-btn>
                            </template>
                        </v-menu>
                    </template>
                </v-text-field>
            </div>

            <div v-if="organizationPreferences.externalApiEnabled">
                <app-text-field class="profile-input-field" row v-model="form.externalId" label="Api External ID" />
            </div>
        </div>
        <div v-else>
            <div>
                <app-text-field class="profile-input-field" row v-model="form.email" label="Email" rules="email" />
            </div>
            <div v-if="organizationPreferences.externalApiEnabled">
                <app-text-field class="profile-input-field" row v-model="form.externalId" label="Api External ID" />
            </div>
        </div>



<!--        <v-divider class="mt-4 mb-2"></v-divider>-->

<!--        -->
<!--        <div v-if="includeGroups">-->
<!--            <v-divider class="mt-4 mb-4"></v-divider>-->

<!--            -->
<!--        </div>-->
        
    </div>
</template>

<script>
import moment from "moment";
import semver from "semver";
import {profileFormOptions} from "@/js/shared/constants";

export default {
    name: "ProfileCreateUpdateForm",
    props: {
        form: {
            required: true
        },
        organizationPreferences: {
            required: true
        },
        includeGroups: {
            default: false
        },
        groups: {
            default: null
        },
        protocols: {
            default: null
        },
        creatingProfile: {
            type: Boolean
        },
        profileAccessLinks: {
            default: null
        }
    },
    data() {
        
        const {
            graduationYearOptions,
            sexOptions,
            raceOptions,
            ethnicityOptions,
            languageOptions,
            inchesOptions,
            genderOptions,
            feetOptions,
            yesNoIdkOptions,
            yesNoOptions
        } = profileFormOptions
        
        let is5510Released = this.is5510Released();
        let updatedRaceOptions = [];
        for (let i = 0; i < raceOptions.length; i++){
            if (is5510Released && raceOptions[i].value === 6)
                continue;
            
            updatedRaceOptions.push({
                text: raceOptions[i].text,
                value: raceOptions[i].value
            });
        }
        
        return {
            graduationYearOptions,
            sexOptions,
            raceOptions: updatedRaceOptions,
            ethnicityOptions,
            languageOptions,
            inchesOptions,
            genderOptions,
            feetOptions,
            yesNoIdkOptions,
            yesNoOptions,
            
            emailToAdd: null,
        }
    },
    mounted() {
        
    },
    computed: {
        today() {
            return moment().format('YYYY-MM-DD')
        },
        graduationYearOptions() {
            let response = []
            let currentYear = moment().year();
            for (let i = 0; i < 21; i++) {
                response.push({
                    label: currentYear + i,
                    value: currentYear + i
                })
            }
            
            return response
        },
        isViewingAsSuper(){
            let {viewingAsSuper} = this.$store.state.currentAccount;
            return viewingAsSuper;
        },
        showIdentifyingInfo(){
            return !this.isViewingAsSuper || this.creatingProfile
        }
    },
    methods: {
        async addEmailToAccountProfiles() {
            if (!this.emailToAdd) {
                return
            }
            
            let email = this.emailToAdd;
            this.emailToAdd = null;
            
            let accountProfile = {
                email,
                relationshipTypeId: 0,
                id: 0,
                accountId: 0,
            }
            
            this.form.accountProfiles.push(accountProfile)
        },
        isProfileAccessLinkExpired(profileAccessLink) {
            let {expiresOn} = profileAccessLink;
            let now = moment();
            let expiration = moment(expiresOn);
            
            return now.isAfter(expiration)
        },
        displayTestDate(date) {
            return moment(date).format('MMM D, YYYY')
        },
        is5510Released(){
            return this.$store.state.latestMobileAppVersion && semver.gte(this.$store.state.latestMobileAppVersion, '5.5.10')
        }
    }
}
</script>

<style scoped>
.profile-create-form-section-title {
    font-weight: 600;
    font-size: 18px;
    margin: 20px 0;
    /*border-bottom: 1px solid var(--v-border-base)*/
  }

.profile-input-field {
    margin: 5px 0
}
</style>