<template>
    <div>
        <div
            v-if="data.positions"
            style="position: absolute; right: 0px; width: 10px; border-radius: 10px"
            :style="{
                                        top: `${data.positions.baselineTop + data.yAxisOffset}px`,
                                        height: `${Math.abs(data.positions.baselineBottom - data.positions.baselineTop)}px`,
                                        backgroundColor: data.ciColor,
                                        'border-bottom-left-radius': getBorderRadius(data.cutOffs.baselineBottom),
                                        'border-bottom-right-radius': getBorderRadius(data.cutOffs.baselineBottom),
                                        'border-top-left-radius': getBorderRadius(data.cutOffs.baselineTop),
                                        'border-top-right-radius': getBorderRadius(data.cutOffs.baselineTop),
                                    }"
        >

        </div>
        <div v-if="data.positions && data.positions.normWideTop">
            <div
                v-if="data.positions"
                style="position: absolute; right: -7px; width: 10px; background-color: #7b7b7b; border-radius: 10px"
                :style="{
                                        top: `${data.positions.normWideTop + data.yAxisOffset}px`,
                                        height: `${Math.abs(data.positions.normWideBottom - data.positions.normWideTop)}px`,
                                        'border-bottom-left-radius': getBorderRadius(data.cutOffs.normWideBottom),
                                        'border-bottom-right-radius': getBorderRadius(data.cutOffs.normWideBottom),
                                        'border-top-left-radius': getBorderRadius(data.cutOffs.normWideTop),
                                        'border-top-right-radius': getBorderRadius(data.cutOffs.normWideTop),
                                    }"
            >

            </div>
            <div
                v-if="data.positions"
                style="position: absolute; right: -7px; width: 10px; background-color: #989898; border-radius: 10px;"
                :style="{
                                        top: `${data.positions.normThinTop + data.yAxisOffset}px`,
                                        height: `${Math.abs(data.positions.normThinBottom - data.positions.normThinTop)}px`,
                                        'border-bottom-left-radius': getBorderRadius(data.cutOffs.normThinBottom),
                                        'border-bottom-right-radius': getBorderRadius(data.cutOffs.normThinBottom),
                                        'border-top-left-radius': getBorderRadius(data.cutOffs.normThinTop),
                                        'border-top-right-radius': getBorderRadius(data.cutOffs.normThinTop),
                                    }"
            >

            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "ProfileTrendsBaselineNorms",
    props: ['data'],
    methods: {
        getBorderRadius(isCutOff) {
            return isCutOff ? '0px' : '10px'
        },
    }
}
</script>

<style scoped>

</style>