<template>
	<div>
		<app-tabs :value="tab">
			<app-tab v-for="tab in tabs" @click="goToRoute(tab.route)" :icon="tab.icon">{{ tab.label }}</app-tab>
		</app-tabs>
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: "Reports",
	methods: {
		goToRoute(route) {
			this.$router.push(route)
		}
	},
	computed: {
		tab() {
			let value = 0;
			let path = this.$route.path.split('/');
			let last = path[2];
			
			switch (last) {
				case 'profiles-tested-report':
					value = 0;
					break;
				case 'billing-email-log':
					value = 1;
					break;
			}
			
			return value
		},
		tabs() {
			let base = '/org-management/reporting'
			let myTabs = [
				{
					label: 'Profiles Tested Report',
					route: `${base}/profiles-tested-report`,
					icon: 'mdi-credit-card-outline'
				},
				{
					label: 'Billing Email Log',
					route: `${base}/billing-email-log`,
					icon: 'mdi-cog-outline'
				},
			
			];
			
			return myTabs;
		}
	}
}
</script>

<style scoped>

</style>