<template>
    <div>
        <page-header title="Bulk Move Profiles"/>
        <app-card hide-header>

            <div class="pa-4" >
                <div style="display: flex; align-items: center" class="pb-4">
                    <div style="flex: 1; display: flex; align-items: center">
                        <div>
                            Move profiles from
                        </div>
                        <div class="pl-2 pr-2" style="transform: translateY(3px)">
                            <AppSelect
                                placeholder="Select Group"
                                :items="groups"
                                item-value="id"
                                item-text="name"
                                :outlined="false"
                                v-model="fromGroupId"
                            />
                        </div>
                        <div>
                            to
                        </div>
                        <div class="pl-2 pr-2" style="transform: translateY(3px)">
                            <AppSelect
                                placeholder="Select Group"
                                :items="groups"
                                item-value="id"
                                item-text="name"
                                :outlined="false"
                                v-model="toGroupId"
                            />
                        </div>
                    </div>
                    
                    
                    <AppBtn 
                        v-if="groupsSelected" 
                        :disabled="selectedProfiles.length === 0"
                        @click="confirmMoveDialog = true"
                    >Move Selected Profiles</AppBtn>
                </div>
                <div v-if="toGroupId && fromGroupId && toGroupId === fromGroupId" style="color: var(--v-error-base)">
                    Please select two different groups
                </div>
                <div style="display: flex" v-if="groupsSelected">
                    <div  style="flex: 1" class="pr-4">
                        <AppTextField v-model="tableSettings.searchString"
                                      placeholder="Filter Profiles by Name"
                                      @input="updateSearchString"
                                      label=""
                                      prepend-inner-icon="mdi-magnify"
                        />
                    </div>
                    <div>
                        <AppSelect
                            :items="graduationYearOptions"
                            v-model="tableSettings.graduationSearchString"
                            @input="updateOptions"
                            label=""
                            itemText="label"
                            itemValue="value"
                            placeholder="Graduation Year"
                        />
                    </div>
                </div>
            </div>
            
            <div v-show="groupsSelected">
                <v-data-table
                    :headers="headers"
                    :items="profiles"
                    :items-per-page="tableSettings.rowsPerPage"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :loading="loading"
                    :page="tableSettings.pageNumber"
                    :hide-default-footer="false"
                    class="profiles-table"
                    id="profiles-table"
                    @update:options="updateOptions"
                    :search="tableSettings.searchString"
                    show-select
                    v-model="selectedProfiles"

                >

                    <template #no-data>
                        <div style="display: flex; justify-content: center">There are no profiles that match your search</div>
                    </template>
                    

                </v-data-table>
            </div>



        </app-card>
        <app-confirm-dialog 
            :open="confirmMoveDialog"
            :title="confirmDialogTitle"
            submit-button-title="Move Profiles"
            @confirm="onMoveProfiles"
            @close="confirmMoveDialog = false"
            :loading="movingProfiles"
        >
            <div style="display: flex; align-items: center">
                <v-simple-checkbox v-model="removeFromOldGroup" color="secondary"/>
                <div class="pl-2">
                    Remove these profiles from the group "{{fromGroupName}}"?
                </div>
                
            </div>
        </app-confirm-dialog>
    </div>
    
</template>

<script>
import moment from 'moment'
import paginatedProfilesMixin from "@/mixins/paginatedProfilesMixin";
import {bulkMove, bulkMoveRequest, getGroups} from "@/services/groups.service";
import {SET_SNACKBAR} from "@/store/mutation-types";

export default {
    name: "BulkMove",
    props: {

    },
    components: {

    },
    mixins: [paginatedProfilesMixin],
    data () {
        return {
            confirmMoveDialog: false,
            removeFromOldGroup: false,
            movingProfiles: false,
            selectedProfiles:[],
            groups: [],
            loadingGroups: false,
            fromGroupId: this.$route.params.groupId ? parseInt(this.$route.params.groupId) : null,
            toGroupId: null,
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                    class: 'th-border'
                },
                { text: 'Full Name', value: 'fullName', sortable: false,},
                { text: 'Graduation Year', value: 'graduationYear', },
                { text: 'Last Test Date', value: 'lastTestDate', },
            ]
        }
    },
    mounted() {
        this.tableSettings.skipAverages = true;

        this.getGroups();
        
        if (this.fromGroupId){
            this.onFromGroupSet();
        }
    },
    watch: {
        fromGroupId() {
            this.onFromGroupSet();
        }
    },
    computed: {
        fromGroupName() {
            if (!this.fromGroupId || this.groups.length === 0) return null
            
              let group = this.groups.find(group => group.id === this.fromGroupId);
              return group.name
        },
        groupsSelected() {
            return this.fromGroupId && this.toGroupId && (this.fromGroupId !== this.toGroupId)
        },
        graduationYearOptions() {
            let response = ['']
            let year = moment().add(10, 'years').year();
            for (let i = 0; i < 15; i++) {
                response.push({
                    value: (year - i).toString(),
                    label: (year - i).toString()
                })
            }
            return response
        },
        groupIds() {
            if (this.fromGroupId) return [this.fromGroupId]
            else return []
        },
        confirmDialogTitle(){
            if (this.selectedProfiles.length == 1)
                return 'Confirm Bulk Move ('+ this.selectedProfiles.length + ' Profile)';
            else
                return 'Confirm Bulk Move ('+ this.selectedProfiles.length + ' Profiles)';
        }
    },
    
    methods: {
        async getGroups() {
            const response = await getGroups();
            if (!response.isSuccess) {
                return
            }
            this.groups = response.groups
            // this.groups = []
            this.loadingGroups = false;
        },
        async onFromGroupSet() {
            this.tableSettings.groupIds = [this.fromGroupId];
            await this.updateOptions();
        },
        async onMoveProfiles() {
            this.movingProfiles = true
            let request = {...bulkMoveRequest}
            request.profileIds = this.selectedProfiles.map(profile => profile.id);
            request.oldGroupId = this.fromGroupId;
            request.newGroupId = this.toGroupId;
            request.removeFromOldGroup = this.removeFromOldGroup;
            
            let response = await bulkMove(request);
            if (response.isSuccess) {
                this.movingProfiles = false
                this.$store.commit(SET_SNACKBAR, {
                    text: 'There was an error moving the profiles.',
                    color: 'error',
                    open: true
                });
            }
            
            this.$store.commit(SET_SNACKBAR, {
                text: 'Successfully moved profiles.',
                color: 'success',
                open: true
            });
            
            this.confirmMoveDialog = false
            this.movingProfiles = false
            this.selectedProfiles = [];
            await this.onFromGroupSet();
        }
    }



}
</script>

<style scoped>
.profiles-table {
    /*color: var(--v-text-base)*/
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input {
    /*height: 40px*/
    width: 50%;
    /*height: 100%*/
}

.table-row:hover {
    background-color: var(--v-accent-base)!important;
    cursor: pointer
}
.table-row {
    background-color: white;
}

.search-input-area {
    border-radius: 15px;
    /*background-color: grey;*/
    height: 0px

}

.no-data {
    color: var(--v-text-base);
    margin: 20px
}

</style>