<template>

    <div class="sway-form" v-if="$store.state.currentAccount.isSuperAdmin" style="padding-left:20px">
        <h1>CREATE NEW ORGANIZATION</h1>
        <br/>
        <br/>
        <app-form
                @submit="completeSetup"
                :saving="isCompletingSetup"
                style="width: 60%"
                submitButtonTitle="Create Organization"
        >
            <h3>Primary Admin User</h3>

            <div class="inline-block-field" id="first-name">
                <app-text-field v-model="model.firstName" label="First Name" rules="required" />
            </div>

            <div class="inline-block-field" id="last-name">
                <app-text-field v-model="model.lastName" label="Last Name" rules="required" />
            </div>

            <div class="inline-block-field" id="job-title">
                <app-text-field v-model="model.jobTitle" label="Professional Designation" rules="required" />
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.email" label="Email" type="email" rules="required|email" />
            </div>

            <div class="inline-block-field">
                <app-select
                        :items="professionalTypes"
                        label="Medical Credentials"
                        v-model="model.medicalCredentials"
                        rules="required"
                ></app-select>
            </div>

            <br />
            <br />
            
            <h3>Organization Information</h3>

            <div class="org-info">
                <div class="inline-block-field">
                    <app-text-field v-model="model.orgName" label="Name" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgPhone" label="Phone Number" :mask="'###-###-####'" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgAdd1" label="Street Address" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgAdd2" label="Apt, Suite, Bldg (optional)" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgCity" label="City" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgState" label="State/Province" rules="required" />
                </div>

                <div class="inline-block-field">
                    <app-text-field v-model="model.orgZip" label="Postal Code" rules="required" />
                </div>

                <div class="inline-block-field checkbox">
                    <app-form-checkbox
                            label="Internal Organization"
                            v-model="model.isInternalOrg"
                    ></app-form-checkbox>
                </div>
            </div>

            <br />
            <br />
            
            <h3>Billing Information</h3>

            <div class="inline-block-field checkbox">
                <app-form-checkbox
                        label="Same as Organization Info"
                        v-model="isSameAddress"
                ></app-form-checkbox>
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.billName" label="Billing Name" rules="required" />
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.billPhone" label="Billing Phone" :mask="'###-###-####'" rules="required" />
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.billEmail" label="Billing Email" type="email" rules="required|email" />
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.billAdd1" label="Street Address" rules="required" />
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.billAdd2" label="Apt, Suite, Bldg (optional)" />
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.billCity" label="City" rules="required" />
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.billState" label="State/Province" rules="required" />
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.billZip" label="Postal Code" rules="required" />
            </div>

            <div class="inline-block-field">
                <app-select
                        :items="countrySelectList"
                        label="Country"
                        v-model="model.billCountry"
                        item-text="text"
                        item-value="text"
                        rules="required"
                ></app-select>
            </div>

            <div class="inline-block-field">
                <app-select
                        :items="billMethodSelectList"
                        label="Billing Method"
                        v-model="model.billMethod"
                        item-text="label"
                        item-value="id"
                        rules="required"
                ></app-select>
            </div>

            <div class="inline-block-field checkbox">
                <app-form-checkbox
                        label="Trial Status"
                        v-model="model.isDemo"
                ></app-form-checkbox>
            </div>

            <div class="inline-block-field checkbox">
                <app-form-checkbox
                        label="Setup Organization As Tax Exempt"
                        v-model="model.isTaxExempt"
                ></app-form-checkbox>
            </div>

            <br />
            <br />

            <h3>Pricing Information</h3>


            <div class="inline-block-field">
                <app-select
                        :items="organizationTypeIds"
                        label="Sale Type"
                        v-model="saleType"
                        rules="required"
                        item-text="label"
                        item-value="id"
                ></app-select>
            </div>

            <div class="inline-block-field">
                <app-select
                        :items="businessTypeSelectList"
                        label="Business Type"
                        v-model="model.businessTypeId"
                        rules="required"
                        item-text="label"
                        item-value="id"
                ></app-select>
            </div>

            <div class="inline-block-field">
                <app-select
                        :items="packageTierSelectList"
                        label="Package Type"
                        v-model="model.packageTierId"
                        rules="required"
                        item-text="text"
                        item-value="value"
                ></app-select>
            </div>

            <div class="inline-block-field">
                <app-select
                        :items="billingCycleTypes"
                        label="Billing Cycle"
                        v-model="model.billingCycleTypeId"
                        rules="required"
                        item-text="text"
                        item-value="value"
                ></app-select>
            </div>
            
            <div class="inline-block-field">
                <app-select
                        :items="distributorSelectList"
                        label="Sold By"
                        v-model="model.orgDistributorId"
                        rules="required"
                ></app-select>
            </div>

            <div class="inline-block-field">
                <app-text-field v-model="model.billToOrgId" label="Organization Id To Bill (if organization is not free or paying for their own system)" type="number"/>
            </div>

            <br/>
            <br/>
        </app-form>
    </div>

</template>

<script>
import {billingCycleTypes, countryOptions, organizationBusinessTypeIds, clinicalPackageTiers, sportsPackageTiers, organizationTypeIds, billMethodIds, professionalTypes} from "@/js/shared/constants";
import {enrollOrganization} from "@/services/enrollment.service";
import {SET_SNACKBAR} from "@/store/mutation-types";
import {switchSelectedOrganization} from "@/services/userService";

export default {
    name: "NewOrgEnrollment",
    data() {
        return {
            model: {
                firstName: "",
                lastName: "",
                jobTitle: "",
                email: "",
                medicalCredentials: "",
                orgName: "",
                orgPhone: "",
                orgAdd1: "",
                orgAdd2: "",
                orgCity: "",
                orgState: "",
                orgZip: "",
                billName: "",
                billPhone: "",
                billEmail: "",
                billAdd1: "",
                billAdd2: "",
                billCity: "",
                billState: "",
                billZip: "",
                billCountry: "",
                billMethod: "",
                isDemo: false,
                isTaxExempt: false,
                billingCycleTypeId: 1,
                orgDistributorId: 48,
                saleType: 0,
                billToOrgId: null,
                isInternalOrg: false
            },
            
            saleType: 0,
            
            isCompletingSetup: false,
            
            isSameAddress: false,

            professionalTypes: professionalTypes,

            countrySelectList: countryOptions,

            billMethodSelectList: billMethodIds,

            businessTypeSelectList: organizationBusinessTypeIds,
            
            distributorSelectList: [
                { text: "Casey Paulk", value: 314},
                { text: "Doug Tauchen", value: 349},
                { text: "National Accounts", value: 48}
            ],

            organizationTypeIds: organizationTypeIds,
            
            billingCycleTypes: billingCycleTypes
        }
    },
    methods: {
        async completeSetup(){
            this.isCompletingSetup = true;
            
            if (!this.model.billToOrgId) // convert empty strings to null
                this.model.billToOrgId = null;

            var response = await enrollOrganization(this.model);
            
            this.isCompletingSetup = false;

            if (response && response.isSuccess && response.organization){
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Organization Successfully Created',
                    color: 'success',
                    open: true
                });

                await switchSelectedOrganization(response.organization.id);
                await this.$router.push(`/dashboard?orgId=${response.organization.id}`);
            }
            else{
                // do nothing and let upstream methods handle this
                //this.$store.commit(SET_SNACKBAR, {
                //    text: 'Error Updating Organization',
                //    color: 'error',
                //    open: true
                //});
            }
        }
    },
    computed: {
        packageTierSelectList() {
            if (this.saleType === 3)
                return clinicalPackageTiers;
            else if (this.saleType === 2)
                return sportsPackageTiers;
            else
                return sportsPackageTiers.concat(clinicalPackageTiers)
        }
    },
    watch: {
        saleType: function() {
            if (this.saleType === 3 && !clinicalPackageTiers.find(x => x.value === this.packageTierId)){
                this.packageTierId = null;
            }
            else if (this.saleType === 2 && !sportsPackageTiers.find(x => x.value === this.packageTierId)){
                this.packageTierId = null;
            }
            this.model.saleType = this.saleType;
        },
        isSameAddress: function() {
            if (this.isSameAddress){
                this.model.billName = this.model.orgName;
                this.model.billPhone = this.model.orgPhone;
                this.model.billAdd1 = this.model.orgAdd1;
                this.model.billAdd2 = this.model.orgAdd2;
                this.model.billCity = this.model.orgCity;
                this.model.billState = this.model.orgState;
                this.model.billZip = this.model.orgZip;
            }
        }
    }
}
</script>

<style scoped>

img {
    max-width: 75vw;
    max-height: 30vh;
    width: auto;
    height: auto;
}

#create-edit .editor-label {
    text-align: left;
}

</style>