<template>
        <app-card title="Settings">
            <div class="pa-4">
                <div v-if="fields">
                    
                    <div v-if="isAdminOrgAccessType($store.state.currentAccount.selectedOrganizationAccessTypeId)">

                        <h3>Profile Sharing</h3>
                        <div v-if="organization != null && organization.canEnableProfileSharing && isViewingAsAdmin" class="ma-2">

                            <!--                            <div v-if="!fields.isSwayVisaEnabled.value" style="flex: 1">-->
                            <!--                                <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>-->
                            <!--                            </div>-->
                            <app-form-checkbox
                                checkbox-left-side label="Profile Sharing Enabled"
                                v-model="fields.isSwayVisaEnabled.value"
                                :helper-message="fields.isSwayVisaEnabled.helperMessage"
                            ></app-form-checkbox>

                        </div>
                        
                        <h3>Security Preferences</h3>
                        <div class="ma-2">
                            <app-form-checkbox
                                checkbox-left-side
                                label="Require Multi-Factor Authentication"
                                v-model="fields.mfaRequired.value"
                                :helper-message="fields.mfaRequired.helperMessage"
                            ></app-form-checkbox>
                            <div v-if="fields.mfaRequired.value">
                                <app-text-field
                                    type="number"
                                    label="MFA Verification Period (Days)"
                                    v-model="fields.mfaVerificationPeriod.value"
                                    :helper-message="fields.mfaVerificationPeriod.helperMessage"
                                    row
                                    style="max-width: 450px"
                                />
                            </div>
                            


                        </div>
                    </div>

                    
                    
                    <h3>Mobile Security Preferences</h3>
                    <div class="ma-2">
                        <app-form-checkbox
                            checkbox-left-side
                            label="Require PIN"
                            v-model="fields.isPasscodeEnabled.value"
                            :helper-message="fields.isPasscodeEnabled.helperMessage"
                        ></app-form-checkbox>


                    </div>
                    <h3>COVID-19 Settings</h3>
                    <div class="ma-2">
                        <app-form-checkbox
                            checkbox-left-side
                            label="Enable COVID-19 Screening Options"
                            v-model="fields.enableCovid19Screening.value"
                            
                        ></app-form-checkbox>
                        <app-form-checkbox
                            v-if="fields.enableCovid19Screening.value"
                            checkbox-left-side
                            label="Send COVID-19 Screening alert if profile skips temperature input"
                            v-model="fields.flagTestOnCovidTemperatureSkip.value"
                            
                        ></app-form-checkbox>
                        <app-form-checkbox
                            checkbox-left-side
                            label="Include Direct Contact questions in covid screening"
                            v-model="fields.enableCovidContactQuestions.value"
                            
                        ></app-form-checkbox>
                    </div>
                    <div v-if="$store.state.currentAccount.isSuperAdmin">
                        <h3>{{superAdminSymbol}} Internal Sway Admin Properties</h3>
                        <div class="ma-2">
                            <app-form-checkbox
                                checkbox-left-side label="Enable Fall Risk flagging"
                                v-model="fields.fallRiskStampEnabled.value"
                                :helper-message="fields.fallRiskStampEnabled.helperMessage"
                            ></app-form-checkbox>
                        </div>
<!--	                    <div class="ma-2">-->
<!--		                    <app-form-checkbox-->
<!--			                    checkbox-left-side label="Is Internal Org"-->
<!--			                    v-model="fields.isInternalOrg.value"-->
<!--			                    :helper-message="fields.isInternalOrg.helperMessage"-->
<!--		                    ></app-form-checkbox>-->
<!--	                    </div>-->
	                    <div class="ma-2">
		                    <app-form-checkbox
			                    checkbox-left-side label="Enable Sports Profile Properties"
			                    v-model="fields.enableSportsProfileProperties.value"
			                    :helper-message="fields.enableSportsProfileProperties.helperMessage"
		                    ></app-form-checkbox>
	                    </div>
                        <div class="ma-2">
                            <app-form-checkbox
                                checkbox-left-side label="Skip Test Enabled"
                                v-model="fields.isSkipTestEnabled.value"
                                :helper-message="fields.isSkipTestEnabled.helperMessage"
                            ></app-form-checkbox>
                        </div>
                        <div class="ma-2">
                            <app-form-checkbox
                                checkbox-left-side label="External API Enabled"
                                v-model="fields.externalApiEnabled.value"
                                :helper-message="fields.externalApiEnabled.helperMessage"
                            ></app-form-checkbox>
                        </div>
                        <div v-if="organization != null && organization.canEnableProfileSharing" class="ma-2">
                            <app-form-checkbox
                                    checkbox-left-side label="Profile Sharing Enabled"
                                    v-model="fields.isSwayVisaEnabled.value"
                                    :helper-message="fields.isSwayVisaEnabled.helperMessage"
                            ></app-form-checkbox>
                        </div>
                        <div v-if="organization != null" class="ma-2">
                            <app-form-checkbox
                                checkbox-left-side label="Enable Patient Account Creation"
                                v-model="fields.enablePatientAccountCreation.value"
                                :helper-message="fields.enablePatientAccountCreation.helperMessage"
                            ></app-form-checkbox>
                        </div>
                    </div>
<!--                    <div v-if="$store.state.currentAccount.isSuperAdmin">-->
<!--                        <h3>Sports vs Clinical</h3>-->
<!--                        -->
<!--                    </div>-->
                    
                    <h3>Other Mobile App Settings</h3>
                    <div class="ma-2">
                        <app-form-checkbox checkbox-left-side label="Use Metric System" v-model="fields.isMetric.value" ></app-form-checkbox>
                    </div>
                    
                    <div class="ma-2" >
                        <app-btn
                            @click="onFormSubmit"
                            :loading="savingChanges"
                        >Save</app-btn>
                    </div>
                </div>
            </div>
        </app-card>

</template>

<script>

import {
    getOrganizationPreferences,
    updateOrganizationPreferences,
    organizationPreferencesModel
} from '@/services/organization.service'
import {SET_SNACKBAR} from "@/store/mutation-types";
import { isAdminOrgAccessType } from "@/js/shared/helper";
import { superAdminSymbol } from "@/js/shared/constants";

export default {
    name: "OrganizationSettings",
    props: {
        organizationPreferences: {
            type: Object,
            required: true
        },
        organization: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            // fields: null,
            // organizationPreferences: null,
            savingChanges: false,
            fields: null,
            superAdminSymbol
        }
    },
    watch: {
        organizationPreferences() {
            this.init()
        }
    },
    async mounted() {
        this.init()
        // this.setFields()
    },
    computed: {
        account() {
            return this.$store.state.currentAccount
        }
    },
    methods: {
        isViewingAsAdmin(){
            return isAdminOrgAccessType(this.$store.state.currentAccount.selectedOrganizationAccessTypeId);
        },
        async init() {
            if (!this.organizationPreferences) return null
            const {
                isPasscodeEnabled,
                enableCovid19Screening,
                flagTestOnCovidTemperatureSkip,
                enableCovidContactQuestions,
                isMetric,
                mfaRequired,
                mfaVerificationPeriod,
                fallRiskStampEnabled,
                enableSportsProfileProperties,
                isSkipTestEnabled,
                externalApiEnabled,
                isSwayVisaEnabled,
                enablePatientAccountCreation
                // isInternalOrg
            } = this.organizationPreferences;

            this.fields = {
                mfaRequired: {
                    value: mfaRequired,
                    helperMessage: 'Require Multi-Factor Authentication when logging in.'
                },
                mfaVerificationPeriod: {
                    value: typeof mfaVerificationPeriod === 'number' ? mfaVerificationPeriod : 10,
                    helperMessage: 'The number of days between each MFA request. If set to 0, user will be required to authorize on every login attempt.'
                },
                isPasscodeEnabled: {
                    value: isPasscodeEnabled,
                    helperMessage: 'Allows organization to manage device security by requiring a local passcode on each device.'
                },
                enableCovid19Screening: {
                    value: enableCovid19Screening,
                    helperMessage: 'Enabling COVID-19 Screening Options does this that and the other thing...'
                },
                flagTestOnCovidTemperatureSkip: {
                    value: flagTestOnCovidTemperatureSkip,
                    helperMessage: 'Enabling COVID-19 Screening Alert does this that and the other thing...'
                },
                enableCovidContactQuestions: {
                    value: enableCovidContactQuestions,
                    helperMessage: 'Enabling COVID-19 Direct Contact Questions does this that and the other thing...'
                },
                isMetric: {
                    value: isMetric,
                    helperMessage: null
                },
                fallRiskStampEnabled: {
                    value: fallRiskStampEnabled,
                    helperMessage: `Dependent on the profile's age, certain test types will be flagged for high fall risk if scores fall below thresholds.`
                },
                enableSportsProfileProperties: {
                    value: enableSportsProfileProperties,
                    helperMessage: `Enables additional profile metadata fields. This includes Graduation Year, Gender Identity, Race, Primary Language, Secondary Language, Concussion History, and ADHD History.`
                },
                isSkipTestEnabled: {
                    value: isSkipTestEnabled,
                    helperMessage: `Allows tests to be skipped on this organization.`
                },
                isSwayVisaEnabled: {
                    value: isSwayVisaEnabled,
                    helperMessage: `Enables sharing profiles. Unless Patient Account Creation is also enabled, only other Sway Administrators will be able to redeem access to the profile.`
                },
                enablePatientAccountCreation:{
                    value: enablePatientAccountCreation,
                    helperMessage: `Enables Patient (or Athlete) Account creations for the organization. Enabling Patient (or Athlete) Account creation has many benefits, including but not limited to: 
Not required to re-input profile data when re-baselining, keep profile demographics up to date (height, weight, other details), and easily take remotely assigned tests.`
                },
                externalApiEnabled: {
                    value: externalApiEnabled,
                    helperMessage: `Allows users to generated external API Keys.`
                },
	            // isInternalOrg: {
                //     value: isInternalOrg,
                //     helperMessage: `Defines whether or not the Organization is an Internal Sway Org. Used for reporting purposes.`
                // },
	            //
            }
        },
        isAdminOrgAccessType(id) {
            return isAdminOrgAccessType(id)
        },
        async onFormSubmit() {

            const request = {...organizationPreferencesModel}
            const {organizationId, organizationPreferencesId} = this.organizationPreferences;

            const {
                isPasscodeEnabled,
                enableCovid19Screening,
                flagTestOnCovidTemperatureSkip,
                enableCovidContactQuestions,
                isMetric,
                mfaRequired,
                mfaVerificationPeriod,
                fallRiskStampEnabled,
                enableSportsProfileProperties,
                isSkipTestEnabled,
                isSwayVisaEnabled,
                enablePatientAccountCreation,
                externalApiEnabled
                // isInternalOrg
            } = this.fields;

            request.organizationId = organizationId;
            request.organizationPreferencesId = organizationPreferencesId;
            
            request.mfaRequired = mfaRequired.value;
            request.mfaVerificationPeriod = mfaVerificationPeriod.value;

            request.isPasscodeEnabled = isPasscodeEnabled.value;
            request.enableCovid19Screening = enableCovid19Screening.value;
            request.flagTestOnCovidTemperatureSkip = flagTestOnCovidTemperatureSkip.value;
            request.enableCovidContactQuestions = enableCovidContactQuestions.value;
            request.isMetric = isMetric.value;
            request.fallRiskStampEnabled = fallRiskStampEnabled.value
            request.enableSportsProfileProperties = enableSportsProfileProperties.value;
            request.isSkipTestEnabled = isSkipTestEnabled.value;
            request.externalApiEnabled = externalApiEnabled.value;
            request.isSwayVisaEnabled = isSwayVisaEnabled.value;
            request.enablePatientAccountCreation = enablePatientAccountCreation.value;
            // request.isInternalOrg = isInternalOrg.value;

            this.savingChanges = true;
            
            
            
            const response = await updateOrganizationPreferences(request);
            if (!response.isSuccess) {
                this.savingChanges = false;
            }

            this.$store.commit(SET_SNACKBAR, {
                text: 'Organization Settings Successfully Updated',
                color: 'success',
                open: true
            })
            
            const {organizationPreferences} = response;
            
            this.$emit('setOrganizationPreferences', organizationPreferences) 
            // this.organizationPreferences = organizationPreferences
            this.savingChanges = false;
        },

    },


}
</script>

<style scoped>

</style>