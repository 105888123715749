<template>
    <div style="background-color: white; overflow-y: scroll; max-width: 890px">
        <div v-if="profile && currentProfileTest">
            <PDFExport 
                :profile="profile" 
                :profile-test="currentProfileTest" 
                :isFullpage="true" 
                :enableSportsProfileProperties="enableSportsProfileProperties"
            />
        </div>
        <div v-else>
            Loading
        </div>
    </div>
</template>

<script>
import {getWebProfileDetail, getProfileTestById, getProfile, getProfileTestForAzureAD, getProfileTestForPortal} from "@/services/profile.service";
import SwayProfile from "@/js/shared/classes/Profile/SwayProfile";
import SwayProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
import PDFExport from "@/components/pdf-export"

export default {
    name: "FullpageProfileTest",
    components: {
        PDFExport
    },
    data() {
        return {
            profile: null,
            currentProfileTest: null,
            enableSportsProfileProperties: null
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getProfile();
            // this.getProfileLocal()
        },
        async getProfile() {
            let {profileTestId, authToken, includedTestIds, isPortal} = this.$route.query;
            // timezone & timezoneOffset query params implemented in helper.js asLocalTime

            profileTestId = parseInt(profileTestId)
            includedTestIds = includedTestIds.split(',').map(x => parseInt(x));
            const response = isPortal ? await getProfileTestForPortal(profileTestId, authToken) : await getProfileTestForAzureAD(profileTestId, authToken);
            
            if (!response.isSuccess) return 
            
            const {profile, profileTest, enableSportsProfileProperties} = response;
            
            this.initPdf({profile, profileTestId, includedTestIds, enableSportsProfileProperties})
            
            // let filteredTests = [];
            //    
            // profile.tests.forEach(test => {
            //     if (includedTestIds.includes(test.id)) {
            //         filteredTests.push(test)
            //     }
            // });
            //
            // profile.tests = filteredTests;
            //
            // this.profile = new SwayProfile(response.profile);
            // this.currentProfileTest = new SwayProfileTest(response.profileTest)
            // this.profile = new SwayProfile(response.profile)
        },
        async getProfileLocal() {
            
            // sample John Doe Report route
            // TEST URL ONLY http://localhost:8080/profile-test-fullpage?profileId=215412&profileTestId=498743&isPortal=true&authToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsZXgucGV0dGlncmV3QHN3YXltZWRpY2FsLmNvbSIsInVuaXF1ZV9uYW1lIjoiQWxleCBQZXR0aWdyZXciLCJBY2NvdW50SWQiOiIxNTIzMCIsIlNlbGVjdGVkT3JnYW5pemF0aW9uSWQiOiI2NDQxIiwiSXNTdXBlckFkbWluIjoiVHJ1ZSIsIlNlbGVjdGVkT3JnYW5pemF0aW9uQWNjZXNzIjoiMSIsIlZpZXdpbmdBc1N1cGVyIjoiRmFsc2UiLCJuYmYiOjE2MjE5NjQ1NTYsImV4cCI6MTYyMjA1MDk1NiwiaWF0IjoxNjIxOTY0NTU2fQ.9z01xXKvpphTYcguRQzzHJNw2tTkb-jHd62KepssUYw&includedTestIds=498863,499027,498743,498742,498741,498740
            
            let {profileId, profileTestId, includedTestIds, authToken} = this.$route.query;
            
            profileId = parseInt(profileId)
            profileTestId = parseInt(profileTestId);
            includedTestIds = includedTestIds.split(',').map(x => parseInt(x));
            
            // let profileId = 215412;
            // let profileTestId = 498743;
            // let includedTestIds = [
            //     498863,
            //     499027,
            //     498743,
            //     498742,
            //     498741,
            //     498740
            // ]
            
            // let profileTestResponse = await getProfileTestById({id: 498743, profileId: 215412})
            
            let profileResponse = await getWebProfileDetail(profileId)
            
            // const {profileTest} = profileTestResponse
            const {profile} = profileResponse;
            
            this.initPdf({profile, profileTestId, includedTestIds})

            // profile.tests.forEach(test => {
            //     if (includedTestIds.includes(test.id)) {
            //         filteredTests.push(test)
            //     }
            // });
            //
            // this.profile = new SwayProfile(profile);
            // this.currentProfileTest = new SwayProfileTest(profileTest)
        },
        initPdf({profile, profileTestId, includedTestIds, enableSportsProfileProperties}) {
            let responseProfile = new SwayProfile(profile);
            this.currentProfileTest = responseProfile.tests.find(test => test.id === profileTestId)
            
            let filteredTests = [];

            responseProfile.tests.forEach(test => {
                if (includedTestIds.includes(test.id)) {
                    filteredTests.push(test)
                }
            });

            responseProfile.tests = filteredTests;
            
            this.enableSportsProfileProperties = enableSportsProfileProperties
            this.profile = responseProfile;
            
            // this.currentProfileTest = profile.tests[0]
        }

    }
}
</script>

<style scoped>

</style>