<template>
    <div v-if="currentInvoiceId">
        <div style="display: flex">
            <div >
   
                <app-select
                    :items="invoices"
                    label=""
                    v-model="currentInvoiceId"
                    item-text="displayRange"
                    item-value="id"
                ></app-select>
  
            </div>
            <v-spacer></v-spacer>
            <div>
                <app-btn @click="exportPdf" :loading="exportingPdf">
                    Download Invoice PDF
                </app-btn>
            </div>
            
        </div>
        <OrganizationInvoice :billing-info="billingInfo" :current-invoice="currentInvoice" :resetBillingInfo="resetBillingInfo" :getInvoices="getInvoices"></OrganizationInvoice>
    </div>
    <div v-else>
        <EmptyState style="margin-top: 30px" title="No Invoices" description="No invoices have been created." />
    </div>
</template>

<script>
import OrganizationInvoice from "@/components/Billing/OrganizationInvoice";
import {getBillingInfo, invoicePdfForPortal} from "@/services/billing.service";
import EmptyState from "@/components/EmptyState";
export default {
    name: "OrganizationViewBills",
    components: {EmptyState, OrganizationInvoice},
    props: {
        invoices: {
            required: true
        },
        billingInfo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            currentInvoiceId: null,
            exportingPdf: false
        }
    },
    mounted() {
        if (this.invoices.length < 1){
            return
        }
        
        if (this.$route.query.id){
            this.currentInvoiceId = parseInt(this.$route.query.id);
            return;
        }
        
        this.currentInvoiceId = this.invoices[0].id
    },
    watch: {
        currentInvoiceId() {
            this.$router.push({path: this.$route.path, query: {orgId: this.$route.query.orgId, id: this.currentInvoiceId}});
        }
    },
    computed: {
        currentInvoice() {
            return this.invoices.find(x => x.id === this.currentInvoiceId)
        }
    },
    methods: {
        async exportPdf() {
            this.exportingPdf = true;
            
            let request = {
                htmlString: "",
                footerHtmlString: "",
                headerHtmlString: "",
                invoiceId: this.currentInvoiceId
            }
            
            await invoicePdfForPortal(request)

            // let documentCopy = document.cloneNode(true);
            // let mainContent = documentCopy.getElementById("app");
            // let newContent = documentCopy.getElementById("invoice")
            // // let downloadBtn = documentCopy.getElementById("download-invoice-btn")
            // // downloadBtn.remove()
            // mainContent.parentNode.replaceChild(newContent, mainContent);
            //
            // let head = documentCopy.head;
            // let link = documentCopy.createElement("link");
            //
            // link.type = "text/css";
            // link.rel = "stylesheet";
            // link.href = `${window.location.origin}/pdf.css`;
            //
            // head.appendChild(link)
            //
            // console.log(documentCopy.documentElement.innerHTML)
            //
            // let htmlToSend = "<html>" + documentCopy.documentElement.innerHTML + "</html>";
            //
            // let request = {
            //     htmlString: htmlToSend,
            //     footerHtmlString: "",
            //     headerHtmlString: "",
            //     invoiceId: this.currentInvoice.id
            // }
            //
            //
            // let result = await createInvoicePdf(request);
            this.exportingPdf = false;
        },
        async resetBillingInfo() {
            const result = await getBillingInfo();
            return this.$emit('setBillingInfo', result)
        },
        async getInvoices() {
            return this.$emit('getInvoices')
        },
    }
}
</script>

<style scoped>

</style>