<template>
    <div class="mt-3" style="display: flex; justify-content: center">

        <app-card hide-header v-if="currentInvoice && billingInfo" class="pa-4" id="invoice" style="display: flex; justify-content: center; min-height: 800px; width: 910px">
            <InvoicePdf :current-invoice="currentInvoice" :billing-info="billingInfo" />

            
            <app-button @click="payInvoiceClicked" v-if="maxPaymentAmount > 0 && !currentInvoice.deletedOn && !currentInvoice.isRefunded && !currentInvoice.isPOPending">Pay Invoice</app-button>
	        <div v-if="$store.state.currentAccount.isSuperAdmin" style="display: flex; gap: .5rem" class="mt-4">
		        <app-button @click="editInvoice" >{{superAdminSymbol}} Edit Invoice</app-button>
		        <app-button @click="sendInvoiceEmail" v-if="!currentInvoice.payment" :loading="sendingInvoiceEmail">{{superAdminSymbol}} Send Invoice Due Email</app-button>
		        <app-button @click="sendReceiptEmail" v-if="currentInvoice.payment">{{superAdminSymbol}} Send Invoice Receipt Email</app-button>
	        </div >
	        

            <div style="padding-top:10px" v-if="$store.state.currentAccount.isSuperAdmin && currentInvoice.voidReason">Void Reason: {{currentInvoice.voidReason}}</div>
        </app-card>

        <v-dialog v-model="isPayInvoiceDialogOpen"
                  width="800">
            <v-card v-if="!isSubmittingPayment">
                <v-card-title>
                    <span style="width:100%;">
                        <h3 style="float:left">Pay Invoice ({{maxPaymentAmount.toFixed(2)}})</h3>
                        <app-text-field v-if="$store.state.currentAccount.isSuperAdmin" v-model="paymentAmount" label="Pay Amount"
                                :error="paymentAmount > maxPaymentAmount || paymentAmount <= 0" style="float:right">
                        </app-text-field>
                    </span>
                    <br />
                </v-card-title>

                <div style="padding-left:40px; padding-bottom:20px">
                    <div>
                        <h4>Pay by Credit Card</h4>
                        <div v-if="billingInfo.billMethod == 1 && billingInfo.stripeId">
                            Credit card on file - ends in {{billingInfo.lastFourDigits}}
                            <br />
                            <app-btn style="margin-top:5px" @click="showAddCreditCard" v-if="!isAddCreditCardShowing">
                                Change Credit Card
                            </app-btn>
                        </div>
                        <div v-else>
                            <app-btn style="margin-top:5px" @click="showAddCreditCard" v-if="!isAddCreditCardShowing">
                                Add Credit Card
                            </app-btn>
                        </div>

                        <div v-show="isAddCreditCardShowing">
                            <credit-card-form
                                    @cancel="isAddCreditCardShowing = false"
                                    @cardSaved="cardSaved"
                                    style="max-width: 650px"
                            ></credit-card-form>
                        </div>

                        <div v-if="billingInfo.billMethod == 1 && billingInfo.stripeId">
                            <app-btn style="margin-top:5px" @click="payByCreditCard">
                                Pay Now
                            </app-btn>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <br />
                    <h4>Pay by Check</h4>
                    <div style="display: flex">
                        <div style="flex: 1 1 0%;">
                        Mail To:<br /><br />
                        Sway Medical<br />
                        Dept 1046<br />
                        Tulsa, OK 74182<br />
                        </div>

                        <div style="flex: 1 1 0%;" v-if="$store.state.currentAccount.isSuperAdmin">
                            Mark Invoice As Paid (Sway Internal Admin Only)
                            <app-form-date-picker v-model="paymentDate" line label="Payment Date" mask="##/##/####" placeholder="MM/DD/YYYY" style="width: 200px" />
                            <app-text-field v-model="checkNumber" line label="Check Number" style="width: 200px" />
                            <app-btn @click="payByCheck" style="margin-top: 5px;">
                                Record Payment
                            </app-btn>
                        </div>

                    </div>
                    <br />
                    <hr />
                    <br />
                    <div style="display: flex">
                        <div style="flex: 1 1 0%;">
                            <h4>Pay by Direct Deposit or ACH</h4>
                            Contact Billing at Sway<br />
                            855-SWAY-MED (855-792-9633) Option 3<br />
                            <a :href="'mailto:billing@swaymedical.com?subject=Payment%20Invoice%20%23' + currentInvoice.id" class="link-span">billing@swaymedical.com</a><br />
                        </div>

                        <div style="flex: 1 1 0%;" v-if="$store.state.currentAccount.isSuperAdmin">
                            Mark Invoice As Paid (Sway Internal Admin Only)
                            <app-form-date-picker v-model="paymentDate" line label="Payment Date" mask="##/##/####" placeholder="MM/DD/YYYY" style="width: 200px" />
                            <app-btn @click="payByDirectDeposit" style="margin-top: 5px;">
                                Record Payment
                            </app-btn>
                        </div>
                    </div>
                    <br v-if="$store.state.currentAccount.isSuperAdmin" />
                    <hr v-if="$store.state.currentAccount.isSuperAdmin" />
                    <br v-if="$store.state.currentAccount.isSuperAdmin" />
                    <div v-if="$store.state.currentAccount.isSuperAdmin" style="display: flex">
                        <div style="flex: 1 1 0%;">
                            <h4>Other Manual Payment (Sway Internal Admin Only)</h4>
                        </div>

                        <div style="flex: 1 1 0%;">
                            Mark Invoice As Paid
                            <app-form-date-picker v-model="paymentDate" line label="Payment Date" mask="##/##/####" placeholder="MM/DD/YYYY" style="width: 200px" />
                            <app-btn @click="payByOtherManual" style="margin-top: 5px;">
                                Record Payment
                            </app-btn>
                        </div>
                    </div>
                </div>
            </v-card>
            <v-card v-else>
                <AppPageLoader text="Submitting Payment..."/>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {payInvoice, sendInvoicePdfEmail, sendInvoiceReceiptEmail} from '@/services/billing.service'
import CreditCardForm from "@/components/Billing/CreditCardForm";
import InvoicePdf from "@/components/invoice-pdf/InvoicePdf";
import AppButton from "@/components/AppButton";
import {SET_SNACKBAR} from "@/store/mutation-types";
import moment from "moment";
import {superAdminSymbol} from "@/js/shared/constants";

export default {
    name: "OrganizationInvoice",
    components: {AppButton, InvoicePdf, CreditCardForm},
    data() {
        return {
            isPayInvoiceDialogOpen: false,
            isAddCreditCardShowing: false,
            isSubmittingPayment: false,

            paymentAmount: 0.0,
            paymentDate: moment().format('YYYY-MM-DD'),
            checkNumber: "",
	        superAdminSymbol,
	        sendingInvoiceEmail: false
        }
    },
    computed: {
        maxPaymentAmount() {
            return (this.currentInvoice.totalNumeric - this.currentInvoice.paidTotalNumeric);
        }
    },
    props: {
        currentInvoice: {
            required: true,
            //type: Object
        },
        billingInfo: {
            required: true
        },
        isFullpage: {
            required: false
        },
        resetBillingInfo: {
            required: false
        },
        getInvoices: {
            required: false
        }
    },
    methods: {
        payInvoiceClicked() {
            this.paymentAmount = this.maxPaymentAmount.toFixed(2);
            this.isPayInvoiceDialogOpen = true;
        },
        async actuallyPayInvoice(request) {
            request.returnUrl = encodeURI(window.location.href);
            this.isSubmittingPayment = true;
            var response = await payInvoice(request);
            if (!response.errors){
                await this.getInvoices();
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Thank you for your payment.',
                    color: 'success',
                    open: true
                });
                this.isPayInvoiceDialogOpen = false;
            }
            this.isSubmittingPayment = false;
        },
        async payByCreditCard() {
            var request = { invoiceId: this.currentInvoice.id, paymentAmount: this.paymentAmount, paymentMethod: 1 };
            let response = await this.actuallyPayInvoice(request);
			if (response.isSuccess) {
				location.reload();
			}
        },
        async payByCheck() {
            var request = { invoiceId: this.currentInvoice.id, paymentAmount: this.paymentAmount, paymentMethod: 3, paymentDate: this.paymentDate, checkNumber: this.checkNumber };
	        let response = await this.actuallyPayInvoice(request);
	        if (response.isSuccess) {
		        location.reload();
	        }
        },
        async payByDirectDeposit() {
            var request = { invoiceId: this.currentInvoice.id, paymentAmount: this.paymentAmount, paymentMethod: 4, paymentDate: this.paymentDate };
	        let response = await this.actuallyPayInvoice(request);
	        if (response.isSuccess) {
		        location.reload();
	        }
        },
        async payByOtherManual() {
            var request = { invoiceId: this.currentInvoice.id, paymentAmount: this.paymentAmount, paymentMethod: 2, paymentDate: this.paymentDate };
	        let response = await this.actuallyPayInvoice(request);
	        if (response.isSuccess) {
		        location.reload();
	        }
        },
        showAddCreditCard() {
            this.isAddCreditCardShowing = true;
        },
        async cardSaved() {
            await this.resetBillingInfo();
            this.$store.commit(SET_SNACKBAR, {
                text: 'Credit Card Successfully Updated',
                color: 'success',
                open: true
            });
            this.isAddCreditCardShowing = false
        },
        editInvoice() {
            this.$router.push({ path: '/billing/create-invoice', query: { orgId: this.billingInfo.id, id: this.currentInvoice.id }});
        },
        async sendInvoiceEmail() {
			this.sendingInvoiceEmail = true
            let response = await sendInvoicePdfEmail(this.currentInvoice.id);
			if (response.isSuccess) {
				this.$store.commit(SET_SNACKBAR, {
					text: 'Successfully Sent Email',
					color: 'success',
					open: true
				});
			} else {
				this.$store.commit(SET_SNACKBAR, {
					text: 'There was an error sending the email.',
					color: 'error',
					open: true
				});
			}
	        this.sendingInvoiceEmail = false
        },
        async sendReceiptEmail() {
            await sendInvoiceReceiptEmail(this.currentInvoice.id);
        }
    }
    
    // mounted() {
    //     this.getInvoice();
    //     if (this.isFullpage){
    //         let head = document.head;
    //         let link = document.createElement("link");
    //
    //         link.type = "text/css";
    //         link.rel = "stylesheet";
    //         link.href = `${window.location.origin}/pdf.css`;
    //
    //         head.appendChild(link)
    //     }
    // },
    
    
}
</script>

<style scoped>

</style>