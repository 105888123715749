import { render, staticRenderFns } from "./SubscriptionReportsDetails.vue?vue&type=template&id=768db606&scoped=true"
import script from "./SubscriptionReportsDetails.vue?vue&type=script&lang=js"
export * from "./SubscriptionReportsDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "768db606",
  null
  
)

export default component.exports