import httpService from "@/services/httpService";
import {baseUrl} from "@/config"


const endpoint = baseUrl + "/Enterprise"

const getEnterpriseOrganizations = async (includeParentOrg) => {
    try {
        return await httpService.post(endpoint + '/GetEnterpriseOrganizations', {includeParentOrg} )
    } catch (e) {
    }
}

const GetOrganizationsBalanceTestVolumeReport = async (startDate, endDate) => {
    try {
        return await httpService.post(endpoint + '/GetOrganizationsBalanceTestVolumeReport', {startDate, endDate} )
    } catch (e) {
    }
}

export {
    getEnterpriseOrganizations,
    GetOrganizationsBalanceTestVolumeReport
}
