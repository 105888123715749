<template>
    
        <div
            class="app-card text--text"
            :width="width"
            :min-width="minWidth"
            :min-height="minHeight"
        >

            <div @click="$emit('click')">
                <div 
                    v-if="!hideHeader" 
                    class="app-card-header"
                >
	                <div style="display: flex; align-items: center">
		                <div style="font-size: 18px">
			                {{ title }}
		                </div>
		
		                <v-spacer></v-spacer>
		                <div v-if="editable" @click="$emit('edit')">
			                <!--                        <app-fab-btn x-small type="edit" @click="$emit('edit')"></app-fab-btn>-->
			                <!--                        <v-icon style="color: white">mdi-circle-edit-outline</v-icon>-->
			                <app-fab-btn type="edit" color="white" x-small />
		                </div>
		                <slot name="header-right"></slot>
	                </div>
                    
	                <slot name="header-bottom"></slot>
                </div>
            <slot></slot>
            </div>
        </div>
    
    
</template>

<script>
export default {
    name: "AppCard",
    props: {
        width: {
            type: Number
        },
        minWidth: {
            type: Number
        },
        minHeight: {
            type: Number
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        title: {
            default: 'Title'
        },
        editable: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
.v-card {
    /*border: 1px solid #DDD;*/
    overflow: hidden;
    box-shadow:  0 0 4px red;
}

.app-card {
    overflow: hidden;
    border-radius: 5px;
    /*box-shadow: 0 0 8px rgba(0,0,0,.2);*/
	border: 1px solid #ddd;
    background-color: white;
}

.app-card-header {
	width: 100%; 
	border-bottom: 1px solid var(--main-border-color);
	background-color: var(--v-dark-base);
	color: white;
	padding: 10px;
	font-weight: bold; 
	
}
</style>