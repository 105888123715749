<template>
    <div>
        <div style="display: flex; border-bottom: 1px solid lightgrey">
            <slot name="search"></slot>
        </div>
    </div>
    
</template>

<script>
import {mdiContentCopy, mdiDeleteOutline, mdiDownload} from "@mdi/js";

export default {
    name: "TableOptionsRow",
    
}
</script>

<style scoped>

</style>