<template>
	<div>
		<v-container fluid>
			<v-row>
				<v-col 
					v-for="(header, index) in headers" :key="index" 
					:cols="12 / headers.length"
					style="display: flex; justify-content: center; border: 1px solid var(--main-border-color)"
					:style="{backgroundColor: header.level == 2 ? '#e3f2fd' : null}"
				>
					<div>{{ header.text }}</div>
				</v-col>
				
				
			</v-row>
		</v-container>
		<highcharts :options="chartOptions" />
	</div>
</template>

<script>
import {getBillingChildOrgs} from "@/services/billing.service";
import {getOrgConnects} from "@/services/organization.service";
import SportsSubscription from "@/js/shared/classes/Subscription/SportsSubscription";
import ClinicalSubscription from "@/js/shared/classes/Subscription/ClinicalSubscription";

export default {
	name: "OverviewConnections",

	props: {
		accessParentOrgs: {
			required: true
		},
		accessChildOrgs: {
			required: true
		},
		billingChildOrgs: {
			required: true
		},
		
		billingInfo: {
			required: true
		},
		allSportsSubscriptions: {
			required: true
		},
		allClinicalSubscriptions: {
			required: true
		}
	},
	data() {
		return {
			headers: []
		}
	},
	mounted() {
		this.init()
	},
	methods: {
		async init() {
			
		},
		async getBillingChildOrgs() {
			let response = await getBillingChildOrgs();
			
		},
		async getAccessChildOrgs() {
			let response = await getOrgConnects();	
		}
	},
	computed: {
		currentSportsSubscription() {
			if (!this.allSportsSubscriptions?.data) return null;
			return this.allSportsSubscriptions.data.find(x => x.isCurrent);
		},
		currentClinicalSubscription() {
			if (!this.allClinicalSubscriptions?.data) return null;
			return this.allClinicalSubscriptions.data.find(x => x.isCurrent);
		},
		childOrgs() {
			let childOrgs = [];
			this.billingChildOrgs.forEach(x => {
				childOrgs.push({
					id: x.organizationId,
					name: x.organizationName,
					sportsSubscription: x.sports,
					clinicalSubscription: x.clinical,
					isInternalOrg: x.isInternalOrg,
					isResearch: x.isResearch,
					isTrial: x.isTrial,
					packageTierId: x.packageTierId
				})
			});
			
			this.accessChildOrgs.forEach(x => {
				let org = childOrgs.find(y => y.id === x.childOrganizationId)
				if (!!org) {
					org.accessInfo = {
						connectionStatus: x.connectionStatus,
						connectionStatusChipColor: x.connectionStatusChipColor,
						connectionType: x.connectionType,
					}
				} else {
					childOrgs.push({
						id: x.childOrganizationId,
						name: x.childOrganizationName,
						accessInfo: {
							connectionStatus: x.connectionStatus,
							connectionStatusChipColor: x.connectionStatusChipColor,
							connectionType: x.connectionType,
						}
						
					})
				}
			})
			return childOrgs
		},
		chartOptions() {
			
			// level 1 = access parents
			// level 2 = current org
			// level 3 = subscriptions
			// level 4 = children
			
			this.headers = []

			let sportsChildOrgOptions = {
				color: '#e8f5e9',
				marker: {
					lineColor: '#4caf50',
					lineWidth: 1
				},
				word: 'Sports'
			}
			
			let clinicalChildOrgOptions = {
				color: '#f3e5f5',
				marker: {
					lineColor: '#9c27b0',
					lineWidth: 1
				},
				word: "Clinical"
			}
			
			if (!this.$store.state.currentOrganization) return
			
			const {organization: currentOrganization} = this.$store.state.currentOrganization;
			
			const {currentSportsSubscription, currentClinicalSubscription} = this;
			
			let data = [];
			
			// Add Parent Billing Org Information
			if (this.billingInfo?.billToOrgId) {
				data.push({
					id: this.billingInfo.billToOrgId.toString(),
					parent: '',
					name: this.billingInfo.billToOrgName,
					level: 1,
					color: '#e3f2fd',
					marker: {
						lineColor: '#2196f3',
						lineWidth: 2,
					},
					tooltipHTML: `<div><strong>${this.billingInfo.billToOrgName}</strong> is a Billing Parent Organization to <strong>${currentOrganization.name}</strong></div>`
				});
				
				// this.headers.push({
				// 	text: 'Parent Organizations',
				// 	level: 1
				// })
			}
			
			// Add Access Parents
			this.accessParentOrgs.forEach(x => {
				if (x.parentOrganizationId == this.billingInfo?.billToOrgId) return;
				data.push({
					id: x.parentOrganizationId.toString(),
					parent: "",
					name: `🔍 ${x.parentOrganizationName} (${x.parentOrganizationId})`,
					level: 1,
					color: '#fff3e0',
					marker: {
						lineColor: '#ff9800',
						lineWidth: 2,
					},
					tooltipHTML: `<div><strong>${x.parentOrganizationName}</strong> is an Access Parent Organization to <strong>${currentOrganization.name}</strong></div>`
				})
			})
			
			if (this.billingInfo?.billToOrgId || this.accessParentOrgs.length > 0) {
				this.headers.push({
					text: 'Parent Organizations',
					level: 1
				})
			}
			
			// Add Current Organization
			data.push({
				id: currentOrganization.id.toString(),
				parent: this.billingInfo?.billToOrgId ? this.billingInfo.billToOrgId.toString() : "", 
				name: `${currentOrganization.name} (${currentOrganization.id})`,
				level: 2,
				color: '#e3f2fd',
				marker: {
					lineColor: '#2196f3',
					lineWidth: 2,
				},
				tooltipHTML: this.billingInfo?.billToOrgId ? `<div><strong>${currentOrganization.name}</strong> is a child billing organization to <strong>${this.billingInfo.billToOrgName}</strong></div>` : null
			})
			
			this.headers.push({
				text: 'Current Organization',
				level: 2
			})
			
			// Add Subscriptions
			if (currentSportsSubscription) {
				data.push({
					id: currentSportsSubscription.productSubscriptionId.toString(),
					parent: currentOrganization.id.toString(),
					name: 'Sports Subscription',
					color: '#4caf50',
					level: 3,
					tooltipHTML: `<div >${currentSportsSubscription.startDate.display} to ${currentSportsSubscription.endDate.display}</div><br /><br />
									<div ><strong>${currentSportsSubscription.packageTier.display}</strong><br />
									<div >Profile Credit Count: <strong>${currentSportsSubscription.totalProfileCount.display}</strong></div><br />
									<div >Profile Price: <strong>${currentSportsSubscription.profilePrice.display}</strong></div><br />
									<div>System Fee: <strong>${currentSportsSubscription.systemFee.display}</strong></div><br />
									<div>Total Price: <strong>${currentSportsSubscription.total.display}</strong></div><br />
									</div>`
				})
			}
			
			if (currentClinicalSubscription) {
				data.push({
					id: currentClinicalSubscription.productSubscriptionId.toString(),
					parent: currentOrganization.id.toString(),
					name: 'Clinical Subscription',
					color: '#9c27b0',
					level: 3,
					tooltipHTML: `<div>${currentClinicalSubscription.startDate.display} to ${currentClinicalSubscription.endDate.display}</div><br /><br />
									<div style="margin-top: 200px"><strong>${currentClinicalSubscription.packageTier.display}</strong><br />
									<div>Profile Credit Price: <strong>${currentClinicalSubscription.profilePrice.display}</strong></div><br />
									<div>System Fee: <strong>${currentClinicalSubscription.systemFee.display}</strong></div><br />
									</div>`
				})
			}
			
			if (currentSportsSubscription || currentSportsSubscription) {
				this.headers.push({
					text: 'Current Organization Subscriptions',
					level: 3
				})
			}
			

			let sportsChildColor = '#e8f5e9';
			let sportsChildLineColor = '#4caf50';
			
			let sportsColor = '#4caf50';
			let sportsLineColor = '#4caf50';
			
			let clinicalColor = '#9c27b0';
			let clinicalLineColor = '#9c27b0';
			
			let clinicalChildColor = '#f3e5f5';
			let clinicalChildLineColor = '#9c27b0';
			
			let childColor = '#e3f2fd';
			let childLineColor = '#2196f3';
			
			let accessOnlyChildColor = '#fff3e0';
			let accessOnlyChildLineColor = "#ff9800"
			
			if (this.childOrgs.length > 0) {
				this.headers.push({
					text: 'Billing and Access Child Organizations',
					level: 4
				})
			}
			
			// Add child billing and access organizations
			this.childOrgs.forEach((x, index) => {
				
				
				let hasSportsSubscription = !!x?.sportsSubscription;
				let hasClinicalSubscription = !!x?.clinicalSubscription;
				
				let color, lineColor, tooltipHTML, parent, linkColor, linkMessage = "";
				
				let accessInfoHTML = `<div>${x.accessInfo ? '✅' : '❌'} Access Child Organization </div>`
				if (x.accessInfo) {
					linkColor = x.accessInfo.connectionType.color
					accessInfoHTML += `| Type: <div style="width: 10px"></div><div style="color: ${x.accessInfo.connectionType.color}"> ${x.accessInfo.connectionType.emoji} ${x.accessInfo?.connectionType.text}</div><div> | Status: </div><div style="color: ${x.accessInfo.connectionStatus.color}">${x.accessInfo.connectionStatus.emoji} ${x.accessInfo?.connectionStatus.text}</div>`
				}
				
				if (!hasSportsSubscription && !hasClinicalSubscription) {
					if (x.packageTierId == 1 || x.packageTierId == 2) {
						color = sportsChildColor;
						parent = currentSportsSubscription?.productSubscriptionId?.toString()
						lineColor = sportsChildLineColor;
						tooltipHTML = `<div>✅ Billing Child Organization</div><br />${accessInfoHTML}<br /><br/> 
										<div>I am a billing child pulling from my parent's <strong>Sports</strong> subscription.</div>`
					} else if (x.packageTierId) {
						color = clinicalChildColor;
						lineColor = clinicalChildLineColor;
						parent = currentClinicalSubscription?.productSubscriptionId?.toString()
						tooltipHTML = `<div>✅ Billing Child Organization</div><br />${accessInfoHTML}<br /><br/>
										<div>I am a billing child pulling from my parent's <strong>Clinical</strong> subscription.</div>`
					} else {
						color = accessOnlyChildColor;
						lineColor = accessOnlyChildLineColor;
						parent = currentOrganization.id.toString()
						tooltipHTML = `<div>❌ Billing Child Organization</div><br />${accessInfoHTML}<br /><br/>
										<div>I am an <strong>Access</strong> Child Organization only</div><br /><div><strong>${currentOrganization.name}</strong> is NOT my Billing Parent Organization.</div>`
					}				
				} else if (hasSportsSubscription && hasClinicalSubscription) {
					color = childColor;
					lineColor = childLineColor;
					parent = currentOrganization.id.toString()
					tooltipHTML = `<div>Oh my goodness I'm a child with both subscription types</div>`;
				} else if (hasSportsSubscription) {
					color = sportsColor;
					lineColor = sportsLineColor;
					parent = currentOrganization.id.toString()
					tooltipHTML = `<div>✅ Billing Child Organization</div><br />${accessInfoHTML}<br /><br/>
									<div>I have my own subscription that my Parent Billing Org pays for.</div><br /><br />
									<div >${x.sportsSubscription.startDate.display} to ${x.sportsSubscription.endDate.display}</div><br /><br />
									<div ><strong>${x.sportsSubscription.packageTier.display}</strong><br />
									<div >Profile Credit Count: <strong>${x.sportsSubscription.profileCount.display}</strong></div><br />
									<div >Profile Price: <strong>${x.sportsSubscription.profilePrice.display}</strong></div><br />
									<div>System Fee: <strong>${x.sportsSubscription.systemFee.display}</strong></div><br />
									<div>Total Price: <strong>${x.sportsSubscription.total.display}</strong></div><br />
									</div>`
				} else if (hasClinicalSubscription) {
					color = clinicalColor;
					lineColor = clinicalLineColor;
					parent = currentOrganization.id.toString()
					tooltipHTML = `<div>✅ Billing Child Organization</div><br /><div>${accessInfoHTML}</div><br /><br/>
									<div>I have my own subscription that my Parent Billing Org pays for.</div><br /><br />
									<div>${x.clinicalSubscription.startDate.display} to ${x.clinicalSubscription.endDate.display}</div><br /><br />
									<div style="margin-top: 200px"><strong>${x.clinicalSubscription.packageTier.display}</strong><br />
									<div>Profile Credit Price: <strong>${x.clinicalSubscription.profilePrice.display}</strong></div><br />
									<div>System Fee: <strong>${x.clinicalSubscription.systemFee.display}</strong></div><br />
									</div>`
				} 
				
				data.push({
					...x,
					name: `${x.accessInfo ? '🔍' : ''} ${x.name} (${x.id})`,
					parent,
					level: 4,
					color: color,
					marker: {
						lineColor: lineColor,
						lineWidth: 1,
					},
					
					link: {
						color: linkColor
					},
					tooltipHTML,
					linkMessage
				})
			})
			
			let levels = [
				{
					level: 1,
					levelIsConstant: false,
					
				},
				
				{
					level: 2,
					marker: {
						symbol: 'rect',
						radius: 20
					}
				},
				{
					level: 3,
					colorByPoint: true,
					marker: {
						symbol: 'rect',
						radius: 20
					}
				},
				{
					level: 4,
					
				}
			]
			
			
			
			
			
			return{
				title: {
					text: ''
				},
				series: [
					{
						link: {
							// color: 'red'	
						},
						type: 'treegraph',
						data: data,
						tooltip: {
							// pointFormat: '{point.name}',
							useHTML: true,
							pointFormatter() {
								return this.tooltipHTML
							},
							style: {
								zIndex: 1000,
							},
							
							backgroundColor: 'red',
							linkFormat: `{point.toNode.linkMessage}`
							// linkFormat: `{point.fromNode.color} -> {point.toNode.linkMessage} -> <br /> {point.word}`
							
						},
						marker: {
							symbol: 'rect',
							width: this.headers.length > 3 ? '20%' : '25%'
						},
						borderRadius: 10,
						dataLabels: {
							
							// useHTML: true,
				// 			formatter() {
				// 				// let x = this;
				// 				// debugger;
				// 				if (this.point.node.level == 1) {
				// 					return `<div style="background-color: red; height: 100%; width: 100%"><div>${this.point.name}</div><br />
              // <div style="background-color: green">Sports Subscription</div></div>`
				// 				}
				//				
				// 				return this.point.name + 'hi'
				// 			},
							
							style: {
								whiteSpace: 'nowrap',
								textOutline: 'none'
							}
						},
						levels: levels,
					}
				],
				tooltip: {
					// backgroundColor: '#FCFFC5',
					// useHTML: true,
					style: {
						zIndex: 10000
					}
				},
				credits: {
					enabled: false
				}
			};
			
		}
	}
}
</script>

<style scoped>

</style>