<template>
    <div style="display: flex; align-items: center; justify-content: center">
        <div style="margin-right: 10px">Key</div>
        <div style="display: flex; align-items: center; justify-content: center; border: 1px solid var(--main-border-color); padding: 5px">
            <div style="display: flex; align-items: center;" v-for="(item, index) in items" :key="index">
                <div class="box" :style="{'background-color': item.color}">

                </div>
                <div class="label" :style="{fontSize: labelSize}">
                    {{ item.label }}
                </div>
            </div>
            <div class="box" style="border-radius: 10px; background-color: red; display: flex; justify-content: center; align-items: center">
<!--                <span style="color: white; font-size: 10px">1</span>-->
            </div>
            <div class="label" :style="{fontSize: labelSize}" style="margin-right: 10px">
                Errors
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "CuedStroopDetailsChartKey",
    props: {
        isPdf: {
            type: Boolean
        }
    },
    data() {
        return {
            items: [
                {
                    label: 'Word',
                    color: '#0000ff'
                },
                {
                    label: 'Color',
                    color: '#34d3fe'
                },
                
            ]
        }
    },
    computed: {
        labelSize() {
            return this.isPdf ? '12px' : '20px'
        }
    }
}
</script>

<style scoped>
.box {
    height: 15px;
    width: 15px;
    margin-left: 15px;
    margin-right: 5px;
}

.label {
    /*font-size: 12px*/
}
</style>