<template>
	<div>
		<div v-if="isDataLoaded" id="manage-billing-page">
			
<!--			<h2>Subscription for {{organizationName}}</h2>-->
<!--			<div>-->
<!--				<a href="#" @click="switchToPrevious" :style="isPreviousSubscription ? 'color: black' : ''">PREVIOUS</a> |-->
<!--				<a href="#" @click="switchToCurrent" :style="!isPreviousSubscription && !isUpcomingSubscription ? 'color: black' : ''">CURRENT</a> |-->
<!--				<a href="#" @click="switchToUpcoming" :style="isUpcomingSubscription ? 'color: black' : ''">UPCOMING</a>-->
<!--			</div>-->
<!--			-->
<!--			<br />-->
			<v-alert
				type="info"
				text
				v-if="billToOrgId"
				
			>
				<strong>Billing Parent Org: {{billToOrgName}} ({{billToOrgId}})</strong>
				<div style="font-size: .9rem">
					This organization has a Billing Parent Organization meaning it most likely <strong>does not</strong> need its own subscription.
				</div>
			</v-alert>
			
			
			<br />
			<AppCard hide-header >
				<div class="pa-4">
					<div style="display: flex">
						<h3>Sports Subscription</h3>
						<v-spacer></v-spacer>
						<AppButton @click="createSportsSubscriptionDialogOpen = true" icon="mdi-plus" color="green">Add Sports Subscription</AppButton>
					</div>
					<div v-if="allSportsSubscriptions.data.length < 1">There are currently no sports subscriptions for this organization.</div>
				</div>
				<div v-if="allSportsSubscriptions.data.length > 0">
					<app-tabs :value="currentSportsTab" underline :margin-bottom="false">
						<app-tab
							v-for="(tab, index) in sportsTabs"
							:key="index"
							@click="currentSportsTab = index"
							:icon="tab.icon"
						>{{ tab.label }}</app-tab>
					</app-tabs>
					<div >
						<div v-if="currentSportsTab === 0">
							<v-data-table
								:items="allSportsSubscriptions.data"
								:headers="allSportsSubscriptionsTableHeaders"
								show-expand
								id="sports-subscriptions-table"
								item-key="productSubscriptionId"
								
								sort-by="startDate.value"
								sort-desc
							>
								<template v-slot:expanded-item="{ headers, item }">
									<td :colspan="allSportsSubscriptionsTableHeaders.length + 1" style="background-color: #fafafa">
										<SportsSubscriptionsTableItemExpandSection :data="item"/>
									</td>
								</template>
								<template v-slot:item.productSubscriptionId="{item}">
									{{item.productSubscriptionId}}
									<AppChip v-if="item.isCurrent" color="green" small label class="ml-2" outlined>Current</AppChip>
									<AppChip v-if="item.isFuture" color="blue" small label class="ml-2" outlined>Future</AppChip>
									<AppChip v-if="item.issues.doesOverlap" color="red" small label class="ml-2">OVERLAP</AppChip>
								
								</template>
								<template v-slot:item.displayName="{item}">
									<AppChip :color="item.color.chip" small label>{{item.displayName}}</AppChip>
								
								</template>
								<template v-slot:item.actions="{ item }">
									<v-icon
										small
										class="mr-2"
										@click="editSportsSubscription(item)"
									>
										mdi-pencil
									</v-icon>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="deleteSubscription(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="createSubscriptionInvoice(item)"
                                    >
                                        mdi-invoice-text-plus
                                    </v-icon>
								</template>
							</v-data-table>
						</div>
						<div v-else>
							<highcharts :options="allSportsSubscriptions.subscriptionChart" ref="highchart" />
						</div>
					</div>
					
				</div>
				
			</AppCard>
			
			<AppCard hide-header class="mt-4" >
				<div class="pa-4">
					<div style="display: flex">
						<h3>Clinical Subscription</h3>
						<v-spacer></v-spacer>
						<AppButton
							@click="createClinicalSubscriptionDialogOpen = true" 
							icon="mdi-plus" 
							color="purple"
						>
							Add Clinical Subscription
						</AppButton>
						
					</div>
					<div v-if="allClinicalSubscriptions.data.length < 1">There are currently no clinical subscriptions for this organization.</div>
				</div>
				<div v-if="allClinicalSubscriptions.data.length > 0">
					<app-tabs :value="currentClinicalTab" underline :margin-bottom="false">
						<app-tab
							v-for="(tab, index) in sportsTabs"
							:key="index"
							@click="currentClinicalTab = index"
							:icon="tab.icon"
						>{{ tab.label }}</app-tab>
					</app-tabs>
					<div>
						<div v-if="currentClinicalTab === 0">
							<v-data-table
								:items="allClinicalSubscriptions.data"
								:headers="allClinicalSubscriptionsTableHeaders"
								show-expand
								id="clinical-subscriptions-table"
								item-key="productSubscriptionId"
								sort-by="startDate.value"
								sort-desc
							>
								<template v-slot:expanded-item="{ headers, item }">
									<td :colspan="allSportsSubscriptionsTableHeaders.length + 1" style="background-color: #fafafa">
										<SportsSubscriptionsTableItemExpandSection :data="item"/>
									</td>
								</template>
								<template v-slot:item.productSubscriptionId="{item}">
									{{item.productSubscriptionId}}
									<AppChip v-if="item.isCurrent" color="green" small label class="ml-2" outlined>Current</AppChip>
									<AppChip v-if="item.isFuture" color="blue" small label class="ml-2" outlined>Future</AppChip>
									<AppChip v-if="item.issues.doesOverlap" color="red" small label class="ml-2">OVERLAP</AppChip>
								
								
								</template>
								<template v-slot:item.displayName="{item}">
									<AppChip :color="item.color.chip" small label>{{item.displayName}}</AppChip>
								
								</template>
								<template v-slot:item.actions="{ item }">
									<v-icon
										small
										class="mr-2"
										@click="editClinicalSubscription(item)"
									>
										mdi-pencil
									</v-icon>
                                    <v-icon
                                        small
                                        class="mr-2"
                                        @click="deleteSubscription(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                    <v-icon
                                        small
                                        @click="createSubscriptionInvoice(item)"
                                    >
                                        mdi-invoice-text-plus
                                    </v-icon>
								</template>
							</v-data-table>
						</div>
						<div v-else>
							<highcharts :options="allClinicalSubscriptions.subscriptionChart" ref="highchart" />
						</div>
					</div>
				</div>
				
			</AppCard>
			
			<DialogForm
				title="Create Sports Subscription"
				:open="createSportsSubscriptionDialogOpen"
				@close="createSportsSubscriptionDialogOpen = false"
				@submit="() => updateProductSubscriptionById(newSportsSubscriptionForm)"
				:saving="savingSubscription"
			>
                <template v-slot:form-content>
                    <SportsSubscriptionCreateUpdateForm :form="newSportsSubscriptionForm" />
                </template>
				
			</DialogForm>
			
			<DialogForm
				title="Edit Sports Subscription"
				:open="editSportsSubscriptionDialogOpen"
				@close="editSportsSubscriptionDialogOpen = false"
				@submit="() => updateProductSubscriptionById(editSportsSubscriptionForm)"
				:saving="savingSubscription"
			>
				<template v-slot:form-content>
					<SportsSubscriptionCreateUpdateForm :form="editSportsSubscriptionForm" />
				</template>
			
			</DialogForm>
			
			<DialogForm
				title="Create Clinical Subscription"
				:open="createClinicalSubscriptionDialogOpen"
				@close="createClinicalSubscriptionDialogOpen = false"
				@submit="() => updateProductSubscriptionById(newClinicalSubscriptionForm)"
				:saving="savingSubscription"
			>
				<template v-slot:form-content>
					<ClinicalSubscriptionCreateUpdateForm :form="newClinicalSubscriptionForm" />
				</template>
			
			</DialogForm>
			
			<DialogForm
				title="Edit Clinical Subscription"
				:open="editClinicalSubscriptionDialogOpen"
				@close="editClinicalSubscriptionDialogOpen = false"
				@submit="() => updateProductSubscriptionById(editClinicalSubscriptionForm)"
				:saving="savingSubscription"
			>
				<template v-slot:form-content>
					<ClinicalSubscriptionCreateUpdateForm :form="editClinicalSubscriptionForm" />
				</template>
			
			</DialogForm>
            
			<br />
			
			<AppConfirmDialog 
				title="Delete Subscription" 
				:open="deleteSubscriptionDialogOpen"
				submit-button-title="Confirm"
				@confirm="confirmDeleteSubscription"
				@close="deleteSubscriptionDialogOpen = false"
				confirm-delete
				:loading="deletingSubscription"
			>
				<div>
					Are you sure you want to delete Subscription ID #{{subscriptionToDelete ? subscriptionToDelete.productSubscriptionId : null}}?
				</div>
			</AppConfirmDialog>

            <br />

            <AppConfirmDialog
                    title="Create Invoice From Subscription"
                    :open="createSubscriptionInvoiceDialogOpen"
                    submit-button-title="Confirm"
                    @confirm="confirmCreateSubscriptionInvoice"
                    @close="createSubscriptionInvoiceDialogOpen = false"
                    :loading="creatingSubscriptionInvoice"
                    style="width: 600px;"
            >
                <div>
                    Are you sure you want to create an invoice for Subscription #{{subscriptionInvoiceToCreate ? subscriptionInvoiceToCreate.productSubscriptionId : null}}?
                </div>
            </AppConfirmDialog>

        </div>
		<div v-else>
			<app-page-loader :text="'Loading'"></app-page-loader>
		</div>
	</div>

</template>

<script>
import {SET_SNACKBAR, SET_USER} from "@/store/mutation-types";
import httpService from "@/services/httpService";
import {baseUrl} from "@/config"
import 'moment-timezone'
import moment from "moment";
import {billingCycleTypes, clinicalPackageTiers, packageTiers} from "@/js/shared/constants";

import {createInvoiceFromSubscription, deleteProductSubscriptionById, getBillingInfo} from "@/services/billing.service";
import SportsSubscriptions from "@/js/shared/classes/Subscription/SportsSubscriptions";
import SportsSubscriptionsTableItemExpandSection
	from "@/components/OrgManagement/SportsSubscriptionsTableItemExpandSection";
import AppButton from "@/components/AppButton";
import SportsSubscriptionCreateUpdateForm
	from "@/components/OrgManagement/Subscription/SportsSubscriptionCreateUpdateForm";
import SportsSubscription from "@/js/shared/classes/Subscription/SportsSubscription";
import ClinicalSubscriptions from "@/js/shared/classes/Subscription/ClinicalSubscriptions";
import ClinicalSubscriptionCreateUpdateForm
	from "@/components/OrgManagement/Subscription/ClinicalSubscriptionCreateUpdateForm";
import ClinicalSubscription from "@/js/shared/classes/Subscription/ClinicalSubscription";
import {createOrUpdateProductSubscriptionById, getAllSubscriptions} from "@/services/billing.service";


export default {
	name: "SubscriptionGeneral",
	components: {
		ClinicalSubscriptionCreateUpdateForm,
		SportsSubscriptionCreateUpdateForm,
		AppButton,
		SportsSubscriptionsTableItemExpandSection
	},
	data() {
		return {
			model: {
				sports: {
					subscriptionStartDate: null,
					subscriptionEndDate: null,
					billingCycle: null,
                    profileCount: null,
					profilePrice: null,
					packageTier: null,
					systemFee: null,
					isManualBilling: null,
					isDemo: null,
                    productSubscriptionId: null,
                    isFree: null,
                    doNotRenew: null,
                    updateFutureSubscriptions: null
				},
				clinical: {
					subscriptionStartDate: null,
					subscriptionEndDate: null,
					billingCycle: null,
					profileCount: null,
					profilePrice: null,
					packageTier: null,
					systemFee: null,
					isManualBilling: null,
					isDemo: null,
                    productSubscriptionId: null,
                    isFree: null,
                    doNotRenew: null,
                    updateFutureSubscriptions: null
				}
			},
			
			sportsProfileTypes: packageTiers,
			clinicalProfileTypes: clinicalPackageTiers,
			
			organizationName: '',
			isUpcomingSubscription: false,
			isPreviousSubscription: false,
			
			sportsSubscriptionEnabled: false,
			clinicalSubscriptionEnabled: false,
			
			createSports: false,
			createClinical: false,
			
			isDataLoaded: false,
			isDeleteDialogOpen: false,
			isUsageDialogOpen: false,
			
			billToOrgId: null,
			billToOrgName: null,
			
			billingCycleTypes,
			// constant
			// billingCycleTypes: billingCycleTypes,
			// clinicalProfileTypes: clinicalPackageTiers,
			// sportsProfileTypes: packageTiers,
			
			createSportsSubscriptionDialogOpen: false,
			editSportsSubscriptionDialogOpen: false,
			deleteSubscriptionDialogOpen: false,
			
			savingSubscription: false,
			deletingSubscription: false,
			subscriptionToDelete: null,
			
			currentSportsTab: 0,
			currentClinicalTab: 0,
			
			createClinicalSubscriptionDialogOpen: false,
			editClinicalSubscriptionDialogOpen: false,

            creatingSubscriptionInvoice: false,
            subscriptionInvoiceToCreate: null,
            createSubscriptionInvoiceDialogOpen: false,
			
			newSportsSubscriptionForm: SportsSubscription.getNewSportsSubscriptionForm(),
			newClinicalSubscriptionForm: ClinicalSubscription.getNewClinicalSubscriptionForm(),
			editSportsSubscriptionForm: null,
			editClinicalSubscriptionForm: null,
			sportsTabs: [
				{
					label: 'Table',
					icon: 'mdi-format-list-bulleted'
				},
				{
					label: 'Chart',
					icon: 'mdi-chart-gantt'
				},
			
		
			],

			allSportsSubscriptions: [],
			allClinicalSubscriptions: [],
			allSportsSubscriptionsTableHeaders: [
				
				{
					text: 'ID',
					value: 'productSubscriptionId'
				},
				{
					text: 'Start Date',
					value: 'startDate.display'
				},
				{
					text: 'End Date',
					value: 'endDate.display',
				},
				{
					text: 'Name',
					value: 'displayName'
				},
				
				{
					text: 'Profile Count',
					value: 'totalProfileCount.display'
				},
				{
					text: 'Total',
					value: 'total.display'
				},
				{ 
					text: 'Actions', 
					value: 'actions', 
					sortable: false 
				},
			],
			
			allClinicalSubscriptionsTableHeaders: [
				
				{
					text: 'ID',
					value: 'productSubscriptionId'
				},
				{
					text: 'Start Date',
					value: 'startDate.display'
				},
				{
					text: 'End Date',
					value: 'endDate.display',
				},
				{
					text: 'Name',
					value: 'displayName'
				},
				{
					text: 'Total',
					value: 'total.display'
				},
				{
					text: 'Actions',
					value: 'actions',
					sortable: false
				},
			],
		}
	},
	mounted() {
		this.init()
	},
	watch: {
		sportsSubscriptionEnabled: function() {
			if (!this.sportsSubscriptionEnabled && this.model) {
				this.model.sports = null;
			}
			else if (this.model && !this.model.sports) {
				this.model.sports = {};
			}
		},
		clinicalSubscriptionEnabled: function () {
			if (!this.clinicalSubscriptionEnabled && this.model) {
				this.model.clinical = null;
			}
			else if (this.model && !this.model.clinical) {
				this.model.clinical = {};
			}
		}
	},
	methods: {
		init() {
			this.getBillingInfo();
			this.getDataFromApi();
		},
		async getDataFromApi() {

            this.isDataLoaded = false;
			
			// let query = 'isUpcomingSubscription=' + this.isUpcomingSubscription + '&isPreviousSubscription=' + this.isPreviousSubscription;
			// const endpoint = baseUrl + '/Billing/GetProductSubscription?' + query;
			// const response = await httpService.post(endpoint);
			
			// let query2 = 'isSports=' + true;
			// let endpoint2 = baseUrl + '/Billing/GetAllSubscriptions?' + query2;
			// let response2 = await httpService.post(endpoint2);
			
			let response2 = await getAllSubscriptions(true);
			
			
			let sportsSubscriptions = response2.productSubscriptions.map(x => {
				return x.model.sports
			})
			
			
			this.allSportsSubscriptions = new SportsSubscriptions(sportsSubscriptions);
			
			// let query3 = 'isSports=' + false;
			// let endpoint3 = baseUrl + '/Billing/GetAllSubscriptions?' + query3;
			// let response3 = await httpService.post(endpoint3);
			
			let response3 = await getAllSubscriptions(false)
			
			let clinicalSubscriptions = response3.productSubscriptions.map(x => {
				return x.model.clinical
			})
			
			this.allClinicalSubscriptions = new ClinicalSubscriptions(clinicalSubscriptions);
			
			// if (response.isSuccess) {
			// 	if (response.model.sports)
			// 		this.sportsSubscriptionEnabled = true;
			//	
			// 	if (response.model.clinical)
			// 		this.clinicalSubscriptionEnabled = true;
			//	
			// 	this.model = response.model;
			// 	this.organizationName = response.organizationName;
			// 	this.isUpcomingSubscription = response.isUpcomingSubscription;
			// 	this.isPreviousSubscription = response.isPreviousSubscription;
			// }
			
			this.isDataLoaded = true;
		},
		async getBillingInfo() {
			const response = await getBillingInfo();
			if (!response.isSuccess) {
				return
			}
			const {billToOrgId, billToOrgName} = response;
			this.billToOrgId = billToOrgId;
			this.billToOrgName = billToOrgName;
		},
		async updateProductSubscriptionById(form) {
			this.savingSubscription = true;
			let response = await createOrUpdateProductSubscriptionById(form);
            
			if (response.isSuccess) {
				this.editSportsSubscriptionDialogOpen = false;
				this.createSportsSubscriptionDialogOpen = false;
				this.editClinicalSubscriptionDialogOpen = false;
				this.createClinicalSubscriptionDialogOpen = false;
				this.init();
				this.$store.commit(SET_SNACKBAR, {
					text: 'Subscription Updated Created',
					color: 'success',
					open: true
				});
			}
			this.savingSubscription = false;
		},
		// async createSportsProductSubscription() {
		// 	this.savingSportsSubscription = true;
		// 	const {newSportsSubscriptionForm} = this;
		// 	let request = newSportsSubscriptionForm;
		// 	let response = await createOrUpdateProductSubscriptionById(request);
		// 	if (response.isSuccess) {
		// 		this.createSportsSubscriptionDialogOpen = false;
		// 		this.init()
		// 		this.$store.commit(SET_SNACKBAR, {
		// 			text: 'Subscription Successfully Created',
		// 			color: 'success',
		// 			open: true
		// 		});
		// 	};
		// 	this.savingSportsSubscription = false;
		// },
		formatDate(date) {
			return moment(date).format("M/D/YYYY")
		},
		// async switchToPrevious() {
		// 	this.isUpcomingSubscription = false;
		// 	this.isPreviousSubscription = true;
		// 	this.getDataFromApi();
		// },
		// async switchToCurrent() {
		// 	this.isUpcomingSubscription = false;
		// 	this.isPreviousSubscription = false;
		// 	this.getDataFromApi();
		// },
		// async switchToUpcoming() {
		// 	this.isUpcomingSubscription = true;
		// 	this.isPreviousSubscription = false;
		// 	this.getDataFromApi();
		// },
		editSportsSubscription(subscription) {
			this.editSportsSubscriptionDialogOpen = true;
			let x = subscription;
			this.editSportsSubscriptionForm = subscription.getSubscriptionForm()
		},
		
		editClinicalSubscription(subscription) {
			this.editClinicalSubscriptionDialogOpen = true;
			this.editClinicalSubscriptionForm = subscription.getSubscriptionForm()
		},
		deleteSubscription(subscription) {
			this.deleteSubscriptionDialogOpen = true;
			this.subscriptionToDelete = subscription;
		},
		async confirmDeleteSubscription() {
			this.deletingSubscription = true;
			let response = await deleteProductSubscriptionById(this.subscriptionToDelete?.productSubscriptionId);
			this.deleteSubscriptionDialogOpen = false;
			this.deletingSubscription = false;
			this.init();
			if (response.isSuccess) {
				this.$store.commit(SET_SNACKBAR, {
					text: 'Subscription Deleted',
					color: 'success',
					open: true
				});
			}
		},
        createSubscriptionInvoice(subscription) {
            this.createSubscriptionInvoiceDialogOpen = true;
            this.subscriptionInvoiceToCreate = subscription;
        },
        async confirmCreateSubscriptionInvoice() {
            this.creatingSubscriptionInvoice = true;
            let response = await createInvoiceFromSubscription(this.subscriptionInvoiceToCreate?.productSubscriptionId);
            this.createSubscriptionInvoiceDialogOpen = false;
            this.creatingSubscriptionInvoice = false;
            this.init();
            if (response.isSuccess) {
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Invoice Created',
                    color: 'success',
                    open: true
                });
            }
        }
	},
	computed: {
		canAutoCreateSports() {
			return this.sportsSubscriptionEnabled && this.model.sports && !this.model.sports.isManualBilling && !this.model.sports.isDemo && !this.model.sports.isFree;
		},
		canAutoCreateClinical(){
			return this.clinicalSubscriptionEnabled && this.model.clinical && !this.model.clinical.isManualBilling && !this.model.clinical.isDemo && !this.model.clinical.isFree;
		},
        sportsSubscriptionId(){
            if (this.model && this.model.sports && this.model.sports.productSubscriptionId)
                return "(#"+this.model.sports.productSubscriptionId+")"
            return "";
        },
        clinicalSubscriptionId(){
            if (this.model && this.model.clinical && this.model.clinical.productSubscriptionId)
                return "(#"+this.model.clinical.productSubscriptionId+")"
            return "";
        }
	}
}

</script>

<style scoped>

</style>

<!--
TODO: remove timestamp from date fields, maybe make date fields use calendar selectors
-->