<template>
    <tbody>

    <tr
        v-for="item in items"
        :key="item.name"
        class="table-row"
        @click="onRowClick(item)"
        
    >
        <td 
            class="table-cell"
            v-for="(value, key, index) in item" 
            v-if="showValue(key)"
            :style="{'border-right': key !== propertiesToShow[propertiesToShow.length - 1 ] ?  '1px solid lightgrey' : 'none'}"
        >
<!--            <v-chip v-if="headers[key].chip">{{ value }}</v-chip>-->
            <span >{{value}}</span>
        </td>
    </tr>
    </tbody>
</template>

<script>
export default {
    name: "TableBody",
    props: {
        items: {
            type: Array,
            required: true
        },
        headers: {
            type: Array,
            required: true
        }
    },
    methods: {
        onRowClick(item) {
            this.$emit('onRowClick', item)
        },
        showValue(key) {
            // there's got to be a quicker way to determine which fields to show
           return this.propertiesToShow.includes(key)
        },
        isChip() {
            return this.propertiesToShow.includes(key)
        },
        shouldShowRightBorder(index) {
            console.log(index)
        }
    },
    
    computed: {
        propertiesToShow() {
          return this.headers.map(header => header.value)  
        },
        propertiesAreChips() {
            let temp = [];
            this.headers.forEach(header => {
               if (header.chip) {
                   temp.push(header.value)
               }
            })
        }
    }
}
</script>

<style scoped>
.table-row:hover {
    background-color: var(--v-light-base)!important;
    cursor: pointer
}

.table-cell {
    border-bottom: 1px solid lightgrey;
}

</style>