<template>
    <div :class="classString" >
        
    </div>
</template>

<script>
export default {
    name: "TestTypeDot",
    props: {
        small: {
            type: Boolean
        },
        // isEvent: {
        //     type: Boolean,
        //     required: true
        // },
        hasDuplicateTests: {
            type: Boolean,
            required: true
        },
        isDotExcluded: {
            type: Boolean,
            // required: true
        },
	    isNonBaseline: {
		    type: Boolean,
		    required: true
	    },
    },
    computed: {
        classString() {
            let base = ''
            let size = this.small ? 'dot-small' : 'dot-normal'
            
            // if (this.isEvent) {
            //     // if (this.isDotExcluded) {
            //     //     base = 'event-excluded'
            //     // } else 
            //         if (this.hasDuplicateTests) {
            //         base = 'event-session'
            //     } else {
            //         base = 'event-test'
            //     }
            // } else {
            //     if (this.isDotExcluded) {
            //         base = 'baseline-excluded'
            //     } else if (this.hasDuplicateTests) {
            //         base = 'baseline-session'
            //     } else {
            //         base = 'baseline-test'
            //     }
			//
            // }
	
	        if (this.isNonBaseline) {
		        // if (this.isDotExcluded) {
		        //     base = 'event-excluded'
		        // } else 
		        if (this.hasDuplicateTests) {
			        base = 'assessment'
		        } else {
			        base = 'screening'
		        }
	        } else {
		        if (this.isDotExcluded) {
					if (this.hasDuplicateTests) base = 'baseline-assessment-excluded'
			        else base = 'baseline-screening-excluded'
		        } else if (this.hasDuplicateTests) {
			        base = 'baseline-assessment'
		        } else {
			        base = 'baseline-screening'
		        }
		
	        }
            
            return `${base} ${size}`
           
        }
    }
}
</script>

<style scoped>


</style>