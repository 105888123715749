import {getProfileTestPdfFooter, getProfileTestPdfHeader} from "@/js/utilities";
import moment from "moment";
import {createPdf} from "@/services/profile.service";
import {createDraftInvoicePdf} from "@/services/billing.service";

export default {
    methods: {
        async exportPdf({pdfPassword = null}) {
            this.exportingPdf = true;


            let documentCopy = document.cloneNode(true);
            let mainContent = documentCopy.getElementById("app");
            let newContent = documentCopy.getElementById("test-pdf")
            mainContent.parentNode.replaceChild(newContent, mainContent);

            let head = documentCopy.head;
            let link = documentCopy.createElement("link");

            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = `${window.location.origin}/pdf.css`;

            head.appendChild(link)

            let link2 = documentCopy.createElement("link");

            link2.type = "text/css";
            link2.rel = "stylesheet";
            link2.href = `${window.location.origin}/general.css`;

            head.appendChild(link2)

            console.log(documentCopy.documentElement.innerHTML)

            let htmlToSend = "<html>" + documentCopy.documentElement.innerHTML + "</html>";

            const headerHtmlString = getProfileTestPdfHeader(moment(this.profileTestForPdf.data.completedOn).format('M/D/YYYY'));
            const footerHtmlString = getProfileTestPdfFooter()

            let request = {
                id: this.simpleProfile.id,
                orgId: 1,
                htmLString: htmlToSend,
                pdfPassword: pdfPassword,
                footerHtmlString,
                headerHtmlString,
            };

            let result = await createPdf({
                request: request,
                firstName: this.firstName,
                lastName: this.lastName
            });
            this.exportingPdf = false;
        },
        
        async exportDraftInvoicePdf() {
            this.exportingPdf = true;


            let documentCopy = document.cloneNode(true);
            let mainContent = documentCopy.getElementById("app");
            let newContent = documentCopy.getElementById("invoice-pdf")
            mainContent.parentNode.replaceChild(newContent, mainContent);

            let head = documentCopy.head;
            let link = documentCopy.createElement("link");

            link.type = "text/css";
            link.rel = "stylesheet";
            link.href = `${window.location.origin}/pdf.css`;
            
            head.appendChild(link)

            let link2 = documentCopy.createElement("link");

            link2.type = "text/css";
            link2.rel = "stylesheet";
            link2.href = `${window.location.origin}/general.css`;

            head.appendChild(link2)

            console.log(documentCopy.documentElement.innerHTML)

            let htmlToSend = "<html><h1 style='text-align: center'>DRAFT</h1>" + documentCopy.documentElement.innerHTML + "</html>";

            let request = {
                id: 1,
                orgId: 1,
                fileName: "Draft Invoice",
                htmlString: htmlToSend,
            };

            let result = await createDraftInvoicePdf({
                request: request
            });
            this.exportingPdf = false;
        },
    }
}