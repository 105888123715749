<template>
    <app-card hide-header>
        <div class="">
            <v-data-table
                :headers="headers"
                :items="profiles"
                :items-per-page="tableSettings.rowsPerPage"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="loading"
                :page="tableSettings.pageNumber"
                :hide-default-footer="false"
                class="profiles-table"
                id="profiles-table"
                @click:row="onRowClick"
                @update:options="updateOptions"
                :search="tableSettings.searchString"
                
            >
                <template v-slot:top>

                        <div style="display: flex; border-bottom: 1px solid lightgrey">
                            <div class="pa-4" style="flex: 1">
                                <v-icon class="ml-2 mr-2">mdi-magnify</v-icon>
                                <input
                                    v-model="tableSettings.searchString"
                                    placeholder="Search Profiles"
                                    @input="updateSearchString"
                                >
                            </div>
	                        <div style="display: flex; align-items: center; justify-content: end; padding-right: 10px">
		                        <div @click="toggleOpenEventsOnly" 
		                             class="open-events-filter" 
		                             :style="{backgroundColor: openEventsOnly ? 'orange' : '#e0e0e0', color: openEventsOnly ? 'white' : '#757575'}"
		                        >
			                        <v-icon size="20" class="mr-1 clickable" :style="{color: openEventsOnly ? 'white' : '#757575'}">mdi-filter-variant</v-icon><span>Open Events</span>
		                        </div>
		                        
	                        </div>
                        </div>
	                
                </template>
                

                
<!--                <template v-slot:no-data="{items}">-->
<!--                    <div style="height: 200px; width: 200px; background-color: red">There are no profiles that match your search</div>-->
<!--                </template>-->
                <template #item.id="{item}">
                    <div style="display: flex; align-items: center">
                        <div>
                            {{item.id}}
                        </div>
                        <app-chip v-if="item.inEvent" class="ml-2" color="warning" label>Event</app-chip>

                    </div>
                </template>
                <template #item.inActiveGroup="{item}">
                    <div style="display: flex; align-items: center">
                        <div :style="{'background-color': item.inActiveGroup ? 'limegreen' : 'red'} " class="dot mr-2"></div>
                        <div>
                            {{item.inActiveGroup ? 'Active' : 'Inactive'}}
                        </div>

                    </div>
                </template>
                <template #item.averages="{item}">
                    <div v-if="loadingAverages">
                        <v-progress-circular
                            indeterminate
                            color="#E0E0E0"
                            :size="20"
                            :width="2"
                        ></v-progress-circular>
                    </div>
                    <div v-else style="display: flex; align-items: center">
                        <div v-if="item.averages" v-for="(average, key) in item.averages">
                            <v-tooltip top v-if="shouldShowAverage(key)" >
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        v-bind="attrs"
                                        v-on="on"
                                        style="height: 10px; width: 10px; border-radius: 10px; margin: 5px"
                                        :style="{backgroundColor: getCiColor(average.variabilityType)}"
                                    >

                                    </div>
                                </template>
                                <span>{{ getTestTypeName(key) }}</span>
                            </v-tooltip>
                        </div>
                        
                        
                        

                    </div>
                </template>
                <template #no-data>
                   <div style="margin: 100px 0">
<!--                       <v-icon size="150">mdi-card-account-details-outline</v-icon>-->
                       <div style="font-size: 1.5em">No profiles match your search.</div>
<!--                       <div style="font-size: 1.5em">Add a new profile to get started.</div>-->
                       
                       
                   </div>
                </template>
                
            </v-data-table>
        </div>



    </app-card>
</template>

<script>
import paginatedProfilesMixin from "@/mixins/paginatedProfilesMixin";
import {getCiColor} from "@/js/shared/helper";
import {allMeasuresFromConstants} from "@/js/shared/constants";

export default {
    name: "ProfilesTable",
    components: {

    },
    props: {
        groupId: {
            type: Number
        }
    },
    mixins: [paginatedProfilesMixin],
    data () {
        return {
	        openEventsOnly: false
        }
    },
	beforeMount() {
		this.openEventsOnly = this.$route?.query?.openEventsOnly === "true" ? true : false
	},
	mounted() {
		
	},
    computed: {
        headers() {
            if (!this.$store.state.currentAccount.viewingAsSuper) {
                return [
                    {
                        text: 'Id',
                        align: 'start',
                        sortable: true,
                        value: 'id',
                        class: 'th-border'
                    },
                    { text: 'Last Name', value: 'lastName', },
                    { text: 'First Name', value: 'firstName', },
                    { text: 'Graduation Year', value: 'graduationYear', },
                    { text: 'Last Test Date', value: 'lastTestDate', },
                    { text: 'Status', value: 'inActiveGroup', },
                    { text: 'Averages', value: 'averages', sortable: false },
                ]
            } else {
                return [
                    {
                        text: 'Id',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                        class: 'th-border'
                    },
                    // { text: 'Last Name', value: 'lastName', },
                    // { text: 'First Name', value: 'firstName', },
                    // { text: 'Graduation Year', value: 'graduationYear', },
                    { text: 'Last Test Date', value: 'lastTestDate', },
                    { text: 'Status', value: 'inActiveGroup', },
                    { text: 'Averages', value: 'averages', sortable: false},
                ]
            }
        }
    },
    methods: {
        getCiColor(variabilityType) {
            return variabilityType ? getCiColor(variabilityType) : 'lightgrey'
        },
        getTestTypeName(key) {
            return allMeasuresFromConstants[key]?.label ? allMeasuresFromConstants[key]?.label : '-'
        },
        shouldShowAverage(key) {
            return !allMeasuresFromConstants[key]?.hasNoBaseline
        },
	    toggleOpenEventsOnly() {
		    this.openEventsOnly = !this.openEventsOnly;
			this.updateOptions()
	    }
    }
    
    
    
}
</script>

<style scoped>
.dot {
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: red;
}
.profiles-table {
    /*color: var(--v-text-base)*/
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input {
    /*height: 40px*/
    width: 50%;
    /*height: 100%*/
}

.open-events-filter {
	padding: 5px 10px; 
	border-radius: 5px
}

.open-events-filter:hover {
	cursor: pointer
}

.table-row:hover {
    background-color: var(--v-accent-base)!important;
    cursor: pointer
}
.table-row {
    background-color: white;
}

.search-input-area {
    border-radius: 15px;
    /*background-color: grey;*/
    height: 0px
    
}

.no-data {
    color: var(--v-text-base);
    margin: 20px
}

</style>