<template>
    <div>
        <validation-observer
            ref="observer"
            v-slot="{ invalid }"
        >
            <v-form @submit.prevent="handleSubmit" >
                <slot :invalid="invalid"></slot>
                <div style="display: flex; justify-content: center" class="mt-2" v-if="useDefaultBtn">
                    <app-btn
                        :color="invalid ? '#757575' : 'secondary'"
                        type="submit"

                        class="mb-4"
                        :disabled="invalid"
                        :loading="saving"
                        color="primary"
                    >
                        {{ submitButtonTitle }}
                    </app-btn>
                    <app-btn v-if="cancelable" color="primary" class="ml-2" outlined @click="$emit('cancel')">Cancel</app-btn>
                </div>
                <!--                <v-card>-->
                <!--                    <v-card-title>-->
                <!--                        <h2 style="font-weight: bold">{{ title }}</h2>-->
                <!--                        <v-spacer></v-spacer>-->
                <!--                        <v-icon  @click="closeDialog" color="accent">mdi-close</v-icon>-->
                <!--                    </v-card-title>-->
                <!--                    <v-card-text  class="text&#45;&#45;text">-->
                <!--                        &lt;!&ndash;        <slot name="form-content"></slot>&ndash;&gt;-->
                <!--                        <v-container >-->

                <!--                            -->


                <!--                        </v-container>-->
                <!--                        &lt;!&ndash;          <small>*indicates required field</small>&ndash;&gt;-->
                <!--                    </v-card-text>-->
                <!--                    <v-card-actions>-->
                <!--                        <v-spacer></v-spacer>-->
                <!--                        -->
                <!--                        <v-spacer></v-spacer>-->
                <!--                    </v-card-actions>-->
                <!--                </v-card>-->
            </v-form>
        </validation-observer>
    </div>
    
        
</template>

<script>

import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

export default {
    props: {
        // onSubmit: {
        //     type: Function,
        // },
        submitButtonTitle: {
            type: String,
            default: 'Save'
        },
        saving: {
            type: Boolean
        },
        cancelable: {
            type: Boolean,
        },
        useDefaultBtn: {
            type: Boolean,
            default: true
        }
    },
    name: "AppForm",
    components: {
        ValidationObserver
    },
    data() {
        return {
            lastName: ''
        }
    },
    methods: {  
        handleSubmit() {
            this.$emit('submit');
        },
    }
}
</script>

<style scoped>
.v-card {
    border-radius: 0!important;
}
</style>