<template>
	<div>
		<app-tabs :value="currentTab">
			<app-tab
				v-for="(tab, index) in tabs"
				:key="index"
				@click="$router.push(tab.route)"
				:icon="tab.icon"
			>{{ tab.label }}</app-tab>
		</app-tabs>
		<router-view
			v-bind="$props"
		/>
	</div>
</template>

<script>

export default {
	name: "OrgConnectManagement",
	props: {
		accessParentOrgs: {
			required: true
		},
		accessChildOrgs: {
			required: true
		},
		billingInfo: {
			required: true
		},
		billingChildOrgs: {
			required: true
		},
		allSportsSubscriptions: {
			required: true
		},
		allClinicalSubscriptions: {
			required: true
		}
	},
	components: {
		
	},
	watch: {
		
	},
	data() {
		return {
			tabs: [
				{
					label: 'Overview',
					route: `/org-management/connections`,
					icon: 'mdi-vector-line'
				},
				{
					label: 'Access',
					route: `/org-management/connections/access`,
					icon: 'mdi-magnify'
				},
				{
					label: 'Billing',
					route: `/org-management/connections/billing`,
					icon: 'mdi-currency-usd'
				},
			
			],
			
			
		}
	},
	computed: {
		currentTab() {
			let items = this.$route.path.split('/')
			let last = items.pop();
			
			if (items[2] === "reporting") return 4
			
			switch (last) {
				
				case 'overview':
					return 0
				case 'access':
					return 1
				case 'billing':
					return 2

				default:
					return 0
			}
			
		}	
	},
	methods() {
		
	}
	
}
</script>

<style scoped>


thead tr th {
	padding: 0 16px;
	font-size: 16px;
	height: 48px;
}

</style>