<template>
    <app-card class="pa-4" hide-header>
        <div style="display: flex; justify-content: flex-start">
            <div style="flex: 1">
                <img :src="item.logo" alt="" style="width: 25px; " >
            </div>
            <!--                                    <div v-if="updatingPage">-->
            <!--                                        <v-progress-circular indeterminate :size="25"></v-progress-circular>-->
            <!--                                    </div>-->
        </div>

        <!--                            <div class="text-center">{{ item.label }}</div>-->
        <div v-for="(stat, index) in item.value" style="">
            <div v-if="index === 0" style="display: flex; flex-direction: column; align-items: center" class="mb-2">
                <h1 :style="{'color': isReadyCheck ? '#65CDBE' : '#34d3fe'}"><CountUp :value="stat.value"/></h1>
                <span style="color: #757575">{{stat.label}}</span>
                <v-divider class="mt-2 mb-2" ></v-divider>
            </div>
            <div v-else style="display: flex; align-items: center; width: 100%">
                <span style="font-weight: bold; font-size: 1.2em" class="mr-1"><CountUp :value="stat.value"/></span><span style="color: #757575">{{ stat.label }} </span>
            </div>

        </div>

    </app-card>
</template>

<script>
import CountUp from "@/components/CountUp";
export default {
    
    name: "StatCard",
    components: {
        CountUp
    },
    props: {
        item: {
            required: true
        },
        isReadyCheck: {
            type: Boolean
        }
    },
    methods: {
        getCommaSeparatedNumber(value) {
            if (typeof value === 'number') return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            else return 'N/A'

        }
    }
}
</script>

<style scoped>

</style>