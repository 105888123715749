import httpService from "@/services/httpService";
import {baseUrl} from "@/config"

const endpoint = baseUrl + "/Profile"

const getProfile = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetProfile', {id})
    } catch (e) {
    }
}

const deleteProfile = async (id) => {
    try {
        return await httpService.post(endpoint + '/DeleteProfile', {id})
    } catch (e) {
    }
}

const createProfile = async (profileModel) => {
    try {
        return await httpService.post(endpoint + '/CreateProfile', {...profileModel.profile})
    } catch (e) {
    }
}

const updateProfile = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateProfile', request)
    } catch (e) {
    }
}

const updateProfileTestNote = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateProfileTestNote', request)
    } catch (e) {
    }
}


const endCurrentProfileEvent = async (request) => {
    try {
        return await httpService.post(endpoint + '/EndCurrentProfileEvent', request)
    } catch (e) {
    }
}

const profileTestPdfForPortal = async (request) => {
    try {
        // let response = await httpService.post(endpoint + '/ProfileTestPdfForPortal', request)
        let response = await httpService.post(endpoint + '/ProfileTestPdfForPortal', request,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer'
        })
        
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        let documentName = `Results.pdf`

        link.setAttribute('download', documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
        return response
    } catch (e) {
    }
}

const profileModel = {
    firstName: "string",
    lastName: "string",
    birthDate: "2021-01-11T22:00:46.667Z",
    gender: "M",
    height: 70,
    weight: 0,
    hasInjuryHistory: true,
    baselineStartDate: "2021-01-11T22:00:46.667Z",
    graduationYear: 0,
    nickname: "Petti",
    defaultOrganizationProtocolId: 0,
    groupIds: [
        0
    ]

};

const updateProfileModel = {
    id: 0,
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "",
    height: 0,
    weight: 0,
    hasInjuryHistory: true,
    baselineStartDate: "",
    graduationYear: 0,
    nickname: "",
    defaultOrganizationProtocolId: 0,
    updateGroups: true,
    groupIds: [
        0
    ],
    email: "string"
}


const getTestRawData = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetTestRawData', {id})
    } catch (e) {
    }
}

const getDetailedTestCalculationGraphs = async (profileTestId, profileTestResultId) => {
    try {
        return await httpService.post(endpoint + '/GetDetailedTestCalculationGraphs', {profileTestId, profileTestResultId, isPublic: false})
    } catch (e) {
    }
}

const getPublicTestCalculationGraphs = async (profileTestId, profileTestResultId) => {
    try {
        return await httpService.post(endpoint + '/GetDetailedTestCalculationGraphs', {profileTestId, profileTestResultId, isPublic: true})
    } catch (e) {
    }
}

const getWebProfileDetail = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetWebProfileDetail', {id})
    } catch (e) {
    }
}

const getProfileAverages = async (ids) => {
    try {
        return await httpService.post(endpoint + '/GetProfileAverages', {ids})
    } catch (e) {
    }
}

const getProfileTestById = async (request) => {
    try {
        return await httpService.post(endpoint + '/GetProfileTestById', request)
    } catch (e) {
    }
}

const getProfilesPaged = async (request) => {
    // 

    try {
        // let token = sessionStorage.getItem("jwtToken");
        // httpService.setAuthTokenHeader(token);

        return await httpService.post(endpoint + '/GetProfilesPaged', request)
    } catch {

    }

}

const adjustBaselineCalculationDate = async (request) => {
    try {

        return await httpService.post(endpoint + '/AdjustBaselineCalculationDate', request)
    } catch {

    }

}

const getProfileTestForAzureAD = async (id, authToken) => {
    try {
        return await httpService.post(endpoint + '/getProfileTestForAzureAD', {id}, {
            headers: {
                'Authorization': "Bearer " + authToken
            }
        })
    } catch {

    }
}

const getProfileTestForPortal = async (id, authToken) => {
    try {
        return await httpService.post(endpoint + '/getProfileTestForPortal', {id}, {
            headers: {
                'Authorization': "Bearer " + authToken
            }
        })
    } catch {

    }
}

const generateProfileAccessLink = async (profileId, sendToEmail) => {
    try {
        return await httpService.post(endpoint + '/GenerateProfileAccessLink', {profileId, sendToEmail}, {

        })
    } catch {

    }
}

const validateProfileAccessLinkUniqueId = async (uniqueId) => {
    try {
        return await httpService.post(endpoint + '/ValidateProfileAccessLinkUniqueId', {uniqueId}, {

        })
    } catch {

    }
}

const getAccountProfilesByProfileId = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetAccountProfilesByProfileId', {id} )
    } catch (e) {
    }
}

const getProfileAccessLinksByProfileId = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetProfileAccessLinksByProfileId', {id} )
    } catch (e) {
    }
}

const submitProfileAccessLink = async (uniqueId, firstName, lastName, birthDate, groupIdsToAdd, defaultOrganizationProtocolId, relationshipTypeId) => {
    try {
        return await httpService.post(endpoint + '/SubmitProfileAccessLink', {uniqueId, firstName, lastName, birthDate, groupIdsToAdd, defaultOrganizationProtocolId, relationshipTypeId}, {

        })
    } catch {

    }
}

// const getProfileTestForAzureAD = async (id) => {
//     try {
//         return await httpService.post(endpoint + '/getProfileTestForAzureAD', {id})
//     } catch {
//
//     }
// }

const createPdf = async ({request, firstName, lastName}) => {
    
    try {
        let response = await httpService.post(endpoint + '/NewPdfReport', request, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer'
        })

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            let documentName = `${firstName}_${lastName}_Results.pdf`
            
            link.setAttribute('download', documentName); //or any other extension
            document.body.appendChild(link);
            link.click();
            return response
        
           
    } catch(error) {
        console.log(error)
    }
}

const excludeIncludeTestResults = async (request) => {
    try {
        return await httpService.post(endpoint + '/ExcludeIncludeTestResults', request, {

        })
    } catch {

    }
}

const updateProfileEvent = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateProfileEvent', request, {

        })
    } catch {

    }
}

const createProfileEvent = async (request) => {
    try {
        return await httpService.post(endpoint + '/CreateProfileEvent', request, {

        })
    } catch {

    }
}

const deleteProfileEvent = async (request) => {
    try {
        return await httpService.post(endpoint + '/DeleteProfileEvent', request, {

        })
    } catch {

    }
}

const getPdfSyncStatus = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetPdfSyncStatus', {id: id}, {

        })
    } catch {

    }
}

const retryPdfSync = async (id) => {
    try {
        return await httpService.post(endpoint + '/RetryPdfSync', {id: id}, {

        })
    } catch {

    }
}

const updatePdfSyncStatus = async (id, pdfSyncStatus) => {
    try {
        return await httpService.post(endpoint + '/UpdatePdfSyncStatus', {pdfSyncIntentId: id, intentStatusId: pdfSyncStatus}, {

        })
    } catch {

    }
}



export {
    getProfile,
    getWebProfileDetail,
    getProfilesPaged,
    profileModel,
    updateProfileModel,
    createProfile,
    deleteProfile,
    updateProfile,
    createPdf,
    getProfileTestById,
    adjustBaselineCalculationDate,
    getProfileTestForAzureAD,
    getProfileAverages,
    profileTestPdfForPortal,
    getProfileTestForPortal,
    endCurrentProfileEvent,
    getTestRawData,
    excludeIncludeTestResults,
    validateProfileAccessLinkUniqueId,
    submitProfileAccessLink,
    generateProfileAccessLink,
    getDetailedTestCalculationGraphs,
    getPublicTestCalculationGraphs,
    getAccountProfilesByProfileId,
    getProfileAccessLinksByProfileId,
    updateProfileTestNote,
    updateProfileEvent,
    createProfileEvent,
    deleteProfileEvent,
    getPdfSyncStatus,
    updatePdfSyncStatus,
    retryPdfSync
}

