<template>
    <div v-if="chartOptions" :class="{'pdf-excluded-section': measureResult.isExcluded}" >
	    <TestCard 
		    :title="name"
		    :testCategory="measureResult.category"
	    >
		    <template>
			    <div>
				    <div style="display: flex; align-items: center">
					    <div style="position: relative; transform: translateY(8px); flex: 5" >
						    <highcharts :options="chartOptions" ref="highchart" />
						    <ChartIndicator :label="displayScore" :xPosition="indicatorXPosition" />
					
					    </div>
					    <div style="display: flex;  background-color: transparent; flex: 1" >
						
						    <div style="padding: 10px; display: flex; justify-content: center; align-items: center; flex-direction: column; flex: 1" >
							    <div style=" text-align: center; display: flex; justify-content: center;  background-color: transparent; font-size: 16px" >
								    {{results.percentileNumber}}<div style="font-size: 10px;text-transform: uppercase">{{ ordinalSuffix }}</div>
							    </div>
							    <div style=" text-transform: uppercase; font-size: 12px; background-color: transparent; text-align: center" >
								    {{results.percentileDescription ? results.percentileDescription : '-'}}
							    </div>
						    </div>
					
					
					
					    </div>
				    </div>
				    
				
				                
				
				    <NewIndividualTestCardSessionResults
					    :individualMeasure="measureResult"
					    :editMode="editMode"
					    :profileTest="profileTest"
					    @excludeIncludeTestResults="excludeIncludeTestResults"
                        @openCobaltGraphs="openCobaltGraphs"
				    />
			    </div>
			    
		    </template>
		    
		    <template #header-right>
			    <div style="color: #414D55; flex: 1">
				    <div v-if="measureResult.fallRiskTypeId === 1">
					    <FallRiskTag />
				    </div>
			    </div>

                <div style="" v-if="measureResult && measureResult.languageCode">
                    <span style="float: right; font-size:16px; padding-right:16px">
                        <v-icon style="font-size:20px; color:black">mdi-translate-variant</v-icon>{{measureResult.languageCode}}
                    </span>
                </div>
                
			    <div
				    v-if="profileTest.data.isEvent"
				    style="font-weight: 600; margin-right: 20px"
			    >
				    <MeasureChangeChip :change-data="measureResult.changeData" />
			    </div>
		    </template>
	    </TestCard>
<!--        <div style="display: flex; align-items: center">-->

<!--            <div style="flex: 4" >-->

<!--                <div style="position: relative; transform: translateY(8px)" >-->
<!--                    <highcharts :options="chartOptions" ref="highchart" />-->
<!--                    <ChartIndicator :label="displayScore" :xPosition="indicatorXPosition" />-->
<!--                    -->
<!--                </div>-->
<!--	            -->
<!--	            -->

<!--            </div>-->

<!--            <div style="flex: 1; display: flex;  background-color: transparent">-->

<!--                    <div style="padding: 10px; display: flex; justify-content: center; align-items: center; flex-direction: column; flex: 1" >-->
<!--                        <div style=" text-align: center; display: flex; justify-content: center;  background-color: transparent; font-size: 16px" >-->
<!--                            {{results.percentileNumber}}<div style="font-size: 10px;text-transform: uppercase">{{ ordinalSuffix }}</div>-->
<!--                        </div>-->
<!--                        <div style=" text-transform: uppercase; font-size: 12px; background-color: transparent; text-align: center" >-->
<!--                            {{results.percentileDescription ? results.percentileDescription : '-'}}-->
<!--                        </div>-->
<!--                    </div>-->


<!--                -->
<!--            </div>-->
<!--        </div>-->
<!--        <div v-if="showDropdown && allDetailRows.length > 1">-->
<!--            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column">-->
<!--                <div @click="open = !open" ref="expandChevron">-->
<!--                    <v-icon >mdi-chevron-down</v-icon>-->
<!--                </div>-->
<!--                <v-expand-transition>-->
<!--                    <div v-if="open" >-->

<!--                        <TestDetailTable -->
<!--	                        :measure-results="measureResult" -->
<!--	                        :all-detail-rows="allDetailRows"-->
<!--	                        :editMode="editMode"-->
<!--                        />-->

<!--                    </div>-->
<!--                    -->
<!--                </v-expand-transition>-->
<!--            </div>-->
<!--        </div>-->
	    
        


    </div>
</template>

<script>
import ProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
// import Highcharts from "highcharts";
// import Annotations from 'highcharts/modules/annotations'
// import VueHighcharts from 'vue-highcharts'
// import More from 'highcharts/highcharts-more';
// import Vue from "vue";
import ChartIndicator from "@/components/ChartIndicator";
import gsap from "gsap";
// More(Highcharts);
// Annotations(Highcharts)
// Vue.use(VueHighcharts)
import {getTestChangeColor, getOrdinalSuffix} from "@/js/shared/helper";
import TestDetailTable from "@/components/pdf-export/components/TestDetailTable";
import FallRiskTag from "@/components/pdf-export/components/FallRiskTag";
import MeasureChangeChip from "@/components/pdf-export/components/MeasureChangeChip";
import NewIndividualTestCardSessionResults from "@/components/Profile/TestsViewer/NewIndividualTestCardSessionResults";
import TestCard from "@/components/Profile/TestsViewer/TestCard";

export default {
    name: "TestResultsCard",
    components: {
	    TestCard,
	    NewIndividualTestCardSessionResults,
	    MeasureChangeChip,
        FallRiskTag,
        TestDetailTable,
        ChartIndicator,
    },
    props: {
        profileTest: ProfileTest,
        id: String,
        name: String,
        measureResult: {
            
        },
        showDropdown: {
            type: Boolean,
            default: false
        },
	    editMode: {
		    type: Boolean
	    }
    },
    data() {
        return {
            // uniqueChartIdentifier: Date.now() + Math.random(),
            // stats: [],
            // chartOptions: null,
            indicatorXPosition: 40,
            // displayScore: null,
            chartOptions: null,
            open: false
        }
    },
    mounted() {
        let x = typeof this.profileTest;
		if (this.editMode) this.open = true
        this.setChartOptions()

    },
    watch: {
        profileTest() {
            this.setChartOptions()
        },
        open() {
            if (!this.open) {
                gsap.to(this.$refs.expandChevron, {
                    rotationZ: 0,
                    duration: .2
                })
            } else {
                gsap.to(this.$refs.expandChevron, {
                    rotationZ: 180,
                    duration: .2

                })
            }
        },
	    editMode() {
		    if (this.editMode) this.open = true
	    }
    },
    computed: {
        // chartOptions() {
        //     
        //    
        // },
        
        displayScore() {
            if (!this.results) return null
            return this.results.score.toFixed(0);
        },
        stats() {
            let results = this.profileTest.formattedTestsData[this.id];
            // 

            

            return [
                {
                    label: 'Percentile',
                    value: typeof results.percentileNumber === 'number' ? results.percentileNumber + ordinalSuffix : '-'
                },
                {
                    label: 'Rank',
                    value: results.percentileDescription ? results.percentileDescription : '-'
                }
            ]
        },
        
        percentChangeClass() {
            if (typeof this.measureResult.percentChange !== 'number') return null
            return getTestChangeColor({percentChange: this.measureResult.percentChange})
        },
        ordinalSuffix() {
            const ordinalSuffix = getOrdinalSuffix(this.results.percentileNumber);
            return ordinalSuffix
        },
        results() {
            return this.profileTest.formattedTestsData[this.id]
        },
        allDetailRows() {
            let response = [];
            this.measureResult.results.forEach(result => {
                result.detailRows.forEach(row => response.push(row))
            })
            return response
        }
    },
    methods: {
	    excludeIncludeTestResults(request) {
		    this.$emit('excludeIncludeTestResults', request)
	    },
         getAllDetailRows(measureResults) {
            let response = [];
            measureResults.results.forEach(result => {
                result.detailRows.forEach(row => response.push(row))
            })
            return response
        },
        setChartOptions() {
            if (!this.profileTest) return null;
            
            this.chartOptions = this.profileTest.getMeasureColumnRangeChartOptionsById({
                id: this.id,
                disableAnimation: true,
                resizeCallback: (indicatorXPosition) => {
                    this.indicatorXPosition = indicatorXPosition;
                }});
        },
        openCobaltGraphs(resultId){
            this.$emit('openCobaltGraphs', resultId)
        }
    }
}
</script>

<style scoped>



</style>