<template>
    <div>
        <AppInput
            :name="errorLabel ? errorLabel : label"
            :rules="rules"
            :label="label"
            v-slot="{errors, valid}"
            :row="row"
        >
            <v-select
                :value="value"
                :items="items"
                required
                :outlined="outlined"
                dense
                color="text"
                @change="updateSelect"
                :item-text="itemText"
                :item-value="itemValue"
                :menu-props="{ offsetY: true, dense: false }"
                :error-messages="errors.length > 0 ? [''] : []"
                :disabled="disabled"
                :placeholder="placeholder"
                :color="color"
            >
	            <template v-slot:item="{item, index, parent}" >
		            <v-list-item-content style="max-width: 500px">
			            <div :style="{marginTop: item.description && showDescription ? '5px' : null, marginBottom: item.description && showDescription ? '5px' : null}">
				            <v-list-item-title :style="{fontWeight: item.description && showDescription ? 'bold' : null}">{{item[itemText] ? item[itemText] : item.label}} {{item.parent}}</v-list-item-title>
				            <div v-if="item.description && showDescription" v-html="item.description" style="margin-top: 5px; font-size: .8rem; color: #757575;">
				
				            </div>
			            </div>
		            </v-list-item-content>
<!--		            <v-list-item-content style="width: 200px">-->
<!--			            <v-list-item-title>{{item.label}}</v-list-item-title>-->
<!--			            <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>-->
<!--		            </v-list-item-content>-->
		            
		            
	            </template>
            </v-select>
        </AppInput>
<!--        <validation-provider-->
<!--            v-slot="{ errors }"-->
<!--            :name="errorLabel ? errorLabel : name"-->
<!--            :rules="rules"-->
<!--        >-->
<!--            <span style="color: #757575">{{label}}</span> <span v-if="errors" class="error&#45;&#45;text text-caption">{{errors[0]}}</span>-->
<!--        -->
<!--            -->
<!--&lt;!&ndash;            <div v-if="errors" class="error&#45;&#45;text text-caption">{{errors[0]}}</div>&ndash;&gt;-->
<!--        </validation-provider>-->
    </div>
<!--    <v-row>-->
<!--        <v-col cols="3" style="display: flex; align-items: center">-->
<!--            {{ label }}-->
<!--        </v-col>-->
<!--        <v-col cols="9">-->
<!--            <validation-provider-->
<!--                v-slot="{ errors }"-->
<!--                :name="errorLabel ? errorLabel : label"-->
<!--                :rules="rules"-->
<!--            >-->
<!--            <v-select-->
<!--                :value="value"-->
<!--                :items="items"-->
<!--                required-->
<!--                outlined-->
<!--                dense-->
<!--                color="text"-->
<!--                @change="updateSelect"-->
<!--                :item-text="itemText"-->
<!--                :item-value="itemValue"-->
<!--                :menu-props="{ offsetY: true }"-->
<!--                :error-messages="errors.length > 0 ? [''] : []"-->
<!--            ></v-select>-->
<!--                <div v-if="errors" class="error&#45;&#45;text text-caption">{{errors[0]}}</div>-->
<!--            </validation-provider>-->
<!--        </v-col>-->
<!--    </v-row>-->
</template>

<script>
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {required} from "vee-validate/dist/rules";
import AppInput from "@/components/forms/AppInput";

setInteractionMode('eager')

extend('required', {
    ...required,
    // message: '{_field_} can not be empty',
    message: 'required',
})

export default {
    components: {
        AppInput,
        ValidationObserver,
        ValidationProvider
    },
    props: {
        value: {
            required: true,
        },
        label: {
            required: false,
            type: String
        },
        items: {
            required: true,
            type: Array
        },
        itemText: {
            required: false,
            type: String,
            default: 'text',
        },
        itemValue: {
            required: false,
            type: String,
            default: 'value'
        },
        rules: {

        },
        errorLabel: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            default: ''
        },
        outlined: {
            default: true
        },
        color: {
            type: String
        },
        row: {
            type: Boolean
        },
	    showDescription: {
		    type: Boolean,
			default: false
	    }
    },
    name: "AppSelect",
    methods: {
        updateSelect(e) {
            this.$emit('input', e)
        }
    }
}
</script>

<style scoped>

</style>