import axios from "axios";
import {logout, switchSelectedOrganization} from "@/services/userService";
import {handleExpectedApiError, handleUnexpectedApiError} from "@/services/serviceHelper";
import router from "@/router";
import store from '../store'
import {SET_MOBILEAPPVERSION} from "@/store/mutation-types";
import {guessCurrentTimezone} from "@/js/shared/helper";

let instance = axios.create({
})

const onFulfilled = (success) => {
    // let temp = success;
    
    // router.push('/login')
    if (success && success.headers && success.headers['x-latest-mobile-app-version']) {
        store.commit(SET_MOBILEAPPVERSION, success.headers['x-latest-mobile-app-version']);
    }

    // saml methods sometimes return 302. leaving it like this till I know if it auto redirect or not
    if (success.status == 302)
        return success;
    
    // The PDF APIs do not return an "isSuccess" datapoint
    if (!('isSuccess' in success.data)) return success.data
    
    if (!success.data.isSuccess) {
        return handleExpectedApiError(success.data)
    }
    return success.data
}

//add request interceptor to make sure current OrgId matches the one in the token
instance.interceptors.request.use((config) => {

    config.headers['ClientTimeZone'] = guessCurrentTimezone();

    let lowercasedUrl = config.url.toLowerCase();
    
    if (lowercasedUrl.endsWith('api/account/switchselectedorganization')) return config;

    if (lowercasedUrl.endsWith('api/account/requestmfacode')) return config;

    if (lowercasedUrl.endsWith('api/account/verifymfacode')) return config;
    
    //javascript for params, stackoverflow: https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript 
    //get orgId from query string
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    let orgId = params.orgId; 
    
    if (!orgId) return config;
    
    //get selectedOrganizationId from user object
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) return config;
    
    if (!user.selectedOrganization.id) return config;
    
    //if orgId in query string doesn't match selectedOrganizationId, switch selectedOrganizationId
    if (orgId != user.selectedOrganization.id) {
        switchSelectedOrganization(orgId).then((response) => {
            
            //just reload the page
            location.reload();
        });
    }
    
    return config;
});

instance.interceptors.response.use(onFulfilled, error => {
    const expectedError = 
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
    
    if (error.response.status === 401) {
        logout()
    }
    
    if (!expectedError) {
        console.log("Logging the error", error);
        // 
        return handleUnexpectedApiError(error.response)
    } else {
        return handleExpectedApiError(error.response)
    }
    
    return Promise.reject(error)
});

// instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('jwtToken')
instance.defaults.headers.common['Selected-Organization-Id'] = localStorage.getItem('selectedOrganizationId')
instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
instance.defaults.headers.common['Referrer-Policy'] = 'no-referrer';

const setAuthTokenHeader = (token) => {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

const removeAuthTokenHeader = (token) => {
    delete instance.defaults.headers.common["Authorization"];
}

const setSelectedOrgId = (orgId) => {
    //debugger;
    instance.defaults.headers.common['Selected-Organization-Id'] = orgId;
}

export default {
    get: instance.get,
    post: instance.post,
    put: instance.put,
    delete: instance.delete,
    removeAuthTokenHeader,
    setAuthTokenHeader,
    setSelectedOrgId
}