<template>
    <div class="login" >

        <div style="flex: 1;  width: 100%; padding: 20px; display: flex" v-if="!isMobileSso">
            <div style="flex: 1">
                <a href="/"><img :src="swayLogo" alt="" style="height: 50px; "></a>
            </div>
            <div v-if="!hideContact" style="font-weight: 600; font-size: 14px; color: var(--v-text-lighten2)">
                <div style="display: flex; align-items: center">
                    New to Sway?
                    <div
                            class="contact-button"
                            @click="getInTouch"
                    >
                        Get In Touch
                    </div>
                </div>
            </div>


        </div>
        <div v-else style="flex: 1;  width: 100%; padding: 20px; display: flex">
            
        </div>
        <slot>

        </slot>
        <div style="flex: 1; width: 100%; display: flex; align-items: flex-end" v-if="!isMobileSso">
            <div class="login-footer">
                <div style="flex: 1; color: var(--v-text-lighten2)">
                    &#169; {{ currentYear }} Sway Medical
                </div>
                <div style="display: flex">
                    <div v-for="(link, index) in links" :key="index" class="login-link" @click="goToLink(link)">
                        {{link.label}}
                    </div>
                </div>
            </div>

        </div>








    </div>
</template>

<script>

import swagLoginGraphic from "@/assets/images/login-graphic.png"
import swayLogo from '@/assets/svgs/sway-logo.svg'
import moment from "moment";

export default {
    name: "LoginFrame",
    props: {
        hideContact: {
            type: Boolean
        },
        isMobileSso: {
            type: Boolean
        }
    },
    data() {
        return {

            swayLoginGraphic: swagLoginGraphic,

            swayLogo,
            links: [
                {
                    label: 'Terms',
                    url: 'https://www.swaymedical.com/terms'
                },
                {
                    label: 'Privacy',
                    url: 'https://www.swaymedical.com/privacy'
                },
                // {
                //     label: 'Security'
                // },
                {
                    label: 'Get in Touch',
                    url: 'https://www.swaymedical.com/contact'
                },

            ]
        }
    },
    computed: {
        currentProduct() {
            return this.$store.state.currentProduct
        },
        currentYear() {
            return moment().year()
        }
    },
    methods: {
        getInTouch() {
            window.open('https://www.swaymedical.com/contact', '_blank');
        },
        goToLink(link) {
            window.open(link.url, '_blank');
        },
    }
}
</script>

<style scoped>


.login {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    background-color: white;
    height: 100vh
}

.login-footer {
    width: 100%;
    padding: 30px;
    border-top: 1px solid var(--v-border-base);
    display: flex
}

.contact-button {
    border: 1px solid var(--v-border-base);
    padding: 5px 15px;
    border-radius: 5px;
    margin-left: 10px;
    color: var(--v-text-base)
}

.contact-button:hover {
    cursor: pointer
}

.login-link {
    margin-left: 15px;
    color: #2196f3
}

.login-link:hover {
    cursor: pointer
}

.hover {
    cursor: pointer
}

</style>