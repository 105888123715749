<template>
    <div>
        <div v-if="isShowing3dPopup">
            <app-btn @click="isShowing3dPopup = false">Return To Graphs</app-btn>
            <InternalGraphPopup3d :chartData="selected3dChart" />
        </div>
        <div v-else>

            <div v-if="charts || scores || charts3d || errorMessage">
                <div style="margin-bottom: 20px;border-color: black;border-bottom-style: solid;">
                    <div style="float: left;width: 20px;" v-if="resultIteration > 0"><a @click="showPrev">&#8592;</a></div>
                    <div style="float: right;width: 20px;" v-if="resultIteration < resultLength-1"><a @click="showNext">&#8594;</a></div>
                    <div style="margin: auto;width: 80%;text-align: center;font-weight: bold;">{{testTitle}}</div>
                </div>
                <div v-if="errorMessage" style="color: red; font-size: 16px; font-weight:bold; text-align: center; padding:10px">{{errorMessage}}</div>
                <div v-if="charts"><a @click="downloadXyz" style="margin: auto">Download XYZ</a></div>
                <div v-if="scores">
                    <div style="text-align: center; font-size: 18px">Scores</div>
                    <table class="scores-table">
                        <tr v-for="(score, index) in scores" :key="index">
                            <td>{{score.name}}</td>
                            <td style="font-weight: bold">{{score.score}}</td>
                        </tr>
                    </table>
                </div>
                <div v-if="charts">
                    <div v-for="(chart, index) in charts" :key="index" style="padding-bottom: 20px">
                        <highcharts :options="chart"></highcharts>
                    </div>
                </div>
                <div v-if="charts3d">
                    <div v-for="(chart, index) in charts3d" :key="index" style="padding-bottom: 20px; text-align: center">
                        <div style="font-size: 18px">{{chart.title.text}} (3D)</div>
                        <app-btn @click="show3dGraph(chart)">Display Graph</app-btn>
                    </div>
                </div>
            </div>
            <div v-else>
                <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; width: 100%; padding: 50px">
                    <SwayLoader />
                    <div style="margin-top: 10px">
                        Loading Data...
                    </div>
                </div>
    
    
            </div>
        </div>
    </div>
</template>

<script>
import {getTestRawData, getDetailedTestCalculationGraphs} from "@/services/profile.service";
import {getFunctionalXyzExport} from "@/services/report.service";
import moment from "moment";
import {allMeasuresFromConstants} from "@/js/shared/constants";
import {buildLineChart, buildLineChart3d} from "@/js/shared/helper";
import InternalGraphPopup3d from "@/components/ProfileTest/InternalGraphPopup3d";
import Highcharts from "highcharts";


export default {
    name: "InternalGraphPopup",
    components:{
        InternalGraphPopup3d
    },
    props: {
        id: {
            required: true
        }
    },
    data() {
        return {
            errorMessage: null,
            scores: null,
            charts: null,
            charts3d: null,
            selected3dChart: null,
            isShowing3dPopup: false,
            currentResultId: null,
            resultIds: [],
            resultLength: 0,
            resultIteration: 0,
            testTitle: ""
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            
            await this.loadGraphs(null);
        },
        async loadGraphs(resultId) {

            this.charts = null;
            this.charts3d = null;
            this.scores = null;
            this.errorMessage = null;
            
            const {id} = this;
            let response = await getDetailedTestCalculationGraphs(id, resultId);

            this.currentResultId = response.selectedTestResultId;
            this.resultIds = response.availableTestResultIds;
            this.resultLength = this.resultIds.length;
            this.resultIteration = this.resultIds.findIndex(o => o === response.selectedTestResultId);
            this.testTitle = response.testType + ' #' + response.selectedTestResultId + ' (' + (this.resultIteration + 1) + ' of ' + (this.resultLength) + ')';

            if (response.Errors) {
                this.errorMessage = response.Errors[0].Message;
                return;
            }
            
            let charts = []
            const scatterGraphs = response.data.scatterGraphs;
            if (scatterGraphs){
                scatterGraphs.forEach(result => {
                    if (result.series)
                        charts.push(buildLineChart(result))
                });
            }
            this.charts = charts

            let charts3d = []
            const scatterGraphs3d = response.data.scatterGraphs3d;
            if (scatterGraphs3d){
                scatterGraphs3d.forEach(result => {
                    if (result.series){

                        var chart = buildLineChart3d(result, 'container3d');
                        charts3d.push(chart);
                    }
                });
            }
            this.charts3d = charts3d

            this.errorMessage = response.data.errorMessage;
            this.scores = response.data.namedScores;
        },
        async downloadXyz(){
            
            const id = this.currentResultId;
            let response = await getFunctionalXyzExport(id);
        },
        async show3dGraph(chart){
            this.selected3dChart = chart;
            this.isShowing3dPopup = true;
        },
        async showPrev(){
            var id = this.resultIds[this.resultIteration-1];
            await this.loadGraphs(id);
        },
        async showNext(){
            var id = this.resultIds[this.resultIteration+1];
            await this.loadGraphs(id);
        }
    }
}
</script>

<style scoped>
    .scores-table
    {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 40px;
        font-size: 12px;
    }
</style>