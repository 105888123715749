<template>
    <div style="display: flex">
        <div style="display: flex; flex-direction: column; margin-right: 30px">
            <div v-for="(row, index) in detailRows" :key="index" :style="{color: row.isFlagged ? 'red' : null}" style="padding-bottom: 1px;">
                {{row.key}}
            </div>
        </div>
        <div style="display: flex; flex-direction: column">
            <div v-for="(row, index) in detailRows" :key="index" :style="{color: row.isFlagged ? 'red' : null}" style="padding: 0 5px; border-bottom: 1px solid black; text-align: center">
                {{row.value}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CovidTestResults",
    props: {
        profileTest: {
            required: true
        }
    },
    computed: {
        detailRows() {
            return this.profileTest.formattedTestsData['1014'].results[0].detailRows
        }
    }
}
</script>

<style scoped>

</style>