<template>
    <div>
        <AppCard title="Trends" v-if="data && data.length > 0">
<!--            <template #header-right>-->
<!--                <div style="color: white">-->
<!--                    <v-radio-group-->
<!--                        v-model="plotBandType"-->
<!--                        row-->
<!--                        style="margin: 0!important; padding: 0!important; height: 24px!important; "-->
<!--                        dense-->
<!--                        dark-->
<!--                        -->
<!--                    >-->
<!--            -->
<!--                        <v-radio-->
<!--                            v-for="type in plotBandTypes"-->
<!--                            :key="type.value"-->
<!--                            :label="type.label"-->
<!--                            :value="type.value"-->
<!--                            style="color: white!important; padding: 0!important; margin: 0!important; margin-left: 30px!important"-->
<!--                         -->
<!--                            color="primary"-->
<!--                        >-->
<!--                            hi-->
<!--                        </v-radio>-->
<!--                    </v-radio-group>-->
<!--                </div>-->
<!--            </template>-->
	        <div class="pa-4">
		        <div v-for="(item, index) in data" :key="index" class="mb-4">
			        <TestCard :title="item.label" :testCategory="item.category">
				        <template>
					        <div style="display: flex; align-items: center">
						        <div style="flex: 1; position: relative">
							        <highcharts :options="item.trendChartOptions" :ref="item.name" style="height: 200px"/>
							        <ProfileTrendsBaselineNorms :data="item" />
						        </div>
						        <div style="display: flex; min-width: 200px; justify-content: center">
							        <div style="display: flex; flex-direction: column; text-align: center" class="mr-4">
								        <div class="stat-label">
									        Score
								        </div>
								        <div class="stat-value">
									        <div v-if="item.currentValue">
										        {{item.currentValue}} {{item.showTimeScores ? 'ms' : null }}
									        </div>
									        <div v-else>
										        -
									        </div>
								
								        </div>
							        </div>
							        <div style="display: flex; flex-direction: column; text-align: center">
								        <div class="stat-label">
									        Percent Change
								        </div>
								        <div class="stat-value">
									        <div v-if="typeof item.percentChange === 'number'">
										        {{(item.percentChange*100).toFixed(0)}}%
									        </div>
									        <div v-else>
										        -
									        </div>
								
								        </div>
							        </div>
						        </div>
					        </div>
					        
				        </template>
			        </TestCard>
		        </div>
	        </div>
	        
            
        </AppCard>
        <div v-else>
            <EmptyState title="No Trends" :svg="emptyProfileGraphic" description="This profile does not have any trends." />
        </div>
    </div>
</template>

<script>
import {measuresCategoriesById, allMeasuresFromConstants} from "@/js/shared/constants";
import {getMeasuresAsArrayByCategoryId} from "@/js/shared/helper";
import ProfileTrendsBaselineNorms from "@/components/Profile/ProfileTrendsBaselineNorms";
import EmptyState from "@/components/EmptyState";
import emptyProfileGraphic from '@/assets/svgs/empty-state/empty-profile-graphic.svg'
import TestCard from "@/components/Profile/TestsViewer/TestCard";

export default {
    name: "ProfileTrends",
    components: {TestCard, ProfileTrendsBaselineNorms, EmptyState},
    props: {
        profile: {
            required: true
        }
    },
    data() {
        return {
            measuresCategoriesById,
            emptyProfileGraphic,
            data: [],
            plotBandType: 0,
            positions: null,
        }
    },
    watch: {
        plotBandType() {
            this.init()
        }
    },
    mounted() {

       this.init()

    },
    methods: {
        getVariabilityColor(item) {
            return item.plotBandColor
        },
        
        init() {
            let self = this;
            const measures = getMeasuresAsArrayByCategoryId()



            let data = []

            measures.forEach(measure => {

                if (this.profile.measureIdsTaken.includes(measure.measureId) && !allMeasuresFromConstants[measure.measureId].hasNoTrendChart) {
                    let trendChartOptions = this.profile.getTrendChartOptionsByMeasureId({
                        measureId: measure.measureId, 
                        onMouseOver: this.onMouseOver, 
                        showNormsPlotBands: !!this.plotBandType,
                        resizeCallback: ({positions, cutOffs, yAxisOffset, ciColor}) => {
                            // const {
                            //     baselineTop,
                            //     baselineHeight,
                            //     normWideTop,
                            //     normWideHeight,
                            //     normThinTop,
                            //     normThinHeight,
                            // } = sizing
                            
                            let index = self.data.findIndex(x => x.measureId === measure.measureId)
                            self.data[index].positions = positions
                            self.data[index].cutOffs = cutOffs
                            
                            self.data[index].yAxisOffset = yAxisOffset
                            self.data[index].ciColor = ciColor
                        }
                        
                    });
                    // let baselineColumnChartOptions = this.profile.getBaselineColumnChartByMeasureId({measure: measure.measureId});
                    data.push({
                        ...measure,
                        trendChartOptions,
                        // baselineColumnChartOptions,
                        currentValue: null,
                        percentChange: null,
                        showTimeScores: allMeasuresFromConstants[measure.measureId].showTimeScores,
                        positions: null,
                        yAxisOffset: null
                    })
                }

            });

            this.data = data;
        },
        trendChartResizeCallback({positions, yAxisOffset}) {
            let x = positions;
            this.positions = positions
            
        },
        onMouseOver(event) {
            let self = this;
            let keys = Object.keys(this.$refs);

            keys.forEach((key, keyIndex) => {
                // if (chart === this.series.chart) return;
                let chart = self.$refs[key][0].chart;
                // 
                chart.series.forEach((series) => {
                    series.data.forEach((point) => {
                        
                        

                        if (point.x === event.target.x) {
                            
                            let measureIndex = self.data.findIndex(x => x.name === key)

                            if (event.type === "mouseOver") {
                                self.data[measureIndex].currentValue = point.y;
                                self.data[measureIndex].percentChange = point.percentChange
                                point.setState('hover');
                                chart.tooltip.refresh(point);
                                chart.xAxis[0].drawCrosshair(event, point);
                            } 
                            // else {
                            //     self.data[measureIndex].currentValue = null;
                            //     self.data[measureIndex].percentChange =  null;
                            //     point.setState();
                            //     chart.tooltip.hide();
                            //     chart.xAxis[0].hideCrosshair();
                            // }
                        }
                    });
                });
            });
        },
        getDisplayPercent(value) {
            return value !== null && value !== undefined ? value + '%' : null
        }
    }
}
</script>

<style scoped>
.stat-label {
    color: var(--v-text-lighten4)
}

.stat-value {
    font-weight: bold
}

</style>