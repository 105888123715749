var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.positions)?_c('div',{staticStyle:{"position":"absolute","right":"0px","width":"10px","border-radius":"10px"},style:({
                                    top: ((_vm.data.positions.baselineTop + _vm.data.yAxisOffset) + "px"),
                                    height: ((Math.abs(_vm.data.positions.baselineBottom - _vm.data.positions.baselineTop)) + "px"),
                                    backgroundColor: _vm.data.ciColor,
                                    'border-bottom-left-radius': _vm.getBorderRadius(_vm.data.cutOffs.baselineBottom),
                                    'border-bottom-right-radius': _vm.getBorderRadius(_vm.data.cutOffs.baselineBottom),
                                    'border-top-left-radius': _vm.getBorderRadius(_vm.data.cutOffs.baselineTop),
                                    'border-top-right-radius': _vm.getBorderRadius(_vm.data.cutOffs.baselineTop),
                                })}):_vm._e(),(_vm.data.positions && _vm.data.positions.normWideTop)?_c('div',[(_vm.data.positions)?_c('div',{staticStyle:{"position":"absolute","right":"-7px","width":"10px","background-color":"#7b7b7b","border-radius":"10px"},style:({
                                    top: ((_vm.data.positions.normWideTop + _vm.data.yAxisOffset) + "px"),
                                    height: ((Math.abs(_vm.data.positions.normWideBottom - _vm.data.positions.normWideTop)) + "px"),
                                    'border-bottom-left-radius': _vm.getBorderRadius(_vm.data.cutOffs.normWideBottom),
                                    'border-bottom-right-radius': _vm.getBorderRadius(_vm.data.cutOffs.normWideBottom),
                                    'border-top-left-radius': _vm.getBorderRadius(_vm.data.cutOffs.normWideTop),
                                    'border-top-right-radius': _vm.getBorderRadius(_vm.data.cutOffs.normWideTop),
                                })}):_vm._e(),(_vm.data.positions)?_c('div',{staticStyle:{"position":"absolute","right":"-7px","width":"10px","background-color":"#989898","border-radius":"10px"},style:({
                                    top: ((_vm.data.positions.normThinTop + _vm.data.yAxisOffset) + "px"),
                                    height: ((Math.abs(_vm.data.positions.normThinBottom - _vm.data.positions.normThinTop)) + "px"),
                                    'border-bottom-left-radius': _vm.getBorderRadius(_vm.data.cutOffs.normThinBottom),
                                    'border-bottom-right-radius': _vm.getBorderRadius(_vm.data.cutOffs.normThinBottom),
                                    'border-top-left-radius': _vm.getBorderRadius(_vm.data.cutOffs.normThinTop),
                                    'border-top-right-radius': _vm.getBorderRadius(_vm.data.cutOffs.normThinTop),
                                })}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }