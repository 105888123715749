<template>
    <div class="select-org-container">
        <h2 v-if="!isUsedForProfileAccessLink" class="mb-2">Select An Organization</h2>
        <h2 v-else class="mb-2" style="text-align: center">To which Organization would you like to transfer?</h2>
        <div class="pa-4" style="min-width: 400px; max-height: 80%; overflow: scroll">
            <div v-for="(organization, index) in availableOrganizations" >
                <div class="link pb-3 pt-3 mt-2 mb-2 pl-3 text-center" @click="selectOrganization(organization.id)" >
                    {{organization.name}}
                    <div >
                        <app-chip v-if="organization.isEnterpriseAdminOrg" small label :color="'blue'" >Enterprise</app-chip>
                    </div>
                </div>
                
                <v-divider v-if="index !== availableOrganizations.length - 1"></v-divider>
            </div>
        </div>
        <app-btn color="primary" @click="logout" class="mt-2">Logout</app-btn>
        
    </div>
</template>

<script>

let viewport = document.querySelector("meta[name=viewport]");
viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

import {getUser, logout, switchSelectedOrganization} from '@/services/userService'
export default {
    name: "SelectOrganization",
    data() {
        return {
            availableOrganizations: null,
            isUsedForProfileAccessLink: false
        }
    },
    mounted() {
        let account = getUser();
        const {availableOrganizations} = account;
        
        if (availableOrganizations.length == 1)
        {
            this.selectOrganization(availableOrganizations[0].id);
        }

        availableOrganizations.sort(function(a, b){
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
        })
        
        this.availableOrganizations = availableOrganizations

        let {isUsedForProfileAccessLink} = this.$route.query;
        this.isUsedForProfileAccessLink = isUsedForProfileAccessLink;
    },
    methods: {
        logout() {
            logout(() => this.$router.push('/login'));
            // this.$router.push('/login')
        },
        async selectOrganization(organizationId) {
            
            let selectedOrganization = this.availableOrganizations.find(x => x.id == organizationId);
            if (this.isUsedForProfileAccessLink && selectedOrganization.isEnterpriseAdminOrg) {
                alert("You cannot transfer to an Enterprise Organization. Please select a different Organization.");
                return;
            }
            
            this.$route.query.orgId = organizationId;
            const {account} = await switchSelectedOrganization(organizationId);
            
            this.$emit('setAccount', account);
            
            //if used for profileAccessLink then redirect back
            if (this.isUsedForProfileAccessLink) {
                await this.$router.push('/redeem-profile-access-link?orgSelected=true&shouldContinue=true&profileAccessLink=' + this.$route.query.profileAccessLink)
            } else {
                await this.$router.push(this.$route.query.redirect ? this.$route.query.redirect : '/')
                location.reload()
            }
        }
    }
}
</script>

<style scoped>
.select-org-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding-top: 10vh;*/
    flex-direction: column;
    height: 100vh;
    background-color: white;
} 

.link {
    /*background-color: red;*/
    /*display: flex;*/
    /*align-items: center;*/
}

.link:hover {
    cursor: pointer;
    background-color: var(--v-light-base);
    border-radius: 5px;
}


</style>