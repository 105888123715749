<template>
    <div style="margin-top: 20px; display: flex; flex-direction: column; align-items: center; justify-content: center">
        <img v-if="svg" :src="svg" alt="empty state" style="max-height: 250px; max-width: 250px">






        <h1 v-if="title" style="margin-top: 20px">
            {{ title }}
        </h1>
        <div v-if="description" style="" class="mt-4 description">
            {{description}}
        </div>


        <app-btn @click="$emit('buttonClick')" v-if="button" class="mt-4" color="primary">{{ buttonText }}</app-btn>
        <slot name="bottom-content" class="mt-4">
            
        </slot>


    </div>
</template>

<script>


export default {
    name: "EmptyState",
    props: {
        title: {
            required: false
        },
        description: {
            required: false
        },
        button: {
            type: Boolean
        },
        buttonText: {
            default: 'Submit'
        },
        svg: {
            
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.description {
    max-width: 300px; 
    text-align: center;
    color: var(--v-text-lighten3)
}
</style>