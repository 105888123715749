<template>
    <v-snackbar
        v-model="snackbar.open"
        :color="snackbar.color"
        top
        :timeout="3000"

    >
        
        <div style="font-size: 16px">
            <span class="mr-2"><v-icon>{{icon}}</v-icon></span>
            {{ snackbar.text }}
        </div>


        <template v-slot:action="{ attrs }">
            <v-icon v-bind="attrs" @click="closeSnackbar" color="white">mdi-close</v-icon>
        </template>
    </v-snackbar>
</template>

<script>
import {SET_SNACKBAR} from "@/store/mutation-types";

export default {
    name: "AppSnackbar",
    data() {
        return {
        }
    },
    components: {

    },
    computed: {
        snackbar() {
            return this.$store.state.snackbar
        },
        icon() {
            if (this.snackbar.color === 'error') {
                return 'mdi-alert'
            } 
            return 'mdi-check-circle'
        }
    },
    methods: {
        closeSnackbar() {
            let snackbar = {...this.$store.state.snackbar}
            snackbar.open = false;
            this.$store.commit(SET_SNACKBAR, snackbar)
        },
    }
}
</script>

<style scoped>

</style>