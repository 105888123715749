<template>
    <div class="center-page" style="horiz-align:center; text-align: center; vertical-align: center; padding: 30px">

        <h1>
            <img style="width: 300px" :src="swayLogoDark" class="visa-logo elevate">
        </h1>
        
        <div v-if="!isValidLink">
            <div style="align-items: center">
                <v-icon style="color: rgba(255,0,0,.5)" :size="16">mdi-alert</v-icon>
                <span style="color: rgba(255,0,0,.5); font-weight: 800; margin-left: 5px">IMPORTANT NOTE: </span>
            </div>

            <div style="margin-bottom: 10px">
                INVALID LINK. Please check with your administrator.
            </div>
            <br>
            <AppBtn @click="goToPortal">Go to Portal</AppBtn>

        </div>
        <div v-else-if="!isAuthenticated">
            
            <div v-if="enablePatientAccountCreation" style="padding-bottom: 10px; color: var(--v-text-lighten2)">Login or Create Account to proceed with claiming this profile.</div>
            <div v-else style="padding-bottom: 10px; color: var(--v-text-lighten2)">Login to an existing Sway Account to proceed with claiming this profile.</div>

            
            <div>
                <div class="pa-2">
                    <div>
                        <AppBtn @click="goToLogin">Login</AppBtn>
                        <br>
                        <br>
                        <a v-if="enablePatientAccountCreation" @click="newUserDialogOpen = true">Create New Account</a>
                    </div>
                </div>
            </div>
            
            <br>
<!--            <a @click="goToPortal">Go to Portal</a>-->
        </div>
        <div v-else>
            <div v-if="completedTransfer">
                <div>Profile successfully transferred. Login to the mobile app to take assigned tests or share your profile. </div>
                <br>
                <a @click="goToPortal">Go to Portal</a>

                <br>
                <a style="width: 25%" href="https://apps.apple.com/us/app/sway-medical/id657932025" target="_blank">
                    <img style="width: 75%; max-width: 300px" class="pa-2" alt="Download Sway on the App Store" :src="appStoreSvg">
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.swaymedical.Sway&hl=en_US&gl=US&pli=1" target="_blank">
                    <img style="width: 75%; max-width: 300px" class="pa-2" alt="Download Sway on the Google Play Store" :src="googlePlaySvg">
                </a>
            </div>
            <div v-if="!completedTransfer">
                You are logged in as <strong>{{currentUser.email}}</strong>. <br><br> Click below to continue the Profile Sharing process.
                <br><br>
                <AppBtn @click="continueAuthenticatedProcess">Continue Transfer</AppBtn>
                <br><br>
                <a @click="logout">Logout</a>
            </div>
        </div>

        <dialog-form
            :open="submitProfileAccessDialogOpen"
            title="Verify Information"
            width="90%"
            max-width="600"
            @close="submitProfileAccessDialogOpen = false"
            :saving="submittingProfileAccessLink"
            @submit="submitProfileAccessLink"
            submit-button-title="Verify and Add"
        >
            <template #form-content>

                <span>Verify the profile information to add to your account or organization.</span>

                <br>

                <app-select
                    v-if="shouldShowRelationshipType"
                    v-model="submitProfileForm.relationshipTypeId"
                    label="How are you related to this profile?"
                    :items="accountTypes"
                    item-text="text"
                    item-value="value"
                />

                <app-text-field label="First Name" v-model="submitProfileForm.firstName"></app-text-field>

                <app-text-field label="Last Name" v-model="submitProfileForm.lastName"></app-text-field>

                <app-form-date-picker

                    label="Date of Birth"
                    v-model="submitProfileForm.birthDate"
                    class="mt-2"
                />

                <br>
                <div v-if="shouldShowGroups" class="profile-input-field">
                    <app-form-multiple-select
                        v-if="groups"
                        v-model="submitProfileForm.groupIds"
                        :items="groups"
                        rules="required"
                        item-text="name"
                        item-value="id"
                        label="Groups*"
                        sublabel="(minimum of 1)"
                        name="groupIds"
                    ></app-form-multiple-select>
                </div>
                <br>

                <div v-if="shouldShowProtocols" class="profile-input-field">
                    <app-select row v-model="submitProfileForm.defaultOrganizationProtocolId" label="Default Protocol" :items="protocols" item-value="organizationProtocolId" item-text="name" rules="required"  />
                </div>
                
            </template>
        </dialog-form>

        <dialog-form
            :open="newUserDialogOpen"
            @close="newUserDialogOpen = false"
            title="Create Account"
            @submit="submitAddUserForm"
            width="90%"
            max-width="600"
            :saving="savingNewUser"
            
        >

            <template v-slot:form-content>
                <add-user-form :new-user-form="newUserForm"></add-user-form>

            </template>
            <template v-slot:actions>

            </template>



        </dialog-form>
    </div>
</template>

<script>

import {SET_SNACKBAR} from "@/store/mutation-types";
import appStoreSvg from "@/assets/svgs/download-on-the-app-store.svg";
import googlePlaySvg from "@/assets/svgs/google-play-badge.svg";
import swayVisaLogo from "@/assets/images/swayvisa-logo.svg";
import swayVisaLogoDark from "@/assets/images/swayvisa-logo-dark.svg";
import swayLogoDark from "@/assets/images/sway-logo-dark.svg";


let viewport = document.querySelector("meta[name=viewport]");
viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');

import {validateProfileAccessLinkUniqueId, submitProfileAccessLink} from "@/services/profile.service";
import {createParentAccount, getUser, newAccountModel, login, isPatient} from "@/services/userService";
import {getGroups} from '@/services/groups.service'
import {getProtocols} from "@/services/protocol.service";
import AddUserForm from "@/components/UserPages/AddUserForm";
import {logout} from "@/services/userService";

export default {
    name: "RedeemProfileAccessLink",
    components: {
        AddUserForm
    },
    data() {
        return {
            swayVisaLogo,
            swayVisaLogoDark,
            swayLogoDark,
            completedTransfer: false,
            profile: null,
            currentProfileTest: null,
            enableSportsProfileProperties: null,
            availableOrganizations: [],
            
            isValidLink: true,
            
            isAuthenticated: false,
            
            submitProfileAccessDialogOpen: false,
            submittingProfileAccessLink: false,
            
            submitProfileForm: {
                firstName: null,
                lastName: null,
                birthDate: null,
                groupIds: [],
                defaultOrganizationProtocolId: null,
                relationshipTypeId: null
            },
            groups: {
                default: null
            },
            shouldShowGroups: true,
            protocols: [],
            shouldShowProtocols: true,
            shouldShowRelationshipType: true,

            enablePatientAccountCreation: false,
            newUserDialogOpen: false,
            savingNewUser: false,
            newUserForm: {
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                jobTitle: '',
                isSafetyCoach: false,
                isBillingContact: false,
                professionalTypeId: null,
                organizationAccessTypeId: 1,
                groupIds: [],
                organizations: [],
                password: '',
                confirmNewPassword: '',
            },
            currentUser: null,
            accountTypes: [
                {
                    text: 'Parent or Guardian',
                    value: 3
                },
                {
                    text: 'This is me (Self)',
                    value: 5
                }
            ],
            appStoreSvg,
            googlePlaySvg
        }
    },
    mounted() {
        this.init()
    },
    computed:{
        //This sucks but no other way to get good validation for password with the abstracted app-text-field on the user form
        newPasswordsLength() {
            const {password} = this.newUserForm
            return password.length > 7
        },
        newPasswordHasUppercase() {
            const {password} = this.newUserForm
            return password.match(/(?=.*[A-Z])/)
        },
        newPasswordHasLowercase() {
            const {password} = this.newUserForm
            return password.match(/(?=.*[a-z])/)
        },
        newPasswordHasDigit() {
            const {password} = this.newUserForm;
            let temp = password.match(/(?=.*\d)/);
            return password.match(/(?=.*\d)/)

        },
        newPasswordHasSpecialCharacter() {
            const {password} = this.newUserForm;
            let temp = password.match(/(?=.*\W)/);
            return password.match(/(?=.*\W)/)

        },
        confirmPasswordMatches() {
            const {password, confirmNewPassword} = this.newUserForm;
            return confirmNewPassword.length > 0 && password === confirmNewPassword

        },
        newPasswordIsValid() {
            return this.newPasswordsLength && this.newPasswordHasUppercase && this.newPasswordHasLowercase && this.newPasswordHasDigit && this.newPasswordHasSpecialCharacter
        },
        formIsValid() {
            return this.newPasswordIsValid && this.confirmPasswordMatches
        },  
    },
    methods: {
        init() {
            this.completedTransfer = false;
            this.validateProfileAccessLink();
            // this.getProfileLocal()
        },
        async validateProfileAccessLink() {
            let {profileAccessLink, orgSelected, shouldContinue} = this.$route.query;

            const response = await validateProfileAccessLinkUniqueId(profileAccessLink);
            if (!response.isSuccess){
                this.isValidLink = false;
                return;
            }
            
            //check if authenticated
            let currentUser = await getUser();
            
            this.currentUser = currentUser;
            if (currentUser && currentUser.id){
                this.isAuthenticated = true;
            }else{
                
            }
            
            this.isValidLink = true;
            
            this.enablePatientAccountCreation = response.profileAccessLink.enablePatientAccountCreation;
            
            if (shouldContinue != null && shouldContinue.toLowerCase() === 'true'){
                await this.continueAuthenticatedProcess();
            }
        },
        async logout(){
            //logout the user and reload
            localStorage.removeItem('user')
            localStorage.removeItem('jwtToken')
            location.reload();
        },
        async continueAuthenticatedProcess() {

            let {profileAccessLink, orgSelected} = this.$route.query;
            
            const response = await validateProfileAccessLinkUniqueId(profileAccessLink);
            
            if (!response.isSuccess) return 
            
            //check auth status
            //if logged in, add to organization and redirect to dashboard
            let currentUser = await getUser();
            if (currentUser && currentUser.id) {
                const {hasMultipleOrganizations} = currentUser;
                
                if ((!orgSelected || orgSelected.toLowerCase() !== 'true') && hasMultipleOrganizations){

                    //go to org select
                    await this.$router.push('/select-organization?isUsedForProfileAccessLink=true&profileAccessLink=' + this.$route.query.profileAccessLink)
                }
                
                if (isPatient()){
                    this.shouldShowGroups = false;
                    this.shouldShowProtocols = false;
                    this.shouldShowRelationshipType = true;
                }else{
                    //get groups to show
                    await this.getGroups();
                    //get protocols to show
                    await this.getProtocols();
                    
                    this.shouldShowGroups = true;
                    this.shouldShowProtocols = true;
                    this.shouldShowRelationshipType = false;
                }
                
                
                
                //show submit dialog
                this.submitProfileAccessDialogOpen = true;
            }else{
                //go to login
                await this.goToLogin();
            }
            
            //else, ask if they already have an account and want to log in (send uniqueId in query string)
            
        },
        async goToLogin(){
            await this.$router.push('/Login?isUsedForProfileAccessLink=true&profileAccessLink=' + this.$route.query.profileAccessLink)
        },
        async submitProfileAccessLink(){
            this.submittingProfileAccessLink = true;
            
            //add to organization
            let response = await submitProfileAccessLink(this.$route.query.profileAccessLink, this.submitProfileForm.firstName, 
                this.submitProfileForm.lastName, this.submitProfileForm.birthDate, this.submitProfileForm.groupIds, 
                this.submitProfileForm.defaultOrganizationProtocolId, this.submitProfileForm.relationshipTypeId);
            
            if (response.isSuccess) {
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Profile Access Granted',
                    color: 'success',
                    open: true
                });
                
                this.completedTransfer = true;
                this.submitProfileAccessDialogOpen = false;
            }
            
            this.submittingProfileAccessLink = false;
        },
        async getGroups() {
            const response = await getGroups()
            this.groups = response.groups;
        },
        async getProtocols() {
            const response = await getProtocols();
            this.protocols = response.protocols
        },
        async goToPortal() {
            await this.$router.push('/dashboard')
        },
        async submitAddUserForm() {
            if (!this.formIsValid){
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Please ensure passwords meet requirements',
                    color: 'error',
                    open: true
                });
                return
            }
            this.savingNewUser = true;
            let request = {...newAccountModel}
            const {
                firstName,
                lastName,
                email,
                phoneNumber,
                jobTitle,
                professionalTypeId,
                isSafetyCoach,
                isBillingContact,
                organizationAccessTypeId,
                groupIds,
                organizations,
                password
            } = this.newUserForm

            let organizationIds = organizations.map(function(o){
                return o.id;
            });
            
            //little hack but using organizationAccessType as the Parent/Guardian or Individual
            request.accountTypeId = organizationAccessTypeId;
            request.organizationAccessTypeId = 1;

            request.firstName = firstName;
            request.lastName = lastName;
            request.email = email;
            request.phoneNumber = phoneNumber;
            request.jobTitle = jobTitle;
            request.professionalTypeId = professionalTypeId;
            request.isSafetyCoach = isSafetyCoach;
            request.isBillingContact = isBillingContact;
            request.groupIds = groupIds;
            request.organizationIds = organizationIds;
            request.requestedPassword = password;
            request.profileAccessLinkUniqueId = this.$route.query.profileAccessLink;

            let response = await createParentAccount(request);

            if (!response.isSuccess) {
                this.savingNewUser = false;
                return
            }

            this.$store.commit(SET_SNACKBAR, {
                text: 'Account Successfully Created',
                color: 'success',
                open: true
            })
            
            //login user that was just created
            let loginResponse = await login(email, password);
            
            this.savingNewUser = false;
            
            if (loginResponse.isSuccess) {
                let currentUser = await getUser();
                this.currentUser = currentUser;
                if (currentUser && currentUser.id){
                    this.isAuthenticated = true;
                }

                this.newUserDialogOpen = false;
            }

            
        },
        
    }
}
</script>

<style scoped>
.center-page{
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.sway-visa-card-container {
    background: linear-gradient(45deg, rgba(0,0,0,1), rgba(51,51,51,1));
    margin: 1rem;
    padding: 1rem;
    color: white;
    border-radius: 1rem;

    overflow: hidden;
    position: relative;
    box-shadow: 0px 0px 20px rgba(51,51,51,.5);
}

.sway-visa-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 3;
    color: #f2f2f2
}

.visa-logo {
    max-width: 80%
}

.qr-code-container {
    /*background-color: white;*/
    margin-top: 2rem;
    align-self: center;
}

.background-logo-container {
    position: absolute;
    top: -10%;
    right: -10%;
    height: 70%;
    transform: rotate(-45deg);
    z-index: 0
}

.cardholder-info {
    padding-top: 1.5rem
}

.cardholder-name {
    font-size: 1.2rem;
    font-weight: 600;

}

</style>