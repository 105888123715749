<template>
    <div>
        <page-header
            :title="protocol ? `${protocol.name}`  : 'Protocol'"
            :subtitle="protocol ? `Protocol`  : null"
            :editable="!protocol.legacyProtocolId"
            deletable
            @edit="editProtocolDialogOpen = true"
            @delete="deleteProtocolDialogOpen = true"
            divider
        >
            <template #title-right>
                <app-chip v-if="!protocol.legacyProtocolId" label color="blue">Custom</app-chip>
                <app-chip v-else label color="green">Standard</app-chip>
                <app-chip v-if="protocol.symptomsEnabled" label color="orange" class="ml-2">Symptoms Tracking</app-chip>
                <app-chip v-if="protocol.isDisabled" label color="red" class="ml-2">Disabled</app-chip>
<!--                <app-chip v-if="protocol.eventsEnabled" label class="ml-2" color="purple">Events Enabled</app-chip>-->
                
            </template>
            <template v-if="protocol.description" #below >
                <span style="color: #757575; font-size: .85em; text-transform: uppercase" >Description: </span>{{ protocol.description}}
            </template>
        </page-header>
        
        
        <div>
            <v-row>
                <v-col cols="12">
                    <app-card  style="max-width: 600px" title="Test Sequence" :editable="!editingProtocolSequence && !protocol.legacyProtocolId" @edit="editingProtocolSequence = true">
                        <div class="pa-4">
                            <div style="display: flex; align-items: center">

                            </div>
                            <div v-if="editingProtocolSequence" class="mt-2">
                                <app-multiple-select 
                                    v-model="editProtocolMeasures"
                                    :items="organizationMeasures"
                                    item-title="name"
                                    item-subtitle="description"
                                    item-value="regulationMeasureId"
                                    draggable
                                />  
                            </div>
                            <div v-else>
                                <div v-for="measure in measures" style="border: 1px solid lightgrey; border-radius: 5px; border-left: 5px solid var(--v-primary-base)" class="mb-2 pa-2 pl-4">
                                    <div style="font-weight: bold">
                                        {{measure.name}}<span style="color:gray" v-if="measure.deletedOn"> (deleted from org)</span>
                                    </div>
                                    <div>
                                        {{measure.description}}
                                    </div>
                                </div>
                                
                            </div>
                            <div class="ma-4" v-if="editingProtocolSequence">
                                <app-btn @click="updateProtocolTestSequence" :loading="savingProtocolSequence">Save Changes</app-btn>
                                <app-btn class="ml-2" outlined color="secondary" @click="editingProtocolSequence = false">Cancel</app-btn>
                            </div>


                        </div>

                    </app-card>
                </v-col>
            </v-row>
        </div>
            
            
            

        
        <dialog-form
            :open="editProtocolDialogOpen"
            @close="editProtocolDialogOpen = false"
            title="Edit Protocol"
            @submit="updateProtocolGeneralInfo"
            :width="600"
            :saving="updatingProtocol"
        >

            <template v-slot:form-content>
                <div>
                    
                    <app-text-field label="Name" v-model="editProtocolForm.name"></app-text-field>
                    <app-text-field label="Description" v-model="editProtocolForm.description"></app-text-field>
                    
                    <app-form-switch label="Symptoms Tracking" v-model="editProtocolForm.symptomsEnabled" />
                    <app-form-switch label="Disabled" v-model="editProtocolForm.isDisabled" />
<!--                    <app-form-switch label="Events Enabled" v-model="editProtocolForm.eventsEnabled" />-->
                </div>
        
            </template>
            <template v-slot:actions>

            </template>



        </dialog-form>
        <app-confirm-dialog 
            title="Delete Protocol" 
            :open="deleteProtocolDialogOpen" 
            @confirm="deleteProtocol" 
            @close="deleteProtocolDialogOpen = false"
            :loading="deletingProtocol"
            confirm-delete
        >
            <div>
                Are you sure you want to delete protocol {{protocol.name}}?
            </div>
        </app-confirm-dialog>
    </div>

</template>

<script>
import {
    getProtocol,
    getMeasuresForProtocol,
    deleteProtocol,
    protocolModel,
    updateProtocol,
    getMeasuresForOrganization, getProtocols
} from '@/services/protocol.service'
import {SET_SNACKBAR, SET_PROTOCOL} from '@/store/mutation-types'
import draggable from 'vuedraggable'

export default {
    name: "Protocol",
    components: {
        draggable
    },
    data() {
        return {
            protocol: null,
            measures: null,
            protocols: [],
            enabled: true,
            list: [
                { name: "John", id: 0 },
                { name: "Joao", id: 1 },
                { name: "Jean", id: 2 }
            ],
            dragging: false,
            deleteProtocolDialogOpen: false,
            editProtocolDialogOpen: false,
            editProtocolForm: null,
            updatingProtocol: false,
            deletingProtocol: false,
            editingProtocolSequence: false,
            savingProtocolSequence: false,
            organizationMeasures: [],
            editProtocolMeasures: []
        }
    },
    watch: {
        $route (to, from){
            if (to.params.id !== from.params.id) {
                this.clearProtocol()
                this.initializeProtocol()
            }
        },
    },
    mounted() {
        this.setProtocols();
        this.initializeProtocol();
        this.setOrganizationMeasure()
    },
    computed: {
        draggingInfo() {
            return this.dragging ? "under drag" : "";
        },
        // measuresForSelection() {
        //     return this.measures.map(measure => measure.regulationMeasureId)
        // }
    },
    methods: {
        async setProtocols() {
            this.loadingProtocols = true;
            const response = await getProtocols();
            if (!response.isSuccess) return
            this.protocols = response.protocols
            this.loadingProtocols = false
        },
        async setOrganizationMeasure() {
            const response = await getMeasuresForOrganization();
            if (!response.isSuccess) return 
            this.organizationMeasures = response.measures
        },
        clearProtocol() {
            this.protocol = null
        },
        
        async initializeProtocol() {
            this.$store.commit(SET_PROTOCOL, null)
            
            // const {data} = await getProtocol(this.$route.params.id);
            const response = await getMeasuresForProtocol(this.$route.params.id);
            if (!response.isSuccess) return;
            
            this.protocol = {...response.protocol};
            this.editProtocolForm = {...response.protocol};
            this.$store.commit(SET_PROTOCOL, {...response.protocol})
            let measures = response.regulationMeasures;
            if (!this.$store.state.currentAccount.isSuperAdmin){
                measures = measures.filter(measure => !measure.deletedOn);
            }
            this.measures = measures;
            this.editProtocolMeasures = measures.map(measure => measure.regulationMeasureId)
        },

        checkMove: function(e) {
            window.console.log("Future index: " + e.draggedContext.futureIndex);
        },
        async deleteProtocol() {
            this.deletingProtocol = true;
            let result = await deleteProtocol(this.protocol.organizationProtocolId);
            this.$store.commit(SET_SNACKBAR, {
                text: 'Protocol Successfully Deleted',
                color: 'success',
                open: true
            });
            this.deletingProtocol = false;
            await this.$router.push('/protocols')
        },
        async updateProtocolGeneralInfo() {
            this.updatingProtocol = true;
            let request = {...protocolModel}
            const {
                name, 
                description, 
                symptomsEnabled, 
                eventsEnabled, 
                legacyProtocolId, 
                isDisabled, 
                protocolItems, 
                organizationProtocolId
            } = this.editProtocolForm;

            let isNameTaken = false;
            this.protocols.forEach(x => {
                if (x.organizationProtocolId !== organizationProtocolId) {
                    if (x.name.toLowerCase() === name.toLowerCase()) isNameTaken = true
                }
                
            })

            if (isNameTaken) {
                this.updatingProtocol = false;
                return this.$store.commit(SET_SNACKBAR, {
                    text: 'Protocol names must be unique.',
                    color: 'error',
                    open: true
                });
            }
            
            request.name = name;
            request.description = description;
            request.symptomsEnabled = symptomsEnabled;
            request.eventsEnabled = eventsEnabled;
            request.legacyProtocolId = legacyProtocolId;
            request.isDisabled = isDisabled;
            request.protocolItems = protocolItems;
            request.organizationProtocolId = organizationProtocolId;
            request.updateMeasures = false;
            
            const response = await updateProtocol(request)
            await this.initializeProtocol()
            this.editProtocolDialogOpen = false;
            this.updatingProtocol = false;
            this.$store.commit(SET_SNACKBAR, {
                text: 'Protocol Successfully Updated',
                color: 'success',
                open: true
            });
        },
        async updateProtocolTestSequence() {
            this.savingProtocolSequence = true;
            const {editProtocolMeasures} = this;
            let request = {...protocolModel}
            const {
                name,
                description,
                symptomsEnabled,
                eventsEnabled,
                legacyProtocolId,
                isDisabled,
                organizationProtocolId
            } = this.protocol;
            
            request.name = name;
            request.description = description;
            request.symptomsEnabled = symptomsEnabled;
            request.eventsEnabled = eventsEnabled;
            request.legacyProtocolId = legacyProtocolId;
            request.isDisabled = isDisabled;
            request.organizationProtocolId = organizationProtocolId;
            request.updateMeasures = true;
            request.protocolItems = editProtocolMeasures.map((measure, index) => ({
                regulationMeasureId: measure,
                regulationMeasureOrder: index
            }));

            const response = await updateProtocol(request)
            await this.initializeProtocol()
            this.savingProtocolSequence = false;
            this.editingProtocolSequence = false;
            
            this.$store.commit(SET_SNACKBAR, {
                text: 'Protocol Successfully Updated',
                color: 'success',
                open: true
            });

        }
    },
    
}
</script>

<style scoped>
.editing:hover {
    cursor: move;
}

.list-group-item {
    border: 1px solid var(--v-light-darken1);
    border-radius: 5px;
    background-color: white;
}

.add-measure-container {
    height: 60px;
    border-radius: 5px;
    border: 2px solid var(--v-primary-base);
    display: flex;
    align-items: center;
    margin: 8px
}

.add-measure-container:hover {
    cursor: pointer
}
</style>