<template>
    <div style="margin: 0px 0px; width: 100%;" class="pdf-section-wrapper pdf-avoid-page-break" id="symptoms-summary">
  
        <div >
           
         
                <div v-for="(group, index) in displayedGroups" :key="index" style="" >
	                <div style="display: flex; align-items: center">
		                <div style="margin-right: 30px; font-weight: bolder" v-if="index === 0">
			                TOTAL <span class="symptoms-value">{{group.value}}</span>
		
		                </div>
		                <div v-for="(item, itemIndex) in group.items" :key="itemIndex" style="margin-right: 20px">
			                {{item.text}} <span class="symptoms-value">{{item.value}}</span>
		                </div>
	                </div>
	                <v-divider class="mt-2"></v-divider>
	
	                <div v-if="totalScore > 0">
		                <SymptomsDetailsSection :profile-test="profileTest" hide-total />
	                </div>
                    

                </div>
            
        </div>
        
            


    </div>
</template>

<script>
import ProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
import SectionWrapper from "@/components/pdf-export/SectionWrapper";
import SymptomsDetailsSection from "@/components/pdf-export/SymptomsDetailsSection";
import gsap from "gsap";

export default {
    name: "SymptomsResults",
    components: {
        SymptomsDetailsSection,
        SectionWrapper,
    },
    props: {
        profileTest: {
            type: ProfileTest,
            required: true
        },
        showDropdown: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // results: null,
            groupedSymptoms: null,
            open: false
        }
    },
    watch: {
        open() {
            if (!this.open) {
                gsap.to(this.$refs.expandChevron, {
                    rotationZ: 0,
                    duration: .2
                })
            } else {
                gsap.to(this.$refs.expandChevron, {
                    rotationZ: 180,
                    duration: .2

                })
            }
        }
    },
    mounted() {
        // this.results = this.profileTest.getMeasureResultsByName('symptoms').results;
        this.groupedSymptoms = this.profileTest.getGroupedSymptoms({asArray: false})
    },
    computed: {
        totalScore() {
            
            if (!this.groupedSymptoms) return null
            
            let totalScore = 0
            
            for (let item in this.groupedSymptoms) {
                totalScore += this.groupedSymptoms[item].totalScore
            }
            
            return totalScore
        },
        displayedGroups() {
            if (!this.groupedSymptoms) return []
            
            let response = [];
            
            response.push({
                label: 'Overview',
                value: this.totalScore,
                items: [
                    {
                        text: 'Physical',
                        value: this.groupedSymptoms.physical.totalScore
                    },
                    {
                        text: 'Cognitive',
                        value: this.groupedSymptoms.cognitive.totalScore
                    },
                    {
                        text: 'Emotional',
                        value: this.groupedSymptoms.emotional.totalScore
                    },
                    {
                        text: 'Sleep-Arousal',
                        value: this.groupedSymptoms.sleepArousal.totalScore
                    },
                    
                ]
            });
            
            // if (this.groupedSymptoms.physical.totalScore > 0) {
            //     response.push({
            //         label: 'Physical',
            //         value: this.groupedSymptoms.physical.totalScore,
            //         items: this.groupedSymptoms.physical.symptoms.map(x => ({text: x.name, value: x.display})),
            //     })
            // }
            //
            // if (this.groupedSymptoms.cognitive.totalScore > 0) {
            //     response.push({
            //         label: 'Physical',
            //         value: this.groupedSymptoms.cognitive.totalScore,
            //         items: this.groupedSymptoms.cognitive.symptoms.map(x => ({text: x.name, value: x.display})),
            //     })
            // }
            //
            // if (this.groupedSymptoms.emotional.totalScore > 0) {
            //     response.push({
            //         label: 'Physical',
            //         value: this.groupedSymptoms.emotional.totalScore,
            //         items: this.groupedSymptoms.emotional.symptoms.map(x => ({text: x.name, value: x.display})),
            //     })
            // }
            //
            // if (this.groupedSymptoms.sleepArousal.totalScore > 0) {
            //     response.push({
            //         label: 'Physical',
            //         value: this.groupedSymptoms.sleepArousal.totalScore,
            //         items: this.groupedSymptoms.sleepArousal.symptoms.map(x => ({text: x.name, value: x.display})),
            //     })
            // }
            
            return response
            
            // return [
            //     {
            //         label: 'Physical',
            //         value: this.groupedSymptoms.physical.totalScore,
            //         items: this.groupedSymptoms.physical.symptoms.map(x => ({text: x.name, value: x.display})),
            //     },
            //     {
            //         label: 'Cognitive',
            //         value: this.groupedSymptoms.cognitive.totalScore,
            //         items: this.groupedSymptoms.cognitive.symptoms,
            //     },
            //     {
            //         label: 'Emotional',
            //         value: this.groupedSymptoms.emotional.totalScore,
            //         items: this.groupedSymptoms.emotional.symptoms,
            //     },
            //     {
            //         label: 'Sleep-Arousal',
            //         value: this.groupedSymptoms.sleepArousal.totalScore,
            //         items: this.groupedSymptoms.sleepArousal.symptoms,
            //     },
            //    
            // ]
        },
        symptoms() {
            return this.profileTest.data.symptoms
        }
    }

}
</script>

<style scoped>




</style>