<template>
    <div >
        <v-container>
            <app-card v-if="editedGroups" title="Profile Groups">
                <div  class="pa-4">
                    <app-multiple-select
                        item-value="id"
                        item-title="name"
                        :items="organizationGroups"
                        v-model="editGroupsForm.groupIds">

                    </app-multiple-select>
                    <app-btn @click="$emit('updateGroups')" :loading="savingProfile" class="mt-2">Save Changes</app-btn>
                </div>
                
            </app-card>
        </v-container>
        
    </div>
</template>

<script>
import {updateProfile} from "@/services/profile.service"
import SwayProfile from "@/js/shared/classes/Profile/SwayProfile";
import {SET_SNACKBAR} from "@/store/mutation-types";

export default {
    name: "ProfileGroups",
    props: {
        profileGroups: {
            required: true,
        },
        organizationGroups: {
            required: true
        },
        updateProfileModel: {
            required: true
        },
        editGroupsForm: {
            required: true
        },
        savingProfile: {
            type: Boolean
        }
    },
    data() {
        return {
            editedGroups: null,
            savingGroups: false,
        }
    },
    mounted() {
        
        this.editedGroups = this.profileGroups
    },
    methods: {
        // async updateProfileGroups() {
        //     this.savingGroups = true
        //     let request = {...this.updateProfileModel};
        //     request.groupIds = this.editedGroups;
        //     request.updateGroups = true;
        //    
        //     const response = await updateProfile(request);
        //     if (!response.isSuccses) {
        //         this.savingGroups = false
        //         return
        //     }
        //    
        //     this.$emit('updatedGroups')
        //    
        //    
        // },

    }
}
</script>

<style scoped>

</style>