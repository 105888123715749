<template>
    <div id="invoice-pdf"
        class="pdf-content"
        style="margin-top: 1em; margin-bottom: 1em; margin-left: 0; margin-right: 0; background-color: white; "
    >
        <div class="pdf-wrapper">
            <div>
                <div style="overflow: visible; margin-bottom: 10px" >
                    <div style="display: flex; width: 100%; justify-content: flex-start; align-items: center">
                        <div style="flex: 1">

                            <v-img
                                :src="logo"
                                style="height: 50px; width: 150px"
                                contain
                            />


                        </div>

                        <!--                                    <app-btn @click="exportPdf" :loading="exportingPdf" id="download-invoice-btn" v-if="!isFullpage">Download Invoice PDF</app-btn>-->
                    </div>

                </div>
            </div>
                    

            <div style="display: flex; margin-top: 20px">
                <div style="flex: 1">
                    <div style="font-weight: bold; text-transform: uppercase;">
                        Organization Info
                    </div>
                    <div style="display: flex; flex-direction: column">
                        <span>{{ billingInfo.name }}</span>
                        <span class="mb-1">{{ billingInfo.phone }}</span>
                        <span>{{ billingInfo.address1 }}</span>
                        <span v-if="billingInfo.address2">{{ billingInfo.address2 }}</span>
                        <span>{{billingInfo.city}}, {{billingInfo.state}} {{billingInfo.postalCode}}</span>
                        <span v-if="billingInfo.country">{{billingInfo.country}}</span>
                    </div>
                </div>
                <div style="flex: 1">
                    <div style="font-weight: bold; text-transform: uppercase;">
                        Billing Info
                    </div>
                    <div style="display: flex; flex-direction: column">
                        <span>{{ billingInfo.billingName }}</span>
                        <span class="mb-1">{{ billingInfo.billingPhone }}</span>
                        <span>{{ billingInfo.billingAddress1 }}</span>
                        <span v-if="billingInfo.billingAddress2">{{ billingInfo.billingAddress2 }}</span>
                        <span>{{billingInfo.billingCity}}, {{billingInfo.billingState}} {{billingInfo.billingPostalCode}}</span>
                        <span>{{billingInfo.billingCountry}}</span>
                    </div>
                </div>
                <div style="flex: 1">
                    <div style="font-weight: bold; text-transform: uppercase;">
                        {{currentInvoice.isPOPending || currentInvoice.poNumber ? "PO" : "Invoice"}} Info
                    </div>
                    <div style="display: flex">
                        <div style="display: flex; flex-direction: column; padding-right: 20px">
                            <span v-if="currentInvoice.id > 0 && !currentInvoice.isPOPending">Invoice Number:</span>
                            <span v-if="currentInvoice.id > 0 && currentInvoice.isPOPending">Quote Number:</span>
                            <span v-if="currentInvoice.poNumber">PO Number:</span>
                            <span>{{currentInvoice.isPOPending ? "PO" : "Invoice"}} Date:</span>
                            <span v-if="!currentInvoice.isPOPending">Due Date:</span>
<!--                            <span>Date of Sale:</span>-->
                        </div>
                        <div style="display: flex; flex-direction: column">
                            <span v-if="currentInvoice.id > 0">{{currentInvoice.id}}{{currentInvoice.deletedOn ? ' (VOID)' : currentInvoice.isRefunded ? ' (REFUNDED)' : ''}}</span>
                            <span v-if="currentInvoice.poNumber">{{currentInvoice.poNumber}}</span>
                            <span>{{formatDate(currentInvoice.issuedOn)}}</span>
                            <span v-if="!currentInvoice.isPOPending">{{formatDate(currentInvoice.displayDueDate)}}</span>
<!--                            <span>{{formatDate(currentInvoice.displayDateOfSale)}}</span>-->
                        </div>
                    </div>

                </div>
            </div>

            <div>

                    <!--                    <app-simple-table :headers="invoiceHeaders" :items="invoiceItems" style="border: 1px solid lightgrey">-->

                    <!--                    </app-simple-table>-->
                    <table class="pdf-table" style="border: 1px solid lightgrey; width: 100%; margin-top:20px" >
                    
                            <thead >
                            <tr>
                                <th v-for="(header, index) in invoiceHeaders" 
                                    style="background-color: #f5f5f5; color: black!important; font-weight: 400; padding: 5px">
                                    {{ header.text }}
                                </th>
<!--                                <th class="text-left">-->
<!--                                    Detail-->
<!--                                </th>-->
<!--                                <th class="text-left">-->
<!--                                    Quantity-->
<!--                                </th>-->
<!--                                <th class="text-left">-->
<!--                                    Amount-->
<!--                                </th>-->
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                v-for="(item, index) in invoiceItems"
                                :key="item.name"

                            >
                                <template v-if="!item.isTotalRow">
                                    <td style="padding: 5px">{{ item.name }}</td>
                                    <td style="padding: 5px">{{ item.description }}</td>
                                    <td style="padding: 5px">{{ item.quantity }}</td>
                                    <td style="padding: 5px">{{ item.subTotal }}</td>
                                </template>
                                <template v-else >
                                    <td style="padding: 5px" :colspan="3" class="text-right" >{{ item.title }}</td>
                                    <td style="padding: 5px" >{{ item.value }}</td>
                                </template>

                            </tr>
                            </tbody>
                     
                    </table>
           
            </div>
            <div style="margin-top: 40px">
                <div v-if="currentInvoice && currentInvoice.paidTotalNumeric < currentInvoice.totalNumeric && !currentInvoice.isPOPending">
                    Payments can be made via credit card, ACH, or checks can be mailed to:
                </div>
                <div style="margin-top: 10px">
                    <div>
                        Sway Medical
                    </div>
                    <div>
                        Dept 1046
                    </div>
                    <div>
                        Tulsa, OK 74182
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import swayLogo from "@/assets/images/sway-logo-dark.svg";
import moment from "moment";
import {formatMoney} from "@/js/shared/helper";

export default {
    name: "InvoicePdf",
    props: {
        currentInvoice: {
            required: true,
            //type: Object
        },
        billingInfo: {
            required: true
        },
        isFullpage: {
            required: false
        }
    },
    data() {
        return {
            logo: swayLogo,
            invoiceHeaders: [
                {
                    text: 'Description',
                    value: 'name'
                },
                {
                    text: 'Detail',
                    value: 'description'
                },
                {
                    text: 'Quantity',
                    value: 'quantity'
                },
                {
                    text: 'Amount',
                    value: 'subTotal'
                },
            ],
            exportingPdf: false
        }
    },
    computed: {
        /*currentInvoice() {
            if (this.currentInvoiceProp)
                return this.currentInvoiceProp;
            else
                return this.currentInvoiceResponse;
        },
        billingInfo() {
            if (this.billingInfoProp)
                return this.billingInfoProp;
            else
                return this.billingInfoResponse;
        },*/
        invoiceItems() {
            let invoiceItems = JSON.parse(JSON.stringify(this.currentInvoice.invoiceItems))
            let items = invoiceItems
                .map(item => {
                    item.subTotal = `$${typeof  item.subTotal === 'number' ?  item.subTotal.toFixed(2) : item.subTotal}`
                    return item
                })
            items.push({
                title: 'Total (USD)',
                value: `${typeof  this.currentInvoice.total === 'number' ?  this.currencyFormatter(this.currentInvoice.total) : this.currentInvoice.total}`,
                isTotalRow: true,
            })
            if (this.currentInvoice.payment && !this.currentInvoice.payment.deletedOn) {
                if (this.currentInvoice.paidTotalNumeric < this.currentInvoice.totalNumeric) {
                    items.push({
                        title: 'Partially Paid (' + this.formatDate(this.currentInvoice.payment.createdOn) + ")",
                        value: this.currentInvoice.paidTotal,
                        isTotalRow: true,
                    })
                    items.push({
                        title: 'Total Remaining Due',
                        value: formatMoney(this.currentInvoice.totalNumeric - this.currentInvoice.paidTotalNumeric, 2),
                        isTotalRow: true,
                    })
                }
                else {
                    items.push({
                        title: 'Paid In Full (' + this.formatDate(this.currentInvoice.payment.createdOn) + ")",
                        value: this.currentInvoice.paidTotal,
                        isTotalRow: true,
                    })
                    items.push({
                        title: 'Total Remaining Due',
                        value: formatMoney(this.currentInvoice.totalNumeric - this.currentInvoice.paidTotalNumeric, 2),
                        isTotalRow: true,
                    })
                }
            }
            return items
        }
    },
    methods: {
        async getInvoice() {
            //let response = await getInvoice(this.$route.params.invoiceId);
        },
        currencyFormatter(value) {
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            });
            
            return formatter.format(value)
            
        },
        formatDate(date) {
            return moment(date).format("M/D/YYYY")
        }
    },
}
</script>

<style scoped>

</style>