<template>

    <app-card hide-header>
        <div class="">
            <app-data-table
                    :items="reportRows"
                    :headers="invoiceTableHeaders"
                    :loading="loading"
                    @onRowClick="onRowClick"
            >
                <template v-slot:item.InvoiceId="{ item }">
                    {{item.InvoiceId}}
                    <AppChip small label v-if="item.DeletedOn" color="red" >VOID</AppChip>
                </template>
                
                <template v-slot:item.InvoiceTotal="{item}">
                    {{ item.InvoiceTotal.toFixed(2) }}
                </template>
                
                <template v-slot:item.TotalPaid="{item}">
                    {{ item.TotalPaid.toFixed(2) }}
                </template>
                <!--<template #item.paidTotal="{item}">
        
                    <div v-if="item.payment && !item.isRefunded">
                        {{ item.paidTotal }}
                        <app-chip  color="green" label small class="ml-2">Paid</app-chip>
                    </div>
                    <div v-else-if="item.isRefunded">
                        <app-chip  color="yellow" label small class="ml-2">Payment Refunded</app-chip>
                    </div>
                    <div v-else-if="item.deletedOn">
                        <app-chip  color="gray" label small class="ml-2">VOIDED</app-chip>
                    </div>
                    <div v-else>
                        <app-chip :color="item.isPastDue ? 'red' : 'blue'" label small>{{ item.isPastDue ? 'Past Due' : 'Outstanding' }}</app-chip>
                    </div>
        
                </template>-->
            </app-data-table>
        </div>
    </app-card>
</template>

<script>

import {getInvoiceReport} from "@/services/report.service";
import UnsentInvoicesDetails from "@/components/Reporting/InvoiceReports/UnsentInvoicesDetails.vue";
import InvoiceTypeChip from "@/components/Billing/Chips/InvoiceTypeChip.vue";
import InvoiceApprovalStatusChip from "@/components/Billing/Chips/InvoiceApprovalStatusChip.vue";
import InvoiceDaysTillChip from "@/components/Billing/Chips/InvoiceDaysTillChip.vue";

export default {
    name: "SuperAdminInvoicesTable",
    components: {
        InvoiceDaysTillChip, InvoiceApprovalStatusChip, InvoiceTypeChip, UnsentInvoicesDetails
    },
    data () {
        return {
            loading: true,
            reportRows: [],
            invoices: [],
            invoiceTableHeaders: [
                {
                    text: 'Invoice Id',
                    value: 'InvoiceId'
                },
                {
                    text: 'Organization Id',
                    value: 'OrganizationId'
                },
                {
                    text: 'Organization Name',
                    value: 'OrganizationName'
                },
                {
                    text: 'Invoice Total',
                    value: 'InvoiceTotal'
                },
                {
                    text: 'Payment Total',
                    value: 'TotalPaid'
                },
                {
                    text: 'Invoice Date',
                    value: 'InvoiceDate'
                },
            ],
        }
    },

    mounted () {
        //set from query string or from defaults
        this.init();
    },
    computed: {
        
    },
    methods: {
        async init() {
            //this.invoices = await getInvoiceReport();

            const response = await getInvoiceReport();
            this.currentReport = 1
            this.headers = response.headers;
            response.report.sort(function (a,b) {
                return b.InvoiceId - a.InvoiceId;
            });
            this.reportRows = response.report;
        },

        onRowClick(item) {
            window.open(`${window.location.origin}/billing/view-invoices?orgId=${item.OrganizationId}&id=${item.InvoiceId}`, "_blank");
        }
    }
}
</script>

<style scoped>

</style>