<template>
    <div >
        
        <div v-if="numberOfProfiles === 0" style="display: flex; justify-content: center">
            <EmptyState
                description="There are no profiles in this group. Creating a group code to get started."
                button
                buttonText="Create Group Code"
                @buttonClick="() => $router.push(`/groups/${this.group.id}/codes?createCode=true`)"
                :svg="emptyStateSvg"
            >
<!--                <template #bottom-content>-->
<!--                    or <div class="custom-text-link" @click="$router.push(`/groups/${group.id}/profiles?editProfiles=true`)">add existing profiles to group</div>-->
<!--                </template>-->
            </EmptyState>
            
            
        </div>
        <div v-else-if="typeof numberOfProfiles === 'number' && groupStats">
            <v-row  class="mb-2">
                <v-col cols="12" md="3" v-for="stat in groupStats" >
                    <app-card :title="stat.label">
                        <div style="display: flex; flex-direction: column; align-items: center" class="pa-4">
<!--                            <span style="color: grey">{{ stat.label }}</span>-->
                            <span v-if="stat.loading" class="text-h3"><v-progress-circular indeterminate color="secondary" /></span>
                            <span v-else class="text-h3">{{ stat.value }}</span>
                        </div>

                    </app-card>
                </v-col>
            </v-row>
            <v-row class="mb-2">
                <v-col cols="12">
                    <TestDataSummaryChart :tests-data="groupTestSummaryData" />
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <AppPageLoader text="Loading Group..." />
        </div>
    </div>
    
</template>

<script>
import TestDataSummaryChart from "@/components/TestDataSummaryChart";
import EmptyState from "@/components/EmptyState";
import emptyStateSvg from '@/assets/svgs/empty-state/empty-group-profile.svg'
import {getProfilesPaged} from "@/services/profile.service";

export default {
    name: "GroupOverview",
    components: {
        EmptyState,
        TestDataSummaryChart
    },
    props: {
        loadingGroupProfiles: {
            type: Boolean,
            required: true
        },
        // groupProfiles: {
        //     default: []
        // },
        groupTestSummaryData: {
            type: Array
        },
        group: {
            required: true
        },
        sessions: {
            required: true
        },
        users: {
            required: true
        },
        numberOfProfiles: {
            
        }
    },
    data() {
        return {
            emptyStateSvg
        }
    },
    async mounted() {
        if (typeof this.numberOfProfiles !== 'number') {
            

            let request = {
                PageNumber: 1,
                RowsPerPage: 10,
                SortByColumn: null,
                SortAscending: null,
                SearchString: '',
                graduationSearchString: '',
                groupIds: [this.group.id]
            }

            let response = await getProfilesPaged(request);

            let {totalRowCount} = response;
            this.$emit('setNumberOfProfiles', totalRowCount)
        }
    },
    computed: {
        groupStats() {
            if (!this.groupTestSummaryData) return null
            let last7Days = this.groupTestSummaryData.slice(Math.max(this.groupTestSummaryData.length - 7, 0));
            let testsLastWeek = 0;
            last7Days.forEach(day => testsLastWeek += day.totalTestCount)
            
            let activeSessionCount = 0;
            this.sessions.forEach(session => {
                if(session.isActive) {
                    activeSessionCount++
                }
            })
            
            return [
                {
                    label: 'Tests This Week',
                    value: testsLastWeek,
                    loading: false
                },
                {
                    label: 'Number of Profiles',
                    value: this.numberOfProfiles,
                    loading: this.loadingGroupProfiles
                },
                {
                    label: 'Number of Users',
                    value: this.users.length,
                    loading: false
                },
                {
                    label: 'Active Sessions',
                    value: activeSessionCount,
                    loading: false
                },
            ]
        } 
    }
}
</script>

<style scoped>

</style>