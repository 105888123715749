<template>
    <div style="display: flex; align-items: center" >
        <div  style="display: flex; align-items: center; margin-right: 15px; color: #757575">
            {{ label }} <br /> {{ sublabel }}
        </div>
        <div  style="max-height: 300px; overflow-y: scroll; flex: 1">
<!--            <div v-for="(item, index) in value">{{item}}</div>-->
            
            <validation-provider
                v-slot="{ errors, valid }"
                :name="errorLabel ? errorLabel : name"
                :rules="rules"
                
            >

<!--                Acts as a show input for validation purposes-->
                <input type="text" readonly="readonly" :value="value" v-show="false">
<!--                <v-select-->
<!--                    :value="value"-->
<!--                    :items="items"-->
<!--                    required-->
<!--                    outlined-->
<!--                    multiple-->
<!--                    color="text"-->
<!--                    @change="updateSelect"-->
<!--                    :item-text="itemText"-->
<!--                    :item-value="itemValue"-->
<!--                    :menu-props="{ offsetY: true }"-->
<!--                    :error-messages="errors.length > 0 ? [''] : []"-->
<!--                >-->
<!--                    <template #selection="{item}">{{ item.name }}</template>-->
<!--                </v-select>-->
               


                <div v-if="errors" class="error--text text-caption">{{errors[0]}}</div>
<!--                <div class="error&#45;&#45;text text-caption">{{valid}}</div>-->
            </validation-provider>
            <AppMultipleSelect
                :items="items"
                item-title="name"
                :item-value="itemValue"
                :value="value"
                @input="updateSelect"
                :addButtonText="addButtonTest"
                :draggable="draggable"
            ></AppMultipleSelect>
        </div>
    </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import {required} from "vee-validate/dist/rules";

setInteractionMode('eager')

export default {
    name: "AppFormMultipleSelect",
    components: {
        ValidationObserver,
        ValidationProvider
    },
    props: {
        value: {
            required: true
        },
        items: {
            required: true
        },
        errorLabel: {
            
        },
        rules: {
            
        },
        itemText: {
            required: false,
            type: String
        },
        itemValue: {
            required: false,
            type: String,
            default: 'id'
        },
        label: {
            type: String,
            required: true
        },
        sublabel: {
            type: String,
        },
        draggable: {
            type: Boolean
        },
        addButtonTest: {
            type: String,
            default: "Add Group"
        },
        name: {
            
        }
        
    },
    methods: {
        updateSelect(e) {
            this.$emit('input', e)
        }
    }
}
</script>

<style scoped>

</style>