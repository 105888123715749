import moment from "moment";

const _formatDate = Symbol()
const _formatCurrency = Symbol()



export default class Invoice {
    constructor(data) {

        const {
            AmortizationStartDate: amortizationStartDate,
            AmortizationEndDate: amortizationEndDate,
            ApprovalStatus: approvalStatus,
            ApprovalStatusId: approvalStatusId,
            CreatedOn : createdOn,
            DateOfSale : dateOfSale,
            DeletedOn : deletedOn,
            Description : description,
            DisplayDate : displayDate,
            DueDate : dueDate,
            EndPeriodOn : endPeriodOn,
            InvoiceDate : invoiceDate,
            InvoiceId : invoiceId,
            InvoiceTotal : invoiceTotal,
            InvoiceType : invoiceType,
            InvoiceTypeId : invoiceTypeId,
            IsDemo : isDemo,
            IsDisabled : isDisabled,
            IsPOPending : isPOPending,
            IsResearch : isResearch,
            IsSyncedToQB : isSyncedToQB,
            IssuedOn : issuedOn,
            ModifiedByUser : modifiedByUser,
            ModifiedOn : modifiedOn,
            OrganizationCountry : organizationCountry,
            OrganizationCreateDate : organizationCreateDate,
            OrganizationId : organizationId,
            OrganizationName : organizationName,
            OrganizationState : organizationState,
            PONumber : poNumber,
            SalesTaxRegion : salesTaxRegion,
            StartPeriodOn : startPeriodOn,
            SubTotal : subTotal,
            SysEndTime : sysEndTime,
            SysStartTime : sysStartTime,
            Total : total,
            TotalPaid : totalPaid,
            VoidReason : voidReason,
        } = data;

        this.organizationInfo = {
            organizationCountry,
            organizationCreateDate,
            organizationId,
            organizationName,
            organizationState,
            isDemo,
            isDisabled,
            isResearch,
        }

        // Calculate Durations
        this.daysTillDue = moment().diff(dueDate, 'days') * -1;
        this.daysTillDisplayDate = moment().diff(displayDate, 'days');

        // Format Dates
        // this.dates = {
        //     amortizationStartDate: this[_formatDate](amortizationStartDate),
        //     amortizationEndDate: this[_formatDate](amortizationEndDate),
        //     createdOn: this[_formatDate](createdOn),
        //     dateOfSale: this[_formatDate](dateOfSale),
        //     deletedOn: this[_formatDate](deletedOn),
        //     displayDate: this[_formatDate](displayDate),
        //     dueDate: this[_formatDate](dueDate),
        //     endPeriodOn: this[_formatDate](endPeriodOn),
        //     invoiceDate: this[_formatDate](invoiceDate),
        //     startPeriodOn: this[_formatDate](startPeriodOn),
        // }

        this.amortizationStartDate = this[_formatDate](amortizationStartDate);
        this.amortizationEndDate = this[_formatDate](amortizationEndDate);
        this.createdOn = this[_formatDate](createdOn);
        this.dateOfSale = this[_formatDate](dateOfSale);
        this.deletedOn = this[_formatDate](deletedOn);
        this.displayDate = this[_formatDate](displayDate);
        this.dueDate = this[_formatDate](dueDate);
        this.endPeriodOn = this[_formatDate](endPeriodOn);
        this.invoiceDate = this[_formatDate](invoiceDate);
        this.startPeriodOn = this[_formatDate](startPeriodOn);
        
        this.approvalStatusId = approvalStatusId;
        this.description = description;
        this.id = invoiceId;
        this.invoiceTotal = invoiceTotal;
        this.invoiceTypeId = invoiceTypeId;
        this.isPOPending = isPOPending;
        this.issuedOn = issuedOn;
        this.poNumber = poNumber;
        this.salesTaxRegion = salesTaxRegion;
        this.subTotal = this[_formatCurrency](subTotal);
        this.total = this[_formatCurrency](total);
        this.totalPaid = this[_formatCurrency](totalPaid);
        this.voidReason = voidReason;
        
        
    }

    [_formatDate](date) {
        if (!date) return null
        return moment(date).format("YYYY-MM-DD")
    }

    [_formatCurrency](value) {
        return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
    }
    
    static invoiceApprovalStatuses = [
        {
            name: "unknown",
            text: "🟠 Unknown",
            value: 0,
            chipColor: "orange",
            description: "The approval status is unknown, meaning automated emails will not be sent."
        },
        {
            name: "unapproved",
            text: "❌ Unapproved - Auto",
            value: 1,
            chipColor: "red",
            description: "This invoice was created automatically but has not been approved by Sway personnel."
        },
        {
            name: "approvedAuto",
            text: "✅ Approved - Auto",
            value: 2 ,
            chipColor: "green",
            description: "This invoice was created automatically and has been reviewed & approved by Sway personnel."
        },
        {
            name: "approvedManual",
            text: "✅ Approved - Manual",
            value: 3 ,
            chipColor: "green",
            description: "This invoice was created manually and is therefore automatically approved."
        }
    ]

    static invoiceTypes = [
        {
            name: "newOrg",
            text: "New Org",
            value: 1,
            chipColor: "green",
        },
        {
            name: "renewal",
            text: "Renewal",
            value: 2,
            chipColor: "blue",
        },
        {
            name: "overage",
            text: "Overage",
            value: 3,
            chipColor: "orange",
        },
        {
            name: "upgrade",
            text: "Upgrade",
            value: 4,
            chipColor: "purple",
        },
        {
            name: "other",
            text: "Other",
            value: 5,
            chipColor: "yellow",
        }
    ]
    // calculate days 
}