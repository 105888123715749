<template>
    <div>
        <v-sheet
            tile
            height="54"
            class="d-flex"
            style="display: flex; align-items: center"
        >

            <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.prev()"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
                icon
                class="ma-2"
                @click="$refs.calendar.next()"
            >
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
<!--                <v-spacer></v-spacer>-->
            <v-select
                v-model="type"
                :items="types"
                dense
                hide-details
                class="ma-2"
                label=""
                outlined
                style="max-width: 200px"
            ></v-select>
            <v-btn
                
                class="ma-2"
                @click="$emit('edit')"
                :elevation="0"
                small
            >
                <v-icon small>mdi-pencil</v-icon> <span class="pl-2">Edit Schedule</span>
            </v-btn>
<!--            <v-spacer></v-spacer>-->
            
                
        </v-sheet>
        <v-sheet height="600">
            <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="type"
                :events="events"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"

            ></v-calendar>
        </v-sheet>
    </div>
</template>

<script>
export default {
    name: "Calendar",
    props: {
        events: {
            type: Array
        }
    },
    data: () => ({
        type: 'month',
        types: ['month', 'week'],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        weekdays: [
            { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
            { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
            { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
            { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        ],
        value: '',
        defaultEvents: [],
        colors: ['blue', 'green', 'cyan'],
        names: ['COVID Screening', 'Balance', 'Symptoms'],
    }),
    computed: { 
        displayedEvents() {
            return this.events && this.events.length > 0 ? this.events : this.defaultEvents
        }
    },
    methods: {
        
        getEventColor (event) {
            return event.color
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
    },
}
</script>

<style scoped>

</style>