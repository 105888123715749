<template>
    <div>
        <AppCard title="Baselines" v-if="data && data.length > 0">
	
	        <div class="pa-3">
		        <div v-for="(item, index) in data" :key="index" class="mb-4">
			        <TestCard :title="item.label" :testCategory="item.category">
				        <template>
					        <div style="display: flex; align-items: center">
						        <div style="margin-right: 50px">
							        <div style="display: flex; flex: 1; justify-content: center; flex-direction: column">
								        <div
									        style="display: flex; text-align: center; flex: 1"
									        v-for="(stat, statIndex) in stats"
									        :key="statIndex"
								        >
									        <div class="stat-label">
										        {{ stat.label }}:
									        </div>
									        <div class="stat-value">
										        <div v-if="item[stat.value]">
											        {{ item[stat.value] }}
										        </div>
										        <div v-else>
											        -
										        </div>
									
									        </div>
								        </div>
							        </div>
						        </div>
						
						        <div style="flex: 1">
							        <highcharts :options="item.baselineColumnChartOptions" :ref="item.name" style="height: 75px"/>
						        </div>
					        </div>
				        </template>
			        </TestCard>
		        </div>
	        </div>
	        
            <div class="pa-3">
                <div
                    v-for="(categoryId, categoryIndex) in profile.categoryIdsTaken"
                    class=""
                    v-if="measuresCategoriesById[categoryId].hasChartableBaselines"
                    :key="categoryIndex"
                >
                    <div style="width: 100%; background-color: #F4F4F4; display: flex; padding: 5px 10px; margin-bottom: 10px">
                        <div style="font-weight: 800; text-transform: uppercase; color: #898C90; flex: 1">
                            {{measuresCategoriesById[categoryId].label}}
                        </div>

                    </div>
                    <div v-for="(item, index) in data" :key="index" style="margin: 20px 0">
                        <div v-if="item.category === categoryId" style="display: flex; align-items: center">
                            <div style="margin-right: 50px">
                                <div style="min-width: 200px; font-weight: 800">
                                    {{item.label}}
                                </div>
                                <div style="display: flex; flex: 1; justify-content: center; flex-direction: column">
                                    <div 
                                        style="display: flex; text-align: center; flex: 1"
                                        v-for="(stat, statIndex) in stats"
                                        :key="statIndex"
                                    >
                                        <div class="stat-label">
                                            {{ stat.label }}:
                                        </div>
                                        <div class="stat-value">
                                            <div v-if="item[stat.value]">
                                                {{ item[stat.value] }}
                                            </div>
                                            <div v-else>
                                                -
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div style="flex: 1">
                                <highcharts :options="item.baselineColumnChartOptions" :ref="item.name" style="height: 75px"/>
                            </div>
                            

                        </div>
                       
                    </div>

                </div>
            </div>

        </AppCard>
        <div v-else>
            <EmptyState title="No Baselines" :svg="emptyProfileGraphic" description="This profile does not have any established baselines." />
            
        </div>
    </div>
</template>

<script>
import {getMeasuresAsArrayByCategoryId, getOrdinalSuffix} from "@/js/shared/helper";
import {allMeasuresFromConstants, measuresCategoriesById} from "@/js/shared/constants";
import EmptyState from "@/components/EmptyState";
import emptyProfileGraphic from '@/assets/svgs/empty-state/empty-profile-graphic.svg'
import TestCard from "@/components/Profile/TestsViewer/TestCard";

export default {
    name: "ProfileBaselines",
    components: {TestCard, EmptyState},
    props: {
        profile: {
            required: true
        }
    },
    data() {
        return {
            measuresCategoriesById,
            emptyProfileGraphic,
            data: [],
            stats: [
                {
                    label: "Baseline Average",
                    value: "baselineAverage"
                },
                {
                    label: "Range",
                    value: "baselineRange"
                },
                {
                    label: "Percentile",
                    value: "percentileNumber"
                },
                {
                    label: "Rank",
                    value: "percentileDescription"
                },
                {
                    label: "Baseline Count",
                    value: "baselineTestCount"
                },
                // {
                //     label: "Baseline Average",
                //     value: "baselineAverage"
                // },
                
            ]
        }
    },
    mounted() {

        this.init()

    },
    methods: {
        init() {
            let self = this;
            const measures = getMeasuresAsArrayByCategoryId(this.categoryId);



            let data = []

            measures.forEach(measure => {
                
                if (measure.isNotChartable || measure.hasNoBaseline) return

                if (this.profile.measureIdsTaken.includes(measure.measureId)) {

                    let baselineColumnChartOptions = self.profile.getBaselineColumnChartByMeasureId({measure: measure.measureId});
                    let formattedAverage = self.profile.formattedAverages[measure.measureId];
                    const {lowScore, highScore, percentileNumber, percentileDescription, display, baselineTestCount} = formattedAverage;
                    let showTimeScores = allMeasuresFromConstants[measure.measureId].showTimeScores;
                    
                    
                    let baselineRange = showTimeScores ? `${lowScore.toFixed(0)} ms - ${highScore.toFixed(0)} ms` : `${lowScore.toFixed(1)} - ${highScore.toFixed(1)}`
                    data.push({
                        ...measure,
                        baselineColumnChartOptions,
                        baselineRange,
                        percentileNumber: percentileNumber ? `${percentileNumber}${getOrdinalSuffix(percentileNumber)}` : null,
                        percentileDescription,
                        // currentValue: null,
                        // percentChange: null,
                        showTimeScores,
                        baselineAverage: display,
                        baselineTestCount
                    })
                }

            });

            this.data = data;
        },
    }
}
</script>

<style scoped>
.stat-label {
    color: var(--v-text-lighten3);
    margin-right: 10px
}

.stat-value {
    /*font-weight: bold*/
}
</style>