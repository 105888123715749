import httpService from "@/services/httpService";
import {baseUrl} from "@/config"
import {getProfilesPaged} from "@/services/profile.service";


const endpoint = baseUrl + "/Group"

const groupModel = {
        id: 0, // Int
        organizationId: 0, // Int
        name: "", // String
        isDefault: true, // Boolean
        isEnabled: true // Boolean
}

const groupPreferencesModel = {
    groupPreferencesId: 0,
    organizationId: 0,
    groupId: 0,
    reminderPushNotificationEnabled: true,
    reminderPushNotificationWeekdays: true,
    reminderPushNotificationWeekends: true,
    reminderPushNotificationHolidays: true,
    averageScoreThreshold: 0,
    createdByApp: "string",
    testScheduleCron: "string",
    testDemandDurationMinutes: 0,
    demandTypeId: 0
}

const bulkMoveRequest = {
    profileIds: [],
    oldGroupId: 0,
    newGroupId: 0,
    removeFromOldGroup: true
}

const getGroups = async () => {
    try {
        return await httpService.post(endpoint + '/GetGroups', )
    } catch (e) {
    }
}

const getGroupsForEnterpriseAccount = async (id) => {
    try {
        return await httpService.post(endpoint + '/GetGroupsForEnterpriseAccount', {id} )
    } catch (e) {
    }
}

const getGroup = async (id) => {
    try {
        const response = await httpService.post(endpoint + '/GetGroup', {id})
        console.log(response)
        return response
    } catch (e) {
    }
}

const getGroupProfiles = async (id, searchString = "") => {
    let request = {
        pageNumber: 1,
        rowsPerPage: 1000000,
        sortByColumn: null,
        sortAscending: true,
        searchString: searchString,
        groupIds: [
            parseInt(id)
        ]
    }

    try {
        let response = await getProfilesPaged(request)
        return response
    } catch (e) {
    }
}

const createGroup = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/CreateGroup', request)
        console.log(response)
        return response
    } catch (e) {
    }
}

const updateGroup = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/UpdateGroup', request)
        return response
    } catch (e) {
    }
}

const adjustGroupBaselineStartDate = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/AdjustGroupBaselineStartDate', request)
        console.log(response)
        return response
    } catch (e) {
    }
}

const adjustGroupDefaultOrganizationProtocol = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/AdjustGroupDefaultOrganizationProtocol', request)
        console.log(response)
        return response
    } catch (e) {
    }
}


const getGroupNotificationPreferences = async (request) => {
    try {
        return await httpService.post(endpoint + '/GetGroupNotificationPreferences', request)
    } catch (e) {
    }
}

const updateGroupNotificationPreferences = async (request) => {
    try {
        return await httpService.post(endpoint + '/UpdateGroupNotificationPreferences', request)
    } catch (e) {
    }
}

const updateGroupProfiles = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/UpdateGroupProfiles', request)
        return response
    } catch (e) {
    }
}

const updateGroupAccounts = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/UpdateGroupAccounts', request)
        return response
    } catch (e) {
    }
}

const createOrUpdateGroupPreferences = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/CreateOrUpdateGroupPreferences', {groupPreferences: request})
        return response
    } catch (e) {
    }
}

const deleteGroup = async (id) => {
    try {
        const response = await httpService.post(endpoint + '/DeleteGroup', {id})
        return response
    } catch (e) {
    }
}

const getGroupTestDataSummary = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/GetGroupTestDataSummary', request)
        return response
    } catch (e) {
    }
}

const bulkMove = async (request) => {
    try {
        return await httpService.post(endpoint + '/BulkMove', request)
    } catch (e) {
    }
}

const getGroupsReport = async (orgId) => {
    // let x  = organizationPreferencesModel;
    // let y = organizationPreferences;
    // 

    // try {
    //     return await httpService.post(endpoint + '/GetTestResultsExport', request )
    // } catch (e) {
    // }

    try {
        let response = await httpService.post(endpoint + '/GetGroupsReport', null, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/zip'
            },
            responseType: 'arraybuffer'
        })

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        let documentName = `GroupReport_${orgId}.zip`

        link.setAttribute('download', documentName); //or any other extension
        document.body.appendChild(link);
        link.click();
        return response


    } catch(error) {
        console.log(error)
    }
}



export {
    getGroups,
    getGroupsForEnterpriseAccount,
    getGroup,
    groupModel,
    groupPreferencesModel,
    createGroup,
    updateGroup,
    deleteGroup,
    updateGroupProfiles,
    getGroupTestDataSummary,
    createOrUpdateGroupPreferences,
    getGroupNotificationPreferences,
    updateGroupNotificationPreferences,
    getGroupProfiles,
    updateGroupAccounts,
    bulkMove,
    bulkMoveRequest,
    adjustGroupBaselineStartDate,
    getGroupsReport,
    adjustGroupDefaultOrganizationProtocol
}
