<template>
    <div>
        <page-header title="Create Survey"></page-header>
        <v-container>
            <app-card class="pa-4">
                <v-text-field
                    v-model="surveyTitle"
                    style="font-size: 2em; font-weight: 800"
                >
                    
                </v-text-field>
                <div>Form Description</div>
            </app-card>
            <draggable v-model="questions" group="people" @start="drag=true" @end="drag=false" handle=".handle">
                <app-card class="question-card mt-4 pa-4" v-for="(question, index) in questions" :key="index">
                    <div class="handle">
                        <v-icon>mdi-drag-horizontal</v-icon>
                    </div>
                    <div style="display: flex">
                        <v-text-field
                            placeholder="Untitled Question"
                            style="flex: 1"
                            v-model="question.title"
                        >
                            
                        </v-text-field>
                        <v-spacer></v-spacer>
                        <v-select
                            :items="questionTypes"
                            v-model="question.type"
                            style="max-width: 200px"
                            outlined
                        >
    
                        </v-select>
                    </div>
                    <div >
                        <div  v-for="(item, index) in [1,2,3]" :key="index">
                            <div style="display: flex" class="mb-2">
                                <div style="height: 20px; width: 20px; border-radius: 20px; border: 1px solid lightgrey"/>
                                <div class="ml-3">Option {{ item }}</div>
                            </div>
                            
                        </div>
    
                    </div>
                    <div style="display: flex">
                        <v-spacer>
                            
                        </v-spacer>
                        <app-fab-btn type="copy" small />
                        <app-fab-btn type="delete" small @click="deleteQuestion(index)"/>
                        
                        
                    </div>
                </app-card>
            </draggable>
            <div style="display: flex; justify-content: center" class="mt-4">
                <app-btn fab @click="addNewQuestion">
                    <v-icon>mdi-plus</v-icon>
                </app-btn>
            </div>
        </v-container>

    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: "CreateSurvey",
    components: {
        draggable,
    },
    data() {
        return {
            questionTypes: [
                {
                    text: 'Multiple Choice',
                    value: 'multipleChoice'
                },
                {
                    text: 'Yes/No',
                    value: 'y/n'
                },
                {
                    text: 'Short Answer',
                    value: 'freeform'
                },

            ],
            questions: [],
            surveyTitle: 'Untitled Survey'
        }
    },
    mounted() {
        let questions = [];
        questions.push({
            title: 'Untitled Question',
            type: 'multipleChoice',
            options: [
                {
                    text: 'Yes',
                    value: 1
                },
                {
                    text: 'No',
                    value: 2
                },

            ]
        });
        this.questions = questions
    },
    methods: {
        addNewQuestion() {
            let questions = [...this.questions];
            questions.push({
                title: 'Untitled Question',
                type: 'multipleChoice',
                options: [
                    {
                        text: 'Yes',
                        value: 1
                    },
                    {
                        text: 'No',
                        value: 2
                    },

                ]
            })
            this.questions = questions
        },
        deleteQuestion(index) {
            let questions = JSON.parse(JSON.stringify(this.questions))
            questions.splice(index, 1);
            this.questions = questions
            
        }
    }
}
</script>

<style scoped>
.question-card {
    border-left: 5px solid var(--v-secondary-base)
}

.handle {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.handle:hover {
    cursor: move;
}
</style>