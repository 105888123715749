<template>
    <div>
        <PageHeader :title="welcomeString" />
        <div v-if="!loadingOrganizations">
            <div>
                <page-header
                    title="Organizations"
                >
                    <template #right-content>
                        <strong>Click an organization to view its profiles, tests, and more.</strong>
                        <app-btn @click="enrollChildOrg" style="margin-left:20px">Create New Child Organization</app-btn>
                    </template>
                    <!--                <div slot="right-content">-->
                    <!--                    <app-btn @click="addGroupDialogOpen = true">Add Group</app-btn>-->
                    <!--                </div>-->
                </page-header>
                <app-card hide-header>
                    <app-data-table
                        :headers="headers"
                        :items="organizations"
                        @onRowClick="onRowClick($event)"
                    >
                        <template #item.isEnabled="{item}">
                            <div style="display: flex; align-items: center">
                                <div :style="{'background-color': item.isEnabled ? 'limegreen' : 'red'} " class="dot mr-2"></div>
                                <div>
                                    {{item.isEnabled ? 'Active' : 'Inactive'}}
                                </div>

                            </div>
                        </template>


                    </app-data-table>
                </app-card>
            </div>



        </div>
        <div v-else>
            <AppPageLoader text="Loading Organizations..."/>
        </div>
        <br />
	    <AppCard class="mt-2" title="Locations">
		    <GmapMap
			    ref="gmap"
			    :center="mapCenter"
			    :zoom='12'
			    style='width:100%;  height: 600px;'
		    >
			    <GmapMarker
				    :key="index"
				    v-for="(m, index) in mapMarkers"
				    :position="m.position"
				    @click="toggleInfoWindow(m,index)"
			    />
			    <GmapInfoWindow
				    :options="infoOptions"
				    :position="infoWindowPos"
				    :opened="infoWinOpen"
				    @closeclick="infoWinOpen=false"
			    >
<!--				    <div v-html="infoContent"></div>-->
				    <MapLocationCard v-bind="infoContent" @goToOrg="onRowClick($event)" />
			    </GmapInfoWindow>
		
		    </GmapMap>
	    </AppCard>
        
        
        <!--    <router-view></router-view>-->
        <!--    <v-img src="@/assets/images/sway_gif_150x105.gif" height="150" width="300"/>-->
    </div>
</template>

<script>
import AppSimpleTable from "@/components/tables/AppSimpleTable";
import {getUser, isSuperView, isEnterpriseAdminOrg, switchSelectedOrganization} from "@/services/userService";
import {getOrganizationTestDataSummary, getTotalGroupsCount, getTotalProfileCount, getDailyTestCounts, getTotalTestCount} from "@/services/organization.service";
import {getProfilesPaged} from '@/services/profile.service'
import {getSessions} from '@/services/session.service'
import {getAccounts} from "@/services/userService";
import {getEnterpriseOrganizations} from "@/services/enterprise.service";
import moment from "moment";
import apiMixin from '@/mixins/api'
import AppStatCard from "@/components/AppStatCard";
import TestDataSummaryChart from "@/components/TestDataSummaryChart";
import SwayLoader from "@/components/SwayLoader";
import {getSessionDetails, asLocalTime} from "@/js/shared/helper";
import {getGroups} from "@/services/groups.service";
import MapLocationCard from "@/components/EnterpriseDashboard/MapLocationCard";

export default {
    name: "EnterpriseDashboard",
    props: ['account'],
    mixins: [apiMixin],
    components: {
        SwayLoader,
        AppSimpleTable,
        AppStatCard,
        TestDataSummaryChart,
	    MapLocationCard
    },
    
    data() {
        return {

            organizations: [],
            loadingOrganizations: false,
            addGroupDialogOpen: false,
            savingNewGroup: false,
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                },
                {
                    text: 'ID',
                    value: 'id',
                },
                {
                    text: 'City',
                    value: 'city',
                },
                {
                    text: 'State',
                    value: 'state',
                },
                {
                    text: 'Last Test Date',
                    value: 'lastTestDate',
                },
                {
                    text: 'Total Tests',
                    value: 'totalTestCount',
                },
                {
                    text: 'Total Profiles',
                    value: 'totalProfileCount',
                },
            ],
            addGroupForm: {
                name: '',
                isInactive: false,
                users: [],
                covidEnabled: false
            },
            users: [
                'Jordan Beacham',
                'Alex Pettigrew',
                'Justin Battenfield',
                'Chase Curtiss',
                'Brek Wilkings',
                'Alex Golimbievsky',
                'Michael Naizer'
            ],
            
            totalChartOptions: null,
            covidChartOptions: null,
            chartsLoading: false,
            historicalTestsData: null,
            chartDataToDisplay: 'totalTestCount',
            mostRecentProfileTested: [],
            loadingRecentTests: false,
            mostRecentSessions: [],
            loadingRecentSessions: false,
            profileCount: null,
            groupsCount: null,
            totalTestsCount: null,
            userCount: null,
            mapCenter: { lat: 32.535841, lng: -97.30681 },
            mapCurrentPlace: null,
            mapMarkers: [],
            mapPlaces: [],
            infoContent: '',
            infoWindowPos: {
                lat: 0,
                lng: 0
            },
            infoWinOpen: false,
            currentMidx: null,
            //optional: offset infowindow so it visually sits nicely on top of our marker
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            
            // mostRecentProfileTestedTableHeaders: ,
            // sessionsTableHeaders: ['Code', 'Start Date', 'End Date', 'Created On'],
            sessionsTableHeaders: [
                {
                    text: 'Code',
                    value: 'code'
                },
                {
                    text: 'Name',
                    value: 'displayName'
                },
                {
                    text: 'Protocol',
                    value: 'protocolName'
                },
                {
                    text: 'End Date',
                    value: 'endsOnFormatted'
                },

            ],
            chips: [
                {
                    label: 'All',
                    value: 'totalTestCount'
                },
                {
                    label: 'COVID',
                    value: 'covidTestCount'
                },
                {
                    label: 'Balance',
                    value: 'balanceTestCount'
                },
                {
                    label: 'Reaction Time',
                    value: 'reactionTimeTestCount'
                },
                {
                    label: 'Impulse Control',
                    value: 'impulseControlTestCount'
                },
                {
                    label: 'Inspection Time',
                    value: 'inspectionTimeTestCount'
                },
                {
                    label: 'Memory',
                    value: 'memoryTestCount'
                },
                {
                    label: 'Number Counting',
                    value: 'numberCountingTestCount'
                },
            ]
        }
    },
    computed: {
        mostRecentProfileTestedTableHeaders() {
            let {viewingAsSuper} = this.$store.state.currentAccount;
            if (viewingAsSuper) {
                return [
                    {
                        text: 'ID',
                        value: 'id'
                    },
                    {
                        text: 'Date',
                        value: 'lastTestDate'
                    },
                ]
            }
            return [
                {
                    text: 'Date',
                    value: 'lastTestDate'
                },
                {
                    text: 'ID',
                    value: 'id'
                },
                {
                    text: 'First Name',
                    value: 'firstName'
                },
                {
                    text: 'Last Name',
                    value: 'lastName'
                },
            ]
        },
        welcomeString() {
            // return `Hey ${this.account ? this.account.firstName : null}`

            if (isEnterpriseAdminOrg()){
                return 'Dashboard (Enterprise View)'
            }
            else{
                return 'Dashboard'
            }
        },
        // firstName() {
        //     return this.$store.state.currentAccount ? this.$store.state.currentAccount.firstName : null
        // },
        organizationStats() {
            const {profileCount, groupsCount, totalTestsCount, userCount} = this;
            return [
                {
                    title: 'Total Tests Taken (all time)',
                    value: totalTestsCount ? totalTestsCount : '-',
                    loading: false
                },
                {
                    title: 'Number of Profiles (all time)',
                    value: profileCount ? profileCount : '-',
                    loading: false
                },
                {
                    title: 'Number of Groups',
                    value: groupsCount ? groupsCount : '-',
                    loading: false
                },
                // {
                //     title: 'Number of Users',
                //     value: userCount ? userCount : '-',
                //     loading: false
                // },
            ]
        },
        isSuperView() {
            return isSuperView();
        }
    },
    async mounted() {

        console.log(this.$refs)
        
        
        
        this.loadingOrganizations = true;
        const response = await getEnterpriseOrganizations(false);
        if (!response.isSuccess) {
            return
        }
        // this.groups = response.groups.map(group => ({
        //     name: group.name,
        //     id: group.id
        // }));

        let organizations = response.organizations;


        // groups.sort((a, b) => {
        //     // if (a.isActive && b.isActive) {
        //     //     if (moment(a.endsOn).isAfter(b.endsOn)) return 1
        //     // }
        //     if (a.isEnabled) return -1
        // })

        this.organizations = organizations;
        // this.organizations = []
        this.loadingOrganizations = false;
        
        await organizations.forEach((org) => {;
            
            if (org.latitude && org.longitude){
                this.addOrgMarker(org)
            }
        });

        this.fitBounds();
        
        let account = this.$store.state.currentAccount;
        this.account = account ? account : 'null';
        // this.getTotalUsersCount()

    },

    methods: {
	    async onRowClick(item) {
        
		    await switchSelectedOrganization(item.id);
        
        try {
          await this.$router.push(`/dashboard?orgId=${item.id}`);
        }catch (e) {
          alert("Make sure you have granted yourself access to this organization on the Users page. Audit logs are kept to ensure only authorized users can access specific organization profile data.")
          return;
        }
        
		    location.reload();
	    },
	
	    async getTotalProfileCount() {
		    let response = await getTotalProfileCount();
		    if (!response.isSuccess) return
		    this.profileCount = response.count
	    },
	    async getTotalUsersCount() {
		    let response = await getAccounts();
		    if (!response.isSuccess) return
		    this.userCount = response.accounts.length;
	    },
	    async getTotalGroupsCount() {
		    let response = await getTotalGroupsCount();
		    if (!response.isSuccess) return
		    this.groupsCount = response.count
	    },
	    async getTotalTestCount() {
		    let response = await getTotalTestCount();
		    if (!response.isSuccess) return
		    this.totalTestsCount = response.testCount
	    },
	    selectProfileTest(item) {
		    // TODO: can we add the most recent testId to the general profile info?
		    this.$router.push(`/profiles/${item.id}/overview`)
	    },
	    selectSession(item) {
		    // TODO: can we add the most recent testId to the general profile info?
		    this.$router.push(`/codes/${item.id}`)
	    },
	    selectChartDataType(value) {
		    this.chartDataToDisplay = value;
		    this.setupCharts()
	    },
	    async setupCharts() {
		    return
		    this.chartsLoading = true;
		    if (!this.historicalTestsData) {
			
			    const response = await getOrganizationTestDataSummary(7);
			
			    if (!response.isSuccess) {
				    return
			    }
			    const {organizationTestDataSummary} = response;
			    this.historicalTestsData = organizationTestDataSummary;
		    }
		
		    let totalTestsCategories = [];
		    let totalTestsData = [];
		
		    this.historicalTestsData.forEach(day => {
			    totalTestsCategories.push(moment(day.date).format('M/D/YY'))
			    totalTestsData.push(day[this.chartDataToDisplay]);
		    })
		
		    this.chartsLoading = false
	    },
	    async setupProfilesTable() {
		    this.loadingRecentTests = true
		    const response = await getProfilesPaged({
			    PageNumber: 1,
			    RowsPerPage: 5,
			    SearchString: "",
			    SortAscending: false,
			    SortByColumn: "lastTestDate"
		    });
		
		    if (!response.isSuccess) {
			    return this.loadingRecentTests = false
		    }
		
		    const {profiles: mostRecentProfileTested} = response
		
		    mostRecentProfileTested.forEach((profile) => {
			    profile.lastTestDate = asLocalTime(moment.utc(profile.lastTestDate)).format('M/D @ h:mm a z')
		    })
		    this.mostRecentProfileTested = mostRecentProfileTested;
		    // this.mostRecentProfileTested = [];
		    this.loadingRecentTests = false
	    },
	    async setupSessionsTable() {
		    this.loadingRecentSessions = true
		    const data = await getSessions();
		    let responseSessions = data.sessions;
		    let formattedSessions = responseSessions.map(session => {
			    session = getSessionDetails(session)
			    return session
		    })
		
		    const filteredSessions = formattedSessions.filter(session => session.isActive)
		    let slicedSessions = filteredSessions.slice(0, 5);
		
		    this.mostRecentSessions = slicedSessions;
		    // this.mostRecentSessions = [];
		    this.loadingRecentSessions = false
	    },
	    goToSubscriptionPage() {
		    this.$router.push(`/subscription`)
	    },
	    addOrgMarker(org) {
		    // if (this.currentPlace) {

		    const marker = {
			    lat: org.latitude,
			    lng: org.longitude,
		    };
		    this.mapMarkers.push({
			    position: marker,
			    name: org.name,
			    address1: org.address1,
			    address2: org.city + ', ' + org.state + ', ' + org.postalCode,
                totalTestCount: org.totalTestCount,
                totalProfileCount: org.totalProfileCount,
			    id: org.id
		    });
		    //this.mapPlaces.push(this.currentPlace);
		    this.mapCenter = marker;
		    this.currentPlace = null;
		
		    //this.fitBounds();
		    //}
	    },
	    fitBounds: function () {
		
		
		    // var b = new google.maps.LatLngBounds();
		    //
		    // for (let m of this.mapMarkers) {
		    //     b.extend(m.position)
		    // }
		    //
		    // this.$refs.mmm.fitBounds(b);
		    //
		    // this.$refs.gmap.fitBounds(b)
		
		    //set bounds of the map
		    this.$refs.gmap.$mapPromise.then((map) => {
			    const bounds = new google.maps.LatLngBounds()
			    for (let m of this.mapMarkers) {
				    bounds.extend(m.position)
			    }
			    map.fitBounds(bounds);
		    });
	    },
	    toggleInfoWindow: function (marker, idx) {
		    this.infoWindowPos = marker.position;
		    this.infoContent = marker
		
		
		    //check if its the same marker that was selected if yes toggle
		    if (this.currentMidx == idx) {
			    this.infoWinOpen = !this.infoWinOpen;
		    }
		    //if different marker set infowindow to open and reset current marker index
		    else {
			    this.infoWinOpen = true;
			    this.currentMidx = idx;
		    }
	    },
        enrollChildOrg() {
            this.$router.push('/enterprise-enrollment')
        },
    }

}
</script>

<style scoped>
.custom-card {
    /*height: 200px*/
}

h4 {
    color: var(--v-dark-base)
}

.table {
    color: var(--v-text-base)!important
}
</style>