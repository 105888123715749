import Subscription from "@/js/shared/classes/Subscription/Subscription";

export default class SportsSubscription extends Subscription {
    constructor(data) {
        super(data)
        this.usageData = []
    }
    
    getSubscriptionForm = () => {
        const {
            subscriptionStartDate,
            subscriptionEndDate,
            isDemo,
            systemFee,
            isManualBilling,
            profilePrice,
            billingCycle,
            doNotRenew,
            isFree,
            productSubscriptionId,
            profileCount,
            packageTier,
            manualEstimatedTotal,
            profileAddOnCount,
            
            updateFutureSubscriptions
        } = this.data;

        return {
            subscriptionStartDate,
            subscriptionEndDate,
            billingCycle,
            profileCount,
            profilePrice,
            packageTier,
            systemFee,
            isManualBilling,
            isDemo,
            productSubscriptionId,
            isFree,
            doNotRenew,
            isPrePaid: true,
            manualEstimatedTotal,
            profileAddOnCount,
            
            updateFutureSubscriptions
        }
    }
    
    static getNewSportsSubscriptionForm = () => {
        return {
            subscriptionStartDate: null,
            subscriptionEndDate: null,
            billingCycle: null,
            profileCount: null,
            profilePrice: null,
            packageTier: null,
            systemFee: null,
            isManualBilling: false,
            isDemo: false,
            productSubscriptionId: null,
            isFree: false,
            doNotRenew: false,
            isPrePaid: true,
            manualEstimatedTotal: null,
            profileAddOnCount: null,
            
            updateFutureSubscriptions: false
        }
    }
    
    addUsageDataItem(usageData) {
        this.usageData.push(usageData)
    }
    
    getProfileCreditBalance() {
        if (!this.usageData.length) return null
        let something = this.usageData;
        
        // handle free subscriptions
        if (this.isFree || this.isDemo) return Infinity
        if (this.isManualBilling) return null
        
        let totalPurchased = 0;
        let totalUsed = 0;
        this.usageData.forEach(x => {
            if (x.usageType.value === 2 || x.usageType.value === 4) totalPurchased += x.usageCount
            else totalUsed += x.usageCount
        })
        
        return totalPurchased - totalUsed
        
    }
}