<template>
	<div style="background-color: white; border: 1px solid #ddd; border-radius: 10px; overflow: hidden">
		<div class="test-card-header-row">
			<div class="test-card-icon" v-if="testCategory" :style="{backgroundColor: iconBackgroundColor}">
				<img :src="icon" style="width: 40px" />
			</div>
			<div class="test-card-header-content">
				<div class="test-card-header-title">{{ title }}</div>
				<slot class="test-card-header-right-slot" name="header-right"></slot>
			</div>
			
		</div>
		<div class="test-card-content">
			<slot></slot>
		</div>
		
	</div>
</template>

<script>
import balanceIcon from '@/assets/svgs/icons/balance-icon-square.svg'
import cognitiveIcon from '@/assets/svgs/icons/cognitive-icon-square.svg'
import functionIcon from '@/assets/svgs/icons/function-icon-square.svg'
import surveyIcon from '@/assets/svgs/icons/survey-icon-square.svg'

export default {
	name: "TestCard",
	props: {
		title: {},
		testCategory: {}
	},
	data() {
		return {
			balanceIcon,
			cognitiveIcon,
			functionIcon,
			surveyIcon
		}
	},
	computed: {
		iconBackgroundColor() {
			return '#34d3fd'
		},
		icon() {
			const {
				balanceIcon,
				cognitiveIcon,
				functionIcon,
				surveyIcon
			} = this;
			
			switch (this.testCategory) {
				case 1: 
					return balanceIcon;
				case 2:
					return functionIcon
				case 11: 
					return cognitiveIcon
				default:
					return surveyIcon
			}
		}
	}
}
</script>

<style scoped>
.test-card-content {
	padding: .5rem
}

.test-card-icon {
	border-right: 1px solid var(--v-border-base);
	display: flex;
	justify-content: center;
	align-items: center;
}

.test-card-header-row {
	display: flex; 
	border-bottom: 1px solid var(--v-border-base)
}

.test-card-header-title {
	flex: 3;
	font-weight: bold;
}

.test-card-header-content {
	display: flex;
	padding: .5rem;
	flex: 1
}


</style>