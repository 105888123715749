<template>
    <div >
        <PageHeader title="Test Results" :printable="!!profileTest" @print="exportPdf" :printing="exportingPdf">
            <template #title-suffix v-if="profileTest"> 
                <div class="ml-2">
                    <app-chip label color="secondary" dark class="mr-2">Is Event</app-chip>
                    <app-chip label color="secondary" dark>Sports+</app-chip>
                </div>
                
            </template>
            
        </PageHeader>
        
        <div v-if="profileTest">{{getDisplayDate(profileTest.completedOn)}}</div>
        
        <v-divider class="mt-4 mb-4"></v-divider>
        <app-page-loader text="Loading Test..." v-if="!profileTest"></app-page-loader>
        <v-container v-else>
            <v-row>
                <v-col cols="12" md="12" v-if="profileTest.hasTestTypes.symptoms">
                    <app-card class="pa-4">
                        <h2>Symptoms Survey</h2>
              

                    </app-card>
                </v-col>
                <v-col cols="12" md="12"  v-if="profileTest.hasTestTypes.balance">
                    <app-card class="pa-4">
                        <h2>Balance Results</h2>
                        <TestRow id="1001" :profileTest="profileTest" name="mBESS" />

                    </app-card>
                </v-col>
                <v-col cols="12" md="12" v-if="profileTest.hasTestTypes.attention">
                    <app-card class="pa-4">
                        <h2>Cognitive Results</h2>
                        <TestRow id="1002" :profileTest="profileTest" name="Reaction Time" />
                        <TestRow id="1003" :profileTest="profileTest" name="Impulse Control" />
                        <TestRow id="1004" :profileTest="profileTest" name="Inspection Time" />
<!--                        <TestRow test-name="memory" :profileTest="profileTest" name="Memory" />-->
                    </app-card>
                </v-col>
                <v-col cols="12" md="12" v-if="profileTest.hasTestTypes.covid">
                    <app-card class="pa-4">
                        <div style="display: flex; align-items: center">
                            <h2>COVID</h2>
                            <span class="ml-2" v-if="profileTest.covidScreen.isFlagged"><app-chip label small>FLAGGED</app-chip></span>
                            <span class="ml-2" v-else><app-chip label small color="secondary">PASSED</app-chip></span>
                        </div>
                        
                        <div v-for="item in profileTest.covidScreen.detailRows" style="display: flex; max-width: 600px;" :class="{'flagged': item.isFlagged}">
                            <div style="flex: 1">
                                <div>{{item.key}}</div>
                            </div>
                            <div >
                                <div style="font-weight: bold">{{item.value}}</div>
                            </div>
                            
                        </div>
                    </app-card>
                </v-col>
            </v-row>
        </v-container>
       
        <div v-if="profileTest && simpleProfile" v-show="false">
            <pdf-export :profileTest="profileTest" :simpleProfile="simpleProfile"></pdf-export>
        </div>
        
    </div>
</template>

<script>
import SwayProfileTest from "@/js/shared/classes/ProfileTest/SwayProfileTest";
import profileData from "@/assets/jsons/profileData/fake1.json"
import {getProfile, getProfileTestById} from '@/services/profile.service'
import ColumnRangeContainer from "@/components/ColumnRangeContainer";
import PdfExport from '@/components/pdf-export/index'
import TestRow from "@/components/pdf-export/TestRow";

import ProfileTestPdfExportMixin from '@/mixins/profile-test-pdf-export'
import moment from 'moment'
import Profile from "@/js/shared/classes/Profile/SwayProfile";

export default {
    name: "ProfileTest",
    components: {
        ColumnRangeContainer,
        PdfExport,
        TestRow,

    },
    mixins: [ProfileTestPdfExportMixin],
    data() {
        return {
            profileTest: null,
            chartOptions: null,
            profile: null,
            simpleProfile: null,
            exportingPdf: false
        }
    },
    mounted() {
        this.init();
        this.initSimpleProfile()
    },
    computed: {
        
        initializing() {
            return !(this.profileTest && this.profile)
        },
        testDate() {
            if (!this.profileTest) return null;

            return moment(this.profileTest.completedOn).format('M/D/YYYY @ H:mm a')
        },
        overview() {
            if (!this.profileTest) return null;
            return [
                {
                    label: 'Type',
                    value: this.profileTest.data.isEvent ? "Event Test" : "Baseline Test"
                },
                {
                    label: 'Date',
                    value: this.testDate
                },
                {
                    label: 'Notes',
                    value: this.profileTest.data.note
                },
            ]
        },
        headerText() {
            if (!this.profile) return 'Test'
            const {firstName, lastName} = this.profile;
            return `${firstName} ${lastName}`   
        },
        measuresTaken() {
            if (!this.profileTest) return null;
            let temp = [];
            for (let test in this.profileTest.formattedTestsData) {
                if (this.profileTest.formattedTestsData[test].data) {
                    temp.push(this.profileTest.formattedTestsData[test].label)
                }
            }

            return temp
        },
        chartsToDisplay() {
            if (!this.profileTest) return null;
            return this.profileTest.getChartableTests()
        }
    },
    methods: {
        init() {
            this.getProfile();
            this.setTest();
        },
        async initSimpleProfile() {
            const response = await getProfile(this.$route.params.profileId);
            if (!response.isSuccess) {
                return
            }
            const {profile} = response;
            this.simpleProfile = profile;
        },
        async setTest() {
            const {testId: id, profileId} = this.$route.params;
            let response = await getProfileTestById({id, profileId});
            if (!response.isSuccess) {
                return
            }
            const {profileTest} = response;
            // 
            this.profileTest = new SwayProfileTest(profileTest);
            this.chartOptions = this.profileTest.getMeasureColumnRangeChartOptionsById({id: 1001});
            
        },
        async getProfile() {
            let response = await getProfile(this.$route.params.profileId);

            if (response && response.profile) {
                this.profile = response.profile;
            }
        },
        getDisplayDate(date) {
            return moment(date).format('dddd MMMM D, YYYY @ h:mm a')
        }
    }
}
</script>

<style scoped>
.flagged {
    color: #ff6b6b;
}
</style>