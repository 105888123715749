<template>
    <div>
        <v-row>

            <v-col cols="4" v-for="(item, index) in organizationStats" :key="index">

                <AppStatCard
                    :title="item.title"
                    :value="item.value"
                ></AppStatCard>
            </v-col>
        </v-row>
        <TestDataSummaryChart
            :tests-data="historicalTestsData"
            :loading="chartsLoading"
        ></TestDataSummaryChart>
    </div>
</template>

<script>
import Checkout from "@/components/Checkout";
import {addEditCard} from '@/services/billing.service'
import {
    getOrganizationTestDataSummary,
    getTotalGroupsCount,
    getTotalProfileCount, getTotalTestCount
} from "@/services/organization.service";
import moment from "moment";
import TestDataSummaryChart from "@/components/TestDataSummaryChart";
import {getAccounts} from "@/services/userService";
import AppStatCard from "@/components/AppStatCard";



export default {
    name: "OrganizationStatistics",
    components: {
        AppStatCard,
        TestDataSummaryChart,
        Checkout
    },
    data() {
        return {
            chartsLoading: false,
            historicalTestsData: null,
            profileCount: null,
            userCount: null,
            groupsCount: null,
            totalTestsCount: null
        }
    },
    computed: {
        organizationStats() {
            const {profileCount, groupsCount, totalTestsCount, userCount} = this;
            return [
                {
                    title: 'Total Tests Taken',
                    value: totalTestsCount ? totalTestsCount : '-',
                    loading: false
                },
                {
                    title: 'Number of Profiles',
                    value: profileCount ? profileCount : '-',
                    loading: false
                },
                {
                    title: 'Number of Groups',
                    value: groupsCount ? groupsCount : '-',
                    loading: false
                },
                // {
                //     title: 'Number of Users',
                //     value: userCount ? userCount : '-',
                //     loading: false
                // },
            ]
        },
    },
    mounted() {
        this.setupCharts();
        
        this.getTotalProfileCount()
        this.getTotalGroupsCount()
        this.getTotalTestCount();
        
        
    },
    methods: {
        async setupCharts() {
            this.chartsLoading = true;
            if (!this.historicalTestsData) {

                const response = await getOrganizationTestDataSummary(7);

                if (!response.isSuccess) {
                    return
                }
                const { organizationTestDataSummary } = response;
                this.historicalTestsData = organizationTestDataSummary;
            }

            let totalTestsCategories = [];
            let totalTestsData = [];

            this.historicalTestsData.forEach(day => {
                totalTestsCategories.push(moment(day.date).format('M/D/YY'))
                totalTestsData.push(day[this.chartDataToDisplay]);
            })

            this.chartsLoading = false
        },
        async getTotalProfileCount() {
            let response = await getTotalProfileCount();
            if (!response.isSuccess) return
            this.profileCount = response.count
        },
        async getTotalUsersCount() {
            let response = await getAccounts();
            if (!response.isSuccess) return
            this.userCount = response.accounts.length;
        },
        async getTotalGroupsCount() {
            let response = await getTotalGroupsCount();
            if (!response.isSuccess) return
            this.groupsCount = response.count
        },
        async getTotalTestCount() {
            let response = await getTotalTestCount();
            if (!response.isSuccess) return
            this.totalTestsCount = response.testCount
        },
    }
}
</script>

<style scoped>
.element {
    background-color: white; 
    padding: 10px; 
    border: 1px solid lightgrey; 
    border-radius: 5px;
    margin-bottom: 5px
}
</style>