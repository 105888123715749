<template>
  <div class="data-table-footer">
    <div class="pa-4" style="border-right: 1px solid #e0e0e0">
      Items per page:
      <span class="pointer">
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <span class="pointer" v-bind="attrs" v-on="on">
                   <span class="ml-4">{{pagination.itemsPerPage}}</span><v-icon color="text">mdi-chevron-down</v-icon>
                </span>
              </template>
              <v-list>
                <v-list-item-group
                    :value="pagination.itemsPerPage"
                    color="text"
                >
                  <v-list-item
                      v-for="(item, index) in [10,50,100]"
                      :key="index"
                      @click="setItemsPerPage(item)"
                  >
                    <v-list-item-title >{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </span>
    </div>
    <div class="pa-4" style="border-right: 1px solid #e0e0e0">
      {{ pagination.pageStart + 1 }}-{{ pagination.pageStop }} of {{ pagination.itemsLength }} items
    </div>
    <v-spacer></v-spacer>
    <div class="pa-4" style="border-left: 1px solid #e0e0e0">
          <span class="pointer">
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <span class="pointer" v-bind="attrs" v-on="on">
                  {{ pagination.page }}<v-icon color="text">mdi-chevron-down</v-icon>
                </span>
              </template>
              <v-list style="max-height: 200px">
                <v-list-item-group
                    :value="pagination.page - 1"
                    color="text"
                >
                  <v-list-item
                      v-for="(item, index) in new Array(pagination.pageCount).length"
                      :key="index"
                      @click="setPage(index + 1)"
                  >
                    <v-list-item-title >{{ index + 1 }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </span>
      of {{ pagination.pageCount }} pages
    </div>
    <div class="pa-4 pointer" style="border-left: 1px solid #e0e0e0" @click="previousPage()">
      <v-icon color="text ">mdi-menu-left</v-icon>
    </div>
    <div class="pa-4 pointer" @click="nextPage()" style="border-left: 1px solid #e0e0e0">
      <v-icon color="text">mdi-menu-right</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableFooter",
  props: {
    pagination: {
      type: Object,
      required: true
    }
  },
  methods: {
    setPage(value) {
      this.$emit('setPage', value)
    },
    setItemsPerPage(value) {
      this.$emit('setItemsPerPage', value)
    },
    previousPage() {
      this.$emit('previousPage')
    },
    nextPage() {
      this.$emit('nextPage')
    }
    
  }
}
</script>

<style scoped>
.data-table-footer {
  background-color: white!important;
  color: var(--v-text-base);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid lightgrey
}


.pointer:hover {
  cursor: pointer
}
</style>