import httpService from "@/services/httpService";
import {baseUrl} from "@/config"


const endpoint = baseUrl + "/Report"

const getSubscriptionReport = async (request) => {
    
    return tryPost(endpoint + '/GetSubscriptionReport', request);
}

const getSubscriptionReportExport = async (request) => {

    return tryDownloadReport(request, '/GetSubscriptionReportExport', 'SubscriptionReport');
}

const getMissingSubscriptionReport = async () => {

    return tryPost(endpoint + '/GetMissingSubscriptionReport');
}

const getMissingSubscriptionReportExport = async () => {

    return tryDownloadReport({}, '/GetMissingSubscriptionReportExport', 'MissingSubscriptionReport');
}

const getOrganizationReport = async (request) => {

    return tryPost(endpoint + '/GetOrganizationReport', request);
}

const getOrganizationReportExport = async (request) => {

    return tryDownloadReport(request, '/GetOrganizationReportExport', 'OrganizationReport');
}

const getProfilesTestedReport = async (request) => {

    return tryPost(endpoint + '/GetProfilesTestedReport', request);
}

const getProfilesTestedReportExport = async (request) => {

    return tryDownloadReport(request, '/GetProfilesTestedReportExport', 'ProfilesTestedReport');
}

const downloadBalanceXyzCsv = async (request) => {

    return tryDownloadReport(request, '/DownloadBalanceXyzCsv', 'BalanceXyzReport');
}

const downloadAllTrialsCsv = async (request) => {

    return tryDownloadReport(request, '/DownloadAllTrialsCsv', 'AllTrialsReport');
}

const getFunctionalXyzExport = async (id) => {

    return tryDownloadReport({id}, '/DownloadFunctionalXyzCsv', "XYZ_" + id);
}

const getInvoiceReport = async (request) => {

    return tryPost(endpoint + '/GetInvoiceReport', request);
}

const getInvoiceReportExport = async (request) => {

    return tryDownloadReport(request, '/GetInvoiceReportExport', 'InvoiceReport');
}

const getUnpaidInvoiceReport = async (request) => {

    return tryPost(endpoint + '/GetUnpaidInvoiceReport', request);
}

const getUnpaidInvoiceReportExport = async (request) => {

    return tryDownloadReport(request, '/GetUnpaidInvoiceReportExport', 'UnpaidInvoiceReport');
}

const getMissingInvoiceReport = async (request) => {

    return tryPost(endpoint + '/GetMissingInvoiceReport', request);
}

const getMissingInvoiceReportExport = async (request) => {

    return tryDownloadReport(request, '/GetMissingInvoiceReportExport', 'MissingInvoiceReport');
}

const getUnsentInvoiceReport = async (request) => {

    return tryPost(endpoint + '/GetUnsentInvoiceReport', request);
}

const getUnsentInvoiceReportExport = async (request) => {

    return tryDownloadReport(request, '/GetUnsentInvoiceReportExport', 'UnsentInvoiceReport');
}

const getEmailLogReport = async (request) => {

    return tryPost(endpoint + '/GetEmailLogReport', request);
}

const getEmailLogReportExport = async (request) => {

    return tryDownloadReport(request, '/GetEmailLogReportExport', 'EmailLogReport');
}

const tryPost = async (endpoint, request) => {

    try {
        return await httpService.post(endpoint, request);
    } catch (e) {
    }
}

const tryDownloadReport = async (request, actionName, docName) => {

    try {
        let response = await httpService.post(endpoint + actionName, request,{
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer'
        }).then(function(response){

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            let documentName = docName + `.zip`

            link.setAttribute('download', documentName); //or any other extension
            document.body.appendChild(link);
            link.click();
            return response

        })
        

    } catch(error) {
        console.log(error)
    }
}


export {
    getSubscriptionReport,
    getSubscriptionReportExport,
    getMissingSubscriptionReport,
    getMissingSubscriptionReportExport,
    getOrganizationReport,
    getOrganizationReportExport,
    getProfilesTestedReport,
    getProfilesTestedReportExport,
    downloadBalanceXyzCsv,
    downloadAllTrialsCsv,
    getFunctionalXyzExport,
    getInvoiceReport,
    getInvoiceReportExport,
    getUnpaidInvoiceReport,
    getUnpaidInvoiceReportExport,
    getMissingInvoiceReport,
    getMissingInvoiceReportExport,
    getUnsentInvoiceReport,
    getUnsentInvoiceReportExport,
    getEmailLogReport,
    getEmailLogReportExport
}
