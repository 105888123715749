<template>
    <div>
        <div v-if="!loading">
<!--            <page-header title="Sway Reporting"></page-header>-->
            <app-tabs :value="tab">
                <app-tab v-for="tab in tabs" @click="goToRoute(tab.route)" :icon="tab.icon">{{ tab.label }}</app-tab>
            </app-tabs>
            <router-view></router-view>
        </div>
        <div v-else>
            <AppPageLoader text="Loading Sway Reporting..." />
        </div>
    </div>
</template>

<script>

export default {
    name: "SwayReporting",
    props: ['account'],
    data() {
        return {
            loading: false
        }
    },
    async mounted() {
        this.loading = true

        this.loading = false
    },
    methods: {
        goToRoute(route) {
            this.$router.push(route)
        }
    },
    computed: {
        tab() {
            let value = 0;
            let path = this.$route.path.split('/');
            let last = path[2];

            switch (last) {
                case 'organization-report':
                    value = 0;
                    break;
                case 'sway-dashboard':
                    value = 1;
                    break;
                case 'subscription-report':
                    value = 2;
                    break;
                case 'raw-data-export':
                    value = 3;
                    break;
                case 'invoice-reports':
                    value = 4;
                    break;
                case 'email-report':
                    value = 5;
                    break;
            }
            
            return value
        },
        tabs() {
			let base = '/super-admin/reporting'
            let myTabs = [
                {
                    label: 'Organization Report',
                    route: `${base}/organization-report`,
                    icon: 'mdi-credit-card-outline'
                },
                {
                    label: 'Sway Dashboard',
                    route: `${base}/sway-dashboard`,
                    icon: 'mdi-cog-outline'
                },
                {
                    label: 'Subscription Report',
                    route: `${base}/subscription-report`,
                    icon: 'mdi-table'
                },
                {
                    label: 'Test Data Export',
                    route: `${base}/raw-data-export`,
                    icon: 'mdi-table'
                },
                {
                    label: 'Invoice Reports',
                    route: `${base}/invoice-reports`,
                    icon: 'mdi-table'
                },
                {
                    label: 'Organization Email Log',
                    route: `${base}/email-report`,
                    icon: 'mdi-table'
                }
            ];
            
            return myTabs;
        }
    }
}
</script>

<style scoped>

</style>