var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.profileIsInactive)?_c('div',[(!_vm.profile.data.eventStartedOn)?_c('div',{staticClass:"mb-2",staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('app-btn',{on:{"click":function($event){return _vm.$emit('createEvent')}}},[_vm._v("Create Event")])],1):_vm._e(),_c('app-card',{attrs:{"hide-header":""}},[_c('app-data-table',{attrs:{"headers":_vm.headers,"items":_vm.profile.events,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(!item.endedOn)?_c('app-chip',{staticClass:"ml-1",attrs:{"color":"orange","dark":""}},[_vm._v(" Open ")]):_vm._e()]}},{key:"item.startedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.startedOn))+" ")]}},{key:"item.endedOn",fn:function(ref){
var item = ref.item;
return [(item.endedOn)?_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(item.endedOn))+" ")]):_vm._e()]}},{key:"item.eventTypeId",fn:function(ref){
var item = ref.item;
return [(item.eventTypeId)?_c('div',[_vm._v(" "+_vm._s(_vm.getEventTypeIdDisplay(item.eventTypeId))+" ")]):_vm._e()]}},{key:"item.deleteButton",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('app-fab-btn',{attrs:{"type":"edit","color":"grey","x-small":""},on:{"click":function($event){return _vm.$emit('editEvent', item)}}}),_c('app-fab-btn',{attrs:{"type":"delete","color":"red","small":""},on:{"click":function($event){return _vm.deleteProfileEvent(item)}}})],1)]}},{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true}],null,true)})],1)],1):_c('div',[_c('EmptyState',{attrs:{"title":"Events Unavailable","description":"You cannot create an event for an inactive profile. To reactivate this profile, please add this profile to an active group."}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }