<template>
    
    <div style="display: flex">
        <div style="display: flex; align-items: center; color: #757575" >
            {{ label }}
        </div>
        <div class="pl-2">
            <v-switch
                v-model="value"
                @change="update"
                color="primary"
                :disabled="disabled"
            ></v-switch>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true,
            type: Boolean
        },
        label: {
            required: true,
            type: String
        },
        checkboxLeftSide: {
            type: Boolean,
            default: false
        },
        helperMessage: {
            type: String
        },
        color: {
            default: 'primary'
        },
        disabled: {
            type: Boolean
        }
    },
    name: "AppFormSwitch",
    methods: {
        update(e) {
            // 
            this.$emit('input', e)
        }
    }
}
</script>

<style scoped>
.helper-container {
    background-color: var(--v-text-base);
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center
}

.v-input .v-input--is-label-active .v-input--is-dirty .theme--light .v-input--selection-controls .v-input--switch .primary--text {
    height: 20px!important
}
</style>