<template>
    <div style="padding: 20px; display: flex; font-size: 12px">
        <div style="width: 65%; display: inline-block">
            <p>
                <strong style="margin-right: 3px">Baseline: </strong>
                A baseline is created when an individual has taken a baseline session or has completed multiple tests. The baseline range is marked with red, yellow, or green based on the consistency of the scores.
            </p>
            <p>
                <strong style="margin-right: 3px">Percentile: </strong>
                Ranking of the individual based on age and sex matched normative data. A score indicates how you compared to others within your population. Ranking is segmented into Very Low (lowest 10%), Low (10%-24%), Low Avg (25%-49%), High Avg (50%-74%), High (75%-89%), Very High (90% and above).
            </p>
        </div>
        <div style="width: 35%; display: flex; justify-content: center; flex-direction: column; align-items: center">
            <div>Baseline Consistency</div>
            <div>
                <svg width="199" height="8" viewBox="0 0 199 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.244141" y="0.606201" width="121.923" height="7.34477" rx="3.67239" fill="#EF465E"/>
                    <rect x="76.6292" y="0.606201" width="121.923" height="7.34477" rx="3.67239" fill="#34D1BF"/>
                    <rect x="66.3464" y="0.606201" width="66.1029" height="7.34477" fill="#FFD22B"/>
                </svg>

            </div>
            <div style="display: flex; width: 200px; margin-top: 20px">
                <div style="flex: 1; text-align: left">Low</div>
                <div style="flex: 1; text-align: center">Average</div>
                <div style="flex: 1; text-align: right">High</div>
            </div>
            <div>
                <svg width="200" height="9" viewBox="0 0 200 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.775146" y="0.912598" width="198.309" height="7.34477" rx="3.67239" fill="#626262"/>
                    <rect x="33.8267" y="0.912598" width="132.206" height="7.34477" rx="3.67239" fill="#989898"/>
                    <line x1="100.113" y1="0.178101" x2="100.113" y2="8.99183" stroke="white" stroke-width="0.367239"/>
                </svg>


            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: "BaselineExplanationSection"
}
</script>

<style scoped>

</style>