<template>
    <div>
        <AppInput
            :name="errorLabel ? errorLabel : name"
            :rules="rules"
            :label="label"
            v-slot="{errors, valid}"
            :row="row"
            :helperMessage="helperMessage"
        >
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="value"
                        label=""
                        outlined
                        readonly
                        dense
                        v-bind="attrs"
                        v-on="on"
                        prepend-inner-icon="mdi-calendar"
                        
                    ></v-text-field>
                </template>
                <v-date-picker
                    :value="value"
                    no-title
                    scrollable
                    @input="onChange"
                    :allowed-dates="allowedDates"
                    :disabled="disabled"
                    ref="picker"
                    :max="max"
                    :min="min"
                >
                    <v-spacer></v-spacer>
	                <v-btn
		                text
		                color="primary"
		                @click="clearDate"
		                v-if="clearable"
	                >
		                Clear
	                </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                    >
                        OK
                    </v-btn>
                </v-date-picker>
            </v-menu>
        </AppInput>
<!--        <validation-provider-->
<!--            v-slot="{ errors, valid }"-->
<!--            :name="errorLabel ? errorLabel : label"-->
<!--            :rules="rules"-->
<!--        >-->
<!--            <div>-->
<!--                <span style="color: #757575">{{label}}</span> <span v-if="errors" class="error&#45;&#45;text text-caption">{{errors[0]}}</span>-->
<!--            </div>-->


<!--            -->


<!--        </validation-provider>-->
    </div>
<!--    <v-row>-->
<!--        <v-col cols="3" style="display: flex; align-items: center">-->
<!--            {{ label }}-->
<!--        </v-col>-->
<!--        <v-col cols="12" sm="9" >-->
<!--            -->



<!--        </v-col>-->
<!--    </v-row>-->
</template>

<script>

import AppInput from "@/components/forms/AppInput";
export default {
    components: {AppInput},
    props: {
        value: {
            required: false,
        },
        label: {
            required: true,
            type: String
        },
        placeholder: {
            type: String,
        },
        allowedDates: {
            
        },
        disabled: {
            type: Boolean
        },
        max: {
            
        },
	    min: {},
        startWithYear: {
            type: Boolean,
            default: false
        },
        errorLabel: {
            
        },
        rules: {
            
        },
        row: {
            type: Boolean
        },
	    clearable: {
		    type: Boolean
	    },
	    helperMessage: {},
	    name: {
		    type: String
	    },
    },

    name: "AppFormDatePicker",
    data() {
        return {
            date: new Date().toISOString().substr(0, 10),
            menu: false,
        }
    },
    methods: {
        onChange(e) {
            this.$emit('input', e)
        },
        clearDate() {
			this.$refs.menu.save([])
	        this.$emit('input', null)
        }
    },
    watch: {
        menu (val) {
            if (this.startWithYear) {
                val && this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
            }
            
        }
    },
    
    computed: {
        adjustedErrorMessages() {
            if (this.errorMessages) {
                return this.errorMessages.length > 0 ? [''] : []
            }
        }
    }

}
</script>

<style>

</style>