<template>
    <thead>
    <tr>
        <th v-for="(header, index) in props.headers" :class="{'th-border': index !== props.headers.length - 1, 'text--text': true}" @click="onHeaderClick(header)">

            {{header.text}}

        </th>
    </tr>
    </thead>
</template>

<script>
export default {
    name: "TableHeader",
    props: {
        props: {
            type: Object,
            required: true
        },
        on: {
            type: Object,
            required: true
        }
    },
    
    methods: {
        onHeaderClick(header) {
            on.sort(header.value)
        }
    }
}
</script>

<style scoped>
.th-border {
    border-right: 1px solid lightgrey!important;

}
</style>